import React, { useCallback } from 'react';
import { Box, BoxProps, TextV2, ButtonV2 } from '@withjoy/joykit';
import { DateFragment, VirtualEventLinkParseResultFragment, VirtualEventProvider } from '@graphql/generated';
import globalWindow from '@shared/core/globals';
import { Livestream } from '@withjoy/joykit/icons';
import { useEventTimes } from './VirtualEventBlock.utils';
import { RedDot, streamButtonOverrides, StyledEventInfo, StyledProviderInfo, StyledStatus, StyledWatchInBrowser, styles } from './VirtualEventBlock.styles';
import { useTranslations } from './VirtualEventBlock.i18n';
import { providerIcons } from './VirtualEventBlock.constants';

interface InternalVirtualEventBlockProps {
  startTime?: Maybe<DateFragment>;
  endTime?: Maybe<DateFragment>;
  virtualEvent: Omit<VirtualEventLinkParseResultFragment, 'isPasswordDetected'>;
  hideStatusMsg?: boolean;
  hideLiveNowMsg?: boolean;
  hideStreamingByMsg?: boolean;
  hideWatchInBrowserMsg?: boolean;
  hideLocalDate?: {
    date?: boolean;
    timeframe?: boolean;
    utc?: boolean;
    hideAll?: boolean;
  };
  buttonWidth?: string;
}

export interface VirtualEventBlockProps extends Readonly<InternalVirtualEventBlockProps>, BoxProps {
  onVirtualEventBlockClicked?: () => void;
}

const VirtualEventBlock: React.FC<VirtualEventBlockProps> = React.memo(
  ({
    virtualEvent,
    startTime,
    endTime,
    hideStatusMsg,
    hideLiveNowMsg = false,
    hideStreamingByMsg = false,
    hideWatchInBrowserMsg = false,
    hideLocalDate,
    buttonWidth,
    onVirtualEventBlockClicked
  }) => {
    const translations = useTranslations();
    const { isEventLive, localizedEventTimeMessage, isSameTimezone, message } = useEventTimes(startTime, endTime, translations);
    const { isLinkValid, provider, urlBrowser, urlLinkText } = virtualEvent;

    const handleVirtualEventClicked = useCallback(() => {
      if (!urlLinkText) return;
      globalWindow.open?.(urlLinkText, '_blank');
      onVirtualEventBlockClicked && onVirtualEventBlockClicked();
    }, [urlLinkText, onVirtualEventBlockClicked]);

    if (!isLinkValid || !urlLinkText) {
      return null;
    }

    const ProviderIcon = providerIcons[provider];
    const isProviderZoom = provider === VirtualEventProvider.zoom;
    const joinCtaText = isProviderZoom ? translations.joinCtaZoom : translations.joinCta;

    return (
      <>
        {!isEventLive && !hideStatusMsg && (
          <TextV2 data-testid={'veb-status-msg'} typographyVariant="body2" whiteSpace="nowrap" color="mono12">
            {message}
          </TextV2>
        )}

        {isEventLive && !hideLiveNowMsg && (
          <StyledStatus data-testid={'veb-nowlive'}>
            <RedDot />
            <TextV2 typographyVariant="button1">{translations.eventLive}</TextV2>
          </StyledStatus>
        )}

        {!hideLocalDate?.hideAll && !isSameTimezone && localizedEventTimeMessage && (
          <StyledEventInfo __css={styles.info}>
            <Box data-testid={'veb-localized-time-msg'}>
              {!hideLocalDate?.date && (
                <TextV2 data-testid={'veb-localized-date'} typographyVariant="label6" color="mono10">
                  {localizedEventTimeMessage.dateShort}
                </TextV2>
              )}
              {!hideLocalDate?.timeframe && (
                <TextV2 data-testid={'veb-localized-timeframe'} typographyVariant="label6" color="mono12">
                  {localizedEventTimeMessage.timeframe}
                </TextV2>
              )}
              {!hideLocalDate?.utc && (
                <TextV2 data-testid={'veb-localized-offset'} typographyVariant="label2" color="mono10">
                  {translations.localTimezoneOffset(localizedEventTimeMessage.offset)}
                </TextV2>
              )}
            </Box>
          </StyledEventInfo>
        )}

        <ButtonV2
          data-testid={'veb-join-button'}
          fullWidth={!buttonWidth}
          onClick={handleVirtualEventClicked}
          overrides={streamButtonOverrides({ isProviderZoom, isStreamNow: isEventLive })}
          shape={'rounded'}
          startIcon={() => <Livestream />}
          variant={'solid'}
          intent="destructive"
          width={buttonWidth}
        >
          {joinCtaText}
        </ButtonV2>

        {provider !== VirtualEventProvider.unknown && !hideStreamingByMsg && (
          <StyledProviderInfo>
            <TextV2 data-testid={'veb-localized-provider'} marginRight={2} typographyVariant="label2">
              {translations.streamingProvider}
            </TextV2>
            {ProviderIcon && <ProviderIcon data-testid={`veb-provider-${provider}`} />}
          </StyledProviderInfo>
        )}

        {urlBrowser && !hideWatchInBrowserMsg && (
          <StyledWatchInBrowser __css={styles.watchInBrowser}>
            <TextV2 color={'mono12'} typographyVariant="label1">
              {translations.troubleWithApp}
            </TextV2>
            <TextV2 as="a" href={urlBrowser} target="_blank" typographyVariant="label1" textDecoration={'underline'} color={'mono14'}>
              {translations.watchInBrowser}
            </TextV2>
          </StyledWatchInBrowser>
        )}
      </>
    );
  }
);

VirtualEventBlock.displayName = 'VirtualEventBlock';

export { VirtualEventBlock };
