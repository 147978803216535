export enum StateType {
  Loading = 'loading',
  Complete = 'complete',
  Failure = 'failure'
}

export interface LoadingState {
  readonly type: StateType.Loading;
}

export interface CompleteState {
  readonly type: StateType.Complete;
}

export interface FailureState {
  readonly type: StateType.Failure;
  readonly reason: unknown;
  readonly context?: object;
}

export type ComponentHealthState = LoadingState | CompleteState | FailureState;

export interface ComponentHealthTelemetryData {
  readonly componentName: string;
  readonly loadId: string;
  readonly duration?: number;
}

export interface ComponentInfo {
  readonly componentName: string;
  readonly loadId: string;
  readonly loadStartTime: number;
}

export interface InternalContextValue {
  readonly onRegisterWithProvider: () => void;
  readonly onLoadComplete: () => void;
  readonly onLoadFailure: (reason: unknown, context?: object) => void;
}
