import React, { useRef, useState } from 'react';

import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

import { useAutoLoadComplete } from '../hooks/useAutoLoadComplete';
import { useLoadEndEffect } from '../hooks/useLoadEndEffect';
import { useLoadStartEffect } from '../hooks/useLoadStartEffect';
import { useLoadTimeoutEffect } from '../hooks/useLoadTimeoutEffect';
import { ComponentHealthState, ComponentInfo, StateType } from '../types';
import { InternalContextValue } from '../types';
import { ErrorBoundary } from './ErrorBoundary';
import { generateLoadId } from './generateLoadId';

export interface ComponentHealthWrapperProps {
  readonly name: string;
  readonly internalContext: React.Context<InternalContextValue | undefined>;
}

export const ComponentHealthWrapper: React.FC<ComponentHealthWrapperProps> = ({ name, internalContext: InternalContext, children }) => {
  const componentInfoRef = useRef<ComponentInfo>({
    componentName: name,
    loadId: generateLoadId(),
    loadStartTime: Date.now()
  });
  const [componentHealthState, setComponentHealthState] = useState<ComponentHealthState>({
    type: StateType.Loading
  });

  useLoadStartEffect(componentInfoRef);
  useLoadEndEffect(componentInfoRef, componentHealthState);

  const onLoadComplete = useEventCallback(() => {
    setComponentHealthState(prevState => {
      if (prevState.type === 'loading') {
        return {
          type: StateType.Complete
        };
      }

      return prevState;
    });
  });
  const onLoadFailure = useEventCallback((reason: unknown, context?: object) => {
    setComponentHealthState({
      type: StateType.Failure,
      reason,
      context
    });
  });
  const onRegisterWithProvider = useAutoLoadComplete(onLoadComplete);

  useLoadTimeoutEffect(componentHealthState, () => {
    onLoadFailure('timeout');
  });

  return (
    <ErrorBoundary onCaughtError={onLoadFailure}>
      <InternalContext.Provider value={{ onLoadComplete, onLoadFailure, onRegisterWithProvider }}>{children}</InternalContext.Provider>
    </ErrorBoundary>
  );
};
