import { useRouteMatch } from '@shared/core';

const WEBSITE = 'website';
const PAGE = 'page';

/**
 * To get current page path/slug
 * @param eventHandle - To get current page path/slug for event
 * @param isAdminPage - To get current page path/slug for admin / guest site
 */
export function useCurrentPageSlug(eventHandle: string, isAdminPage?: boolean) {
  const matchRoute = isAdminPage ? `/${eventHandle}/edit/${WEBSITE}/${PAGE}/:pageSlug` : `/${eventHandle}/${PAGE}/:pageSlug`;

  const routeMatch = useRouteMatch<{ pageSlug: string }>(`/${eventHandle}/:pageSlug`);
  const routeMatchCustomPage = useRouteMatch<{ pageSlug: string }>(matchRoute);

  const isCustomPageSlug = routeMatch?.params.pageSlug === PAGE;
  const currentPageSlug = isCustomPageSlug ? routeMatchCustomPage?.params.pageSlug : routeMatch?.params.pageSlug;

  return { currentPageSlug, isCustomPageSlug };
}
