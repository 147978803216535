const tryCreateEvent = (eventName: string, eventInit: EventInit = {}) => {
  let event;
  // Currently, globalWindow typing does not include all of the DOM API types
  // Until we update the typing, use window directly here
  if (typeof window !== 'undefined' && typeof window.Event === 'function') {
    event = new window.Event(eventName, { bubbles: true, cancelable: true, ...eventInit });
  }
  return event;
};

const tryGetNativeInputSetter = () => {
  let inputSetter;
  if (typeof window !== 'undefined') {
    inputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
  }
  return inputSetter;
};

export const trySetNativeValueAndDispatch = (input: Maybe<HTMLInputElement>) => {
  if (input) {
    // Setting `value` directly via React will result in the event being swallowed.
    // We want to call the native input prototype value setter + dispatch an event from the input directly
    // The synthetic event will correctly reference the updated input
    const valueSetter = tryGetNativeInputSetter();
    const event = tryCreateEvent('input');
    if (event && valueSetter) {
      valueSetter.call(input, '');
      return input.dispatchEvent(event);
    }
  }
  return false;
};
