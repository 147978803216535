import React, { useEffect, useMemo, useState } from 'react';
import { useGetGuestsiteCoupleStoryAndQandasQuery, EventPageType, EventPageVisibility } from '@graphql/generated';

import { Box, BoxProps, Flex, TextV2 } from '@withjoy/joykit';
import { PrettyLink } from '../../components/PrettyLink';
import { ContentColumns } from '../../primitives';
import { FaqList } from '../FaqList';
import { useLayout } from '../../layouts/LayoutProvider';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { GuestSiteEvent } from '../../layouts/layout.types';
import { InlineEditor } from '@shared/components';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { routePaths } from '@apps/guest/utils/RouteHelper.utils';
import { withWindow } from '@shared/utils/withWindow';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { InlineEditExperimentProps, useInlineEditExperimentListeners } from '@shared/hooks/useInlineEditExperimentListeners';

export interface CoupleStoryProps
  extends Readonly<{
      stackByDefault?: boolean;
      qandasTitle?: Maybe<string>;
      eventHandle: string;
      eventPages: GuestSiteEvent['pages'] | undefined;
    }>,
    BoxProps {}

const CoupleStory = React.forwardRef<HTMLDivElement, CoupleStoryProps>(({ stackByDefault, eventPages, ...restProps }, ref) => {
  const { eventHandle } = restProps;

  const [inlinePageEditEnabled, setInlinePageEditEnabled] = useState(false);

  const { layout } = useLayout();
  const isPreviewing = isInIframe();
  const { isAdmin } = useEventUserRole();
  const { data, loading } = useGetGuestsiteCoupleStoryAndQandasQuery({
    variables: { eventHandle: eventHandle, isAdminDashboard: isPreviewing && isAdmin },
    batchMode: 'fast',
    fetchPolicy: 'cache-and-network',
    ssr: false
  });

  useEffect(() => {
    sendMessageToParentWindow({
      action: 'experimentRequestFromPreview',
      value: {
        id: EventPageType.story || '',
        key: 'admindashboardInlinePageEditExperiment',
        skip: !isPreviewing
      },
      source: 'joyPreview'
    });
  }, [isPreviewing]);

  const experimentData: InlineEditExperimentProps = useMemo(
    () => [
      {
        experimentKey: 'admindashboardInlinePageEditExperiment',
        setFn: data => {
          if (data.id === EventPageType.story || '') {
            setInlinePageEditEnabled(data.isEnabled);
          }
        }
      }
    ],
    []
  );

  useInlineEditExperimentListeners(experimentData);

  useEffect(() => {
    if (isPreviewing) {
      sendMessageToParentWindow({ action: 'requestIframeScaleValue', source: 'joyPreview' });
    }
  }, [loading, isPreviewing]);

  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);

  const { storyText, qandas, isBrannan } = useMemo(() => {
    return {
      storyText: data?.eventByName?.info.story || '',
      qandas: data?.eventByName?.info.tidbits || [],
      isBrannan: layout === 'brannan'
    };
  }, [data, layout]);

  const { storyTitle, qandasTitle, storyVisibility, qandasVisibility, storyId, qandasId } = useMemo(() => {
    let storyTitle,
      qandasTitle = null;
    let storyId = '';
    let qandasId = '';
    let storyVisibility: EventPageVisibility = EventPageVisibility.public;
    let qandasVisibility: EventPageVisibility = EventPageVisibility.public;
    eventPages?.forEach(page => {
      if (page.type === EventPageType.tidbits) {
        qandasTitle = page.pageTitle;
        qandasVisibility = page.visibility;
        qandasId = page.id;
      } else if (page.type === EventPageType.story) {
        storyTitle = page.pageTitle;
        storyVisibility = page.visibility;
        storyId = page.id;
      }
    });

    return {
      storyTitle,
      qandasTitle,
      storyVisibility,
      qandasVisibility,
      storyId,
      qandasId
    };
  }, [eventPages]);

  const onPageEditClick = useEventCallback(() => {
    withWindow(window => {
      window.open(`/${eventHandle}/edit/${routePaths[EventPageType.story].goToPath()}`, '_blank');
    });
  });

  const onVisibilityClick = useEventCallback((id: string, type: EventPageType, title: string, visibility: EventPageVisibility) => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: {
        action: 'editPageSettings',
        inlineEditData: { pageId: id, pageType: type, pageTitle: title, pageVisibility: visibility, isPageContainer: false }
      }
    });
  });

  if (loading) return <div style={{ height: '300px' }}></div>; // height set to prevent divs from jumping around while page loads

  if (!storyText && (!qandas || qandas.length === 0)) {
    return null;
  }

  return (
    <Box ref={ref} {...restProps}>
      <ContentColumns>
        {storyTitle &&
          storyText &&
          (inlinePageEditEnabled ? (
            <InlineEditor
              actionData={{
                edit: onPageEditClick,
                visibility: {
                  function: () => onVisibilityClick(storyId, EventPageType.story, storyTitle, storyVisibility),
                  visibility: storyVisibility
                }
              }}
              inset={isMobile ? { x: -4, y: -16 } : { x: 4, y: -16 }}
              wrapperType="actionOutside"
              componentName="page"
              elementLabel={storyTitle}
              pageName={EventPageType.story}
              pageSlug="story"
              stickyOnScroll={true}
              isEligibleForInlineEditing={isBrannan}
              wrapperCSS={{ width: '100%' }}
            >
              <ContentColumns.Column offset={stackByDefault ? undefined : [0, 1]} span={stackByDefault ? 12 : [12, 10]}>
                {isBrannan && (
                  <TextV2 data-testid={'story-title'} typographyVariant={'display4'} textAlign="center" marginBottom={9}>
                    {storyTitle}
                  </TextV2>
                )}
                <TextV2 tagName="div" typographyVariant="body4" wordBreak="break-word">
                  <PrettyLink>{storyText}</PrettyLink>
                </TextV2>
              </ContentColumns.Column>
            </InlineEditor>
          ) : (
            <ContentColumns.Column offset={stackByDefault ? undefined : [0, 1]} span={stackByDefault ? 12 : [12, 10]}>
              {isBrannan && (
                <TextV2 data-testid={'story-title'} typographyVariant={'display4'} textAlign="center" marginBottom={9}>
                  {storyTitle}
                </TextV2>
              )}
              <TextV2 tagName="div" typographyVariant="body4" wordBreak="break-word">
                <PrettyLink>{storyText}</PrettyLink>
              </TextV2>
            </ContentColumns.Column>
          ))}
        {qandas && qandasTitle && !stackByDefault && (
          <>
            <Box marginTop={10} width="100%" />
            {inlinePageEditEnabled ? (
              <InlineEditor
                actionData={{
                  edit: onPageEditClick,
                  visibility: {
                    function: () => onVisibilityClick(qandasId, EventPageType.tidbits, qandasTitle, qandasVisibility),
                    visibility: qandasVisibility
                  }
                }}
                inset={isMobile ? { x: -4, y: -16 } : { x: 4, y: -16 }}
                wrapperType="actionOutside"
                componentName="page"
                elementLabel={qandasTitle}
                pageName={EventPageType.tidbits}
                pageSlug="story"
                stickyOnScroll={true}
                isEligibleForInlineEditing={isBrannan}
                wrapperCSS={{ width: '100%' }}
              >
                <ContentColumns.Column offset={stackByDefault ? undefined : [0, 1]} span={stackByDefault ? 12 : [12, 10]}>
                  <Flex flexDirection={'column'} alignItems={'center'} marginBottom={8}>
                    <TextV2 data-testid={'story-qandas-title'} typographyVariant={'display4'} textAlign="center">
                      {qandasTitle}
                    </TextV2>
                  </Flex>
                  <FaqList data-testid={'story-qandas'} stackByDefault={true} nested={true} eventHandle={eventHandle} tidbits={qandas} />
                </ContentColumns.Column>
              </InlineEditor>
            ) : (
              <ContentColumns.Column offset={stackByDefault ? undefined : [0, 1]} span={stackByDefault ? 12 : [12, 10]}>
                <Flex flexDirection={'column'} alignItems={'center'} marginBottom={8}>
                  <TextV2 data-testid={'story-qandas-title'} typographyVariant={'display4'} textAlign="center">
                    {qandasTitle}
                  </TextV2>
                </Flex>
                <FaqList data-testid={'story-qandas'} stackByDefault={true} nested={true} eventHandle={eventHandle} tidbits={qandas} />
              </ContentColumns.Column>
            )}
          </>
        )}
      </ContentColumns>
    </Box>
  );
});

CoupleStory.displayName = 'CoupleStory';

export { CoupleStory };
