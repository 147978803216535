export type GoogleFontKey = 'greatVibes' | 'notoSerif';

export const googleFontFamilies: Readonly<Record<GoogleFontKey, string>> = {
  greatVibes: 'Great Vibes',
  notoSerif: 'Noto Serif'
};

// https://github.com/typekit/webfontloader#google
const _getWebFontFamily = (key: GoogleFontKey, fontWeights?: Array<string | number>) => {
  const fontFamily = googleFontFamilies[key];
  const fontWeightString = fontWeights?.join(',');
  return [fontFamily, fontWeightString].filter(x => x).join(':');
};

export const googleFontVariations: Readonly<Record<GoogleFontKey, string>> = {
  greatVibes: _getWebFontFamily('greatVibes'),
  notoSerif: _getWebFontFamily('notoSerif', [400, 500, 700])
};
