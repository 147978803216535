/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Chevron Left Square',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path fillRule="evenodd" clipRule="evenodd" d="M4 8L10.1091 2L11 2.78505L5.65455 8L11 13.215L10.1091 14L4 8Z" fill="currentColor" />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path fillRule="evenodd" clipRule="evenodd" d="M6 12L15.6 2L17 3.30841L8.6 12L17 20.6916L15.6 22L6 12Z" fill="currentColor" />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
