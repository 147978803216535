import React, { useContext, useMemo } from 'react';
import { ColumnGap } from './Columns.types';

export type ColumnsContextType = {
  columnGap: ColumnGap;
  isRootContainer: boolean;
};

export const ColumnsContext = React.createContext<ColumnsContextType | undefined>(undefined);

export const ColumnsContextProvider: React.FC<ColumnsContextType> = ({ children, columnGap, isRootContainer }) => {
  const value = useMemo(() => {
    return {
      columnGap,
      isRootContainer
    };
  }, [columnGap, isRootContainer]);
  return <ColumnsContext.Provider value={value}>{children}</ColumnsContext.Provider>;
};

type AlertLevel = 'silent' | 'error';

function useColumnsContext(alert?: 'error'): ColumnsContextType;
function useColumnsContext(alert?: 'silent'): ColumnsContextType | undefined;
function useColumnsContext(alert?: AlertLevel) {
  alert = alert || 'error';
  const context = useContext(ColumnsContext);
  if (context === undefined) {
    if (alert === 'error') {
      throw new Error('useColumnsContext must be used within a ColumnsContextProvider');
    }
  }
  return context;
}

export { useColumnsContext };
