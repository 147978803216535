import { StationeryPrintOrderStatusEnum } from '@graphql/generated';
import { ConsolidatedOrderStatus } from './PrintJobDetails.types';

export const PRINT_ORDER_STATUS_TO_CONSOLIDATED_STATUS: Record<StationeryPrintOrderStatusEnum, ConsolidatedOrderStatus> = {
  init: 'orderPlaced',
  paid: 'orderPlaced',
  canceled: 'canceled',
  refunded: 'refunded',

  // Handoff to Taylor
  queued: 'inProgress',
  inProgress: 'inProgress',
  sent: 'inProgress',
  paused: 'inProgress',

  // Print vendor has begun shipping process
  readyToShip: 'readyToShip',

  // Print vendor has passed order off to shipping vendor
  shipped: 'shipped',
  error: 'shipped',

  // Shipping vendor has sent a shipping update
  complete: 'delivered',
  delivered: 'delivered'
};
