import { withWindow } from '@shared/utils/withWindow';
import React, { useEffect, useRef, useState } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { GuestPartnerHotelBookingRedirectParams, useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { generateGoogleUrl } from '@shared/utils/googleUrl';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useCalculatedCheckIn, useCalculatedCheckOut } from '@shared/hooks/useDateUtils';
import { useIsPartnerHotelValidQuery } from '@graphql/generated';
import { differenceInMonths } from 'date-fns';
import { PartnerLinkSearchParams } from '@shared/hooks/useHotelBookingPartnerLink';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import queryString from 'query-string';
import { config } from '@static/js/env.config';

/**
 * Formats a Date object into a string in the format 'YYYY-MM-DD'.
 */
const formatDate = (dateObj: Date | null): string | null =>
  dateObj && !isNaN(dateObj.getTime()) ? `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}` : null;

function replaceUrl(url: string): void {
  withWindow(() => {
    window.location.replace(url);
  });
}

export const HotelBookingPartner: React.FC<{ zentrumHubId?: string }> = ({ zentrumHubId }) => {
  const redirectInProgress = useRef(false);
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const { eventInfo, loading: eventInfoLoading } = useEventInfo();
  const eventDate = eventInfo?.finalizedDate?.dateString || undefined;

  const checkIn = useCalculatedCheckIn(eventDate);
  const checkOut = useCalculatedCheckOut(eventDate);
  const checkInOrCheckout = checkIn || checkOut;
  const isCheckInOrCheckoutValid = !!checkInOrCheckout && differenceInMonths(checkInOrCheckout, new Date()) < 11;

  const telemetry = useHotelBookingGuestSiteTelemetry();

  const guestLinkUseRedirectService = useFeatureValue('guestLinkUseRedirectService').value === 'on';
  const { value: zentrumHubEnabled, loading: featureFlagsLoading } = useFeatureValue('zentrumHubEnabled');
  const shouldSendUsersToZentrumHubWhiteLabel =
    useFeatureValue('zentrumHubPartnerEnabled', {
      skip: zentrumHubEnabled !== 'on'
    }).value === 'on';

  const hotelIdAsNumber = parseInt(zentrumHubId || '', 10);
  const { data, loading: isPartnerHotelValidQueryLoading } = useIsPartnerHotelValidQuery({
    variables: {
      payload: {
        zhId: hotelIdAsNumber,
        ...(isCheckInOrCheckoutValid ? { checkIn, checkOut } : {})
      }
    },
    batchMode: 'off',
    skip: isNaN(hotelIdAsNumber) || eventInfoLoading
  });

  const queryParams = useQueryParams();
  const getStringQueryParam = useEventCallback((key: string) => {
    const value = queryParams[key];
    return Array.isArray(value) ? value[0] : value ?? undefined;
  });

  useMount(() => {
    telemetry.hotelbooking.enter();
  });

  useEffect(() => {
    if (redirectInProgress.current || eventInfoLoading || featureFlagsLoading || (shouldSendUsersToZentrumHubWhiteLabel && isPartnerHotelValidQueryLoading)) {
      return;
    }

    const { isValid, hasRates, hasInventory } = data?.zentrumHubHotelIsValid || {};
    const hotelWebsite = getStringQueryParam(PartnerLinkSearchParams.hotelWebsite);
    const lat = getStringQueryParam(PartnerLinkSearchParams.latitude);
    const lng = getStringQueryParam(PartnerLinkSearchParams.longitude);
    const gplid = getStringQueryParam(PartnerLinkSearchParams.googlePlaceId);
    const name = getStringQueryParam(PartnerLinkSearchParams.name);
    const address = getStringQueryParam(PartnerLinkSearchParams.address);
    const telemetryPayload: Pick<GuestPartnerHotelBookingRedirectParams, 'zentrumHub' | 'hotelWebsite' | 'googleMaps'> = {
      zentrumHub: zentrumHubId ? { id: zentrumHubId, isValid, hasRates, hasInventory } : undefined,
      hotelWebsite: hotelWebsite ? { value: hotelWebsite } : undefined,
      googleMaps:
        gplid || lat || lng || name || address
          ? {
              gplid,
              name,
              address,
              lat,
              lng
            }
          : undefined
    };

    /**
     * Try redirect to ZentrumHub white label
     */
    if (shouldSendUsersToZentrumHubWhiteLabel && zentrumHubId && isValid) {
      const zhUrl = new URL(`https://hotels.withjoy.com/hotels/v2/${zentrumHubId}`);
      zhUrl.searchParams.append('campaignid', eventInfo?.eventId || '');

      if (isCheckInOrCheckoutValid) {
        const checkInAsDate = formatDate(checkIn);
        if (checkInAsDate) {
          zhUrl.searchParams.append('checkin', checkInAsDate);
        }

        const checkOutAsDate = formatDate(checkOut);
        if (checkOutAsDate) {
          zhUrl.searchParams.append('checkout', checkOutAsDate);
        }
      }

      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'zentrumHub',
        destinationUrl: zhUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          replaceUrl(zhUrl.toString());
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, try to redirect to hotel website
     */
    let hotelWebsiteUrl: URL | undefined;
    if (hotelWebsite) {
      try {
        hotelWebsiteUrl = new URL(hotelWebsite);
      } catch {
        // Do nothing
      }
    }
    if (hotelWebsite && hotelWebsiteUrl) {
      const hotelWebsiteWithRedirectService = guestLinkUseRedirectService
        ? queryString.stringifyUrl({
            url: `${config.redirectServiceUri}/redirect`,
            query: {
              url: hotelWebsite
            }
          })
        : hotelWebsite;
      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'hotelWebsite',
        destinationUrl: hotelWebsiteUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          replaceUrl(hotelWebsiteWithRedirectService);
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, try redirect to Google Maps
     */
    const googleUrlString = generateGoogleUrl({
      placeId: gplid,
      name,
      address,
      lat,
      lng
    });
    if (googleUrlString) {
      const googleUrl = new URL(googleUrlString);

      telemetry.guestPartnerHotelBookingRedirect({
        destinationName: 'googleMaps',
        destinationUrl: googleUrl,
        ...telemetryPayload,
        onTelemetrySend: () => {
          replaceUrl(googleUrl.toString());
        }
      });

      redirectInProgress.current = true;
      return;
    }

    /**
     * Else, show not found page
     */
    telemetry.guestPartnerHotelBookingRedirect({
      destinationName: 'notFound',
      destinationUrl: new URL(window.location.href),
      ...telemetryPayload
    });

    setShowNotFound(true);
  }, [
    checkIn,
    checkOut,
    data,
    eventInfo,
    eventInfoLoading,
    featureFlagsLoading,
    getStringQueryParam,
    guestLinkUseRedirectService,
    isCheckInOrCheckoutValid,
    isPartnerHotelValidQueryLoading,
    redirectInProgress,
    shouldSendUsersToZentrumHubWhiteLabel,
    telemetry,
    zentrumHubId
  ]);

  return showNotFound ? <PageNotFound /> : null;
};
