import React from 'react';
import { Box, TextV2 } from '@withjoy/joykit';
import { styles, StyledAvatar, BatchCardContainer, StyledTextContainer } from './BatchCard.styles';
interface BatchCardProps {
  name: string;
  email: string;
  initials: string;
}

const BatchCard: React.FC<BatchCardProps> = ({ name, email, initials }) => {
  return (
    <BatchCardContainer __css={styles.batchCardContainer}>
      <Box paddingRight={4}>
        <StyledAvatar __css={styles.avatar}>
          <TextV2>{initials}</TextV2>
        </StyledAvatar>
      </Box>
      <StyledTextContainer __css={styles.textContainer}>
        <TextV2 typographyVariant={'label2'} truncate={true}>
          {name}
        </TextV2>
        <TextV2 typographyVariant={'label2'} truncate={true} __css={styles.email}>
          {email}
        </TextV2>
      </StyledTextContainer>
    </BatchCardContainer>
  );
};

export default BatchCard;
