import { Flex, Box, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { StarFilled } from '@withjoy/joykit/icons';
import { distanceInMiles } from '@shared/utils/distanceInMiles';

export const AccommodationTileSubtitle: React.FC<{
  starRating: Maybe<number>;
  venueLatitude: Maybe<number>;
  venueLongitude: Maybe<number>;
  accommodationLatitude: Maybe<number>;
  accommodationLongitude: Maybe<number>;
}> = ({ starRating, venueLatitude, venueLongitude, accommodationLatitude, accommodationLongitude }) => {
  const haveDistanceToVenue = venueLatitude && venueLongitude && accommodationLatitude && accommodationLongitude;
  const milesToVenue = distanceInMiles(venueLatitude || 0, venueLongitude || 0, accommodationLatitude || 0, accommodationLongitude || 0).toFixed(1);
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  if (!haveDistanceToVenue && !starRating) return null;

  return (
    <Flex flexDirection={'row'} paddingTop={1} justifyContent={'space-between'}>
      {haveDistanceToVenue && (
        <TextV2 typographyVariant={'body1'} fontFamily="Inter UI" textDecoration={'none !important'}>
          {haveDistanceToVenue ? accTrans.milesFromVenue({ miles: milesToVenue }) : ''}
        </TextV2>
      )}
      {starRating && (
        <Flex>
          <Box paddingRight={1}>
            <StarFilled size={16} />
          </Box>
          <TextV2 typographyVariant={'body1'} alignSelf={'center'} fontFamily="Inter UI">
            {starRating}
          </TextV2>
        </Flex>
      )}
    </Flex>
  );
};
