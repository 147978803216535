import { ModalProps, styled, StyleSystemProps } from '@withjoy/joykit';
import { Menu, MenuItemProps } from '@withjoy/joykit/components/Menu';

export const StyledMenuItem = styled(Menu.Item)``;
const menuItemStyles: StyleSystemProps = {
  color: 'linkText',
  borderBottom: '1px solid rgba(51,51,51,0.2)',
  _last: {
    border: '0px'
  },
  _hover: {
    backgroundColor: 'black',
    color: 'white'
  },
  _active: {
    backgroundColor: 'black',
    color: 'white'
  }
};

export const menuItemOverrides: NonNullable<MenuItemProps['overrides']> = {
  ListItem: {
    props: {
      padding: 0
    }
  }
};

const buttonStyles: StyleSystemProps = {
  paddingY: 4,
  paddingX: 5
};

export const styles = {
  menuitem: menuItemStyles,
  button: buttonStyles
};

export const dialogOverridesMobile: NonNullable<ModalProps['overrides']> = {
  Content: {
    props: {
      boxShadow: '0px -16px 60px -12px rgba(44, 41, 37, 0.25), 0px -8px 36px -18px rgba(0, 0, 0, 0.3)',
      borderRadius: '12px 12px 0px 0px'
    }
  },
  Header: {
    props: {
      _first: {
        paddingTop: '24px',
        paddingBottom: '24px'
      }
    }
  }
};

export const DrawerItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 13px;
  gap: 8px;
  border: 1px solid ${props => props.theme.colors.mono14};
  border-radius: 4px;
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  &:hover,
  &:active {
    border-color: black;
    background-color: ${props => props.theme.colors.mono4};
  }
`;

export const CloseItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 13px;
  gap: 8px;
  border: 0px;
  border-radius: 4px;
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  &:hover,
  &:active {
    border-color: black;
    background-color: ${props => props.theme.colors.mono4};
  }
`;
