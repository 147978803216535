/* istanbul ignore file */

import React, { useEffect, useState } from 'react';

export interface NoSsrProps {
  children: React.ReactNode;

  /**
   * A fallback component to be rendered by the server.
   */
  fallback?: React.ReactElement<unknown>;
}

/**
 * NoSsr wraps components that should not be rendered by the server when Server Side Rendering.
 */
const NoSsr: React.FC<NoSsrProps> = ({ children, fallback = null }) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (isMounted ? children : fallback) as React.ReactElement;
};

NoSsr.displayName = 'NoSsr';

export { NoSsr };
