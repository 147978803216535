import React, { useMemo } from 'react';
import { Link } from '@react-router';
import { useTranslation } from '@shared/core';
import { pxToRem } from '@withjoy/joykit/theme';
import { ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { animationTransitionExt } from '@shared/utils/animationTransition';
import { PlanningRoutes } from '@apps/admin/routes/Planning/Planning.routes';
import { NO_LEAD_BANNER_HEIGHT } from '@apps/guest/packages/layout-engine/widgets/Custom/Custom.constants';
import { useCustomPageTelemetry } from '@apps/guest/packages/layout-engine/widgets/Custom/Custom.telemetry';
import { isInIframe } from '@shared/utils/isInIframe';

const isPreviewing = isInIframe();

interface AdminLeadGenBannerProps {
  show: boolean;
  eventHandle: string;
  isBrannan: boolean;
}

export const AdminLeadGenBanner: React.FC<AdminLeadGenBannerProps> = ({ show, eventHandle, isBrannan }) => {
  const isMobile = useIsMobileScreen();
  const { leadGenBannerCtaClicked } = useCustomPageTelemetry();
  const { t2 } = useTranslation('guestSiteCustom');
  const transNoLeadBanner = t2('accommodations', 'leadGenBanner');

  const generatedHotelBlockUrl = useMemo(() => `/${eventHandle}/edit${PlanningRoutes.hotelblock}`, [eventHandle]);

  return (
    <Flex
      width="100%"
      backgroundColor="#000"
      justifyContent="center"
      paddingY={pxToRem(18)}
      paddingX={{ _: 6, sm2: 5 }}
      __css={{ position: 'absolute', width: '100%', zIndex: 1024, pointerEvents: isPreviewing ? 'none' : 'all' }}
      opacity={show ? 1 : 0}
      top={show || isBrannan ? 0 : `-${NO_LEAD_BANNER_HEIGHT}px`}
      transition={animationTransitionExt(
        { property: 'opacity', duration: '250ms', timingFunction: 'ease-in-out' },
        { property: 'top', duration: '250ms', timingFunction: 'ease-in-out' }
      )}
    >
      <Flex flexDirection="column" rowGap={pxToRem(10)}>
        <Flex columnGap={{ _: 5, sm2: 7 }} alignItems="center">
          <Flex flexDirection="column" rowGap={1}>
            <TextV2 wordBreak="break-word" typographyVariant="hed3" color="white">
              {transNoLeadBanner.title}
            </TextV2>
            {!isMobile && (
              <TextV2 wordBreak="break-word" typographyVariant="label2" color="white" maxWidth={pxToRem(331)}>
                {transNoLeadBanner.content}
              </TextV2>
            )}
          </Flex>
          <ButtonV2
            flex="100%"
            variant="solid"
            shape="rounded"
            color="mono14"
            backgroundColor="white"
            width={pxToRem(121)}
            maxWidth={pxToRem(121)}
            height={pxToRem(40)}
            _hover={{ backgroundColor: 'mono3', borderColor: 'mono12', borderWidth: '2px', color: 'mono12' }}
            _active={{ backgroundColor: 'mono2', borderColor: 'mono14', borderWidth: '2px', color: 'mono14' }}
            as={Link}
            to={generatedHotelBlockUrl}
            onClick={() => leadGenBannerCtaClicked()}
          >
            {transNoLeadBanner.cta}
          </ButtonV2>
        </Flex>
        {isMobile && (
          <TextV2 wordBreak="break-word" typographyVariant="label2" color="white">
            {transNoLeadBanner.content}
          </TextV2>
        )}
      </Flex>
    </Flex>
  );
};
