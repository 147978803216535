import React, { ReactNode, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useDisclosure, Box, TextV2, IconButton } from '@withjoy/joykit';
import { Close } from '@withjoy/joykit/icons';

export interface NotificationProps extends Readonly<{ children: ReactNode; handleOnClose?: () => void }> {}

const Notification: React.FC<NotificationProps> = ({ children, handleOnClose }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleClose = useCallback(() => {
    handleOnClose && handleOnClose();
    onClose();
  }, [onClose, handleOnClose]);

  const appRoot = document.getElementById('root');

  return isOpen && appRoot
    ? ReactDOM.createPortal(
        <Box
          display={'flex'}
          backgroundColor={'white'}
          width={'100%'}
          zIndex={3001}
          position={'fixed'}
          top={0}
          alignItems={'center'}
          justifyContent={'space-between'}
          paddingY={5}
          paddingLeft={[6, 7]}
        >
          <TextV2 typographyVariant={'button2'} color="mono14" textAlign={['left', 'center']}>
            {children}
          </TextV2>
          <IconButton
            color="mono14"
            aria-label={'Close'}
            backgroundColor={'white'}
            height={'24px'}
            _hover={{ backgroundColor: 'mono1', color: 'mono14' }}
            _active={{ backgroundColor: 'mono2', color: 'mono14' }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>,
        appRoot
      )
    : null;
};

Notification.displayName = 'Notification';

export { Notification };
