import { PopoverV2Props } from '@withjoy/joykit';

export const DISABLED_MENU_ITEM_PROPS: React.HTMLAttributes<'ul'> = {
  onClick: undefined,
  onMouseEnter: undefined,
  onMouseDown: undefined,
  onMouseLeave: undefined,
  tabIndex: -1
};

export const SUB_MENU_POPPER_OPTIONS: PopoverV2Props['popperOptions'] = {
  offset: {
    offset: [0, 20]
  }
};
