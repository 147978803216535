import 'intersection-observer';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { withWindow } from '../withWindow';
import { isEqual } from 'lodash-es';

export type OnIntersectFn = (isIntersecting: boolean, entry: IntersectionObserverEntry) => void;

export const useOnIntersectingChange = <T extends HTMLElement>(ref: RefObject<T>, callback: OnIntersectFn, intersectionOtions?: IntersectionObserverInit) => {
  const [storedIntersectionOptions, setstoredIntersectionOptions] = useState(intersectionOtions);

  useEffect(() => {
    if (!isEqual(storedIntersectionOptions, intersectionOtions)) {
      setstoredIntersectionOptions(intersectionOtions);
    }
  }, [intersectionOtions, storedIntersectionOptions]);

  const observer = useMemo(() => {
    return withWindow<null | IntersectionObserver>(() => {
      // polyfill is imported above
      // eslint-disable-next-line compat/compat
      return new IntersectionObserver(([entry]) => {
        callback(entry.isIntersecting, entry);
      }, storedIntersectionOptions);
    }, null);
  }, [callback, storedIntersectionOptions]);

  useEffect(() => {
    const node = ref.current;
    if (!observer) {
      return;
    }
    if (node) {
      observer.observe(node);
      return () => {
        observer.unobserve(node);
      };
    }

    return () => {};
  }, [ref, observer]);
};
