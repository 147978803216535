import React, { useMemo } from 'react';
import { Link } from '@react-router';
import { useTranslation } from '@shared/core';
import { Close } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { ButtonV2, TextV2, Flex, CloseButton } from '@withjoy/joykit';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { PlanningRoutes } from '@apps/admin/routes/Planning/Planning.routes';
import { drawerOverrides } from '@shared/components/ResponsiveDialog/ResponsiveDialog.styles';
import { useCustomPageTelemetry } from '@apps/guest/packages/layout-engine/widgets/Custom/Custom.telemetry';

interface AdminLeadGenDialogProps {
  showDialog: boolean;
  openCloseDialog: (show: boolean) => void;
  eventHandle: string;
}

const AdminLeadGenDialog: React.FC<AdminLeadGenDialogProps> = ({ showDialog, openCloseDialog, eventHandle }) => {
  const isMobile = useIsMobileScreen();
  const { leadGenDialogCtaClicked, leadGenDialogDismissClicked } = useCustomPageTelemetry();
  const { t2 } = useTranslation('guestSiteCustom');
  const transNoLeadDialog = t2('accommodations', 'leadGenDialog');

  const generatedHotelBlockUrl = useMemo(() => `/${eventHandle}/edit${PlanningRoutes.hotelblock}`, [eventHandle]);

  const handleClose = useEventCallback(() => {
    leadGenDialogDismissClicked();
    openCloseDialog(false);
  });

  return (
    <ResponsiveDialog
      dialogOverrides={{
        Body: { props: { padding: '0 !important' } },
        Content: { props: { width: ['540px'], paddingX: 8, paddingY: pxToRem(56) } }
      }}
      drawerOverrides={{
        ...drawerOverrides,
        Content: {
          props: {
            ...drawerOverrides.Content?.props,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            boxShadow: '0px -10px 27px -5px rgba(44, 41, 37, 0.25), 0px -7px 16px -8px rgba(0, 0, 0, 0.30)'
          }
        },
        Body: { props: { ...drawerOverrides.Body?.props, paddingBottom: 7, paddingTop: 10, paddingX: 7 } }
      }}
      disableCloseOnEscapeKeyClick={true}
      disableCloseOnOutsideClick={true}
      isOpen={showDialog}
      onClose={handleClose}
      hasCloseButton={false}
      hideDivider={true}
      removeBodyPadding={true}
    >
      <CloseButton
        overrides={{
          Root: {
            props: { position: 'absolute', top: [5, 0], right: [5, 0], padding: [pxToRem(10), 0], minWidth: 'auto', width: ['auto', pxToRem(56)], height: ['auto', pxToRem(56)] }
          }
        }}
        onClick={handleClose}
        aria-label={'close dialog'}
      >
        <Close size={isMobile ? 16 : 24} />
      </CloseButton>
      <Flex flexDirection="column" rowGap={[7, 8]} alignItems="center">
        <Flex flexDirection="column" rowGap={[3, 5]}>
          <TextV2
            typographyVariant="display1"
            fontWeight={700}
            fontSize={[pxToRem(26), pxToRem(32)]}
            lineHeight={[pxToRem(33.8), pxToRem(38.4)]}
            fontFamily="Inter UI"
            textTransform="none"
            textAlign="center"
          >
            {transNoLeadDialog.title}
          </TextV2>
          <TextV2
            wordBreak="break-word"
            typographyVariant="body2"
            fontSize={[pxToRem(16), pxToRem(18)]}
            lineHeight={[pxToRem(24), pxToRem(27)]}
            color="mono12"
            textAlign="center"
            textTransform="none"
            fontFamily="Inter UI"
          >
            {transNoLeadDialog.content}
          </TextV2>
        </Flex>
        <ButtonV2
          shape="rounded"
          intent="neutral"
          width="100%"
          fontFamily="Inter UI"
          fontWeight={600}
          as={Link}
          to={generatedHotelBlockUrl}
          onClick={() => leadGenDialogCtaClicked()}
        >
          {transNoLeadDialog.cta}
        </ButtonV2>
      </Flex>
    </ResponsiveDialog>
  );
};

AdminLeadGenDialog.displayName = 'AdminLeadGenDialog';

export { AdminLeadGenDialog };
