import { useEffect, useRef } from 'react';
import { useLocation } from '@react-router';
import { withWindow } from '@shared/utils/withWindow';

type ScrollToTopProps = Merge<
  ScrollToOptions,
  {
    behavior?: 'instant' | 'smooth' | 'auto';
  }
>;

export const ScrollToTop = (options: ScrollToTopProps = {}) => {
  const { pathname } = useLocation();
  const optionsRef = useRef(options);
  optionsRef.current = options;

  useEffect(() => {
    withWindow(window => {
      const { top = 0, left, behavior = 'smooth' } = optionsRef.current;
      // @ts-ignore
      window.scrollTo({ top, behavior, left });
    });
  }, [pathname]);

  return null;
};
