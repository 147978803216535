import { StyleSystemProps } from '@withjoy/joykit';

const actionContainer: StyleSystemProps = {
  width: '12rem',
  height: '8rem',
  padding: 6,
  borderRadius: 4,
  border: 'solid 2px',
  borderColor: 'linkText',
  _hover: {
    borderColor: 'linkHover',
    color: 'linkHover'
  },
  _active: {
    borderColor: 'linkActive',
    color: 'linkActive'
  },
  _notLast: {
    marginRight: 2
  }
};

export const styles = {
  actionContainer
};
