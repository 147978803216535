import { useEventNameMatch } from '@shared/components/AuthProvider/AuthProvider.utils';
import { get } from 'lodash-es';
import { useTranslation } from '@shared/core';
import {
  EventRSVPAttendanceStatus,
  EventScheduleItemStreamFragment,
  useGetEventCTAButtonsDataQuery,
  useGuestCanGetAccessEventByNameActionBarQuery,
  useLogoutEventSessionMutation
} from '@graphql/generated';
import { useUnlockDialogContext } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialogProvider';
import { performFullPageRedirect } from '@shared/utils/navigation';
import { useCallback, useRef, useState } from 'react';
import { useOnIntersectingChange } from '@shared/utils/hooks/useOnIntersectingChange';
import { isStartDaysBefore, isStartMinutesBefore } from '@apps/guest/packages/layout-engine/components/VirtualEventBlock/VirtualEventBlock.utils';
import { isPast } from 'date-fns';
import { useRouteMatch } from '@react-router';
import { useLayout } from '@apps/guest/packages/layout-engine/layouts/LayoutProvider';
import { useActionBarTelemetry } from './ActionBar.telemetry';
import { useCtaButtons } from '@apps/guest/packages/layout-engine/common/utils/useCtaButtons';
import { CtaButtonsSessionData } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons.types';

export const useActionBarController = () => {
  const { handleUnlockDialogOpen } = useUnlockDialogContext();
  const [isTriggerPoint, setIsTriggerPoint] = useState(false);
  const triggerPointRef = useRef(null);
  const nameMatchResult = useEventNameMatch();
  const eventHandle = get(nameMatchResult, 'eventName', '');
  const { data } = useGuestCanGetAccessEventByNameActionBarQuery({
    variables: { eventHandle },
    batchMode: 'fast',
    ssr: false
  });
  // Marking this query as `ssr: false` because any RSVP data query is proxied through app-server-api -- which takes long to resolve.
  const { data: sessionData } = useGetEventCTAButtonsDataQuery({
    variables: { name: eventHandle || '' },
    batchMode: 'fast',
    ssr: false
  });
  const [logoutEvent] = useLogoutEventSessionMutation({ variables: { eventId: data?.eventByName?.id } });
  const { getButtonsVisibility, getUnlockButtonText, getRSVPButtonText } = useCtaButtons();
  const nowDate = Date.now();
  const telemetry = useActionBarTelemetry();

  //Livestream variables parsing
  const streamNow = get(data, 'eventByName.info.schedule.virtualScheduleItemNow', null);
  const scheduleItems = get(data, 'eventByName.info.schedule.items', []);

  const streamToDisplay =
    streamNow ||
    scheduleItems.filter((item: EventScheduleItemStreamFragment) => !!item.virtualEventLink && isStartMinutesBefore(item.startTime?.milliseconds || 0, nowDate, 15))[0];

  const streamEvent = get(streamToDisplay, 'virtualEventLink', null);
  const streamStartTime = get(streamToDisplay, 'startTime', null);
  const streamStartTimeMs = get(streamToDisplay, 'startTime.milliseconds', 0);
  const streamEndTime = get(streamToDisplay, 'endTime', null);
  const streamEndTimeMs = get(streamToDisplay, 'endTime.milliseconds', 0);
  const resolvedEndTimeMs = streamEndTimeMs || streamStartTimeMs + 1000 * 60 * 60;
  const isEventPast = isPast(resolvedEndTimeMs);

  //Rest Event variables parsing
  const date = get(data, 'eventByName.info.dateV0918.dateString', '').split(' ').slice(0, -2).join(' ');
  const dateInMilliseconds = get(data, 'eventByName.info.dateV0918.milliseconds', null);
  const hasUnlocked = sessionData?.eventByName?.activeSession.hasUnlocked ?? false;
  const guestName = sessionData?.eventByName?.activeSession.person?.fullName ?? '';
  const rsvpIsEnabled = sessionData?.eventByName?.activeSession.eventCTA.rsvpIsEnabled ?? false;
  const rsvpAttendanceStatus = sessionData?.eventByName?.activeSession?.eventCTA?.rsvpAttendanceStatus || EventRSVPAttendanceStatus.notAttending;
  const scheduleHasInviteOnlyItems = sessionData?.eventByName?.activeSession.eventCTA.scheduleHasInviteOnlyItems ?? false;
  const rsvpLabel = sessionData?.eventByName?.activeSession.eventCTA.label ?? '';
  const isReminderWarning = isStartDaysBefore(dateInMilliseconds, nowDate, 14); //when the event date is 2 weeks or less away

  //Text Translation
  const { t, t2 } = useTranslation('actionBar');
  const dateText = t('text', 'date')({ date });
  const personalizedText = t('text', 'personalizedText')({ guestName });
  const { buttonNotYou } = t2('buttons');

  const onLogoutHandle = () => {
    logoutEvent({ refetchQueries: () => ['GetEventSession', 'GetGuestSiteSchedule', 'GetEventCTAButtonsData'] })
      .then(() => telemetry.onLogoutClicked())
      .catch(err => telemetry.trackError('ActionBarLogout', err));
  };

  const onVirtualEventBlockClicked = () => {
    telemetry.onVirtualEventClicked();
  };

  const onRsvpHandle = () => {
    performFullPageRedirect(`/${eventHandle}/rsvp`);
    telemetry.onRsvpHandle();
  };

  const onIntersectingTriggerPointEl = useCallback(
    (isIntersecting: boolean) => {
      if (isIntersecting && triggerPointRef.current) {
        setIsTriggerPoint(true);
      } else {
        setIsTriggerPoint(false);
      }
    },
    [setIsTriggerPoint]
  );

  useOnIntersectingChange(triggerPointRef, onIntersectingTriggerPointEl);

  const mainText = () => {
    if (guestName) return personalizedText;
    else if (date && data?.eventByName?.info.eventDateIsFinalized) return dateText;
    return undefined;
  };

  //General conditions
  const isStreamDisplayed = !!streamToDisplay && !isEventPast;
  const event: CtaButtonsSessionData = sessionData?.eventByName ?? {};
  const { welcomePage, storyPage, tidbitsPage, schedulePage, travelPage, faqPage, weddingPage, registryPage, momentsPage, rsvpPage, customPages } = event;
  const pages = [welcomePage, storyPage, tidbitsPage, schedulePage, travelPage, faqPage, weddingPage, registryPage, momentsPage, rsvpPage, ...(customPages ?? [])];
  const rsvpIsPasswordProtected = rsvpPage?.private;
  const { shouldDisplayUnlockButton, shouldDisplayRSVPButton } = getButtonsVisibility(
    pages,
    welcomePage,
    schedulePage,
    rsvpIsEnabled,
    rsvpIsPasswordProtected,
    scheduleHasInviteOnlyItems,
    hasUnlocked
  );
  const unlockButtonText = getUnlockButtonText(hasUnlocked);
  const rsvpButtonText = getRSVPButtonText(rsvpAttendanceStatus, rsvpLabel);

  //Display on Brannan Welcome only when Live
  const { layout } = useLayout();
  const isWelcomePage = !!useRouteMatch('/:eventHandle/welcome');
  const isNotLiveOnBrannanWelcome = layout === 'brannan' && isWelcomePage && !streamNow;

  return {
    isActionBarHidden: (!shouldDisplayUnlockButton && !shouldDisplayRSVPButton && !isStreamDisplayed) || isNotLiveOnBrannanWelcome,
    isTriggerPoint,
    triggerPointRef,
    shouldDisplayRSVPButton,
    isReminderWarning,
    onUnlockDialogHandle: () => handleUnlockDialogOpen(),
    buttonNotYou,
    onLogoutHandle,
    guestName,
    shouldDisplayUnlockButton,
    rsvpButtonText,
    onRsvpHandle,
    mainText: mainText(),
    isStreamDisplayed,
    streamStartTime,
    streamEndTime,
    streamEvent,
    streamNow,
    onVirtualEventBlockClicked,
    unlockButtonText
  };
};
