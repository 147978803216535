import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { MapMarker } from '@withjoy/joykit/icons';
import { AccommodationCombinedType } from '../../Accommodation.types';

export const AccommodationTileAddress: React.FC<{ type: AccommodationCombinedType; address?: string | null }> = ({ address, type }) => {
  if (type === AccommodationCombinedType.Roomblock || !address) return null;

  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Flex>
        <MapMarker size={32} />
      </Flex>
      <Flex flexDirection={'column'} justifyContent={'center'}>
        <TextV2 fontFamily={'Inter UI'} fontSize={15} lineHeight={'22.5px'} letterSpacing={'-0.15px'} paddingBottom={1}>
          {address}
        </TextV2>
      </Flex>
    </Flex>
  );
};
