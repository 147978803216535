import { ButtonV2, Flex, styled } from '@withjoy/joykit';
import { UnlockDialog } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialog';

const buttonStyle = `
  color: inherit;
  border-color: inherit;

  :hover {
    color: inherit;
    border-color: inherit;
    background-color: inherit;
    opacity: 0.8;
  }

  :active {
    color: inherit;
    border-color: inherit;
    background-color: inherit;
  }
`;

export const StyledContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  max-width: max-content;
`;

export const StyledButton = styled(ButtonV2)`
  width: 100%;
  ${buttonStyle}
`;

export const StyledUnlockButton = styled(UnlockDialog.TriggerButton)`
  ${buttonStyle}
`;
