import React from 'react';
import { animationTransition } from '../../utils';
import { styled, css } from '@withjoy/joykit';
import { TextV1 } from '../TextV1';

const Label: React.FC<{ className?: string; id?: string; disabled?: boolean; hideLabel?: boolean; isAlignedLeft: boolean }> = props => (
  <label className={props.className} htmlFor={props.id}>
    {props.children}
  </label>
);

export const StyledLabelText = styled(TextV1)``;

export const StyledLabel = styled(Label)`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: ${props => (props.isAlignedLeft ? 'flex-start' : 'space-between')};
  position: relative;
  transition: ${animationTransition('all')};

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 1px;
    height: 1px;
    z-index: -1;
  }

  ${props => {
    if (props.hideLabel) {
      return css`
        & ${StyledLabelText} {
          position: absolute;
          overflow: hidden;
          clip: rect(0 0 0 0);
          height: 1px;
          width: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
        }
      `;
    }

    return css`
      > :not(:last-child):not(input) {
        margin-right: ${props.theme.space[3]};
      }
    `;
  }}

  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
          color: #bbbbbb;
        `
      : undefined}
`;
