/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Checkmark',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.3979 4.55134C21.6459 4.77111 21.6688 5.15032 21.4491 5.39833L9.92906 18.3983C9.82288 18.5181 9.67323 18.5906 9.51338 18.5995C9.35354 18.6084 9.19677 18.553 9.07794 18.4458L2.59794 12.5958C2.35197 12.3737 2.33259 11.9943 2.55464 11.7483C2.77669 11.5024 3.1561 11.483 3.40206 11.705L9.43257 17.1492L20.5509 4.60247C20.7707 4.35446 21.1499 4.33157 21.3979 4.55134Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
