import React, { useCallback } from 'react';
import { DialogContainer, DialogBody } from './FocusPointDialog.styles';
import { JoyKitThemeProvider } from '@withjoy/joykit';
import { EventPageType } from '@graphql/generated';
import { useFocusPointController } from './FocusPointDialog.controller';
import { PagePhotoSettings } from '@shared/components/FocusPointEditor';
import { useApolloClient } from '@apollo/client';

export interface FocusPointDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FocusPointDialog: React.FC<FocusPointDialogProps> = ({ isOpen, onClose }) => {
  const { dialogRef, isMobileScreen, isTabletScreen, photo, resetFocusPoint, page, pageId, eventId, previewPosition, reflectPhotoChange } = useFocusPointController();

  const closeDialog = useCallback(() => {
    resetFocusPoint();
    onClose();
  }, [onClose, resetFocusPoint]);

  const client = useApolloClient();

  const onDeletePhotoSuccess = (derivedPageIdToDelete: string, derivedPageType: EventPageType | '', actualPageId: string, actualPageType: EventPageType | undefined) => {
    client.cache.modify({
      id: client.cache.identify({ __typename: 'Event', id: eventId }),
      fields: {
        pages(
          existingPages: Readonly<Object> &
            {
              __ref: string;
            }[] = [],
          { readField }
        ) {
          const updatedPages = existingPages.map(pageRef => {
            const pageId = readField('id', pageRef);
            if (pageId === actualPageId || pageId === derivedPageIdToDelete) {
              client.cache.modify({
                id: pageRef.__ref,
                fields: {
                  photo() {
                    return null;
                  }
                }
              });
            }

            return pageRef;
          });
          return updatedPages;
        }
      }
    });
    if (derivedPageType === EventPageType.welcome) {
      // on deleteing the welcome page photo some other fields values are getting updated in BE, so refetching the queries
      client.refetchQueries({ include: ['EventWebsiteGuest'] });
    }
  };

  const handleFileStackClose = useCallback(
    (hasImageUploaded: boolean) => {
      if (!hasImageUploaded) {
        closeDialog();
      }
    },
    [closeDialog]
  );

  return (
    <JoyKitThemeProvider>
      <DialogContainer
        id="FocusPointDialog"
        ref={dialogRef}
        overrides={{
          Content: { props: { width: ['100%', '480px'] } },
          Body: { props: { paddingX: 8, paddingY: 8 } }
        }}
        // transition={transition}
        onClose={closeDialog}
        isOpen={isOpen}
        isDraggable={!isMobileScreen && !isTabletScreen}
        disableFocusLock={true}
      >
        <DialogBody>
          <PagePhotoSettings
            isOpen={isOpen}
            handleOnClose={closeDialog}
            handleOnCancel={closeDialog}
            imageData={photo}
            pageType={page || EventPageType.welcome}
            pageId={pageId}
            showNavigateBack={false}
            onPhotoDeleteSuccess={onDeletePhotoSuccess}
            telemetryCategory="wedding"
            onFocusPointChange={previewPosition}
            onFocusUpdateSuccess={(_, imageInfo) => reflectPhotoChange(imageInfo)}
            onFileStackClose={handleFileStackClose}
          />
        </DialogBody>
      </DialogContainer>
    </JoyKitThemeProvider>
  );
};

FocusPointDialog.displayName = 'FocusPointDialog';
