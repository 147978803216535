import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { CurrencyFormatter } from '@shared/utils/currency';
import { times } from 'lodash-es';

const WILDEST_DREAM: { [n: number]: number } = {
  10: 4077,
  20: 2953,
  30: 2441,
  40: 2017,
  50: 1578,
  60: 1434,
  70: 1309,
  80: 1229,
  90: 1146,
  100: 1073,
  110: 1054,
  120: 1026,
  130: 990,
  140: 977,
  150: 968,
  160: 959,
  170: 950,
  180: 941,
  190: 933,
  200: 914,
  210: 906,
  220: 906,
  230: 906,
  240: 906,
  250: 896,
  260: 896,
  270: 895,
  280: 895,
  290: 895,
  300: 895
};

const TOGETHER: { [n: number]: number } = {
  10: 2519,
  20: 1619,
  30: 1302,
  40: 1043,
  50: 932,
  60: 865,
  70: 816,
  80: 788,
  90: 759,
  100: 740,
  110: 732,
  120: 732,
  130: 722,
  140: 722,
  150: 722,
  160: 688,
  170: 688,
  180: 688,
  190: 688,
  200: 679,
  210: 679,
  220: 679,
  230: 679,
  240: 679,
  250: 669,
  260: 669,
  270: 669,
  280: 669,
  290: 669,
  300: 669
};

const MILK_AND_HONEY: { [n: number]: number } = {
  10: 3520,
  20: 2527,
  30: 2083,
  40: 1655,
  50: 1441,
  60: 1299,
  70: 1151,
  80: 1088,
  90: 1002,
  100: 933,
  110: 900,
  120: 876,
  130: 849,
  140: 832,
  150: 830,
  160: 828,
  170: 826,
  180: 824,
  190: 822,
  200: 809,
  210: 808,
  220: 807,
  230: 806,
  240: 805,
  250: 799,
  260: 798,
  270: 798,
  280: 797,
  290: 796,
  300: 796
};

const SAGE: { [n: number]: number } = {
  10: 4382,
  20: 2899,
  30: 2316,
  40: 1914,
  50: 1650,
  60: 1509,
  70: 1378,
  80: 1325,
  90: 1281,
  100: 1220,
  110: 1189,
  120: 1161,
  130: 1140,
  140: 1106,
  150: 1087,
  160: 1081,
  170: 1071,
  180: 1054,
  190: 1049,
  200: 1038,
  210: 1023,
  220: 1020,
  230: 1017,
  240: 1003,
  250: 1000,
  260: 998,
  270: 996,
  280: 982,
  290: 981,
  300: 979
};

const IVORY: { [n: number]: number } = {
  10: 4983,
  20: 3288,
  30: 2583,
  40: 2081,
  50: 1793,
  60: 1645,
  70: 1489,
  80: 1416,
  90: 1323,
  100: 1245,
  110: 1195,
  120: 1152,
  130: 1123,
  140: 1095,
  150: 1087,
  160: 1081,
  170: 1076,
  180: 1071,
  190: 1066,
  200: 1056,
  210: 1052,
  220: 1049,
  230: 1046,
  240: 1043,
  250: 1041,
  260: 1038,
  270: 1034,
  280: 1032,
  290: 1030,
  300: 1029
};

const SOFIA: { [n: number]: number } = {
  10: 4402,
  20: 2678,
  30: 2124,
  40: 1677,
  50: 1419,
  60: 1272,
  70: 1154,
  80: 1095,
  90: 1009,
  100: 964,
  110: 918,
  120: 869,
  130: 858,
  140: 850,
  150: 842,
  160: 829,
  170: 824,
  180: 818,
  190: 793,
  200: 790,
  210: 785,
  220: 782,
  230: 779,
  240: 777,
  250: 774,
  260: 768,
  270: 766,
  280: 760,
  290: 759,
  300: 757
};

const HARMONY_GATEFOLD: { [n: number]: number } = {
  10: 1127,
  20: 859,
  30: 754,
  40: 668,
  50: 633,
  60: 633,
  70: 633,
  80: 633,
  90: 633,
  100: 633,
  110: 625,
  120: 625,
  130: 625,
  140: 625,
  150: 625,
  160: 591,
  170: 591,
  180: 591,
  190: 591,
  200: 591,
  210: 591,
  220: 591,
  230: 591,
  240: 591,
  250: 591,
  260: 591,
  270: 591,
  280: 591,
  290: 591,
  300: 591
};

const HARMONY: { [n: number]: number } = {
  10: 4937,
  20: 3300,
  30: 2594,
  40: 2092,
  50: 1805,
  60: 1661,
  70: 1512,
  80: 1442,
  90: 1349,
  100: 1271,
  110: 1221,
  120: 1178,
  130: 1149,
  140: 1121,
  150: 1113,
  160: 1107,
  170: 1102,
  180: 1097,
  190: 1092,
  200: 1082,
  210: 1078,
  220: 1075,
  230: 1072,
  240: 1069,
  250: 1067,
  260: 1064,
  270: 1060,
  280: 1058,
  290: 1056,
  300: 1055
};

const EMPIRE_NOW: { [n: number]: number } = {
  10: 1512,
  20: 1029,
  30: 864,
  40: 688,
  50: 645,
  60: 587,
  70: 533,
  80: 523,
  90: 484,
  100: 476,
  110: 439,
  120: 414,
  130: 401,
  140: 387,
  150: 382,
  160: 378,
  170: 373,
  180: 371,
  190: 369,
  200: 359,
  210: 358,
  220: 357,
  230: 356,
  240: 355,
  250: 354,
  260: 353,
  270: 352,
  280: 352,
  290: 351,
  300: 350
};

const BYRON_GATEFOLD: { [n: number]: number } = {
  10: 1127,
  20: 859,
  30: 754,
  40: 668,
  50: 633,
  60: 633,
  70: 633,
  80: 633,
  90: 633,
  100: 633,
  110: 625,
  120: 625,
  130: 625,
  140: 625,
  150: 625,
  160: 591,
  170: 591,
  180: 591,
  190: 591,
  200: 591,
  210: 591,
  220: 591,
  230: 591,
  240: 591,
  250: 591,
  260: 591,
  270: 591,
  280: 591,
  290: 591,
  300: 591
};

const JARDIN: { [n: number]: number } = {
  10: 1127,
  20: 859,
  30: 754,
  40: 668,
  50: 633,
  60: 633,
  70: 633,
  80: 633,
  90: 633,
  100: 633,
  110: 625,
  120: 625,
  130: 625,
  140: 625,
  150: 625,
  160: 591,
  170: 591,
  180: 591,
  190: 591,
  200: 591,
  210: 591,
  220: 591,
  230: 591,
  240: 591,
  250: 591,
  260: 591,
  270: 591,
  280: 591,
  290: 591,
  300: 591
};

const CELADON: { [n: number]: number } = {
  10: 1723,
  20: 1397,
  30: 1190,
  40: 944,
  50: 823,
  60: 817,
  70: 786,
  80: 765,
  90: 756,
  100: 737,
  110: 728,
  120: 718,
  130: 708,
  140: 699,
  150: 690,
  160: 690,
  170: 690,
  180: 680,
  190: 681,
  200: 680,
  210: 671,
  220: 670,
  230: 671,
  240: 662,
  250: 662,
  260: 661,
  270: 661,
  280: 652,
  290: 652,
  300: 651
};

const WILD_MEADOW: { [n: number]: number } = {
  10: 3070,
  20: 1980,
  30: 1601,
  40: 1291,
  50: 1156,
  60: 1069,
  70: 1006,
  80: 969,
  90: 932,
  100: 907,
  110: 899,
  120: 899,
  130: 886,
  140: 886,
  150: 886,
  160: 852,
  170: 852,
  180: 852,
  190: 852,
  200: 840,
  210: 840,
  220: 840,
  230: 840,
  240: 840,
  250: 827,
  260: 827,
  270: 827,
  280: 827,
  290: 827,
  300: 827
};

const EDEN: { [n: number]: number } = {
  10: 1322,
  20: 1054,
  30: 949,
  40: 863,
  50: 828,
  60: 828,
  70: 828,
  80: 828,
  90: 828,
  100: 828,
  110: 820,
  120: 820,
  130: 820,
  140: 820,
  150: 820,
  160: 786,
  170: 786,
  180: 786,
  190: 786,
  200: 786,
  210: 786,
  220: 786,
  230: 786,
  240: 786,
  250: 786,
  260: 786,
  270: 786,
  280: 786,
  290: 786,
  300: 786
};

const LUMINOUS: { [n: number]: number } = {
  10: 2912,
  20: 2215,
  30: 1787,
  40: 1509,
  50: 1266,
  60: 1151,
  70: 1066,
  80: 1028,
  90: 952,
  100: 915,
  110: 897,
  120: 874,
  130: 865,
  140: 848,
  150: 838,
  160: 829,
  170: 819,
  180: 809,
  190: 800,
  200: 790,
  210: 780,
  220: 771,
  230: 761,
  240: 751,
  250: 742,
  260: 732,
  270: 721,
  280: 711,
  290: 702,
  300: 692
};

const GRACE_TRIFOLD: { [n: number]: number } = {
  10: 1270,
  20: 1116,
  30: 1011,
  40: 1008,
  50: 878,
  60: 821,
  70: 756,
  80: 722,
  90: 722,
  100: 722,
  110: 697,
  120: 697,
  130: 693,
  140: 683,
  150: 674,
  160: 666,
  170: 658,
  180: 651,
  190: 648,
  200: 642,
  210: 639,
  220: 634,
  230: 630,
  240: 626,
  250: 625,
  260: 622,
  270: 620,
  280: 617,
  290: 615,
  300: 614
};

const FLUTTER: { [n: number]: number } = {
  10: 4492,
  20: 3164,
  30: 2599,
  40: 2230,
  50: 1991,
  60: 1876,
  70: 1699,
  80: 1600,
  90: 1506,
  100: 1414,
  110: 1354,
  120: 1306,
  130: 1259,
  140: 1232,
  150: 1206,
  160: 1201,
  170: 1197,
  180: 1173,
  190: 1170,
  200: 1167,
  210: 1143,
  220: 1141,
  230: 1139,
  240: 1116,
  250: 1114,
  260: 1112,
  270: 1111,
  280: 1088,
  290: 1087,
  300: 1086
};

const KOFFEE: { [n: number]: number } = {
  10: 4259,
  20: 3062,
  30: 2556,
  40: 2117,
  50: 1866,
  60: 1719,
  70: 1548,
  80: 1472,
  90: 1385,
  100: 1302,
  110: 1255,
  120: 1219,
  130: 1184,
  140: 1155,
  150: 1142,
  160: 1138,
  170: 1134,
  180: 1121,
  190: 1118,
  200: 1108,
  210: 1097,
  220: 1095,
  230: 1093,
  240: 1082,
  250: 1081,
  260: 1079,
  270: 1078,
  280: 1067,
  290: 1066,
  300: 1065
};

const GRACE: { [n: number]: number } = {
  10: 5610,
  20: 3571,
  30: 2797,
  40: 2335,
  50: 2031,
  60: 1878,
  70: 1740,
  80: 1679,
  90: 1619,
  100: 1544,
  110: 1504,
  120: 1462,
  130: 1440,
  140: 1397,
  150: 1378,
  160: 1371,
  170: 1365,
  180: 1349,
  190: 1344,
  200: 1333,
  210: 1318,
  220: 1314,
  230: 1311,
  240: 1297,
  250: 1294,
  260: 1291,
  270: 1286,
  280: 1273,
  290: 1271,
  300: 1269
};

const ROSE_ET_GRIS: { [n: number]: number } = {
  10: 5933,
  20: 3845,
  30: 2995,
  40: 2337,
  50: 1946,
  60: 1704,
  70: 1552,
  80: 1468,
  90: 1345,
  100: 1275,
  110: 1250,
  120: 1220,
  130: 1199,
  140: 1182,
  150: 1172,
  160: 1162,
  170: 1147,
  180: 1136,
  190: 1127,
  200: 1104,
  210: 1094,
  220: 1085,
  230: 1075,
  240: 1065,
  250: 1042,
  260: 1033,
  270: 1021,
  280: 1012,
  290: 1002,
  300: 992
};

const LUNA: { [n: number]: number } = {
  10: 1791,
  20: 1060,
  30: 771,
  40: 613,
  50: 504,
  60: 471,
  70: 444,
  80: 423,
  90: 399,
  100: 379,
  110: 369,
  120: 351,
  130: 347,
  140: 337,
  150: 333,
  160: 330,
  170: 328,
  180: 325,
  190: 323,
  200: 321,
  210: 320,
  220: 318,
  230: 317,
  240: 316,
  250: 315,
  260: 314,
  270: 311,
  280: 310,
  290: 309,
  300: 309
};

const MINIMALISME: { [n: number]: number } = {
  10: 1791,
  20: 1060,
  30: 771,
  40: 613,
  50: 504,
  60: 471,
  70: 444,
  80: 423,
  90: 399,
  100: 379,
  110: 369,
  120: 351,
  130: 347,
  140: 337,
  150: 333,
  160: 330,
  170: 328,
  180: 325,
  190: 323,
  200: 321,
  210: 320,
  220: 318,
  230: 317,
  240: 316,
  250: 315,
  260: 314,
  270: 311,
  280: 310,
  290: 309,
  300: 309
};

const SUMMER_MONTAGE: { [n: number]: number } = {
  '10': 1791,
  '20': 1060,
  '30': 771,
  '40': 613,
  '50': 504,
  '60': 471,
  '70': 444,
  '80': 423,
  '90': 399,
  '100': 379,
  '110': 369,
  '120': 351,
  '130': 347,
  '140': 337,
  '150': 333,
  '160': 330,
  '170': 328,
  '180': 325,
  '190': 323,
  '200': 321,
  '210': 320,
  '220': 318,
  '230': 317,
  '240': 316,
  '250': 315,
  '260': 314,
  '270': 311,
  '280': 310,
  '290': 309,
  '300': 309
};

const SUMMER_NIGHTS: { [n: number]: number } = {
  '10': 1791,
  '20': 1060,
  '30': 771,
  '40': 613,
  '50': 504,
  '60': 471,
  '70': 444,
  '80': 423,
  '90': 399,
  '100': 379,
  '110': 369,
  '120': 351,
  '130': 347,
  '140': 337,
  '150': 333,
  '160': 330,
  '170': 328,
  '180': 325,
  '190': 323,
  '200': 321,
  '210': 320,
  '220': 318,
  '230': 317,
  '240': 316,
  '250': 315,
  '260': 314,
  '270': 311,
  '280': 310,
  '290': 309,
  '300': 309
};

const LINEAR: { [n: number]: number } = {
  '10': 1053,
  '20': 852,
  '30': 708,
  '40': 564,
  '50': 483,
  '60': 425,
  '70': 387,
  '80': 390,
  '90': 384,
  '100': 368,
  '110': 368,
  '120': 368,
  '130': 368,
  '140': 355,
  '150': 355,
  '160': 355,
  '170': 355,
  '180': 355,
  '190': 355,
  '200': 348,
  '210': 348,
  '220': 348,
  '230': 348,
  '240': 348,
  '250': 348,
  '260': 348,
  '270': 348,
  '280': 348,
  '290': 0,
  '300': 0
};

const POLAROID: { [n: number]: number } = {
  '10': 835,
  '20': 537,
  '30': 403,
  '40': 326,
  '50': 269,
  '60': 262,
  '70': 254,
  '80': 247,
  '90': 236,
  '100': 226,
  '110': 223,
  '120': 213,
  '130': 213,
  '140': 208,
  '150': 208,
  '160': 208,
  '170': 208,
  '180': 208,
  '190': 208,
  '200': 208,
  '210': 208,
  '220': 208,
  '230': 208,
  '240': 208,
  '250': 208,
  '260': 208,
  '270': 207,
  '280': 207,
  '290': 207,
  '300': 207
};

const DELILAH: { [n: number]: number } = {
  '10': 1495,
  '20': 1027,
  '30': 794,
  '40': 606,
  '50': 498,
  '60': 423,
  '70': 372,
  '80': 366,
  '90': 351,
  '100': 330,
  '110': 326,
  '120': 322,
  '130': 318,
  '140': 302,
  '150': 299,
  '160': 297,
  '170': 295,
  '180': 293,
  '190': 292,
  '200': 283,
  '210': 282,
  '220': 281,
  '230': 280,
  '240': 279,
  '250': 278,
  '260': 277,
  '270': 276,
  '280': 276,
  '290': 275,
  '300': 274
};

const FLOWERET: { [n: number]: number } = {
  '10': 1495,
  '20': 1027,
  '30': 794,
  '40': 606,
  '50': 498,
  '60': 423,
  '70': 372,
  '80': 366,
  '90': 351,
  '100': 330,
  '110': 326,
  '120': 322,
  '130': 318,
  '140': 302,
  '150': 299,
  '160': 297,
  '170': 295,
  '180': 293,
  '190': 292,
  '200': 283,
  '210': 282,
  '220': 281,
  '230': 280,
  '240': 279,
  '250': 278,
  '260': 277,
  '270': 276,
  '280': 276,
  '290': 275,
  '300': 274
};

const MINIMALIST_BLACK_WHITE: { [n: number]: number } = {
  '10': 1203,
  '20': 1063,
  '30': 837,
  '40': 724,
  '50': 601,
  '60': 523,
  '70': 479,
  '80': 453,
  '90': 427,
  '100': 393,
  '110': 349,
  '120': 340,
  '130': 331,
  '140': 322,
  '150': 314,
  '160': 305,
  '170': 296,
  '180': 287,
  '190': 279,
  '200': 270,
  '210': 261,
  '220': 261,
  '230': 261,
  '240': 261,
  '250': 261,
  '260': 262,
  '270': 261,
  '280': 261,
  '290': 261,
  '300': 261
};

const BYRON: { [n: number]: number } = {
  '10': 1203,
  '20': 1063,
  '30': 837,
  '40': 724,
  '50': 601,
  '60': 523,
  '70': 479,
  '80': 453,
  '90': 427,
  '100': 393,
  '110': 349,
  '120': 340,
  '130': 331,
  '140': 322,
  '150': 314,
  '160': 305,
  '170': 296,
  '180': 287,
  '190': 279,
  '200': 270,
  '210': 261,
  '220': 261,
  '230': 261,
  '240': 261,
  '250': 261,
  '260': 262,
  '270': 261,
  '280': 261,
  '290': 261,
  '300': 261
};

const CLAIR_DE_LUNE: { [n: number]: number } = {
  '10': 1444,
  '20': 944,
  '30': 705,
  '40': 542,
  '50': 451,
  '60': 411,
  '70': 378,
  '80': 349,
  '90': 341,
  '100': 327,
  '110': 313,
  '120': 299,
  '130': 299,
  '140': 299,
  '150': 299,
  '160': 299,
  '170': 288,
  '180': 286,
  '190': 286,
  '200': 286,
  '210': 286,
  '220': 286,
  '230': 286,
  '240': 286,
  '250': 286,
  '260': 286,
  '270': 286,
  '280': 286,
  '290': 286,
  '300': 286
};

export const formatter = new CurrencyFormatter({ locale: 'en', defaultCurrency: 'USD' });

export type QuantityPriceConfig = {
  quantity: number;
  totalPriceInMinorUnits: number;
  individualPriceString: string;
  totalPriceString: string;
};

const _generatePriceConfig = ({ getIndividualPriceByQuantityInMinorUnits }: { getIndividualPriceByQuantityInMinorUnits: (quantity: number) => number }) => {
  const configByQuantity: Record<number, QuantityPriceConfig> = {};
  times(300 / 10, num => {
    const quantity = (num + 1) * 10;
    const individPriceInMinorUnits = getIndividualPriceByQuantityInMinorUnits(quantity);
    const totalPriceInMinorUnits = quantity * individPriceInMinorUnits;

    const config: QuantityPriceConfig = {
      quantity,
      totalPriceInMinorUnits,
      individualPriceString: formatter.formatCurrency(individPriceInMinorUnits / 100, { form: 'auto' }),
      totalPriceString: formatter.formatCurrency(totalPriceInMinorUnits / 100, { form: 'auto' })
    };

    configByQuantity[quantity] = config;
  });

  return configByQuantity;
};

export type ConciergeServiceCategory = StationeryTemplateCategoryEnum.invitation | StationeryTemplateCategoryEnum.saveTheDate;

type PriceConfig = Record<
  ConciergeServiceCategory,
  {
    quantity: {
      [k: string]: Record<
        number,
        {
          quantity: number;
          totalPriceInMinorUnits: number;
          individualPriceString: string;
          totalPriceString: string;
        }
      >;
    };
    upgrades?: {
      [k: string]: Record<
        number,
        {
          quantity: number;
          totalPriceInMinorUnits: number;
          individualPriceString: string;
          totalPriceString: string;
        }
      >;
    };
  }
>;

export const PRICE_CONFIG: PriceConfig = {
  [StationeryTemplateCategoryEnum.invitation]: {
    quantity: {
      wildestDream: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return WILDEST_DREAM[quantity];
        }
      }),
      together: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return TOGETHER[quantity];
        }
      }),
      sofia: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return SOFIA[quantity];
        }
      }),
      sage: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return SAGE[quantity];
        }
      }),
      milkAndHoney: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return MILK_AND_HONEY[quantity];
        }
      }),
      jardin: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return JARDIN[quantity];
        }
      }),
      ivory: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return IVORY[quantity];
        }
      }),
      harmony: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return HARMONY[quantity];
        }
      }),
      harmonyGatefold: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return HARMONY_GATEFOLD[quantity];
        }
      }),
      empireNow: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return EMPIRE_NOW[quantity];
        }
      }),
      byronGatefold: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return BYRON_GATEFOLD[quantity];
        }
      }),
      celadon: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return CELADON[quantity];
        }
      }),
      wildMeadow: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return WILD_MEADOW[quantity];
        }
      }),
      eden: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return EDEN[quantity];
        }
      }),
      luminous: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return LUMINOUS[quantity];
        }
      }),
      graceTrifold: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return GRACE_TRIFOLD[quantity];
        }
      }),
      flutter: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return FLUTTER[quantity];
        }
      }),
      koffee: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return KOFFEE[quantity];
        }
      }),
      grace: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return GRACE[quantity];
        }
      }),
      roseEtGris: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return ROSE_ET_GRIS[quantity];
        }
      })
    }
  },
  [StationeryTemplateCategoryEnum.saveTheDate]: {
    upgrades: {
      magnetic: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: () => {
          return 99;
        }
      })
    },
    quantity: {
      luna: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return LUNA[quantity];
        }
      }),
      minimalisme: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return MINIMALISME[quantity];
        }
      }),
      summerMontage: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return SUMMER_MONTAGE[quantity];
        }
      }),
      summerNights: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return SUMMER_NIGHTS[quantity];
        }
      }),
      linear: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return LINEAR[quantity];
        }
      }),
      polaroid: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return POLAROID[quantity];
        }
      }),
      delilah: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return DELILAH[quantity];
        }
      }),
      floweret: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return FLOWERET[quantity];
        }
      }),
      minimalistBlackWhite: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return MINIMALIST_BLACK_WHITE[quantity];
        }
      }),
      byron: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return BYRON[quantity];
        }
      }),
      clairDeLune: _generatePriceConfig({
        getIndividualPriceByQuantityInMinorUnits: quantity => {
          return CLAIR_DE_LUNE[quantity];
        }
      })
    }
  }
};
