import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import warning from 'warning';
import { DateSelection, DateRangeSelection, RangeDisplayValue, DatePickerProps } from './DatePicker.types';

/**
 * Get the `start` and `end` date from the provided value - even when isRange = false.
 */
export const extractDatesFromValue = (value: DateSelection | DateRangeSelection) => {
  const [start, end] = Array.isArray(value) ? value : [value];
  return {
    startDate: start || null,
    endDate: end || null
  };
};

/**
 * Transform DatePicker value to text input, formatted according to the `formatString`
 */
export const formatDateToInputValue = (value: DateSelection | DateRangeSelection, formatString: string) => {
  const formatDate = (date: Maybe<Date>) => {
    // Date must be valid else an exception is thrown.
    // https://date-fns.org/v2.16.1/docs/format#exceptions
    return date && isValid(date) ? format(date, formatString) : '';
  };
  let startDate: Date | null = null;
  let endDate: Date | null = null;
  if (value) {
    if (Array.isArray(value)) {
      startDate = value[0];
      endDate = value[1];
    } else {
      startDate = value;
    }
  }
  return {
    startInputValue: formatDate(startDate),
    endInputValue: formatDate(endDate)
  };
};

export const isSelectingRangeTarget = (input: Maybe<RangeDisplayValue>, target: RangeDisplayValue) => {
  return input ? input === target : undefined;
};

export const validateDatePickerProps = (props: DatePickerProps) => {
  const { isRange, rangeDisplayValue, value } = props;
  if (__DEV__) {
    if (isRange) {
      warning(Array.isArray(value), '[DatePicker] value must be an array - [start: DateSelection, end: DateSelection] - when isRange=true.');
    } else {
      warning(!Array.isArray(value), '[DatePicker] value must be of type `DateSelection` when isRange=false.');
      warning(typeof rangeDisplayValue === 'undefined', '[DatePicker] rangeDisplayValue only applies if isRange=true');
    }
  }
};
