/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Info',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 21.8c5.412 0 9.8-4.388 9.8-9.8 0-5.412-4.388-9.8-9.8-9.8-5.412 0-9.8 4.388-9.8 9.8 0 5.412 4.388 9.8 9.8 9.8zm0 1.2c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm1-5.5a1 1 0 1 1-2 0v-7a1 1 0 1 1 2 0v7zm-1-12a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
            fill="currentColor"
          />{' '}
          <path
            d="M13 17.5h-.125H13zm-2 0h-.125H11zm0-7h.125H11zm2 0h.125H13zm8.675 1.5A9.675 9.675 0 0 1 12 21.675v.25c5.481 0 9.925-4.444 9.925-9.925h-.25zM12 2.325c5.343 0 9.675 4.332 9.675 9.675h.25c0-5.481-4.444-9.925-9.925-9.925v.25zM2.325 12c0-5.343 4.332-9.675 9.675-9.675v-.25c-5.481 0-9.925 4.444-9.925 9.925h.25zM12 21.675c-5.343 0-9.675-4.332-9.675-9.675h-.25c0 5.481 4.444 9.925 9.925 9.925v-.25zM22.875 12c0 6.006-4.869 10.875-10.875 10.875v.25c6.144 0 11.125-4.98 11.125-11.125h-.25zM12 1.125c6.006 0 10.875 4.869 10.875 10.875h.25C23.125 5.856 18.145.875 12 .875v.25zM1.125 12C1.125 5.994 5.994 1.125 12 1.125v-.25C5.856.875.875 5.855.875 12h.25zM12 22.875C5.994 22.875 1.125 18.006 1.125 12h-.25c0 6.144 4.98 11.125 11.125 11.125v-.25zm0-4.25c.621 0 1.125-.504 1.125-1.125h-.25a.875.875 0 0 1-.875.875v.25zM10.875 17.5c0 .621.504 1.125 1.125 1.125v-.25a.875.875 0 0 1-.875-.875h-.25zm0-7v7h.25v-7h-.25zM12 9.375c-.621 0-1.125.504-1.125 1.125h.25c0-.483.392-.875.875-.875v-.25zm1.125 1.125c0-.621-.504-1.125-1.125-1.125v.25c.483 0 .875.392.875.875h.25zm0 7v-7h-.25v7h.25zm-2-11c0-.483.392-.875.875-.875v-.25c-.621 0-1.125.504-1.125 1.125h.25zm.875.875a.875.875 0 0 1-.875-.875h-.25c0 .621.504 1.125 1.125 1.125v-.25zm.875-.875a.875.875 0 0 1-.875.875v.25c.621 0 1.125-.504 1.125-1.125h-.25zM12 5.625c.483 0 .875.392.875.875h.25c0-.621-.504-1.125-1.125-1.125v.25z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
