type PageBase = {
  type: string;
  pageSlug: string;
};

export const formatPagesForBrannan = <T extends PageBase>(pages: ReadonlyArray<T>) =>
  pages.map(page => {
    if (page?.type === 'registry') {
      return { ...page, pageSlug: 'registry' };
    }
    return page;
  });
