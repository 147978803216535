import { get } from 'lodash-es';
import { ConciergeServiceCategory, formatter, PRICE_CONFIG } from '../config';
import { useMemo } from 'react';

interface Input {
  designSetName: string;
  quantity: number;
  category: ConciergeServiceCategory;
  customizations: {
    magnet: boolean;
  };
}

const useCalculateConciergeOrderPrice = (input: Input) => {
  const { designSetName, quantity, category, customizations } = input;

  const quantityPriceConfig = get(PRICE_CONFIG, [category, 'quantity', designSetName, quantity], {
    quantity: 0,
    totalPriceInMinorUnits: 0,
    individualPriceString: '$0.00',
    totalPriceString: '$0.00'
  });

  const magneticUpgradeConfig = useMemo(() => get(PRICE_CONFIG, [category, 'upgrades', 'magnetic', quantity], null), [category, quantity]);
  const isMagneticUpgradeAvailable = useMemo(() => Boolean(magneticUpgradeConfig), [magneticUpgradeConfig]);

  let totalPriceInMinorUnits = quantityPriceConfig.totalPriceInMinorUnits;

  if (customizations.magnet) {
    totalPriceInMinorUnits += magneticUpgradeConfig.totalPriceInMinorUnits;
  }

  return {
    availableCustomizations: {
      magnet: isMagneticUpgradeAvailable
    },
    totalPrice: {
      minorUnits: totalPriceInMinorUnits,
      formatted: formatter.formatCurrency(totalPriceInMinorUnits / 100, { form: 'auto' })
    },
    lineItems: [
      [`Quantity: ${quantityPriceConfig.quantity} cards ${quantityPriceConfig.individualPriceString} each`, quantityPriceConfig.totalPriceString],
      customizations.magnet && [`Magnet Upgrade: ${magneticUpgradeConfig?.individualPriceString} each`, magneticUpgradeConfig?.totalPriceString]
    ].filter(Boolean) as [string, string][],
    configs: {
      quantity: quantityPriceConfig,
      magneticUpgrade: magneticUpgradeConfig
    }
  };
};

export default useCalculateConciergeOrderPrice;
