import { ButtonV2, DatePickerInput, Flex, FormControl, OnDatePickerChange, SpacingStack, TextV2, useDisclosure } from '@withjoy/joykit';
import { Guests, Search } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';
import { OptionType, SelectV1 } from '@withjoy/joykit';
import { format } from 'date-fns-tz';
import React, { useState, useMemo } from 'react';
import { BookingAssistant } from '@apps/guest/packages/layout-engine/components/BookingAssistant';
import { BookingContainer } from './Booking.styles';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useLocaleContext } from '@shared/core/i18n/LocaleContext';
import { GuestSiteFormTypography } from '@apps/guest/components/GuestSiteFormTypography';
import { addDays } from 'date-fns';
import { getZonedTime } from './Booking.utils';

interface Props
  extends Readonly<{
    eventId: string;
    bookingType?: Maybe<string>;
    placeId?: Maybe<string>;
    lat?: Maybe<number> | undefined;
    lng?: Maybe<number> | undefined;
    address?: Maybe<string>;
    timezone?: Maybe<string>;
    startTimeMs?: number;
    endTimeMs?: number;
    onProviderClicked?: (checkIn: Maybe<string>, checkOut: Maybe<string>, guests: Maybe<string>, url: Maybe<string>, provider: Maybe<string>) => void;
  }> {}

export const Booking: React.FC<Props> = props => {
  const { eventId, address, bookingType, lat, lng, placeId, timezone, startTimeMs, endTimeMs, onProviderClicked } = props;
  const { t2 } = useTranslation('guestSiteTravel');
  const { suggestedStayDatesTitle, bookingSubmitButtonTitle, guestOptionPlural, guestOptionSingle, checkIn, checkOut } = t2('booking');
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const locale = useLocaleContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mobileDateFormat = 'MMM dd';
  const mobileMask = '';
  const currentDateFormat = isMobile ? mobileDateFormat : locale.dateFormat;
  const currentDateMask = isMobile ? mobileMask : locale.dateInputMask;
  const today = new Date();

  const zonedStartDate = useMemo(() => getZonedTime(startTimeMs, timezone), [startTimeMs, timezone]);
  const zonedEndDate = useMemo(() => getZonedTime(endTimeMs, timezone), [endTimeMs, timezone]);

  const [guests, setGuests] = useState<OptionType>({
    label: `1 ${guestOptionSingle}`,
    value: '1'
  });
  const [startDate, setStartDate] = useState<Date | null>(zonedStartDate || today);
  const [endDate, setEndDate] = useState<Date | null>(zonedEndDate || today);
  const onChange: OnDatePickerChange = dates => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const nextAfterStartDay = addDays(startDate || today, 1);
  const minStartDate = !!endDate ? today : nextAfterStartDay;
  const bookingProps = {
    address,
    bookingType,
    startDate: startDate?.getTime() as number,
    endDate: endDate?.getTime() as number,
    lat,
    lng,
    guests: parseInt(guests.value),
    placeId,
    timezone,
    eventId,
    isOpen,
    onClose
  };

  const formattedStartDate = zonedStartDate && format(zonedStartDate, 'eee, MMMM dd');
  const formattedEndDate = zonedEndDate && format(zonedEndDate, 'eee, MMMM dd');

  const guestOptions = [...Array(8)].map((el, i) => {
    const cur = i + 1;
    return {
      label: cur + ' ' + (cur === 1 ? guestOptionSingle : guestOptionPlural),
      value: cur.toString()
    };
  });

  return (
    <BookingContainer paddingX={[6]} paddingY={[7]}>
      <SpacingStack spacing={6}>
        <div>
          <TextV2 typographyVariant="label3" color="mono12" paddingBottom={3}>
            {suggestedStayDatesTitle}
          </TextV2>
          <TextV2 typographyVariant="body3" marginBottom={6}>
            {formattedStartDate} - {formattedEndDate}
          </TextV2>
          <TextV2 typographyVariant="body3">{address}</TextV2>
        </div>
        <GuestSiteFormTypography>
          <Flex>
            <FormControl
              label={checkIn}
              overrides={{
                Label: {
                  props: {
                    typographyVariant: 'label3',
                    color: 'mono12'
                  }
                }
              }}
            >
              <DatePickerInput
                minDate={minStartDate}
                isRange={true}
                rangeDisplayValue="start"
                onChange={onChange}
                value={[startDate, endDate]}
                monthsShown={1}
                dateFormat={currentDateFormat}
                mask={currentDateMask}
                placeholder={currentDateFormat.toUpperCase()}
              />
            </FormControl>
            <FormControl
              label={checkOut}
              marginLeft={6}
              overrides={{
                Label: {
                  props: {
                    typographyVariant: 'label3',
                    color: 'mono12'
                  }
                }
              }}
            >
              <DatePickerInput
                minDate={nextAfterStartDay}
                isRange={true}
                rangeDisplayValue="end"
                onChange={onChange}
                value={[startDate, endDate]}
                monthsShown={1}
                dateFormat={currentDateFormat}
                mask={currentDateMask}
                placeholder={currentDateFormat.toUpperCase()}
              />
            </FormControl>
          </Flex>
          <FormControl>
            <SelectV1
              searchable={false}
              iconLeft={<Guests />}
              defaultValue={guests}
              onChange={e => setGuests({ label: e?.label || '', value: e?.value || '1' })}
              options={guestOptions}
            />
          </FormControl>
        </GuestSiteFormTypography>
        <ButtonV2 startIcon={<Search />} onClick={onOpen} variant="solid" intent="primary">
          {bookingSubmitButtonTitle}
        </ButtonV2>
        <BookingAssistant onProviderClicked={onProviderClicked} {...bookingProps} />
      </SpacingStack>
    </BookingContainer>
  );
};
