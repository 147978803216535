import { useTranslation } from '@shared/core/i18n';

export const useLayoutEngineTranslations = () => {
  const { t } = useTranslation('layoutEngine');

  const footerTranslations = t('components').announcement;

  return {
    closeText: footerTranslations.close(),
    continueText: footerTranslations.continue()
  };
};
