import { styled, Box } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const MainFrameContainer = styled(Box)`
  background: ${({ theme }) => theme.colors.white};
  width: 39%;
  transition: ${animationTransition('background', 'background-color')};
  position: static;
  margin-left: auto;
  /* offset position fixed sibling */
  z-index: 1;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm4}) {
    width: 100%;
  }
`;
