import { format, utcToZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';

const testIds = [
  'addToCalendar',
  'getDirections',
  'eventName',
  'primaryBlock',
  'secondaryBlock',
  'descriptionBlock',
  'dressCodeblock',
  'mapBlock',
  'timeframe',
  'livestream'
] as const;

type TestIds = typeof testIds[number];

export type ScheduleListTestIds = {
  listItemTestIds: Record<TestIds, { prop: { 'data-testid': string }; value: string }>;
};

const _prefixTestId = (value: string | number) => `item-${value}`;

export const getItemTestIds = (id: string): ScheduleListTestIds => {
  const listItemTestIds = testIds.reduce((acc, key) => {
    const testId = _prefixTestId(`${key}-${id}`);
    acc[key] = {
      prop: { 'data-testid': testId },
      value: testId
    };
    return acc;
  }, {} as ScheduleListTestIds['listItemTestIds']);

  return {
    listItemTestIds
  };
};

// Maybe consider generalizing this for wide usage
export const useTestIds = (id: string) => {
  return useMemo(() => {
    return getItemTestIds(id);
  }, [id]);
};

////////////////////////////////////////////////////////////

// Until we update the Date core type, this is the only approach to having custom locale aware date strings
// Confirmed with Dan.
export const getScheduleGroupHeader = (hostDateString: string, hostTimeString: string) => {
  let dateHeader = hostDateString.replace(hostTimeString, '').trim();
  const [dayOfWeek, ...dates] = dateHeader.split(',');
  dateHeader = `${dayOfWeek}\n${dates.join(',')}`;
  return dateHeader;
};

// get timezone abbr by timezone name
export const getTimeZoneAbbr = (timeInMillis: number | undefined, timeZone: string | undefined) => {
  const zonedDate = utcToZonedTime(timeInMillis ? new Date(timeInMillis) : new Date(), timeZone || 'America/Los_Angeles');
  return format(zonedDate, 'zzz', { timeZone });
};
