/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect } from 'react';
import { Box, ButtonV2, Flex, LinkV2, Radio, TextV2, useToast } from '@withjoy/joykit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SafetyRating, useUpdateSafetyRatingMutation } from '@graphql/generated';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { withWindow } from '@shared/utils/withWindow';

interface FormikEventsForm {
  safety: SafetyRating;
}

export const EventTable: React.FC<{ event: any }> = ({ event }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const [eventSafetyRating, setEventSafetyRating] = React.useState<SafetyRating>(SafetyRating.none);
  const { toast } = useToast();

  useEffect(() => {
    if (event?.info?.safetyRating) {
      setEventSafetyRating(event?.info?.safetyRating);
    } else {
      setEventSafetyRating(SafetyRating.none);
    }
  }, [event]);

  const formik = useFormik<FormikEventsForm>({
    initialValues: {
      safety: eventSafetyRating || SafetyRating.none
    },
    validationSchema: Yup.object<FormikEventsForm>({
      safety: Yup.mixed().oneOf([SafetyRating.none, SafetyRating.safeOverride, SafetyRating.unsafeRequiresEscalation])
    }),
    enableReinitialize: true,
    onSubmit: async values => {
      const { data } = await updateEvent({ variables: { eventId: event?.id, safetyRating: values.safety } });
      setEventSafetyRating(data?.updateSafetyRating?.safetyRating || formik.values.safety);
      toast("Event's safety rating updated successfully");
    },
    validateOnMount: true
  });

  const [updateEvent, { loading: loadingSaveEvent }] = useUpdateSafetyRatingMutation();

  const onSaveClicked = useCallback(() => {
    if (formik.dirty) {
      formik.submitForm();
    }
  }, [formik]);

  const openWebsite = useCallback(() => {
    withWindow(() => {
      window.open(`${window.location.origin}/${event.website}`, '_blank');
    });
  }, [event]);

  if (!event) return null;

  return (
    <Box>
      <Box display={'grid'} gridTemplateColumns={'min-content 1fr'} columnGap={'1.5rem'} rowGap={'1rem'} alignItems={'center'}>
        <TextV2 typographyVariant="hed3">EventName:</TextV2>
        <LinkV2 onClick={openWebsite} typographyVariant="hed2">
          {event.website}
        </LinkV2>
        <TextV2 typographyVariant="hed3">Event ID:</TextV2>
        <TextV2 typographyVariant="hed2"> {event.id} </TextV2>
      </Box>
      <TextV2 typographyVariant="hed5" marginTop={'2rem'}>
        Set Event Safety Rating:
      </TextV2>
      <TextV2 marginTop={'1.5rem'}>
        If an event has been marked as unsafe, you can manually override the safety rating here. <br />
        Or, if you believe an event is unsafe, you can mark it as such.
      </TextV2>
      <Flex marginY={3} paddingRight={4} paddingY={4} columnGap={10} rowGap={10} flexDirection={isMobile ? 'column' : 'row'}>
        <Radio
          onChange={formik.handleChange}
          id="safetyRatingSafe"
          checked={formik.values.safety === SafetyRating.safeOverride}
          tabIndex={0}
          value={SafetyRating.safeOverride}
          label="Safe"
          name="safety"
        />
        <Radio
          onChange={formik.handleChange}
          id="safetyRatingUnsafe"
          checked={formik.values.safety === SafetyRating.unsafeRequiresEscalation}
          tabIndex={0}
          value={SafetyRating.unsafeRequiresEscalation}
          label="Unsafe"
          name="safety"
        />
        <Radio
          onChange={formik.handleChange}
          id="safetyRatingNone"
          checked={formik.values.safety === SafetyRating.none}
          tabIndex={0}
          value={SafetyRating.none}
          label="None"
          name="safety"
        />
      </Flex>
      <ButtonV2 marginY={3} variant="solid" intent="primary" onClick={onSaveClicked} disabled={!formik.isValid || !formik.dirty} loading={loadingSaveEvent}>
        Update
      </ButtonV2>
    </Box>
  );
};
