import { InterUIMetrics, getInterUIDynamicMetricCssObject } from './fontMetrics/interUI/metrics';

export const fontFamily = '-apple-system, "proxima-nova", BlinkMacSystemFont, "Segoe UI"';

export type FontWeight = 'regular' | 'medium' | 'semiBold' | 'bold';

export const fontWeightKeywordToNumber: { [fontWeight in FontWeight]: number } = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
};

const withFontWeightModifier = (guideline: InterUIMetrics, weight: FontWeight) => {
  let fontWeight: number;
  switch (weight) {
    case 'regular':
      fontWeight = 400;
      break;
    case 'medium':
      fontWeight = 500;
      break;
    case 'semiBold':
      fontWeight = 600;
      break;
    case 'bold':
      fontWeight = 700;
      break;
    default:
      fontWeight = 400;
      break;
  }

  return {
    fontWeight,
    ...guideline
  };
};

/**
 * A collection of text styles that can applied to elements under the admin context
 *
 * Notes:
 *  - Typeface: Inter UI.
 *  - For a given font size, there is a matching set of: font size, line height,
 *    and letter spacing. Inter UI Dynamic Metric provides guidelines for how to
 *    best use Inter UI. https://rsms.me/inter/dynmetrics/
 */
export const WebTextStyles = {
  withFontWeightModifier,

  page: withFontWeightModifier(getInterUIDynamicMetricCssObject(26), 'bold'),
  title: withFontWeightModifier(getInterUIDynamicMetricCssObject(20), 'semiBold'),
  head: withFontWeightModifier(getInterUIDynamicMetricCssObject(17), 'semiBold'),

  body: withFontWeightModifier(getInterUIDynamicMetricCssObject(15), 'regular'),
  bodyTitle: withFontWeightModifier(getInterUIDynamicMetricCssObject(15), 'semiBold'),
  button: { ...withFontWeightModifier(getInterUIDynamicMetricCssObject(15), 'bold'), letterSpacing: '.02em' },

  subhead: { ...withFontWeightModifier(getInterUIDynamicMetricCssObject(13), 'bold'), linHeight: '22px' },
  caption: {
    ...withFontWeightModifier(getInterUIDynamicMetricCssObject(13), 'regular')
  }
};
