import { styled } from '@withjoy/joykit';

export const Badge = styled.div`
  svg {
    border-radius: 50%;
    background: rgba(125, 125, 125, 0.2);
    box-shadow: 0px 0px 34.9091px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(17.4545px);
  }
`;
