const getElementPosition = (
  element: HTMLDivElement | null,
  scrollTop: number,
  isContainer?: boolean
): {
  min: number;
  max: number;
} => {
  if (!element) {
    return { min: 0, max: 0 };
  }

  const top = isContainer ? 0 : element.getBoundingClientRect().top;
  const contentHeight = element.offsetHeight;
  const min = top + scrollTop;
  const max = min + contentHeight;
  return { min, max };
};

export default getElementPosition;
