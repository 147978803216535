import { Box, TextV2 } from '@withjoy/joykit';
import { styled } from '@withjoy/joykit';
import { TypographyVariant } from '@withjoy/joykit/theme';
import React, { useState } from 'react';

const FontsContainer = styled.div`
  padding: 0 40px 40px;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  & > * {
    margin-top: 16px;
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    &:first-child {
      margin-top: 0;
      border: none;
    }
  }
`;

export const JustFonts: React.FC<{}> = () => {
  const [stringVal, setStringVal] = useState('');
  return (
    <FontsContainer>
      <Box padding={8} position="sticky" top="0" backgroundColor="rgba(255,255,255, 0.3)">
        <input type="text" value={stringVal} onChange={e => setStringVal(e.currentTarget.value)} />
      </Box>
      {Array.from(Array(10).keys()).map((_, i) => (
        <TextV2 key={`display${i + 1}`} typographyVariant={[`display${i + 1}` as TypographyVariant]}>
          {stringVal || `Display ${i + 1}`}
        </TextV2>
      ))}
      {Array.from(Array(6).keys()).map((_, i) => (
        <TextV2 key={`label${i + 1}`} typographyVariant={[`label${i + 1}` as TypographyVariant]}>
          {stringVal || `Label ${i + 1}`}
        </TextV2>
      ))}
      {Array.from(Array(4).keys()).map((_, i) => (
        <TextV2 key={`body${i + 1}`} typographyVariant={[`body${i + 1}` as TypographyVariant]}>
          {stringVal || `Body ${i + 1}`}
        </TextV2>
      ))}
      {Array.from(Array(6).keys()).map((_, i) => (
        <TextV2 key={`hed${i + 1}`} typographyVariant={[`hed${i + 1}` as TypographyVariant]}>
          {stringVal || `Hed ${i + 1}`}
        </TextV2>
      ))}
    </FontsContainer>
  );
};
