import { easingCoordinates } from 'easing-coordinates';

const decimalPlaces = (val: number, decimalPlaces: number) => Math.floor(val * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

interface BezierGradientArgs
  extends Readonly<{
    coordinates: [number, number, number, number];
    steps: number;
    deg: number;
    reverse?: boolean;
    colorFunction: (step: { x: number; y: number }) => string;
  }> {}

/**
 * Experimental function for generating bezier like gradients of
 * a specified color using a color getting function (see darkAlphaBezier)
 */
export const linearGradientBezier = (args: BezierGradientArgs) => {
  const { coordinates, steps, deg, colorFunction } = args;
  const gradientPoints = easingCoordinates(`cubic-bezier(${coordinates.join(', ')})`, steps);
  return `
    linear-gradient(${deg}deg, ${gradientPoints.map(colorFunction).join(', ')})
  `;
};

/**
 * Experimental function for generating bezier like gradients of dark colors
 */
export const darkAlphaBezier = (args: Omit<BezierGradientArgs, 'colorFunction'>) => {
  // need the -1 to match the natural CSS deg direction
  return linearGradientBezier({ ...args, colorFunction: ({ x, y }) => `rgba(0, 0, 0, ${decimalPlaces(0.4 * (1 - y), 4)}) ${Math.floor(100 * x)}%` });
};
