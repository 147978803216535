import React from 'react';
import { Position, AbstractPureComponent } from '../../common';
import Downshift, { DownshiftProps, ChildrenFunction, ControllerStateAndHelpers } from 'downshift';
import matchSorter from 'match-sorter';
import { IconSource, IconV1 } from '../IconV1';
import { InputV1, InputV1Props } from '../InputV1';
import { generateComponentDisplayName, safeInvoke } from '../../utils';
import { Popover } from '../PopoverV1';
import { Container, ValueContainer, MenuContainer, ControllerButton, Menu, Toggle, Item, NoOptionMessage, Placeholder, ItemLabel } from './styles';
import { PopoverOnInteractionHandler } from '../PopoverV1/sharedPopoverProps';
import { SELECT_SELECTED_OPTION_CLASS } from './constants';
import { SELECT_CONTROLLED_INPUT_REQUIRES_ON_CHANGE, SELECT_CONTROLLED_VALUE_REQUIRES_ON_CHANGE } from '../../common/errors';

export type OptionType = {
  label: string;
  subtitle?: string;
  value: string;
  key?: string;
  disabled?: boolean;
  iconStart?: IconSource;
  iconEnd?: IconSource;
};
export type OptionsType<T> = ReadonlyArray<T>;

// type GroupedOptionsType<T> = {
//   options: ReadonlyArray<T>;
//   [key: string]: any;
// };

type ValueType<T> = T | OptionsType<T> | null | undefined;

type OptionFilter<T> = (option: T, inputValue: string, index: number) => boolean;
type LoadingMessage = string | ((inputValue: string | null) => string | JSX.Element);

export interface SelectV2Props<T = OptionType> {
  children?: never;

  /**
   * Close the select when the user selects an option,
   *
   * @default true
   */
  closeMenuOnSelect?: boolean;

  /**
   * Close the select when the user clears their selection.
   *
   * @default true
   */
  closeMenuOnClear?: boolean;

  /**
   * The value of `isOpen` when the selection is cleared or when an item is selected.
   *
   * @default false
   */
  defaultIsOpen?: boolean;

  defaultValue?: ValueType<T>;

  /**
   * Whether the select can be interacted with.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Whether the Select list should take the full width of the input box.
   *
   * @default true;
   */
  fluid?: boolean;

  focusedInputBorderColor?: InputV1Props['focusedBorderColor'];

  /**
   * Hide the selected options from the menu.
   *
   * @default false
   */
  hideSelectedOptions?: boolean;

  iconLeft?: IconSource;

  /**
   * Custom logic to determine if an option is disabled.
   */
  isOptionDisabled?: (option: T) => boolean;

  /** The initial `isOpen` value when initialized. */
  initialIsOpen?: boolean;

  /**
   * Initial value of inputValue when the select is initialized.
   */
  initialInputValue?: string;

  /**
   * The value of the search input. Creates a controlled component.
   *
   * @default undefined
   */
  inputValue?: string;

  /**
   * Whether the Select should show that it is currently loading data.
   *
   * Useful for when loading data asynchronously.
   *
   */
  loading?: boolean;

  /**
   * Message to display when loading options.
   *
   * @default 'Loading...'.
   */
  loadingMessage?: LoadingMessage;

  /**
   * Max height of the dropdown before scrolling.
   *
   * @default 300px
   */
  maxDropdownHeight?: string;

  /**
   * Supports multiple selections.
   *
   * @default false
   */
  multi?: boolean;

  /**
   * @default "No results."
   */
  noOptionsMessage?: React.ReactNode;

  /**
   * Array of options that populate the menu.
   */
  options: OptionsType<T>; // OptionsType<GroupedOptionsType<T>> | OptionsType<T>;

  onChange?: (value: T | null) => void;

  onInputChange?: (value: string) => void;

  onMenuOpen?: () => void;

  onMenuClose?: () => void;

  /**
   * A custom method to filter options to be displayed.
   */
  optionFilter?: OptionFilter<T>;

  placeholder?: string;

  /**
   * Defines whether search functionality is enabled.
   *
   * @default true
   */
  searchable?: boolean;

  value?: ValueType<T>;
  divideAt?: number;
  afterMenuContent?: React.ReactNode;

  iconRight?: React.ReactNode;

  isInvalid?: boolean;

  autoComplete?: string;

  /**
   * Defines The Popover position
   *
   */
  position?: Position;
  noBorder?: boolean;
  noBackground?: boolean;
}

type SelectState = {
  isOpen: boolean;
};

const selectDefaultProps = {
  closeMenuOnSelect: true,
  closeMenuOnClear: true,
  disabled: false,
  fluid: true,
  hideSelectedOptions: false,
  loadingMessage: 'Loading...' as LoadingMessage,
  maxDropdownHeight: '300px',
  noOptionsMessage: 'No results.',
  searchable: true
};

type SelectInternalProps<T> = SelectV2Props<T> & typeof selectDefaultProps;

export class SelectV1<T extends OptionType> extends AbstractPureComponent<SelectInternalProps<T> & { 'data-testid'?: string }, SelectState> {
  static displayName = generateComponentDisplayName('Select');
  static defaultProps = selectDefaultProps;

  public inputRef: HTMLElement | null;
  private menuContainerRef: HTMLElement | null;

  protected handleRefs = {
    inputRef: (node: HTMLInputElement | null) => {
      this.inputRef = node;
    },
    menuContainerRef: (node: HTMLDivElement | null) => {
      this.menuContainerRef = node;
    }
  };

  state: SelectState = {
    isOpen: this.props.defaultIsOpen || false
  };

  protected validateProps(props: SelectInternalProps<T>) {
    const { onInputChange, inputValue, value, onChange } = props;
    if (typeof inputValue !== 'undefined' && !onInputChange) {
      console.error(SELECT_CONTROLLED_INPUT_REQUIRES_ON_CHANGE);
    }

    if (value !== undefined && !onChange) {
      console.error(SELECT_CONTROLLED_VALUE_REQUIRES_ON_CHANGE);
    }
  }

  // ==============================================================
  // Handlers
  // ==============================================================

  private handleDownshiftOnStateChange: DownshiftProps<T>['onUserAction'] = (options, stateAndHelpers) => {
    if (options.isOpen !== undefined && this.state.isOpen !== options.isOpen) {
      this.handleOnOpen(options.isOpen);
    }
  };

  private handleOnChange: DownshiftProps<T>['onChange'] = item => {
    const { closeMenuOnSelect, closeMenuOnClear, value, onChange } = this.props;

    if (value !== undefined && !onChange) {
      return;
    }

    const shouldClose = (item && closeMenuOnSelect) || (!item && closeMenuOnClear);

    if (shouldClose) {
      safeInvoke(this.props.onChange, item);
      this.handleOnOpen(false);
    } else {
      safeInvoke(this.props.onChange, item);
    }
  };

  private handleOnInputValueChange: DownshiftProps<T>['onInputValueChange'] = (inputValue, stateAndHelpers) => {
    safeInvoke(this.props.onInputChange, inputValue);
  };

  private handlePopoverOnInteraction: PopoverOnInteractionHandler = (isOpen, e) => {
    this.handleOnOpen(isOpen);
  };

  private handlePopoverOnEntering = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
    this.scrollSelectedOptionIntoView();
  };
  private handlePopoverOnEntered = () => {
    // this.scrollSelectedOptionIntoView();
  };

  private stateReducerHandler: DownshiftProps<T>['stateReducer'] = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem: {
        if (this.props.value !== undefined) {
          return {
            ...state
          };
        }
        // if (this.isInControlledValueMode()) {
        //   const { selectedItem, highlightedIndex } = state;
        //   const next = {
        //     ...changes,
        //     selectedItem,
        //     highlightedIndex
        //   };
        //   return next;
        // }
        return {
          ...changes,
          highlightedIndex: changes.selectedItem ? this.props.options.indexOf(changes.selectedItem) : null
        };
      }

      default:
        return changes;
    }
  };

  private handleOnOpen = (isOpen: boolean) => {
    if (isOpen) {
      safeInvoke(this.props.onMenuOpen);
    } else {
      safeInvoke(this.props.onMenuClose);
    }
    this.setState({ isOpen });
  };

  // ==============================================================
  // Getters
  // ==============================================================

  private getItemDisplayValue = (item: T | null) => {
    return item ? item.label : '';
  };

  protected getOptions = ({ inputValue, selectedItem }: ControllerStateAndHelpers<T>) => {
    const { hideSelectedOptions, options, searchable } = this.props;

    const filteredOptions =
      searchable && inputValue
        ? // Filter for input
          this.filterOptions(inputValue)
        : hideSelectedOptions && selectedItem
        ? // Remove the selected item
          this.filterOptions(selectedItem.label)
        : // No filters applied, return all
          options;

    return filteredOptions;
  };

  protected isOptionDisabled = (option: T): boolean => {
    if (this.props.isOptionDisabled) {
      return this.props.isOptionDisabled(option);
    }
    return !!option.disabled;
  };

  // ==============================================================
  // Behaviour
  // ==============================================================

  private scrollSelectedOptionIntoView = () => {
    if (!this.menuContainerRef) {
      return;
    }
    const menu = this.menuContainerRef.firstChild as HTMLElement;
    if (!menu) {
      return;
    }
    const option = menu.getElementsByClassName(SELECT_SELECTED_OPTION_CLASS)[0] as HTMLElement | null;
    if (!option) {
      return;
    }

    const { scrollHeight: menuScrollHeight } = menu;
    const { clientHeight: optionHeight, offsetTop: optionOffsetTop } = option;

    const maxDropdownHeight = Number.parseInt(this.props.maxDropdownHeight, 10);
    if (menuScrollHeight > maxDropdownHeight) {
      menu.scrollTop = optionOffsetTop - optionHeight;
    }
  };

  // ==============================================================
  // Util
  // ==============================================================

  private filterOptions = (inputValue: string) => {
    const { optionFilter, options } = this.props;
    if (optionFilter) {
      return options.filter((option, index) => optionFilter(option, inputValue, index));
    }
    return matchSorter(options as any, inputValue, { keys: ['label'], threshold: matchSorter.rankings.MATCHES });
  };

  // ==============================================================
  // render
  // ==============================================================

  public render() {
    const { defaultIsOpen, initialIsOpen, initialInputValue, defaultValue, disabled, fluid, searchable, inputValue, value, position, noBackground } = this.props;
    return (
      <Downshift
        defaultIsOpen={defaultIsOpen}
        initialIsOpen={initialIsOpen}
        initialInputValue={initialInputValue}
        initialSelectedItem={defaultValue as any}
        inputValue={searchable ? inputValue : ''}
        isOpen={this.state.isOpen}
        itemToString={this.getItemDisplayValue}
        onSelect={this.handleOnChange}
        onInputValueChange={this.handleOnInputValueChange}
        onUserAction={this.handleDownshiftOnStateChange}
        stateReducer={this.stateReducerHandler}
        selectedItem={value as any}
      >
        {
          (renderProps => {
            return (
              <Container
                noBackground={noBackground}
                fluid={fluid}
                {...renderProps.getRootProps({
                  refKey: 'ref'
                })}
              >
                <Popover
                  disabled={disabled}
                  interactionVariant="click"
                  isOpen={renderProps.isOpen}
                  onInteraction={this.handlePopoverOnInteraction}
                  position={position || Position.BOTTOM_LEFT}
                  pinned={false}
                  boundary={'window'}
                  showArrow={false}
                  targetTagName="div"
                  usePortal={false}
                  wrapperTagName="div"
                  onEntering={this.handlePopoverOnEntering}
                  onEntered={this.handlePopoverOnEntered}
                >
                  {this.renderValue(renderProps)}
                  {this.renderMenu(renderProps)}
                </Popover>
              </Container>
            );
          }) as ChildrenFunction<T>
        }
      </Downshift>
    );
  }

  // \\\\\\\\\\\\\\\\\\\\\\\\
  // Value render
  // \\\\\\\\\\\\\\\\\\\\\\\\

  private renderValue = (renderProps: ControllerStateAndHelpers<T>) => {
    return (
      <ValueContainer>
        {this.renderInput(renderProps)}
        {this.renderPlaceholder(renderProps)}
      </ValueContainer>
    );
  };

  private renderPlaceholder = ({ inputValue }: ControllerStateAndHelpers<T>) => {
    const { placeholder, searchable, iconLeft, value } = this.props;
    const inputIsEmpty = !inputValue;

    const selectedItem = Array.isArray(value) ? value[0].label : value;

    if (!!selectedItem) {
      return inputIsEmpty && searchable && <Placeholder hasLeftIcon={!!iconLeft}>{selectedItem.label}</Placeholder>;
    } else if (inputIsEmpty && placeholder) {
      return (
        <Placeholder hasLeftIcon={!!iconLeft} isPlaceholder={true}>
          {placeholder}
        </Placeholder>
      );
    }
    return null;
  };

  private renderInput = (renderProps: ControllerStateAndHelpers<T>) => {
    const { disabled, focusedInputBorderColor, searchable, iconLeft, iconRight, isInvalid, autoComplete, noBorder, ...rest } = this.props;
    const iconMarkup = (
      <ControllerButton {...renderProps.getToggleButtonProps({ disabled })} onClick={null}>
        {searchable ? <Toggle source="search" /> : <Toggle source="optionSelect" $rotate={renderProps.isOpen} />}
      </ControllerButton>
    );
    return (
      <InputV1
        inputRef={this.handleRefs.inputRef}
        focusedBorderColor={focusedInputBorderColor}
        aria-invalid={isInvalid}
        {...renderProps.getInputProps({ disabled } as any)}
        readOnly={!searchable}
        value={searchable ? renderProps.inputValue : renderProps.selectedItem ? renderProps.selectedItem.label : ''}
        iconLeft={iconLeft}
        iconRight={iconRight ?? iconMarkup}
        autoComplete={autoComplete}
        data-testid={rest['data-testid']}
        minimal={noBorder}
      />
    );
  };

  // \\\\\\\\\\\\\\\\\\\\\\\\
  // Dropdown render
  // \\\\\\\\\\\\\\\\\\\\\\\\

  protected renderMenu = (renderProps: ControllerStateAndHelpers<T>) => {
    const { maxDropdownHeight, divideAt, afterMenuContent = undefined } = this.props;
    const { innerRef, ...menuProps } = renderProps.getMenuProps({ refKey: 'innerRef' }, { suppressRefError: true });
    return (
      <MenuContainer ref={this.handleRefs.menuContainerRef}>
        <Menu {...menuProps} ref={innerRef} maxHeight={maxDropdownHeight} divideAt={divideAt} className="select-menu">
          {this.renderOptions(renderProps)}
        </Menu>
        {afterMenuContent}
      </MenuContainer>
    );
  };

  protected renderOptions = (renderProps: ControllerStateAndHelpers<T>) => {
    const { selectedItem, highlightedIndex, getItemProps, inputValue } = renderProps;
    const { noOptionsMessage, loading, loadingMessage } = this.props;

    if (loading) {
      return <NoOptionMessage>{typeof loadingMessage === 'function' ? loadingMessage(inputValue) : loadingMessage}</NoOptionMessage>;
    }

    const filteredOptions = this.getOptions(renderProps);
    if (filteredOptions.length) {
      return (filteredOptions as Array<any>).map((option, index) => {
        const isSelected = selectedItem === option;
        const isDisabled = this.isOptionDisabled(option);
        return (
          <Item
            key={option.key || option.value}
            isHighlighted={highlightedIndex === index}
            isSelected={isSelected}
            isDisabled={isDisabled}
            {...getItemProps({
              key: option.key || option.value,
              item: option,
              index,
              ['aria-selected']: isSelected,
              disabled: isDisabled
            })}
            className={isSelected && SELECT_SELECTED_OPTION_CLASS}
          >
            {option.iconStart && <IconV1 source={option.iconStart} />}
            <ItemLabel variant="body1" color="currentColor" fontWeight={isSelected ? 700 : undefined}>
              {option.label}
            </ItemLabel>
            {option.iconEnd && <IconV1 source={option.iconEnd} />}
          </Item>
        );
      });
    }

    return <NoOptionMessage>{noOptionsMessage}</NoOptionMessage>;
  };
}
