import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const RegistryListTelemetry = createTelemetryObject({
  actions: {
    registryBrowseClicked: (clickFrom: string) => ({
      action: 'RegistryBrowseClicked',
      actionType: 'click',
      category: 'wedding',
      extraInfo: {
        clickFrom
      }
    }),

    viewGiftsClick: (clickFrom: string) => ({
      category: 'wedding',
      action: 'ViewGiftsClick',
      actionType: 'click',
      extraInfo: {
        clickFrom
      }
    })
  }
});

const { TelemetryProvider, useTelemetry: useRegistryListTelemetry } = createTelemetry(RegistryListTelemetry);
export { TelemetryProvider, useRegistryListTelemetry };
