import type { BasePlacement, ModifierArguments, Variation } from '@popperjs/core';
import { ARROW_SIZE, INVERSE_PLACEMENT, PLACEMENT, POPPER_MARGIN, TO_POPPER_PLACEMENT, INVERSE_PLACEMENT_VARIATION, InversePlacement } from './Popover.constants';
import type { Offset, Placement, TriggerType } from './Popover.types';

const isPlacementVertical = (placement: Placement) => {
  return placement === PLACEMENT.top || placement === PLACEMENT.bottom;
};

export const isTriggerHover = (triggerType: TriggerType): triggerType is 'hover' => triggerType === 'hover';
export const isTriggerClick = (triggerType: TriggerType): triggerType is 'click' => triggerType === 'click';

export const normalizeOffsets = (offsets: { x?: number; y?: number } = { x: 0, y: 0 }): Offset => {
  return {
    top: Math.floor(offsets.y || 0),
    left: Math.floor(offsets.x || 0)
  };
};

type ParsePlacementResult = { basePlacement: BasePlacement; inversePlacement: Maybe<BasePlacement>; variation: Maybe<Variation> };
/**
 * @example
 * _parsePlacement('topStart');
 *  -> {
 *        basePlacement: 'top',
 *        inversePlacement: 'bottom',
 *        variation: 'start'
 *      }
 *
 * _parsePlacement('auto');
 *  -> {
 *        basePlacement: 'auto',
 *        inversePlacement: undefined,
 *        variation: undefined
 *      }
 */
const _parsePlacement = (placement: Placement): ParsePlacementResult => {
  const [basePlacement, variation] = TO_POPPER_PLACEMENT[placement].split('-') as [BasePlacement, Maybe<Variation>];
  return {
    basePlacement,
    inversePlacement: INVERSE_PLACEMENT[basePlacement],
    variation
  };
};

const getMainAxisFromPlacement = (placement: BasePlacement) => {
  return ['top', 'bottom'].includes(placement) ? 'x' : 'y';
};

/**
 * We want to animate the popover by having it scale relative to its placement.
 *  EG. If `placement = top`, it will be placed above the anchor - we want the popper
 *      to scale from the bottom.
 */
export const getTransformOrigin = (placement: Placement, data: ModifierArguments<object>) => {
  const { basePlacement, inversePlacement, variation } = _parsePlacement(placement);
  if (inversePlacement) {
    let transformValue: number;

    const state = data.state;
    const isVertical = isPlacementVertical(inversePlacement);
    let maybeOffsetInversePlacement: string | undefined;

    const { popperOffsets, preventOverflow = { x: 0, y: 0 } } = state.modifiersData;
    const { popper, reference } = state.rects;
    const mainAxis = getMainAxisFromPlacement(basePlacement);
    const length = mainAxis === 'x' ? 'width' : 'height';

    // preventOverflow serves to keep the popper as close as possible to the reference
    if (popperOffsets && preventOverflow[mainAxis] !== 0) {
      // Overflow on the main axis detected - find precise transform origin relative to overflow difference
      const offset = popperOffsets[mainAxis];
      const popperOffsetFromRef = reference[mainAxis] - offset;
      transformValue = popperOffsetFromRef + reference[length] / 2;
    } else if (variation && placement in INVERSE_PLACEMENT_VARIATION) {
      // Animate start/end placement variations perpendicular to the cross-axis
      return INVERSE_PLACEMENT_VARIATION[placement as InversePlacement];
    } else {
      // No overflow. To simplify computation for the browser, use rect info (as opposed to using 'center')
      // to determine exact halfway point of the popper.
      transformValue = popper[length] / 2;
    }

    // https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin
    const components = [`${Math.floor(transformValue)}px`, maybeOffsetInversePlacement || inversePlacement];

    return (isVertical ? components : components.reverse()).join(' ');
  }

  // Very unlikely that we'd cant determine a transform origin.
  return null;
};

// Styles

export const calculateMargin = (showArrow: boolean) => {
  return showArrow ? ARROW_SIZE * 2 : POPPER_MARGIN;
};

/**
 * Get CSS rules for the popover arrow
 */
export const getArrowPlacement = (placement: Placement, arrowOffset: Offset) => {
  const { basePlacement, inversePlacement } = _parsePlacement(placement);
  if (!inversePlacement) {
    return undefined;
  }

  const alignment = isPlacementVertical(basePlacement) ? 'left' : 'top';
  return {
    [alignment]: `${arrowOffset[alignment]}px`,
    [inversePlacement]: `-${ARROW_SIZE - 2}px`
  };
};
