import { Box, styled } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const StyledLinkContainer = styled(Box)`
  a {
    border-radius: ${props => props.theme.radii[3]};
    transition: ${animationTransition('background')};
    &.active {
      background: rgba(51, 51, 51, 0.1);
    }
  }
`;

export const StyledHamburger = styled(Box).attrs({ as: 'span' })`
  width: 24px;
  height: 18px;
  position: relative;
  &::after {
    content: ' ';
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    position: relative;
    top: 2px;
    ${({ theme }) => {
      const barColor = theme.colors.mono13;
      return {
        backgroundColor: barColor,
        boxShadow: `${barColor} 0px 6px 0px 0px, ${barColor} 0px 12px 0px 0px`
      };
    }}
  }
`;

export const StyledLegacyNavLinkContainer = styled(Box).attrs({ as: 'li' })`
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    :not(.active) {
      :hover {
        color: ${props => props.theme.colors.mono12};
      }
      :active {
        color: ${props => props.theme.colors.mono14};
      }
    }
    &.active {
      border-radius: 8px;
      ${({ theme }) => ({ backgroundColor: theme.colors.mono3 })}
    }
  }
`;
