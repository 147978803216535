import React from 'react';
import { ServiceCenterState } from '@apps/serviceCenter/ServiceCenterState';
import { RouterServiceCenter } from '@apps/serviceCenter/route.paths';
import { Helmet } from 'react-helmet-async';
import { SERVICE_CENTER_APP_NAME } from '@apps/serviceCenter/constants';

const ServiceCenterApp = () => {
  return (
    <>
      <Helmet>
        <title>Service Center</title>
        <link rel="canonical" href="https://withjoy.com/servicecenter" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ServiceCenterState>
        <RouterServiceCenter appUrl={SERVICE_CENTER_APP_NAME} />
      </ServiceCenterState>
    </>
  );
};

export default ServiceCenterApp;
