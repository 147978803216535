import React from 'react';
import { BaseBoxProps, BoxProps, ButtonV2Props, DialogV2, DialogV2Props } from '@withjoy/joykit';
import { dialogOverrides, StyledBody, StyledCloseButton, StyledFooter, StyledHeader, StyledBackButton, StyledBackground } from './DialogStyled.styles';
import { JoyBadge } from '@shared/components';
import { ReactComponent as ChevronBackIcon } from '@shared/joykit/packages/core/icons/svgs/16/chevron-back.svg';
import { useDialogThemeController } from '@apps/guest/packages/layout-engine/components/DialogStyled/DialogStyled.controller';

// Themed Dialog for reusing in Guest site. Compatible with mobile and desktop screens

export type BackButtonCallBackType = () => void;

const ThemedDialogBackground: React.FC<BaseBoxProps> = props => {
  const { styleAttrConfig } = useDialogThemeController();
  return <StyledBackground {...styleAttrConfig.prop} {...props} />;
};

const DialogStyled: React.FC<DialogV2Props> & {
  Header: typeof StyledHeader;
  Body: typeof StyledBody;
  Footer: typeof Footer;
  CloseButton: typeof CloseButton;
  BackButton: typeof BackButton;
} = props => {
  return (
    <DialogV2 size="lg" overrides={dialogOverrides} {...props}>
      <ThemedDialogBackground />
      {props.children}
    </DialogV2>
  );
};

const BackButton: React.FC<ButtonV2Props> = props => (
  <StyledBackButton startIcon={ChevronBackIcon} id="backButton" variant="ghost" shape="rounded" {...props}>
    {'Back'}
  </StyledBackButton>
);

const CloseButton: React.FC<ButtonV2Props> = props => <StyledCloseButton variant="ghost" shape="rounded" padding={0} {...props} />;

const Footer: React.FC<BoxProps> = props => (
  <StyledFooter {...props}>
    <JoyBadge />
  </StyledFooter>
);

DialogStyled.Header = StyledHeader;
DialogStyled.Body = StyledBody;
DialogStyled.Footer = Footer;
DialogStyled.CloseButton = CloseButton;
DialogStyled.BackButton = BackButton;

export { DialogStyled };
