/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Warning Triangle',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fill="currentColor"
            d="M8 5.417c-.351 0-.636.285-.636.636v4.455c0 .352.285.636.636.636s.636-.285.636-.636V6.053c0-.351-.285-.636-.636-.636zm.001 6.194a.761.761 0 0 0 0 1.523.761.761 0 0 0 0-1.523z"
          />
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.125 2.622a1 1 0 0 1 1.75 0l5.97 10.788a1 1 0 0 1-.875 1.484H2.03a1 1 0 0 1-.875-1.484l5.97-10.788zM2.199 13.794L8 3.312l5.801 10.482H2.199z"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8749 2.6584C12.4943 1.97068 11.5056 1.97068 11.125 2.6584L1.32137 20.3732C0.952513 21.0397 1.43456 21.8574 2.19632 21.8574H21.8036C22.5654 21.8574 23.0474 21.0397 22.6786 20.3732L12.8749 2.6584ZM12 3.55565L2.53559 20.6574L21.4644 20.6574L12 3.55565Z"
            fill="currentColor"
          />
          <path
            d="M12.0001 19.5274C12.5523 19.5274 13.0001 19.0797 13.0001 18.5274C13.0001 17.9752 12.5523 17.5274 12.0001 17.5274C11.4478 17.5274 11.0001 17.9752 11.0001 18.5274C11.0001 19.0797 11.4478 19.5274 12.0001 19.5274Z"
            fill="currentColor"
          />
          <path
            d="M12.6002 15.9274C12.6002 16.2588 12.3315 16.5274 12.0002 16.5274C11.6688 16.5274 11.4002 16.2588 11.4002 15.9274C11.4002 15.5961 11.4002 7.62744 11.4002 7.62744C11.4002 7.29606 11.6688 7.02744 12.0002 7.02744C12.3315 7.02744 12.6002 7.29606 12.6002 7.62744C12.6002 7.62744 12.6002 15.5961 12.6002 15.9274Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
