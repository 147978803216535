import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { StyledDatePickerRoot, StyledReactDatePicker, StyledDayContent, StyledNavArrow } from './DatePicker.styles';
import { DatePickerProps } from './DatePicker.types';
import { extractDatesFromValue, isSelectingRangeTarget, validateDatePickerProps } from './DatePicker.utils';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useOverrides } from '@shared/utils/overrides';

const defaultProps = {
  monthsShown: 1 as number
} as const;

const defaultComponents = {
  DatePicker: StyledReactDatePicker
} as const;
/**
 * Interactive calendar date picker. This does NOT render an input - to use the DatePicker as a form control,
 * use the `DatePickerInput` component.
 *
 * Known Issues:
 *   - Keyboard navigation in inline version (resolved, but not released): https://github.com/Hacker0x01/react-datepicker/pull/2588
 */
const DatePicker: React.FC<DatePickerProps> = props => {
  const { filterDate, isDisabled, isReadOnly, isRange, onChange, overrides = {}, maxDate, minDate, monthsShown, rangeDisplayValue, value, openToDate } = props as DatePickerProps &
    typeof defaultProps;

  validateDatePickerProps(props);

  const {
    DatePicker: [DatePickerComponent, datePickerProps]
  } = useOverrides(defaultComponents, overrides);

  const { startDate, endDate } = extractDatesFromValue(value);

  const handleOnChange = useEventCallback<ReactDatePickerProps['onChange']>((dates, evt) => {
    onChange?.(dates, evt);
  });

  return (
    <StyledDatePickerRoot
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      pickerType={isRange ? 'dateRange' : 'dayPicker'}
      paddingX={6}
      paddingY={5}
      minHeight={292}
      height={292}
    >
      <DatePickerComponent
        disabled={isDisabled}
        endDate={endDate}
        filterDate={filterDate}
        inline={true}
        maxDate={maxDate}
        minDate={minDate}
        monthsShown={monthsShown}
        nextMonthButtonLabel={<StyledNavArrow $type="next" />}
        onChange={handleOnChange}
        previousMonthButtonLabel={<StyledNavArrow $type="prev" />}
        readOnly={isReadOnly}
        renderDayContents={(day: number) => {
          return <StyledDayContent>{day}</StyledDayContent>;
        }}
        selected={startDate}
        selectsEnd={isSelectingRangeTarget(rangeDisplayValue, 'end')}
        selectsStart={isSelectingRangeTarget(rangeDisplayValue, 'start')}
        selectsRange={isRange}
        startDate={startDate}
        openToDate={openToDate}
        {...datePickerProps}
      />
    </StyledDatePickerRoot>
  );
};
DatePicker.defaultProps = defaultProps;
DatePicker.displayName = 'DatePicker';

export { DatePicker };
