/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Assistance',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 1.2251C5.67157 1.2251 5 1.89667 5 2.7251C5 3.55352 5.67157 4.2251 6.5 4.2251H9.5C10.3284 4.2251 11 3.55352 11 2.7251C11 1.89667 10.3284 1.2251 9.5 1.2251H6.5ZM9.5 2.3251H6.5C6.27909 2.3251 6.1 2.50418 6.1 2.7251C6.1 2.94601 6.27909 3.1251 6.5 3.1251H9.5C9.72091 3.1251 9.9 2.94601 9.9 2.7251C9.9 2.50418 9.72091 2.3251 9.5 2.3251Z"
            fill="currentColor"
          />
          <path
            d="M0.55 13.6753C0.246243 13.6753 0 13.9215 0 14.2253C0 14.529 0.246244 14.7753 0.55 14.7753H15.45C15.7538 14.7753 16 14.529 16 14.2253C16 13.9215 15.7538 13.6753 15.45 13.6753H0.55Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00001 4.8252C4.19611 4.8252 1.05363 7.65706 0.565781 11.3276C0.493017 11.8751 0.947728 12.3252 1.50001 12.3252H14.5C15.0523 12.3252 15.507 11.8751 15.4342 11.3276C14.9464 7.65706 11.8039 4.8252 8.00001 4.8252ZM8.00001 5.9252C11.1592 5.9252 13.7852 8.21509 14.306 11.2252H1.69401C2.21487 8.21509 4.84087 5.9252 8.00001 5.9252Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 18.95H3C2.55817 18.95 2.2 19.3082 2.2 19.75C2.2 20.1918 2.55817 20.55 3 20.55H21C21.4418 20.55 21.8 20.1918 21.8 19.75C21.8 19.3082 21.4418 18.95 21 18.95ZM14.353 6.05951C14.0942 6.18168 13.8051 6.25 13.5 6.25H10.5C10.1949 6.25 9.90573 6.18168 9.647 6.0595C8.96907 5.73936 8.49999 5.04946 8.49999 4.25C8.49999 3.14543 9.39542 2.25 10.5 2.25H13.5C14.6046 2.25 15.5 3.14543 15.5 4.25C15.5 5.04947 15.0309 5.73938 14.353 6.05951ZM14.4284 7.31323C18.1873 8.30422 21.0344 11.5409 21.4481 15.5016C21.5055 16.0509 21.0522 16.5 20.4999 16.5H3.49994C2.94765 16.5 2.49441 16.0509 2.55178 15.5016C2.96545 11.5409 5.81263 8.30419 9.57149 7.31322C9.8753 7.23312 10.1851 7.16769 10.5 7.11774C10.9886 7.04025 11.4896 7 11.9999 7C12.5104 7 13.0114 7.04025 13.5 7.11776C13.8149 7.16771 14.1247 7.23314 14.4284 7.31323ZM10.5 3.45C10.0582 3.45 9.69999 3.80817 9.69999 4.25C9.69999 4.69183 10.0582 5.05 10.5 5.05H13.5C13.9418 5.05 14.3 4.69183 14.3 4.25C14.3 3.80817 13.9418 3.45 13.5 3.45H10.5ZM3.7859 15.3C4.36671 11.2852 7.82377 8.2 11.9999 8.2C16.1761 8.2 19.6332 11.2852 20.214 15.3H3.7859ZM1 19.75C1 18.6454 1.89543 17.75 3 17.75H21C22.1046 17.75 23 18.6454 23 19.75C23 20.8546 22.1046 21.75 21 21.75H3C1.89543 21.75 1 20.8546 1 19.75Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.66654 20.3835C4.63196 20.3835 4.60904 20.3719 4.59732 20.3627C5.20595 14.6044 10.0796 10.1168 15.9999 10.1168C21.9201 10.1168 26.7938 14.6044 27.4024 20.3627C27.3907 20.3719 27.3678 20.3835 27.3332 20.3835H4.66654ZM13.1999 6.0835C13.1999 5.64167 13.558 5.2835 13.9999 5.2835H17.9999C18.4417 5.2835 18.7999 5.64167 18.7999 6.0835C18.7999 6.52532 18.4417 6.8835 17.9999 6.8835H13.9999C13.558 6.8835 13.1999 6.52532 13.1999 6.0835ZM3.40233 20.2522C3.97333 14.7852 8.02182 10.3526 13.2964 9.20593C13.5286 9.15545 13.7631 9.11134 13.9999 9.07379C14.6513 8.97046 15.3193 8.91679 15.9999 8.91679C16.6804 8.91679 17.3484 8.97046 17.9999 9.07379C18.2366 9.11134 18.4712 9.15545 18.7033 9.20593C23.9779 10.3526 28.0264 14.7852 28.5974 20.2522C28.6739 20.9846 28.0696 21.5835 27.3332 21.5835H4.66654C3.93017 21.5835 3.32584 20.9846 3.40233 20.2522ZM11.9999 6.0835C11.9999 6.95652 12.5592 7.6989 13.3391 7.97177C13.546 8.04415 13.7683 8.0835 13.9999 8.0835H17.9999C18.2314 8.0835 18.4538 8.04415 18.6606 7.97177C19.4405 7.69889 19.9999 6.95652 19.9999 6.0835C19.9999 4.97893 19.1044 4.0835 17.9999 4.0835H13.9999C12.8953 4.0835 11.9999 4.97893 11.9999 6.0835ZM4.33333 24.4501H27.6667C28.2926 24.4501 28.8 24.9576 28.8 25.5835C28.8 26.2094 28.2926 26.7168 27.6667 26.7168H4.33333C3.70741 26.7168 3.2 26.2094 3.2 25.5835C3.2 24.9576 3.70741 24.4501 4.33333 24.4501ZM2 25.5835C2 24.2948 3.04467 23.2501 4.33333 23.2501H27.6667C28.9553 23.2501 30 24.2948 30 25.5835C30 26.8721 28.9553 27.9168 27.6667 27.9168H4.33333C3.04467 27.9168 2 26.8721 2 25.5835Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.75 10.75V13.7301L25.87 13.8472C34.1795 14.7166 40.785 21.323 41.653 29.633C41.6815 29.9066 41.4534 30.25 40.9999 30.25H6.99989C6.5464 30.25 6.31823 29.9066 6.34681 29.633C7.21474 21.323 13.8204 14.7164 22.1301 13.8472L23.25 13.7301V10.75H21.5C20.8097 10.75 20.25 10.1904 20.25 9.5C20.25 8.80964 20.8097 8.25 21.5 8.25H26.5C27.1904 8.25 27.75 8.80964 27.75 9.5C27.75 10.1904 27.1904 10.75 26.5 10.75H24.75ZM29 9.5C29 10.3892 28.5358 11.1699 27.8365 11.6132C27.4499 11.8581 26.9915 12 26.5 12H26V12.604C26.1673 12.6215 26.334 12.6412 26.5 12.663C27.015 12.7307 27.5235 12.819 28.0246 12.9271C35.9514 14.6367 42.0386 21.2921 42.8962 29.5031C43.011 30.6017 42.1045 31.5 40.9999 31.5H6.99989C5.89532 31.5 4.98883 30.6017 5.10357 29.5031C5.96117 21.292 12.0485 14.6366 19.9753 12.9271C20.4765 12.819 20.985 12.7307 21.5 12.663C21.666 12.6412 21.8327 12.6215 22 12.604V12H21.5C21.0085 12 20.5501 11.8581 20.1635 11.6131C19.4642 11.1699 19 10.3892 19 9.5C19 8.11929 20.1193 7 21.5 7H26.5C27.8807 7 29 8.11929 29 9.5ZM6.5 35.25H41.5C42.7426 35.25 43.75 36.2574 43.75 37.5C43.75 38.7426 42.7426 39.75 41.5 39.75H6.5C5.25736 39.75 4.25 38.7426 4.25 37.5C4.25 36.2574 5.25736 35.25 6.5 35.25ZM3 37.5C3 35.567 4.567 34 6.5 34H41.5C43.433 34 45 35.567 45 37.5C45 39.433 43.433 41 41.5 41H6.5C4.567 41 3 39.433 3 37.5Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
