import React from 'react';
import { useOverrides } from '@shared/utils/overrides';
import { forwardRef } from '@shared/utils/forwardRef';
import { getListItemStyles, StyledList } from './Menu.styles';
import { MenuItemOverrides, MenuItemProps, SharedMenuItemProps } from './Menu.types';
import { Box } from '../Box';
import { TextV2 } from '../TextV2';
import { PopoverV2 } from '../PopoverV2';
import { DISABLED_MENU_ITEM_PROPS, SUB_MENU_POPPER_OPTIONS } from './Menu.constants';
import { ContentOrRenderFn } from '@withjoy/joykit/utils';

const defaultProps = {
  overrides: {} as MenuItemOverrides
} as const;

const defaultComponents = {
  ListItem: Box,
  SubMenu: StyledList,
  Content: TextV2
} as const;

const renderContent = (content: ContentOrRenderFn<SharedMenuItemProps>, sharedProps: SharedMenuItemProps) => {
  return typeof content === 'function' ? content(sharedProps) : content;
};

const MenuItem = forwardRef<'li', MenuItemProps>((props, ref) => {
  const { children, content, isActive, isDisabled, isHighlighted, overrides, ...restProps } = props as typeof props & typeof defaultProps;

  const {
    ListItem: [ListItem, listItemOverrides],
    SubMenu: [SubMenu, subMenuOverrides],
    Content: [Content, contentProps]
  } = useOverrides(defaultComponents, overrides);

  const sharedProps: SharedMenuItemProps = {
    $isActive: !!isActive,
    $isDisabled: !!isDisabled,
    $isHighlighted: !!isHighlighted
  };
  const listItemStyles = getListItemStyles(sharedProps);
  const maybeDisabledProps = sharedProps.$isDisabled ? DISABLED_MENU_ITEM_PROPS : {};

  const maybeRenderPopover = (target: React.ReactNode) => {
    if (!children) {
      return target;
    }

    return (
      <PopoverV2 onMouseLeaveDelay={400} placement="rightStart" popperOptions={SUB_MENU_POPPER_OPTIONS} triggerType="hover">
        <PopoverV2.Trigger>{target}</PopoverV2.Trigger>
        <PopoverV2.Content>
          <SubMenu {...subMenuOverrides}>{children}</SubMenu>
        </PopoverV2.Content>
      </PopoverV2>
    );
  };

  return (
    <ListItem as="li" ref={ref} role="option" {...restProps} {...maybeDisabledProps} {...listItemStyles} {...listItemOverrides}>
      {maybeRenderPopover(
        <Content tagName="div" truncate={true} width="100%" {...contentProps}>
          {renderContent(content, sharedProps)}
        </Content>
      )}
    </ListItem>
  );
});

MenuItem.defaultProps = defaultProps;
export { MenuItem };
