import { useEffect, useRef, useState } from 'react';
import globalWindow from '@shared/core/globals';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';
import { withWindow } from '@shared/utils/withWindow';

export type CleanupFunction = () => void;

export type ApplicatorResolveValue = Readonly<{
  hasAppliedSprites: boolean;
  cleanUp: CleanupFunction;
}>;

export type GatherApplicatorCallsCallback = (applicator: Window['StyleApplicator']) => Promise<ApplicatorResolveValue>[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useStyleApplicator = (gatherApplicatorCalls: GatherApplicatorCallsCallback, dependencies: unknown[]) => {
  const mounted = useIsMounted();
  const [loading, setIsLoading] = useState(true);
  const handler = useEventCallback((styleApplicator: Window['StyleApplicator']) => {
    return gatherApplicatorCalls(styleApplicator);
  });

  const appliedSpritesRef = useRef<CleanupFunction[]>([]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    withWindow(() => {
      if (!mounted) {
        return;
      }

      function tryApplyingStyles() {
        setIsLoading(true);
        if (!globalWindow.StyleApplicator) {
          return;
        }
        Promise.all(handler(globalWindow.StyleApplicator))
          .then(results => {
            const currentSprites = appliedSpritesRef.current;
            appliedSpritesRef.current = [];
            currentSprites.forEach(cleanUp => cleanUp?.());

            results.forEach(result => {
              if (result.hasAppliedSprites) {
                appliedSpritesRef.current.push(result.cleanUp);
              }
            });
          })
          .finally(() => setIsLoading(false));
      }

      // NOT FUN, need to wait for the style-applicator script to
      // be loaded in the Helmet, i.e. <Head /> of the page
      // see GuestSiteHead.tsx
      const waitForStyles = (time: number) => {
        if (globalWindow.StyleApplicator) {
          return tryApplyingStyles();
        }
        timeout = setTimeout(() => {
          waitForStyles(time * 1.5);
        }, time);
      };
      waitForStyles(200);
    });
    return () => {
      if (timeout) {
        withWindow(() => {
          clearTimeout(timeout);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, handler, ...dependencies]);

  return { loading };
};
