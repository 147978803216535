import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useCallback, useState } from 'react';

export interface UseDiscloseArgs
  extends Readonly<{
    isOpen?: boolean;
    defaultIsOpen?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
  }> {}

const useControllableProp = <T>(prop: T | undefined, state: T) => {
  const isControlled = prop !== undefined;
  const value = isControlled && typeof prop !== 'undefined' ? prop : state;
  return [isControlled, value] as const;
};

/**
 * Intended to be used with feedback components that have `open` and `close` functionality
 * such as `Dialog`, `Drawer`, etc.
 *
 * @example
 *
 * ```javascript
 * const MyComponent = () => {
 *  const { isOpen, onOpen, onClose } = useDisclosure();
 *  <button onClick={onOpen}>Trigger</button>
 *
 *  return <Dialog isOpen={isOpen} onClose={onClose}>
 *    <Dialog.CloseButton />
 *    <Dialog.Body>Content to display</Dialog.body>
 *  </Dialog>
 * }
 * ```
 */
export const useDisclosure = (args: UseDiscloseArgs = {}) => {
  const { isOpen: isOpenProp, defaultIsOpen = false, onOpen: onOpenProp, onClose: onCloseProp } = args;

  const [isOpenState, setIsOpen] = useState(defaultIsOpen);
  const [isControlled, isOpen] = useControllableProp(isOpenProp, isOpenState);

  const onClose = useEventCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    onCloseProp?.();
  });

  const onOpen = useEventCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    onOpenProp?.();
  });

  const onToggle = useCallback(() => {
    const handler = isOpen ? onClose : onOpen;
    handler();
  }, [isOpen, onClose, onOpen]);

  return {
    isControlled,
    isOpen: !!isOpen,
    onClose,
    onOpen,
    onToggle
  } as const;
};

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>;
