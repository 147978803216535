import React from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { HotelProduct } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';

export const AccommodationTileEyebrow: React.FC<{ displayName: Maybe<string> }> = ({ displayName }) => {
  return (
    <Flex columnGap={3} paddingBottom={3}>
      <HotelProduct size={'sm'} />
      <TextV2 fontFamily={'Inter UI'} fontWeight={600} fontSize={14} letterSpacing={pxToRem(-0.035)}>
        {displayName}
      </TextV2>
    </Flex>
  );
};
