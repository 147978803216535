/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'House',
  definitions: {
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M15.199 2.616c.466-.381 1.136-.381 1.602 0l12.667 10.342c.295.24.465.601.465.981v2.398a1.27 1.27 0 0 1-2.064.984l-1.992-1.616v12.179.002c-.001.476-.195.93-.535 1.264s-.797.518-1.272.52h-.002-4.105-.02c-.007 0-.013 0-.02 0h-7.96-.02c-.007 0-.013 0-.02 0H7.819h-.002c-.475-.001-.933-.187-1.272-.52s-.533-.787-.535-1.264v-.002-12.087l-1.879 1.524a1.27 1.27 0 0 1-2.065-.984v-2.398c0-.38.171-.741.466-.981L15.199 2.616zM7.21 14.824v13.058a.58.58 0 0 0 .175.411c.113.111.269.176.434.177h3.524v-6.376c.003-1.137.457-2.226 1.26-3.03s1.893-1.257 3.03-1.26h.002.617c1.137.003 2.226.456 3.03 1.26s1.257 1.893 1.26 3.03v.002 6.374h3.524c.165-.001.321-.065.434-.177s.174-.258.175-.41V14.732l-8.635-7.003c-.025-.02-.06-.02-.084 0L7.21 14.824zm12.133 13.646v-6.373c-.003-.819-.329-1.605-.909-2.184s-1.365-.906-2.184-.909h-.614c-.82.003-1.605.329-2.184.909s-.906 1.365-.909 2.184v6.373h6.8zM16.042 3.545c-.024-.02-.06-.02-.084 0L3.291 13.887c-.015.013-.025.032-.025.052v2.398c0 .021.005.03.009.036s.015.017.029.024.028.008.037.007.017-.003.034-.016l11.827-9.592c.465-.377 1.131-.377 1.596 0l11.827 9.592c.016.013.027.015.034.016s.023-.001.037-.007.024-.016.029-.024.009-.015.009-.036v-2.398c0-.02-.009-.039-.024-.052L16.042 3.545z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M23 4.103c.588-.458 1.412-.458 1.999 0l19 14.824c.395.308.625.781.625 1.281v3.368c0 1.351-1.553 2.112-2.62 1.284l-3.465-2.685V41.84a2.39 2.39 0 0 1-.72 1.7 2.46 2.46 0 0 1-1.716.701H29.92h-12-6.194a2.46 2.46 0 0 1-1.716-.701 2.39 2.39 0 0 1-.72-1.7v-.002-19.531L5.995 24.86a1.63 1.63 0 0 1-2.62-1.284v-3.368c0-.501.231-.973.625-1.281L23 4.103zM10.54 21.338v20.498a1.14 1.14 0 0 0 .345.811 1.21 1.21 0 0 0 .844.344h5.561v-9.836-.002a6.18 6.18 0 0 1 6.162-6.162h.002.925a6.18 6.18 0 0 1 6.162 6.162v.002 9.837h5.56c.319-.001.623-.126.845-.344a1.14 1.14 0 0 0 .345-.811v-20.63L24.23 11.085c-.135-.105-.324-.105-.459 0L10.54 21.338zm18.75 21.653v-9.836a4.93 4.93 0 0 0-4.914-4.914h-.921a4.93 4.93 0 0 0-4.914 4.914v9.836h10.75zM24.231 5.089c-.136-.106-.326-.106-.461 0l-19 14.824c-.091.071-.144.18-.144.296v3.368c0 .312.358.487.605.296l17.775-13.775c.586-.454 1.405-.454 1.991 0L42.77 23.872c.246.191.605.015.605-.296v-3.368c0-.116-.053-.225-.144-.296l-19-14.824z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M30.667 5.487c.783-.611 1.882-.611 2.666 0l25.333 19.765c.526.411.834 1.041.834 1.708v4.49a2.17 2.17 0 0 1-3.494 1.713l-4.653-3.606V55.8v.002c-.003.845-.346 1.651-.95 2.243a3.25 3.25 0 0 1-2.265.925h-.002-8.242-16-8.256-.002a3.25 3.25 0 0 1-2.265-.925c-.604-.592-.947-1.399-.95-2.243V55.8 29.733l-4.426 3.43A2.17 2.17 0 0 1 4.5 31.451v-4.49c0-.667.308-1.298.834-1.708L30.667 5.487zM14.02 28.493v27.306c.001.412.169.809.47 1.105a1.65 1.65 0 0 0 1.148.468h7.448V44.222v-.002c.006-2.168.87-4.246 2.404-5.779s3.611-2.397 5.779-2.404h.002 1.231.002c2.168.006 4.246.87 5.779 2.404s2.397 3.611 2.404 5.779v.002 13.149h7.448a1.65 1.65 0 0 0 1.148-.468c.302-.296.469-.693.47-1.105v-.001-27.48L32.306 14.796a.5.5 0 0 0-.613 0L14.02 28.493zm25.067 28.878V44.224c-.005-1.745-.701-3.417-1.935-4.651s-2.906-1.929-4.651-1.935h-1.228c-1.745.005-3.417.701-4.651 1.935s-1.93 2.906-1.935 4.651v13.148h14.4zm-6.779-50.57a.5.5 0 0 0-.615 0L6.359 26.566a.5.5 0 0 0-.192.394v4.49a.5.5 0 0 0 .806.395l23.7-18.367c.781-.605 1.873-.605 2.654 0l23.7 18.367a.5.5 0 0 0 .806-.395v-4.49a.5.5 0 0 0-.192-.394L32.308 6.801z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
