import { isInIframe } from '@shared/utils/isInIframe';
import { ColorSetFragment, EventDesignFragment } from '@graphql/generated';
import { colorPointerUtils, EventDesignPreferences, parseColorPalette, parseDesignPreferences } from '@shared/utils/websiteDesign';
import { GuestSiteEventDesign, LayoutConfigAloha, LayoutThemeOverrides, ApplicatorConfigVariantsAloha } from '../layout.types';
import chroma from 'chroma-js';
import { useStyleApplicator } from '../../common/utils/useStyleApplicator';
import { Theme, createTheme } from '@withjoy/joykit';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useMemo } from 'react';
import globalWindow from '@shared/core/globals';
import { Colors, createGlobalStyle, pxToRem } from '@withjoy/joykit/theme';
import memoizeOne from 'memoize-one';
import { CreateThemeArgs } from '@withjoy/joykit/theme';
import { mergeTypograhyThemeOverrides } from '../../common/theming';
import { useFeatureValue } from '@shared/core/featureFlags';

const isIframed = isInIframe();

const THEME_DEFAULTS: CreateThemeArgs['defaults'] = {
  breakpointWidths: [isIframed ? 375 : 480, 960, 1024, 1440, 1920]
};

export const getBrannanMobileMediaQuery = (theme: Theme) => theme.mediaQueries.between(0, { breakpointAlias: 'sm4' });

export const MAX_CONTENT_WIDTH = 376;

export const CONTENT_BLOCK_MAX_WIDTH = {
  _: 'auto',
  md: `calc(${pxToRem(MAX_CONTENT_WIDTH)} + 4rem)`
};

export const CONTENT_BLOCK_PADDING__X = {
  _: 7,
  md: 'auto'
};

////////////////////////////////////////////////////////////////

const _darkenColor = (color: string) => {
  // taken from monorepo
  return chroma(
    chroma(color)
      .rgb()
      .map(x => x * 0.8)
  ).hex();
};

// TODO: Darken color when constrast falls below standards
const generateThemableColorValues = memoizeOne(
  (color?: string): DeepPartial<Colors> => {
    if (color) {
      const baseColorDarkened = _darkenColor(color);
      const colorHover = chroma(baseColorDarkened).brighten(0.2).hex();
      const colorActive = chroma(baseColorDarkened).darken(0.2).hex();

      return {
        linkVisited: chroma(baseColorDarkened).darken(0.3).hex(),
        linkText: baseColorDarkened,
        linkHover: colorHover,
        linkActive: colorActive,

        buttonPrimaryText: '#FFFFFF',
        buttonPrimaryFill: baseColorDarkened,
        buttonPrimaryHover: colorHover,
        buttonPrimaryActive: colorActive,

        buttonOutlinePrimaryText: baseColorDarkened,
        buttonOutlinePrimaryTextHover: colorHover,
        buttonOutlinePrimaryTextPress: colorActive,
        buttonOutlinePrimaryFillHover: chroma(baseColorDarkened).alpha(0.1).css(),
        buttonOutlinePrimaryFillPress: chroma(baseColorDarkened).alpha(0.2).css(),

        scrollbarHandle: `rgba(${chroma(color).alpha(0.5).rgba().join(', ')})`
      };
    }
    return {};
  }
);

export const getThemeOverrides = (overrides: LayoutThemeOverrides = {}): DeepPartial<Theme> => {
  const { eventFontFamily, textTransform = 'none' } = overrides.typography || {};
  const { primaryFillColor } = overrides.colors || {};

  const themableColors: DeepPartial<Colors> = generateThemableColorValues(primaryFillColor);
  const typography = mergeTypograhyThemeOverrides({
    primaryFontFamily: eventFontFamily,
    variants: {
      display1: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display2: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display3: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display4: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display5: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display6: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display7: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display8: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display9: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display10: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display11: {
        fontFamily: eventFontFamily,
        textTransform
      }
    }
  });
  return {
    typography,
    colors: {
      ...themableColors
    }
  };
};

export const alohaConfig: LayoutConfigAloha = {
  applicatorHtmlProps: {
    simple: {
      prop: { 'data-layer': 'simple' },
      selector: '[data-layer="simple"]'
    }
  },
  theme: createTheme({ defaults: THEME_DEFAULTS, overrides: getThemeOverrides() }), // extendTheme(getThemeOverrides()),
  configureThemeOverrides: (eventDesign, currentTheme) => {
    const { font, colorPalette } = eventDesign;
    const [primaryFill] = colorPalette;
    const overrides = getThemeOverrides({
      typography: { eventFontFamily: font.fontFamily, textTransform: font.textTransform },
      colors: { primaryFillColor: primaryFill.color.hex }
    });
    return createTheme({ defaults: THEME_DEFAULTS, overrides });
  },
  fonts: {
    google: ['greatVibes']
  },
  previewEventHandlers: {
    onLayoutChange: ({ history, eventPageRoutes }) => {
      if (eventPageRoutes.welcome) {
        history.push({ pathname: eventPageRoutes.welcome.path });
      }
      globalWindow.scrollTo?.(0, 0);
    },
    onThemeChange: () => {
      globalWindow.scrollTo?.(0, 0);
    },
    onFontChange: history => {
      globalWindow.scrollTo?.(0, 0);
    },
    onGraphicAccentChange: () => {
      const el = globalWindow.document?.getElementById('graphic-accent');
      el?.scrollIntoView();
    },
    onPrimaryFillColorChange: ({ history, eventPageRoutes }) => {
      if (eventPageRoutes.schedule) {
        const historySub = history.listen(() => {
          globalWindow.setTimeout(() => {
            const el = globalWindow.document?.getElementById('schedule');
            el?.scrollIntoView();
          }, 500);
          historySub();
        });
        history.push({ pathname: eventPageRoutes.schedule.path });
        return [historySub];
      }
      return [];
    }
  }
};

////////////////////////////////////////////////////////////////

export const getAlohaApplicatorConfig = (
  designPreferences: EventDesignPreferences,
  colorPalette: ReadonlyArray<ColorSetFragment>,
  websiteLayoutAlohaSettings: EventDesignFragment['theme']['websiteLayoutAlohaSettings']
): ApplicatorConfigVariantsAloha => {
  const { alohaColorPreference } = parseDesignPreferences(designPreferences);

  const { primaryFillColor, primaryTextColor } = parseColorPalette(colorPalette);

  const useSuggestedBackgroundColor = colorPointerUtils.isThemePointer(alohaColorPreference?.colorPointerPosition ?? undefined);

  return {
    simple: {
      customBackgroundColor:
        alohaColorPreference && primaryTextColor && primaryFillColor
          ? {
              isEnabled: !useSuggestedBackgroundColor,
              textColor: chroma(primaryTextColor.color.hex).rgba(),
              backgroundColor: chroma(primaryFillColor.color.hex).rgba()
            }
          : undefined
    }
  };
};

////////////////////////////////////////////////////////////////

export const useAlohaDesign = (eventDesign: GuestSiteEventDesign) => {
  const { designPreferences, colorPalette, customCss } = eventDesign || {};
  const themeId = eventDesign?.theme.themeId;
  const isSmallScreen = useMediaQuery(getBrannanMobileMediaQuery);

  // FeatureFlag => wait for StyleApplicator https://github.com/joylifeinc/joy-web/pull/3214
  const styledApplicatorLoading = useFeatureValue('enableLoadingStyleApplicator');

  // Get applicator config based on user design preferences
  const applicatorConfig = useMemo(() => {
    return designPreferences && colorPalette ? getAlohaApplicatorConfig(designPreferences, colorPalette, eventDesign?.theme.websiteLayoutAlohaSettings) : undefined;
  }, [designPreferences, colorPalette, eventDesign]);

  const { loading } = useStyleApplicator(
    styleApplicator => {
      if (themeId) {
        const { simple } = alohaConfig.applicatorHtmlProps;

        // Apply styles to frames
        return [styleApplicator.applyCompositionSprites(simple.selector, themeId, 'simple', 'content', applicatorConfig?.simple)];
      }
      return [];
    },
    [themeId, applicatorConfig]
  );

  return { applicatorConfig, isSmallScreen, loading: styledApplicatorLoading ? loading : false, CustomStyle: customCss ? createGlobalStyle`${customCss}` : null };
};
