/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Schedule',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M17.567 2.625a.6.6 0 0 0-1.2 0v.398h-8.39v-.398a.6.6 0 1 0-1.2 0v.398H4.524C3.11 3.023 2.05 4.26 2.05 5.67v12.095c0 1.41 1.06 2.647 2.475 2.647h8.25a.6.6 0 1 0 0-1.2h-8.25c-.656 0-1.275-.597-1.275-1.447V9.488H20.8v3.06a.6.6 0 1 0 1.2 0V5.67c0-1.41-1.06-2.648-2.475-2.648h-1.957v-.398zm0 1.598h1.957c.656 0 1.275.597 1.275 1.447v2.618H3.25V5.67c0-.85.62-1.447 1.275-1.447h2.252v1.18a.6.6 0 1 0 1.2 0v-1.18h8.39v1.18a.6.6 0 0 0 1.2 0v-1.18zm.645 9.752a3.4 3.4 0 0 0 0 6.8 3.4 3.4 0 0 0 0-6.8zm-4.6 3.4a4.6 4.6 0 1 1 9.2 0 4.6 4.6 0 1 1-9.2 0zm5.2-2a.6.6 0 1 0-1.2 0v2.25l1.176 1.176a.6.6 0 0 0 .848-.848l-.824-.824v-1.75z"
            fill="currentColor"
          ></path>
        </>
      )
    },
    '48': {
      viewBox: '0 0 40 38',
      path: (
        <>
          <path
            d="M30.4997 24.0014C30.8448 24.0014 31.1247 24.2812 31.1247 24.6264V29.2409L33.3783 31.4945C33.6224 31.7386 33.6224 32.1343 33.3783 32.3784C33.1342 32.6224 32.7385 32.6224 32.4944 32.3784L30.0577 29.9417C29.9405 29.8245 29.8747 29.6655 29.8747 29.4998V24.6264C29.8747 24.2812 30.1545 24.0014 30.4997 24.0014Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.305 0.625C10.6502 0.625 10.93 0.904822 10.93 1.25V2.48275H27.5301V1.47559C27.5301 1.13041 27.8099 0.850589 28.1551 0.850589C28.5002 0.850589 28.7801 1.13041 28.7801 1.47559V2.48275H32.9142C35.237 2.48275 37.0285 4.52119 37.0285 6.91768V23.1138C38.3346 24.6255 39.1246 26.5955 39.1246 28.75C39.1246 33.5135 35.2631 37.375 30.4996 37.375C27.6499 37.375 25.123 35.993 23.5526 33.8626H4.99711C2.67426 33.8626 0.882812 31.8242 0.882812 29.4277V6.91768C0.882812 4.52119 2.67426 2.48275 4.99711 2.48275H9.68003V1.25C9.68003 0.904822 9.95986 0.625 10.305 0.625ZM2.13281 29.4277C2.13281 31.2396 3.46579 32.6126 4.99711 32.6126H22.7858C22.2028 31.4506 21.8747 30.1387 21.8747 28.75C21.8747 23.9866 25.7362 20.1251 30.4996 20.1251C32.4882 20.1251 34.3196 20.798 35.7785 21.9287V11.5313H2.13281V29.4277ZM10.305 7.04496C9.95986 7.04496 9.68003 6.76514 9.68003 6.41996V3.73275H4.99711C3.46579 3.73275 2.13281 5.10584 2.13281 6.91768V10.2813H35.7785V6.91768C35.7785 5.10584 34.4455 3.73275 32.9142 3.73275H28.7801V6.6482C28.7801 6.99338 28.5002 7.2732 28.1551 7.2732C27.8099 7.2732 27.5301 6.99338 27.5301 6.6482V3.73275H10.93V6.41996C10.93 6.76514 10.6502 7.04496 10.305 7.04496ZM23.1247 28.75C23.1247 24.6769 26.4265 21.3751 30.4996 21.3751C34.5727 21.3751 37.8746 24.6769 37.8746 28.75C37.8746 32.8231 34.5727 36.125 30.4996 36.125C26.4265 36.125 23.1247 32.8231 23.1247 28.75Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
