/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Direction',
  definitions: {
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M 16 3 C 15.23 3 14.457 3.293 13.875 3.875 L 13.75 4.03125 L 4.03125 13.75 L 3.875 13.875 C 2.711 15.039 2.711 16.961 3.875 18.125 L 13.875 28.125 C 15.039 29.289 16.961 29.289 18.125 28.125 L 28.125 18.125 C 29.289 16.961 29.289 15.039 28.125 13.875 L 18.125 3.875 C 17.543 3.293 16.77 3 16 3 z M 16 5 C 16.254 5 16.51975 5.08225 16.71875 5.28125 L 26.71875 15.28125 C 27.11675 15.67925 27.11675 16.31975 26.71875 16.71875 L 16.71875 26.71875 C 16.32075 27.11675 15.68025 27.11675 15.28125 26.71875 L 5.28125 16.71875 C 4.88325 16.32075 4.88325 15.68025 5.28125 15.28125 L 15.28125 5.28125 C 15.48025 5.08225 15.746 5 16 5 z M 17 11 L 17 14 L 13 14 C 11.895 14 11 14.895 11 16 L 11 19 L 13 19 L 13 16 L 17 16 L 17 19 L 21 15 L 17 11 z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
