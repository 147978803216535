import React, { HTMLProps } from 'react';
import { animated, useSpring } from 'react-spring';

import { TRIGGER_TYPE } from './Popover.constants';
import {
  PopoverStylesProvider,
  popoverStyles,
  usePopoverStyles,
  PopoverRoot as StyledPopoverRoot,
  PopoverContent as StyledPopoverContent,
  PopoverArrow as StyledPopoverArrow,
  PopoverBody as StyledPopoverBody
} from './Popover.styles';
import type { PopoverInternalState, Placement, PopoverV2Props, PopoverContentProps } from './Popover.types';
import { getArrowPlacement } from './Popover.utils';
import { useOverrides } from '@shared/utils/overrides';
import { createDisplayName } from '@withjoy/joykit/utils';
import { forwardRef } from '@shared/utils/forwardRef';

import { createContext } from '@shared/utils/createContext';
import { PropGetter } from '@withjoy/joykit/utils';
import { useStyleConfig } from '@shared/joykit/packages/core/common/utils/styleConfig';
import { usePopover } from './usePopover';

export const defaultProps = {
  disableCloseOnBlur: false as boolean,
  disableCloseOnEscapeKeyClick: false as boolean,
  onMouseEnterDelay: 0 as number,
  onMouseLeaveDelay: 300 as number,
  placement: 'bottom' as Placement,
  showArrow: false as boolean,
  triggerType: TRIGGER_TYPE.click,
  overrides: {} as NonNullable<PopoverV2Props['overrides']>
} as const;

type PopoverContext = {
  isOpen: boolean;
  closePopover: () => void;
  overrides: NonNullable<PopoverV2Props['overrides']>;
  showArrow: boolean;
  shouldKeepOverlayOpen: boolean;

  getAnchorProps: PropGetter;
  getArrowProps: PropGetter;
  getPopoverProps: PropGetter;
  getTriggerProps: PropGetter;
  setVisibilityForKey: (key: 'content', isOpen: boolean) => void;
} & Pick<PopoverInternalState, 'arrowOffset' | 'placement' | 'transformOrigin'>;

const [PopoverProvider, usePopoverContext] = createContext<PopoverContext>({ name: 'Popover' });

/**
 * Low-level component that has listeners for the relevant events based on `triggerType`.
 */
const PopoverV2 = (props: PopoverV2Props & typeof defaultProps) => {
  const { children, overrides, showArrow } = props;
  const { onClose, ...popover } = usePopover(props);
  const styles = useStyleConfig(popoverStyles, props);

  return (
    <PopoverProvider value={{ ...popover, closePopover: onClose, overrides, showArrow }}>
      <PopoverStylesProvider value={styles}>{children}</PopoverStylesProvider>
    </PopoverProvider>
  );
};

const PopoverAnchor: React.FC = props => {
  const { getAnchorProps } = usePopoverContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anchor: any = React.Children.only(props.children);

  return React.cloneElement(anchor, getAnchorProps(anchor.props, anchor.ref));
};

const PopoverTrigger: React.FC<HTMLProps<HTMLDivElement>> = props => {
  const { getTriggerProps } = usePopoverContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trigger: any = React.Children.only(props.children);

  return React.cloneElement(trigger, getTriggerProps(trigger.props, trigger.ref));
};

const PopoverContent = forwardRef<'section', PopoverContentProps>((props, ref) => {
  const { children, ...restProps } = props;
  const { arrowOffset, isOpen, overrides, placement, transformOrigin, getArrowProps, getPopoverProps, showArrow, shouldKeepOverlayOpen, setVisibilityForKey } = usePopoverContext();

  const springs = useSpring({
    opacity: isOpen ? 1 : 0,
    scale: isOpen ? 1 : 0.3,
    config: {
      clamp: true
    },
    onFrame: (styles: { opacity: number }) => {
      setVisibilityForKey('content', styles.opacity > 0);
    }
  });

  const {
    Root: [Root, rootProps],
    Body: [Body, bodyProps],
    Arrow: [Arrow, arrowProps],
    Content: [Content, contentProps]
  } = useOverrides({ Root: StyledPopoverRoot, Body: StyledPopoverBody, Arrow: StyledPopoverArrow, Content: StyledPopoverContent }, overrides);

  const styles = usePopoverStyles();

  const arrowStyles = {
    ...styles.arrow,
    ...getArrowPlacement(placement, arrowOffset)
  };

  return isOpen || shouldKeepOverlayOpen ? (
    <Root as={animated.section} __css={styles.root} tabIndex={-1} {...getPopoverProps(rootProps, ref)} style={{ opacity: springs.opacity }}>
      <Content
        as={animated.div}
        __css={styles.content}
        style={{ transformOrigin, transform: springs.scale?.interpolate(x => `translate3d(0,0,0) scale(${x})`) }}
        {...contentProps}
        {...restProps}
      >
        {showArrow && <Arrow {...getArrowProps(arrowProps)} __css={arrowStyles} />}

        <Body __css={styles.body} {...bodyProps}>
          {children}
        </Body>
      </Content>
    </Root>
  ) : null;
});

PopoverV2.defaultProps = defaultProps;
PopoverV2.Anchor = PopoverAnchor;
PopoverV2.Trigger = PopoverTrigger;
PopoverV2.Content = PopoverContent;

PopoverV2.displayName = createDisplayName('PopoverV2');
PopoverAnchor.displayName = createDisplayName('PopoverAnchor');
PopoverTrigger.displayName = createDisplayName('PopoverTrigger');
PopoverContent.displayName = createDisplayName('PopoverContent');

export { PopoverV2, usePopoverContext };
