// eslint-disable-next-line no-restricted-imports
import type { CSSObject } from 'styled-components';
import { ThemeStyledProps } from '@withjoy/joykit';
import { getMediaQueries } from '@withjoy/joykit/utils';
import type { ColumnV2Props } from './';
import type { ColumnsContextType } from '../../Columns.context';
import type { ColumnEigths, ColumnFifths, ColumnWidthNumeric, ColumnWidth } from '../../Columns.types';

const COLUMN_EIGTHS: ReadonlyArray<ColumnEigths> = ['one-eigth', 'one-fourth', 'three-eigths', 'one-half', 'five-eigths', 'three-fourths', 'seven-eigths'];
const COLUMN_FIFTHS: ReadonlyArray<ColumnFifths> = ['one-fifth', 'two-fifths', 'three-fifths', 'four-fifths'];
const COLUMN_SIZES: ReadonlyArray<ColumnWidthNumeric> = Array.from(Array(13)).map((_, idx) => idx as ColumnWidthNumeric);

/**
 * Default level of precision in node-sass is 5 decimal places.
 * Bootstrap v5 increased the rounding precision to 6 to prevent issues with browser rounding
 */
const PRECISION = 1e6;

/**
 *
 * @example
 *  round(100 / 12 * 8) === 66.666667
 *  round(100 / 12 * 1) ===  8.333333
 */
export const roundToPrecision = (num: number, precision: number = PRECISION) => {
  return Math.round(num * PRECISION) / PRECISION;
};

function calculateWidthPercentage(span: number, columnCount: number, format?: 'string'): string;
function calculateWidthPercentage(span: number, columnCount: number, format?: 'number'): number;
function calculateWidthPercentage(span: number, columnCount: number, format: 'string' | 'number' = 'string'): string | number {
  const value = roundToPrecision((100 / columnCount) * span);
  return format === 'number' ? value : `${value}%`;
}

const getWidthToPercentageMap = <T extends string | number>(widths: ReadonlyArray<T>, columnCount: number): Readonly<Record<T, string>> => {
  const offset = typeof widths[0] === 'string' ? 1 : 0;
  return widths.reduce((acc, curr, idx) => {
    acc[curr] = calculateWidthPercentage(idx + offset, columnCount);
    return acc;
  }, {} as Record<T, string>);
};

const eigthValues = getWidthToPercentageMap(COLUMN_EIGTHS, 8);
const fifthValues = getWidthToPercentageMap(COLUMN_FIFTHS, 5);
const standardValues = getWidthToPercentageMap(COLUMN_SIZES, 12);

export const getColumnLayout = (props: ThemeStyledProps<ColumnV2Props & ColumnsContextType>) => {
  const { theme, span, offset } = props;

  const mediaQueries = getMediaQueries(theme.breakpoints);
  const rules = mediaQueries.reduce<CSSObject>((acc, cur, idx) => {
    const offsetValue = getResponsiveValue(offset, idx);
    const spanValue = getResponsiveValue(span, idx);

    const style: CSSObject = {};

    if (offsetValue) {
      style.marginLeft = offsetValue;
    }

    if (spanValue) {
      style.flexGrow = 0;
      style.flexBasis = spanValue;
      style.maxWidth = spanValue;
    }

    acc[cur] = style;
    return acc;
  }, {});

  return rules;
};

// Exported to be tested.
export const getResponsiveValue = <T extends Nullable<ColumnWidth>>(scale: Maybe<T> | Maybe<ReadonlyArray<T>>, index: number): string | null => {
  let value: Nullable<ColumnWidth>;

  if (scale === undefined || scale === null) {
    value = null;
  } else if (!Array.isArray(scale)) {
    value = scale as T;
  } else if (typeof scale[index] === 'undefined') {
    value = scale[scale.length - 1];
  } else {
    value = scale[index];
  }

  if (typeof value === 'string') {
    if (value === 'full') {
      return standardValues[12];
    }
    return eigthValues[value as keyof typeof eigthValues] || fifthValues[value as keyof typeof fifthValues];
  } else {
    return value === null ? null : standardValues[value];
  }
};
