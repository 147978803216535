export const ANCHOR_AXIS = {
  left: {
    crossAxis: 'height',
    mainAxis: 'width'
  },
  right: {
    crossAxis: 'height',
    mainAxis: 'width'
  },
  top: {
    crossAxis: 'width',
    mainAxis: 'height'
  },
  bottom: {
    crossAxis: 'width',
    mainAxis: 'height'
  }
} as const;

export const ANCHOR_ORIGIN = {
  left: {
    x: 'left',
    y: 'top'
  },
  right: {
    x: 'right',
    y: 'top'
  },
  top: {
    x: 'left',
    y: 'top'
  },
  bottom: {
    x: 'left',
    y: 'bottom'
  }
} as const;

export const SIZE_DIMENSION = {
  full: '100%',
  sm: `${360 / 16}rem`
} as const;

export const DRAWER_TRANSFORMS = {
  left: {
    in: 'translateX(0)',
    out: `translateX(-100%)`
  },
  right: {
    in: 'translateX(0)',
    out: `translateX(100%)`
  },
  top: {
    in: 'translateY(0)',
    out: `translateY(-100%)`
  },
  bottom: {
    in: 'translateY(0)',
    out: `translateY(100%)`
  }
};
