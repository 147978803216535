/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Add To Calendar',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.57522 2.0625C5.57522 1.75874 5.32898 1.5125 5.02522 1.5125C4.72147 1.5125 4.47522 1.75874 4.47522 2.0625V2.45H3.19992C2.20665 2.45 1.45001 3.28991 1.45001 4.2645V6.07089V11.7355C1.45001 12.7101 2.20665 13.55 3.19992 13.55H7.40001C7.70377 13.55 7.95001 13.3038 7.95001 13C7.95001 12.6962 7.70377 12.45 7.40001 12.45H3.19992C2.8678 12.45 2.55001 12.1576 2.55001 11.7355V6.62089H13.45V8.08606C13.45 8.38982 13.6963 8.63606 14 8.63606C14.3038 8.63606 14.55 8.38982 14.55 8.08606V4.2645C14.55 3.28991 13.7934 2.45 12.8001 2.45H11.7135V2.14007C11.7135 1.83632 11.4673 1.59007 11.1635 1.59007C10.8598 1.59007 10.6135 1.83632 10.6135 2.14007V2.45H5.57522V2.0625ZM4.47522 3.55V3.84036C4.47522 4.14411 4.72147 4.39036 5.02522 4.39036C5.32898 4.39036 5.57522 4.14411 5.57522 3.84036V3.55H10.6135V3.91884C10.6135 4.2226 10.8598 4.46884 11.1635 4.46884C11.4673 4.46884 11.7135 4.2226 11.7135 3.91884V3.55H12.8001C13.1322 3.55 13.45 3.84236 13.45 4.2645V5.52089H2.55001V4.2645C2.55001 3.84236 2.8678 3.55 3.19992 3.55H4.47522ZM12.85 10.3391C12.85 10.0353 12.6038 9.78906 12.3 9.78906C11.9963 9.78906 11.75 10.0353 11.75 10.3391V11.75H10.3547C10.0509 11.75 9.8047 11.9962 9.8047 12.3C9.8047 12.6038 10.0509 12.85 10.3547 12.85H11.75V14.2391C11.75 14.5428 11.9963 14.7891 12.3 14.7891C12.6038 14.7891 12.85 14.5428 12.85 14.2391V12.85H14.2547C14.5585 12.85 14.8047 12.6038 14.8047 12.3C14.8047 11.9962 14.5585 11.75 14.2547 11.75H12.85V10.3391Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.138 3.094a.6.6 0 0 0-1.2 0V3.9H4.8c-1.355 0-2.4 1.148-2.4 2.497v11.206c0 1.35 1.045 2.497 2.4 2.497h6.3a.6.6 0 1 0 0-1.2H4.8c-.634 0-1.2-.55-1.2-1.297V9.706h16.8v2.423a.6.6 0 1 0 1.2 0V6.397c0-1.35-1.045-2.497-2.4-2.497h-1.855v-.69a.6.6 0 0 0-1.2 0v.69H8.138v-.806zM6.938 5.1v.66a.6.6 0 0 0 1.2 0V5.1h8.007v.778a.6.6 0 1 0 1.2 0V5.1H19.2c.634 0 1.2.55 1.2 1.297v2.11H3.6v-2.11c0-.746.566-1.297 1.2-1.297h2.138zM18.4 14.6a.6.6 0 0 0-1.2 0v2.5h-2.6a.6.6 0 0 0 0 1.2h2.6v2.6a.6.6 0 1 0 1.2 0v-2.6h2.5a.6.6 0 1 0 0-1.2h-2.5v-2.5z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.225 2.733a.6.6 0 0 1 .6.6v.639h10.7v-.488a.6.6 0 1 1 1.2 0v.488h2.573c1.664 0 2.926 1.456 2.926 3.14v8.242a8 8 0 1 1-11.996 9.905H5.687v-1.2H14.759a8 8 0 0 1 11.266-9.451V10.37H3.96v11.747c0 1.123.821 1.94 1.726 1.94v1.2c-1.664 0-2.926-1.456-2.926-3.14V7.111c0-1.683 1.262-3.14 2.926-3.14h2.939v-.638a.6.6 0 0 1 .6-.6zm-.6 4.047V5.172H5.686c-.905 0-1.726.818-1.726 1.94V9.17h22.064V7.11c0-1.121-.822-1.94-1.726-1.94h-2.573v1.761a.6.6 0 1 1-1.2 0v-1.76h-10.7V6.78a.6.6 0 0 1-1.2 0zM29.22 21.75a6.8 6.8 0 1 1-13.6 0 6.8 6.8 0 0 1 13.6 0zm-6.64-4.646a.6.6 0 0 1 .6.6v3.607h3.295a.6.6 0 0 1 0 1.2H23.18v3.294a.6.6 0 1 1-1.2 0v-3.294h-3.607a.6.6 0 0 1 0-1.2h3.607v-3.608a.6.6 0 0 1 .6-.6z"
            fill="#333"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.337 5.875c.345 0 .625.28.625.625v1.233h16.6V6.726a.625.625 0 1 1 1.25 0v1.007h4.134c2.323 0 4.115 2.038 4.115 4.435V24.75a.627.627 0 0 1-.006.084 10.963 10.963 0 0 1 3.053 7.605c0 6.075-4.925 11-11 11a10.982 10.982 0 0 1-8.745-4.326H10.03v-.625-.625H24.535a10.949 10.949 0 0 1-1.427-5.424c0-6.075 4.924-11 11-11 2.523 0 4.847.85 6.703 2.278V16.78H7.165v17.897c0 1.812 1.333 3.185 2.864 3.185v1.25c-2.323 0-4.114-2.039-4.114-4.435V12.168c0-2.397 1.791-4.435 4.114-4.435h4.683V6.5c0-.345.28-.625.625-.625zm-.625 5.795V8.983H10.03c-1.531 0-2.864 1.373-2.864 3.185v3.363h33.646v-3.363c0-1.812-1.333-3.185-2.865-3.185h-4.134v2.915a.625.625 0 1 1-1.25 0V8.983h-16.6v2.687a.625.625 0 0 1-1.25 0zm29.146 20.77c0 5.384-4.366 9.75-9.75 9.75-5.385 0-9.75-4.366-9.75-9.75 0-5.386 4.365-9.75 9.75-9.75 5.384 0 9.75 4.364 9.75 9.75zm-9.745-6.5c.345 0 .624.279.624.624v5.255h5.246a.625.625 0 0 1 0 1.25h-5.245v5.245a.625.625 0 1 1-1.25 0V33.07h-5.255a.625.625 0 1 1 0-1.25h5.254v-5.255c0-.345.28-.625.625-.625z"
            fill="#333"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
