import React, { RefObject, useCallback } from 'react';
import { interval, range } from 'rxjs';
import { map, filter, zip } from 'rxjs/operators';
import BezierEasing from 'bezier-easing';

interface PageReference
  extends Readonly<{
    element: HTMLElement;
    top: number;
    bottom: number;
  }> {}

export const useScrollToY = (ref: RefObject<HTMLElement>) => {
  const getReferenceData = (ref: React.RefObject<HTMLElement>): PageReference | undefined => {
    if (ref.current) {
      return {
        element: ref.current,
        top: ref.current.offsetTop - 60,
        bottom: ref.current.offsetTop + ref.current.clientHeight
      };
    } else {
      return undefined;
    }
  };

  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    const easing = BezierEasing(0.23, 1, 0.32, 1);
    return b + c * easing(t / d);
  };

  const scrollToYOverTime = useCallback((endY: number, duration: number, onScrollFinish: () => void, frameInterval: number = 16) => {
    const _scrollToY = (value: number) =>
      window.requestAnimationFrame(() => {
        window.scrollTo(0, value);
      });
    const startY = document.documentElement && document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop;
    const scrollValueGenerator = (timeFromStart: number) => easeInOutQuad(timeFromStart, startY, endY - startY, duration);

    const scrollValueEmiter = range(0, duration).pipe(
      filter(n => n % frameInterval === 0 || n === duration - 1),
      map(scrollValueGenerator),
      // TODO: swap zip for zipWith
      zip(interval(frameInterval), a => a)
    );

    // TODO Use an observer instead of a complete callback
    scrollValueEmiter.subscribe(_scrollToY, undefined, onScrollFinish);
  }, []);

  const scrollToYCasually = useCallback(
    (endY: number, onScrollFinish: () => void, frameInterval: number = 16) => {
      const startY = document.documentElement && document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop;
      const idealRate = 1.5; // pixes per ms
      const duration = Math.abs(startY - endY) / idealRate;
      return scrollToYOverTime(endY, duration, onScrollFinish, frameInterval);
    },
    [scrollToYOverTime]
  );

  const scrollToRef = useCallback(
    (scrollCasually?: boolean, onScrollFinish: () => void = () => {}) => {
      const eleData = getReferenceData(ref);
      if (eleData) {
        if (scrollCasually) {
          scrollToYCasually(eleData.top + 2, onScrollFinish);
        } else {
          const offset = eleData.top;
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              window.scrollTo(0, offset);
            });
          });
        }
      }
    },
    [ref, scrollToYCasually]
  );

  return scrollToRef;
};
