import { animationTransition } from '@shared/utils/animationTransition';
import { Box, Flex } from '@withjoy/joykit';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ConsolidatedOrderStatus } from '../../PrintJobDetails.types';
import { useTranslation } from '@shared/core';

const TRACKER_WIDTH = 629;
const ITEM_MARGIN = 20;

export interface ProgressTrackerProps {
  status: ConsolidatedOrderStatus;
}

/**
 * The `status` prop values are tempory for now until we map the graph enum
 */
export const ProgressTracker = ({ status }: ProgressTrackerProps) => {
  const progressTrackerRef = useRef<HTMLDivElement>(null);
  const [percentage, setPercentage] = useState(0);
  const { t } = useTranslation('stationery');

  const statusMilestonesTranslations = useMemo(() => {
    return t('printJobDetails', 'progressTracker', 'statusMilestones');
  }, [t]);

  const { STATUSES, ITEM_WIDTH } = useMemo(() => {
    let STATUSES: ConsolidatedOrderStatus[] = ['orderPlaced', 'inProgress', 'readyToShip', 'shipped', 'delivered'];

    if (['canceled', 'refunded'].includes(status)) {
      STATUSES = ['orderPlaced', 'inProgress', status];
    }

    const ITEM_WIDTH = (TRACKER_WIDTH - ITEM_MARGIN * (STATUSES.length - 1)) / STATUSES.length;

    return {
      STATUSES,
      ITEM_WIDTH
    };
  }, [status]);

  useEffect(() => {
    const calculatePercentage = () => {
      const index = STATUSES.findIndex(s => s === status);
      let percentage: number;

      if (index === STATUSES.length - 1) {
        percentage = 100;
      } else if (index === 0) {
        percentage = (ITEM_WIDTH / 2 / TRACKER_WIDTH) * 100;
      } else {
        // 1. Calculate total between items
        // 2. Calculate total width of items + half of the current (to place the bar right in the center)
        percentage = ((ITEM_MARGIN * index + ITEM_WIDTH * (index + 0.5)) / TRACKER_WIDTH) * 100;
      }

      return percentage;
    };

    const scrollToPercentage = (percentage: number) => {
      if (progressTrackerRef.current) {
        const HORIZONTAL_PADDING = 40;
        const scrollWidth = progressTrackerRef.current.scrollWidth - HORIZONTAL_PADDING * 2;
        const clientWidth = progressTrackerRef.current.clientWidth;

        const scrollLeft = scrollWidth * (percentage / 100) - clientWidth / 2 + HORIZONTAL_PADDING;
        progressTrackerRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
      }
    };

    // Wrapping the animation in a timeout for visual delay
    setTimeout(() => {
      const percentage = calculatePercentage();
      setPercentage(percentage);
      scrollToPercentage(percentage);
    }, 500);
  }, [status, STATUSES, ITEM_WIDTH]);

  return (
    <Box
      ref={progressTrackerRef}
      width="100%"
      overflowX="scroll"
      whiteSpace={'nowrap'}
      paddingX={{ _: 8, md: 0 }}
      __css={{
        ['&::-webkit-scrollbar']: {
          display: 'none'
        }
      }}
    >
      <Box
        // Must apply display=inline-block
        // https://stackoverflow.com/questions/10054870/when-a-child-element-overflows-horizontally-why-is-the-right-padding-of-the-par
        display="inline-block"
        width={TRACKER_WIDTH}
        marginX={'auto'}
      >
        <Box height={2} backgroundColor="mono5" borderRadius="5px">
          <Box style={{ width: `${percentage}%` }} transition={animationTransition('width')} height={2} backgroundColor={'mono10'} borderRadius={'5px'} />
        </Box>
        <Flex as="ol" marginTop={5} position="relative" textAlign="center">
          {STATUSES.map((status, index) => {
            const isLast = index === STATUSES.length - 1;
            return (
              <Box
                key={status}
                as="li"
                _firstChild={{
                  textAlign: 'left'
                }}
                _lastChild={{ textAlign: 'right' }}
                typographyVariant="label2"
                style={{ width: ITEM_WIDTH, marginRight: isLast ? 0 : ITEM_MARGIN }}
              >
                {statusMilestonesTranslations[status]?.()}
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
};
