import { useAuth } from '@shared/components/AuthProvider';

const isDefinedPredicate = (value: unknown): value is string => !!value;

type FormattedProfile = {
  initials: string | null;
  avatarUrl: string | null;
};

export const useUserProfile = (): FormattedProfile => {
  const {
    currentUser: { profile }
  } = useAuth();
  const formattedProfile: FormattedProfile = {
    initials: null,
    avatarUrl: null
  };
  if (profile) {
    const { firstName, lastName, avatar, email } = profile;
    const initials = [firstName, lastName]
      .filter(isDefinedPredicate)
      .map(x => x.trim().charAt(0))
      .join('')
      .toUpperCase();
    formattedProfile.initials = initials || email?.charAt(0).toUpperCase() || null;
    formattedProfile.avatarUrl = avatar?.url || null;
  }

  return formattedProfile;
};
