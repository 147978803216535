import { CurrencyFormatter } from '@shared/utils/currency';
import {
  PriceConfig,
  CardDieCut,
  CardDelivery,
  CardEnvelopeReturnAddressLayout,
  CardEnvelopeRecipientAddressLayout,
  CardPaperType,
  DigitalCardDelivery,
  DigitalDeliveryFeatures
} from './routes/CardCustomizer/CardCustomizer.types';
import { arraysEqual } from '../../shared/utils/array';

const formatter = new CurrencyFormatter({ locale: 'en', defaultCurrency: 'USD' });

type QuantityPriceConfig = {
  quantity: number;
  totalPriceInMinorUnits: number;
  individualPriceString: string;
  totalPriceString: string;
};

// [4, 10, 20, 30, 40, ..up to 400 (inclusive)]
export const PRINT_SAMPLE_ORDER_QUANTITY = 4;
const AVAILABLE_QUANTITIES = [PRINT_SAMPLE_ORDER_QUANTITY, ...new Array(40).fill(null).map((_, index) => (index + 1) * 10)];

const _generatePriceConfig = ({ getIndividualPriceByQuantityInMinorUnits }: { getIndividualPriceByQuantityInMinorUnits: (quantity: number) => number }) => {
  const configByQuantity: Record<number, QuantityPriceConfig> = {};

  AVAILABLE_QUANTITIES.forEach(quantity => {
    const individPriceInMinorUnits = getIndividualPriceByQuantityInMinorUnits(quantity);
    const totalPriceInMinorUnits = quantity * individPriceInMinorUnits;

    const config: QuantityPriceConfig = {
      quantity,
      totalPriceInMinorUnits,
      individualPriceString: formatter.formatCurrency(individPriceInMinorUnits / 100, { form: 'auto' }),
      totalPriceString: formatter.formatCurrency(totalPriceInMinorUnits / 100, { form: 'auto' })
    };

    configByQuantity[quantity] = config;
  });

  return configByQuantity;
};

export const PRICE_CONFIG: PriceConfig = {
  quantity: {
    thankYou: _generatePriceConfig({
      getIndividualPriceByQuantityInMinorUnits: quantity => {
        if (quantity >= 100) {
          return 139;
        } else if (quantity >= 70) {
          return 149;
        } else if (quantity >= 40) {
          return 169;
        }

        return 239;
      }
    }),
    saveTheDate: _generatePriceConfig({
      getIndividualPriceByQuantityInMinorUnits: quantity => {
        if (quantity >= 100) {
          return 139;
        } else if (quantity >= 70) {
          return 149;
        } else if (quantity >= 40) {
          return 169;
        }

        return 239;
      }
    }),
    invitation: _generatePriceConfig({
      getIndividualPriceByQuantityInMinorUnits: quantity => {
        if (quantity >= 100) {
          return 179;
        } else if (quantity >= 70) {
          return 189;
        } else if (quantity >= 40) {
          return 229;
        }

        return 319;
      }
    }),
    holiday: _generatePriceConfig({
      // TEMPORARILY COPIED FROM SAVETHEDATE
      getIndividualPriceByQuantityInMinorUnits: quantity => {
        if (quantity >= 100) {
          return 139;
        } else if (quantity >= 70) {
          return 149;
        } else if (quantity >= 40) {
          return 169;
        }

        return 239;
      }
    })
  },
  paperType: {
    [CardPaperType.signature]: _generatePriceConfig({
      getIndividualPriceByQuantityInMinorUnits: quantity => {
        return 0;
      }
    }),
    [CardPaperType.pearlescent]: _generatePriceConfig({
      getIndividualPriceByQuantityInMinorUnits: quantity => {
        if (quantity <= 10) {
          return 70;
        }

        if (quantity <= 70) {
          return 35;
        }

        return 30;
      }
    }),
    [CardPaperType.doubleThick]: _generatePriceConfig({
      getIndividualPriceByQuantityInMinorUnits: quantity => {
        if (quantity <= 10) {
          return 190;
        }

        if (quantity <= 70) {
          return 110;
        }

        return 80;
      }
    }),
    [CardPaperType.tripleThick]: _generatePriceConfig({
      getIndividualPriceByQuantityInMinorUnits: quantity => {
        if (quantity <= 10) {
          return 220;
        }

        if (quantity <= 70) {
          return 180;
        }

        return 140;
      }
    })
  },
  shape: {
    [CardDieCut.rectangle]: {
      code: undefined,
      label: 'Rectangle',
      individualPriceString: undefined,
      getTotalPriceStringByQuantity: () => 'FREE',
      getTotalPriceByQuantityInMinorUnits: quantity => {
        return 0;
      }
    },
    [CardDieCut.rounded]: {
      code: 'round',
      label: 'Rounded',
      individualPriceString: formatter.formatCurrency(0.25, { form: 'short' }),
      getTotalPriceStringByQuantity: quantity => formatter.formatCurrency(0.25 * quantity, { form: 'short' }),
      getTotalPriceByQuantityInMinorUnits: quantity => {
        return quantity * 25;
      }
    },
    [CardDieCut.arch]: {
      code: 'arch5x7',
      label: 'Arch',
      individualPriceString: formatter.formatCurrency(0.25, { form: 'short' }),
      getTotalPriceStringByQuantity: quantity => formatter.formatCurrency(0.25 * quantity, { form: 'short' }),
      getTotalPriceByQuantityInMinorUnits: quantity => {
        return quantity * 25;
      }
    }
  },
  delivery: {
    [CardDelivery.standard]: 1495, // $14.95,
    [CardDelivery.express]: 3995 // $39.95
  },
  returnAddress: {
    [CardEnvelopeReturnAddressLayout.back]: {
      individualPriceString: undefined,
      getTotalPriceByQuantityInMinorUnits: () => 0,
      getTotalPriceStringByQuantity: () => 'FREE'
    },
    [CardEnvelopeReturnAddressLayout.front]: {
      individualPriceString: undefined,
      getTotalPriceByQuantityInMinorUnits: () => 0,
      getTotalPriceStringByQuantity: () => 'FREE'
    }, // $0.35
    [CardEnvelopeReturnAddressLayout.none]: {
      individualPriceString: undefined,
      getTotalPriceByQuantityInMinorUnits: () => 0,
      getTotalPriceStringByQuantity: () => 'FREE'
    }
  },
  recipientAddress: {
    [CardEnvelopeRecipientAddressLayout.front]: {
      individualPriceString: undefined,
      getTotalPriceByQuantityInMinorUnits: () => 0,
      getTotalPriceStringByQuantity: () => 'FREE'
    },
    [CardEnvelopeRecipientAddressLayout.none]: {
      individualPriceString: undefined,
      getTotalPriceByQuantityInMinorUnits: () => 0,
      getTotalPriceStringByQuantity: () => 'FREE'
    }
  }
};

interface DigitalPriceConfig {
  premiumPrice: number;
  delivery: {
    [Key in DigitalCardDelivery]: {
      features: DigitalDeliveryFeatures[];
      price: number;
    };
  };
}

export const DIGITAL_PRICE_CONFIG: DigitalPriceConfig = {
  premiumPrice: 1900,
  delivery: {
    [DigitalCardDelivery.digitalStandard]: {
      features: [DigitalDeliveryFeatures.email, DigitalDeliveryFeatures.link],
      price: 0
    },
    [DigitalCardDelivery.digitalPremium]: {
      features: [DigitalDeliveryFeatures.email, DigitalDeliveryFeatures.link, DigitalDeliveryFeatures.international_sms, DigitalDeliveryFeatures.sms],
      price: 3900 // $39
    }
  }
};

export const getSelectedDeliveryMethod: (currentFeatures?: DigitalDeliveryFeatures[]) => DigitalCardDelivery | undefined = (currentFeatures = []) => {
  return (Object.keys(DIGITAL_PRICE_CONFIG.delivery) as DigitalCardDelivery[]).find(key => {
    return arraysEqual(DIGITAL_PRICE_CONFIG.delivery[key].features, currentFeatures);
  });
};
