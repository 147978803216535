import React from 'react';
import { StyledRoot } from './ContentColumn.styles';
import { ResponsiveValue } from '@withjoy/joykit/styled-system';
import { ColumnV2, ColumnV2Props, BoxProps } from '@withjoy/joykit';
import { getOverrides, Override } from '@shared/utils/overrides';

export type ColumnInset = 'none' | 'md';

export type ContentColumnOverrides = Readonly<{
  Root?: Override<BoxProps>;
}>;

export interface ContentColumnProps
  extends Readonly<{
      /**
       * Distance the content from the left.
       *
       * 'md' -> 64px, 4rem
       *
       * @default 'none'
       */
      leftInset?: ResponsiveValue<ColumnInset>;

      overrides?: ContentColumnOverrides;

      width?: string | undefined | number;
    }>,
    ColumnV2Props {}

/**
 * A simple wrapper for Joykit's `<Column />` component.
 *
 * Intended for the `3.2/4 Column` Components in Figma.
 *
 * This component's props extends ColumnProps.
 */
const ContentColumn: React.FC<ContentColumnProps> = ({ children, leftInset, overrides, ...restProps }) => {
  const isInset = leftInset !== undefined;
  leftInset = leftInset || 'none';
  const [Root, rootProps] = getOverrides(overrides?.Root, StyledRoot);
  return (
    <ColumnV2 {...restProps}>
      {isInset ? (
        <Root {...rootProps} leftInset={leftInset}>
          {children}
        </Root>
      ) : (
        children
      )}
    </ColumnV2>
  );
};

ContentColumn.displayName = 'ContentColumn';

export { ContentColumn };
