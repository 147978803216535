import { createDisplayName } from '@withjoy/joykit/utils';
import { mergeOverrides } from '@shared/utils/overrides';
import React from 'react';
import { PopoverV2, PopoverV2Props } from '../PopoverV2';

const TOOLTIP_OVERRIDES: PopoverV2Props['overrides'] = {
  Content: { props: { paddingX: 5, paddingY: 4, backgroundColor: 'black', color: 'white' } },
  Arrow: { props: { backgroundColor: 'black' } }
};

const defaultProps = {
  triggerType: 'hover',
  placement: 'bottom',
  showArrow: true
} as const;

export interface TooltipV2Props extends PopoverV2Props {
  content: React.ReactNode;
}
export const TooltipV2 = (props: TooltipV2Props) => {
  const { children: childrenProp, content, overrides: overridesProp } = props;
  const overrides = mergeOverrides(TOOLTIP_OVERRIDES, overridesProp);

  // Ensure only one child
  const children = React.Children.only(childrenProp);

  return (
    <PopoverV2 {...props} overrides={overrides}>
      <PopoverV2.Trigger>{children}</PopoverV2.Trigger>
      <PopoverV2.Content>{content}</PopoverV2.Content>
    </PopoverV2>
  );
};

TooltipV2.displayName = createDisplayName('Tooltip');
TooltipV2.defaultProps = defaultProps;
