import { LinkV2 } from '@withjoy/joykit';
import { useBannerReminderTranslations } from './BannerPublishReminder.i18n';
import { Notification } from '@apps/guest/packages/layout-engine/components/Notification';
import React, { useCallback } from 'react';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';

interface BannerPublishReminderProps {
  eventHandle: string;
  handleClickLink: Function;
  handleDismiss: Function;
}

const BannerPublishReminder: React.FC<BannerPublishReminderProps> = ({ eventHandle, handleClickLink, handleDismiss }) => {
  const { message, linkText } = useBannerReminderTranslations();
  const mounted = useIsMounted();

  const onClose = useCallback(() => {
    handleDismiss && handleDismiss();
  }, [handleDismiss]);

  const onClickLink = useCallback(() => {
    handleClickLink && handleClickLink();
  }, [handleClickLink]);

  if (!mounted || !eventHandle) return null;
  return (
    <Notification handleOnClose={onClose}>
      {message()}
      <LinkV2 onClick={onClickLink} href={`/${eventHandle}/edit/design`} target="__blank">
        {linkText()}
      </LinkV2>
    </Notification>
  );
};

BannerPublishReminder.displayName = 'BannerPublishReminder';

export { BannerPublishReminder };
