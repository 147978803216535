import React, { useContext, useMemo } from 'react';
import { allowedDomains } from './safeLinkAllowedDomains';
import { LinkWithConfirmationDialog } from './LinkWithConfirmationDialog';
import { LinkV2, LinkV2Props } from '@withjoy/joykit';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { AnalyticsContext } from '@shared/core';
import { useFeatureValue } from '@shared/core/featureFlags';
import { formatRedirectURL } from '@shared/utils/formatting/redirectURL.formatter';

function ensureHttpsPrefix(url: string): string {
  if (!['http://', 'https://'].some(prefix => url.startsWith(prefix))) {
    return `https://${url}`;
  }

  return url;
}

export const SafeLink: React.FC<LinkV2Props & { ensureProtocol?: boolean; source?: string }> = ({ href, onClick, ensureProtocol = false, children, source, ...restParams }) => {
  const analytics = useContext(AnalyticsContext);
  const safeLinksEnabled = useFeatureValue('guestSiteSafeLinks');
  const guestLinkUseRedirectService = useFeatureValue('guestLinkUseRedirectService').value === 'on';

  const isAllowListedUrl = useMemo<boolean>(() => {
    let isAllowListed = false;

    if (href) {
      let url: URL | undefined;
      try {
        url = new URL(ensureHttpsPrefix(href.trim()));
      } catch (_e) {
        // invalid href, assume url is not allowlisted
      }

      if (url) {
        // Below algorithm checks if a domain and some of its subdomains are allowlisted.
        // E.g. if the domain is 'a.b.c.d.com', it will check if 'com', 'd.com', 'c.d.com', and 'b.c.d.com' are in the allow list.
        // This is more efficient than looping through each domain in the allow list and checking if it is a subdomain of the url.
        const domainParts = url.hostname.toLowerCase().split('.');
        const maxDomainPartsToCheck = Math.min(domainParts.length, 4);
        for (let i = 1; i <= maxDomainPartsToCheck; i++) {
          const domainToCheck = domainParts.slice(-i).join('.');
          if (allowedDomains.has(domainToCheck)) {
            isAllowListed = true;
            break;
          }
        }
      }
    }

    return isAllowListed;
  }, [href]);

  const type = href?.startsWith('mailto:') ? 'email' : 'url';
  const onClickInternal = useEventCallback(evt => {
    onClick?.(evt);
    analytics.track({
      action: 'LinkInteracted',
      category: 'safeLink',
      extraInfo: { actionType: 'click', url: href, isAllowListedUrl, source, type }
    });
  });

  return isAllowListedUrl || !safeLinksEnabled ? (
    guestLinkUseRedirectService ? (
      <LinkV2 href={ensureProtocol && href ? formatRedirectURL({ url: ensureHttpsPrefix(href) }) : formatRedirectURL({ url: href })} onClick={onClickInternal} {...restParams}>
        {children}
      </LinkV2>
    ) : (
      <LinkV2 href={ensureProtocol && href ? ensureHttpsPrefix(href) : href} onClick={onClickInternal} {...restParams}>
        {children}
      </LinkV2>
    )
  ) : (
    <LinkWithConfirmationDialog href={ensureProtocol && href ? ensureHttpsPrefix(href) : href} onClick={onClickInternal}>
      {children}
    </LinkWithConfirmationDialog>
  );
};
