import { useTranslation } from '@shared/core/i18n';

export const useAddToCalendarTranslations = () => {
  const { t2 } = useTranslation('addToCalendar');

  const google = t2('google');
  const ical = t2('ical');
  const outlook = t2('outlook');
  const cancel = t2('cancel');
  const apple = t2('apple');

  return {
    google,
    ical,
    outlook,
    cancel,
    apple
  };
};
