import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectContainer, SelectButton, ButtonText, ButtonRightIcon, ButtonRightSlot } from './Select.styles';
import clsx from 'classnames';
import { ChevronDown, Checkmark } from '@withjoy/joykit/icons';
import { IconV2 } from '@withjoy/joykit';

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  onChange: (value: string[]) => void;
  value?: string[];
  options: ReadonlyArray<SelectOption>;
}

export const Select: React.FC<SelectProps> = ({ value = [], onChange, options }) => {
  const renderOption = (option: SelectOption) => {
    const isSelected = value.includes(option.value);

    return (
      <React.Fragment key={option.value}>
        <Listbox.Option value={option.value} className={clsx('selector-listbox-option', { 'active-option': isSelected })}>
          <ButtonText>{option.label}</ButtonText>
          <ButtonRightSlot>
            {isSelected && (
              <ButtonRightIcon>
                <IconV2 size="sm">
                  <Checkmark color="linkActive" />
                </IconV2>
              </ButtonRightIcon>
            )}
          </ButtonRightSlot>
        </Listbox.Option>
      </React.Fragment>
    );
  };

  if (!options?.length) return null;

  return (
    <>
      <SelectContainer>
        <Listbox value={value} onChange={onChange} multiple>
          {({ open }) => (
            <>
              <SelectButton>
                <ButtonText>{value.map(string => options.find(({ value }) => value === string)?.label ?? string).join(', ')}</ButtonText>
                <ButtonRightSlot>
                  <ButtonRightIcon rotate={open ? -180 : 0}>
                    <IconV2 size="md">
                      <ChevronDown />
                    </IconV2>
                  </ButtonRightIcon>
                </ButtonRightSlot>
              </SelectButton>
              <Transition
                as={Fragment}
                enter="transition"
                enterFrom="opacity-0"
                enterTo="opacity=100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="selector-listbox-options">{options.map(renderOption)}</Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </SelectContainer>
    </>
  );
};
