import React from 'react';
import { useGetGuestsiteTidbitsQuery } from '@graphql/generated';
import { Box, BoxProps } from '@withjoy/joykit';
import { ContentColumns } from '../../primitives';
import { FaqList } from '../FaqList';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventUserRole } from '@shared/components/AuthProvider';

export interface TidbitsListProps
  extends Readonly<{
      stackByDefault?: boolean;
      eventHandle: string;
    }>,
    BoxProps {}

const TidbitsList = React.forwardRef<HTMLDivElement, TidbitsListProps>(({ stackByDefault, ...restProps }, ref) => {
  const { eventHandle } = restProps;
  const isPreviewing = isInIframe();
  const { isAdmin } = useEventUserRole();
  const { data, loading } = useGetGuestsiteTidbitsQuery({
    variables: { eventHandle: eventHandle, isAdminDashboard: isPreviewing && isAdmin },
    batchMode: 'fast',
    ssr: false
  });

  if (loading) return <div style={{ height: '300px' }}></div>; // height set to prevent divs from jumping around while page loads

  const qandas = data?.eventByName?.info.tidbits || [];
  if (!qandas || qandas.length === 0) {
    return null;
  }

  return (
    <Box ref={ref} {...restProps}>
      <ContentColumns>
        <ContentColumns.Column offset={stackByDefault ? undefined : [0, 1]} span={stackByDefault ? 12 : [12, 10]}>
          <FaqList data-testid={'story-qandas'} stackByDefault={true} nested={true} tidbits={qandas} eventHandle={eventHandle} />
        </ContentColumns.Column>
      </ContentColumns>
    </Box>
  );
});

TidbitsList.displayName = 'TidbitsList';

export { TidbitsList };
