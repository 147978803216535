import { css } from '@withjoy/joykit';

export const customScrollbarCss = css`
  /* width */
  ::-webkit-scrollbar {
    position: relative;
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    padding: 0 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.scrollbarHandle};
    width: 8px;
    border-radius: 8px;
    background-clip: padding-box;
    border-radius: 9999px;
    border: 2px solid rgba(0, 0, 0, 0);
  }
`;
