/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Sun',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M11.7745 8.00007C11.7745 10.0848 10.0846 11.7748 7.99986 11.7748C5.91516 11.7748 4.22518 10.0848 4.22518 8.00007C4.22518 5.91537 5.91516 4.22539 7.99986 4.22539C10.0846 4.22539 11.7745 5.91537 11.7745 8.00007Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99998 2.59794C7.65877 2.59794 7.38218 2.32134 7.38218 1.98014V0.617798C7.38218 0.276597 7.65877 -1.49108e-08 7.99998 0C8.34118 1.4918e-08 8.61777 0.276597 8.61777 0.617798V1.98014C8.61777 2.32134 8.34118 2.59794 7.99998 2.59794Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99998 16.0004C7.65877 16.0004 7.38218 15.7238 7.38218 15.3826V14.0202C7.38218 13.679 7.65877 13.4024 7.99998 13.4024C8.34118 13.4024 8.61777 13.679 8.61777 14.0202V15.3826C8.61777 15.7238 8.34118 16.0004 7.99998 16.0004Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4022 8.00005C13.4022 7.65885 13.6788 7.38225 14.02 7.38225H15.3823C15.7235 7.38225 16.0001 7.65885 16.0001 8.00005C16.0001 8.34125 15.7235 8.61785 15.3823 8.61785H14.02C13.6788 8.61785 13.4022 8.34125 13.4022 8.00005Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-0.000244141 8.00005C-0.000244111 7.65885 0.276353 7.38225 0.617554 7.38225H1.97989C2.32109 7.38225 2.59769 7.65885 2.59769 8.00005C2.59769 8.34125 2.32109 8.61785 1.97989 8.61785H0.617554C0.276353 8.61785 -0.00024417 8.34125 -0.000244141 8.00005Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8207 4.18027C11.5794 3.939 11.5794 3.54783 11.8207 3.30657L12.784 2.34325C13.0253 2.10198 13.4164 2.10198 13.6577 2.34325C13.899 2.58451 13.899 2.97568 13.6577 3.21695L12.6944 4.18027C12.4531 4.42153 12.0619 4.42153 11.8207 4.18027Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.34309 13.657C2.10183 13.4158 2.10183 13.0246 2.34309 12.7833L3.30641 11.82C3.54768 11.5787 3.93884 11.5787 4.18011 11.82C4.42137 12.0613 4.42137 12.4524 4.18011 12.6937L3.21679 13.657C2.97552 13.8983 2.58436 13.8983 2.34309 13.657Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8201 11.8202C12.0614 11.5789 12.4526 11.5789 12.6938 11.8202L13.6572 12.7835C13.8984 13.0248 13.8984 13.4159 13.6572 13.6572C13.4159 13.8985 13.0247 13.8985 12.7835 13.6572L11.8201 12.6939C11.5789 12.4526 11.5789 12.0615 11.8201 11.8202Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.34256 2.34316C2.58382 2.1019 2.97499 2.1019 3.21626 2.34316L4.17958 3.30648C4.42084 3.54775 4.42084 3.93892 4.17958 4.18018C3.93831 4.42145 3.54714 4.42145 3.30588 4.18018L2.34256 3.21686C2.10129 2.9756 2.10129 2.58443 2.34256 2.34316Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
