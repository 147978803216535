import React from 'react';
import { NavLink, useRouteMatch } from '@react-router';
import { Box, Flex } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useTranslation } from '@shared/core';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

const PrimaryNavCSS = {
  default: {
    ['&.active > span']: {
      borderBottomColor: 'black'
    }
  },
  disabled: {
    ['& > span']: {
      borderBottomColor: 'none'
    }
  },
  active: {
    ['& > span']: {
      borderBottomColor: 'black'
    },
    ['& > span:hover']: {
      borderBottomColor: 'black'
    }
  }
};

type PrimaryNavItemProps = {
  to: string;
  exact?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  activePaths?: string | string[];
};

const PrimaryNavItem: React.FC<PrimaryNavItemProps> = ({ to, exact, children, onClick, disabled, activePaths }) => {
  // Underline if active and not disabled.
  // If 'activePaths' param passed, check if current route matches and force underline if it does.
  const customRouteMatching = useRouteMatch(activePaths ?? '');
  const override = activePaths && customRouteMatching;
  const underlineCSS = override ? PrimaryNavCSS.active : !disabled ? PrimaryNavCSS.default : PrimaryNavCSS.disabled;

  return (
    <Box __css={underlineCSS} paddingTop={1} paddingX={2} as={NavLink} onClick={onClick} exact={exact} to={to} typographyVariant={{ _: 'hed2', md: 'hed3' }}>
      <Box
        _hover={{ borderBottomColor: 'mono5' }}
        transition={animationTransition('border-bottom-color')}
        borderBottom={'1px solid transparent'}
        borderBottomWidth={{ _: '2px', md: '1px' }}
        as="span"
        paddingBottom={{ _: '11px', md: 1 }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const PrimaryNav: React.FC = () => {
  const { getDraftsPath, getShopPath, getCardImaginePath, getOrdersPath, routes } = useCardsRouterContext();
  const { primaryNavInteracted } = useCardTelemetry();
  const { t } = useTranslation('stationery');
  const { value: showImagineTab } = useFeatureValue('printImagineGalleryEnabled');
  const isMobile = useIsMobileScreen();

  const primaryNavTranslations = t('dashboard', 'primaryNav', isMobile ? 'mobile' : 'desktop');

  // Used to distinguish /cards/:occasion/:category/imagine from /cards/:occasion/:category
  const imagineRouteMatch = useRouteMatch(routes.imagine.paths);

  return (
    <Flex columnGap={28} marginLeft={{ md: 8 }} borderBottom={{ _: '1px solid', md: 0 }} borderBottomColor={'mono3'} paddingX={4} height={{ _: 9, md: 0 }} alignItems="center">
      <PrimaryNavItem
        to={getShopPath('wedding')}
        onClick={() => {
          primaryNavInteracted('home');
        }}
        disabled={imagineRouteMatch !== null}
      >
        {primaryNavTranslations.home()}
      </PrimaryNavItem>
      {showImagineTab && (
        <PrimaryNavItem
          to={getCardImaginePath('wedding')}
          onClick={() => {
            primaryNavInteracted('home');
          }}
          activePaths={routes.imagine.paths}
        >
          {primaryNavTranslations.imagine()}
        </PrimaryNavItem>
      )}

      <PrimaryNavItem
        to={getDraftsPath()}
        onClick={() => {
          primaryNavInteracted('drafts');
        }}
      >
        {primaryNavTranslations.drafts()}
      </PrimaryNavItem>
      <PrimaryNavItem
        to={getOrdersPath()}
        onClick={() => {
          primaryNavInteracted('orders');
        }}
      >
        {primaryNavTranslations.orders()}
      </PrimaryNavItem>
    </Flex>
  );
};
