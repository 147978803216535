import React, { useMemo, useState } from 'react';
import { Flex } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { GuestTravelMapComponentFragment } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

import { useTravelMapData } from './hooks/useTravelMapData';
import { Accommodations } from './components/Accommodations';
import { AccommodationMap, AccommodationMapSkeleton } from './components/AccommodationsMap';
import { AccommodationsTileSkeleton } from './components/Accommodations/components';
import { BookingAssistantCta } from './components/BookingAssistantCta';

interface TravelMapProps {
  pageId: string;
  eventId: string;
  pageSlug: string;
  eventHandle: string;
  pageTitle?: string;
  subTitle?: string | null;
  isBrannanLayout?: boolean;
  travelMapComponentData: GuestTravelMapComponentFragment;
}

export const TravelMap: React.FC<TravelMapProps> = ({ pageSlug, eventId, eventHandle, pageTitle, isBrannanLayout, travelMapComponentData }) => {
  const [locationBeingHovered, setLocationBeingHovered] = useState<string | undefined>(undefined);
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const { loading, showDefaultData, destination, eventPhoto, reservedRoomBlocks, blockOrdering, pointsOnMap, venues, sortedHotelList, eventTimezone } = useTravelMapData(
    eventId,
    travelMapComponentData
  );

  const onSelectHotel = useEventCallback((id: string) => {
    const scrollEl = document.getElementById(id);
    if (scrollEl) {
      const elementTop = scrollEl.getBoundingClientRect().top;
      const offsetPosition = elementTop + window.scrollY - (isBrannanLayout && !isMobile ? 32 : 0);
      // To support scrolling to card in Brannan Desktop with offset and Aloha without offset
      isBrannanLayout ? window.scrollTo({ top: offsetPosition, behavior: 'smooth' }) : scrollEl.scrollIntoView({ behavior: 'smooth' });
    }
  });

  // Show map if have at least one venue or hotel with latitude or longitude
  const shouldShowMap = useMemo(
    () =>
      pointsOnMap.find(pointOnMap => (pointOnMap.latitude && pointOnMap.latitude !== 0) || (pointOnMap.longitude && pointOnMap.longitude !== 0)) ||
      reservedRoomBlocks?.find(
        reservedRoomBlock => (reservedRoomBlock.latitude && reservedRoomBlock.latitude !== 0) || (reservedRoomBlock.longitude && reservedRoomBlock.longitude !== 0)
      ),
    [pointsOnMap, reservedRoomBlocks]
  );

  return (
    <Flex flexDirection="column" alignItems="center" width="100%" rowGap={{ _: 8, sm2: isBrannanLayout ? 3 : 8 }}>
      <Flex
        flexDirection={isBrannanLayout && !isMobile ? 'row' : 'column'}
        justifyContent={'center'}
        alignItems={isBrannanLayout ? { _: 'center', sm2: 'flex-start' } : 'center'}
        width={'100%'}
        paddingY={{ _: 0, sm2: isBrannanLayout ? 7 : 0 }}
        paddingRight={{ _: isBrannanLayout ? 6 : 0, sm2: isBrannanLayout ? 7 : 0 }}
        paddingLeft={{ _: isBrannanLayout ? 6 : 0, sm2: isBrannanLayout ? 0 : 0 }}
      >
        <Flex
          width={isBrannanLayout && !isMobile ? pxToRem(400) : '100%'}
          maxWidth={isBrannanLayout && isMobile ? pxToRem(520) : '100%'}
          flex={isBrannanLayout ? { _: 'auto', sm2: 'none' } : 'auto'}
          flexDirection={'column'}
          paddingLeft={isBrannanLayout ? (isMobile ? 0 : 7) : 0}
          paddingRight={isBrannanLayout ? (isMobile ? 0 : 7) : 0}
          position={{ sm2: isBrannanLayout ? 'sticky' : 'initial' }}
          top={{ sm2: isBrannanLayout ? 7 : 0 }}
        >
          <SkeletonGroup isReady={!loading} placeholder={<AccommodationsTileSkeleton />}>
            {reservedRoomBlocks && reservedRoomBlocks?.length > 0 && (
              <Accommodations
                hotelTileListData={reservedRoomBlocks}
                destination={destination}
                eventPhoto={eventPhoto}
                eventHandle={eventHandle}
                blockOrdering={blockOrdering}
                showDefaultData={showDefaultData}
                setLocationBeingHovered={setLocationBeingHovered}
              />
            )}
          </SkeletonGroup>
        </Flex>
        <SkeletonGroup
          isReady={!loading}
          placeholder={<AccommodationMapSkeleton isBrannanLayout={isBrannanLayout} />}
          width="100%"
          position={{ sm2: isBrannanLayout ? 'sticky' : 'initial' }}
          top={{ sm2: isBrannanLayout ? 7 : 0 }}
        >
          {shouldShowMap && (
            <AccommodationMap
              showFullScreenMap={false}
              pointsOnMap={pointsOnMap}
              accommodationsCombined={reservedRoomBlocks ?? []}
              pageTitle={pageTitle}
              onSelectHotel={onSelectHotel}
              isBrannanLayout={isBrannanLayout}
              locationBeingHovered={locationBeingHovered}
            />
          )}
        </SkeletonGroup>
      </Flex>
      {!travelMapComponentData.hideExploreCTA && (
        <BookingAssistantCta accommodations={sortedHotelList} venue={venues[0] || null} pageSlug={pageSlug} eventTimezone={eventTimezone} />
      )}
    </Flex>
  );
};
