/**
 * Return construct a url for a background image
 * e.g. www.withjoy.com/assets/image.jpg ->
 * url("www.withjoy.com/assets/image.jpg")
 * @param {string} url - The title of the book.
 */
export const backgroundImageForUrl = <T>(url: T) => {
  if (url && typeof url === 'string') {
    return `url("${url}")`;
  }
  return url;
};
