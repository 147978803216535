/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Shop',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M8.378 4.365A6.03 6.03 0 0 0 7.509 6.4h-1.57a1.65 1.65 0 0 0-1.644 1.536l-.856 12.489A1.1 1.1 0 0 0 4.535 21.6h14.929a1.1 1.1 0 0 0 1.097-1.175l-.856-12.489A1.65 1.65 0 0 0 18.061 6.4h-1.561c-.136-.657-.417-1.412-.881-2.087C14.905 3.274 13.734 2.4 12 2.4c-1.745 0-2.914.917-3.622 1.965zm.994.672A4.8 4.8 0 0 0 8.746 6.4h6.522c-.119-.449-.324-.952-.638-1.407C14.095 4.214 13.266 3.6 12 3.6c-1.255 0-2.087.636-2.628 1.437zM7.4 9V7.6H5.939a.45.45 0 0 0-.447.418L4.848 17.4h14.304l-.643-9.382a.45.45 0 0 0-.447-.418H16.6V9a.6.6 0 1 1-1.2 0V7.6H8.6V9a.6.6 0 1 1-1.2 0zm11.834 9.6H4.766l-.123 1.8h14.715l-.123-1.8z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M10.947 5.672c-.825 1.058-1.252 2.265-1.373 3.155H7.652a1.65 1.65 0 0 0-1.645 1.536L4.839 27.537a1.1 1.1 0 0 0 1.097 1.175h20.129a1.1 1.1 0 0 0 1.098-1.175l-1.169-17.173a1.65 1.65 0 0 0-1.645-1.536h-1.918c-.114-.939-.54-2.162-1.381-3.22-1.02-1.285-2.654-2.325-5.05-2.325-2.405 0-4.038 1.089-5.053 2.39zm.946.738a5.75 5.75 0 0 0-1.104 2.417h10.431c-.112-.702-.45-1.645-1.108-2.474-.812-1.022-2.11-1.872-4.111-1.872-1.991 0-3.29.882-4.107 1.929zm-2.355 5.089v-1.472H7.652a.45.45 0 0 0-.447.418l-.901 13.234h19.393l-.901-13.234a.45.45 0 0 0-.447-.418h-1.886v1.472a.6.6 0 0 1-1.2 0v-1.472H10.738v1.472a.6.6 0 0 1-1.2 0zm16.241 13.38H6.222l-.179 2.632h19.915l-.179-2.632z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M16.591 7.51c-1.49 1.856-2.15 4.009-2.211 5.365h-4.361c-.88 0-1.61.682-1.669 1.56L6.413 42.924c-.044.65.471 1.201 1.122 1.201h32.93c.651 0 1.167-.551 1.122-1.201l-1.937-28.489c-.06-.878-.789-1.56-1.669-1.56h-3.36c-.056-1.461-.713-3.626-2.215-5.465-1.596-1.953-4.145-3.535-7.906-3.535-3.771 0-6.319 1.654-7.909 3.635zm.975.783c-1.315 1.637-1.87 3.506-1.934 4.582H33.37c-.058-1.184-.615-3.062-1.932-4.674-1.373-1.68-3.574-3.076-6.938-3.076-3.354 0-5.556 1.451-6.935 3.168zm-3.19 8.207v-2.375h-4.356c-.223 0-.407.172-.422.395l-1.52 22.355h31.846l-1.52-22.355c-.015-.222-.2-.395-.422-.395h-3.356V16.5c0 .345-.28.625-.625.625s-.625-.28-.625-.625v-2.375h-17.75V16.5c0 .345-.28.625-.625.625s-.625-.28-.625-.625zm25.633 21.625H8l-.331 4.75h32.662l-.323-4.75z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
