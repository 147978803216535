/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Share',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.38887 0.944294C8.17409 0.729506 7.82584 0.729506 7.61106 0.944294L5.61106 2.94429C5.39627 3.15908 5.39627 3.50732 5.61106 3.72211C5.82585 3.9369 6.17409 3.9369 6.38887 3.72211L7.44997 2.66102V10.2221C7.44997 10.5259 7.69621 10.7721 7.99997 10.7721C8.30372 10.7721 8.54997 10.5259 8.54997 10.2221V2.66102L9.61106 3.72211C9.82584 3.9369 10.1741 3.9369 10.3889 3.72211C10.6037 3.50732 10.6037 3.15908 10.3889 2.94429L8.38887 0.944294ZM2.99441 7.44431C2.99441 6.52077 3.74309 5.77209 4.66663 5.77209H5.22219C5.52594 5.77209 5.77219 5.52585 5.77219 5.22209C5.77219 4.91834 5.52594 4.67209 5.22219 4.67209H4.66663C3.13558 4.67209 1.89441 5.91326 1.89441 7.44431V12.4443C1.89441 13.9754 3.13557 15.2165 4.66663 15.2165H11.3333C12.8644 15.2165 14.1055 13.9754 14.1055 12.4443V7.44431C14.1055 5.91326 12.8644 4.67209 11.3333 4.67209H10.7777C10.474 4.67209 10.2277 4.91834 10.2277 5.22209C10.2277 5.52585 10.474 5.77209 10.7777 5.77209H11.3333C12.2568 5.77209 13.0055 6.52077 13.0055 7.44431V12.4443C13.0055 13.3679 12.2568 14.1165 11.3333 14.1165H4.66663C3.74309 14.1165 2.99441 13.3679 2.99441 12.4443V7.44431Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4243 1.07613C12.19 0.841812 11.8101 0.841812 11.5758 1.07613L8.57577 4.07613C8.34145 4.31044 8.34145 4.69034 8.57577 4.92465C8.81008 5.15897 9.18998 5.15897 9.42429 4.92465L11.4 2.94892L11.4 13.5004C11.4 13.8318 11.6687 14.1004 12 14.1004C12.3314 14.1004 12.6 13.8318 12.6 13.5004L12.6 2.94892L14.5758 4.92465C14.8101 5.15897 15.19 5.15897 15.4243 4.92465C15.6586 4.69034 15.6586 4.31044 15.4243 4.07613L12.4243 1.07613ZM3.60002 9.5004C3.60002 8.7272 4.22683 8.1004 5.00002 8.1004H7.50002C7.8314 8.1004 8.10002 7.83177 8.10002 7.5004C8.10002 7.16903 7.8314 6.9004 7.50002 6.9004H5.00002C3.56408 6.9004 2.40002 8.06446 2.40002 9.5004V20.5004C2.40002 21.9363 3.56408 23.1004 5.00002 23.1004H19C20.436 23.1004 21.6 21.9363 21.6 20.5004V9.5004C21.6 8.06446 20.436 6.9004 19 6.9004H16.5C16.1687 6.9004 15.9 7.16903 15.9 7.5004C15.9 7.83177 16.1687 8.1004 16.5 8.1004H19C19.7732 8.1004 20.4 8.7272 20.4 9.5004V20.5004C20.4 21.2736 19.7732 21.9004 19 21.9004H5.00002C4.22683 21.9004 3.60002 21.2736 3.60002 20.5004V9.5004Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4243 3.57613C16.19 3.34181 15.8101 3.34181 15.5758 3.57613L11.5758 7.57613C11.3414 7.81044 11.3414 8.19034 11.5758 8.42465C11.8101 8.65897 12.19 8.65897 12.4243 8.42465L15.4 5.44892L15.4 20.0004C15.4 20.3318 15.6687 20.6004 16 20.6004C16.3314 20.6004 16.6 20.3318 16.6 20.0004L16.6 5.44892L19.5758 8.42465C19.8101 8.65897 20.19 8.65897 20.4243 8.42465C20.6586 8.19034 20.6586 7.81044 20.4243 7.57613L16.4243 3.57613ZM6.60002 14.0004C6.60002 12.6749 7.67454 11.6004 9.00002 11.6004H11C11.3314 11.6004 11.6 11.3318 11.6 11.0004C11.6 10.669 11.3314 10.4004 11 10.4004H9.00002C7.0118 10.4004 5.40002 12.0122 5.40002 14.0004V25.0004C5.40002 26.9886 7.0118 28.6004 9.00002 28.6004H23C24.9883 28.6004 26.6 26.9886 26.6 25.0004V14.0004C26.6 12.0122 24.9883 10.4004 23 10.4004H21C20.6687 10.4004 20.4 10.669 20.4 11.0004C20.4 11.3318 20.6687 11.6004 21 11.6004H23C24.3255 11.6004 25.4 12.6749 25.4 14.0004V25.0004C25.4 26.3259 24.3255 27.4004 23 27.4004H9.00002C7.67454 27.4004 6.60002 26.3259 6.60002 25.0004V14.0004Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            d="M24.4419 5.55806C24.1979 5.31398 23.8021 5.31398 23.5581 5.55806L17.5581 11.5581C17.314 11.8021 17.314 12.1979 17.5581 12.4419C17.8021 12.686 18.1979 12.686 18.4419 12.4419L23.375 7.50888L23.375 30C23.375 30.3452 23.6548 30.625 24 30.625C24.3452 30.625 24.625 30.3452 24.625 30V7.50888L29.5581 12.4419C29.8021 12.686 30.1979 12.686 30.4419 12.4419C30.686 12.1979 30.686 11.8021 30.4419 11.5581L24.4419 5.55806Z"
            fill="currentColor"
          />
          <path
            d="M9.625 21C9.625 18.8599 11.3599 17.125 13.5 17.125H16.5C16.8452 17.125 17.125 16.8452 17.125 16.5C17.125 16.1548 16.8452 15.875 16.5 15.875H13.5C10.6695 15.875 8.375 18.1695 8.375 21V37.5C8.375 40.3305 10.6695 42.625 13.5 42.625H34.5C37.3305 42.625 39.625 40.3305 39.625 37.5V21C39.625 18.1695 37.3305 15.875 34.5 15.875H31.5C31.1548 15.875 30.875 16.1548 30.875 16.5C30.875 16.8452 31.1548 17.125 31.5 17.125H34.5C36.6401 17.125 38.375 18.8599 38.375 21V37.5C38.375 39.6401 36.6401 41.375 34.5 41.375H13.5C11.3599 41.375 9.625 39.6401 9.625 37.5V21Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
