import urlParser from 'js-video-url-parser';
import { VideoMimeType } from './video.types';

export const assetIdFromUrl = (url: string) => {
  if (!url) {
    throw new Error('No url');
  }

  const videoInfo = urlParser.parse(url);

  if (!videoInfo || !videoInfo.provider) {
    throw new Error('No url');
  }

  return videoInfo.id;
};

export const iframeUrlFromVideoUrl = (url: string, provider: VideoMimeType) => {
  const id = assetIdFromUrl(url);
  if (provider === 'video/x-vimeo') {
    return `https://player.vimeo.com/video/${id}?byline=false&portrait=false&title=false`;
  } else if (provider === 'video/x-vimeo-event') {
    return `https://vimeo.com/event/${id}/embed?byline=false&portrait=false&title=false`;
  } else if (provider === 'video/x-youtube') {
    return `https://www.youtube.com/embed/${id}?controls=1&showinfo=0`;
  } else {
    throw new Error(`Provider not supported: ${provider}`);
  }
};
