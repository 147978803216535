import React from 'react';
import { URLS } from '@shared/utils/urls';
import { BaseBoxProps } from '@withjoy/joykit';
import { getDeviceResolution } from '@shared/utils/deviceResolution';
import { GraphicAccentContainer, GraphicAccentImg } from './GraphicAccent.styles';

interface Props
  extends BaseBoxProps,
    Readonly<{
      accentId: string;
      id?: string;
    }> {}

const DEVICE_RESOLUTION = getDeviceResolution();

export const GraphicAccent: React.FC<Props> = props => {
  const { accentId, ...restProps } = props;
  return (
    <GraphicAccentContainer {...restProps}>
      <GraphicAccentImg src={URLS.graphicAccent(accentId)} pixelDensity={DEVICE_RESOLUTION} />
    </GraphicAccentContainer>
  );
};
