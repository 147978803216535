import { Flex, TextV2, LinkV2 } from '@withjoy/joykit';
import React from 'react';
import { Phone } from '@withjoy/joykit/icons';
import { AccommodationCombinedType } from '../../Accommodation.types';

export const AccommodationTilePhone: React.FC<{ type: AccommodationCombinedType; phone?: string | null }> = ({ phone, type }) => {
  if (type === AccommodationCombinedType.Roomblock || !phone) return null;

  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Flex>
        <Phone size={32} />
      </Flex>
      <Flex flexDirection={'column'} justifyContent={'center'}>
        <LinkV2 fontSize={15} lineHeight={'22.5px'} letterSpacing={'-0.15px'} textDecoration={'none'} wordBreak="break-word" href={`tel:${phone}`}>
          <TextV2 fontFamily={'Inter UI'} paddingBottom={1}>
            {phone}
          </TextV2>
        </LinkV2>
      </Flex>
    </Flex>
  );
};
