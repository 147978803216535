import React, { useEffect } from 'react';
import { Flex } from '@withjoy/joykit';
import { StyledIconFrame, styles } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockForm/UnlockForm.styles';
import { UnlockedThin } from '@withjoy/joykit/icons';

const UnlockSuccess = ({ onHide }: { onHide: () => void }) => {
  useEffect(() => {
    setTimeout(() => {
      onHide();
    }, 1500);
  }, [onHide]);

  return (
    <Flex __css={styles.unlockedContent}>
      <StyledIconFrame>
        <UnlockedThin size={48} />
      </StyledIconFrame>
    </Flex>
  );
};

export default UnlockSuccess;
