/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Sign',
  definitions: {
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M15.334.733c-1.068 0-1.933.866-1.933 1.933v1.4H8.04a1.45 1.45 0 0 0-1.068.47L4.083 7.688a1.45 1.45 0 0 0 0 1.958l2.889 3.151a1.45 1.45 0 0 0 1.068.47h5.36V15.4H6.848A1.45 1.45 0 0 0 5.4 16.849v6.303A1.45 1.45 0 0 0 6.848 24.6H13.4V30c0 .037-.03.067-.067.067h-2.667a.6.6 0 0 0 0 1.2h2.667c.7 0 1.267-.567 1.267-1.267v-5.4h2.8V30c0 .7.567 1.267 1.267 1.267h2.667a.6.6 0 0 0 0-1.2h-2.667c-.037 0-.067-.03-.067-.067v-5.4h5.36a1.45 1.45 0 0 0 1.068-.47l2.889-3.152a1.45 1.45 0 0 0 0-1.958l-2.889-3.152a1.45 1.45 0 0 0-1.068-.47H18.6v-2.133h6.551a1.45 1.45 0 0 0 1.449-1.448V5.515a1.45 1.45 0 0 0-1.449-1.448H18.6v-1.4c0-1.068-.866-1.933-1.933-1.933h-1.333zM17.4 4.067v-1.4c0-.405-.328-.733-.733-.733h-1.333c-.405 0-.733.328-.733.733v1.4h2.8zm0 9.2h-2.8V15.4h2.8v-2.133zm-9.36-8c-.07 0-.136.029-.183.081L4.968 8.499c-.087.095-.087.241 0 .336l2.889 3.152c.047.051.114.081.183.081h17.112c.137 0 .248-.111.248-.248V5.515c0-.137-.111-.248-.248-.248H8.04zM23.96 16.6c.07 0 .136.029.183.081l2.889 3.151c.087.095.087.241 0 .336l-2.889 3.152c-.047.051-.114.081-.183.081H6.848c-.137 0-.248-.111-.248-.248v-6.303c0-.137.111-.248.248-.248H23.96z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M23 1.375c-1.45 0-2.625 1.175-2.625 2.625v2.375H12.06a1.9 1.9 0 0 0-1.399.615l-4.333 4.727a1.9 1.9 0 0 0 0 2.565l4.333 4.727a1.9 1.9 0 0 0 1.399.615h8.315v3.75H10.273a1.9 1.9 0 0 0-1.898 1.898v9.455a1.9 1.9 0 0 0 1.898 1.898h10.102V45c0 .207-.168.375-.375.375h-4c-.345 0-.625.28-.625.625s.28.625.625.625h4c.898 0 1.625-.727 1.625-1.625v-8.375h4.75V45c0 .898.728 1.625 1.625 1.625h4c.345 0 .625-.28.625-.625s-.28-.625-.625-.625h-4c-.207 0-.375-.168-.375-.375v-8.375h8.315a1.9 1.9 0 0 0 1.399-.615l4.333-4.727a1.9 1.9 0 0 0 0-2.565l-4.333-4.727a1.9 1.9 0 0 0-1.399-.615h-8.315v-3.75h10.102a1.9 1.9 0 0 0 1.898-1.898V8.273a1.9 1.9 0 0 0-1.898-1.898H27.625V4c0-1.45-1.175-2.625-2.625-2.625h-2zm3.375 5V4c0-.759-.616-1.375-1.375-1.375h-2c-.759 0-1.375.616-1.375 1.375v2.375h4.75zm0 13.25h-4.75v3.75h4.75v-3.75zm-14.315-12a.65.65 0 0 0-.478.21l-4.333 4.727a.65.65 0 0 0 0 .875l4.333 4.727a.65.65 0 0 0 .478.21h25.667a.65.65 0 0 0 .648-.648V8.273a.65.65 0 0 0-.648-.648H12.06zm23.88 17a.65.65 0 0 1 .477.21l4.333 4.727a.65.65 0 0 1 0 .875l-4.333 4.727a.65.65 0 0 1-.477.21H10.273a.65.65 0 0 1-.648-.648v-9.455a.65.65 0 0 1 .648-.648H35.94z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M30.667 1.834a3.5 3.5 0 0 0-3.5 3.5v3.2H16.08c-.7 0-1.368.294-1.841.81l-5.778 6.303a2.5 2.5 0 0 0 0 3.375l5.778 6.303c.473.516 1.141.81 1.841.81h11.087V31.2h-13.47a2.5 2.5 0 0 0-2.497 2.497v12.606a2.5 2.5 0 0 0 2.497 2.497h13.47V60a.5.5 0 0 1-.5.5h-5.333c-.46 0-.833.373-.833.833s.373.833.833.833h5.333c1.197 0 2.167-.97 2.167-2.167V48.8h6.333V60c0 1.197.97 2.167 2.167 2.167h5.333c.46 0 .833-.373.833-.833s-.373-.833-.833-.833h-5.333a.5.5 0 0 1-.5-.5V48.8H47.92c.7 0 1.368-.294 1.841-.81l5.778-6.303c.875-.955.875-2.42 0-3.374l-5.778-6.303c-.473-.516-1.141-.81-1.841-.81H36.833v-5.067h13.47a2.5 2.5 0 0 0 2.497-2.497V11.031a2.5 2.5 0 0 0-2.497-2.497h-13.47v-3.2a3.5 3.5 0 0 0-3.5-3.5h-2.667zm4.5 6.7v-3.2c0-1.013-.821-1.833-1.833-1.833h-2.667c-1.012 0-1.833.821-1.833 1.833v3.2h6.333zm0 17.6h-6.333V31.2h6.333v-5.067zm-19.087-16c-.251 0-.491.106-.661.291l-5.778 6.303c-.314.343-.314.869 0 1.212l5.778 6.303c.17.185.41.291.661.291h34.223c.495 0 .897-.402.897-.897V11.031c0-.495-.402-.897-.897-.897H16.08zM47.92 32.8c.252 0 .491.105.661.291l5.778 6.303c.314.343.314.869 0 1.212l-5.778 6.303c-.17.185-.41.291-.661.291H13.697c-.495 0-.897-.402-.897-.897V33.697c0-.495.402-.897.897-.897H47.92z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
