/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Arrow Left',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.38886 1.61104C7.60365 1.82583 7.60365 2.17407 7.38886 2.38886L2.32777 7.44995H15C15.3037 7.44995 15.55 7.69619 15.55 7.99995C15.55 8.30371 15.3037 8.54995 15 8.54995H2.32777L7.38886 13.611C7.60365 13.8258 7.60365 14.1741 7.38886 14.3889C7.17407 14.6036 6.82583 14.6036 6.61104 14.3889L0.611042 8.38886C0.396254 8.17407 0.396254 7.82583 0.611042 7.61104L6.61104 1.61104C6.82583 1.39625 7.17407 1.39625 7.38886 1.61104Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
