import React from 'react';
import { getOverrides } from '@shared/utils/overrides';
import { ComponentWithAs, forwardRef } from '@shared/utils/forwardRef';
import { getListStyles, StyledList } from './Menu.styles';
import { MenuProps, MenuOverrides } from './Menu.types';
import { MenuItem } from './MenuItem';

const defaultProps = {
  overrides: {} as MenuOverrides
} as const;

type MenuComponent = ComponentWithAs<'ul', MenuProps> & { Item: typeof MenuItem };

const Menu: MenuComponent = forwardRef<'ul', MenuProps>((props, ref) => {
  const { children, overrides, ...restProps } = props as MenuProps & typeof defaultProps;
  const [List, listOverrides] = getOverrides(overrides.List, StyledList);
  const listStyles = getListStyles();
  return (
    <List as="ul" role="menu" ref={ref} {...restProps} {...listStyles} {...listOverrides}>
      {children}
    </List>
  );
}) as MenuComponent;

Menu.displayName = 'Menu';
Menu.defaultProps = defaultProps;
Menu.Item = MenuItem;

export { Menu, MenuItem };
