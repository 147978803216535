/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Info Star',
  definitions: {
    '16': {
      viewBox: '0 0 22 21',
      path: (
        <>
          <path
            d="M11.001 5.09995C11.3324 5.09995 11.601 5.36858 11.601 5.69995V11.7C11.601 12.0313 11.3324 12.3 11.001 12.3C10.6696 12.3 10.401 12.0313 10.401 11.7L10.401 5.69995C10.401 5.36858 10.6696 5.09995 11.001 5.09995Z"
            fill="currentColor"
          />
          <path
            d="M10.201 14.5C10.201 14.9418 10.5592 15.3 11.001 15.3C11.4428 15.3 11.801 14.9418 11.801 14.5C11.801 14.0581 11.4428 13.7 11.001 13.7C10.5592 13.7 10.201 14.0581 10.201 14.5Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.21079 11.9142C0.429738 11.1332 0.429738 9.86684 1.21079 9.08579L3.07843 7.21815V4.57726C3.07843 3.47269 3.97386 2.57726 5.07843 2.57726H7.71932L9.58677 0.70981C10.3678 -0.0712384 11.6341 -0.0712389 12.4152 0.709809L14.2826 2.57726H16.9239C18.0284 2.57726 18.9239 3.47269 18.9239 4.57726V7.21847L20.7912 9.08579C21.5722 9.86684 21.5722 11.1332 20.7912 11.9142L18.9239 13.7815V16.4227C18.9239 17.5273 18.0284 18.4227 16.9239 18.4227H14.2827L12.4152 20.2902C11.6342 21.0713 10.3678 21.0713 9.58677 20.2902L7.71925 18.4227H5.07843C3.97386 18.4227 3.07843 17.5273 3.07843 16.4227V13.7819L1.21079 11.9142ZM3.92695 12.9333C4.152 13.1584 4.27843 13.4636 4.27843 13.7819V16.4227C4.27843 16.8645 4.6366 17.2227 5.07843 17.2227H7.71925C8.03751 17.2227 8.34274 17.3491 8.56778 17.5742L10.4353 19.4417C10.7477 19.7541 11.2543 19.7541 11.5667 19.4417L13.4342 17.5742C13.6592 17.3491 13.9645 17.2227 14.2827 17.2227H16.9239C17.3657 17.2227 17.7239 16.8645 17.7239 16.4227V13.7815C17.7239 13.4633 17.8503 13.1581 18.0753 12.933L19.9427 11.0657C20.2551 10.7533 20.2551 10.2467 19.9427 9.93432L18.0753 8.067C17.8503 7.84195 17.7239 7.53673 17.7239 7.21847V4.57726C17.7239 4.13543 17.3657 3.77726 16.9239 3.77726H14.2826C13.9644 3.77726 13.6592 3.65083 13.4341 3.42579L11.5667 1.55834C11.2542 1.24592 10.7477 1.24592 10.4353 1.55834L8.56785 3.42579C8.34281 3.65083 8.03758 3.77726 7.71932 3.77726H5.07843C4.6366 3.77726 4.27843 4.13543 4.27843 4.57726V7.21815C4.27843 7.53641 4.152 7.84164 3.92696 8.06668L2.05931 9.93432C1.7469 10.2467 1.7469 10.7533 2.05931 11.0657L3.92695 12.9333Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 33',
      path: (
        <>
          <path
            d="M16 8.17299C16.511 8.17299 16.9253 8.58722 16.9253 9.09821V18.3503C16.9253 18.8613 16.511 19.2756 16 19.2756C15.4891 19.2756 15.0748 18.8613 15.0748 18.3503L15.0748 9.09821C15.0748 8.58722 15.4891 8.17299 16 8.17299Z"
            fill="currentColor"
          />
          <path
            d="M14.7664 22.668C14.7664 23.3493 15.3187 23.9016 16.0001 23.9016C16.6814 23.9016 17.2337 23.3493 17.2337 22.668C17.2337 21.9867 16.6814 21.4344 16.0001 21.4344C15.3187 21.4344 14.7664 21.9867 14.7664 22.668Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.903297 18.6808C-0.301098 17.4764 -0.301099 15.5236 0.903295 14.3193L3.78324 11.4393V7.36699C3.78324 5.66372 5.16401 4.28294 6.86728 4.28294H10.9396L13.8192 1.4033C15.0236 0.198902 16.9764 0.198901 18.1807 1.4033L21.0604 4.28294H25.1332C26.8365 4.28294 28.2173 5.66372 28.2173 7.36699V11.4398L31.0967 14.3192C32.3011 15.5236 32.3011 17.4764 31.0967 18.6807L28.2173 21.5602V25.6329C28.2173 27.3362 26.8365 28.717 25.1332 28.717H21.0605L18.1808 31.5967C16.9764 32.8011 15.0236 32.8011 13.8193 31.5967L10.9395 28.717H6.86729C5.16401 28.717 3.78324 27.3362 3.78324 25.6329V21.5607L0.903297 18.6808ZM5.09169 20.2522C5.43871 20.5993 5.63367 21.0699 5.63367 21.5607V25.6329C5.63367 26.3142 6.18598 26.8665 6.86729 26.8665H10.9395C11.4303 26.8665 11.9009 27.0615 12.248 27.4085L15.1277 30.2883C15.6095 30.77 16.3905 30.77 16.8723 30.2883L19.7521 27.4085C20.0991 27.0615 20.5697 26.8665 21.0605 26.8665H25.1332C25.8145 26.8665 26.3668 26.3142 26.3668 25.6329V21.5602C26.3668 21.0694 26.5618 20.5988 26.9088 20.2518L29.7883 17.3723C30.27 16.8905 30.27 16.1095 29.7883 15.6277L26.9088 12.7482C26.5618 12.4012 26.3668 11.9306 26.3668 11.4398V7.36699C26.3668 6.68568 25.8145 6.13337 25.1332 6.13337H21.0604C20.5696 6.13337 20.099 5.93841 19.7519 5.59139L16.8723 2.71175C16.3905 2.22999 15.6095 2.22999 15.1277 2.71175L12.2481 5.59139C11.901 5.93841 11.4304 6.13337 10.9396 6.13337H6.86728C6.18598 6.13337 5.63367 6.68568 5.63367 7.36699V11.4393C5.63367 11.9301 5.43871 12.4007 5.09169 12.7478L2.21175 15.6277C1.72999 16.1095 1.72999 16.8905 2.21175 17.3723L5.09169 20.2522Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
