import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { Schedule } from '@withjoy/joykit/icons';
import { createDateFromUnformattedString } from '@shared/utils/useDateUtils';
import { AccommodationCombinedType } from '../../Accommodation.types';

export const AccommodationTileSchedule: React.FC<{ type: AccommodationCombinedType; cutoffDate?: string | null }> = ({ cutoffDate, type }) => {
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  if (type !== AccommodationCombinedType.Roomblock) return null;

  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Flex>
        <Schedule size={48} />
      </Flex>
      <Flex flexDirection={'column'}>
        <TextV2 fontFamily={'Inter UI'} fontSize={15} fontWeight={600} lineHeight={'22.5px'} letterSpacing={'-0.15px'} paddingBottom={1}>
          {accTrans.beforeDate({ date: createDateFromUnformattedString(cutoffDate || '').toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) })}
        </TextV2>
        <TextV2 typographyVariant={'body1'} fontFamily={'Inter UI'} fontSize={15} fontWeight={400} color={'mono12'}>
          {accTrans.riskFreeCancellation()}
        </TextV2>
      </Flex>
    </Flex>
  );
};
