/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Phone',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M5.066 3.043c.63-.63 1.651-.63 2.281 0L9.855 5.55c.63.63.63 1.651 0 2.281L8.488 9.197l6.314 6.314 1.367-1.367c.63-.63 1.651-.63 2.281 0l2.507 2.507c.63.63.63 1.651 0 2.281l-1.791 1.791c-.558.558-1.339.742-2.162.699s-1.758-.314-2.737-.753c-1.962-.879-4.221-2.487-6.336-4.602s-3.723-4.375-4.602-6.336c-.439-.979-.71-1.914-.753-2.737s.141-1.604.699-2.162l1.791-1.791zm1.433.849c-.161-.161-.423-.161-.584 0L4.124 5.682c-.233.233-.382.627-.349 1.251s.246 1.408.649 2.308c.806 1.797 2.317 3.94 4.356 5.979s4.181 3.55 5.979 4.356c.901.404 1.685.617 2.308.649s1.017-.116 1.251-.349l1.791-1.791c.161-.161.161-.423 0-.584l-2.507-2.507c-.161-.161-.423-.161-.584 0l-1.791 1.791a.6.6 0 0 1-.849 0L7.215 9.622a.6.6 0 0 1 0-.849l1.791-1.791c.161-.161.161-.423 0-.584L6.499 3.891z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
