import { Typography } from '@withjoy/joykit/theme';

export const TEXT_COMPONENT = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  span: 'span',
  p: 'p'
};

export const TEXT_COMPONENT_VARIANT_MAPPING: { [component in 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'p']: keyof Typography['variants'] } = {
  h1: 'hed6', // 'page',
  h2: 'hed4', // 'title',
  h3: 'hed2', // 'head',
  h4: 'label2', // 'caption',
  span: 'body1', // 'body',
  p: 'body1' // 'body'
};
