import { withWindow } from '@shared/utils/withWindow';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * To detect text is overflowing with line clamp
 * @param maxLinesToShow - Show text max number of lines with -webkit-line-clamp
 *
 */
export function useIsTextOverflowing(maxLinesToShow: number) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const checkOverflow = useCallback(() => {
    if (textRef.current) {
      // Calculate the maximum height of the collapsed element
      const maxHeight = parseFloat(getComputedStyle(textRef.current).lineHeight) * (maxLinesToShow + 1);
      setIsOverflowing(textRef.current?.scrollHeight > maxHeight);
    }
  }, [maxLinesToShow]);

  useEffect(() => {
    const textEl = textRef.current;
    if (!textEl) return;

    return withWindow(global => {
      global.addEventListener('resize', checkOverflow);
      return () => {
        global.removeEventListener('resize', checkOverflow);
      };
    });
  }, [checkOverflow]);

  useEffect(() => {
    const textEl = textRef.current;
    if (!textEl) return;

    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    resizeObserver.observe(textEl, {});
    return () => resizeObserver.disconnect();
  }, [checkOverflow]);

  return { textRef, isOverflowing };
}
