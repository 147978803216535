import { AnalyticsContext } from '@shared/core';
import { useDisclosure } from '@withjoy/joykit';
import { useFeatureValue } from '@shared/core/featureFlags';
import React, { useContext, useEffect, useState } from 'react';
import { ensureHttpsPrefix, getIsAllowListedUrl } from './SafeLink.utils';
import { formatRedirectURL } from '@shared/utils/formatting/redirectURL.formatter';
import { LinkConfirmationDialog } from '@shared/components/SafeLink/LinkConfirmationDialog';

const wrapHtmlWithSafeLink = <P extends object & { id: string; text: string; ensureProtocol?: boolean }>(WrappedComponent: React.FC<P>) => {
  return (props: P) => {
    const analytics = useContext(AnalyticsContext);
    const safeLinksEnabled = useFeatureValue('guestSiteSafeLinks');
    const guestLinkUseRedirectService = useFeatureValue('guestLinkUseRedirectService').value === 'on';

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [url, setUrl] = useState('');

    useEffect(() => {
      const links = document.querySelectorAll(`.richtext-${props.id} a`);

      const handleClick = (event: Event) => {
        event.preventDefault();

        const link = event.currentTarget as HTMLAnchorElement;
        const href = link.getAttribute('href');

        if (href) {
          const isAllowListedUrl = getIsAllowListedUrl(href);
          const type = href.startsWith('mailto:') ? 'email' : 'url';
          analytics.track({
            action: 'LinkInteracted',
            category: 'safeLink',
            extraInfo: { actionType: 'click', url: href, isAllowListedUrl, source: href, type }
          });

          if (isAllowListedUrl || !safeLinksEnabled) {
            const safeLink = guestLinkUseRedirectService
              ? formatRedirectURL({ url: props.ensureProtocol ? ensureHttpsPrefix(href) : href })
              : props.ensureProtocol
              ? ensureHttpsPrefix(href)
              : href;
            window.open(safeLink, '_blank');
          } else {
            setUrl(props.ensureProtocol ? ensureHttpsPrefix(href) : href);
            onOpen();
          }
        }
      };

      links.forEach(link => {
        link.addEventListener('click', handleClick);
      });

      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handleClick);
        });
      };
    }, [analytics, guestLinkUseRedirectService, onOpen, props, safeLinksEnabled]);

    return (
      <>
        <WrappedComponent {...props} />
        <LinkConfirmationDialog href={url} isOpen={isOpen} onClose={onClose} />
      </>
    );
  };
};

export default wrapHtmlWithSafeLink;
