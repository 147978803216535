import { VirtualEventProvider } from '@graphql/generated';

import { ReactComponent as BrandEventliveMono } from '@assets/icons/brand-eventlive-mono.svg';
import { ReactComponent as BrandMeetMono } from '@assets/icons/brand-meet-mono.svg';
import { ReactComponent as BrandYoutubeMono } from '@assets/icons/brand-youtube-mono.svg';
import { ReactComponent as BrandZoomMono } from '@assets/icons/brand-zoom-mono.svg';

export const providerIcons: Readonly<Partial<Record<VirtualEventProvider, React.FC>>> = {
  [VirtualEventProvider.eventlive]: BrandEventliveMono,
  [VirtualEventProvider.googleMeet]: BrandMeetMono,
  [VirtualEventProvider.youtube]: BrandYoutubeMono,
  [VirtualEventProvider.zoom]: BrandZoomMono
};
