import { styled, keyframes, StyleSystemProps, Flex } from '@withjoy/joykit';
import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';

const blinkerAnimation = keyframes`
  from { box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.5); }
  to { box-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.8); }
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  min-width: 12px;
  border-radius: 50%;
`;

export const RedDot = styled(Dot)`
  background: radial-gradient(50% 50% at 50% 50%, #e10024 64.06%, #cd0122 100%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.5);
  animation: ${blinkerAnimation} 1s cubic-bezier(0.23, 1, 0.32, 1) infinite alternate;
`;

export const streamButtonOverrides = ({ isProviderZoom, isStreamNow }: { isProviderZoom: boolean; isStreamNow: boolean }) => {
  if (isStreamNow && !isProviderZoom) return;

  const overrides: ButtonOverrides = {
    Root: {
      props: {
        backgroundColor: isStreamNow ? 'brandBlue5' : 'mono14',
        color: 'white',
        _hover: {
          backgroundColor: isStreamNow ? 'brandBlue4' : 'mono13'
        },
        _active: {
          backgroundColor: isStreamNow ? 'brandBlue6' : 'black'
        }
      }
    }
  };

  return overrides;
};

export const StyledStatus = styled(Flex)`
  align-items: center;
  color: ${({ theme }) => theme.colors.negative6};
  gap: 0.5rem;
  white-space: nowrap;
`;

export const StyledEventInfo = styled(Flex)``;
const infoStyles: StyleSystemProps = {
  flexDirection: 'column',
  textAlign: 'center',
  whiteSpace: 'nowrap'
};

export const StyledProviderInfo = styled(Flex)`
  align-items: center;
  height: 1.25rem;
  color: ${({ theme }) => theme.colors.mono9};
`;

export const StyledWatchInBrowser = styled(Flex)``;
const watchInBrowserStyles: StyleSystemProps = {
  flexDirection: ['column', 'row'],
  alignItems: 'center',
  columnGap: '4px',
  whiteSpace: 'nowrap'
};

export const styles = {
  info: infoStyles,
  watchInBrowser: watchInBrowserStyles
};
