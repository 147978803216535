type PageData = {
  id: string;
  disabled: boolean;
  type: string;
  pageSlug: string;
};

export const generateDescriptionKeywords = (pageData?: readonly PageData[]) => {
  let hasAccommodations = false;
  let hasTravel = false;
  let hasRegistry = false;

  pageData?.forEach(page => {
    if (!page.disabled) {
      if (page.type === 'custom' && page.pageSlug === 'accommodations') {
        hasAccommodations = true;
      } else if (page.type === 'travel') {
        hasTravel = true;
      } else if (page.type === 'registry') {
        hasRegistry = true;
      }
    }
  });

  const keywords: string[] = [];
  if (hasAccommodations) {
    keywords.push('accommodations');
  } else if (hasTravel) {
    keywords.push('travel details');
  }
  if (hasRegistry) {
    keywords.push('registry information');
  }
  if (keywords.length === 0) {
    keywords.push('event information');
  }

  return keywords.join(', ');
};
