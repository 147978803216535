import { useFeatureValue } from '@shared/core/featureFlags';

export function useIsHotelBlockAccommodationsFlagEnabled(): boolean {
  const isHotelBlockEnabled = useFeatureValue('hotelBlockEnabled').value === 'on';
  return (
    useFeatureValue('hotelBlockAccommodations', {
      skip: !isHotelBlockEnabled
    }).value === 'on'
  );
}

export function useShouldShowSettingsOnComingSoonScreen(): boolean {
  const isHotelBlockEnabled = useFeatureValue('hotelBlockEnabled').value === 'on';
  return (
    useFeatureValue('hotelBlockAccommodations', {
      skip: isHotelBlockEnabled
    }).value === 'on'
  );
}
