import { PopoverPosition } from './sharedPopoverProps';
import PopperJs, { Placement } from 'popper.js';
import { Position, isVerticalPosition, isHorizontalPosition } from '../../common';
import { PopoverOffset, PopoverTransition } from './types';
import { TransitionProperties } from '../Overlay/Transition';

export const positionToPlacement = (position: PopoverPosition): Placement => {
  switch (position) {
    case Position.TOP_LEFT:
      return 'top-start';
    case Position.TOP:
      return 'top';
    case Position.TOP_RIGHT:
      return 'top-end';

    case Position.BOTTOM_LEFT:
      return 'bottom-start';
    case Position.BOTTOM:
      return 'bottom';
    case Position.BOTTOM_RIGHT:
      return 'bottom-end';

    case Position.LEFT_TOP:
      return 'left-start';
    case Position.LEFT:
      return 'left';
    case Position.LEFT_BOTTOM:
      return 'left-end';

    case Position.RIGHT_TOP:
      return 'right-start';
    case Position.RIGHT:
      return 'right';
    case Position.RIGHT_BOTTOM:
      return 'right-end';

    case 'auto':
    case 'auto-start':
    case 'auto-end':
      return position;

    default:
      throw new Error('Unexpected position');
  }
};

export const getHorizontalAlignment = (placement: PopperJs.Placement) => {
  const alignment = placement.split('-')[1] as 'start' | 'end' | undefined;
  switch (alignment) {
    case 'start':
      return 'left';
    case 'end':
      return 'right';
    default:
      return 'center';
  }
};

export const getVerticalAlignment = (placement: PopperJs.Placement) => {
  const alignment = placement.split('-')[1] as 'start' | 'end' | undefined;
  switch (alignment) {
    case 'start':
      return 'top';
    case 'end':
      return 'bottom';
    default:
      return 'center';
  }
};

export const getPosition = (placement: PopperJs.Placement) => {
  return placement.split('-')[0] as PopperJs.Position;
};

export const getOppositePosition = (side: PopperJs.Position): PopperJs.Position => {
  switch (side) {
    case 'top':
      return 'bottom';
    case 'right':
      return 'left';
    case 'bottom':
      return 'top';
    default:
      return 'right';
  }
};

export const getTransformOrigin = (data: PopperJs.Data) => {
  const position = getPosition(data.placement);
  const isHorizontal = isHorizontalPosition(position);

  const { reference, popper } = data.offsets;
  const naturalAlignment = (data.placement.split('-')[1] || 'center') as 'start' | 'end' | 'center';

  // Alignment is for the cross axis
  let alignment: string = isHorizontal ? getVerticalAlignment(data.placement) : getHorizontalAlignment(data.placement);

  const alignmentPoints = isHorizontal
    ? {
        leadingFactor: reference.top,
        trailingFactor: popper.top,
        popperSize: popper.height,
        refSize: reference.height
      }
    : {
        leadingFactor: reference.left,
        trailingFactor: popper.left,
        popperSize: popper.width,
        refSize: reference.width
      };

  if (alignmentPoints.popperSize > alignmentPoints.refSize) {
    // 1. If aligning to the center, must factor in the center point of the reference
    // 2. For non center alignment, no offsets should be considered b/c - only need to determine the leading factor delta.
    const referenceOffset = naturalAlignment === 'center' ? alignmentPoints.refSize / 2 : 0;

    // Verify that popper transform origin is correct on the cross-axis
    const crossAxisOffset = alignmentPoints.leadingFactor - alignmentPoints.trailingFactor + referenceOffset;
    alignment = crossAxisOffset + 'px';
  }

  const transformOrigin = isHorizontal ? `${getOppositePosition(position)} ${alignment}` : `${alignment} ${getOppositePosition(position)}`;
  return transformOrigin;
};

export const getPopoverOffset = (offset: PopoverOffset) => {
  switch (offset) {
    case PopoverOffset.NONE:
      return 0;
    case PopoverOffset.SMALL:
      return 4;
    case PopoverOffset.LARGE:
      return 16;
    case PopoverOffset.MEDIUM:
    default:
      return 8;
  }
};

export const popoverAnimations: { [key in PopoverTransition]: TransitionProperties } & {
  minimal: TransitionProperties;
} = {
  minimal: {
    to: { opacity: 1 },
    from: { opacity: 0.5 },
    leave: { opacity: 0.5 }
  },
  [PopoverTransition.FADE_SCALE_IN_OUT]: {
    to: { scale: 1, opacity: 1 },
    from: { scale: 0.8, opacity: 0 },
    leave: { scale: 0.8, opacity: 0 }
  },
  [PopoverTransition.FADE_SLIDE_IN_OUT]: {
    to: { scale: 1, opacity: 1 },
    from: { translateY: 0.8, opacity: 0 } as any,
    leave: { scale: 0.8, opacity: 0 }
  }
};

// const ARROW_PATH_WIDTH = 26;
// const ARROW_PATH_HEIGHT = 10;

export const getPopoverArrowOffsetModifier = (data: PopperJs.Data, offset: PopoverOffset) => {
  if (!data.arrowElement) {
    return data;
  }

  const currentPosition = getPosition(data.placement);
  const isVertical = isVerticalPosition(currentPosition);
  const offsetSide = isVertical ? 'top' : 'left';
  const length = isVertical ? 'height' : 'width';
  const arrowSize = data.arrowElement.clientHeight; // isVertical ? data.arrowElement.clientHeight : data.arrowElement.clientWidth;

  // console.log(data, arrowSize, currentPosition, targetPosition, offsetSide, isVertical);

  switch (currentPosition) {
    case 'top':
      data.offsets.popper[offsetSide] -= arrowSize;
      data.offsets.arrow[offsetSide] = data.offsets.popper[length];
      break;
    case 'left':
      data.offsets.popper.left -= arrowSize;
      data.offsets.arrow.left = data.offsets.popper.width - 10;
      break;

    case 'bottom':
      data.offsets.popper.top += arrowSize;
      data.offsets.arrow.top = -arrowSize;
      break;

    case 'right':
      data.offsets.popper.left += arrowSize;
      data.offsets.arrow.left = -(data.arrowElement.clientWidth - 10);
      break;
    default:
      break;
  }

  return data;
};
