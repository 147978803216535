import React, { forwardRef } from 'react';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { generateComponentDisplayName } from '../../utils';

interface Props {
  children: React.ReactNode;
  direction: 'left' | 'right';
}
const StyledInputIconContainer = styled(props => (
  <span ref={props.iconRef} className={props.className}>
    {props.children}
  </span>
))<{
  direction: Props['direction'];
}>`
  position: absolute;
  top: 0;
  ${props => props.direction}: 0;
  min-height: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInputIcon: React.FC<Props & { ref: React.RefObject<HTMLSpanElement> }> = React.memo(
  forwardRef<HTMLSpanElement, Props>((props, ref) => {
    const { children, direction } = props;

    return (
      <StyledInputIconContainer direction={direction} iconRef={ref}>
        {children}
      </StyledInputIconContainer>
    );
  })
);

StyledInputIcon.displayName = generateComponentDisplayName(StyledInputIcon);
