import React from 'react';
import Pagination from 'react-responsive-pagination';
import { Box, ButtonV2, DatePickerInput, SelectV1, TextV2, Flex, InputV2, FormControl, LinkV2 } from '@withjoy/joykit';
import { Link } from '@react-router';
import { useFilterBarController } from './FilterBar.controller';
import { usePrintOrderManagementState } from '../../state';
import { Select } from './components/Select';

import '../../../Messages/components/SearchBar/pagination.css';

const TAYLOR_URL = 'https://ordermanager.theoccasionsgroup.com/';

export const FilterBar = () => {
  const { totalPageCount, totalCount } = usePrintOrderManagementState();
  const {
    formik,
    pageSizeOptions,
    statusOptions,
    handlePageSizeSelect,
    handlePageNumberChange,
    handleOnFilterClick,
    handleOnOrderNumberChange,
    getSearchByOrderNumberPath,
    orderNumber
  } = useFilterBarController();

  return (
    <Box paddingTop={6}>
      <Flex alignItems="center" justifyContent="space-between">
        <TextV2 marginBottom={4}>Filter by one or multiple fields. Text must be exact match.</TextV2>
        <LinkV2 href={TAYLOR_URL} target="_blank" fontSize="1rem">
          Taylor Order Management System
        </LinkV2>
      </Flex>
      <Flex flexWrap="wrap" marginBottom={6}>
        <FormControl label="Event ID" padding={2} error={formik.errors.eventId}>
          <InputV2 {...formik.getFieldProps('eventId')} />
        </FormControl>
        <FormControl label="Event Name" padding={2} error={formik.errors.eventName}>
          <InputV2 {...formik.getFieldProps('eventName')} />
        </FormControl>
        <FormControl label="Owner ID" padding={2} error={formik.errors.ownerId}>
          <InputV2 {...formik.getFieldProps('ownerId')} />
        </FormControl>
        <FormControl label="User Email" padding={2} error={formik.errors.userEmail}>
          <InputV2 {...formik.getFieldProps('userEmail')} />
        </FormControl>
        <FormControl label="Status" padding={2} error={formik.errors.statusList}>
          <Select value={formik.values.statusList} onChange={value => formik.setFieldValue('statusList', value)} options={statusOptions} />
        </FormControl>
      </Flex>
      <Flex alignItems="flex-end" justifyContent="flex-start" columnGap="20px" marginBottom={6}>
        <FormControl label="Order Number" padding={2}>
          <InputV2 value={orderNumber} name="orderNumber" onChange={handleOnOrderNumberChange} />
        </FormControl>
        <ButtonV2 as={Link} to={getSearchByOrderNumberPath} target="_blank" disabled={!orderNumber} marginBottom="0.375rem">
          Search
        </ButtonV2>
      </Flex>
      <Box marginBottom={4}>
        <TextV2>Leave both dates blank to search all time.</TextV2>
        <TextV2>If only providing “From Date” results will show from that date to current day.</TextV2>
        <TextV2>If only providing “To Date” results will show order history up until that date.</TextV2>
      </Box>
      <Flex>
        <FormControl label="From Date" padding={2} error={formik.errors.startDate}>
          <Flex flexDirection="column" rowGap="5px">
            <DatePickerInput
              isReadOnly
              mask={null}
              dateFormat="MM/dd/yyyy"
              {...formik.getFieldProps('startDate')}
              onChange={date => {
                formik.setFieldValue('startDate', date);
              }}
            />
            <LinkV2 onClick={() => formik.setFieldValue('startDate', '')} fontSize="15px" textDecoration="none">
              Clear Date
            </LinkV2>
          </Flex>
        </FormControl>
        <FormControl label="To Date" padding={2} error={formik.errors.endDate}>
          <Flex flexDirection="column" rowGap="5px">
            <DatePickerInput
              isReadOnly
              mask={null}
              minDate={formik.getFieldProps('startDate').value}
              dateFormat="MM/dd/yyyy"
              {...formik.getFieldProps('endDate')}
              onChange={date => {
                formik.setFieldValue('endDate', date);
              }}
            />
            <LinkV2 onClick={() => formik.setFieldValue('endDate', '')} fontSize="15px" textDecoration="none">
              Clear Date
            </LinkV2>
          </Flex>
        </FormControl>
      </Flex>
      <Box paddingTop={7} paddingBottom={2} paddingX={2}>
        <ButtonV2 variant="solid" intent="primary" onClick={handleOnFilterClick} disabled={!formik.isValid}>
          Filter
        </ButtonV2>
      </Box>
      <Flex flexDirection="column" rowGap={6} marginTop={6}>
        <Flex>
          <FormControl label="Page Size" padding={2} error={formik.errors.pageSize}>
            <SelectV1 defaultValue={pageSizeOptions[1]} searchable={false} onChange={e => handlePageSizeSelect(Number(e?.value))} options={pageSizeOptions} />
          </FormControl>
        </Flex>
        {totalPageCount > 1 && (
          <Flex justifyContent="left">
            <FormControl label="Go to Page" padding={2}>
              <Pagination
                className="service-center-pagination"
                pageItemClassName="service-center-pagination__page-item"
                pageLinkClassName="service-center-pagination__page-link"
                activeItemClassName="service-center-pagination__page-item--active"
                disabledItemClassName="service-center-pagination__page-item--disabled"
                srOnlyClassName="service-center-pagination__sr"
                current={formik.getFieldProps('pageNumber').value}
                total={totalPageCount}
                onPageChange={newPage => handlePageNumberChange(newPage)}
              />
            </FormControl>
          </Flex>
        )}
        {!!totalCount && <TextV2>Total Results: {totalCount}</TextV2>}
      </Flex>
    </Box>
  );
};
