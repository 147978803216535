import { Flex, styled, StyleSystemProps, Box, ButtonV2 } from '@withjoy/joykit';
import { animated } from 'react-spring';

export const StyledRoot = styled(Flex)`
  position: fixed;
  bottom: 24px;
  gap: ${({ theme }) => theme.space[5]};
  width: min-content;
  max-width: 90vw;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid white;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.16);
  backdrop-filter: saturate(180%) blur(20px);
  border-radius: 8px;
  padding: 16px 32px;
  flex-wrap: nowrap;
  ${props => props.theme.mediaQueries.down({ breakpointAlias: 'sm4' })} {
    padding: 24px 32px;
    flex-wrap: wrap;
  }
`;

export const StyledTextContainer = styled(Flex)<{ hasIdentity: boolean }>`
  gap: ${({ theme }) => theme.space[3]};
  align-items: center;
  overflow: ${({ hasIdentity }) => (hasIdentity ? 'unset' : 'auto')};
`;

const textStyles: StyleSystemProps = {
  textAlign: 'center',
  whiteSpace: 'nowrap'
};

export const StyledButtonsContainer = styled(Flex)`
  gap: ${({ theme }) => theme.space[5]};
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  ${props => props.theme.mediaQueries.down({ breakpointAlias: 'xs' })} {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const StyledTransitionWrapper = styled(animated.div)`
  position: fixed;
  margin: 0 50%;
  bottom: 0;
  /* offset position grater then MainFrame z-index: 1 */
  z-index: ${({ theme }) => theme.zIndices.banner};
`;

export const StyledLink = styled(ButtonV2)`
  color: ${({ theme }) => theme.colors.mono14};
  :hover {
    color: ${({ theme }) => theme.colors.mono14};
  }
`;

export const StyledTriggerPoint = styled(Box)``;

const triggerPointStyles: StyleSystemProps = {
  position: 'relative',
  pointerEvents: 'none',
  bottom: ['30px', '200px']
};

const streamContentStyles: StyleSystemProps = {
  flexDirection: 'column',
  rowGap: 1
};

const streamStatusStyles: StyleSystemProps = {
  alignItems: 'center',
  columnGap: 3
};

export const styles = {
  text: textStyles,
  triggerPoint: triggerPointStyles,
  streamContainer: streamContentStyles,
  streamStatus: streamStatusStyles
};
