import * as React from 'react';
import { isValidElementType } from 'react-is';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isIconElement = (input: any): input is React.ElementType<any> => isValidElementType(input);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useIconProp = <P extends any = {}>(Icon: React.ReactNode, sharedProps: P = {} as P) => {
  //@ts-ignore
  return (isIconElement(Icon) ? <Icon focusable={false} {...sharedProps} /> : Icon) as React.ReactElement;
};
