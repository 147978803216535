import { ApolloError } from '@apollo/client';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const WeddingPartyListTelemetry = createTelemetryObject({
  actions: {
    trackError: (mutation: string, error: ApolloError | Error | unknown, extra = {}) => ({
      category: 'wedding',
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),

    VIPDetailsOpened: (position: number) => ({
      action: 'VIPDetailsOpenedClicked',
      actionType: 'click',
      category: 'wedding',
      label: position.toString()
    }),

    VIPDetailsClosed: () => ({
      action: 'VIPDetailsClosedClicked',
      actionType: 'click',
      category: 'wedding'
    })
  }
});

const { TelemetryProvider, useTelemetry: useWeddingPartyListTelemetry } = createTelemetry(WeddingPartyListTelemetry);
export { TelemetryProvider, useWeddingPartyListTelemetry };
