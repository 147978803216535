import { forwardStyledComponentRef } from '@shared/utils/forwardRef';
import React from 'react';
import { ButtonV2, ButtonV2Props } from '../ButtonV2';
import { VARIANT_STYLES } from './FabButton.styles';
import { IconButton } from '../IconButton';

type OmittedButtonProps = 'intent' | 'shape' | 'size';
export interface FabButtonProps
  extends Readonly<
    Merge<
      Omit<ButtonV2Props, OmittedButtonProps>,
      {
        /**
         * The icon to be rendered
         */
        children: React.ReactNode;

        /**
         * @default 'solid'
         */
        variant?: 'solid' | 'outline' | 'ghostShadow';
      }
    >
  > {}

type DefaultFabButtonProps = Required<Pick<FabButtonProps, 'variant'>>;

const defaultProps: DefaultFabButtonProps = {
  variant: 'solid'
};

export const FabButton = forwardStyledComponentRef<'button', FabButtonProps>((props, ref) => {
  return <ButtonV2 ref={ref} shape={'rounded'} {...VARIANT_STYLES[props.variant!]} {...props} />;
});

FabButton.defaultProps = defaultProps;

//---------------

export interface FabIconButtonProps
  extends Readonly<
    FabButtonProps & {
      /**
       * Accessibility label that describes the button
       */
      'aria-label': string;

      /**
       * The icon to be rendered
       */
      children: React.ReactElement;
    }
  > {}

export const FabIconButton = forwardStyledComponentRef<'button', FabIconButtonProps>((props, ref) => {
  return <IconButton ref={ref} shape={'rounded'} {...VARIANT_STYLES[props.variant!]} paddingX={0} {...props} />;
});

FabIconButton.defaultProps = defaultProps;
