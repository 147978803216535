import { useCallback, useMemo, useEffect, useState, ChangeEvent } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { omit } from 'lodash-es';
import { OptionType } from '@withjoy/joykit';
import { StationeryPrintOrderStatusEnum } from '@graphql/generated';
import { PrintOrderSearchValues } from '../../PrintOrderManagement.types';
import { usePrintOrderManagementState } from '../../state';
import { useRouterHelper } from '@shared/core';
import { routePaths } from '@apps/serviceCenter/route.paths';

const getFormikValidationSchema = () => {
  return Yup.object<PrintOrderSearchValues>({
    eventId: Yup.string(),
    eventName: Yup.string(),
    ownerId: Yup.string(),
    userEmail: Yup.string().email('Please enter a valid email')
  });
};

export const useFilterBarController = () => {
  const { setQueryArgsAndGetResults } = usePrintOrderManagementState();
  const formikValidationSchema = getFormikValidationSchema();
  const { buildPath } = useRouterHelper();
  const [orderNumber, setOrderNumber] = useState<string>('');

  const defaultSearchValues: PrintOrderSearchValues = {
    eventId: '',
    eventName: '',
    ownerId: '',
    userEmail: '',
    statusList: [
      StationeryPrintOrderStatusEnum.paid,
      StationeryPrintOrderStatusEnum.queued,
      StationeryPrintOrderStatusEnum.sent,
      StationeryPrintOrderStatusEnum.inProgress,
      StationeryPrintOrderStatusEnum.readyToShip,
      StationeryPrintOrderStatusEnum.shipped,
      StationeryPrintOrderStatusEnum.delivered,
      StationeryPrintOrderStatusEnum.complete,
      StationeryPrintOrderStatusEnum.error,
      StationeryPrintOrderStatusEnum.canceled,
      StationeryPrintOrderStatusEnum.refunded
    ],
    startDate: '',
    endDate: '',
    pageNumber: 1,
    pageSize: 20
  };

  const formik = useFormik<PrintOrderSearchValues>({
    initialValues: defaultSearchValues,
    validationSchema: formikValidationSchema,
    onSubmit: values => {
      const { pageNumber = 1, pageSize = 20, ...filters } = values;
      setQueryArgsAndGetResults({ pageNumber, pageSize }, filters);
    }
  });

  const pageSizeOptions: OptionType[] = useMemo(() => [10, 20, 30, 40, 50, 100].map(option => ({ value: `${option}`, label: `${option}` })), []);
  const statusOptions: OptionType[] = [
    { value: 'init', label: 'Init' },
    { value: 'paid', label: 'Paid' },
    { value: 'queued', label: 'Queued' },
    { value: 'sent', label: 'Sent' },
    { value: 'inProgress', label: 'In Progress' },
    { value: 'readyToShip', label: 'Ready To Ship' },
    { value: 'shipped', label: 'Shipped' },
    { value: 'delivered', label: 'Delivered' },
    { value: 'complete', label: 'Complete' },
    { value: 'error', label: 'Error' },
    { value: 'canceled', label: 'Canceled' },
    { value: 'refunded', label: 'Refunded' }
  ];

  const handlePageSizeSelect = useCallback(
    (pageSize: number) => {
      formik.setFieldValue('pageSize', pageSize);
      formik.setFieldValue('pageNumber', 1);
      const filters = omit(formik.values, ['pageSize', 'pageNumber']);
      setQueryArgsAndGetResults({ pageSize, pageNumber: 1 }, filters);
    },
    [formik, setQueryArgsAndGetResults]
  );

  const handlePageNumberChange = useCallback(
    (pageNumber: number) => {
      formik.setFieldValue('pageNumber', pageNumber);
      const filters = omit(formik.values, ['pageSize', 'pageNumber']);
      setQueryArgsAndGetResults({ pageSize: formik.values.pageSize ?? 20, pageNumber }, filters);
    },
    [formik, setQueryArgsAndGetResults]
  );

  const handleOnFilterClick = useCallback(() => {
    formik.setFieldValue('pageNumber', 1);
    formik.submitForm();
  }, [formik]);

  const handleOnOrderNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setOrderNumber(e.target.value), []);

  const getSearchByOrderNumberPath = useCallback(() => buildPath(routePaths.printOrderSearchByOrderNumber.goToPath(orderNumber)), [buildPath, orderNumber]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleOnFilterClick(), []);

  return {
    formik,
    pageSizeOptions,
    statusOptions,
    handlePageSizeSelect,
    handlePageNumberChange,
    handleOnFilterClick,
    handleOnOrderNumberChange,
    getSearchByOrderNumberPath,
    orderNumber
  };
};
