import React, { useRef } from 'react';
import { forwardStyledComponentRef } from '@shared/utils/forwardRef';
import { TextV2, TextV2Props } from '@withjoy/joykit';
import { isWhitespaceKeyClick } from '@withjoy/joykit/utils';
import { useCombinedRefs } from '@shared/utils/hooks/useCombinedRefs';
import { StyleSystemProps } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export type LinkV2Props = Readonly<{
  /**
   * If true, will open in a new tab.
   */
  isExternal?: boolean;
  /**
   * If true, rel attibute nofollow won't be added.
   */
  allowFollow?: boolean;

  href?: string;
}> &
  TextV2Props<'a'>;

const pseudoStyles: StyleSystemProps = {
  color: 'linkText',
  transition: animationTransition('color'),
  _visited: {
    color: 'linkVisited'
  },
  _active: {
    color: 'linkActive'
  },
  _hover: {
    color: 'linkHover'
  }
};

export const LinkV2 = forwardStyledComponentRef<'a', LinkV2Props>((props, ref) => {
  const { children, isExternal, allowFollow = false, as = 'a', ...restProps } = props;
  const linkRef = useRef<HTMLAnchorElement>(null);

  const combinedRef = useCombinedRefs(ref, linkRef);
  let relValue = [isExternal ? 'noopener' : undefined, !allowFollow ? 'nofollow' : undefined].filter(x => !!x).join(' ');
  return (
    <TextV2
      as={as}
      // Casting combinedRef as React.RefObject<any> because I can't get the right
      // typing to infer ref based on the polymorphic 'as' prop
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={combinedRef as React.RefObject<any>}
      typographyVariant={'body3'}
      rel={relValue}
      target={isExternal ? '_blank' : undefined}
      textDecoration={'underline'}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (isWhitespaceKeyClick(e.key)) {
          linkRef.current?.click();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 1);
        }
      }}
      {...pseudoStyles}
      {...restProps}
    >
      {children}
    </TextV2>
  );
});

LinkV2.displayName = 'Link';
