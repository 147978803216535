import { useMemo } from 'react';
import { isPast, isAfter, format, isFuture, differenceInMinutes, differenceInDays } from 'date-fns';
import { DateFragment } from '@graphql/generated';
import { VirtualEventBlockTranslations } from './VirtualEventBlock.i18n';

type ParsedEventTimes = Readonly<{
  localizedEventTimeMessage: { dateShort: string; timeframe: string; offset: string } | null;
  isEventUpcoming: boolean;
  isEventLive: boolean;
  isEventPast: boolean;
  isSameTimezone: boolean;
  message: string;
}>;

export const _formatTimeWindowEntry = (time: number) => format(time, 'h:mm a');
export const _formatTimezoneOffset = (time: number) => format(time, 'z');

// NOTE: Exposing original event time UTC Offset from the graph is in talks
// Confirmed with Dan that this approach is the only valid option until all the services
// use the latest @withjoy/server-core.
// https://github.com/joylifeinc/server-core/blob/master/src/graphql/core.types.ts#L73
export const _isSameTimezone = (sourceTimestamp: string, targetTimeMs: number) => {
  const targetUtcOffset = format(targetTimeMs, 'xxxxx'); // -07:00
  return sourceTimestamp.endsWith(targetUtcOffset);
};

export const isStartMinutesBefore = (startTimeMs: number, currentTimeMs: number, minutesBefore: number) => {
  const diffInMinStartAndNow = differenceInMinutes(startTimeMs, currentTimeMs);
  return diffInMinStartAndNow > 0 && diffInMinStartAndNow <= minutesBefore;
};

export const isStartDaysBefore = (startTimeMs: number, currentTimeMs: number, daysBefore: number) => {
  const diffInDaysStartAndNow = differenceInDays(startTimeMs, currentTimeMs);
  return diffInDaysStartAndNow > 0 && diffInDaysStartAndNow <= daysBefore;
};

export const useEventTimes = (startTime: Maybe<DateFragment>, endTime: Maybe<DateFragment>, translations: VirtualEventBlockTranslations): ParsedEventTimes => {
  return useMemo<ParsedEventTimes>(() => {
    // Indeterminate when no `startTime`
    if (!startTime) {
      return {
        localizedEventTimeMessage: null,
        isEventUpcoming: false,
        isEventLive: false,
        isEventPast: false,
        isSameTimezone: false,
        message: translations.eventTba
      };
    }

    const now = Date.now();
    const startTimeMs = startTime.milliseconds;
    const endTimeMs = endTime?.milliseconds || 0;
    // If no end time, use 1hr from start
    // NOTE: nick + erica are discussing this
    const resolvedEndTimeMs = endTimeMs || startTimeMs + 1000 * 60 * 60;

    const isEventUpcoming = isFuture(startTimeMs);
    const isEventPast = isPast(resolvedEndTimeMs);
    const isEventLive = !isEventPast && isAfter(now, startTimeMs);
    const upcomingTimeMessage = ''; //isEventUpcoming && isSameTimezone ? translations.eventUpcoming : '';

    // Show localized virtual event times when user is in a different timezone
    let localizedEventTimeMessage: ParsedEventTimes['localizedEventTimeMessage'] = null;

    const formattedStart = _formatTimeWindowEntry(startTimeMs);
    localizedEventTimeMessage = {
      // We use the initial `endTimeMs` instead of `resolvedEndTimeMs` for accurate representation
      dateShort: format(startTime.milliseconds, 'LLL d, yyyy'),
      timeframe: endTimeMs ? translations.timeframe(formattedStart, _formatTimeWindowEntry(endTimeMs)) : formattedStart,
      offset: _formatTimezoneOffset(startTimeMs) // GMT(-7)
    };

    const isSameTimezone = _isSameTimezone(startTime.timestamp, now);
    const startsInMin = differenceInMinutes(startTimeMs, now);
    const isStartsInHour = startsInMin <= 60;

    const message = (() => {
      if (isEventUpcoming && !isStartsInHour) return translations.eventUpcoming;
      if (isStartsInHour && !isEventPast) return translations.eventStartsSoon(startsInMin);
      if (isEventPast) return translations.eventPassed;

      return '';
    })();

    return {
      localizedEventTimeMessage,
      upcomingTimeMessage,
      isEventUpcoming,
      isEventPast,
      isEventLive,
      isSameTimezone,
      message
    };
  }, [startTime, endTime, translations]);
};
