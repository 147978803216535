import { DesignLayoutType } from '@graphql/generated';
import React, { createContext, useContext } from 'react';

export type LayoutContextValue = {
  layout: DesignLayoutType;
};

export const LayoutContext = createContext<LayoutContextValue | undefined>(undefined);

export const LayoutProvider: React.FC<{ layout: DesignLayoutType }> = ({ children, layout }) => {
  return <LayoutContext.Provider value={{ layout }}>{children}</LayoutContext.Provider>;
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};
