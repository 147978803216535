import { forwardStyledComponentRef } from '@shared/utils/forwardRef';
import React from 'react';
import { ButtonV2, ButtonV2Props } from '../ButtonV2';

type OmittedButtonProps = 'startIcon' | 'endIcon' | 'fullWidth';
export interface IconButtonProps
  extends Readonly<
    Merge<
      Omit<ButtonV2Props, OmittedButtonProps>,
      {
        /**
         * Accessibility label that describes the biutton
         */
        'aria-label': string;

        /**
         * The icon to be rendered
         */
        children: React.ReactElement;

        /**
         * @default 'lg'
         */
        size?: 'sm' | 'md' | 'lg';

        /**
         * @default 'solid'
         */
        variant?: 'solid' | 'outline' | 'ghost';
      }
    >
  > {}

type DefaultIconButtonProps = Required<Pick<IconButtonProps, 'size' | 'variant'>>;

const defaultProps: DefaultIconButtonProps = {
  size: 'lg',
  variant: 'solid'
};

export const IconButton = forwardStyledComponentRef<'button', IconButtonProps>((props, ref) => {
  const { 'aria-label': ariaLabel, children, ...restProps } = props as IconButtonProps & DefaultIconButtonProps;

  const childIcon = React.isValidElement(children) ? React.cloneElement(children as any, { 'aria-hidden': true, focusable: false, title: undefined }) : null;

  return (
    <ButtonV2 ref={ref} paddingX={0} aria-label={ariaLabel} {...restProps}>
      {childIcon}
    </ButtonV2>
  );
});

IconButton.defaultProps = defaultProps;
