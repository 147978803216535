import React from 'react';
import { RadioProps } from '.';
import { removeNonHTMLProps, safeInvoke } from '../../utils';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { CHOICE_INDICATOR } from '../../common';

const Input: React.FC<RadioProps> = props => (
  <input
    aria-checked={props.checked}
    {...removeNonHTMLProps(props)}
    onChange={e => {
      safeInvoke(props.onChange, e);
      // e.currentTarget.focus();
    }}
    checked={props.checked}
    type={'radio'}
  />
);

export const StyledInput = styled(Input)`
  :enabled {
    :focus ~ .${CHOICE_INDICATOR} {
      border-color: ${props => props.theme.colors.fillDark};
    }
    :active ~ .${CHOICE_INDICATOR} {
      border-color: ${props => props.theme.colors.fillDark};
    }
  }

  :checked {
  }
`;
