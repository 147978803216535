import React, { useState, useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { createContext } from '@shared/utils/createContext';
import { useAuth } from '@shared/components/AuthProvider';
import type { EmailDeliverySearchResults } from '@apps/serviceCenter/ServiceCenter.types';
import { useGetEmailDeliveryDataLazyQuery, EmailSearchCriteria } from '@graphql/generated';
import { omit } from 'lodash-es';
import { format } from 'date-fns';
import { ApolloError } from '@apollo/client';
import { isUndefined } from '@shared/utils/assertions';

type ServiceCenterStateContext = {
  isLoading: boolean;
  isSuperAdmin: boolean;
  currentUserName: string;
  searchResults: EmailDeliverySearchResults;
  setSearchResults: (values: Maybe<EmailSearchCriteria> | undefined) => void;
  searchResultsLoading: boolean;
  searchResultsError: ApolloError | undefined;
  searchResultsCalled: boolean;
  filteredSearchResults: EmailDeliverySearchResults;
  setFilteredSearchResults: Dispatch<SetStateAction<EmailDeliverySearchResults>>;
  totalPageCount: number;
};

const isValidSearchResult = <T extends EmailDeliverySearchResults[number]>(x: Maybe<T>): x is T => !isUndefined(x);

const [Provider, useServiceCenterState] = createContext<ServiceCenterStateContext>({ name: 'ServiceCenterState' });

const ServiceCenterState: React.FC = ({ children }) => {
  const { currentUser, isCurrentUserSettled } = useAuth();
  const { profile: userProfile } = currentUser;

  const isSuperAdmin = currentUser && currentUser.profile?.superAdmin;
  const currentUserName = userProfile?.firstName || userProfile?.lastName || userProfile?.email || '';

  const [
    findEmailDeliveryData,
    { loading: searchResultsLoading, data: searchResultsData, error: searchResultsError, called: searchResultsCalled }
  ] = useGetEmailDeliveryDataLazyQuery({
    batchMode: 'fast'
  });

  const totalPageCount = useMemo<number>(() => {
    return searchResultsData?.getEmailDeliveryData?.pageInfo?.totalPageCount || 0;
  }, [searchResultsData]);

  const searchResults = useMemo<EmailDeliverySearchResults>(() => {
    return searchResultsData?.getEmailDeliveryData?.edges?.map(edge => edge?.node).filter(isValidSearchResult) || [];
  }, [searchResultsData]);

  const setSearchResults = useCallback(
    (values: Maybe<EmailSearchCriteria> | undefined) => {
      const args = {
        ...omit(values, ['startCreateDate', 'endCreateDate']),
        startCreateDate: values && values.startCreateDate ? format(new Date(values.startCreateDate), 'MM/dd/yyyy') : '',
        endCreateDate: values && values.endCreateDate ? format(new Date(values.endCreateDate), 'MM/dd/yyyy') : ''
      };
      findEmailDeliveryData({ variables: { args } });
    },
    [findEmailDeliveryData]
  );

  const [filteredSearchResults, setFilteredSearchResults] = useState<EmailDeliverySearchResults>(searchResults);

  return (
    <Provider
      value={{
        isLoading: !isCurrentUserSettled,
        isSuperAdmin: !!isSuperAdmin,
        currentUserName,
        searchResults,
        setSearchResults,
        searchResultsLoading,
        searchResultsError,
        searchResultsCalled,
        filteredSearchResults,
        setFilteredSearchResults,
        totalPageCount
      }}
    >
      {children}
    </Provider>
  );
};

export { useServiceCenterState, ServiceCenterState };
