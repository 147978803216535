import { styled, pseudoSelectors } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import { margin } from 'styled-system';
import { Linkify } from '@shared/components';

const linkifyProps = new Set(['tagName', 'options', 'children', 'prettyUrlOptions', 'source']);
export const StyledRoot = styled(Linkify).withConfig({
  shouldForwardProp: (prop, defaultValidator) => {
    return typeof prop === 'string' ? linkifyProps.has(prop) : defaultValidator(prop);
  }
})`
  ${margin}
  a {
    color: ${props => props.theme.colors.linkText};
    text-decoration: underline;
    transition: ${animationTransition('color')};
    ${props => {
      return {
        [pseudoSelectors._visited]: {
          color: props.theme.colors.linkVisited
        },
        [pseudoSelectors._active]: {
          color: props.theme.colors.linkActive
        },
        [pseudoSelectors._hover]: {
          color: props.theme.colors.linkHover
        }
      };
    }}
  }
`;
