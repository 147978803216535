import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FindInviteFormProps } from '@apps/guest/packages/layout-engine/components/UnlockDialog/FindInviteForm/FindInviteForm';
import { useApolloClient } from '@apollo/client';
import { FindInviteDocument, FindInviteQuery, useJoinEventAsPersonWithPasswordMutation } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { useEffect } from 'react';
import { useToast } from '@withjoy/joykit';

interface FindInviteFields
  extends Readonly<{
    firstName: string;
    lastName: string;
    notFound: string;
  }> {}

export function useFindInviteFormController({ eventId, setGuestsFound, setGuest }: FindInviteFormProps) {
  const { toast } = useToast();
  const client = useApolloClient();
  const { t, t2 } = useTranslation('guestUnlock');
  const { title, subtitle, firstNameLabel, lastNameLabel, submitButtonText, required, firstNamePlaceholder, lastNamePlaceholder } = t2('findInviteForm');
  const [joinEvent, { loading }] = useJoinEventAsPersonWithPasswordMutation();

  const formik = useFormik<FindInviteFields>({
    initialValues: {
      firstName: '',
      lastName: '',
      notFound: ''
    },
    validationSchema: Yup.object<FindInviteFields>({
      firstName: Yup.string().required(required),
      lastName: Yup.string().required(required),
      notFound: Yup.string()
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async ({ firstName, lastName }) => {
      try {
        const { data } = await client.query<FindInviteQuery>({
          query: FindInviteDocument,
          variables: { eventId, firstName, lastName },
          fetchPolicy: 'no-cache'
        });
        const guestsFoundCount = data?.event?.findPeople.resultCount;
        if (guestsFoundCount && guestsFoundCount > 1) {
          setGuestsFound(guestsFoundCount);
          setGuest({ firstName, lastName });
        } else if (guestsFoundCount === 1) {
          const person = data?.event?.findPeople.results[0].person;

          await joinEvent({
            variables: {
              eventId,
              payload: { personIdTag: person?.personIdTag }
            },
            refetchQueries: () => ['GetEventSession', 'GetGuestSiteSchedule', 'GetEventCTAButtonsData'],
            awaitRefetchQueries: true
          });

          setGuest({
            firstName: person?.firstName,
            lastName: person?.lastName,
            personIdTag: person?.personIdTag
          });
        } else {
          const notFoundErrorTitle = t(
            'findInviteForm',
            'notFoundErrorText'
          )({
            firstName: formik.values.firstName,
            lastName: formik.values.lastName
          });
          formik.setFieldError('notFound', notFoundErrorTitle);
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  const inputError = formik.errors.firstName || formik.errors.lastName || '';
  const notFoundError = formik.errors.notFound;

  useEffect(() => {
    if (inputError) {
      toast(inputError);
    }
  }, [inputError, toast]);

  useEffect(() => {
    if (notFoundError) {
      toast(notFoundError);
    }
  }, [notFoundError, toast]);

  return {
    formik,
    loading: formik.isSubmitting || loading,
    title,
    subtitle,
    firstNameLabel,
    lastNameLabel,
    firstNamePlaceholder,
    lastNamePlaceholder,
    submitButtonText,
    lastNameInputError: formik.errors.lastName,
    firstNameInputError: formik.errors.firstName,
    notFoundError: formik.errors.notFound
  };
}
