export function getValidBookingLink(bookingLink: string | undefined): URL | undefined {
  if (!bookingLink) {
    return undefined;
  }
  // Ensure the URL string includes a protocol
  if (!/^https?:\/\//i.test(bookingLink)) {
    bookingLink = 'https://' + bookingLink;
  }
  try {
    return new URL(bookingLink);
  } catch (error) {
    return undefined;
  }
}
