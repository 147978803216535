import { styled } from '@withjoy/joykit/theme';
import { Box, StyleSystemProps } from '@withjoy/joykit';

export const BatchCardContainer = styled(Box)``;
export const StyledAvatar = styled(Box)``;
export const StyledTextContainer = styled(Box)``;

const batchCardContainer: StyleSystemProps = {
  color: 'linkText',
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  textAlign: 'left',
  width: '100%'
};

const textContainer: StyleSystemProps = {
  overflow: 'hidden'
};

const avatar: StyleSystemProps = {
  backgroundColor: 'buttonPrimaryFill',
  color: 'buttonPrimaryText',
  fontWeight: 'bold',
  borderRadius: [32],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.5rem',
  width: [56],
  height: [56],
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: 'buttonPrimaryText'
};

const email: StyleSystemProps = {
  opacity: 0.8
};

export const styles = {
  batchCardContainer,
  textContainer,
  avatar,
  email
};
