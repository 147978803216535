import { useEffect } from 'react';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { withWindow } from '@shared/utils/withWindow';

export const useInformParentOnMousedown = (isPreviewing: boolean) => {
  useEffect(() => {
    // send message to parent window to notify the that a mouse down event happened inside the guest site loaded in the iframe
    const handleOnMousedown = (e: MouseEvent) => {
      const el = e.target;
      if (el) {
        // send the message to the parent window to notify a click is happened inside the iframe
        sendMessageToParentWindow({
          action: 'iframePreviewMouseDown',
          source: 'joyPreview'
        });
      }
    };
    if (isPreviewing) {
      withWindow(window => {
        window.document.addEventListener('mousedown', handleOnMousedown);
      });
    }
    return () => {
      withWindow(window => {
        window.document.removeEventListener('mousedown', handleOnMousedown);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviewing]);
};
