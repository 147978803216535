import { withWindow } from '@shared/utils/withWindow';
import React from 'react';

/**
 * Changes to button IDs must be coordinated with the native apps
 */
type WellKnownButtonId = 'SetupAddToJoyButton' | 'ContactCollectorEditGuestList' | 'ContactCollectorCopyLink';

type MessageType = 'ButtonClickEvent' | 'NavigateToTab' | 'Navigate'; // add new event types here like CheckBoxSelectedEvent etc.

type ButtonClickEventData = Readonly<{
  componentAction: 'Clicked';
  componentId: WellKnownButtonId;
  componentType: 'Button';
  url?: string;
}>;

type NavigateToTabData = Readonly<{
  tabName: 'addGifts';
}>;

type NavigateDeeplinkData = Readonly<{
  url: string;
}>;

type PostMessagePayload = Readonly<
  {
    signature: 'JoyWebViewToNativeMessage';
    type: MessageType;
  } & (
    | {
        type: 'ButtonClickEvent';
        data: ButtonClickEventData;
      }
    | {
        type: 'NavigateToTab';
        data: NavigateToTabData;
      }
    | {
        type: 'Navigate';
        data: NavigateDeeplinkData;
      }
  )
>;

export const webViewPostMessage = (message: PostMessagePayload) => {
  withWindow(global => {
    // iOS
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (global as any).webkit?.messageHandlers?.iOSHandler?.postMessage?.(message);

    //Android
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (global as any).JoyAndroid?.postMessage?.(message);
  });
};

export const useNativeState = (): Readonly<{
  isNative: boolean;
  appVersion: string | null;
}> => {
  return withWindow(
    global => {
      return {
        isNative: !!global.localStorage?.getItem('isNative'),
        appVersion: global.localStorage?.getItem('appVersion')
      };
    },
    {
      isNative: false,
      appVersion: '0.0.0'
    }
  );
};

export const useButtonClickHandler = (
  options: Readonly<{
    buttonId: WellKnownButtonId;
    handler: React.MouseEventHandler;
  }>
): React.MouseEventHandler => {
  const { isNative } = useNativeState();
  return isNative
    ? e => {
        e.preventDefault();
        webViewPostMessage({
          signature: 'JoyWebViewToNativeMessage',
          type: 'ButtonClickEvent',
          data: {
            componentAction: 'Clicked',
            componentId: options.buttonId,
            componentType: 'Button'
          }
        });
        // per Orest:
        // the mobile app will never respond to both messages at the same time:
        // - old clients will only respond to the old message type (element based)
        // - new updated clients will only respond to the new message type (action based)
        // after we reach a adoption in the new clients we can remove the old post message.
        // TO DO: remove the old call
        webViewPostMessage({
          signature: 'JoyWebViewToNativeMessage',
          type: 'NavigateToTab',
          data: {
            tabName: 'addGifts'
          }
        });
      }
    : options.handler;
};
