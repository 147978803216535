import React from 'react';
import { Box, ButtonV2 } from '@withjoy/joykit';
import { EventTable } from './components/EventTable';
import { routePaths } from '@apps/serviceCenter/route.paths';
import { ArrowLeft } from '@withjoy/joykit/icons';
import { Link, useRouterHelper } from '@shared/core';
import { EventFound, SearchForEvent } from '@apps/serviceCenter/forms/SearchForEvent';

export const Events = () => {
  const routeHelpers = useRouterHelper();
  const [eventFound, setEventFound] = React.useState<EventFound>(null);

  return (
    <Box as="main" paddingX={'2.5rem'}>
      <Box display="grid" rowGap={'1.5rem'} maxWidth={'1440px'} marginX={'auto'} marginTop={10}>
        <ButtonV2 as={Link} to={routeHelpers.buildPath(routePaths.servicecenter.path)} variant="link" fullWidth={false} justifySelf={'self-start'}>
          <ArrowLeft size="md" marginRight={'0.5rem'} /> Back to Service Center
        </ButtonV2>
        <SearchForEvent setEventFound={setEventFound} />
        <EventTable event={eventFound} />
      </Box>
    </Box>
  );
};
