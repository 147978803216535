import { isInIframe } from '@shared/utils/isInIframe';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { useGuestPageContainerBySlugQuery } from '@graphql/generated';

export const useCustomData = (eventId: string, eventHandle: string, pageSlug: string) => {
  const isPreviewing = isInIframe();
  const { isAdmin } = useEventUserRole();

  const { data: pageContainerData, loading } = useGuestPageContainerBySlugQuery({
    variables: {
      payload: {
        eventName: eventHandle,
        pageSlug: pageSlug
      },
      isAdminDashboard: isPreviewing && isAdmin
    },
    fetchPolicy: 'cache-and-network',
    batchMode: 'fast'
  });

  return {
    loading,
    pageContainerBySlug: pageContainerData?.pageContainerBySlug
  };
};
