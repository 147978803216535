import React, { useMemo } from 'react';
import { styles } from './SlapAdsRegistry.styles';
import { ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { useSlapAdsRegistryTranslations } from './SlapAdsRegistry.i18n';

interface SlapAdsRegistryProps {
  redirectTo: string;
}

const SlapAdsRegistry: React.FC<SlapAdsRegistryProps> = ({ redirectTo }) => {
  const { messageVariants } = useSlapAdsRegistryTranslations();
  const variantSelected = useMemo(() => messageVariants[Math.floor(Math.random() * messageVariants.length)], [messageVariants]);

  return (
    <Flex
      __css={styles.slapAdsRegistryWrapper}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      rowGap={6}
      borderRadius={3}
      backgroundColor="warning2"
      padding={6}
    >
      <TextV2 textAlign="center" color="mono14" typographyVariant="hed2">
        {variantSelected.message()}
      </TextV2>
      <ButtonV2 href={redirectTo} target="_blank" as="a" intent="neutral" shape="rounded" fontWeight="bold" fontSize={16}>
        {variantSelected.btn()}
      </ButtonV2>
    </Flex>
  );
};

SlapAdsRegistry.displayName = 'SlapAdsRegistry';

export { SlapAdsRegistry };
