import React, { useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useIsMounted } from '../../common';

interface ResizeSensorProps {
  children: React.ReactNode;
  onResize: () => void;
}

export const ResizeSensor: React.FC<ResizeSensorProps> = props => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (!isMounted) {
      return;
    }
    let ro: ResizeObserver;

    requestAnimationFrame(() => {
      ro = new ResizeObserver((entries: unknown[], observer: unknown) => {
        for (const _ of entries) {
          // const { left, top, width, height } = entry.contentRect;
          props.onResize();
        }
      });

      if (nodeRef.current) {
        ro.observe(nodeRef.current);
      }
    });

    return () => {
      if (ro) {
        ro.disconnect();
      }
    };
  }, [isMounted]);

  const { children } = props;
  return <div ref={nodeRef}>{children}</div>;
};

ResizeSensor.displayName = 'ResizeSensor';
