import { useUpdateEffect } from '@shared/utils/hooks/useUpdateEffect';
import { useCallback } from 'react';
import { isTabbable } from '@withjoy/joykit/utils';

const focusableSelectors = [
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'embed',
  'iframe',
  'object',
  'a[href]',
  'area[href]',
  'button:not([disabled])',
  '[tabindex]:not([tabindex="-1"])',
  'audio[controls]',
  'video[controls]',
  '*[tabindex]:not([aria-disabled])',
  '*[contenteditable]'
] as const;

const focusableSelectorString = focusableSelectors.join();

const getAllFocusableEl = (container: HTMLElement) => {
  const focusableEls = [container, ...Array.from(container.querySelectorAll<HTMLElement>(focusableSelectorString))];
  return focusableEls.filter(el => !el.hasAttribute('disabled') && !el.hasAttribute('aria-hidden') && window.getComputedStyle(el)?.display !== 'none');
};

export const useOnShowFocus = <T extends HTMLElement>(ref: React.RefObject<T>, { isVisible, shouldFocus }: { isVisible: boolean; shouldFocus: boolean }) => {
  const element = ref.current;

  const canFocus = isVisible && shouldFocus;

  const addFocus = useCallback(() => {
    if (!element || !canFocus) {
      return;
    }
    const focusable = getAllFocusableEl(element);
    if (focusable.length > 0) {
      focusable[0]?.focus({ preventScroll: true });
    }
  }, [element, isVisible, shouldFocus, canFocus]);

  useUpdateEffect(() => {
    addFocus();
  }, [addFocus]);
};

const shouldPreventReturnFocus = (el: Maybe<HTMLElement>) => {
  if (!el) {
    return false;
  }

  const activeElement = el.ownerDocument?.activeElement as HTMLElement | null;

  if (!activeElement) {
    return false;
  }

  if (el.contains(activeElement)) {
    return false;
  }

  if (isTabbable(activeElement)) {
    return true;
  }
  return false;
};

export const useOnHideFocus = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  { focusRef, isVisible, shouldFocus }: { isVisible: boolean; shouldFocus: boolean; focusRef: React.RefObject<HTMLElement | null> }
) => {
  const element = ref.current;

  const canMoveFocus = !isVisible && shouldFocus;

  const addFocus = useCallback(() => {
    if (!element || !canMoveFocus) {
      return;
    }

    if (!shouldPreventReturnFocus(focusRef.current)) {
      focusRef.current?.focus({ preventScroll: true });
    }
    focusRef;
  }, [element, canMoveFocus, focusRef]);

  useUpdateEffect(() => {
    addFocus();
  }, [addFocus]);
};
