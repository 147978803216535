import React, { useCallback } from 'react';
import { DateFragment, EventPageType } from '@graphql/generated';
import { Box, LinkV2, Flex, TextV2 } from '@withjoy/joykit';
import { EventCountdown } from '../EventCountdown';
import { Link as RL } from '@react-router';
import { useLayout } from '../../layouts/LayoutProvider';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { useTranslation as useTranslationCore } from '@shared/core';
import { StyledRoot, StyledBoxViewDetails, StyledBoxText, StyledBoxArrow, StyledBoxArrowIcon, styles } from './EventLogisticBlock.styles';
import { InlineEditor } from '@shared/components';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';

export interface EventLogisticsBlockProps
  extends Readonly<{
    location?: Nullable<string>;
    eventHandle?: string;
    page?: Object;
    date?: Maybe<DateFragment & { timeString: string; dateString: string; timezone: string }>;
    shouldBreakDate?: boolean;
    hideEventCountdown: boolean;
    showViewDetailsButton?: boolean;
    nextPageSlug?: Maybe<string>;
    onViewDetailsButtonClicked?: () => void;
  }> {}

const _getEventDateHeader = (date: EventLogisticsBlockProps['date'], shouldBreak: boolean) => {
  let dateHeader = date ? date.dateString.replace(date.timeString, '').trim() : null;
  if (shouldBreak && dateHeader) {
    const [dayOfWeek, ...dates] = dateHeader.split(',');
    dateHeader = `${dayOfWeek}\n${dates.join(',')}`;
  }
  return dateHeader;
};

const EventLogisticsBlock: React.FC<EventLogisticsBlockProps> = ({
  children,
  page,
  eventHandle,
  location,
  date,
  shouldBreakDate = false,
  hideEventCountdown,
  showViewDetailsButton,
  nextPageSlug,
  onViewDetailsButtonClicked,
  ...restProps
}) => {
  const { t } = useTranslation('guestSiteWelcome');
  const inlineEditorTrans = useTranslationCore('joykit').t2('inlineEditor');

  const { layout } = useLayout();
  const [isMobile] = useResponsive({ values: { xxs: true, xs: true, sm: false } });
  const isAloha = layout === 'aloha';
  const eventDateHeader = _getEventDateHeader(date, shouldBreakDate);

  const handleViewDetailsButton = useCallback(() => {
    onViewDetailsButtonClicked && onViewDetailsButtonClicked();
  }, [onViewDetailsButtonClicked]);

  const handleEditEventDateClick = useEventCallback(() => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editDate', inlineEditData: { date: date?.dateString || '', timeZone: date?.timezone || 'America/Los_Angeles', label: inlineEditorTrans.eventDate } }
    });
  });

  const handleEditEventLocationClick = useEventCallback(() => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editLocation', inlineEditData: { location: location || '', label: inlineEditorTrans.eventLocation } }
    });
  });

  if (!children && !location && !date) {
    return null;
  }

  return (
    // position relative should prevent the back sprite from being above the z of this box
    <StyledRoot __css={styles.root} {...restProps}>
      <Flex flexDirection={'column'} textAlign={'center'}>
        {eventDateHeader && (
          <InlineEditor
            elementLabel={inlineEditorTrans.eventDate}
            actionData={{
              edit: handleEditEventDateClick
            }}
            componentName="eventDate"
            pageName={EventPageType.welcome}
            pageSlug="welcome"
            wrapperCSS={{ zIndex: 100 }}
          >
            <TextV2 data-testid={'logistics-date'} typographyVariant={'display2'} whiteSpace={'pre-wrap'}>
              {eventDateHeader}
            </TextV2>
          </InlineEditor>
        )}
        {location && (
          <Box marginTop={7}>
            <InlineEditor
              elementLabel={inlineEditorTrans.eventLocation}
              actionData={{
                edit: handleEditEventLocationClick
              }}
              componentName="eventLocation"
              pageName={EventPageType.welcome}
              pageSlug="welcome"
              wrapperCSS={{ zIndex: 100 }}
            >
              <TextV2 data-testid={'logistics-location'} typographyVariant={'display1'}>
                {location}
              </TextV2>
            </InlineEditor>
          </Box>
        )}
        {!hideEventCountdown && date && <EventCountdown data-testid={'logistics-countdown'} milliseconds={date.milliseconds} timezone={date.timezone} marginTop={6} />}
      </Flex>
      {children && <Box>{children}</Box>}
      {
        /* View Details + Arrow */
        isAloha && !isMobile && nextPageSlug && (
          <StyledBoxViewDetails show={!!showViewDetailsButton}>
            <LinkV2
              onClick={handleViewDetailsButton}
              as={RL}
              to={`/${eventHandle}/${nextPageSlug}`}
              color={'inherit'}
              _visited={{ color: 'inherit' }}
              _hover={{ color: 'inherit' }}
              _active={{ color: 'inherit' }}
              textAlign="center"
              textDecoration="none"
            >
              <StyledBoxText>{t('viewDetails')}</StyledBoxText>
              <StyledBoxArrow>
                <StyledBoxArrowIcon />
              </StyledBoxArrow>
            </LinkV2>
          </StyledBoxViewDetails>
        )
      }
    </StyledRoot>
  );
};

EventLogisticsBlock.displayName = 'EventLogisticsBlock';

export { EventLogisticsBlock };
