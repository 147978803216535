import { styled, TextV2 } from '@withjoy/joykit';

export const DiscountCodeCoppiedNotification = styled(TextV2)`
  background: rgba(0, 0, 0, 0.75);
  widthy: 168px;
  height: 46px;
  border-radius: 22px;
  padding: 12px 16px;
  color: #ffffff;
  position: absolute;
`;
