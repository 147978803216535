import { ResponsiveMarginProps } from '@withjoy/joykit/styled-system';
import React from 'react';
import { ICalendar, GoogleCalendar, OutlookCalendar } from 'datebook';
import { Menu } from '@withjoy/joykit/components/Menu';
import { Box, DrawerV2, PopoverV2, SpacingStack } from '@withjoy/joykit';
import { useDisclosure } from '@withjoy/joykit';
import { BrandGoogle, BrandOutlook, BrandApple } from '@withjoy/joykit/icons';
import { ReactComponent as BrandOutlookInverse } from '@assets/icons/brand-outlook-inverse.svg';
import { ReactComponent as BrandAppleInverse } from '@assets/icons/brand-apple-inverse.svg';
import { useAddToCalendarTranslations } from './AddToCalendar.i18n';
import { StyledMenuItem, menuItemOverrides, styles, dialogOverridesMobile, DrawerItem, CloseItem } from './AddToCalendar.styles';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

export interface AddToCalendarProps
  extends Readonly<{
      title?: string;
      description?: string;
      location?: string;
      start: string;
      end?: string;
      children: React.ReactElement | React.ReactText;
      handleClickAddToCalendar?: (title: string, type: string) => void;
    }>,
    ResponsiveMarginProps {}

const AddToCalendar: React.FC<AddToCalendarProps> = ({ children, title, location, description, start, end, handleClickAddToCalendar }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { google, apple, outlook, cancel } = useAddToCalendarTranslations();
  const dateStart = new Date(start);
  /**
   * If the end time is not defined (some events don't have an end date)
   * then the default behavior is to add 4 hours
   */
  const dateEnd = end ? new Date(end) : new Date(new Date(start).setHours(dateStart.getHours() + 4));
  const config = {
    title,
    location,
    description,
    start: dateStart,
    end: dateEnd
  };
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const mobileOpen = (isMobile ?? false) && isOpen;
  const nonMobileOpen = (!isMobile ?? false) && isOpen;

  const handleClick = (type: 'google' | 'apple' | 'outlook') => () => {
    switch (type) {
      case 'google':
        const googleCalendar = new GoogleCalendar(config);
        let calendarUrl = googleCalendar.render();
        window.open(calendarUrl, '_blank');
        break;
      case 'apple':
        const icalendar = new ICalendar(config);
        icalendar.download();
        break;
      default:
        const outlookCalendar = new OutlookCalendar(config);
        calendarUrl = outlookCalendar.render();
        window.open(calendarUrl, '_blank');
        break;
    }
    handleClickAddToCalendar && handleClickAddToCalendar(title || '', type);
    onClose();
  };

  return (
    <PopoverV2 triggerType="click" isOpen={nonMobileOpen} onClose={onClose} onOpen={onOpen}>
      <PopoverV2.Trigger>{children}</PopoverV2.Trigger>
      <PopoverV2.Content>
        <Menu>
          <StyledMenuItem
            __css={styles.menuitem}
            overrides={menuItemOverrides}
            data-testid="menuitem-google"
            content={
              <Box as="button" __css={styles.button} onClick={handleClick('google')}>
                <BrandGoogle /> {google}
              </Box>
            }
          />
          <StyledMenuItem
            __css={styles.menuitem}
            overrides={menuItemOverrides}
            data-testid="menuitem-apple"
            content={
              <Box as="button" __css={styles.button} onClick={handleClick('apple')}>
                <BrandAppleInverse /> {apple}
              </Box>
            }
          />
          <StyledMenuItem
            __css={styles.menuitem}
            overrides={menuItemOverrides}
            data-testid="menuitem-outlook"
            content={
              <Box as="button" __css={styles.button} onClick={handleClick('outlook')}>
                <BrandOutlookInverse /> {outlook}
              </Box>
            }
          />
        </Menu>
      </PopoverV2.Content>
      <DrawerV2 isOpen={mobileOpen} onClose={onClose} enableDividers={true} anchor="bottom" overrides={dialogOverridesMobile} size="sm">
        <DrawerV2.Header typographyVariant="hed4" textAlign="center">
          {title}
        </DrawerV2.Header>
        <DrawerV2.Body>
          <SpacingStack spacing={2} as="ul" alignItems={'center'}>
            <DrawerItem onClick={handleClick('google')}>
              <BrandGoogle /> {google}
            </DrawerItem>
            <DrawerItem onClick={handleClick('apple')}>
              <BrandApple /> {apple}
            </DrawerItem>
            <DrawerItem onClick={handleClick('outlook')}>
              <BrandOutlook /> {outlook}
            </DrawerItem>
            <CloseItem onClick={onClose}>{cancel}</CloseItem>
          </SpacingStack>
        </DrawerV2.Body>
      </DrawerV2>
    </PopoverV2>
  );
};

AddToCalendar.displayName = 'AddToCalendar';

export { AddToCalendar };
