/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Hotel Product',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M1.45 4.749c0-1.04.843-1.883 1.883-1.883h9.333c1.04 0 1.883.843 1.883 1.883v3.067c.433.367.708.915.708 1.527v3.284a.51.51 0 0 1-.508.508h-1.9a.51.51 0 0 1-.508-.508v-.649H3.664v.649a.51.51 0 0 1-.508.508H1.25a.51.51 0 0 1-.508-.508V9.342c0-.612.275-1.16.708-1.527V4.749zm12 0v2.603c-.291-.028-.588-.009-.88-.009V5.739c0-.368-.298-.667-.667-.667H9.106c-.368 0-.667.298-.667.667v1.604h-.878V5.739c0-.368-.298-.667-.667-.667H4.096c-.368 0-.667.298-.667.667v1.604c-.292 0-.589-.019-.88.009V4.749c0-.433.351-.783.783-.783h9.333c.433 0 .783.351.783.783zM6.461 7.342H4.53V6.172h1.931v1.171zm5.009 0H9.539V6.172h1.931v1.171zm2.688 2a.9.9 0 0 0-.9-.9H2.742a.9.9 0 0 0-.9.9v2.692h.722v-1.157h10.877v1.157h.716V9.342z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M4.927 4.53a2.6 2.6 0 0 0-2.6 2.6v3.541c-.737.547-1.215 1.423-1.215 2.411v5.625a.76.76 0 0 0 .762.762H3.98a.76.76 0 0 0 .762-.762v-1.052h14.516v1.052a.76.76 0 0 0 .762.762h2.105a.76.76 0 0 0 .762-.762v-5.625c0-.988-.478-1.864-1.215-2.411V7.13a2.6 2.6 0 0 0-2.6-2.6H4.927zm15.545 5.609V7.13a1.4 1.4 0 0 0-1.4-1.4H4.927a1.4 1.4 0 0 0-1.4 1.4v3.009a3.02 3.02 0 0 1 .585-.057h.741V7.987a1 1 0 0 1 1-1h4.726a1 1 0 0 1 1 1v2.095h.841V7.987a1 1 0 0 1 1-1h4.726a1 1 0 0 1 1 1v2.095h.741a3.02 3.02 0 0 1 .585.057zM6.054 8.187v1.895h4.326V8.187H6.054zm7.567 0v1.895h4.326V8.187H13.62zm8.065 6.589L2.313 14.8v3.469h1.229v-1.815h16.916v1.815h1.229l-.002-3.494zm.001-1.694v.494H2.313v-.494a1.8 1.8 0 0 1 1.8-1.8h15.774a1.8 1.8 0 0 1 1.8 1.8z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M6.57 6.141a3.27 3.27 0 0 0-3.267 3.267v4.582c-1.095.713-1.819 1.949-1.819 3.354v7.5c0 .561.455 1.016 1.016 1.016h2.806c.561 0 1.016-.455 1.016-1.016V23.44h19.355v1.403c0 .561.455 1.016 1.016 1.016H29.5c.561 0 1.016-.455 1.016-1.016v-7.5c0-1.405-.724-2.64-1.819-3.354V9.407c0-1.804-1.462-3.267-3.267-3.267H6.57zm20.927 7.324V9.407c0-1.141-.925-2.067-2.067-2.067H6.57c-1.141 0-2.067.925-2.067 2.067v4.057a4.01 4.01 0 0 1 .981-.121h.527V10.55c0-.736.597-1.333 1.333-1.333h6.735c.736 0 1.333.597 1.333 1.333v2.793h1.175V10.55c0-.736.597-1.333 1.333-1.333h6.735c.736 0 1.333.597 1.333 1.333v2.793h.527c.338 0 .667.042.981.121zM7.211 10.55v2.793h7.001V10.55c0-.074-.06-.133-.133-.133H7.344c-.074 0-.133.06-.133.133zm17.578 0v2.793h-7.001V10.55c0-.074.06-.133.133-.133h6.735c.074 0 .133.06.133.133zm4.526 9.052H2.685l-.001 5.058h2.439V22.24h21.755v2.419h2.439l-.001-5.058zm.001-2.258v1.058H2.684v-1.058a2.8 2.8 0 0 1 2.8-2.8h21.032a2.8 2.8 0 0 1 2.8 2.8z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M5.23 13.973c0-2.554 2.071-4.625 4.625-4.625h28.291c2.554 0 4.625 2.071 4.625 4.625v6.704c1.796 1.037 3.004 2.977 3.004 5.2v11.25c0 .842-.682 1.524-1.524 1.524h-4.209c-.842 0-1.524-.683-1.524-1.524v-2.105H9.484v2.105c0 .842-.683 1.524-1.524 1.524H3.75c-.842 0-1.524-.683-1.524-1.524v-11.25c0-2.223 1.208-4.163 3.004-5.2v-6.704zm36.291 0v6.162a6 6 0 0 0-1.746-.258h-.351v-4.19a2 2 0 0 0-2-2H26.907a2 2 0 0 0-2 2v4.19h-1.814v-4.19a2 2 0 0 0-2-2H10.577a2 2 0 0 0-2 2v4.19h-.351a6 6 0 0 0-1.746.258v-6.162c0-1.864 1.511-3.375 3.375-3.375h28.291c1.864 0 3.375 1.511 3.375 3.375zM9.827 19.877v-4.19a.75.75 0 0 1 .75-.75h10.516a.75.75 0 0 1 .75.75v4.19H9.827zm16.331 0v-4.19a.75.75 0 0 1 .75-.75h10.516a.75.75 0 0 1 .75.75v4.19H26.157zm18.366 9.387H3.477l-.001 7.863c0 .151.123.274.274.274h4.209c.152 0 .274-.123.274-.274v-3.355h31.532v3.355c0 .151.123.274.274.274h4.209c.152 0 .274-.123.274-.274l-.001-7.863zm.001-3.387v2.137H3.476v-2.137a4.75 4.75 0 0 1 4.75-4.75h31.549a4.75 4.75 0 0 1 4.75 4.75z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M13.139 12.481c-3.387 0-6.133 2.746-6.133 6.133v8.92c-2.412 1.378-4.038 3.975-4.038 6.952v4.194l.003.237-.003.118v10.451c0 1.123.91 2.033 2.033 2.033h5.612c1.123 0 2.033-.91 2.033-2.033V48.28v-1.6h1.6 35.51 1.6v1.6 1.206c0 1.122.91 2.032 2.032 2.032H59c1.123 0 2.032-.91 2.032-2.032V39.035l-.004-.122.003-.233v-4.194c0-2.977-1.626-5.575-4.039-6.952v-8.919c0-3.387-2.746-6.133-6.133-6.133H13.139zM55.393 26.84v-8.226c0-2.504-2.03-4.533-4.533-4.533H13.139c-2.504 0-4.533 2.03-4.533 4.533v8.226a8 8 0 0 1 2.362-.354h.468v-5.587c0-1.473 1.194-2.667 2.667-2.667h14.022c1.473 0 2.667 1.194 2.667 2.667v5.587h2.419v-5.587c0-1.473 1.194-2.667 2.667-2.667h14.022c1.473 0 2.667 1.194 2.667 2.667v5.587h.468c.822 0 1.615.124 2.361.354zm-4.429-.354v-5.587c0-.589-.478-1.067-1.067-1.067H35.876c-.589 0-1.067.478-1.067 1.067v5.587h16.155zm-21.774 0v-5.587c0-.589-.478-1.067-1.067-1.067H14.102c-.589 0-1.067.478-1.067 1.067v5.587H29.19zM4.569 39.003h54.863v.005l.001.027v10.451c0 .239-.194.432-.432.432h-5.612c-.239 0-.432-.194-.432-.432V46.68v-1.6h-1.6-38.71-1.6v1.6 2.806c0 .239-.194.433-.433.433H5c-.239 0-.433-.194-.433-.433V39.035l.001-.027v-.006zm-.001-1.6h54.865v-2.917a6.4 6.4 0 0 0-6.4-6.4H10.967a6.4 6.4 0 0 0-6.4 6.4v2.917z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
