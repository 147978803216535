import { MessageSearchValues } from '@apps/serviceCenter/ServiceCenter.types';
import * as Yup from 'yup';

export const getFormikValidationSchema = () => {
  return Yup.object<MessageSearchValues>({
    fromEmail: Yup.string().email('Please enter a valid email'),
    replyToEmail: Yup.string().email('Please enter a valid email'),
    toEmail: Yup.string().email('Please enter a valid email'),
    eventId: Yup.string(),
    subject: Yup.string()
  });
};
