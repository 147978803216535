import React from 'react';
import { SpacingStack, SpacingStackProps } from '@withjoy/joykit';

const BlockGroupSpread = {
  sm: 'sm',
  md: 'md'
} as const;

type BlockGroupSpread = typeof BlockGroupSpread[keyof typeof BlockGroupSpread];

interface InternalInfoBlockGroupProps {
  /** @default 'md' */
  spread?: BlockGroupSpread;
}

export interface InfoBlockGroupProps extends Readonly<InternalInfoBlockGroupProps>, Omit<SpacingStackProps, 'stack' | 'inline'> {}

const spreadToSpace: Record<BlockGroupSpread, number> = {
  sm: 5,
  md: 6
};

const InfoBlockGroup: React.FC<InfoBlockGroupProps> = ({ children, spread = 'md', ...restProps }) => {
  return (
    <SpacingStack stack={'vertical'} spacing={spreadToSpace[spread]} {...restProps}>
      {children}
    </SpacingStack>
  );
};

InfoBlockGroup.displayName = 'InfoBlockGroup';

export { InfoBlockGroup };
