/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Lock Heavy',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M6.6 6.641V9.75H6a1.8 1.8 0 0 0-1.8 1.8v9a1.8 1.8 0 0 0 1.8 1.8h12a1.8 1.8 0 0 0 1.8-1.8v-9a1.8 1.8 0 0 0-1.8-1.8h-.6V6.641c0-2.836-2.501-4.991-5.4-4.991S6.6 3.805 6.6 6.641zm1.8 0C8.4 4.957 9.93 3.45 12 3.45s3.6 1.506 3.6 3.191V9.75H8.4V6.641zM18 11.55v9H6v-9h12z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
