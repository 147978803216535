import { styled } from '@withjoy/joykit';
import { anatomy } from '@shared/joykit/packages/core/common/utils/anatomy';
import { createMultiPartStylesContext, MultiPartStyleConfig, PartsStyleObject } from '@shared/joykit/packages/core/common/utils/styleConfig';
import { StyleSystemProps, Box } from '../Box';
import { ARROW_WIDTH } from './Popover.constants';
import type { PopoverV2Props } from './Popover.types';

export const PopoverRoot = styled(Box)``;
export const PopoverContent = styled(Box)``;
export const PopoverBody = styled(Box)``;
export const PopoverArrow = styled(Box)``;

const baseStyleRoot: StyleSystemProps = {
  left: 0,
  opacity: 0,
  position: 'absolute',
  top: 0,
  zIndex: 'popover',
  _focusVisible: { outline: 'none' }
};

const baseStyleContent: StyleSystemProps = {
  borderRadius: '4px',
  backgroundColor: 'white',
  boxShadow: '0px 4px 12px -2px rgba(44, 41, 37, 0.25), 0px 3px 7px -3px rgba(0, 0, 0, 0.3)',
  display: 'inline-block'
  // transformOrigin: sharedProps.$transformOrigin,
};

const baseStyleBody: StyleSystemProps = {
  position: 'relative'
};

const baseStyleArrow: StyleSystemProps = {
  backgroundColor: 'white',
  position: 'absolute',
  height: `${ARROW_WIDTH}px`,
  transform: 'rotate(45deg)',
  width: `${ARROW_WIDTH}px`
};

export const Hidden = styled.div`
  display: none;
`;

const popoverAnatomy = anatomy('Popover').parts('root', 'content', 'body', 'arrow');

const popoverBaseStyle: PartsStyleObject<typeof popoverAnatomy> = {
  root: baseStyleRoot,
  content: baseStyleContent,
  body: baseStyleBody,
  arrow: baseStyleArrow
};

const popoverStyles: MultiPartStyleConfig<PopoverV2Props, typeof popoverAnatomy> = {
  parts: popoverAnatomy.keys,
  baseStyle: popoverBaseStyle
};

const [PopoverStylesProvider, usePopoverStyles] = createMultiPartStylesContext(popoverAnatomy);

export { PopoverStylesProvider, usePopoverStyles, popoverStyles };
