import { ApolloError } from '@apollo/client';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const TravelListTelemetry = createTelemetryObject({
  actions: {
    trackError: (mutation: string, error: ApolloError | Error | unknown, extra = {}) => ({
      category: 'wedding',
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),

    travelDiscountCodeClicked: (travelType: string, travelTitle: string) => ({
      action: 'TravelDiscountCodeClicked',
      actionType: 'click',
      category: 'wedding',
      label: travelTitle,
      extraInfo: {
        travelType
      }
    }),

    travelURLClicked: (travelType: string, travelTitle: string, url?: string) => ({
      action: 'TravelURLClicked',
      actionType: 'click',
      category: 'wedding',
      label: travelTitle,
      extraInfo: {
        travelType,
        url: url || ''
      }
    }),

    travelTelephoneClicked: (travelType: string, travelTitle: string) => ({
      action: 'TravelTelephoneClicked',
      actionType: 'click',
      category: 'wedding',
      label: travelTitle,
      extraInfo: {
        travelType
      }
    }),

    travelAddressClicked: (travelType: string, travelTitle: string) => ({
      action: 'TravelAddressClicked',
      actionType: 'click',
      category: 'wedding',
      label: travelTitle,
      extraInfo: {
        travelType
      }
    }),

    travelHertzWidgetClicked: (travelType: string, travelTitle: string, url: string, isAllowListedUrl: boolean) => ({
      action: 'LinkInteracted',
      category: 'wedding',
      label: travelTitle,
      extraInfo: {
        actionType: 'click',
        travelType,
        url: url,
        isAllowListedUrl
      }
    }),

    // DONE

    bookingAssistantSearchProviderClicked: (checkIn: Maybe<string>, checkOut: Maybe<string>, guests: Maybe<string>, url: Maybe<string>, provider: Maybe<string>) => ({
      action: 'BookingAssistantSearchProviderClicked',
      actionType: 'click',
      category: 'wedding',
      label: provider || '',
      extraInfo: {
        url,
        checkIn,
        checkOut,
        guests,
        provider
      }
    })
  }
});

const { TelemetryProvider, useTelemetry: useTravelListTelemetry } = createTelemetry(TravelListTelemetry);
export { TelemetryProvider, useTravelListTelemetry };
