import { useMemo } from 'react';
import { isObject } from 'lodash-es';
import { omitEmpty } from '@apps/createWedding/utils';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';

const supportedKeys = ['type', 'utm_medium', 'utm_campaign', 'utm_source', 'guestid', 'batchid', 'slug'] as const;

export type GuestSiteQueryParams = Partial<Record<typeof supportedKeys[number], string>>;

export const useGuestSiteQueryParams = (): GuestSiteQueryParams => {
  const queryParams = useQueryParams();

  const paramsFromQuery = useMemo(() => {
    if (!isObject(queryParams)) {
      return {};
    }

    return supportedKeys.reduce((acc, key) => {
      const value = queryParams[key];
      if (typeof value === 'string' && value !== 'null') {
        acc[key] = value;
      }
      return acc;
    }, {} as GuestSiteQueryParams);
  }, [queryParams]);

  return omitEmpty(paramsFromQuery);
};
