import { styled, Box } from '@withjoy/joykit';

// NOTE: pay attention to react-remove-scroll and the right: 0; property.
// This code is a little funky, but it avoids the scrollbar shift from react-remove-scroll
// in conjunction with { zeroRightClassName } from 'react-remove-scroll-bar'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuxFrameContainer = styled(Box).withConfig({ componentId: 'AuxFrame' } as any)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm4}) {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
  }
`;

export const AuxFrameInnerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 61%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm4}) {
    width: 100%;
  }
`;
