/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Camera',
  definitions: {
    '24': {
      viewBox: '0 0 22 18',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 9.4999C16 12.2613 13.7614 14.4999 11 14.4999C8.2386 14.4999 6.00002 12.2613 6.00002 9.4999C6.00002 6.73848 8.2386 4.4999 11 4.4999C13.7614 4.4999 16 6.73848 16 9.4999ZM14.8 9.4999C14.8 11.5986 13.0987 13.2999 11 13.2999C8.90134 13.2999 7.20002 11.5986 7.20002 9.4999C7.20002 7.40122 8.90134 5.6999 11 5.6999C13.0987 5.6999 14.8 7.40122 14.8 9.4999Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.6995 0.899902C7.11843 0.899902 6.58304 1.21493 6.30084 1.72287L5.20569 3.69416C5.13514 3.82115 5.00129 3.8999 4.85602 3.8999H2.00002C1.11637 3.8999 0.400024 4.61625 0.400024 5.4999V15.4999C0.400024 16.3836 1.11637 17.0999 2.00002 17.0999H20C20.8837 17.0999 21.6 16.3836 21.6 15.4999V5.4999C21.6 4.61625 20.8837 3.8999 20 3.8999H17.1439C16.9987 3.8999 16.8648 3.82115 16.7943 3.69416L15.6991 1.72287C15.4169 1.21493 14.8815 0.899902 14.3005 0.899902H7.6995ZM7.34983 2.30564C7.42038 2.17866 7.55423 2.0999 7.6995 2.0999H14.3005C14.4457 2.0999 14.5796 2.17866 14.6501 2.30564L15.7453 4.27693C16.0275 4.78488 16.5629 5.0999 17.1439 5.0999H20C20.2209 5.0999 20.4 5.27899 20.4 5.4999V15.4999C20.4 15.7208 20.2209 15.8999 20 15.8999H2.00002C1.77911 15.8999 1.60002 15.7208 1.60002 15.4999V5.4999C1.60002 5.27899 1.77911 5.0999 2.00002 5.0999H4.85602C5.43709 5.0999 5.97248 4.78488 6.25467 4.27693L7.34983 2.30564Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
