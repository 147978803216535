import { RefObject, useCallback, useEffect } from 'react';
import { useScrollTo } from '../providers/ScrollToProvider/ScrollTo.context';
import { useScrollToY } from './useScrollToY';

export const useScrollToRef = (ref: RefObject<HTMLElement>, id?: string) => {
  const { register } = useScrollTo();
  const scrollToRef = useScrollToY(ref);

  const scrollToEle = useCallback(scrollToRef, [scrollToRef]);

  useEffect(() => {
    if (!id) {
      return;
    }
    return register(id, scrollToEle);
  }, [id, register, scrollToEle]);

  return {};
};
