import { useEffect } from 'react';
import globalWindow from '@shared/core/globals';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { isInIframe } from '@shared/utils/isInIframe';
import { createPreviewMessageEventHandler, IframeParentFocus, PreviewIframeMessage, PreviewScaleChange } from '@shared/utils/previewMessageBus';

type ParentMessageListenerProps = {
  onScaleChange: (scale: number) => void;
  onParentFocus: () => void;
};

export const ParentMessageListener = (props: ParentMessageListenerProps) => {
  const { onScaleChange, onParentFocus } = props;

  const isPreviewing = isInIframe();

  const handleScaleChange = useEventCallback((message: PreviewScaleChange) => {
    onScaleChange(message.value.scale);
  });

  const handleIframeParentFocus = useEventCallback((message: IframeParentFocus) => {
    onParentFocus();
  });

  useEffect(() => {
    if (!isPreviewing) {
      return;
    }

    const processMessage = (message: PreviewIframeMessage) => {
      switch (message.action) {
        case 'previewScaleChange': {
          handleScaleChange(message as PreviewScaleChange);
          break;
        }
        case 'iframeParentFocus': {
          handleIframeParentFocus(message as IframeParentFocus);
          break;
        }
      }
    };

    const receiveMessage = createPreviewMessageEventHandler(processMessage, 'joy');
    globalWindow.addEventListener?.('message', receiveMessage, false);

    return () => {
      globalWindow.removeEventListener?.('message', receiveMessage, false);
    };
  }, [handleIframeParentFocus, handleScaleChange, isPreviewing]);

  // This component is used for side effects, not rendering.
  return null;
};
