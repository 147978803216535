/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Livestream',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.278 4.5a.778.778 0 0 0-.778.778v5.444c0 .43.348.778.778.778h7.444c.43 0 .778-.348.778-.778V5.278a.778.778 0 0 0-.778-.778H2.278zm9.415 1.637a.389.389 0 0 0-.193.336v3.054c0 .138.073.266.193.336l2.222 1.296a.389.389 0 0 0 .585-.336V5.177c0-.3-.326-.487-.585-.336l-2.222 1.296z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 7.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1H5zm11.255 2.107a.5.5 0 0 0-.255.435v3.916a.5.5 0 0 0 .255.435l3 1.688a.5.5 0 0 0 .745-.436v-7.29a.5.5 0 0 0-.745-.436l-3 1.688z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.248 12.355a.5.5 0 0 0-.248.432v6.426a.5.5 0 0 0 .248.432l5 2.916A.5.5 0 0 0 27 22.13V9.87a.5.5 0 0 0-.752-.431l-5 2.916zM6 10a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1H6z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
