import React, { useRef, useCallback } from 'react';
import { CSVLink } from 'react-csv';
import { ButtonV2 } from '@withjoy/joykit';
import { useServiceCenterState } from '@apps/serviceCenter/ServiceCenterState';
import { TableActionsContainer, styles } from './TableActions.styles';

const csvHeaders = [
  {
    label: 'Date',
    key: 'createdDate'
  },
  {
    label: 'To Email',
    key: 'toEmail'
  },
  {
    label: 'replyTo Email',
    key: 'replyToEmail'
  },
  {
    label: 'From Email',
    key: 'fromEmail'
  },
  {
    label: 'Subject',
    key: 'subject'
  },
  {
    label: 'Last Status',
    key: 'status'
  },
  {
    label: 'Email Type',
    key: 'emailType'
  }
];

export const TableActions = () => {
  const { filteredSearchResults, searchResultsLoading } = useServiceCenterState();
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const handleDownloadCSV = useCallback(() => {
    if (downloadRef?.current?.link) {
      downloadRef.current.link.click();
    }
  }, []);

  if (filteredSearchResults.length === 0) {
    return null;
  }

  return (
    <TableActionsContainer __css={styles.container}>
      <CSVLink data={filteredSearchResults.concat()} filename="Messages.csv" headers={csvHeaders} ref={downloadRef} />
      <ButtonV2 disabled={searchResultsLoading} onClick={handleDownloadCSV}>
        Export CSV
      </ButtonV2>
    </TableActionsContainer>
  );
};
