import { useGetGuestSiteScheduleQuery } from '@graphql/generated';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { GuestScheduleSessionData } from '@apps/guest/packages/layout-engine/widgets/ScheduleList/ScheduleList.types';
import { useUnlockDialogContext } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialogProvider';
import { useCtaButtons } from '@apps/guest/packages/layout-engine/common/utils/useCtaButtons';

export const useScheduleListController = (eventHandle: string) => {
  const { isAdmin } = useEventUserRole();
  const { handleUnlockDialogOpen } = useUnlockDialogContext();
  const { getUnlockButtonText } = useCtaButtons();
  const { data, loading } = useGetGuestSiteScheduleQuery({
    variables: { eventHandle, isAdmin },
    batchMode: 'fast',
    ssr: false
  });

  const event: GuestScheduleSessionData = data?.eventByName ?? {};
  const { activeSession, info } = event;
  const { eventCTA, hasUnlocked, person } = activeSession || {};

  const tbaSchedule = info?.schedule.toBeAnnouncedItems || [];
  const formattedSchedule = info?.schedule.formattedSchedules || [];
  const shouldDisplayUnlockButton = eventCTA?.scheduleHasInviteOnlyItems && !person?.fullName?.length;
  const unlockButtonText = getUnlockButtonText(hasUnlocked);

  return {
    formattedSchedule,
    shouldDisplayUnlockButton,
    loading,
    tbaSchedule,
    handleUnlockDialogClick: () => handleUnlockDialogOpen(true),
    unlockButtonText
  };
};
