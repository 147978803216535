import { styled, css } from '@shared/joykit/packages/core/common/styledComponents';
import { styleFunction } from '../../common/styledSystem/styledFunctions';
import { FontWeight, fontWeightKeywordToNumber } from '../../common/textStyles';
import { TextV1Props } from '.';
import { Box } from '../Box';

const getResponsveFontWeight = styleFunction({
  prop: 'fontWeight',
  transformValue: (val: string | number) => {
    if (typeof val === 'number') {
      return val;
    }
    if (val in fontWeightKeywordToNumber) {
      return fontWeightKeywordToNumber[val as FontWeight];
    }
    return val;
  }
});

export const StyledText = styled(Box)<TextV1Props>`
  ${getResponsveFontWeight}
  ${props => {
    if (props.truncate) {
      return css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: ${typeof props.truncate === 'boolean' ? '100%' : props.truncate};
      `;
    }
    return null;
  }}
  ${props =>
    props.fontSize !== undefined
      ? {
          lineHeight: 'initial'
        }
      : undefined}
`;
