import React, { useState } from 'react';
import { Flex, TextV2, ButtonV2, useDisclosure, DialogV2, Box, LinkV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { Layout } from '@apps/card/components/Layout';
import { OrderDetails } from '@apps/card';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import {
  useStationeryPrintOrderByOrderNumberQuery,
  useCancelStationeryPrintOrderByIdMutation,
  useRefundStationeryPrintOrderByIdMutation,
  StationeryPrintOrderStateEnum,
  useGetEventByIdServiceCenterQuery,
  EventUserStatus,
  usePauseStationeryPrintOrderByIdMutation,
  useResumeStationeryPrintOrderByIdMutation,
  StationeryPrintOrderStatusEnum,
  useInstantlyProcessStationeryPrintOrderByIdMutation
} from '@graphql/generated';
import { TempSpinner } from '@shared/components/TempSpinner';
import { withWindow } from '@shared/utils/withWindow';
import { CardsRoutesProvider } from '@apps/card/Card.routes';
import { ArrowLeft } from '@withjoy/joykit/icons';
import { Link, useRouterHelper } from '@shared/core';
import { routePaths } from '@apps/serviceCenter/route.paths';

const isDevOrTestEnv = ['development', 'test'].includes(process.env.NODE_ENV ?? '');

const STRIPE_URL = `https://dashboard.stripe.com/${isDevOrTestEnv ? 'test/' : ''}search`;

interface PrintOrderDetailsProps {
  printOrderNumber: string;
}

export const PrintOrderDetails = (props: PrintOrderDetailsProps) => {
  const { printOrderNumber } = props;
  const { buildPath } = useRouterHelper();
  const { eventId } = useQueryParams();
  const [mutationError, setMutationError] = useState<boolean>(false);
  const [operation, setOperation] = useState<'cancellation' | 'refund' | 'pause' | 'resume' | 'instantlyProcess'>('cancellation');
  const confirmationDialog = useDisclosure();
  const isMobile = useIsMobileScreen();
  const { data: queryEventData, loading: queryEventLoading } = useGetEventByIdServiceCenterQuery({
    variables: {
      id: eventId as string
    },
    batchMode: 'fast',
    skip: !eventId
  });
  const { data: queryData, loading: queryLoading, refetch } = useStationeryPrintOrderByOrderNumberQuery({
    variables: {
      orderNumber: printOrderNumber
    },
    batchMode: 'fast',
    skip: !printOrderNumber
  });
  const [cancelStationeryPrintOrderByIdMutation, { loading: cancelMutationloading }] = useCancelStationeryPrintOrderByIdMutation();
  const [refundStationeryPrintOrderByIdMutation, { loading: refundMutationloading }] = useRefundStationeryPrintOrderByIdMutation();
  const [pauseStationeryPrintOrderByIdMutation, { loading: pauseMutationLoading }] = usePauseStationeryPrintOrderByIdMutation();
  const [resumeStationeryPrintOrderByIdMutation, { loading: resumeMutationLoading }] = useResumeStationeryPrintOrderByIdMutation();
  const [instantlyProcessStationeryPrintOrderByIdMutation, { loading: instantlyProcessMutationLoading }] = useInstantlyProcessStationeryPrintOrderByIdMutation();

  if (queryLoading || queryEventLoading) return <TempSpinner />;

  const eventHandle = queryEventData?.eventById?.website;
  const eventUsersEmail = queryEventData?.eventById?.eventUsers?.filter(({ status }) => [EventUserStatus.admin, EventUserStatus.owner].includes(status));
  const eventUsersEmailAsString = eventUsersEmail?.map(({ user }) =>
    user?.email ? (
      <LinkV2 key={user.email} href={`mailto:${user.email}`} target="_blank" typographyVariant="label4" paddingRight="5px">
        {user.email}
      </LinkV2>
    ) : null
  );

  const stationeryPDFUrl = queryData?.stationeryPrintOrderByOrderNumber?.stationeryPDFUrl;
  const envelopePDFUrl = queryData?.stationeryPrintOrderByOrderNumber?.envelopePDFUrl;

  const order = queryData?.stationeryPrintOrderByOrderNumber;
  let orderId = '';
  let isOrderCancellable = false;
  let isOrderRefundable = false;
  let isOrderPauseable = false;
  let isOrderResumable = false;
  let isOrderInstantlyProcessable = false;
  let stripeUrl = '';

  if (order) {
    orderId = order.id;
    isOrderCancellable = order.state === StationeryPrintOrderStateEnum.valid;
    isOrderRefundable = !order.refunds.length && order.printPayment?.paid === true;
    isOrderPauseable = order.currentStatus === StationeryPrintOrderStatusEnum.paid;
    isOrderResumable = order.currentStatus === StationeryPrintOrderStatusEnum.paused;
    isOrderInstantlyProcessable = order.currentStatus === StationeryPrintOrderStatusEnum.paid;
    const orderCreatedAt = (order.createdAt as string)?.split('T')?.[0]?.split('-');
    const orderCreatedAtYear = orderCreatedAt?.[0];
    const orderCreatedAtMonth = orderCreatedAt?.[1];
    const orderCreatedAtDay = orderCreatedAt?.[2];
    const stripeDateParam = orderCreatedAtDay && orderCreatedAtMonth && orderCreatedAtYear ? ` date:${orderCreatedAtMonth}/${orderCreatedAtDay}/${orderCreatedAtYear}` : '';
    const encodedStripeDateParam = stripeDateParam ? encodeURIComponent(stripeDateParam) : '';
    stripeUrl = `${STRIPE_URL}${eventId ? `?query=${eventId}${encodedStripeDateParam}` : ''}`;
  }

  const handleOnClickCancel = () => {
    setOperation('cancellation');
    return confirmationDialog.onOpen();
  };

  const handleOnClickRefund = () => {
    setOperation('refund');
    confirmationDialog.onOpen();
  };

  const handleOnClickPause = () => {
    setOperation('pause');
    confirmationDialog.onOpen();
  };

  const handleOnClickResume = () => {
    setOperation('resume');
    confirmationDialog.onOpen();
  };

  const handleOnClickProcessNow = () => {
    setOperation('instantlyProcess');
    confirmationDialog.onOpen();
  };

  const handleOnCloseDialog = () => {
    setMutationError(false);
    confirmationDialog.onClose();
  };

  const handleOnClickDialogFirstButton = async () => {
    try {
      if (orderId) {
        if (operation === 'refund' && isOrderRefundable) {
          await refundStationeryPrintOrderByIdMutation({ variables: { id: orderId } });
          setMutationError(false);
          refetch();
          return confirmationDialog.onClose();
        }
        if (operation === 'cancellation' && isOrderCancellable) {
          await cancelStationeryPrintOrderByIdMutation({ variables: { id: orderId } });
          setMutationError(false);
          refetch();
          confirmationDialog.onClose();
        }
        if (operation === 'pause' && isOrderPauseable) {
          await pauseStationeryPrintOrderByIdMutation({ variables: { id: orderId } });
          setMutationError(false);
          refetch();
          confirmationDialog.onClose();
        }
        if (operation === 'resume' && isOrderResumable) {
          await resumeStationeryPrintOrderByIdMutation({ variables: { id: orderId } });
          setMutationError(false);
          refetch();
          confirmationDialog.onClose();
        }
        if (operation === 'instantlyProcess' && isOrderInstantlyProcessable) {
          await instantlyProcessStationeryPrintOrderByIdMutation({ variables: { id: orderId } });
          setMutationError(false);
          refetch();
          confirmationDialog.onClose();
        }
      }
    } catch {
      setMutationError(true);
    }
  };

  const handleOnClickDialogSecondButton = () => {
    if (operation === 'refund') {
      withWindow(window => {
        window.open(stripeUrl, '_blank');
      });
      setMutationError(false);
      return confirmationDialog.onClose();
    }
    if (operation === 'cancellation') {
      setMutationError(false);
      confirmationDialog.onClose();
    }
    if (operation === 'pause') {
      setMutationError(false);
      confirmationDialog.onClose();
    }
    if (operation === 'resume') {
      setMutationError(false);
      confirmationDialog.onClose();
    }
    if (operation === 'instantlyProcess') {
      setMutationError(false);
      confirmationDialog.onClose();
    }
  };

  const copies = {
    refund: {
      title: 'Are you sure you want to set this order as refunded?',
      subTitle: 'This is the confirmation that the refund has been processed manually.',
      firstButton: 'Set to Refunded',
      secondButton: 'Go to Order in Stripe',
      error: 'refund'
    },
    cancellation: {
      title: 'Are you sure you want to cancel this order?',
      subTitle: 'Once you cancel the order, it will be removed from the queue for the print orders.',
      firstButton: 'Cancel Order',
      secondButton: 'Keep Order',
      error: 'cancellation'
    },
    pause: {
      title: 'Are you sure you want to pause this order?',
      subTitle: 'Once you pause the order, it will be removed from the queue for the print orders.',
      firstButton: 'Pause Order',
      secondButton: 'Cancel',
      error: 'pause'
    },
    resume: {
      title: 'Are you sure you want to resume this order?',
      subTitle: 'Once you resume the order, it will be added to the queue for the print orders.',
      firstButton: 'Resume Order',
      secondButton: 'Cancel',
      error: 'resume'
    },
    instantlyProcess: {
      title: 'Are you sure you want to immediately process this order?',
      subTitle:
        'Once you process the order, it will be sent to the print vendor, and no longer will be able to be modified or canceled without interacting with the print partner customer support.',
      firstButton: 'Process Now',
      secondButton: 'Cancel',
      error: 'process now'
    }
  };

  return (
    <Box as="main" paddingX={'2.5rem'}>
      <Box display="grid" rowGap={'1.5rem'} maxWidth={'1440px'} marginX={'auto'} marginTop={10}>
        <ButtonV2 as={Link} to={buildPath(routePaths.print.path)} variant="link" fullWidth={false} justifySelf={'self-start'}>
          <ArrowLeft size="md" marginRight={'0.5rem'} /> Back to Print Orders
        </ButtonV2>
      </Box>
      <CardsRoutesProvider eventHandle={eventHandle || ''}>
        <Layout.Grid>
          <Layout.Main>
            <Flex justifyContent="center" alignItems="center" paddingTop={10} paddingBottom={pxToRem(97)} flexDirection="column">
              {order && (
                <Flex alignItems="center" justifyContent="flex-end" width="100%" backgroundColor="mono2" marginBottom={32} columnGap={24} paddingY={14} paddingX={24}>
                  <TextV2 typographyVariant={['hed2', null, 'hed4']}>{`${isMobile ? '' : 'Manage Order from the '}CX Service Center`}</TextV2>
                  <ButtonV2
                    intent="neutral"
                    variant="outline"
                    shape="rounded"
                    onClick={handleOnClickCancel}
                    disabled={!isOrderCancellable}
                    width={200}
                    fontSize={['12px', null, '16px']}
                  >
                    Cancel Order
                  </ButtonV2>
                  <ButtonV2
                    intent="neutral"
                    variant="outline"
                    shape="rounded"
                    onClick={handleOnClickRefund}
                    disabled={!isOrderRefundable}
                    width={200}
                    fontSize={['12px', null, '16px']}
                  >
                    Set as Refunded
                  </ButtonV2>
                  {isOrderInstantlyProcessable && (
                    <ButtonV2 intent="neutral" variant="outline" shape="rounded" onClick={handleOnClickProcessNow} width={200} fontSize={['12px', null, '16px']}>
                      Process Now
                    </ButtonV2>
                  )}
                  {isOrderPauseable && (
                    <ButtonV2 intent="neutral" variant="outline" shape="rounded" onClick={handleOnClickPause} width={200} fontSize={['12px', null, '16px']}>
                      Pause Order
                    </ButtonV2>
                  )}
                  {isOrderResumable && (
                    <ButtonV2 intent="neutral" variant="outline" shape="rounded" onClick={handleOnClickResume} width={200} fontSize={['12px', null, '16px']}>
                      Resume Order
                    </ButtonV2>
                  )}
                </Flex>
              )}
              {eventHandle && (
                <Flex alignItems="center" justifyContent="flex-end" marginBottom={16} width="100%" paddingRight="24px">
                  <LinkV2
                    href={withWindow(window => `${window.location.origin}/${eventHandle}/edit/dashboard`, `https://withjoy.com/${eventHandle}/edit/dashboard`)}
                    target="_blank"
                    fontSize="16px"
                  >
                    Event Admin Dashboard
                  </LinkV2>
                </Flex>
              )}
              {stationeryPDFUrl && (
                <Flex alignItems="center" justifyContent="flex-end" width="100%" paddingRight="24px">
                  <LinkV2 href={stationeryPDFUrl} target="_blank" fontSize="16px">
                    Printable Card PDF
                  </LinkV2>
                </Flex>
              )}
              {envelopePDFUrl && (
                <Flex alignItems="center" justifyContent="flex-end" width="100%" paddingRight="24px">
                  <LinkV2 href={envelopePDFUrl} target="_blank" fontSize="16px">
                    Printable Envelope PDF
                  </LinkV2>
                </Flex>
              )}
              {eventUsersEmailAsString && (
                <Flex alignItems="flex-start" justifyContent="flex-end" marginY={32} width="100%" paddingX="24px">
                  <TextV2 typographyVariant="label4" marginRight="5px">
                    User&apos;s Email Address:
                  </TextV2>
                  <Flex flexDirection="column">{eventUsersEmailAsString}</Flex>
                </Flex>
              )}
              <OrderDetails disableTelemetry data={queryData} />
              <DialogV2 id="confirmationDialog" isOpen={confirmationDialog.isOpen} onClose={handleOnCloseDialog} size="2xl">
                <DialogV2.Body padding="0 !important">
                  <Flex alignItems="center" justifyContent="center" height={['auto', null, '510px']} flexDirection={['column', null, 'row']}>
                    <DialogV2.CloseButton onClick={handleOnCloseDialog} position="absolute" top="24px" />
                    {order?.printCardFrontPreviewUrl && (
                      <Flex paddingY={76} paddingX={28} justifyContent="center" width={['auto', null, 500]} backgroundColor="mono1" height="100%" borderRadius="12px 0 0 12px">
                        <Box as="img" src={order.printCardFrontPreviewUrl} width="100%" boxShadow="0px 4px 16px 0px #00000012" />
                      </Flex>
                    )}
                    <Flex alignItems="center" justifyContent="center" flexDirection="column" paddingY={[32, null, 56]} paddingX={[32, null, 40]}>
                      <Flex alignItems="center" justifyContent="flex-start" flexDirection="column" rowGap={16} marginBottom={32}>
                        <TextV2 typographyVariant="hed6">{copies[operation].title}</TextV2>
                        <TextV2 typographyVariant="body2">{copies[operation].subTitle}</TextV2>
                      </Flex>
                      <ButtonV2
                        intent="neutral"
                        variant="solid"
                        shape="rounded"
                        onClick={handleOnClickDialogFirstButton}
                        fullWidth
                        marginBottom={16}
                        loading={cancelMutationloading || refundMutationloading || pauseMutationLoading || resumeMutationLoading || instantlyProcessMutationLoading}
                        disabled={!orderId}
                      >
                        {copies[operation].firstButton}
                      </ButtonV2>
                      <ButtonV2 intent="neutral" variant="outline" shape="rounded" onClick={handleOnClickDialogSecondButton} fullWidth marginBottom={16}>
                        {copies[operation].secondButton}
                      </ButtonV2>
                      {mutationError && <TextV2 color="negative5">{`There was an error performing the ${copies[operation].error}.`}</TextV2>}
                    </Flex>
                  </Flex>
                </DialogV2.Body>
              </DialogV2>
            </Flex>
          </Layout.Main>
        </Layout.Grid>
      </CardsRoutesProvider>
    </Box>
  );
};
