import { useTranslation } from '@shared/core/i18n';

export const useBannerReminderTranslations = () => {
  const { t } = useTranslation('publishReminder');

  const linkText = t('linkText');
  const message = t('message');

  return {
    linkText,
    message
  };
};
