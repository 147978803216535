import React from 'react';
import InputMask from 'react-input-mask';
import { InputV2 } from './Input';
import { InputV2Props, MaskedInputProps } from './Input.types';
import { StyledInput } from './Input.styles';

const MaskInputOverride = React.forwardRef<HTMLInputElement, Merge<React.InputHTMLAttributes<HTMLInputElement>, MaskedInputProps>>((props, ref) => {
  const { 'aria-relevant': ariaRelevant, mask, maskPlaceholder, onChange, onFocus, onBlur, value, disabled, readOnly, size, style, ...restProps } = props;
  return (
    <InputMask
      mask={mask || ''}
      maskChar={maskPlaceholder}
      disabled={disabled}
      readOnly={readOnly}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      {...restProps}
    >
      {(inputProps: Object) => {
        return <StyledInput as="input" ref={ref} disabled={disabled} readOnly={readOnly} onBlur={onBlur} onChange={onChange} onFocus={onFocus} value={value} {...inputProps} />;
      }}
    </InputMask>
  );
});

const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>((props, ref) => {
  const { overrides: providedOverrides, ...restProps } = props;
  const overrides: InputV2Props['overrides'] = {
    ...providedOverrides,
    Input: {
      component: MaskInputOverride,
      props: {
        ...providedOverrides?.Input?.props,
        mask: props.mask,
        maskPlaceholder: props.maskPlaceholder
      }
    }
  };

  return <InputV2 ref={ref} {...restProps} overrides={overrides} />;
});

MaskedInput.defaultProps = {
  maskPlaceholder: ' '
};

export { MaskedInput };
