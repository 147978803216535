import { useCallback } from 'react';

import { useHistory } from '@shared/core';
import { EventPageFragment, EventPageType } from '@graphql/generated';
import { getSlugFromPathname } from '@apps/guest/packages/layout-engine/common/utils/useGuestSitePageChanged';
import { ACCOMMODATIONS_SLUG, CUSTOM_PAGE_PATH } from '@apps/guest/routes/GuestSite/GuestSite.routes';

export const usePageInHistory = () => {
  const history = useHistory();

  const replacePageInBrowserHistory = useCallback(
    (page: EventPageFragment | null) => {
      if (!page) {
        return;
      }

      const pageSlug = page?.pageSlug;
      const isCustomPage = page.type === EventPageType.custom && pageSlug !== ACCOMMODATIONS_SLUG;
      const { pagePath } = getSlugFromPathname(history.location.pathname);

      if (pagePath !== pageSlug) {
        const pathnameParts = history.location.pathname.split('/');
        if (isCustomPage) {
          pathnameParts[2] = CUSTOM_PAGE_PATH;
          pathnameParts[3] = pageSlug;
        } else {
          if (pathnameParts.length > 3 && pathnameParts[2] === CUSTOM_PAGE_PATH) {
            pathnameParts.splice(3, 1);
          }
          pathnameParts[2] = pageSlug;
        }
        history.replace({ ...history.location, pathname: pathnameParts.join('/') });
      }
    },
    [history]
  );

  return replacePageInBrowserHistory;
};
