import { EventPageType, PhotoFragment } from '@graphql/generated';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CoverPhotoContainer } from '../Body/Body.styles';
import { SkeletonScreen, StyledCoverPhoto } from './CoverPhoto.styles';
import { InlineEditor } from '@shared/components';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { isInIframe } from '@shared/utils/isInIframe';
import { useFeatureValue } from '@shared/core/featureFlags';
import { Box, Flex } from '@withjoy/joykit';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useQueryParamHelper } from '@shared/core/queryString';

const _getImageRendition = (url: string) => {
  const imageUrl = new URL(url);
  imageUrl.searchParams.append('rendition', 'medium');
  return imageUrl.toString();
};

const { isTest } = getNodeEnv();

const LazyScreen: React.FC<{ src?: string; height: string | number; width?: string | number; children: (loaded: boolean) => JSX.Element }> = ({ height, width, children, src }) => {
  const [loaded, setLoaded] = useState(src === undefined || isTest || __SERVER__);

  const isPreviewing = isInIframe();

  const handeOnLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded && src) {
      const img = new Image();
      img.onload = handeOnLoad;
      img.src = _getImageRendition(src);
    }
  }, [loaded, src]);

  useEffect(() => {
    if (isPreviewing && loaded) {
      sendMessageToParentWindow({ action: 'requestIframeScaleValue', source: 'joyPreview' });
    }
  }, [isPreviewing, loaded]);

  // const memoizedImage = src ? <img alt="" src={src} style={{ display: 'none' }} onLoad={handeOnLoad} /> : null;

  return (
    <SkeletonScreen height={height} width={width} index={0} loaded={loaded}>
      {loaded ? children(loaded) : null}
    </SkeletonScreen>
  );
};

interface Props
  extends Readonly<{
    photo?: Maybe<PhotoFragment>;
    pageId?: string;
    pageType?: EventPageType;
    pageSlug?: string;
  }> {}

export const CoverPhoto: React.FC<Props> = props => {
  const { photo, pageId, pageType, pageSlug } = props;

  const queryParamHelper = useQueryParamHelper();
  const inlineEditingEnabled = useMemo(() => queryParamHelper.getValue('feature.enableInlineEditing') === 'true', [queryParamHelper]);

  const guestBrannanCoverPhotoV2Enabled = useFeatureValue('guestBrannanCoverPhotoV2Enabled').value === 'on';
  const adminDashboardInlineEditingEnabled = useFeatureValue('adminDashboardInlineEditingExperiment', { skip: !inlineEditingEnabled }).value === 'treatment';
  const adminDashboardAddBrannanPagePhotoEnabled = useFeatureValue('adminDashboardAddBrannanPagePhotoEnabled', { skip: !adminDashboardInlineEditingEnabled }).value === 'on';

  const handleEditPhotoClick = () => {
    // send the message to the parent window to open the photo editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editPhoto', inlineEditData: { photo: photo, pageId: pageId || '', page: pageType, pageSlug } }
    });
  };

  const imageRef = useRef<HTMLImageElement>(null);

  const [imageHeight, setImageHeight] = useState(imageRef.current?.clientHeight || 600);

  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);

  useEffect(() => {
    return () => {
      setImageHeight(600);
    };
  }, [photo?.url, isMobile]);

  return (
    <>
      {photo ? (
        <LazyScreen key={photo.id} src={photo.url} width={(600 * photo.width) / photo.height} height={600}>
          {() => (
            <>
              {!guestBrannanCoverPhotoV2Enabled ? (
                <InlineEditor
                  key={`inline-edit-photo-${pageId || ''}-${photo?.id || ''}`}
                  elementLabel="Photo"
                  wrapperType="actionInside"
                  actionData={{
                    editPhoto: handleEditPhotoClick
                  }}
                  componentName="pagePhoto"
                  pageName={pageType}
                  pageSlug={pageSlug || ''}
                >
                  <CoverPhotoContainer
                    data-width={photo.width}
                    data-height={photo.height}
                    data-testid={'body-coverphoto'}
                    position={'relative'}
                    photo={photo}
                    maxHeight={[null, null, null, 600]}
                    width={'100%'}
                  >
                    <StyledCoverPhoto backgroundSize={'contain'} url={_getImageRendition(photo.url)} />
                  </CoverPhotoContainer>
                </InlineEditor>
              ) : (
                <CoverPhotoContainer
                  data-width={photo.width}
                  data-height={photo.height}
                  data-testid={'body-coverphoto'}
                  position={'relative'}
                  photo={photo}
                  maxHeight={[null, null, null, 600]}
                  width={'100%'}
                >
                  <Flex position="absolute" alignItems="center" justifyContent="center" width="100%" height="100%">
                    <InlineEditor
                      key={`inline-edit-photo-${pageId || ''}-${photo?.id || ''}`}
                      elementLabel="Photo"
                      wrapperType="actionInside"
                      actionData={{
                        editPhoto: handleEditPhotoClick
                      }}
                      minHeight="unset"
                      wrapperCSS={{
                        maxHeight: imageHeight || '600px',
                        height: '100%'
                      }}
                      containerCSS={{
                        height: '100%',
                        maxHeight: '600px'
                      }}
                      componentName="pagePhoto"
                      pageName={pageType}
                      pageSlug={pageSlug || ''}
                    >
                      <img
                        key={`${_getImageRendition(photo.url)}_${isMobile ? 'mobile' : 'desktop'}`}
                        ref={imageRef}
                        src={_getImageRendition(photo.url)}
                        onLoad={() => setImageHeight(imageRef.current?.clientHeight || 600)}
                        alt=""
                        style={{ height: 'auto', width: 'auto', maxHeight: '100%', maxWidth: '100%', aspectRatio: `auto ${photo.width / photo.height}` }}
                      />
                    </InlineEditor>
                  </Flex>
                </CoverPhotoContainer>
              )}
            </>
          )}
        </LazyScreen>
      ) : (
        adminDashboardAddBrannanPagePhotoEnabled && (
          <InlineEditor
            key={`inline-edit-photo-${pageId || ''}-add-new-photo`}
            elementLabel="Photo"
            wrapperType="emptyChild"
            actionData={{
              addPhoto: handleEditPhotoClick
            }}
            componentName="pagePhoto"
            pageName={pageType}
            pageSlug={pageSlug || ''}
          >
            <Box height="100%" width="100%" />
          </InlineEditor>
        )
      )}
    </>
  );
};
