/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Train',
  definitions: {
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M9.961 2.458c-3.294 0-5.894 3.077-5.894 6.267v16.6h5.564l-3.688 3.161a.6.6 0 0 0 .781.911l1.387-1.189h15.778l1.387 1.189a.6.6 0 0 0 .781-.911l-3.688-3.161h5.564v-16.6c0-3.19-2.6-6.267-5.894-6.267H9.961zm12.528 24.55l-1.88-1.611a.61.61 0 0 1-.072-.073h-9.076a.61.61 0 0 1-.071.073l-1.88 1.611h12.978zM5.38 7.675c.477-2.196 2.392-4.017 4.58-4.017h12.079c2.188 0 4.103 1.821 4.58 4.017H5.38zm-.114 1.2v6.8H15.4v-6.8H5.267zm21.467 6.8H16.6v-6.8h10.133v6.8zm-21.467 1.2h21.467v7.25H5.267v-7.25zm5.233 2.333c-.681 0-1.233.552-1.233 1.233s.552 1.233 1.233 1.233 1.233-.552 1.233-1.233-.552-1.233-1.233-1.233zm-2.433 1.233c0-1.344 1.089-2.433 2.433-2.433s2.433 1.089 2.433 2.433-1.089 2.433-2.433 2.433-2.433-1.089-2.433-2.433zM21.5 19.209c-.681 0-1.233.552-1.233 1.233s.552 1.233 1.233 1.233 1.233-.552 1.233-1.233-.552-1.233-1.233-1.233zm-2.433 1.233c0-1.344 1.089-2.433 2.433-2.433s2.433 1.089 2.433 2.433-1.089 2.433-2.433 2.433-2.433-1.089-2.433-2.433z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M14.941 3.962c-4.766 0-8.566 4.468-8.566 9.125v24.625h8.815l-6.097 5.226c-.262.225-.292.619-.068.881s.619.292.881.068l2.158-1.85h23.871l2.158 1.85c.262.225.657.194.881-.068s.194-.657-.068-.881l-6.097-5.226h8.815V13.087c0-4.657-3.8-9.125-8.566-9.125H14.941zm19.536 36.825l-3.384-2.9a.62.62 0 0 1-.142-.175H17.049a.62.62 0 0 1-.142.175l-3.384 2.9h20.954zm-26.74-29c.614-3.55 3.661-6.575 7.204-6.575h18.118c3.543 0 6.59 3.025 7.204 6.575H7.737zm-.112 1.25v.05 10.7h15.75v-10.75H7.625zm32.75 10.75h-15.75v-10.75h15.75v.05 10.7zm-32.75 1.25h32.75v11.425H7.625V25.038zm8.125 3.5c-1.174 0-2.125.951-2.125 2.125s.951 2.125 2.125 2.125 2.125-.951 2.125-2.125-.951-2.125-2.125-2.125zm-3.375 2.125c0-1.864 1.511-3.375 3.375-3.375s3.375 1.511 3.375 3.375-1.511 3.375-3.375 3.375-3.375-1.511-3.375-3.375zm19.875-2.125c-1.174 0-2.125.951-2.125 2.125s.951 2.125 2.125 2.125 2.125-.951 2.125-2.125-.951-2.125-2.125-2.125zm-3.375 2.125c0-1.864 1.511-3.375 3.375-3.375s3.375 1.511 3.375 3.375-1.511 3.375-3.375 3.375-3.375-1.511-3.375-3.375z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M19.921 5.316c-6.333 0-11.388 5.939-11.388 12.133v32 .8h.8 11.01l-8.198 7.027a.8.8 0 1 0 1.041 1.215l2.886-2.474h31.853l2.886 2.474a.8.8 0 1 0 1.041-1.215l-8.198-7.027h11.01.8v-.8-32c0-6.194-5.055-12.133-11.388-12.133H19.921zM46.06 54.417l-4.58-3.926a.8.8 0 0 1-.191-.241H22.712c-.046.09-.11.172-.191.241l-4.58 3.926H46.06zM10.277 15.75c.805-4.764 4.889-8.834 9.644-8.834h24.157c4.756 0 8.839 4.07 9.644 8.834H10.277zm-.143 1.6l-.001.099V31.75H31.2v-14.4H10.134zm43.733 14.4H32.8v-14.4h21.066v.078.021V31.75zm-43.733 1.6h43.733V48.65H10.133V33.35zM21 38.017c-1.583 0-2.867 1.283-2.867 2.867S19.417 43.75 21 43.75s2.867-1.283 2.867-2.867-1.283-2.867-2.867-2.867zm-4.467 2.867A4.47 4.47 0 0 1 21 36.417a4.47 4.47 0 0 1 4.467 4.467A4.47 4.47 0 0 1 21 45.35a4.47 4.47 0 0 1-4.467-4.467zM43 38.017c-1.583 0-2.867 1.283-2.867 2.867S41.417 43.75 43 43.75s2.867-1.283 2.867-2.867-1.284-2.867-2.867-2.867zm-4.467 2.867A4.47 4.47 0 0 1 43 36.417a4.47 4.47 0 0 1 4.467 4.467A4.47 4.47 0 0 1 43 45.35a4.47 4.47 0 0 1-4.467-4.467z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
