type UrlKeys = keyof Pick<URL, 'protocol' | 'hostname' | 'port' | 'pathname' | 'hash' | 'search'>;

export interface PrettyUrlOptions extends Readonly<Partial<Record<UrlKeys, boolean>>> {
  www?: boolean;
}

/**
 * Return a prettier version of url to display inline in place of url
 * @param url
 * @param options defaults { hostname: true, pathname: true }
 * @returns url | string
 */
export const prettyUrl = (url?: string | URL, options?: PrettyUrlOptions) => {
  const opts = { hostname: true, pathname: true, www: true, ...options };
  if (!url) {
    return url;
  }
  try {
    const urlObj = new URL(url);
    const hostname = opts.www ? urlObj.hostname : stripWWW(urlObj.hostname);

    return [
      opts.protocol && urlObj.protocol && urlObj.protocol + '//',
      opts.hostname && hostname,
      opts.port && urlObj.port && `:${urlObj.port}`,
      opts.pathname && urlObj.pathname.length > 1 && urlObj.pathname, // all urls have a pathname filled with at least one slash '/', so we set the flag only if there really is a pathname
      opts.search && urlObj.search,
      opts.hash && urlObj.hash
    ]
      .filter(i => !!i) // filter out falsy
      .join('');
  } catch {
    return url;
  }
};

function stripWWW(hostname?: string): string {
  if (!hostname) {
    return '';
  }
  const [, domain] = hostname.split('www.');
  return domain || hostname;
}
