import React, { forwardRef } from 'react';
import { SpacingStackWrapper } from './SpacingStack.styles';
import { createDisplayName } from '@withjoy/joykit/utils';
import { ResponsiveSpacingProps, ResponsiveValue } from '@withjoy/joykit/styled-system';
import { BoxProps } from '@withjoy/joykit';

export interface SpacingStackProps extends BoxProps, ResponsiveSpacingProps {
  /**
   * Whether the children should be laid vertically or horizontally.
   * @default vertical
   */
  stack?: 'horizontal' | 'vertical';

  /**
   * Whether the container should behave like a inline element rather than a block element.
   * @default false
   */
  inline?: ResponsiveValue<boolean>;
}

const SpacingStack = forwardRef<HTMLDivElement, SpacingStackProps>(({ stack = 'vertical', inline = false, spacing = 2, children, ...restProps }, ref) => {
  return (
    <SpacingStackWrapper {...restProps} spacing={spacing} stack={stack} inline={inline}>
      {children}
    </SpacingStackWrapper>
  );
});

SpacingStack.displayName = createDisplayName('SpacingStack');

export { SpacingStack };
