import { defaultGuestSiteTypography } from '@apps/guest/packages/layout-engine/common/theming';
import { JoyKitThemeProvider, Theme, useJoyKitContext } from '@withjoy/joykit';
import React from 'react';

export const GuestSiteFormTypography: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { theme: currentTheme } = useJoyKitContext();
  const { typography, ...currentThemeWithoutTypography } = currentTheme;
  const theme: Theme = {
    ...currentThemeWithoutTypography,
    ...defaultGuestSiteTypography
  };
  return <JoyKitThemeProvider theme={theme}>{children}</JoyKitThemeProvider>;
};
