import React from 'react';
import { InputV1Props } from '.';
import { animationTransition } from '../../utils';
import { HTMLInputProps } from '../../common/props';
import { styled, css, ThemeStyledProps } from '@shared/joykit/packages/core/common/styledComponents';

const InputComponent = React.forwardRef<HTMLInputElement, InputV1Props & HTMLInputProps>(
  (
    {
      autoFocus,
      children,
      error,
      ghost,
      iconLeft,
      iconRight,
      inputRef,
      minimal,
      flex,
      order,
      margin,
      marginX,
      marginY,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      ...props
    },
    ref
  ) => {
    return <input {...props} ref={ref} />;
  }
);

const minimalStyle = (props: ThemeStyledProps<InputV1Props>) => {
  return css`
    border: none;
  `;
};
const ghostStyle = (props: ThemeStyledProps<InputV1Props>) => {
  return css`
    background-color: transparent;
    padding: 0;
  `;
};

export const StyledInput = styled(InputComponent)`
  border: 1px solid ${props => (props.error ? props.theme.colors.buttonDestructiveFill : props.theme.colors.mono4)};
  border-radius: 5px;
  min-height: 48px;
  outline: none;
  padding: 0 16px;
  width: 100%;
  ${props => props.theme.typography.variants.body1};
  transition: ${animationTransition('border-color', 'background-color', 'color')};

  ${props =>
    props.minimal
      ? minimalStyle(props)
      : css`
          :hover:enabled {
            border-color: ${props.error
              ? props.theme.colors.buttonDestructiveHover
              : (props.focusedBorderColor && props.theme.colors[props.focusedBorderColor]) || props.theme.colors.buttonPrimaryHover};
          }
        `}
  ${props => props.ghost && ghostStyle(props)}

  :read-only {
    cursor: default;
  }

  :disabled {
    background-color: ${props => !props.minimal && props.theme.colors.buttonDisabledFill};
    color: ${props => props.theme.colors.typeDisabledDark};
    cursor: not-allowed;
  }
`;
