import { ApolloError } from '@apollo/client';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const ActionBarTelemetry = createTelemetryObject({
  actions: {
    trackError: (mutation: string, error: ApolloError | Error | unknown, extra = {}) => ({
      category: 'wedding',
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),

    onVirtualEventClicked: () => ({
      action: 'ActionBarJoinLivestreamClicked',
      actionType: 'click',
      category: 'wedding'
    }),
    onLogoutClicked: () => ({
      action: 'ActionBarLogoutClicked',
      actionType: 'click',
      category: 'wedding'
    }),
    onRsvpHandle: () => ({
      action: 'ActionBarRSVPClicked',
      actionType: 'click',
      category: 'wedding'
    }),

    onUnlockDialogHandle: () => ({
      action: 'ActionBarUnlockClicked',
      actionType: 'click',
      category: 'wedding'
    })
  }
});

const { TelemetryProvider, useTelemetry: useActionBarTelemetry } = createTelemetry(ActionBarTelemetry);
export { TelemetryProvider, useActionBarTelemetry };
