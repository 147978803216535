import React, { useEffect, useMemo, useState } from 'react';
import { EventPageFragment, EventPageType } from '@graphql/generated';
import { Box, BoxProps, ColumnsV2, Flex, TextV2 } from '@withjoy/joykit';
import { LayoutBrannanProps } from '../../LayoutBrannan';
import { pageColumnProps, pageWidgets, PreparedPages } from './Body.constants';
import { shouldRenderGraphicAccent } from '../../../layout.utils';
import { PrettyLink } from '@apps/guest/packages/layout-engine/components/PrettyLink';
import GraphicAccent from '@apps/guest/packages/layout-engine/components/GraphicAccent';
import { useSlapAdsTravelClosed } from '@apps/guest/packages/layout-engine/components/SlapAdsTravel';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { InlineEditor } from '@shared/components';
import { useTranslation } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { withWindow } from '@shared/utils/withWindow';
import { routePaths, RoutesNames } from '@apps/guest/utils/RouteHelper.utils';
import { isInIframe } from '@shared/utils/isInIframe';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { InlineEditExperimentProps, useInlineEditExperimentListeners } from '@shared/hooks/useInlineEditExperimentListeners';

export interface BodyProps extends Readonly<{ page: Maybe<EventPageFragment>; photo?: React.ReactNode } & LayoutBrannanProps & BoxProps> {}

const Body: React.FC<BodyProps> = ({ page, event, eventHandle, photo, ...restProps }) => {
  const [inlinePageEditEnabled, setInlinePageEditEnabled] = useState(false);

  const slapAdsTravelClosed = useSlapAdsTravelClosed();
  const { isAdmin } = useEventUserRole();
  const { isNativeAccommodations, isAccommodations } = useGuestSiteState();

  const { t2 } = useTranslation('joykit');
  const inlineEditorTrans = t2('inlineEditor');

  const isPreviewing = isInIframe();

  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);

  useEffect(() => {
    sendMessageToParentWindow({
      action: 'experimentRequestFromPreview',
      value: {
        id: page?.type || '',
        key: 'admindashboardInlinePageEditExperiment',
        skip: !isPreviewing || page?.type === EventPageType.app || page?.type === EventPageType.welcome
      },
      source: 'joyPreview'
    });
  }, [isPreviewing, page?.type]);

  const experimentData: InlineEditExperimentProps = useMemo(
    () => [
      {
        experimentKey: 'admindashboardInlinePageEditExperiment',
        setFn: data => {
          if (data.id === page?.type || '') {
            setInlinePageEditEnabled(data.isEnabled);
          }
        }
      }
    ],
    [page?.type]
  );

  useInlineEditExperimentListeners(experimentData);

  const handleEditTextClick = useEventCallback((textContent: string, label: string, pageId: string) => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editTextArea', inlineEditData: { textContent, label, pageId, validationConstraints: { maxLength: 140 } } }
    });
  });

  const onPageEditClick = useEventCallback(() => {
    const path = page?.type === EventPageType.custom && page.pageSlug === 'accommodations' ? 'accommodations' : page?.type;

    withWindow(window => {
      if (path && routePaths.hasOwnProperty(path)) {
        window.open(`/${eventHandle}/edit/${routePaths[path as RoutesNames].goToPath(page?.pageSlug || '')}`, '_blank');
      }
    });
  });

  const onVisibilityClick = useEventCallback(() => {
    if (page) {
      // send the message to the parent window to open the text editor
      sendMessageToParentWindow({
        action: 'inlineEditingInteraction',
        source: 'joyPreview',
        value: {
          action: 'editPageSettings',
          inlineEditData: { pageId: page.id, pageType: page.type, pageTitle: page.pageTitle, pageVisibility: page.visibility, isPageContainer: page.isPageContainer }
        }
      });
    }
  });

  if (!page) {
    return null;
  }
  const { type, pageTitle, subTitle, pageSlug, id } = page;
  const graphicAccent = event.eventDesign?.accent;

  const PageWidget = pageWidgets[type as PreparedPages];
  const isWelcomePage = type === EventPageType.welcome;
  const isSchedulePage = type === EventPageType.schedule;
  const isStoryPage = type === EventPageType.story;
  const isTravelPage = type === EventPageType.travel;
  const isCustomPage = type === EventPageType.custom;
  const isAppPage = type === EventPageType.app;

  const pageContentMarkup = PageWidget && (
    <ColumnsV2.Column
      id={pageSlug}
      {...(isCustomPage && !isAccommodations ? { span: [10, 10, 8] } : { ...pageColumnProps[type as PreparedPages] })}
      marginTop={isWelcomePage || isSchedulePage ? undefined : isCustomPage ? 0 : [10, null, 0]}
      {...(isTravelPage && inlinePageEditEnabled && { style: { display: 'flex', justifyContent: 'center' } })}
    >
      <PageWidget isAccommodations={isAccommodations} event={event} eventHandle={eventHandle} pageId={id} pageSlug={pageSlug} pageTitle={pageTitle} subTitle={subTitle} />
    </ColumnsV2.Column>
  );

  const graphicAccentMarkup = shouldRenderGraphicAccent(graphicAccent) && (
    <GraphicAccent id="graphic-accent" marginBottom={8} data-testid={'body-accent'} accentId={graphicAccent!} />
  );

  return (
    <ColumnsV2 {...restProps}>
      {inlinePageEditEnabled ? (
        <>
          <ColumnsV2.Column style={isCustomPage ? { display: isNativeAccommodations && !subTitle ? 'none' : 'block' } : {}} span={[12, 10]} offset={[0, 1]}>
            {photo}
            <Flex
              flexDirection={'column'}
              alignItems={'center'}
              marginTop={isAccommodations ? { _: 6, sm2: 9 } : isCustomPage ? { _: '8rem', sm2: '7rem', md: 9 } : 9}
              marginX={isCustomPage ? [6, 'auto'] : [6, null, 0]}
              maxWidth={isCustomPage ? '580px' : null}
            >
              {graphicAccentMarkup}
            </Flex>
          </ColumnsV2.Column>
          <InlineEditor
            actionData={{
              edit: onPageEditClick,
              visibility: {
                function: onVisibilityClick,
                visibility: page.visibility
              }
            }}
            inset={isMobile ? { x: 8, y: -16 } : { x: 4, y: -16 }}
            wrapperType="actionOutside"
            componentName="page"
            elementLabel={page.pageTitle}
            pageName={page.type}
            pageSlug={page.pageSlug}
            stickyOnScroll={true}
            isEligibleForInlineEditing={!isWelcomePage && !isAppPage && !isStoryPage}
            wrapperCSS={{ width: '100%' }}
          >
            <ColumnsV2.Column style={isCustomPage ? { display: isNativeAccommodations && !subTitle ? 'none' : 'block' } : {}} span={[12, 10]} offset={[0, 1]}>
              <Flex
                flexDirection={'column'}
                alignItems={'center'}
                marginBottom={isStoryPage || (isAdmin && !slapAdsTravelClosed && isTravelPage) ? 0 : isAccommodations ? { _: 6, sm2: 9 } : 9}
                marginX={isCustomPage ? [6, 'auto'] : [6, null, 0]}
                maxWidth={isCustomPage ? '580px' : null}
              >
                {/* {graphicAccentMarkup} */}
                {isWelcomePage && pageContentMarkup}
                {!isWelcomePage && !isStoryPage && !isNativeAccommodations && (
                  <TextV2
                    data-testid={'body-pagetitle'}
                    typographyVariant={'display4'}
                    fontSize={isAccommodations ? { _: 'clamp(2rem,5cqi,3rem)', sm2: 'clamp(1.8rem,5cqi,3rem)' } : '3rem'}
                    textAlign="center"
                    wordBreak="break-word"
                  >
                    {pageTitle}
                  </TextV2>
                )}
                {!isStoryPage &&
                  subTitle &&
                  (isWelcomePage ? (
                    <Box marginTop={9}>
                      <InlineEditor
                        elementLabel={inlineEditorTrans.greetings}
                        actionData={{
                          edit: () => handleEditTextClick(subTitle, inlineEditorTrans.greetings, id)
                        }}
                        wrapperCSS={{
                          zIndex: 1200
                        }}
                        componentName="greetings"
                        pageName={type}
                        pageSlug={pageSlug}
                      >
                        <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} maxWidth={500} textAlign="center">
                          <PrettyLink source={page.pageSlug}>{subTitle}</PrettyLink>
                        </TextV2>
                      </InlineEditor>
                    </Box>
                  ) : (
                    <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} marginTop={4} maxWidth={500} textAlign="center">
                      <PrettyLink source={page.pageSlug}>{subTitle}</PrettyLink>
                    </TextV2>
                  ))}
              </Flex>
            </ColumnsV2.Column>

            {!isWelcomePage && (
              <Box width={isMobile ? 'unset' : '100%'} marginX={[isAccommodations ? 0 : 7, null, 0]} display={'flex'} justifyContent={'center'}>
                {pageContentMarkup}
              </Box>
            )}
          </InlineEditor>
        </>
      ) : (
        <>
          <ColumnsV2.Column style={isCustomPage ? { display: isNativeAccommodations && !subTitle ? 'none' : 'block' } : {}} span={[12, 10]} offset={[0, 1]}>
            {photo}
            <Flex
              flexDirection={'column'}
              alignItems={'center'}
              marginTop={isAccommodations ? { _: 6, sm2: 9 } : isCustomPage ? { _: '8rem', sm2: '7rem', md: 9 } : 9}
              marginBottom={isStoryPage || (isAdmin && !slapAdsTravelClosed && isTravelPage) ? 0 : isAccommodations ? { _: 6, sm2: 9 } : 9}
              marginX={isCustomPage ? [6, 'auto'] : [6, null, 0]}
              maxWidth={isCustomPage ? '580px' : null}
            >
              {graphicAccentMarkup}
              {isWelcomePage && pageContentMarkup}
              {!isWelcomePage && !isStoryPage && !isNativeAccommodations && (
                <TextV2
                  data-testid={'body-pagetitle'}
                  typographyVariant={'display4'}
                  fontSize={isAccommodations ? { _: 'clamp(2rem,5cqi,3rem)', sm2: 'clamp(1.8rem,5cqi,3rem)' } : '3rem'}
                  textAlign="center"
                  wordBreak="break-word"
                >
                  {pageTitle}
                </TextV2>
              )}
              {!isStoryPage &&
                subTitle &&
                (isWelcomePage ? (
                  <Box marginTop={9}>
                    <InlineEditor
                      elementLabel={inlineEditorTrans.greetings}
                      actionData={{
                        edit: () => handleEditTextClick(subTitle, inlineEditorTrans.greetings, id)
                      }}
                      wrapperCSS={{
                        zIndex: 1200
                      }}
                      componentName="greetings"
                      pageName={type}
                      pageSlug={pageSlug}
                    >
                      <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} maxWidth={500} textAlign="center">
                        <PrettyLink source={page.pageSlug}>{subTitle}</PrettyLink>
                      </TextV2>
                    </InlineEditor>
                  </Box>
                ) : (
                  <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} marginTop={4} maxWidth={500} textAlign="center">
                    <PrettyLink source={page.pageSlug}>{subTitle}</PrettyLink>
                  </TextV2>
                ))}
            </Flex>
          </ColumnsV2.Column>

          {!isWelcomePage && (
            <Box width={'100%'} marginX={[isAccommodations ? 0 : 7, null, 0]} display={'flex'} justifyContent={'center'}>
              {pageContentMarkup}
            </Box>
          )}
        </>
      )}
    </ColumnsV2>
  );
};

Body.displayName = 'Body';

export { Body };
