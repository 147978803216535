import React from 'react';
import { StyledTitleStack, StyledFormStack, StyledHeaderStack, StyledContent, styles } from '../UnlockForm/UnlockForm.styles';
import { ButtonV2, TextV2 } from '@withjoy/joykit';
import { GuestTypes } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialog';
import { useFindInviteSuccessController } from './FindInviteSuccessForm.controller';

export interface SuccessFormProps {
  eventId: string;
  guest: GuestTypes;
  onDialogClose: () => void;
}

const FindInviteSuccessForm: React.FC<SuccessFormProps> = ({ guest, eventId, onDialogClose }) => {
  const { title, subtitle, submitButtonText, onSubmitHandler } = useFindInviteSuccessController({ guest, eventId, onDialogClose });

  return (
    <StyledContent __css={styles.content}>
      <StyledHeaderStack>
        <StyledTitleStack>
          <TextV2 typographyVariant="display4">{title}</TextV2>
          <TextV2 typographyVariant="body4">{subtitle}</TextV2>
        </StyledTitleStack>
      </StyledHeaderStack>
      <StyledFormStack spacing={6} __css={styles.formStack}>
        <ButtonV2 width={'100%'} intent="primary" onClick={onSubmitHandler}>
          {submitButtonText}
        </ButtonV2>
      </StyledFormStack>
    </StyledContent>
  );
};

export default FindInviteSuccessForm;
