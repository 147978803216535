import { useHistory } from '@react-router';
import { useTranslation } from '@shared/core';
import { SuccessFormProps } from './FindInviteSuccessForm';

export const useFindInviteSuccessController = ({ guest, onDialogClose }: SuccessFormProps) => {
  const history = useHistory();
  const { t, t2 } = useTranslation('guestUnlock');
  const title = t('findInviteSuccessForm', 'title')({ firstName: guest.firstName });
  const { subtitle, submitButtonText } = t2('findInviteSuccessForm');

  const onSubmitHandler = () => {
    onDialogClose();
    history.push('schedule');
  };

  return {
    title,
    subtitle,
    submitButtonText,
    onSubmitHandler
  };
};
