import { addDays, subDays } from 'date-fns';
import { useEventInfo } from '@shared/utils/eventInfo';
import { PointsOnMapFragment } from '@graphql/generated';
import { AccommodationCombined, AccommodationCombinedType } from '../../Accommodations/Accommodation.types';
import { useMemo } from 'react';

const GUESTS = 2;
const BOOKING_TYPE = 'hotels';

interface BookingAssistantInfoProps {
  venue: Maybe<PointsOnMapFragment>;
  accommodations: Maybe<AccommodationCombined[]>;
}

export const useBookingAssistantInfo = ({ venue, accommodations }: BookingAssistantInfoProps) => {
  const { eventInfo } = useEventInfo();

  const roomBlock = accommodations?.find(accommodation => accommodation.type === AccommodationCombinedType.Roomblock);
  const hotel = accommodations?.find(accommodation => accommodation.type !== AccommodationCombinedType.Roomblock);

  const { placeId, address, lat, lng, startDate, endDate } = useMemo(() => {
    const placeId = hotel?.googlePlaceId || eventInfo?.locationInfo?.placeId;
    const address = venue ? `${venue.name}, ${venue.address}` : roomBlock?.address || hotel?.address || eventInfo?.locationInfo?.name;
    const lat = venue?.latitude || roomBlock?.latitude || hotel?.latitude || eventInfo?.locationInfo?.latitude;
    const lng = venue?.longitude || roomBlock?.longitude || hotel?.longitude || eventInfo?.locationInfo?.longitude;

    const today = new Date();
    const startDate = new Date(roomBlock?.checkInDate || subDays(eventInfo?.finalizedDate?.milliseconds ?? addDays(today, 1), 1));
    const endDate = new Date(roomBlock?.checkOutDate || eventInfo?.finalizedDate?.milliseconds || today);

    return {
      placeId,
      address,
      lat,
      lng,
      startDate,
      endDate
    };
  }, [eventInfo, hotel, roomBlock, venue]);

  const bookingProps = {
    placeId,
    address,
    lat,
    lng,
    eventId: eventInfo?.eventId ?? '',
    startDate: startDate?.getTime() as number,
    endDate: endDate?.getTime() as number,
    guests: GUESTS,
    bookingType: BOOKING_TYPE
  };

  return bookingProps;
};
