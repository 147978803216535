import React from 'react';

import { ErrorComponent } from './ErrorComponent';

export interface ErrorBoundaryProps {
  readonly onCaughtError: (error: Error, errorInfo: React.ErrorInfo) => void;
}

interface ErrorBoundaryState {
  readonly hasErrored: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasErrored: false
    };
  }

  static getDerivedStateFromError() {
    return {
      hasErrored: true
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onCaughtError(error, errorInfo);
  }

  render() {
    return !this.state.hasErrored ? this.props.children : <ErrorComponent />;
  }
}
