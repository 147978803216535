import globalWindow from '@shared/core/globals';
import { useEffect, useState } from 'react';
import { useTheme } from '@withjoy/joykit';
import { MediaQuery, Theme } from '@withjoy/joykit/theme';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';

type Query = keyof MediaQuery | ((theme: Theme) => string);
type Orientation = '(orientation: portrait)' | '(orientation: landscape)';

const matchMedia = globalWindow.matchMedia;

type UseMediaQueryOptions = {
  defaultMatches?: boolean;
};

export const useMediaQuery = (queryInput: Query | Orientation, options: UseMediaQueryOptions = {}) => {
  const theme = useTheme();

  // matchMedia is a Web API - ensure useMediaQuery triggers when window is available.
  const isMounted = useIsMounted();

  let query: string = typeof queryInput === 'function' ? queryInput(theme) : queryInput;
  query = query.replace(/^@media( ?)/m, '');

  const [match, setMatch] = useState<boolean>(() => {
    if (matchMedia) {
      return matchMedia(query).matches;
    }

    return !!options.defaultMatches;
  });

  useEffect(() => {
    if (!isMounted || !matchMedia) {
      return;
    }

    const queryList = matchMedia(query);
    const updateMatch = () => {
      setMatch(queryList.matches);
    };

    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      queryList.removeListener(updateMatch);
    };
  }, [query, isMounted]);

  return match;
};
