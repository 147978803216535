import { ButtonV2Props } from '@withjoy/joykit';

export const editPhotoOverrides: ButtonV2Props['overrides'] = {
  Root: {
    props: {
      fontFamily: 'Inter UI',
      color: 'gray14',
      _hover: {
        backgroundColor: 'mono1',
        color: 'purple6'
      },
      _active: {
        backgroundColor: 'mono2',
        color: 'purple8'
      }
    }
  }
};
