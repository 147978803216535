import { PhotoFragment } from '@graphql/generated';
import { Box, pseudoSelectors, styled } from '@withjoy/joykit';
import { roundToPrecision } from '@shared/utils/numbers';

export const CoverPhotoContainer = styled(Box)<{ photo: PhotoFragment }>`
  ${({ theme, photo }) => {
    const aspectRatio = roundToPrecision((photo.height / photo.width) * 100);

    return {
      [theme.mediaQueries.down({ viewport: 'xl' })]: {
        [pseudoSelectors._after]: {
          content: '" "',
          display: 'block',
          paddingTop: `${aspectRatio}%`,
          pointerEvents: 'none'
        }
      }
    };
  }}
`;
