import { Box, SpacingStack, styled, StyleSystemProps } from '@withjoy/joykit';

export const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  left: 0;
  background: white;
`;

export const StyledHeaderStack = styled(Box)`
  margin-bottom: 32px;
`;

export const StyledIconFrame = styled(Box)`
  display: flex;
  width: 96px;
  height: 96px;
  border: 4px solid ${props => props.theme.colors.mono2};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
`;

export const StyledTitleStack = styled(SpacingStack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledFormStack = styled(SpacingStack)``;

export const StyledInputsStack = styled(SpacingStack)`
  margin-bottom: 32px;
`;

export const StyledLinksStack = styled(SpacingStack)`
  justify-content: space-between;
  margin-top: 24px;
`;

const contentStyles: StyleSystemProps = {
  padding: ['48px 24px', '48px'],
  width: '100%'
};

const formStackStyles: StyleSystemProps = {
  width: ['100%', '308px']
};

const unlockedContentStyles: StyleSystemProps = {
  height: ['100%', '530px'],
  backgroundColor: 'transparent',
  alignItems: 'center'
};

export const styles = {
  content: contentStyles,
  formStack: formStackStyles,
  unlockedContent: unlockedContentStyles
};
