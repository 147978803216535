import { useEffect, useMemo, useRef } from 'react';
import { ModifierArguments, Options, State as PopperState } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import { UsePopperArgs } from './Popover.types';
import { calculateMargin, getTransformOrigin, normalizeOffsets } from './Popover.utils';
import { FROM_POPPER_PLACEMENT, TO_POPPER_PLACEMENT } from './Popover.constants';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

type SizeTracker = { width: number; height: number };

export const usePopper = (args: UsePopperArgs) => {
  const { anchorRef, arrowRef, onPopperUpdate, options = {}, popperRef, placement } = args;
  const isMounted = useIsMounted();
  const sizeRef = useRef<SizeTracker>({ width: 0, height: 0 });
  const anchorSizeRef = useRef<SizeTracker>({ width: 0, height: 0 });
  const popperInstanceRef = useRef<{ forceUpdate: () => void; update: () => Promise<Partial<PopperState>> } | null>(null);

  const handleOnPopperUpdate = useEventCallback((data: ModifierArguments<object>) => {
    const nextPlacement = FROM_POPPER_PLACEMENT[data.state.placement];
    onPopperUpdate(
      nextPlacement,
      {
        popover: normalizeOffsets(data.state.modifiersData.popperOffsets),
        arrow: normalizeOffsets(data.state.modifiersData.arrow)
      },
      getTransformOrigin(nextPlacement, data),
      data
    );
  });

  const popperOptions = useMemo<Options>(() => {
    const { offset: offsetOptions, disableFlip } = options;
    const offsetHandler = offsetOptions?.offset || [0, calculateMargin(!!arrowRef)];
    return {
      strategy: 'absolute',
      placement: TO_POPPER_PLACEMENT[placement],
      modifiers: [
        {
          name: 'arrow',
          enabled: !!arrowRef,
          options: {
            element: arrowRef
          }
        },
        {
          name: 'offset',
          options: {
            offset: offsetHandler
          }
        },
        {
          name: 'flip',
          enabled: !disableFlip,
          options: { padding: 8 }
        },
        {
          name: 'adjustStyles',
          enabled: true,
          phase: 'beforeWrite',
          fn: ({ state }) => {
            const arrowStyles = state.styles.arrow;
            if (arrowStyles?.transform) {
              arrowStyles.transform = `${arrowStyles.transform} rotate(45deg)`;
            }
          },
          requires: ['computeStyles']
        },
        {
          name: 'applyStyles',
          enabled: true
        },
        {
          name: 'preventOverflow',
          enabled: true
        },
        {
          name: 'applyReactStyle',
          enabled: true,
          phase: 'write',
          fn: handleOnPopperUpdate,
          requires: ['computeStyles']
        }
      ]
    };
  }, [arrowRef, handleOnPopperUpdate, options, placement]);

  useEffect(() => {
    if (isMounted) {
      if (!anchorRef || !popperRef) {
        return;
      }

      const popper = createPopper(anchorRef, popperRef, popperOptions);

      popperInstanceRef.current = {
        forceUpdate: popper.forceUpdate,
        update: popper.update
      };

      return () => {
        popper.destroy();
        popperInstanceRef.current = null;
      };
    }
    return () => {};
  }, [isMounted, arrowRef, anchorRef, popperRef, popperOptions]);

  const hasElementDimensionsChanged = useEventCallback((el: Maybe<HTMLElement>, sizeTrackerRef: React.MutableRefObject<SizeTracker>) => {
    let hasChanged: boolean = false;
    if (el) {
      const { height, width } = el.getBoundingClientRect();
      // Check if dimensions have changed
      if (sizeTrackerRef.current.height !== height || sizeTrackerRef.current.width !== width) {
        sizeTrackerRef.current = { height, width };
        hasChanged = true;
      }
    }
    return hasChanged;
  });

  // Handle case where popper content changes size. Popper.js only observes window resize + scroll events
  useIsomorphicLayoutEffect(() => {
    const hasPopperChanged = hasElementDimensionsChanged(popperRef, sizeRef);
    const hasAnchorChanged = hasElementDimensionsChanged(anchorRef, anchorSizeRef);
    if (hasPopperChanged || hasAnchorChanged) {
      // Schedule an update
      popperInstanceRef.current?.update();
    }
  }, [popperRef, anchorRef, hasElementDimensionsChanged]);

  return popperInstanceRef.current;
};
