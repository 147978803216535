import { styled, Box, TextV2 } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const StyledItemAvatar = styled(Box)`
  border-radius: 2px;
  background-color: #eaeaea;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-origin: border-box;
  padding-top: 100%;
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: ${animationTransition('transform')};
  @media (hover: hover) {
    :hover {
      transform: scale(1.1);
    }
  }
`;

export const StyledItemInitials = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.theme.colors.white};
  font-size: 48px;
  text-transform: uppercase;
`;

export const StyledWedginPartyText = styled(TextV2)`
  overflow-wrap: break-word;
  text-align: left;
`;
