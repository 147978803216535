import { css, Flex, styled } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

const hideOnLoading = () => css`
  visibility: hidden;
  opacity: 0;
`;

const showOnLoading = () => css`
  visibility: initial;
  transition: ${animationTransition('opacity')};
`;

export const StyledFlexAloha = styled(Flex)<{ loading: boolean; isPreviewing: boolean }>`
  width: 100%;
  flex-direction: column;
  ${({ loading }) => (loading ? hideOnLoading : showOnLoading)}

  height: 100vh;
  max-height: 100%;
  z-index: 1;
  overflow: auto;

  ${({ isPreviewing }) =>
    isPreviewing &&
    `
    * {
        pointer-events: none;
      }
  `}
`;

export const PageContainer = styled.div`
  display: inline-block;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    & > :first-child {
      margin-bottom: 4rem;
    }
  }
`;

export const CustomPageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    margin-bottom: 4rem;
  }
`;
