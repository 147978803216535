/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Star Filled',
  definitions: {
    '16': {
      viewBox: '0 0 16 17',
      path: (
        <>
          <path
            d="M3.37425 15.2936C3.34847 15.2936 3.32269 15.2856 3.30078 15.2697C3.25691 15.2378 3.2386 15.1813 3.25544 15.1297L5.00716 9.77492L0.441378 6.47148C0.39744 6.43967 0.379003 6.38317 0.395784 6.33158C0.412503 6.28008 0.460503 6.24517 0.514659 6.24517C0.514721 6.24517 0.514815 6.24517 0.514878 6.24517L6.15041 6.2553L7.88028 0.893234C7.89694 0.841641 7.94497 0.806641 7.99922 0.806641C8.05347 0.806641 8.10153 0.841609 8.11819 0.893234L9.8495 6.25533L15.4851 6.2452C15.4851 6.2452 15.4852 6.2452 15.4853 6.2452C15.5394 6.2452 15.5874 6.28008 15.6042 6.33161C15.6209 6.38317 15.6025 6.4397 15.5586 6.47152L10.9928 9.77495L12.7446 15.1298C12.7614 15.1813 12.7431 15.2379 12.6993 15.2698C12.6553 15.3016 12.5959 15.3016 12.5521 15.2696L7.99928 11.9485L3.44794 15.2696C3.426 15.2856 3.40013 15.2936 3.37425 15.2936Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 26 26',
      path: (
        <>
          <path
            d="M6.06138 23.416C6.02271 23.416 5.98404 23.404 5.95118 23.3802C5.88536 23.3323 5.85789 23.2475 5.88316 23.1702L8.51074 15.138L1.66207 10.1828C1.59616 10.1351 1.5685 10.0503 1.59368 9.97295C1.61875 9.8957 1.69075 9.84334 1.77199 9.84334C1.77189 9.84334 1.77208 9.84334 1.77199 9.84334L10.2256 9.85853L12.8204 1.81544C12.8454 1.73805 12.9175 1.68555 12.9988 1.68555C13.0802 1.68555 13.1523 1.738 13.1773 1.81544L15.7743 9.85858L24.2276 9.84339C24.2275 9.84339 24.2277 9.84339 24.2276 9.84339C24.3088 9.84339 24.3812 9.8957 24.4063 9.973C24.4314 10.0503 24.4038 10.1351 24.3378 10.1829L17.4892 15.138L20.1168 23.1702C20.1422 23.2476 20.1147 23.3324 20.0489 23.3802C19.983 23.428 19.8938 23.428 19.8281 23.38L12.9989 18.3983L6.17191 23.38C6.139 23.404 6.10019 23.416 6.06138 23.416Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
