import React from 'react';
import { useFeatureSet } from '@shared/core/featureSet/useFeatureSet';
import { useEffect } from 'react';
import { useGuestSiteRedirection } from '../../common/utils/useGuestSiteRedirection';
import { useGuestSiteFeatureSetHealth, wrapWithGuestSiteFeatureSetHealthProvider } from './GuestSiteFeatureSet.health';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

interface Props {
  loadingGuestSite: boolean;
}

/**
 * Component responsible for waiting while the GuestSite is initialized, and resolving the FeatureSet and redirection.
 */
export const GuestSiteFeatureSet: React.FC<Props> = wrapWithGuestSiteFeatureSetHealthProvider(({ children, loadingGuestSite }) => {
  const { onLoadComplete, onLoadFailure } = useGuestSiteFeatureSetHealth();
  const { isInitialized: featureSetInitialized, features, hasErrors: featureSetError } = useFeatureSet();
  const { waitingRedirection: waitingFeaturesetRedirection } = useGuestSiteRedirection(features);

  useEffect(() => {
    if (featureSetError) {
      onLoadFailure(featureSetError);
    }
  }, [featureSetError, onLoadFailure]);

  useEffect(() => {
    if (featureSetInitialized && !waitingFeaturesetRedirection) {
      onLoadComplete();
    }
  }, [featureSetInitialized, waitingFeaturesetRedirection, onLoadComplete]);

  return <>{loadingGuestSite || !featureSetInitialized || waitingFeaturesetRedirection ? <JoyLogoLoader loaderKey="guest-site-feature-set" /> : children}</>;
});
