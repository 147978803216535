import { StyleSystemProps } from '@withjoy/joykit';

const slapAdsRegistryWrapper: StyleSystemProps = {
  border: '1px solid',
  borderColor: 'warning3'
};

export const styles = {
  slapAdsRegistryWrapper
};
