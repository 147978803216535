/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Icon Error',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3336 7.99979C15.3336 12.0501 12.0501 15.3336 7.99979 15.3336C3.94946 15.3336 0.666016 12.0501 0.666016 7.99979C0.666016 3.94946 3.94946 0.666016 7.99979 0.666016C12.0501 0.666016 15.3336 3.94946 15.3336 7.99979ZM14.2336 7.99979C14.2336 11.4426 11.4426 14.2336 7.99979 14.2336C4.55697 14.2336 1.76602 11.4426 1.76602 7.99979C1.76602 4.55697 4.55697 1.76602 7.99979 1.76602C11.4426 1.76602 14.2336 4.55697 14.2336 7.99979Z"
            fill="currentColor"
          />
          <path
            d="M7.99822 3.8623C8.34969 3.8623 8.63461 4.14722 8.63461 4.49868V8.95338C8.63461 9.30484 8.34969 9.58976 7.99822 9.58976C7.64675 9.58976 7.36184 9.30484 7.36184 8.95338V4.49868C7.36184 4.14722 7.64675 3.8623 7.99822 3.8623Z"
            fill="currentColor"
          />
          <path
            d="M7.99797 10.4986C8.55058 10.4983 8.99886 10.9466 8.99854 11.4992C8.99821 12.0515 8.55025 12.4989 7.99797 12.4986C7.44601 12.4983 6.99886 12.0511 6.99854 11.4992C6.99821 10.9469 7.44568 10.4989 7.99797 10.4986Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
