import { cubicBezierEasingFn } from '@shared/utils/animationTransition';
import { useKeycodeListener } from '@shared/utils/hooks/useKeycodeListener';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useDisclosure } from '@withjoy/joykit';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import Carousel, { ItemObject } from 'react-elastic-carousel';
import { useSpring } from 'react-spring';

interface ICarousel extends Carousel {
  goTo: (index: number) => void;
  slideNext: () => void;
  slidePrev: () => void;
  state: {
    activeIndex: number;
    pages: number[];
  };
}

type PrimaryAnimationSpring = {
  opacity: number;
};

type SlideAction = {
  type: 'prev' | 'next' | 'goto';
  payload?: number;
};

interface currentItemTypesExtended extends ItemObject {
  item?: {
    id: string;
  };
}

const DEBOUNCE_TIMEOUT = 300;
const ANIMATION_DURATION = 400;

export function useProfileDialogController() {
  const [isMobile] = useResponsive({ values: { xxs: true, xs: true, sm: true, md: false, lg: false, xl: false, xxl: false } });
  const slideActionRef = useRef<SlideAction | null>();
  const carouselRef = useRef<ICarousel | null>(null);
  const curSlideRef = useRef<Maybe<HTMLElement>>(null);
  const [springStyles, set] = useSpring<PrimaryAnimationSpring>(() => ({
    from: { opacity: 1 },
    opacity: 1,
    config: {
      clamp: true,
      duration: ANIMATION_DURATION,
      easing: cubicBezierEasingFn
    },
    onFrame: (springValue: PrimaryAnimationSpring) => {
      if (!isMobile && springValue.opacity === 0 && carouselRef.current && slideActionRef.current) {
        switch (slideActionRef.current.type) {
          case 'goto':
            carouselRef.current.goTo(slideActionRef.current.payload as number);
            break;
          case 'prev':
            carouselRef.current.slidePrev();
            break;
          case 'next':
            carouselRef.current.slideNext();
            break;
        }
        slideActionRef.current = null;
      }
    },
    onRest: () => {
      set({ opacity: 1 });
    }
  }));

  const handlePrev = useCallback(() => {
    if (carouselRef.current) {
      const { activeIndex } = carouselRef.current.state;
      if (activeIndex === 0) return;

      if (isMobile) {
        carouselRef.current.slidePrev();
      } else {
        slideActionRef.current = { type: 'prev' };
        set({ opacity: 0 });
      }
    }
  }, [set, isMobile]);

  const handleNext = useCallback(() => {
    if (carouselRef.current) {
      const { activeIndex, pages } = carouselRef.current.state;
      if (activeIndex === pages.length - 1) return;
      if (isMobile) {
        carouselRef.current.slideNext();
      } else {
        slideActionRef.current = { type: 'next' };
        set({ opacity: 0 });
      }
    }
  }, [set, isMobile]);

  const handleGoTo = (index: number) => {
    if (carouselRef.current) {
      if (isMobile) {
        carouselRef.current.goTo(index);
      } else {
        slideActionRef.current = { type: 'goto', payload: index };
        set({ opacity: 0 });
      }
    }
  };

  const setVh = debounce(
    useCallback(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []),
    DEBOUNCE_TIMEOUT
  );

  const scrollToTop = (initialItemId: string, obj: currentItemTypesExtended) => {
    const id = obj?.item?.id;

    if (!curSlideRef.current) {
      curSlideRef.current = document.getElementById(initialItemId);
    }

    if (curSlideRef.current) {
      curSlideRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    if (id) {
      curSlideRef.current = document.getElementById(id);
    }
  };

  useKeycodeListener([37, 38], () => {
    handlePrev();
  });

  useKeycodeListener([39, 40], () => {
    handleNext();
  });

  useEffect(() => {
    window.addEventListener('resize', setVh);
    setVh();
    return () => window.removeEventListener('resize', setVh);
  }, [setVh]);

  return {
    carouselRef,
    goTo: handleGoTo,
    isMobile,
    scrollToTop,
    slidePrev: handlePrev,
    slideNext: handleNext,
    springStyles
  };
}

export const useProfileDialog = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const openProfileDialog = (index: number) => {
    setActiveIndex(index);
    onOpen();
  };

  const closeProfileDialog = () => {
    setActiveIndex(0);
    onClose();
  };

  return {
    activeIndex,
    isOpen,
    openProfileDialog,
    closeProfileDialog
  };
};
