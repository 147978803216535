/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Calendar',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.57516 2.68037C5.57516 2.37661 5.32892 2.13037 5.02516 2.13037C4.7214 2.13037 4.47516 2.37661 4.47516 2.68037V2.76923H3.19986C2.20659 2.76923 1.44995 3.60914 1.44995 4.58373V12.0547C1.44995 13.0293 2.20659 13.8692 3.19986 13.8692H12.8C13.7933 13.8692 14.55 13.0293 14.55 12.0547V4.58373C14.55 3.60914 13.7933 2.76923 12.8 2.76923H11.7135V2.75795C11.7135 2.45419 11.4672 2.20795 11.1635 2.20795C10.8597 2.20795 10.6135 2.45419 10.6135 2.75795V2.76923H5.57516V2.68037ZM4.47516 3.86923V4.45823C4.47516 4.76199 4.7214 5.00823 5.02516 5.00823C5.32892 5.00823 5.57516 4.76199 5.57516 4.45823V3.86923H10.6135V4.53672C10.6135 4.84048 10.8597 5.08672 11.1635 5.08672C11.4672 5.08672 11.7135 4.84048 11.7135 4.53672V3.86923H12.8C13.1322 3.86923 13.45 4.16159 13.45 4.58373V6.13877H2.54995V4.58373C2.54995 4.16159 2.86774 3.86923 3.19986 3.86923H4.47516ZM2.54995 7.23877V12.0547C2.54995 12.4769 2.86774 12.7692 3.19986 12.7692H12.8C13.1322 12.7692 13.45 12.4769 13.45 12.0547V7.23877H2.54995Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.764 3a.6.6 0 0 0-1.2 0v1.15H5a2.6 2.6 0 0 0-2.6 2.6v12a2.6 2.6 0 0 0 2.6 2.6h14a2.6 2.6 0 0 0 2.6-2.6v-12a2.6 2.6 0 0 0-2.6-2.6h-1.645V3.12a.6.6 0 1 0-1.2 0V4.15h-8.39V3zm-1.2 2.35v.428a.6.6 0 0 0 1.2 0V5.35h8.392v.55a.6.6 0 1 0 1.2 0v-.55H19a1.4 1.4 0 0 1 1.4 1.4v2.913H3.6V6.75A1.4 1.4 0 0 1 5 5.35h1.564zM3.6 10.863v7.887a1.4 1.4 0 0 0 1.4 1.4h14a1.4 1.4 0 0 0 1.4-1.4v-7.887H3.6z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9406 4.16665C22.9406 3.83528 22.6719 3.56665 22.3406 3.56665C22.0092 3.56665 21.7406 3.83528 21.7406 4.16665V5.9H10.1524V4.16667C10.1524 3.8353 9.88379 3.56667 9.55242 3.56667C9.22105 3.56667 8.95242 3.8353 8.95242 4.16667V5.9H6.66657C4.86244 5.9 3.3999 7.36254 3.3999 9.16667V25.1667C3.3999 26.9708 4.86244 28.4333 6.66657 28.4333H25.3332C27.1374 28.4333 28.5999 26.9708 28.5999 25.1667V9.16667C28.5999 7.36254 27.1374 5.9 25.3332 5.9H22.9406V4.16665ZM21.7406 7.1V7.87054C21.7406 8.20191 22.0092 8.47054 22.3406 8.47054C22.6719 8.47054 22.9406 8.20191 22.9406 7.87054V7.1H25.3332C26.4746 7.1 27.3999 8.02528 27.3999 9.16667V13.2508H4.5999V9.16667C4.5999 8.02528 5.52518 7.1 6.66657 7.1H8.95242V7.87054C8.95242 8.20191 9.22105 8.47054 9.55242 8.47054C9.88379 8.47054 10.1524 8.20191 10.1524 7.87054V7.1H21.7406ZM4.5999 14.4508V25.1667C4.5999 26.3081 5.52518 27.2333 6.66657 27.2333H25.3332C26.4746 27.2333 27.3999 26.3081 27.3999 25.1667V14.4508H4.5999Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
