import React, { forwardRef, PropsWithChildren, useCallback } from 'react';
import { animated, AnimatedValue, useSpring } from 'react-spring';
import { DrawerPropsWithDefaults, DrawerSize } from './Drawer.types';
import { useOverrides } from '@shared/utils/overrides';
import { useTranslation } from '@shared/core';
import { StyleSystemProps, ModalFooterProps } from '@withjoy/joykit';
import { createDisplayName } from '@withjoy/joykit/utils';
import { Modal, ModalFocusLock, useModalContext, ModalBackdrop, ModalCloseButton, ModalBody, ModalHeader } from '../Modal/Modal';
import { useModalStyles, StyledRoot, StyledContentContainer, StyledContent, StyledFooter } from '../Modal/Modal.styles';
import type { Anchor, DrawerV2Props, SharedDrawerProps } from './Drawer.types';
import { drawerStyles, getSizeStyles, getAnchorOriginStyles, getTransitionStates } from './Drawer.styles';
import { CloseButtonProps } from '@withjoy/joykit';
import { forwardStyledComponentRef } from '@shared/utils/forwardRef';

const defaultProps = {
  anchor: 'right' as Anchor,
  overrides: {} as DrawerV2Props['overrides'],
  size: 'sm' as DrawerSize,
  useBackdrop: true as boolean
} as const;

const DrawerV2 = (props: PropsWithChildren<DrawerV2Props & typeof defaultProps>) => {
  const { anchor, children, overrides, size, useBackdrop, ...restProps } = props;
  const { t } = useTranslation('joykit');
  const rootAriaLabel = t('drawer', 'rootLabel')();

  return (
    <Modal {...restProps} overrides={overrides} styleConfig={drawerStyles}>
      {useBackdrop && <DrawerBackdrop />}
      <DrawerContent overrides={overrides} anchor={anchor} size={size} rootAriaLabel={rootAriaLabel}>
        {children}
      </DrawerContent>
    </Modal>
  );
};

const DrawerBackdrop = forwardRef<'div', {}>((props, ref) => {
  const { setVisibilityForKey } = useModalContext();

  const handleOnBackdropFrame = useCallback(
    (styles: { opacity: number }) => {
      setVisibilityForKey('backdrop', styles.opacity > 0.08);
    },
    [setVisibilityForKey]
  );

  return <ModalBackdrop duration={140} onFrame={handleOnBackdropFrame} />;
});

const DrawerContent = forwardRef<'div', Pick<DrawerPropsWithDefaults, 'children' | 'anchor' | 'size' | 'overrides' | 'rootAriaLabel'>>((props, ref) => {
  const { overrides = {}, anchor, children, size, rootAriaLabel } = props;
  const {
    Root: [Root, rootProps],
    Content: [Content, contentProps]
  } = useOverrides({ Root: StyledRoot, ContentContainer: StyledContentContainer, Content: StyledContent }, overrides);
  const { getDialogProps, getDialogRootProps, isOpen, setVisibilityForKey } = useModalContext();

  const styles = useModalStyles();

  const sharedProps: SharedDrawerProps = {
    $anchor: anchor,
    $size: size,
    $isOpen: isOpen
  };

  const springs = useSpring({
    ...getTransitionStates(sharedProps),
    config: {
      clamp: true
    },
    onFrame: (styles: { opacity: number }) => {
      setVisibilityForKey('content', styles.opacity > 0.015);
    }
  }) as AnimatedValue<{ opacity: number; transform: string }>;

  const contentStyles: StyleSystemProps = {
    ...styles.content,
    ...getSizeStyles(sharedProps),
    ...getAnchorOriginStyles(sharedProps)
  };

  return (
    <Root __css={styles.dialogRoot} aria-label={rootAriaLabel} {...getDialogRootProps(rootProps)}>
      <ModalFocusLock>
        <Content as={animated.div} __css={contentStyles} style={springs} {...getDialogProps(contentProps, ref)}>
          {children}
        </Content>
      </ModalFocusLock>
    </Root>
  );
});

const DrawerCloseButton = forwardStyledComponentRef<'button', CloseButtonProps>((props, ref) => {
  const { t } = useTranslation('joykit');
  return <ModalCloseButton aria-label={t('drawer', 'closeButtonLabel')()} {...props} ref={ref} />;
});

const DrawerFooter = forwardRef<'footer', ModalFooterProps>((props, ref) => {
  const styles = useModalStyles();
  return <StyledFooter as="footer" ref={ref} __css={styles.footer} {...props} />;
});

DrawerV2.defaultProps = defaultProps;
DrawerV2.CloseButton = DrawerCloseButton;
DrawerV2.Body = ModalBody;
DrawerV2.Header = ModalHeader;
DrawerV2.Footer = DrawerFooter;

DrawerV2.displayName = createDisplayName('DrawerV2');

export { DrawerV2 };
