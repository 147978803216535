import { pxToRem } from '@withjoy/joykit/theme';
import { StyleSystemProps, createGlobalStyle, TextV2, styled } from '@withjoy/joykit';

export const RemoveScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const StyledTitle = styled(TextV2)`
  margin-left: 0.24rem;
  margin-right: 0.24rem;
  word-break: break-word;
`;

export const HotelName = styled(TextV2)`
  width: 100%;
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const mapSizeVariants = (screenHeight: number, isBrannanLayout?: boolean): { fullscreen: StyleSystemProps; normalscreen: StyleSystemProps } => ({
  fullscreen: {
    top: 0,
    right: 0,
    zIndex: 1101,
    width: '100vw',
    position: 'fixed',
    height: 'var(--full-screen-height)'
  },
  normalscreen: {
    width: '100%',
    position: 'relative',
    height: isBrannanLayout ? { _: pxToRem(300), sm2: pxToRem(screenHeight - 64), sm4: pxToRem(screenHeight - 64) } : { _: pxToRem(273), sm2: pxToRem(396), sm4: pxToRem(329) }
  }
});
