import React from 'react';
import { useTranslation } from '@shared/core';
import { pxToRem } from '@withjoy/joykit/theme';
import { Flex, TextV2, Box } from '@withjoy/joykit';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { animationTransition } from '@shared/utils/animationTransition';
import { CloseThick, HotelBed, StarFilled } from '@withjoy/joykit/icons';
import { PointsOnMapFragment } from '@graphql/generated';
import { createDateFromUnformattedString } from '@shared/hooks/useDateUtils';
import { MAP_CARDS_PORTAL_ID, MapPinWithCard } from '@shared/components/JoyInteractableMap';

import { HotelName } from './AccommodationsMap.styles';
import { AccommodationCombined, AccommodationCombinedType } from '../Accommodations/Accommodation.types';

const DEFAULT_HOTEL_IMAGE = 'https://withjoy.com/assets/public/hotelselection/empty-hotel-item.png';

interface AccommodationsMapCardProps {
  destination?: PointsOnMapFragment;
  onSelectHotel: (id: string) => void;
  handleHotelPinSelected: (hotel: AccommodationCombined) => void;
  handleHotelPinCardSelected: (e: React.MouseEvent, hotel: AccommodationCombined) => void;
  setIsFullScreen: (fullscreen: boolean) => void;
  setActiveMarker: React.Dispatch<React.SetStateAction<string | null>>;
  isFullScreen: boolean;
  activeMarker?: string | null;
  isBrannanLayout?: boolean;
  hotel: AccommodationCombined;
  locationBeingHovered?: string;
}

const AccommodationsMapCard: React.FC<AccommodationsMapCardProps> = ({
  hotel,
  destination,
  isFullScreen,
  activeMarker,
  isBrannanLayout,
  onSelectHotel,
  setIsFullScreen,
  setActiveMarker,
  handleHotelPinSelected,
  handleHotelPinCardSelected,
  locationBeingHovered
}) => {
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));

  const isCardVisible = activeMarker === hotel.id;
  const isHighlighted = isCardVisible || hotel.id === locationBeingHovered;

  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  const latlng = { lat: destination?.latitude ?? 0, lng: destination?.longitude ?? 0 };

  return (
    <>
      <MapPinWithCard
        usePortal={isMobile}
        portalContainer={document.getElementById(MAP_CARDS_PORTAL_ID) as HTMLElement}
        cardStyleOverride={{
          bottom: {
            _: isBrannanLayout ? 7 : 6,
            sm2: 10
          },
          borderRadius: 3,
          boxShadow: '0px 4px 16px 0px #00000012, 0px 7px 27px 0px #2C29250F',
          width: {
            _: '100%',
            sm2: 'max-content'
          },
          maxWidth: {
            _: '100%',
            sm: 259
          },
          flexDirection: {
            _: 'row',
            sm2: 'column'
          }
        }}
        key={hotel.id}
        position={{ lat: hotel.latitude || 0, lng: hotel.longitude || 0 }}
        isActive={isHighlighted}
        showOnTop={isHighlighted}
        isCardVisible={isCardVisible}
        onClick={e => {
          e.stopPropagation();
          handleHotelPinSelected(hotel);
          if (isFullScreen) {
            setActiveMarker(prev => (prev === hotel.id ? null : hotel.id!));
          } else {
            onSelectHotel(hotel.id);
          }
        }}
        onDoubleClick={e => {
          e.stopPropagation();
        }}
        label={
          <Flex justifyContent="center" alignItems="center" columnGap={2}>
            <HotelBed size="sm" />
            {hotel.type === AccommodationCombinedType.Roomblock && (
              <TextV2 typographyVariant="label3" fontSize={pxToRem(13)} fontWeight={600} fontFamily="Inter UI">
                ${hotel.pricePerNight}
              </TextV2>
            )}
          </Flex>
        }
      >
        <Box
          width={{ _: pxToRem(104), sm2: pxToRem(259) }}
          height={{ _: pxToRem(140), sm2: pxToRem(167) }}
          cursor={'pointer'}
          flexShrink={0}
          objectFit={'contain'}
          backgroundSize={'cover'}
          backgroundPosition={'center'}
          backgroundRepeat={'no-repeat'}
          backgroundImage={`url(${hotel?.photo?.url || DEFAULT_HOTEL_IMAGE})`}
          transition={animationTransition('opacity')}
          onClick={e => {
            e.stopPropagation();
            if (activeMarker) {
              setIsFullScreen(!isFullScreen);
              setActiveMarker(null);
              setTimeout(() => onSelectHotel(activeMarker), 100);
            }
          }}
        />
        <Flex
          width={pxToRem(32)}
          height={pxToRem(32)}
          cursor="pointer"
          padding={3}
          position="absolute"
          alignItems="center"
          justifyContent="center"
          left={{ _: 3, sm2: 'auto' }}
          right={{ _: 'auto', sm2: 3 }}
          top={3}
          backgroundColor="rgba(255, 255, 255, 0.5)"
          borderRadius="50%"
          onClick={e => {
            e.stopPropagation();
            if (activeMarker) {
              setActiveMarker(null);
            }
          }}
        >
          <CloseThick size="sm" />
        </Flex>
        <Flex
          flexDirection={'column'}
          rowGap={5}
          flexGrow={1}
          paddingX={5}
          paddingY={{ _: 4, sm2: 5 }}
          cursor={'pointer'}
          height={{ _: pxToRem(140), sm2: 'auto' }}
          onClick={e => {
            if (activeMarker) {
              handleHotelPinCardSelected(e, hotel);
              setIsFullScreen(!isFullScreen);
              setActiveMarker(null);
              setTimeout(() => onSelectHotel(activeMarker), 100);
            }
          }}
        >
          <Flex flexDirection="column">
            <HotelName typographyVariant={{ _: 'hed1', sm2: 'hed2' }} marginBottom={{ _: pxToRem(2), sm2: pxToRem(6) }} fontFamily={{ _: 'Inter UI', sm2: 'Inter UI' }}>
              {hotel.displayName}
            </HotelName>
            <TextV2 typographyVariant={{ _: 'label2', sm2: 'body1' }} color="mono12" marginBottom={pxToRem(2)} fontFamily={{ _: 'Inter UI', sm2: 'Inter UI' }}>
              {accTrans.milesAway({ miles: distanceInMiles(latlng.lat, latlng.lng, hotel.latitude ?? 0, hotel.longitude ?? 0).toFixed(1) })}
            </TextV2>
            {hotel.cutoffDate && (
              <TextV2 typographyVariant={{ _: 'label2', sm2: 'body1' }} color="mono12" fontFamily={{ _: 'Inter UI', sm2: 'Inter UI' }}>
                {accTrans.beforeDate({
                  date: createDateFromUnformattedString(hotel.cutoffDate).toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
                })}
              </TextV2>
            )}
          </Flex>
          <Flex justifyContent={'space-between'} alignItems={'center'} paddingTop={'2px'}>
            {hotel.type === AccommodationCombinedType.Roomblock && (
              <TextV2
                typographyVariant={{ _: 'hed2', sm2: 'button1' }}
                fontSize={{ _: pxToRem(13), sm2: pxToRem(15) }}
                fontWeight={600}
                fontFamily={{ _: 'Inter UI', sm2: 'Inter UI' }}
              >
                {accTrans.fromPrice({ strikeoutPricePerNight: hotel.strikeoutPricePerNight })}
              </TextV2>
            )}

            <Flex alignItems={'center'} columnGap={'2px'}>
              <StarFilled size="sm" height={{ sm2: `${pxToRem(19)} !important` }} width={{ _: pxToRem(16), sm2: `${pxToRem(19)} !important` }} />
              <TextV2 typographyVariant={{ _: 'label2', sm2: 'body1' }}>{hotel.starRating}</TextV2>
            </Flex>
          </Flex>
        </Flex>
      </MapPinWithCard>
    </>
  );
};

AccommodationsMapCard.displayName = 'AccommodationsMapCard';

export { AccommodationsMapCard };
