import { ResponsiveMarginProps } from '@withjoy/joykit/styled-system';
import React from 'react';
import { PrettyUrlOptions } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';
import { StyledRoot } from './PrettyLink.styles';

export interface PrettyLinkProps extends ResponsiveMarginProps {
  readonly tagName?: 'span' | 'p' | 'div';

  readonly children: Maybe<string>;

  readonly prettyUrlOptions?: PrettyUrlOptions;

  readonly source?: string;
}

const PrettyLink: React.FC<PrettyLinkProps> = ({ children, tagName = 'p', ...restProps }) => {
  return (
    <StyledRoot tagName={tagName} {...restProps}>
      {children}
    </StyledRoot>
  );
};

PrettyLink.displayName = 'PrettyLink';

export { PrettyLink };
