import { Box, ButtonV2, CloseButton, ModalProps, styled } from '@withjoy/joykit';

export const StyledBody = styled(Box)`
  display: flex;
  height: 100%;
  z-index: 1;
`;

export const StyledHeader = styled(Box)`
  background-color: transparent;
  display: flex;
  padding: 16px;
  height: 80px;
  align-self: stretch;
  z-index: 1;
`;

export const StyledFooter = styled(Box)`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  height: 80px;
  align-self: stretch;
  z-index: 1;
`;

export const StyledCloseButton = styled(CloseButton)`
  position: static;
  background: white;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.mono14};
  &:hover {
    color: ${({ theme }) => theme.colors.mono14};
  }
`;

export const StyledBackButton = styled(ButtonV2)`
  background-color: white;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.mono14};
  &:hover {
    color: ${({ theme }) => theme.colors.mono14};
  }
`;

export const StyledDialogToastWrapper = styled.div`
  > div {
    top: 80px;
    width: 100%;
    margin: 0;
    border-radius: 0;
    z-index: 100;
  }
`;

export const StyledBackground = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const dialogOverrides: NonNullable<ModalProps['overrides']> = {
  Root: {
    props: {
      backgroundColor: 'rgb(0, 0, 0, 0.4)',
      overflow: 'auto'
    }
  },
  Content: {
    props: {
      borderRadius: [0, '32px'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: ['0 0 120px', '0'],
      margin: ['inherit', 'auto'],
      overflow: 'hidden',
      zIndex: 1,
      position: 'relative'
    }
  }
};
