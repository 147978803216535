import { useContext, useMemo, useRef } from 'react';
import { EventPageType, PhotoXPosition, PhotoYPosition } from '@graphql/generated';
import { AuxFrameContext } from '../../AuxFrame';
import { useMediaQuery } from '@withjoy/joykit/utils';

export const useFocusPointController = () => {
  const isMobileScreen = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isTabletScreen = useMediaQuery(theme => theme.mediaQueries.between({ breakpointAlias: 'sm2' }, { breakpointAlias: 'md' }));

  const { eventId, page, pageId, photo, previewFocusPoint, reflectPhotoChange, pages } = useContext(AuxFrameContext);

  const dialogRef = useRef<HTMLDivElement>(null);

  const prevFocusPoint: [PhotoXPosition, PhotoYPosition] = useMemo(() => {
    const pageInfo = pages.find(p => p.type === page);
    const welcomePageInfo = pages.find(p => p.type === EventPageType.welcome);
    // welcome page photo alignament as fallback, if welcome page photo is not available then use center alignment
    return [
      (pageInfo?.photo?.layout.alignX as PhotoXPosition) || welcomePageInfo?.photo?.layout.alignX || PhotoXPosition.center,
      (pageInfo?.photo?.layout.alignY as PhotoYPosition) || welcomePageInfo?.photo?.layout.alignY || PhotoYPosition.center
    ];
  }, [pages, page]);

  const resetFocusPoint = async () => {
    previewFocusPoint(prevFocusPoint[0], prevFocusPoint[1]);
  };

  const previewPosition = (x: PhotoXPosition, y: PhotoYPosition) => previewFocusPoint(x, y);

  return {
    dialogRef,
    isMobileScreen,
    isTabletScreen,
    page,
    pageId,
    photo,
    previewPosition,
    resetFocusPoint,
    eventId,
    reflectPhotoChange
  };
};
