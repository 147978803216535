import { animationTransition } from '@shared/utils/animationTransition';
import { Box, defaultTheme, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { MAP_CARD_POSITIONS } from './MapPin';

export const CARD_DIMENSIONS = { width: 259, height: 324 };
export const CARD_DIMENSIONS_SAFE = { width: 340, height: 420 };
export const MARKER_DIMENSIONS = { width: 75, height: 50 };

export const StyledMapPinLabelContainer = styled(Box)``;
const joyMapPinLabelContainerStyle = ({
  isActive,
  isClickable,
  isCardVisible,
  showOnTop
}: {
  isActive?: boolean;
  isClickable?: boolean;
  isCardVisible?: boolean;
  showOnTop?: boolean;
}): StyleSystemProps => ({
  zIndex: showOnTop ? 'popover' : 'auto',
  position: 'relative',
  cursor: 'default',
  transition: animationTransition('transform'),
  _hover: {
    ...((isClickable || isActive) && {
      cursor: 'pointer',
      transform: 'scale(1.1)',
      zIndex: 'popover'
    })
  },
  ...((isActive || isCardVisible) && { transform: 'scale(1.1)' })
});

export const StyledMapPinLabelTipShadow = styled(Box)``;
const joyMapPinLabelTipShadowStyle: StyleSystemProps = {
  width: 0,
  height: 0,
  border: '12px solid',
  borderColor: 'white transparent transparent transparent',
  filter: 'drop-shadow(0 1px 5px rgba(0, 0, 0, 0.5))',
  position: 'absolute',
  left: '0',
  bottom: '-12px',
  transform: 'translateX(-50%)'
};

export const StyledMapPinLabel = styled(Flex)``;
const joyMapPinLabelStyle = ({ isActive, isClickable, isCardVisible }: { isActive?: boolean; isClickable?: boolean; isCardVisible?: boolean }): StyleSystemProps => ({
  alignItems: 'center',
  paddingX: 4,
  height: 7,
  color: isActive || isCardVisible ? 'white' : 'typePrimaryDark',
  backgroundColor: isActive || isCardVisible ? 'mono14' : 'white',
  borderRadius: 4,
  width: 'auto',
  position: 'absolute',
  boxShadow: '0 1px 5px -1px rgba(0, 0, 0, 0.5)',
  transform: 'translate(-50%, -40px)',
  whiteSpace: 'nowrap',
  transition: animationTransition('background-color', 'color'),
  _groupHover: {
    ...(isClickable &&
      !isCardVisible &&
      !isActive && {
        backgroundColor: 'fillLightHover'
      })
  }
});

export const StyledMapPinLabelTip = styled(Box)``;
const joyMapPinLabelTipStyle = ({ isActive, isClickable, isCardVisible }: { isActive?: boolean; isClickable?: boolean; isCardVisible?: boolean }): StyleSystemProps => ({
  width: 0,
  height: 0,
  border: '12px solid',
  borderColor: isActive || isCardVisible ? `${defaultTheme.colors.mono14} transparent transparent transparent` : 'white transparent transparent transparent',
  position: 'absolute',
  left: '0',
  bottom: '-12px',
  transform: 'translateX(-50%)',
  transition: animationTransition('border-color'),
  _groupHover: {
    ...(isClickable &&
      !isCardVisible &&
      !isActive && {
        borderColor: `${defaultTheme.colors.fillLightHover} transparent transparent transparent`
      })
  }
});

const _createCardStyle = (usePortal?: boolean, cardPosition?: typeof MAP_CARD_POSITIONS[keyof typeof MAP_CARD_POSITIONS], isCardVisible?: boolean) => {
  if (usePortal) {
    return {
      bottom: 'unset',
      transform: 'unset'
    };
  }

  switch (cardPosition) {
    case MAP_CARD_POSITIONS.TOP_CENTER:
      return {
        left: `calc(50% - ${CARD_DIMENSIONS.width / 2}px)`,
        bottom: isCardVisible ? 60 : 20
      };
    case MAP_CARD_POSITIONS.TOP_RIGHT:
      return {
        left: -(MARKER_DIMENSIONS.width / 2),
        bottom: isCardVisible ? 60 : 20
      };
    case MAP_CARD_POSITIONS.TOP_LEFT:
      return {
        left: -(CARD_DIMENSIONS.width - MARKER_DIMENSIONS.width / 2),
        bottom: isCardVisible ? 60 : 20
      };
    case MAP_CARD_POSITIONS.BOTTOM_CENTER:
      return {
        left: `calc(50% - ${CARD_DIMENSIONS.width / 2}px)`,
        bottom: isCardVisible ? -(CARD_DIMENSIONS.height + 20) : -(CARD_DIMENSIONS.height - 20)
      };
    case MAP_CARD_POSITIONS.BOTTOM_RIGHT:
      return {
        left: -(MARKER_DIMENSIONS.width / 2),
        bottom: isCardVisible ? -(CARD_DIMENSIONS.height + 20) : -(CARD_DIMENSIONS.height - 20)
      };
    case MAP_CARD_POSITIONS.BOTTOM_LEFT:
      return {
        left: -(CARD_DIMENSIONS.width - MARKER_DIMENSIONS.width / 2),
        bottom: isCardVisible ? -(CARD_DIMENSIONS.height + 20) : -(CARD_DIMENSIONS.height - 20)
      };
    default:
      return {};
  }
};

export const StyledMapPinCard = styled(Flex)<{ isCardVisible?: boolean }>`
  & * {
    pointer-events: ${props => (props.isCardVisible ? 'auto' : 'none')};
  }
`;
const joyMapPinCardStyle = ({
  isCardVisible,
  cardStyleOverride,
  usePortal,
  cardPosition
}: {
  isCardVisible?: boolean;
  cardStyleOverride?: StyleSystemProps;
  usePortal?: boolean;
  cardPosition?: typeof MAP_CARD_POSITIONS[keyof typeof MAP_CARD_POSITIONS];
}): StyleSystemProps => {
  const cardStyle = _createCardStyle(usePortal, cardPosition, isCardVisible);

  return {
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: 'white',
    width: CARD_DIMENSIONS.width,
    borderRadius: 12,
    boxShadow: '0 1px 5px -1px rgba(0, 0, 0, 0.5)',
    overflow: 'hidden',
    opacity: isCardVisible ? 1 : 0,
    transition: animationTransition('opacity', 'bottom', 'top', 'left', 'right', 'transform'),
    zIndex: 'tooltip',
    pointerEvents: isCardVisible ? 'auto' : 'none',
    ...cardStyle,
    ...(cardStyleOverride || {})
  };
};

export const styles = {
  joyMapPinLabelContainer: joyMapPinLabelContainerStyle,
  joyMapPinLabelTipShadow: joyMapPinLabelTipShadowStyle,
  joyMapPinLabel: joyMapPinLabelStyle,
  joyMapPinLabelTip: joyMapPinLabelTipStyle,
  joyMapPinCard: joyMapPinCardStyle
};
