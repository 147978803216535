import { SharedPopoverProps } from './sharedPopoverProps';
import { Modifiers } from 'popper.js';
import { BoxProps } from '../Box';

export const PopoverInteractionVariant = {
  CLICK: 'click' as 'click',
  CLICK_TARGET: 'click-target' as 'click-target',
  HOVER: 'hover' as 'hover',
  HOVER_TARGET: 'hover-target' as 'hover-target'
};
export type PopoverInteractionVariant = typeof PopoverInteractionVariant[keyof typeof PopoverInteractionVariant];

export const PopoverOffset = {
  NONE: 'none' as 'none',
  SMALL: 'sm' as 'sm',
  MEDIUM: 'md' as 'md',
  LARGE: 'lg' as 'lg'
};
export type PopoverOffset = typeof PopoverOffset[keyof typeof PopoverOffset];

export const PopoverTransition = {
  FADE_SCALE_IN_OUT: 'fadeScaleInOut' as 'fadeScaleInOut',
  FADE_SLIDE_IN_OUT: 'fadeSlideInOut' as 'fadeSlideInOut'
};
export type PopoverTransition = typeof PopoverTransition[keyof typeof PopoverTransition];

export interface PopoverProps extends SharedPopoverProps {
  /**
   * The content to be displayed. This can also be provided as the second element in `children`.
   * The first element is considered the target.
   *
   * When provided, this takes precedence over content passed via children.
   */
  content?: string | JSX.Element;

  children?: React.ReactNode;

  elevation?: 0 | 1;

  interactionVariant?: PopoverInteractionVariant;

  /**
   * Whether the Popover should have minimal styles - limited animations/transitions.
   */
  minimal?: boolean;

  modifiers?: Modifiers;

  offset?: PopoverOffset;

  /**
   * Whether the Popover should not automatically reposition itself for optimal viewing.
   *
   * @default false
   */
  pinned?: boolean;

  popoverArrowClassName?: string;

  /**
   * Whether or not to show the arrow pointing frmo the popover to the trigger
   *
   * @default false
   */
  showArrow?: boolean;

  /**
   * Transition effet to be applied.
   *
   * @default 'fadeScaleInOut'
   */
  transitionName?: PopoverTransition;
  transitionElProps?: BoxProps;
  contentWrapperProps?: BoxProps;
  contentProps?: BoxProps & { ref?: React.RefObject<HTMLDivElement> };
}

export type PopoverState = {
  isOpen: boolean;
  transformOrigin: React.CSSProperties['transformOrigin'];
};
