import { styled, Box, ColumnsV2, Flex } from '@withjoy/joykit';

export const StyledItemsContainer = styled(Flex)`
  gap: 8px;
`;

export const StyledColumns = styled(ColumnsV2)`
  gap: 8px;
  margin: auto;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const StyledColumnsV2Column = styled(ColumnsV2.Column)`
  max-width: 150px;
  padding: 0px;
  margin-top: 0px;
`;

export const StyledItemAvatar = styled(Box)`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-origin: border-box;
  mix-blend-mode: multiply;
`;
