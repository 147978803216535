import React from 'react';
import SearchBar from './components/SearchBar';
import { ResultsTable } from '@apps/serviceCenter/routes/Messages/components/ResultsTable';
import { TableActions } from '@apps/serviceCenter/routes/Messages/components/TableActions';
import { Box, ButtonV2 } from '@withjoy/joykit';
import { ArrowLeft } from '@withjoy/joykit/icons';
import { Link, useRouterHelper } from '@shared/core';
import { routePaths } from '@apps/serviceCenter/route.paths';

export const Messages = () => {
  const routeHelpers = useRouterHelper();
  return (
    <>
      <Box as="main" paddingX={'2.5rem'}>
        <Box display="grid" rowGap={'1.5rem'} maxWidth={'1440px'} marginX={'auto'} marginTop={10}>
          <ButtonV2 as={Link} to={routeHelpers.buildPath(routePaths.servicecenter.path)} variant="link" fullWidth={false} justifySelf={'self-start'}>
            <ArrowLeft size="md" marginRight={'0.5rem'} /> Back to Service Center
          </ButtonV2>
          <SearchBar />
          <TableActions />
        </Box>
      </Box>
      <ResultsTable />
    </>
  );
};
