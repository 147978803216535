import { darkAlphaBezier } from '@shared/utils/style/beziers';
import { Box, styled } from '@withjoy/joykit';
import { reversePadding } from './AuxFramTitle.utils';

export const AuxTitleContainer = styled(Box)<{ y: 'top' | 'middle' | 'bottom'; x: 'left' | 'middle' | 'right' }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: ${props => reversePadding('10% 20% 11% 10%;', { x: 'left', y: 'bottom' }, { x: props.x, y: props.y })};
  background: ${props =>
    props.y === 'middle'
      ? `linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%)`
      : darkAlphaBezier({
          coordinates: [0.47, 0.85, 0.77, 1],
          deg: props.y === 'bottom' ? 0 : 180,
          reverse: true,
          steps: 10
        })};
  width: 100%;
  transform: ${props => (props.y === 'middle' ? `translateY(-50%)` : undefined)};
  height: ${props => (props.y === 'middle' ? '100%' : '75%')};
  justify-content: ${props => (props.y === 'middle' ? 'center' : props.y === 'top' ? 'flex-start' : 'flex-end')};
  top: ${props => (props.y === 'top' ? 0 : props.y === 'middle' ? '50%' : undefined)};
  bottom: ${props => (props.y === 'bottom' ? 0 : undefined)};
  align-items: ${props => (props.x === 'left' ? 'flex-start' : props.x === 'middle' ? 'center' : 'flex-end')};
`;
