/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Edit',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4396 2.38895C12.8733 1.82269 11.9552 1.82269 11.389 2.38895L10.8588 2.91916L13.0809 5.14135L13.6112 4.61114C14.1774 4.04488 14.1774 3.12679 13.6111 2.56053L13.4396 2.38895ZM12.3031 5.91916L10.0809 3.69698L3.31214 10.4658C3.26024 10.5177 3.22183 10.5815 3.20024 10.6516L2.24547 13.7546L5.34847 12.7999C5.41862 12.7783 5.48242 12.7399 5.53432 12.688L12.3031 5.91916ZM10.6112 1.61114C11.607 0.615298 13.2216 0.615299 14.2174 1.61114L14.389 1.78271C15.3848 2.77854 15.3848 4.39312 14.389 5.38895L6.31214 13.4658C6.13337 13.6445 5.91359 13.7769 5.67196 13.8512L1.57602 15.1115C1.3813 15.1714 1.16942 15.1188 1.02536 14.9747C0.881308 14.8307 0.828681 14.6188 0.888594 14.4241L2.14888 10.3281C2.22323 10.0865 2.35556 9.86673 2.53433 9.68796L10.6112 1.61114Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4042 2.9245C18.8574 2.37777 17.971 2.37777 17.4243 2.9245L15.7628 4.58603L19.4142 8.2375L21.0757 6.57598C21.6225 6.02924 21.6225 5.14281 21.0757 4.59608L19.4042 2.9245ZM18.5657 9.08603L14.9142 5.43455L4.51412 15.8347C4.46798 15.8808 4.43383 15.9375 4.41465 15.9999L2.82099 21.1793L8.00038 19.5856C8.06274 19.5664 8.11946 19.5323 8.16559 19.4861L18.5657 9.08603ZM16.5757 2.07598C17.5911 1.06061 19.2373 1.06061 20.2527 2.07598L21.9243 3.74755C22.9396 4.76291 22.9396 6.40914 21.9243 7.4245L9.01412 20.3347C8.82958 20.5192 8.60271 20.6558 8.35328 20.7325L2.09068 22.6595C1.87826 22.7249 1.64711 22.6674 1.48996 22.5103C1.33281 22.3531 1.2754 22.122 1.34076 21.9096L3.26771 15.647C3.34446 15.3975 3.48106 15.1707 3.66559 14.9861L16.5757 2.07598Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
