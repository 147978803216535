/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Copy',
  definitions: {
    '16': {
      viewBox: '0 0 13 14',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.46162 11.3404V11.9447C3.46162 13.0493 4.35705 13.9447 5.46162 13.9447L10.1425 13.9447C11.2471 13.9447 12.1425 13.0493 12.1425 11.9447L12.1425 4.65961C12.1425 3.55504 11.2471 2.65961 10.1425 2.65961L9.53829 2.65961V2.0553C9.53829 0.950729 8.64286 0.0552984 7.53829 0.0552983L2.85742 0.0552979C1.75285 0.0552978 0.857423 0.950729 0.857423 2.0553L0.857422 9.34042C0.857422 10.445 1.75285 11.3404 2.85742 11.3404H3.46162ZM11.0425 11.9447L11.0425 4.65961C11.0425 4.16255 10.6395 3.75961 10.1425 3.75961L9.53829 3.75961L9.53829 9.34042C9.53829 10.445 8.64286 11.3404 7.53829 11.3404C6.96899 11.3404 2.74028 11.3405 4.56162 11.3405V11.9447C4.56162 12.4418 4.96456 12.8447 5.46162 12.8447L10.1425 12.8447C10.6395 12.8447 11.0425 12.4418 11.0425 11.9447ZM8.43829 2.0553L8.43829 9.34042C8.43829 9.83748 8.03535 10.2404 7.53829 10.2404L2.85742 10.2404C2.36037 10.2404 1.95742 9.83748 1.95742 9.34042L1.95742 2.0553C1.95742 1.55824 2.36037 1.1553 2.85742 1.1553L7.53829 1.1553C8.03535 1.1553 8.43829 1.55824 8.43829 2.0553Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M19.3381 8.43218V19.5078C19.3381 20.5019 18.5322 21.3078 17.5381 21.3078L10.403 21.3078C9.4089 21.3078 8.60302 20.5019 8.60302 19.5078V18.5673L7.40302 18.5673V19.5078C7.40302 21.1647 8.74616 22.5078 10.403 22.5078L17.5381 22.5078C19.195 22.5078 20.5381 21.1647 20.5381 19.5078L20.5381 8.43218C20.5381 6.77533 19.195 5.43218 17.5381 5.43218H16.5975L16.5975 6.63218H17.5381C18.5322 6.63218 19.3381 7.43807 19.3381 8.43218Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5975 18.5673C15.2544 18.5673 16.5975 17.2241 16.5975 15.5673L16.5975 4.49163C16.5975 2.83478 15.2544 1.49163 13.5975 1.49163H6.4624C4.80555 1.49163 3.4624 2.83478 3.4624 4.49163L3.4624 15.5673C3.4624 17.2241 4.80555 18.5673 6.4624 18.5673L13.5975 18.5673ZM15.3975 15.5673L15.3975 4.49163C15.3975 3.49752 14.5916 2.69163 13.5975 2.69163H6.4624C5.46829 2.69163 4.6624 3.49752 4.6624 4.49163L4.6624 15.5673C4.6624 16.5614 5.46829 17.3673 6.4624 17.3673H13.5975C14.5916 17.3673 15.3975 16.5614 15.3975 15.5673Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
