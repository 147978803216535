/* istanbul ignore file */

import { EventPageFragment } from '@graphql/generated';
// import { Box } from '@withjoy/joykit';
import { Flex, TextV2 } from '@withjoy/joykit';
import React, { useEffect, useRef, useMemo } from 'react';
import { useImmer } from 'use-immer';
import { NavLink as RouterLink } from '@react-router';
import { isSupportedEventPage } from '../../../layout.utils';
import { StyledLegacyNavLinkContainer } from './NavBar.styles';
import EventMenu from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/components/EventMenu/EventMenu';
import { NavbarEventPropsInterface } from '../../../layout.types';
import { formatPagesForBrannan } from './NavBar.utils';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventPageRoute } from '@apps/guest/routes/GuestSite/GuestSite.routes';

export interface NavBarProps
  extends Readonly<{
    eventHandle: string;
    pages: ReadonlyArray<EventPageFragment>;
    variant: 'inline' | 'menu';
    title?: Maybe<string>;
    isCustomPage?: boolean;
    hasBanner?: boolean;
    handleMenuClicked?: (item: string) => void;
  }> {}

const LegacyNavLink: React.FC<{ to: string; isRsvp: boolean; onClick: () => void }> = ({ to, isRsvp, children, onClick, ...rest }) => {
  return (
    <StyledLegacyNavLinkContainer height={34} _empty={{ display: 'none' }}>
      {!isRsvp ? (
        <RouterLink onClick={onClick} data-testid={(rest as Record<string, string>)['data-testid']} to={to}>
          {children}
        </RouterLink>
      ) : (
        <a href={to}>{children}</a>
      )}
    </StyledLegacyNavLinkContainer>
  );
};

const OVERFLOW_BOUND = 860;

type OverflowState = { maxWidth: number | undefined; hasOverflow: boolean };

const LegacyBrannanOverflowNav: React.FC<Omit<NavBarProps, 'variant' | 'title'>> = ({ pages, eventHandle, handleMenuClicked, ...restProps }) => {
  const containerRef = useRef<HTMLUListElement | null>(null);
  const [state, setState] = useImmer<OverflowState>({
    maxWidth: undefined,
    hasOverflow: false
  });
  const eventPageRoute = useEventPageRoute(eventHandle);

  const isPreviewing = isInIframe();

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const _checkOverflow = () => {
        if (container.childElementCount > 0) {
          const results = Array.from(container.children).reduce(
            (acc, child, idx) => {
              if (!acc.hasOverflow) {
                const width = child.getBoundingClientRect().width + 32;
                const hasOverflow = acc.runningWidth + width >= OVERFLOW_BOUND;
                if (!hasOverflow) {
                  acc.runningWidth += width;
                  acc.lastIndex = idx;
                }
                acc.hasOverflow = hasOverflow;
              }

              return acc;
            },
            { runningWidth: 0, lastIndex: 0, hasOverflow: false } as { runningWidth: number; lastIndex: number; hasOverflow: boolean }
          );

          // The last child's offset top will be larger when overflow
          setState(draft => {
            draft.hasOverflow = results.hasOverflow;
            draft.maxWidth = results.hasOverflow ? results.runningWidth : undefined;
          });
        } else {
          setState(draft => {
            draft.hasOverflow = false;
            draft.maxWidth = undefined;
          });
        }
      };

      _checkOverflow();
      const observer = new MutationObserver(mutations => {
        _checkOverflow();
      });
      observer.observe(container, { childList: true, subtree: true, characterData: true });
    }
  }, [pages, setState]);

  return (
    <Flex as="nav" {...restProps} justifyContent="center" marginTop={8} pointerEvents={isPreviewing ? 'all' : 'auto'}>
      <Flex as="ul" flexWrap={'wrap'} maxWidth={state.hasOverflow ? '90%' : OVERFLOW_BOUND} justifyContent="center" ref={containerRef}>
        {pages.map(page => {
          if (!isSupportedEventPage(page.type)) {
            return null;
          }

          const isRsvp = page.pageSlug === 'rsvp';

          return (
            <LegacyNavLink
              onClick={() => handleMenuClicked && handleMenuClicked(page.pageSlug)}
              key={page.id}
              data-testid={`nav-${page.id}`}
              isRsvp={isRsvp}
              to={eventPageRoute.goToPath(page.type, page.pageSlug)}
            >
              <TextV2 typographyVariant="body1" paddingX={5} paddingY={4}>
                {page.pageTitle}
              </TextV2>
            </LegacyNavLink>
          );
        })}
      </Flex>
    </Flex>
  );
};

const NavBar: React.FC<NavBarProps & { eventProps: NavbarEventPropsInterface }> = ({
  eventProps,
  eventHandle,
  pages: origPages,
  variant,
  title,
  isCustomPage,
  hasBanner,
  handleMenuClicked,
  ...restProps
}) => {
  const isVariantMenu = variant === 'menu';
  const isVariantInline = variant === 'inline';
  const pages = useMemo(() => formatPagesForBrannan(origPages), [origPages]);

  const eventPropsFormatted = useMemo(
    () => ({
      ...eventProps,
      pages: formatPagesForBrannan(eventProps.pages)
    }),
    [eventProps]
  );

  return (
    <>
      {isVariantMenu && (
        <EventMenu
          handleMenuClicked={handleMenuClicked}
          eventHandle={eventHandle}
          title={title}
          eventProps={eventPropsFormatted}
          isCustomPage={isCustomPage}
          isBrannanLayout
          hasBanner={hasBanner}
        />
      )}
      {isVariantInline && <LegacyBrannanOverflowNav handleMenuClicked={handleMenuClicked} pages={pages} eventHandle={eventHandle} {...restProps} />}
    </>
  );
};

NavBar.displayName = 'NavBar';

export { NavBar };
