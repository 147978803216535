import React, { SyntheticEvent } from 'react';
import { Portal } from 'react-portal';
import { Backdrop } from './Backdrop';
import { generateComponentDisplayName, safeInvoke } from '../../utils';
import { styled, isKeyboardEscapeClick } from '../../common';
import { OverlayContext, OverlayContextType } from './';
// eslint-disable-next-line no-restricted-imports
import { SimpleInterpolation } from 'styled-components';
import { Props } from '../../common/props';

// const debug = makeDebugger('Overlay');

/**
 * @deprecated
 */
export interface OverlayableProps {
  /**
   * Whether pressing the `esc` should invoke `onClose`.
   */
  canCloseOnEscapeKey?: boolean;

  /**
   * Whether clicking outside the overlay element should invoke `onClose`.
   */
  canCloseOnOutsideClick?: boolean;

  /**
   * Whether a backdrop element rendered behind the overlayed element.
   */
  useBackdrop?: boolean;

  /**
   * Whether the overlay should be wrapped in a portal.
   *
   * Set this prop to `false` on nested overlays to ensure that they are rendered above their parents.
   */
  usePortal?: boolean;

  /**
   * A callback to be invoked when user interaction causes the overlay to close.
   */
  onClose?: (e: SyntheticEvent<HTMLElement>) => void;

  /**
   * Callback fired after the "entering" status is applied.
   */
  onEntering?: () => void;

  /**
   * A callback to be invoked when the open transition ends and the element has been added to the DOM.
   */
  onEntered?: () => void;

  /**
   * Callback fired after the "exiting" status is applied.
   */
  onExiting?: () => void;
  /**
   * A callback to be invoked when the close transition ends and the element has been removed from the DOM.
   */
  onExited?: () => void;

  /**
   * The container into which the overlay will render. Will be used when `usePortal` is true.
   */
  portalContainer?: HTMLElement | null;
}

export interface OverlayProps extends OverlayableProps, Props {
  overlayKey: string;
  children: React.ReactNode;
}

interface InternalOverlayProps {
  containerElement?: HTMLElement | null;
  isScrollContainer?: boolean;
  containerRef?: (instance: HTMLDivElement | null) => void;
}

interface OverlayState {}

const OverlayWrapper = styled.div<{ isOpen: boolean; overlayStyles: SimpleInterpolation; isScrollContainer?: boolean }>`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${props => props.overlayStyles}
  /*  props => (props.isOpen ? '100%' : undefined)};
  width: ${props => (props.isOpen ? '100%' : undefined)}; */
  z-index: 100;
  ${props => {
    if (!props.isOpen) {
      return {
        pointerEvents: 'none'
        // visibility: 'hidden'
      };
    }

    return null;
  }}

  ${props => {
    if (props.isScrollContainer) {
      return {
        position: 'fixed',
        overflow: 'auto'
      };
    }

    return {
      position: 'static'
    };
  }}
`;

/**
 * -----------------------------------
 * DEPRECATED -- Use `<DialogV2 />` or `<DrawerV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { DialogV2, DrawerV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/DialogV2/Dialog.stories.tsx`.
 */
export class Overlay extends React.Component<OverlayProps & InternalOverlayProps & { isOpen: boolean; overlayStyles?: SimpleInterpolation }, OverlayState, OverlayContextType> {
  static contextType = OverlayContext;
  static displayName = generateComponentDisplayName('Overlay');

  /**
   * Currently active overlay's that are added upon `isOpen` change.
   */
  static overlayStack: Overlay[] = [];

  static defaultProps = {
    usePortal: true,
    useBackdrop: true,
    canCloseOnEscapeKey: true,
    canCloseOnOutsideClick: true,
    portalContainer: typeof document !== 'undefined' ? document.body : null
  };

  state = {};
  containerElement: HTMLDivElement | null;
  context: React.ContextType<typeof OverlayContext>;

  // ==============================================================
  // Lifecycle
  // ==============================================================
  componentDidMount() {
    this.prepare();
  }

  componentDidUpdate() {
    this.toggleBodyScroll();
  }

  componentWillUnmount() {
    this.prepareToClose();
    this.toggleBodyScroll();
  }

  // ==============================================================
  // Callback
  // ==============================================================

  handleDocumentMousedown: EventListener = e => {
    if (!this.isCurrentOverlay()) {
      return;
    }

    const eventTarget = e.target as HTMLElement;

    const shouldKeepOpen = this.getCurrentAndFollowingOverlays().some(({ containerElement, props: { containerElement: targetContainer } }) => {
      // We want to confirm that a click did not occur within a nested child
      const actualTarget = targetContainer || containerElement;
      return actualTarget ? actualTarget.contains(eventTarget) && !actualTarget.isSameNode(eventTarget) : false;
    });

    if (!shouldKeepOpen && this.props.canCloseOnOutsideClick) {
      // debug(`Document click detected - calling close handler`);
      this.close(e);
    }
  };

  handleDocumentKeydown: EventListener = e => {
    if (!isKeyboardEscapeClick((e as any).which) || !this.isCurrentOverlay() || e.defaultPrevented) {
      return;
    }

    e.stopPropagation();

    // debug(`Keydown "escape" key detected - calling close handler.`);
    this.close(e);
  };

  handleBackdropMousedown: React.MouseEventHandler<HTMLDivElement> = e => {
    const { canCloseOnOutsideClick } = this.props;
    if (canCloseOnOutsideClick) {
      this.close(e);
    }
  };

  handleRef = (node: HTMLDivElement | null) => {
    this.containerElement = node;
    safeInvoke(this.props.containerRef, node);
  };

  // ==============================================================
  // Render
  // ==============================================================

  render() {
    const {
      overlayKey,
      isOpen,
      usePortal,
      overlayStyles,
      canCloseOnEscapeKey,
      canCloseOnOutsideClick,
      useBackdrop,
      onClose,
      onExited,
      onEntered,
      onEntering,
      onExiting,
      className,
      ...restProps
    } = this.props;

    this.prepare();

    const content = (
      <OverlayWrapper ref={this.handleRef} className={className} isOpen={isOpen} overlayStyles={overlayStyles} {...restProps}>
        {this.renderBackdrop()}
        {this.renderChildren()}
      </OverlayWrapper>
    );

    if (usePortal) {
      return <Portal node={restProps.portalContainer}>{content}</Portal>;
    }
    return content;
  }

  private renderChildren = () => {
    return this.props.children;
  };

  private renderBackdrop = () => {
    if (this.props.useBackdrop) {
      return <Backdrop key={'overlay'} isOpen={this.props.isOpen} onMouseDown={this.handleBackdropMousedown} />;
    }
    return null;
  };

  // ==============================================================
  // Helpers
  // ==============================================================

  toggleBodyScroll = () => {
    if (!Overlay.overlayStack.some(overlay => overlay.props.isOpen && !!overlay.props.usePortal && !!overlay.props.useBackdrop)) {
      document.body.classList.remove('overlay-open');
    }
  };

  private getCurrentAndFollowingOverlays = (): Overlay[] => {
    const currentOverlayIndex = Overlay.overlayStack.indexOf(this);
    return currentOverlayIndex === -1 ? [] : Overlay.overlayStack.slice(currentOverlayIndex);
  };

  private isCurrentOverlay = (): boolean => Overlay.overlayStack[Overlay.overlayStack.length - 1] === this;

  private prepare = () => {
    if (Overlay.overlayStack.indexOf(this) === -1) {
      if (this.props.isOpen) {
        this.prepareToOpen();
      }
    } else {
      if (!this.props.isOpen) {
        this.prepareToClose();
      }
    }
  };

  private prepareToOpen = (): void => {
    Overlay.overlayStack.push(this);

    if (this.props.canCloseOnOutsideClick) {
      document.addEventListener('mousedown', this.handleDocumentMousedown);
    }

    if (this.props.canCloseOnEscapeKey) {
      document.addEventListener('keydown', this.handleDocumentKeydown);
    }

    if (this.props.usePortal && this.props.useBackdrop) {
      document.body.classList.add('overlay-open');
    }

    // debug('openining', Overlay.overlayStack);
  };

  /**
   * Perform cleanup
   */
  private prepareToClose = (): void => {
    Overlay.overlayStack = Overlay.overlayStack.filter(overlay => overlay !== this);
    document.removeEventListener('mousedown', this.handleDocumentMousedown);
    document.removeEventListener('keydown', this.handleDocumentKeydown);

    this.toggleBodyScroll();
    // debug('unmounting', Overlay.overlayStack);
  };

  private close = (e: any) => {
    safeInvoke(this.props.onClose, e);
    this.context.hide(this.props.overlayKey);
  };
}
