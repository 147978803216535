import { MessageSearchValues } from '@apps/serviceCenter/ServiceCenter.types';
import { useFormik } from 'formik';
import { getFormikValidationSchema } from '../../Messages.utils';
import { useServiceCenterState } from '@apps/serviceCenter/ServiceCenterState';
import { OptionType } from '@withjoy/joykit';
import { useCallback, useMemo } from 'react';

export const useSearchBarController = () => {
  const { setSearchResults } = useServiceCenterState();
  const formikValidationSchema = getFormikValidationSchema();

  const defaultSearchValues: MessageSearchValues = {
    fromEmail: '',
    replyToEmail: '',
    toEmail: '',
    eventId: '',
    subject: '',
    startCreateDate: '',
    endCreateDate: '',
    pageNumber: 1,
    pageSize: 20
  };

  const formik = useFormik<MessageSearchValues>({
    initialValues: defaultSearchValues,
    validationSchema: formikValidationSchema,
    onSubmit: values => {
      setSearchResults({ ...values, pageNumber: 1 });
    }
  });

  const pageSizeOptions: OptionType[] = useMemo(() => [10, 20, 30, 40, 50, 100].map(option => ({ value: `${option}`, label: `${option}` })), []);

  const handlePageSizeSelect = useCallback(
    (pageSize: number) => {
      formik.setFieldValue('pageSize', pageSize);
      formik.setFieldValue('pageNumber', 1);
      setSearchResults({ ...formik.values, pageSize, pageNumber: 1 });
    },
    [formik, setSearchResults]
  );

  const handlePageNumberChange = useCallback(
    (pageNumber: number) => {
      formik.setFieldValue('pageNumber', pageNumber);
      setSearchResults({ ...formik.values, pageNumber });
    },
    [formik, setSearchResults]
  );

  return { formik, pageSizeOptions, handlePageSizeSelect, handlePageNumberChange };
};
