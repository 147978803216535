import React from 'react';
import { styled, css } from '@shared/joykit/packages/core/common/styledComponents';
import { Props } from '../../common/props';
import { InputV1Props } from '.';
import { generateComponentDisplayName, animationTransition } from '../../utils';
import { getStyledBaseComponentProps } from '../../common/styledSystem/styledFunctions';
import { PaletteColorTokens } from '../../common';

const Container: React.FC<{ disabled: InputV1Props['disabled']; invalid: boolean; minimal?: boolean; focused: boolean; focusedBorderColor?: keyof PaletteColorTokens } & Props> = ({
  children,
  className
}) => {
  return <div className={className}>{children}</div>;
};

export const StyledInputContainer = styled(Container)`
  position: relative;
  color: ${props => (props.disabled ? props.theme.colors.typeDisabledDark : undefined)};

  ${props => {
    if (!props.disabled && !props.minimal) {
      const focusedBorderColor = props.focusedBorderColor;
      const resolvedBorderColor = (focusedBorderColor ? props.theme.colors[focusedBorderColor] : props.theme.colors.buttonPrimaryActive) || 'transparent';
      return css`
        &::after {
          content: ' ';
          display: block;
          position: absolute;
          opacity: 0;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          border: 3px solid ${props.invalid ? props.theme.colors.buttonDestructiveActive : resolvedBorderColor};
          border-radius: 5px;
          pointer-events: none;
          transition: ${animationTransition('border-color', 'opacity')};
          opacity: ${props.focused ? 1 : 0};
        }
      `;
    }

    if (props.disabled) {
      return {
        cursor: 'not-allowed'
      };
    }
    return null;
  }}

  ${getStyledBaseComponentProps}
`;

StyledInputContainer.displayName = generateComponentDisplayName('Input__Container');
