import React from 'react';
import { FormControl, InputV2, TextV2, ButtonV2 } from '@withjoy/joykit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRestoreEventMutation } from '@graphql/generated';

export const RestoreEvent = () => {
  const [restoreEvent, { loading: restoringEvent, error: errorRestoringEvent }] = useRestoreEventMutation();

  const formik = useFormik({
    initialValues: {
      eventId: ''
    },
    onSubmit: values => restoreEvent({ variables: { id: values.eventId } }),
    validationSchema: Yup.object().shape({
      eventId: Yup.string()
        .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
        .required('Event ID is required and must be a valid UUID')
    })
  });
  return (
    <>
      <TextV2 marginBottom={'2rem'} typographyVariant={'body2'}>
        If an event was accidentally deleted, you can restore it here. You will need the event ID (NOT from Firebase) to restore the event.
      </TextV2>
      <form>
        <FormControl label={'Event ID'}>
          <>
            <InputV2 placeholder={'00000000-0000-0000-0000-000000000000'} {...formik.getFieldProps('eventId')} />
            <TextV2 typographyVariant="body3" marginTop={2} color={'inputBorderError'} hidden={!formik.getFieldMeta('eventId').error}>
              {formik.getFieldMeta('eventId').error}
            </TextV2>
          </>
        </FormControl>

        <ButtonV2 marginTop={'1.5rem'} type="submit" disabled={!formik.isValid} loading={restoringEvent}>
          Restore Event
        </ButtonV2>
        <TextV2 marginTop={'1.5rem'} color="red7">
          {errorRestoringEvent?.message}
        </TextV2>
      </form>
    </>
  );
};
