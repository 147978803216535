import React, { useCallback, useContext, useState } from 'react';
import { ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { AnalyticsContext, useTranslation } from '@shared/core';
import { LOCAL_STORAGE_SLAP_AD_TRAVEL } from './useSlapAdsTravelClosed';

interface SlapAdsTravelProps {
  redirectTo: string;
}

const SlapAdsTravel: React.FC<SlapAdsTravelProps> = ({ redirectTo }) => {
  const { t } = useTranslation('slapAdsTravel');
  const analytics = useContext(AnalyticsContext);

  const [slapAdVisible, setSlapAdVisible] = useState(true);

  const onBtnDismissClicked = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_SLAP_AD_TRAVEL, new Date().toISOString());
    setSlapAdVisible(false);
    analytics.track({
      category: 'GuestSite',
      action: 'ButtonInteracted',
      label: 'TravelSlapAd',
      extraInfo: {
        name: 'adminRoomBlockGuestSiteCTA_dismiss'
      }
    });
  }, [analytics]);

  const onBtnStartClicked = useCallback(() => {
    analytics.track({
      category: 'GuestSite',
      action: 'ButtonInteracted',
      label: 'TravelSlapAd',
      extraInfo: {
        name: 'adminRoomBlockGuestSiteCTA'
      }
    });
  }, [analytics]);

  return slapAdVisible ? (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        rowGap={6}
        borderRadius={4}
        paddingY={6}
        paddingX={5}
        background={`linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), url(https://withjoy.com/assets/public/Guest-Site-Room-Blocks/ImageSalpAd.png?opt=mild), lightgray -51.442px -62.588px / 137.852% 154.395% no-repeat`}
        backgroundSize={'113%'}
        backgroundPosition={'left 65%'}
        backgroundRepeat={'no-repeat'}
      >
        <Flex justifyContent="center" alignItems="center" flexDirection="column" rowGap={3}>
          <TextV2 textAlign="center" fontFamily={'Inter UI'} color="mono14" typographyVariant="hed6" fontSize={26} maxWidth={333} fontWeight={700}>
            {t('title')()}
          </TextV2>
          <TextV2 textAlign="center" color="mono14" fontFamily={'Inter UI'} typographyVariant="button2" fontWeight={600}>
            {t('subtitle')()}
          </TextV2>
        </Flex>
        <Flex justifyContent="center" alignItems="center" flexDirection="column" rowGap={16}>
          <ButtonV2
            href={redirectTo}
            onClick={onBtnStartClicked}
            fontFamily={'Inter UI'}
            target="_blank"
            as="a"
            intent="neutral"
            variant="solid"
            shape="rounded"
            fontWeight="bold"
            fontSize={16}
          >
            {t('btn')()}
          </ButtonV2>
          <ButtonV2
            typographyVariant="button1"
            fontFamily={'Inter UI'}
            intent="neutral"
            variant="ghost"
            shape="square"
            fontWeight="medium"
            fontSize={16}
            onClick={onBtnDismissClicked}
            color={'mono14'}
            _hover={{ textDecoration: 'underline', color: 'gray12' }}
          >
            {t('btnDismiss')()}
          </ButtonV2>
        </Flex>
      </Flex>
      <TextV2 marginTop={16} typographyVariant="label2" textAlign="center" fontFamily={'Inter UI'}>
        {t('infoMessage')()}
      </TextV2>
    </>
  ) : null;
};

SlapAdsTravel.displayName = 'SlapAdsTravel';

export { SlapAdsTravel };
