import { Theme } from '@withjoy/joykit';
import { extendTheme } from '@withjoy/joykit/theme';
import { createTextStyleVariantBuilder } from '@shared/joykit/packages/core/common/themes/shared/typography';

const GUEST_PRIMARY_FONT_FAMILY = '"Gotham SSm","Helvetica","Arial",sans-serif';
const buildVariant = createTextStyleVariantBuilder(GUEST_PRIMARY_FONT_FAMILY);
export const defaultGuestSiteTypography = {
  typography: {
    primaryFontFamily: GUEST_PRIMARY_FONT_FAMILY,
    variants: {
      // These are the base GuestKit text style variants

      body1: buildVariant(400, 15, 1.47, -0.01),
      body2: buildVariant(400, 16, 1.28, 0),
      body3: buildVariant(400, 16, 1.4, 0.01),
      body4: buildVariant(400, 16, 1.7, 0),

      label1: buildVariant(400, 12, 1.52, 0),
      label2: buildVariant(400, 13, 1.4, 0),
      label3: buildVariant(500, 14, 1.83, 0),
      label4: buildVariant(400, 14, 1.4, 0.12, { casing: 'uppercase', fontFeatures: `'tnum' on,'lnum' on` }),
      label5: buildVariant(700, 15, 1.47, -0.0087),
      label6: buildVariant(500, 16, 1.2, 0.03),

      hed1: buildVariant(500, 16, 1.275, 0),
      hed2: buildVariant(500, 17, 1.4, -0.005),
      hed3: buildVariant(400, 19, 1.4, 0.03),
      hed4: buildVariant(500, 20, 1.2, -0.01),
      hed5: buildVariant(500, 24, 1.3, -0.01),
      hed6: buildVariant(500, 26, 1.4, -0.01),

      button1: buildVariant(500, 16, 1.25, 0),
      button2: buildVariant(400, 16, 1.2, 0, { fontFeatures: `'tnum' on,'lnum' on` }),
      button3: buildVariant(500, 16, 1.2, 0),
      button4: buildVariant(700, 16, 1.38, 0), // not really used in Aloha

      display1: buildVariant(400, 26, 1.25, 0),
      display2: buildVariant(400, 32, 1.25, 0),
      display3: buildVariant(400, 36, 1.25, 0),
      display4: buildVariant(400, 48, 1.25, 0),
      display5: buildVariant(400, 48, 1.25, 0),
      display6: buildVariant(400, 56, 1.25, 0),
      display7: buildVariant(400, 64, 1.31, 0),
      display8: buildVariant(400, 72, 1.16, 0),
      display9: buildVariant(400, 96, 1.2, 0),
      display10: buildVariant(400, 144, 1.2, 0),
      display11: buildVariant(400, 288, 1.2, -0.022)
    }
  }
} as Theme;

export const mergeTypograhyThemeOverrides = (typographyOverrides: DeepPartial<Theme['typography']>) => {
  const partialTheme = extendTheme({ typography: typographyOverrides }, defaultGuestSiteTypography);

  return partialTheme.typography;
};
