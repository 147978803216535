import React from 'react';
import { generateComponentDisplayName } from '../../utils';
import { Choice } from '../Choice';
import { HTMLInputProps, BaseControlProps } from '../../common/props';
import { CHOICE_INDICATOR } from '../../common';
import { StyledInput } from './StyledInput';
import { StyledIcon } from './StyledIcon';
interface RadioProps extends BaseControlProps<HTMLInputElement>, HTMLInputProps {
  children?: never;
  /** Where to align the icon in relation to the label.  */
  alignIndicator?: 'left' | 'right';

  checked: boolean;

  /** A unique identifier */
  id: string;

  name: string;

  /** Label for the choice */
  label: string;

  /** Visually hide the label. */
  labelHidden?: boolean;

  labelledBy?: string;

  /** Called when the user attempts to change the checked state */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  /** Called when the Radio or label is clicked */
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

/**
 * A radio button is used where only *one* item - in a series of options - can be selected at a time.
 *
 * Any other properties supplied will be spread to the root element - `Input`.
 */
const Radio: React.SFC<RadioProps & HTMLInputProps> = React.memo(props => {
  const { alignIndicator, id, checked, className, disabled, label } = props;
  const labelMarkup = (
    <Choice id={id} alignIndicator={alignIndicator} className={className} disabled={disabled} label={label}>
      <StyledInput {...props} />
      <StyledIcon className={CHOICE_INDICATOR} disabled={disabled} checked={checked} />
    </Choice>
  );

  return labelMarkup;
});

Radio.defaultProps = {
  alignIndicator: 'left'
};
Radio.displayName = generateComponentDisplayName('Radio');

export { RadioProps, Radio };
