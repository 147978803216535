import React from 'react';
import { StyledInfoBlockWrapper } from './InfoBlock.styles';
import { SpacingStack, Box, TextV2, BoxProps } from '@withjoy/joykit';
import { useIconProp } from '@withjoy/joykit/utils';
import { InfoBlockGroup } from '../InfoBlockGroup';
import { TypographyVariant } from '@withjoy/joykit/theme';

interface InternalInfoBlockProps {
  text?: string;

  icon?: React.ReactNode;

  typographyVariant?: TypographyVariant;
}

export interface InfoBlockProps extends Readonly<InternalInfoBlockProps>, Omit<BoxProps, 'typographyVariant'> {}

const InfoBlock: React.FC<InfoBlockProps> & { Group: typeof InfoBlockGroup } = props => {
  const { children, text, icon, typographyVariant = 'body3', ...restProps } = props;

  const maybeIcon = useIconProp(icon);

  return (
    <StyledInfoBlockWrapper {...restProps}>
      <SpacingStack stack="horizontal" spacing={5}>
        {maybeIcon}
        <Box
          typographyVariant={typographyVariant}
          __css={{
            flex: '1 0 auto',
            width: '50px'
          }}
          overflow="hidden"
        >
          {text && <TextV2 typographyVariant="body3">{text}</TextV2>}
          {children}
        </Box>
      </SpacingStack>
    </StyledInfoBlockWrapper>
  );
};

InfoBlock.displayName = 'InfoBlock';

InfoBlock.Group = InfoBlockGroup;

export { InfoBlock };
