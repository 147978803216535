import { useTranslation } from '@shared/core/i18n';
import { useMemo } from 'react';

export type VirtualEventBlockTranslations = Readonly<{
  eventLive: string;
  eventPassed: string;
  eventUpcoming: string;
  eventStartsSoon: (startsInMin: number) => string;
  eventTba: string;
  joinCta: string;
  joinCtaZoom: string;
  localTimezoneOffset: (offset: string) => string;
  streamingProvider: string;
  timeframe: (start: string, end: string) => string;
  troubleWithApp: string;
  watchInBrowser: string;
}>;

export const useTranslations = (): VirtualEventBlockTranslations => {
  const { t } = useTranslation('guestKit');

  const component = t('components', 'virtualEventBlock');
  const common = t('common');

  const value = useMemo<VirtualEventBlockTranslations>(() => {
    return {
      eventLive: component.eventLive(),
      eventUpcoming: component.eventUpcoming(),
      eventStartsSoon: startsInMin => component.eventStartsSoon({ startsInMin }),
      eventPassed: component.eventPassed(),
      eventTba: component.eventTba(),
      joinCta: component.joinCta(),
      joinCtaZoom: component.joinCtaZoom(),
      localTimezoneOffset: (offset: string) => component.localTimezoneOffset({ offset }),
      streamingProvider: component.streamingProvider(),
      timeframe: (start, end) => common.timeframe({ start, end }),
      troubleWithApp: component.troubleWithApp(),
      watchInBrowser: component.watchInBrowser()
    };
  }, [component, common]);

  return value;
};
