import { useTranslation } from '@shared/core/index';

export const useTranslations = () => {
  const { t } = useTranslation('guestKit');
  const commonTranslations = t('common');
  return {
    discountCode: commonTranslations.discountCodeLabel(),
    textCopiedNotificationLabel: commonTranslations.textCopiedNotificationLabel()
  };
};
