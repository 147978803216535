import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { Box, FabButton, ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { Share } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { withWindow } from '@shared/utils/withWindow';
import { useHotelBookingRoutePaths } from '@apps/guest/routes/HotelBooking/HotelBooking.routes';
import { AccommodationCombinedType } from '../../Accommodation.types';
import { useCustomPageTelemetry } from '@apps/guest/packages/layout-engine/widgets/Custom/Custom.telemetry';
import { useQueryParamHelper } from '@shared/core/queryString';

type TelemetryHotelInfo = {
  hotelName: string;
  isHotelPreferred: boolean;
  hotelPrice: number | undefined;
  hotelStrikeThroughPrice: number | undefined;
};

export const AccommodationTileImage: React.FC<{
  id: Maybe<string>;
  showDefaultData: Maybe<boolean>;
  photoUrl: Maybe<string>;
  eventHandle: Maybe<string>;
  telemetryHotelInfo: TelemetryHotelInfo;
  type: AccommodationCombinedType;
  hotelTileGuestCountEnabled: Maybe<boolean>;
  interestedGuestCount: Maybe<number>;
}> = ({ showDefaultData, photoUrl, eventHandle, telemetryHotelInfo, id, type, hotelTileGuestCountEnabled, interestedGuestCount }) => {
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const hasShare = Boolean(navigator.share);
  const DEFAULT_HOTEL_IMAGE = 'https://withjoy.com/assets/public/hotelselection/empty-hotel-item.png';
  const { roomblock, buildPath } = useHotelBookingRoutePaths();
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const { getValueString } = useQueryParamHelper();
  const testNum = getValueString('num') as string;
  const showGuestCount = hotelTileGuestCountEnabled && interestedGuestCount && interestedGuestCount >= 2;
  const showGuestCountTest = hotelTileGuestCountEnabled && +testNum >= 2;

  const telemetry = useCustomPageTelemetry();
  const { setShowNoLeadDialog, enabledAdminGuestSiteBannerDialog } = useGuestSiteState();

  const handleShareBookinglink = useEventCallback(() => {
    if (enabledAdminGuestSiteBannerDialog) {
      telemetry.leadGenDialogPromptedClicked();
      setShowNoLeadDialog(true);
    } else {
      telemetry.shareRoomClicked('accommodations', telemetryHotelInfo);
      withWindow(global => navigator.share({ url: `${global.location.origin}${buildPath(eventHandle || '', roomblock, id || '')}` }));
    }
  });

  if (type !== AccommodationCombinedType.Roomblock && !photoUrl) return null;

  return (
    <Box
      width={'100%'}
      height={250}
      minWidth={56}
      borderRadius={'12px'}
      objectFit={'cover'}
      backgroundSize={'cover'}
      backgroundPosition={`center ${showDefaultData ? 'bottom' : 'center'}`}
      backgroundRepeat={'no-repeat'}
      backgroundImage={`url(${photoUrl || DEFAULT_HOTEL_IMAGE})`}
      display="flex"
      position={'relative'}
    >
      {type === AccommodationCombinedType.Roomblock && !hotelTileGuestCountEnabled && (
        <ButtonV2
          position={'absolute'}
          variant="solid"
          backgroundColor={'white'}
          shape="rounded"
          color={'black'}
          top={5}
          left={3}
          pointerEvents={'none'}
          height={pxToRem(34)}
          paddingX={5}
          paddingY={3}
          fontFamily={'Inter UI'}
          fontWeight={600}
          fontSize={13}
        >
          {accTrans.hotelRoomBlock()}
        </ButtonV2>
      )}
      {(showGuestCount || showGuestCountTest) && type === AccommodationCombinedType.Roomblock && (
        <Flex
          position={'absolute'}
          backgroundColor={'rgba(255, 255, 255, 0.80)'}
          backdropFilter={'blur(3.75px)'}
          borderRadius={pxToRem(25)}
          top={5}
          left={3}
          width={pxToRem(190)}
          height={pxToRem(38)}
          paddingY={1}
          paddingLeft={1}
        >
          <Flex justifyContent="center" alignItems={'center'} borderRadius={'50%'} width={pxToRem(30)} height={pxToRem(30)} backgroundColor={'white'}>
            <TextV2 fontFamily={'Inter UI'} color={'mono14'} fontSize={pxToRem(15)} fontWeight={600} padding={pxToRem(5)}>
              {showGuestCountTest ? +testNum : interestedGuestCount}
            </TextV2>
          </Flex>
          <TextV2 fontFamily={'Inter UI'} color={'black'} fontSize={pxToRem(13)} letterSpacing={pxToRem(-0.032)} fontWeight={600} paddingLeft={3} alignSelf={'center'}>
            {accTrans.guestCount()}
          </TextV2>
        </Flex>
      )}
      {isMobile && hasShare && !hotelTileGuestCountEnabled && (
        <FabButton
          position={'absolute'}
          variant="ghostShadow"
          onClick={handleShareBookinglink}
          top={5}
          right={5}
          width={pxToRem(32)}
          height={pxToRem(32)}
          minWidth={pxToRem(7)}
          minHeight={pxToRem(7)}
          padding={0}
        >
          <Share size="sm" />
        </FabButton>
      )}
      {isMobile && hasShare && hotelTileGuestCountEnabled && (
        <FabButton
          position={'absolute'}
          variant="ghostShadow"
          backgroundColor={'rgba(255, 255, 255, 0.80)'}
          backdropFilter={'blur(3.75px)'}
          color={'black'}
          onClick={handleShareBookinglink}
          top={5}
          right={5}
          width={pxToRem(40)}
          height={pxToRem(40)}
          minWidth={pxToRem(7)}
          minHeight={pxToRem(7)}
          padding={0}
        >
          <Share size="sm" />
        </FabButton>
      )}
    </Box>
  );
};
