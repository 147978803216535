import React, { useEffect, useMemo, useState } from 'react';
import { createContext } from '@shared/utils/createContext';

type JoyInteractableMapContext = {
  map?: google.maps.Map;
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>;
  initialBounds?: google.maps.LatLngBounds;
  loadingPlaces: boolean;
  setLoadingPlaces: React.Dispatch<React.SetStateAction<boolean>>;
};

const [Provider, useJoyInteractableMapContext] = createContext<JoyInteractableMapContext>({ name: 'JoyInteractableMap' });

const JoyInteractableMapProvider: React.FC = ({ children }) => {
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const [initialBounds, setInitialBounds] = useState<google.maps.LatLngBounds | undefined>();
  const [loadingPlaces, setLoadingPlaces] = useState(false);

  useEffect(() => {
    if (!map) return;

    google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
      setInitialBounds(map.getBounds());
    });

    return () => {
      google.maps.event.clearInstanceListeners(map);
    };
  }, [map]);

  const ctx = useMemo<JoyInteractableMapContext>(
    () => ({
      map,
      setMap,
      initialBounds,
      loadingPlaces,
      setLoadingPlaces
    }),
    [initialBounds, loadingPlaces, map]
  );

  return <Provider value={ctx}>{children}</Provider>;
};

export { JoyInteractableMapProvider, useJoyInteractableMapContext };
