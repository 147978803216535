import React from 'react';
import { Box, BoxProps } from '@withjoy/joykit';
import { createDisplayName } from '@withjoy/joykit/utils';

interface InternalDividerProps {
  orientation?: 'vertical' | 'horizontal';
}

export interface DividerProps extends Readonly<InternalDividerProps>, BoxProps {}

const _orientationStyles: Record<'horizontal' | 'vertical', BoxProps> = {
  horizontal: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    width: '100%'
  },
  vertical: {
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
    height: 'auto'
  }
};

const Divider: React.FC<DividerProps> = ({ orientation = 'horizontal', ...restProps }) => {
  return <Box as="hr" role="separator" aria-orientation={orientation} border={0} borderColor={'mono3'} {..._orientationStyles[orientation]} {...restProps} />;
};

Divider.displayName = createDisplayName('Divider');

export { Divider };
