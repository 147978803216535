import { styled } from '@shared/joykit/packages/core/common/styledComponents';

export const ArrowBorderPath = styled.path``;

export const Arrow = styled.div`
  position: absolute;
  font-size: 30px;
  width: 30px;
  height: 10px;
  svg {
    fill: white;
    position: absolute;
    top: 0;
    height: 10px;
    width: 30px;
  }

  &[data-placement*='bottom'] {
    /* top: -29px; */
    transform: rotateZ(360deg);

    ${ArrowBorderPath} {
      fill: rgba(0, 0, 0, 0.03);
    }
  }
  &[data-placement*='top'] {
    /* bottom: -29px; */
    transform: rotate(180deg);
    ${ArrowBorderPath} {
      fill: rgba(0, 0, 0, 0.08);
    }
  }
  &[data-placement*='right'] {
    /* left: -29px; */
    transform: rotate(270deg);
    ${ArrowBorderPath} {
      fill: rgba(0, 0, 0, 0.08);
    }
  }
  &[data-placement*='left'] {
    /* right: -29px; */
    transform: rotate(90deg);
    ${ArrowBorderPath} {
      fill: rgba(0, 0, 0, 0.08);
    }
  }

  &.jk-tooltip-arrow {
    svg {
      fill: #333;
    }
  }
`;
