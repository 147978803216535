import { useEffect } from 'react';
import { useScrollToPageWhenUrlChanges } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/useScrollToPageWhenUrlChanges';
import { useDebounceScroll } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/useDebounceScroll';
import { PagesRefs } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/LayoutAloha.types';
import { EventPageFragment } from '@graphql/generated';
import { usePageInHistory } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/usePageInHistory';
import getElementPosition from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/utils/getElementPosition';

export type VisiblePageProps = {
  page: EventPageFragment;
  percentageInScreen: number;
};

export const usePageInScreenWhenScrolling = (pagesRef: PagesRefs[], container: HTMLDivElement | null, currentPageInTheUrl: EventPageFragment | undefined) => {
  const onUserScroll = useDebounceScroll();
  const replacePageInBrowserHistory = usePageInHistory();
  const { hasScrolled } = useScrollToPageWhenUrlChanges({ pagesRef, container });

  useEffect(() => {
    if (!pagesRef?.length || !hasScrolled) return;

    const unsubscribe = onUserScroll(container, ({ scrollTop }) => {
      // Calculates what page is the best match to represent the current page in the URL.
      // Sometimes there are multiple pages at the same time on the screen. The priorities are:
      // 1. Page that is on the visible screen, and it is the same as the page in the URL
      // 2. Page that occupies 80% or more part of the visible screen
      // 3. Page that occupies more percentage on the screen. e.g there are 2 pages,
      // one is occupying 49% of the screen and the other 51%, The page in the URL should be the second one
      const calculateWhatPageIsInTheScreen = () => {
        if (!container?.offsetHeight) return;

        const minRequiredPagePercentage = 0.8;
        const containerHeight = container.offsetHeight;
        const containerPosition = getElementPosition(container, scrollTop, true);
        let currentPage: EventPageFragment | null = null;
        let currentPageInTheScreen: VisiblePageProps | null = null;

        pagesRef.some(({ page, ref }: PagesRefs): boolean | void => {
          const contentHeight = ref?.offsetHeight;

          if (!contentHeight) return;

          const pagePosition = getElementPosition(ref, scrollTop);
          const isWholePageInsideScreen = pagePosition.min >= containerPosition.min && pagePosition.max < containerPosition.max;
          const isCurrentPageSameAsThePageInTheUrl = currentPageInTheUrl?.id === page.id;

          // Case 1. Page is on the visible screen, and it is the same as the page in the URL
          if (isCurrentPageSameAsThePageInTheUrl && isWholePageInsideScreen) {
            currentPage = page;
            return true;
          }

          const commonRegionBetweenPageAndScreen = {
            min: Math.max(containerPosition.min, pagePosition.min),
            max: Math.min(containerPosition.max, pagePosition.max)
          };
          const commonAreaBetweenPageAndScreen = commonRegionBetweenPageAndScreen.max - commonRegionBetweenPageAndScreen.min;
          const isPageInTheScreen = commonAreaBetweenPageAndScreen > 0;
          let pagePercentageInScreen = 0;

          if (isPageInTheScreen) {
            pagePercentageInScreen = commonAreaBetweenPageAndScreen / containerHeight;
          }

          const isPageInTheMinRequiredPercentageOfScreen = pagePercentageInScreen > minRequiredPagePercentage;

          // Case 2. Page occupies 80% or more part of the visible screen
          if (isWholePageInsideScreen || isPageInTheMinRequiredPercentageOfScreen) {
            currentPage = page;
          }

          // Case 3. Page that occupies more percentage on the screen.
          if (!currentPageInTheScreen || currentPageInTheScreen.percentageInScreen < pagePercentageInScreen) {
            currentPageInTheScreen = { page, percentageInScreen: pagePercentageInScreen };
          }
        });

        if (!currentPage) {
          currentPage = currentPageInTheScreen?.['page'] ?? null;
        }

        replacePageInBrowserHistory(currentPage);
      };

      calculateWhatPageIsInTheScreen();
    });

    return () => unsubscribe?.();
  }, [pagesRef, container, hasScrolled, replacePageInBrowserHistory, onUserScroll, currentPageInTheUrl?.id]);
};
