/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Link',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 3C20.1046 3 21 3.89543 21 5V8.4C21 8.73137 20.7314 9 20.4 9C20.0686 9 19.8 8.73137 19.8 8.4V5.05227L15.9467 8.90553C15.7124 9.13984 15.3325 9.13984 15.0982 8.90553C14.8639 8.67121 14.8639 8.29131 15.0982 8.057L18.9552 4.2H15.6C15.2686 4.2 15 3.93137 15 3.6C15 3.26863 15.2686 3 15.6 3H19ZM7 6.2C6.55817 6.2 6.2 6.55817 6.2 7V17C6.2 17.4418 6.55817 17.8 7 17.8H17C17.4418 17.8 17.8 17.4418 17.8 17V12.6C17.8 12.2686 18.0686 12 18.4 12C18.7314 12 19 12.2686 19 12.6V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V7C5 5.89543 5.89543 5 7 5H11.4C11.7314 5 12 5.26863 12 5.6C12 5.93137 11.7314 6.2 11.4 6.2H7Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
