/**
 * Default level of precision in node-sass is 5 decimal places.
 * Bootstrap v5 increased the rounding precision to 6 to prevent issues with browser rounding
 */
const ROUND_PRECISION = 1e6;

/**
 *
 * @example
 *  round(100 / 12 * 8) === 66.666667
 *  round(100 / 12 * 1) ===  8.333333
 */
export const roundToPrecision = (num: number, precision: number = ROUND_PRECISION) => {
  return Math.round(num * precision) / precision;
};

/*
  Custom rounding function that rounds a number according to a specific logic.
  Rounds down if the decimal value is less than 0.2,
  rounds to the nearest half value if the decimal value is between 0.2 and 0.79,
  otherwise rounds up to the nearest integer.
  @param {number} number - The number to be rounded
  @returns {number} - The rounded number based on the defined logic
*/
export const customRounding = (number: number) => {
  const decimalValue = number - Math.floor(number);
  if (decimalValue < 0.2) {
    return Math.floor(number);
  } else if (decimalValue <= 0.79) {
    return Math.floor(number) + 0.5;
  } else {
    return Math.ceil(number);
  }
};
