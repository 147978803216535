import { animationTransition } from './animationTransition';
import { styled } from '@withjoy/joykit';

export const TOP_BAR_JOY_ICON_TABLET_RIGHT = 16;
export const TOP_BAR_JOY_ICON_DESKTOP_RIGHT = 56;
export const JOY_LOGO_WIDTH_MOBILE = 88;
export const JOY_LOGO_WIDTH_DESKTOP = 64;
export const JOY_LOGO_WIDTH_XLARGE = 80;

export const StyledJoyLogo = styled.a<{ $isScrollCollapsed?: boolean }>`
  position: relative;
  display: flex;
  transition: ${animationTransition('color', 'transform')};
  margin-right: 0px;
  color: ${({ color }) => (color ? color : '')};

  & svg {
    width: ${JOY_LOGO_WIDTH_MOBILE}px;
    height: inherit;

    path.clip-path {
      width: unset;
      transform: scale(0.01075, 0.0155);
    }
    ${props => props.theme.breakpoints.md} {
      width: ${JOY_LOGO_WIDTH_DESKTOP}px;
      margin-top: 0px;
    }
    ${props => props.theme.breakpoints.xl} {
      width: ${({ $isScrollCollapsed }) => ($isScrollCollapsed ? JOY_LOGO_WIDTH_DESKTOP : JOY_LOGO_WIDTH_XLARGE)}px;
      margin-top: 0px;
    }
  }

  &:not(.footer-joy-icon) {
    margin-top: ${({ $isScrollCollapsed }) => ($isScrollCollapsed ? 3 : 0)}px;
    ${props => props.theme.breakpoints.xs} {
      margin-top: 12px;
    }
    ${props => props.theme.breakpoints.sm} {
      margin-right: ${TOP_BAR_JOY_ICON_TABLET_RIGHT}px;
    }
    ${props => props.theme.breakpoints.md} {
      margin-right: ${TOP_BAR_JOY_ICON_DESKTOP_RIGHT}px;
    }
  }

  :hover {
    // TODO: Make hover effect not clip on bottom border
    /* transform: scale(1.1); */
    color: ${({ color }) => (color ? color : '')};
  }
`;

export const StyledCanvasWrapper = styled.div<{ clipPathId: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  clip-path: url('#${props => props.clipPathId}');
`;

export const StyledCanvas = styled.canvas<{ isScrollCollapsed: boolean }>`
  width: 40px;
  height: intrinsic;
  ${props => props.theme.breakpoints.xs} {
    transform: translateY(-1px);
  }
  ${props => props.theme.breakpoints.md} {
    width: ${JOY_LOGO_WIDTH_DESKTOP}px;
  }
  ${props => props.theme.breakpoints.xl} {
    width: ${({ isScrollCollapsed }) => (isScrollCollapsed ? JOY_LOGO_WIDTH_DESKTOP : JOY_LOGO_WIDTH_XLARGE)}px;
  }
`;
