// eslint-disable-next-line no-restricted-imports
import type { FlattenSimpleInterpolation } from 'styled-components';

import { ColumnsV2Props } from './Columns.types';
import { getMediaQuery } from '@withjoy/joykit/utils';
import { css, Theme } from '@withjoy/joykit';
import { allBreakpoints, allGridRecommendations } from '@withjoy/joykit/theme';

type SnapPoint = Readonly<{
  mediaQuery: string;
  rule: FlattenSimpleInterpolation;
}>;

const allSnapPoints: ReadonlyArray<SnapPoint> = allBreakpoints.map((breakpoint, idx) => {
  const maxWidth = allGridRecommendations.maxWidths[idx];
  const mediaQuery = getMediaQuery(breakpoint);
  return {
    mediaQuery,
    rule: css`
      ${mediaQuery} {
        max-width: ${maxWidth};
      }
    `
  };
});

export const fluidMediaQueryRules = ({ fluid, theme }: { fluid?: ColumnsV2Props['fluid']; theme: Theme }) => {
  if (fluid === true) {
    // 100% width at all viewports
    return null;
  }

  // Use all snap points by default.
  let snapPoints = allSnapPoints;

  if (!!fluid) {
    // If provided, the container will be fluid until the specified breakpoint.
    const upToIndex = snapPoints.findIndex(({ mediaQuery }) => mediaQuery === theme.mediaQueries[fluid]);

    if (upToIndex !== -1) {
      snapPoints = snapPoints.slice(upToIndex, snapPoints.length);
    }
  }

  return snapPoints.map(({ rule }) => rule);
};
