import React, { useEffect } from 'react';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import parse from 'date-fns/parse';
import { useImmer } from 'use-immer';

import { PopoverV2, MaskedInput } from '@withjoy/joykit';
import { DatePicker } from './DatePicker';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { Calendar } from '../../icons';
import { DatePickerInputProps, DatePickerInputState, OnDatePickerChange } from './DatePicker.types';
import { formatDateToInputValue } from './DatePicker.utils';
import { KEYBOARD_KEYS } from '@withjoy/joykit/utils';
import { useLocaleContext } from '@shared/core/i18n/LocaleContext';
import { useDisclosure } from '@withjoy/joykit/hooks';
import { createPortal } from 'react-dom';

const DatePickerInput: React.FC<DatePickerInputProps> = (props: DatePickerInputProps) => {
  const locale = useLocaleContext();

  const {
    // Don't do anything with these props, just don't want to pass it to the MaskedInput
    filterDate,
    maxDate,
    minDate,
    monthsShown,
    overrides,
    // DatePickerProps
    'aria-describedby': ariaDescribedBy,
    'aria-errormessage': ariaErrorMessage,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    appendPickerToBody,
    dateFormat = locale.dateFormat,
    isDisabled,
    isInvalid,
    isRange,
    isReadOnly,
    isRequired,
    mask = locale.dateInputMask,
    onChange,
    placeholder = locale.dateFormat.toUpperCase(),
    rangeDisplayValue = 'start',
    value,
    startElement,
    endElement,
    placement = 'bottom',
    isOpen: _isOpen,
    onClose: _onClose,
    onOpen: _onOpen,
    onInputChange,

    ...restProps
  } = props as DatePickerInputProps;

  const formatValue = (value: DatePickerInputProps['value']) => {
    return formatDateToInputValue(value, dateFormat);
  };

  const [{ startInputValue, endInputValue }, setState] = useImmer<DatePickerInputState>(() => ({ isOpen: !!_isOpen, ...formatValue(value) }));
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: _isOpen,
    onOpen: _onOpen,
    onClose: _onClose
  });
  //////////////////////////////
  // State Handlers

  const openCalendar = useEventCallback(() => {
    onOpen();
  });

  const closeCalendar = useEventCallback(() => {
    onClose();
  });

  const setInputValueFromProp = useEventCallback((value: DatePickerInputProps['value']) => {
    setState(draft => {
      const formattedValues = formatValue(value);
      draft.startInputValue = formattedValues.startInputValue;
      draft.endInputValue = formattedValues.endInputValue;
    });
  });

  const updateInputValue = useEventCallback((value: string) => {
    setState(draft => {
      const inputField = isRange && rangeDisplayValue === 'end' ? 'endInputValue' : 'startInputValue';
      draft[inputField] = value;
    });
  });

  //////////////////////////////
  // Event handlers

  const handleOnChange: OnDatePickerChange = (dates, ev) => {
    onChange?.(dates, ev);
  };

  const handleOnInputChange = useEventCallback<React.ChangeEventHandler<HTMLInputElement>>(e => {
    const nextInputValue = e.target.value;

    updateInputValue(nextInputValue);
    onInputChange?.(nextInputValue);

    if (nextInputValue.replace(/(\s)*/g, '').length === dateFormat.replace(/(\s)*/g, '').length) {
      // Prevent early parsing

      const inputDate = parse(nextInputValue, dateFormat, new Date());

      if (!isValid(inputDate)) {
        return;
      }

      if (isRange && Array.isArray(value)) {
        let [startValue, endValue] = value;

        // Picking a date range can occur in one Date Picker, or across several composed pickers.
        // When segmented, pickers can be designated to choose specific range values - EG the "start" or "end" date

        if (rangeDisplayValue === 'start') {
          startValue = inputDate;
          if (endValue && !(isAfter(endValue, startValue) || isEqual(startValue, endValue))) {
            // If end value is before the start, reset the end
            endValue = null;
          }
        } else if (rangeDisplayValue === 'end') {
          endValue = inputDate;
          if (!startValue || !(isAfter(endValue, startValue) || isEqual(startValue, endValue))) {
            // If no start value, set same start/end.
            startValue = endValue;
          }
        }

        onChange?.([startValue, endValue]);
      } else {
        onChange?.(inputDate);
      }
    }
  });

  const handleOnInputKeyDown = useEventCallback<React.KeyboardEventHandler>(e => {
    if (!isOpen && e.key === KEYBOARD_KEYS.arrowDown) {
      // Open the calendar when the user presses down on the input
      openCalendar();
    }
  });

  //////////////////////////////
  // Lifecycle

  useEffect(() => {
    // When the value prop changes, sync the input value.
    setInputValueFromProp(value);
  }, [value, setInputValueFromProp]);

  //////////////////////////////
  // Render

  const inputValue = rangeDisplayValue === 'end' ? endInputValue : startInputValue;

  return (
    <PopoverV2 isOpen={isOpen} onOpen={openCalendar} onClose={closeCalendar} placement={placement}>
      <PopoverV2.Trigger>
        <MaskedInput
          aria-describedby={ariaDescribedBy}
          aria-errormessage={ariaErrorMessage}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          mask={mask || ''}
          onChange={handleOnInputChange}
          onKeyDown={handleOnInputKeyDown}
          placeholder={placeholder}
          overrides={overrides?.Input}
          startElement={startElement ? startElement : undefined}
          endElement={endElement ? endElement : endElement === null ? undefined : <Calendar />}
          autoComplete="off"
          value={inputValue}
          {...restProps}
        />
      </PopoverV2.Trigger>

      {appendPickerToBody ? (
        createPortal(
          <PopoverV2.Content>
            <DatePicker {...props} onChange={handleOnChange} />
          </PopoverV2.Content>,
          document.body
        )
      ) : (
        <PopoverV2.Content>
          <DatePicker {...props} onChange={handleOnChange} />
        </PopoverV2.Content>
      )}
    </PopoverV2>
  );
};

export { DatePickerInput };
