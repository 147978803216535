import { LinkV2, TextV2, TextV2Props } from '@withjoy/joykit';
import React, { isValidElement, ReactNode, useCallback } from 'react';
import { useState } from 'react';
import { InfoBlock } from '../../primitives/InfoBlock';
import { Link as LinkIcon, DiscountCode, MapMarker, DressCode, BulletPointEmpty, Phone } from '@withjoy/joykit/icons';
import { Linkify } from '../Linkify';
import { useTranslations } from './ItineraryBlocks.i18n';
import { PrettyLink } from '../PrettyLink';
import { DiscountCodeCoppiedNotification } from './itineraryBlocks.styles';
import globalWindow from '@shared/core/globals';
import { SafeLink } from '@shared/components/SafeLink';

const AlignedText: React.FC<TextV2Props> = ({ children, ...props }) => {
  return (
    <TextV2 display="inline-block" verticalAlign="middle" typographyVariant="body3" {...props}>
      {children}
    </TextV2>
  );
};

/////////////////////////////////////////////////////////////

const DescriptionBlock: React.FC<{ children: Maybe<string> }> = props => {
  const { children, ...restProps } = props;
  return (
    <InfoBlock {...restProps}>
      <TextV2 tagName="div" typographyVariant={'body3'}>
        <PrettyLink>{children}</PrettyLink>
      </TextV2>
    </InfoBlock>
  );
};
DescriptionBlock.displayName = 'DescriptionBlock';

/////////////////////////////////////////////////////////////
const DiscountCodeBlock: React.FC<{ handleClick?: () => void }> = props => {
  const { children, handleClick, ...restProps } = props;
  const { discountCode, textCopiedNotificationLabel } = useTranslations();
  const navigator = globalWindow.navigator;
  const [showNotification, setShowNotification] = useState(false);

  const reactNodeToString = function (reactNode: React.ReactNode): string {
    let string = '';
    if (typeof reactNode === 'string') {
      string = reactNode;
    } else if (typeof reactNode === 'number') {
      string = reactNode.toString();
    } else if (reactNode instanceof Array) {
      reactNode.forEach(function (child) {
        string += reactNodeToString(child);
      });
    } else if (isValidElement(reactNode)) {
      string += reactNodeToString(reactNode.props.children);
    }
    return string;
  };

  const copyText = (text: ReactNode) => {
    if (navigator) {
      navigator.clipboard.writeText(reactNodeToString(text));
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 2000);
    }
    handleClick && handleClick();
  };

  return (
    <InfoBlock icon={DiscountCode} {...restProps}>
      <AlignedText tagName="div">
        {discountCode}:
        {showNotification ? (
          <DiscountCodeCoppiedNotification onClick={() => setShowNotification(false)}>
            <span role="img" aria-label="sparkle">
              ✨
            </span>
            {' ' + textCopiedNotificationLabel + ' '}
            <span role="img" aria-label="sparkle">
              ✨
            </span>
          </DiscountCodeCoppiedNotification>
        ) : null}
        <TextV2 typographyVariant="body3" textDecoration="underline" onClick={() => copyText(children)}>
          {children}
        </TextV2>
      </AlignedText>
    </InfoBlock>
  );
};
DiscountCodeBlock.displayName = 'DiscountCodeBlock';

/////////////////////////////////////////////////////////////

const DressCodeBlock: React.FC = props => {
  const { children, ...restProps } = props;
  return (
    <InfoBlock icon={DressCode} {...restProps}>
      <AlignedText>{children}</AlignedText>
    </InfoBlock>
  );
};
DressCodeBlock.displayName = 'DressCodeBlock';

/////////////////////////////////////////////////////////////

const ListItemBlock: React.FC = props => {
  const { children, ...restProps } = props;
  return (
    <InfoBlock icon={BulletPointEmpty} {...restProps}>
      <AlignedText>{children}</AlignedText>
    </InfoBlock>
  );
};
ListItemBlock.displayName = 'ListItemBlock';

/////////////////////////////////////////////////////////////

const LinkBlock: React.FC<{ url?: string; handleClick?: () => void; ensureProtocol?: boolean }> = props => {
  const { children, url, handleClick, ensureProtocol = false, ...restProps } = props;
  const onLinkClicked = useCallback(() => {
    handleClick && handleClick();
  }, [handleClick]);
  return (
    <SafeLink wordBreak="break-word" ensureProtocol={ensureProtocol} onClick={onLinkClicked} isExternal={true} href={url} display="block" truncate={true}>
      <InfoBlock icon={LinkIcon} {...restProps}>
        {children}
      </InfoBlock>
    </SafeLink>
  );
};
LinkBlock.displayName = 'LinkBlock';

/////////////////////////////////////////////////////////////

const DIRECTIONS_BASE_URL = `https://www.google.com/maps/search/?api=1`;

const _getGoogleDirection = (location: string) => {
  const url = new URL(DIRECTIONS_BASE_URL);
  url.searchParams.append('query', location);
  return url.toString();
};
const MapBlock: React.FC<Readonly<{ showMap?: boolean; location?: Maybe<string>; placeId?: Maybe<string>; children?: never; handleClick?: () => void }>> = props => {
  const { children, location, placeId, showMap, handleClick, ...restProps } = props;

  const onLinkClicked = useCallback(() => {
    handleClick && handleClick();
  }, [handleClick]);

  if (!location) {
    return null;
  }

  return (
    <InfoBlock.Group {...restProps}>
      <LinkV2 wordBreak="break-word" onClick={onLinkClicked} href={_getGoogleDirection(location)} isExternal={true}>
        <InfoBlock icon={MapMarker}>{location}</InfoBlock>
      </LinkV2>
      {showMap && <Linkify data-testid={`map-${location}`} height={'320px'} a11yTitle={`Map for ${location}`} address={location} place={placeId ?? undefined} />}
    </InfoBlock.Group>
  );
};
MapBlock.displayName = 'MapBlock';

/////////////////////////////////////////////////////////////

const PhoneBlock: React.FC<{ phoneNumber: string; children?: never; handleClick?: () => void }> = props => {
  const { children, phoneNumber, handleClick, ...restProps } = props;

  const onLinkClicked = useCallback(() => {
    handleClick && handleClick();
  }, [handleClick]);

  return (
    <LinkV2 wordBreak="break-word" onClick={onLinkClicked} href={`tel:${phoneNumber}`}>
      <InfoBlock icon={Phone} {...restProps}>
        {phoneNumber}
      </InfoBlock>
    </LinkV2>
  );
};
PhoneBlock.displayName = 'PhoneBlock';

export { DescriptionBlock, DiscountCodeBlock, DressCodeBlock, ListItemBlock, LinkBlock, MapBlock, PhoneBlock };
