import { Box, styled } from '@withjoy/joykit';

export const InlineEditorWrapper = styled(Box)<{ isPreviewing: boolean }>`
  /* to overrride any pointer-events none to its children when in editing mode in preview */
  ${({ isPreviewing }) =>
    isPreviewing &&
    `
    * {
        pointer-events: all !important;
      }
  `}
`;
