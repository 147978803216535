import type { Offset, Placement, TriggerType } from './Popover.types';
import type { Placement as PopperPlacement, BasePlacement } from '@popperjs/core';

export const TRIGGER_TYPE: ReadonlyRecord<TriggerType, TriggerType> = {
  click: 'click',
  hover: 'hover'
};

const getBasePlacements = () => {
  return {
    auto: 'auto',
    top: 'top',
    bottom: 'bottom',
    right: 'right',
    left: 'left'
  } as const;
};

type PlacementMapping = {
  placement: Record<Placement, Placement>;
  fromPopperPlacement: Record<PopperPlacement, Placement>;
  toPopperPlacement: Record<Placement, PopperPlacement>;
};

const { placement: PLACEMENT, fromPopperPlacement: FROM_POPPER_PLACEMENT, toPopperPlacement: TO_POPPER_PLACEMENT } = ['auto', 'top', 'bottom', 'left', 'right'].reduce(
  (acc, placement) => {
    const startVariant = `${placement}Start` as Placement;
    const endVariant = `${placement}End` as Placement;
    const popperStartVariant = `${placement}-start` as PopperPlacement;
    const popperEndVariant = `${placement}-end` as PopperPlacement;

    // { 'topStart': 'topStart' }
    acc.placement[startVariant] = startVariant;
    acc.placement[endVariant] = endVariant;

    // { 'top-start': 'topStart' }
    acc.fromPopperPlacement[popperStartVariant] = startVariant;
    acc.fromPopperPlacement[popperEndVariant] = endVariant;

    // { 'topStart': 'top-start' }
    acc.toPopperPlacement[startVariant] = popperStartVariant;
    acc.toPopperPlacement[endVariant] = popperEndVariant;

    return acc;
  },
  {
    placement: getBasePlacements(),
    fromPopperPlacement: getBasePlacements(),
    toPopperPlacement: getBasePlacements()
  } as PlacementMapping
) as Readonly<PlacementMapping>;

export { PLACEMENT, FROM_POPPER_PLACEMENT, TO_POPPER_PLACEMENT };

export const INVERSE_PLACEMENT: ReadonlyRecord<BasePlacement, BasePlacement> = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left'
};

export const INVERSE_PLACEMENT_VARIATION = {
  bottomStart: 'left top',
  bottomEnd: 'right top',
  topStart: 'left bottom',
  topEnd: 'right bottom',
  rightStart: 'left top',
  rightEnd: 'left bottom',
  leftStart: 'right top',
  leftEnd: 'right bottom'
} as const;

export type InversePlacement = keyof typeof INVERSE_PLACEMENT_VARIATION;

export const ARROW_SIZE = 6;
/**
 * The arrow is a square, rotated 45deg. When rotated, the diagonal is <ARROW_SIZE>.
 * We use the pythagorean theorem to determine the width (in this case, width === height):
 *  width = √((arrow_size * 2)^2 / 2)
 */
export const ARROW_WIDTH = Math.ceil(Math.sqrt((ARROW_SIZE * 2) ** 2 / 2));

export const POPPER_MARGIN = 8;

export const DEFAULT_OFFSET: Offset = {
  top: 0,
  left: 0
} as const;
