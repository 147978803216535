/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Trash',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M4.05 4.55V12a1.45 1.45 0 0 0 1.45 1.45h5A1.45 1.45 0 0 0 11.95 12V4.55h-7.9zm7-1.55v.45h2.9a.55.55 0 1 1 0 1.1h-.9V12a2.55 2.55 0 0 1-2.55 2.55h-5A2.55 2.55 0 0 1 2.95 12V4.55h-.9a.55.55 0 0 1 0-1.1h2.9V3A1.55 1.55 0 0 1 6.5 1.45h3A1.55 1.55 0 0 1 11.05 3zm-5 .45V3a.45.45 0 0 1 .45-.45h3a.45.45 0 0 1 .45.45v.45h-3.9zm.55 3.1a.55.55 0 0 0-1.1 0v4.9a.55.55 0 1 0 1.1 0v-4.9zM8.05 6a.55.55 0 0 1 .55.55v4.9a.55.55 0 1 1-1.1 0v-4.9A.55.55 0 0 1 8.05 6zm1.45.55v4.9a.55.55 0 1 0 1.1 0v-4.9a.55.55 0 0 0-1.1 0z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M9.5 1.687a3.13 3.13 0 0 0-3.127 3.127v1.373H2.5v1.254H4v11.851c0 1.669 1.343 3.023 3 3.023h10c1.657 0 3-1.353 3-3.023V7.441h1.5V6.187h-3.873V4.814A3.13 3.13 0 0 0 14.5 1.687h-5zm6.873 4.5H7.627V4.814c0-1.034.839-1.873 1.873-1.873h5c1.034 0 1.873.839 1.873 1.873v1.373zM5.25 19.292V7.454h13.5v11.838c0 .974-.784 1.763-1.75 1.763H7c-.966 0-1.75-.789-1.75-1.763zm6.749-10.079c-.345 0-.625.28-.625.625v8.912c0 .345.28.625.625.625s.625-.28.625-.625V9.838c0-.345-.28-.625-.625-.625zm-4.625.625c0-.345.28-.625.625-.625s.625.28.625.625v8.912c0 .345-.28.625-.625.625s-.625-.28-.625-.625V9.838zm8.625-.625c-.345 0-.625.28-.625.625v8.912c0 .345.28.625.625.625s.625-.28.625-.625V9.838c0-.345-.28-.625-.625-.625z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M11.605 6.4A3.6 3.6 0 0 1 15 4h2a3.6 3.6 0 0 1 3.395 2.4H26.4a.6.6 0 1 1 0 1.2H25V24a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V7.6H5.6a.6.6 0 1 1 0-1.2h6.005zM8.2 7.6h15.6V24a2.8 2.8 0 0 1-2.8 2.8H11A2.8 2.8 0 0 1 8.2 24V7.6zm4.721-1.2A2.4 2.4 0 0 1 15 5.2h2a2.4 2.4 0 0 1 2.079 1.2h-6.158zm-.721 4.8a.6.6 0 0 0-1.2 0V23a.6.6 0 0 0 1.2 0V11.2zm3.9-.6a.6.6 0 0 1 .6.6V23a.6.6 0 0 1-1.2 0V11.2a.6.6 0 0 1 .6-.6zm5.1.6a.6.6 0 0 0-1.2 0V23a.6.6 0 0 0 1.2 0V11.2z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <g fill="currentColor">
            <use href="#B" />
            <path d="M24.005 15.763c.345 0 .625.28.625.625v18.25c0 .345-.28.625-.625.625s-.625-.28-.625-.625v-18.25c0-.345.28-.625.625-.625z" />
            <use href="#B" x="13.5" />
            <path
              fillRule="evenodd"
              d="M17.528 10.013c.558-2.226 2.572-3.875 4.971-3.875h3c2.399 0 4.413 1.649 4.971 3.875h9.404c.345 0 .625.28.625.625s-.28.625-.625.625H37.5v24.6a6 6 0 0 1-6 6h-15a6 6 0 0 1-6-6v-24.6H8.125c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h9.403zm-5.778 1.25h24.5v24.6a4.75 4.75 0 0 1-4.75 4.75h-15a4.75 4.75 0 0 1-4.75-4.75v-24.6zm7.081-1.25c.52-1.527 1.966-2.625 3.669-2.625h3c1.703 0 3.149 1.098 3.669 2.625H18.831z"
            />
          </g>
          <defs>
            <path id="B" d="M17.88 16.388c0-.345-.28-.625-.625-.625s-.625.28-.625.625v18.25c0 .345.28.625.625.625s.625-.28.625-.625v-18.25z" />
          </defs>
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <g fill="currentColor">
            <use href="#B" />
            <path d="M32 21a.8.8 0 0 1 .8.8v24.4a.8.8 0 1 1-1.6 0V21.8a.8.8 0 0 1 .8-.8z" />
            <use href="#B" x="18" />
            <path
              fillRule="evenodd"
              d="M23.389 13.4c.72-2.983 3.406-5.2 6.611-5.2h4c3.205 0 5.891 2.217 6.611 5.2H53.2a.8.8 0 0 1 0 1.6H50v32.8a8 8 0 0 1-8 8H22a8 8 0 0 1-8-8V15h-3.2a.8.8 0 0 1 0-1.6h12.589zM15.6 15h32.8v32.8a6.4 6.4 0 0 1-6.4 6.4H22a6.4 6.4 0 0 1-6.4-6.4V15zm9.451-1.6c.675-2.089 2.636-3.6 4.949-3.6h4c2.314 0 4.274 1.511 4.949 3.6H25.051z"
            />
          </g>
          <defs>
            <path id="B" d="M23.8 21.8a.8.8 0 1 0-1.6 0v24.4a.8.8 0 1 0 1.6 0V21.8z" />
          </defs>
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
