import React from 'react';
import { GuestTextComponentFragment } from '@graphql/generated';
import { Flex } from '@withjoy/joykit';
import { RichTextEditorGlobalStyle } from '@withjoy/joykit/components/RichTextEditor/RichTextEditor.styles';
import wrapHtmlWithSafeLink from '@shared/components/SafeLink/wrapHtmlWithSafeLink';
import { RichTextGlobalStyle } from './RichText.styles';

interface RichTextProps {
  isFirstItem?: boolean;
  isBrannanLayout?: boolean;
  richTextComponentData: GuestTextComponentFragment;
}

const RichTextWithSafeLinkBase = ({ id, text }: { id: string; text: string }) => {
  return <Flex width="100%" className={`jodit-editor richtext-${id}`} flexDirection="column" dangerouslySetInnerHTML={{ __html: text }} />;
};

const RichTextWithSafeLink = wrapHtmlWithSafeLink(RichTextWithSafeLinkBase);

export const RichText: React.FC<RichTextProps> = ({ isFirstItem, richTextComponentData }) => {
  return (
    <Flex textAlign="left" width="100%">
      {isFirstItem && (
        <>
          <RichTextGlobalStyle />
          <RichTextEditorGlobalStyle overrides={{ Paragraph: { props: { typographyVariant: 'body4' } } }} />
        </>
      )}
      <RichTextWithSafeLink id={richTextComponentData.id} text={richTextComponentData.text || ''} />
    </Flex>
  );
};
