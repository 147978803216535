import React from 'react';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { animated } from 'react-spring';

const BackdropWrapperComponent: React.FC<{ isOpen: boolean }> = ({ isOpen, ...restProps }) => <animated.div {...restProps} />;

export const BackdropWrapper = styled(BackdropWrapperComponent)<{ isOpen: boolean } & React.HTMLAttributes<any>>`
  position: fixed;
  top: 0;
  left: 0;
  --webkit-tap-highlight-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  right: 0;
  overflow: auto;
  user-select: none;
  pointer-events: ${props => (props.isOpen ? 'initial' : 'none')};
`;

export const BackdropRoot = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  --webkit-tap-highlight-color: transparent;
  background-color: black;
`;
