import { compose, margin, flex, width, padding } from 'styled-system';

import { fluidMediaQueryRules } from './Columns.utils';

import { styled, css } from '@withjoy/joykit';
import { ResponsiveMarginProps, ResponsivePaddingProps } from '@withjoy/joykit/styled-system';
import { createShouldForwardProp } from '@withjoy/joykit/utils';
import type { ColumnsV2Props } from './Columns.types';
import { Box, StyleSystemProps } from '../Box';

const composedResponsiveFunctions = compose(margin, padding, flex, width);

const shouldForwardProp = createShouldForwardProp([...composedResponsiveFunctions.propNames!, 'width', 'centered', 'fluid', 'columnGap', 'multiline', 'stackOnMobile']);

type ColumnsOuterProps = Pick<ColumnsV2Props, 'fluid'> & ResponsiveMarginProps & Pick<ResponsivePaddingProps, 'paddingX'>;

const containerStyles = css<ColumnsV2Props>`
  ${composedResponsiveFunctions};
  ${fluidMediaQueryRules};
`;

export const StyledContainer = styled(Box).withConfig<ColumnsOuterProps>({
  shouldForwardProp
})`
  width: 100%;
  ${containerStyles}
`;

export const StyledWrapper = styled(Box).withConfig<ColumnsV2Props & StyleSystemProps>({
  shouldForwardProp
})`
  /* By default, columns are stacked on mobile */
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.centered ? 'center' : undefined)};

  ${props => props.theme.mediaQueries.sm} {
    display: flex;
  }
`;
