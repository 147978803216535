import { Theme, useTheme, StyleSystemProps } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

const borderColorFromState = ({ colors }: Theme, { $isDisabled, $isFocused, $isInvalid }: UseFocusBoxStylesArgs) => {
  if ($isDisabled) {
    return 'transparent';
  } else if ($isInvalid) {
    return colors.inputBorderError;
  } else if ($isFocused) {
    return colors.inputBorderFocused;
  }
  return 'transparent';
};

type UseFocusBoxStylesArgs = {
  $isDisabled: boolean;
  $isInvalid: boolean;
  $isFocused: boolean;
};

const baseStyles: StyleSystemProps = {
  position: 'relative',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'inputBorder',
  borderRadius: 2
};

export const useFocusBoxStyles = (args: UseFocusBoxStylesArgs): StyleSystemProps => {
  const theme = useTheme();
  return {
    ...baseStyles,
    // Hover state
    _hover: {
      borderColor: args.$isDisabled ? undefined : 'mono6'
    },

    // Box shadow styles
    _after: {
      background: 'none',
      borderRadius: 2,
      boxShadow: `0px 0 0px 2px ${borderColorFromState(theme, args)}`,
      display: args.$isDisabled ? 'none' : 'block',
      content: '" "',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      transition: animationTransition('box-shadow'),
      width: '100%'
    }
  };
};
