import React from 'react';
import { StyledButtonsContainer, StyledLink, StyledRoot, StyledTextContainer, StyledTriggerPoint, styles } from './ActionBar.styles';
import { ButtonV2, TextV2 } from '@withjoy/joykit';
import { Warning, MailPersonalized, LockHeavy } from '@withjoy/joykit/icons';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useActionBarController } from '@apps/guest/packages/layout-engine/components/ActionBar/ActionBar.controller';
import { ActionBarTransition } from '@apps/guest/packages/layout-engine/components/ActionBar/ActionBarTransition';
import { VirtualEventBlock } from '@apps/guest/packages/layout-engine/components/VirtualEventBlock';
import { TelemetryProvider } from './ActionBar.telemetry';
import { useLayout } from '../../layouts/LayoutProvider';

interface ActionBarProps {
  eventId: string;
}

const ActionBarContent: React.FC<Omit<ActionBarProps, 'eventId'>> = () => {
  const [isMobile] = useResponsive({
    values: {
      xxs: true,
      xs: true,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      xxl: false
    }
  });
  const {
    isActionBarHidden,
    isTriggerPoint,
    triggerPointRef,
    shouldDisplayRSVPButton,
    isReminderWarning,
    onUnlockDialogHandle,
    buttonNotYou,
    onLogoutHandle,
    guestName,
    shouldDisplayUnlockButton,
    rsvpButtonText,
    onRsvpHandle,
    mainText,
    isStreamDisplayed,
    streamStartTime,
    streamEndTime,
    streamEvent,
    streamNow,
    onVirtualEventBlockClicked,
    unlockButtonText
  } = useActionBarController();

  const showMainContent = isReminderWarning || guestName || mainText;

  const MAIN_CONTENT = !isMobile && (
    <>
      {!isStreamDisplayed && (
        <>
          <Warning size={24} hidden={!isReminderWarning} />
          <MailPersonalized size={48} hidden={!guestName} />
        </>
      )}
      {mainText && (
        <TextV2 __css={styles.text} typographyVariant="button1" truncate={true}>
          {mainText}
        </TextV2>
      )}
      {guestName && (
        <StyledLink typographyVariant="button1" onClick={onLogoutHandle} variant="link">
          {buttonNotYou}
        </StyledLink>
      )}
    </>
  );

  return (
    <>
      {!isActionBarHidden && (
        <ActionBarTransition show={isTriggerPoint}>
          <StyledRoot flexDirection={isMobile ? 'column' : 'row'}>
            {!isMobile && showMainContent && (
              <StyledTextContainer hasIdentity={!!guestName} color={isReminderWarning ? 'negative6' : null}>
                {MAIN_CONTENT}
              </StyledTextContainer>
            )}
            {isStreamDisplayed ? (
              <VirtualEventBlock
                virtualEvent={streamEvent}
                startTime={streamStartTime}
                endTime={streamEndTime}
                hideWatchInBrowserMsg={true}
                hideStreamingByMsg={true}
                hideLocalDate={{ hideAll: streamNow, date: !streamNow }}
                hideStatusMsg={true}
                buttonWidth={!isMobile ? '288px' : 'auto'}
                onVirtualEventBlockClicked={onVirtualEventBlockClicked}
              />
            ) : (
              <>
                <StyledButtonsContainer>
                  {shouldDisplayUnlockButton && (
                    <ButtonV2 onClick={onUnlockDialogHandle} startIcon={<LockHeavy />} variant="outline" backgroundColor="white">
                      {unlockButtonText}
                    </ButtonV2>
                  )}
                  {shouldDisplayRSVPButton && (
                    <ButtonV2 onClick={onRsvpHandle} intent="primary">
                      {rsvpButtonText}
                    </ButtonV2>
                  )}
                </StyledButtonsContainer>
              </>
            )}
          </StyledRoot>
        </ActionBarTransition>
      )}
      <StyledTriggerPoint __css={styles.triggerPoint} ref={triggerPointRef} />
    </>
  );
};

const ActionBar: React.FC<ActionBarProps> = ({ eventId }) => {
  const { layout } = useLayout();
  return (
    <TelemetryProvider context={{ eventId, layout }}>
      <ActionBarContent />
    </TelemetryProvider>
  );
};

ActionBar.displayName = 'ActionBar';

export { ActionBar };
