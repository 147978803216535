import { useEffect } from 'react';

export const usePreventBodyScroll = () => {
  useEffect(() => {
    const scrollingElement = document.body;
    const scrollTop = window.getComputedStyle(scrollingElement).getPropertyValue('top');
    const originalOverflow = window.getComputedStyle(scrollingElement).getPropertyValue('overflow');
    scrollingElement.setAttribute('style', `top: ${-1 * (Number(scrollTop) || 0)}px; overflow: hidden`);
    return () => {
      scrollingElement.setAttribute('style', `top: ${-1 * (Number(scrollTop) || 0)}px; overflow: ${originalOverflow}`);
    };
  });
};
