import React, { useCallback } from 'react';
import { StyledFooterWrapper, ParticleJoyLogoWithPadding, linkStyles } from './Footer.styles';
import { useLayoutEngineTranslations } from './Footer.i18n';
import { JOY_ABOUT_URL, JOY_HELP_URL } from '@shared/utils/constants';
import { LinkV2, useTheme } from '@withjoy/joykit';

const ITEM_NAMES = {
  HELP: 'Help',
  ABOUT: 'About',
  JOY: 'Joy'
};

const Footer: React.FC<{ onButtonClicked?: (item: string) => void }> = props => {
  const { help, about } = useLayoutEngineTranslations();
  const { colors } = useTheme();
  const { onButtonClicked, ...restProps } = props;

  const handleButtonClicked = useCallback(
    (item: string) => {
      onButtonClicked && onButtonClicked(item);
    },
    [onButtonClicked]
  );

  return (
    <StyledFooterWrapper {...restProps}>
      <LinkV2 isExternal={true} onClick={() => handleButtonClicked(ITEM_NAMES.HELP)} {...linkStyles} href={JOY_HELP_URL}>
        {help}
      </LinkV2>
      <ParticleJoyLogoWithPadding onClick={() => handleButtonClicked(ITEM_NAMES.JOY)} id="footer2brand-desktop" logoColor={colors.mono10} href={'/'} />
      <LinkV2 isExternal={true} onClick={() => handleButtonClicked(ITEM_NAMES.ABOUT)} {...linkStyles} href={JOY_ABOUT_URL}>
        {about}
      </LinkV2>
    </StyledFooterWrapper>
  );
};

Footer.displayName = 'Footer';

export { Footer };
