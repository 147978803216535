import { useToast } from '@withjoy/joykit';
import { useEffect } from 'react';

type Props = {
  isCharm: boolean;
  eventId: string;
  firebaseId: string;
};

const SECRET_KEY = 'KeyJ';

export const useGuestSiteDebugger = ({ isCharm, eventId, firebaseId }: Props) => {
  const { toast } = useToast();

  useEffect(() => {
    const handler = (e: DocumentEventMap['keydown']) => {
      if (e.code === SECRET_KEY && e.altKey) {
        toast(`isCharm: ${isCharm}, eventId: ${eventId}, firebaseId: ${firebaseId}`);
      }
    };

    window.document.addEventListener('keydown', handler);

    return () => window.document.removeEventListener('keydown', handler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
