import { RadioProps } from '.';
import { styled, css, ThemeStyledProps } from '@shared/joykit/packages/core/common/styledComponents';
import { animationTransition } from '../../utils';

const themedStyle = (props: ThemeStyledProps<Pick<RadioProps, 'disabled'>>) => {
  if (props.disabled) {
    return css`
      color: inherit;
      background-color: #eaeaea;
    `;
  }
  return css`
    color: ${props.theme.colors.fillDark};
  `;
};

export const StyledIcon = styled.span<{ disabled?: boolean; checked?: boolean }>`
  height: 20px;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  position: relative;
  background-color: #ffffff;
  transition: ${animationTransition('background-color', 'border-color', 'color')};
  ::before {
    background-color: #ffffff;
  }

  ::after {
    background-color: ${props => props.theme.colors.fillDark};
    border-radius: 50%;
    content: ' ';
    height: 10px;
    opacity: 0;
    position: absolute;
    width: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: ${animationTransition('background-color', 'opacity')};
  }

  :hover:enabled {
    border-color: ${props => (props.disabled ? 'inherit' : props.theme.colors.fillDark)};
  }

  ${props => {
    if (props.checked) {
      return css`
        ::after {
          opacity: 1;
          background-color: ${props.disabled ? '#bbbbbb' : undefined};
        }
      `;
    }
    return null;
  }}

  ${themedStyle}
`;
