import { CloseButtonProps, defaultTheme, DrawerV2Props, Flex, ModalProps, styled, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const HeaderWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const DialogTitle = styled(TextV2)`
  ${({ theme }) => theme.typography.variants.hed3};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    ${({ theme }) => theme.typography.variants.hed4};
  }
`;

export const BodyWrapper = styled.div<{ removeBodyPadding: boolean }>`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    padding-top: ${props => (props.removeBodyPadding ? ' 0rem' : '2rem')};
  }
`;

export const closeButtonOverrides: NonNullable<CloseButtonProps['overrides']> = {
  Root: {
    props: {
      borderRadius: '50%',
      border: `0.0625rem solid ${defaultTheme.colors.mono5}`,
      height: '2.5rem',
      minWidth: '2.5rem',
      outline: `0.125rem solid ${defaultTheme.colors.transparent}`,
      padding: 0,
      width: '2.5rem',
      _focusVisible: {
        background: defaultTheme.colors.white,
        outline: `0.125rem solid ${defaultTheme.colors.primary8}`
      },
      _hover: {
        background: defaultTheme.colors.mono3
      },
      _active: {
        background: defaultTheme.colors.mono5
      }
    }
  },
  Icon: {
    props: {
      color: defaultTheme.colors.black
    }
  }
};

export const drawerOverrides: NonNullable<DrawerV2Props['overrides']> = {
  Root: {
    props: {
      background: 'rgb(0 0 0 / 0.3)'
    }
  },
  Content: {
    props: {
      height: 'unset',
      borderRadius: '0.5rem 0.5rem 0 0',
      width: '100vw',
      padding: '0',
      maxHeight: '100%'
    }
  }
};

export const dialogOverridesV2: NonNullable<ModalProps['overrides']> = {
  Header: {
    props: {
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: 'transparent',
      paddingTop: { _: '1rem !important', sm: '1rem' },
      paddingRight: { _: '1rem !important', sm: '1rem' }
    }
  },
  Body: { props: { padding: '0 !important' } }
};

export const drawerOverridesV2: NonNullable<ModalProps['overrides']> = {
  Content: { props: { borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: 'auto' } },
  Header: {
    props: {
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: 'transparent',
      paddingTop: '1rem !important',
      paddingRight: '1rem !important'
    }
  },
  Body: { props: { padding: '0 !important' } }
};

export const closeButtonOverridesV2: NonNullable<CloseButtonProps['overrides']> = {
  Root: {
    props: {
      borderRadius: '50%',
      height: `${pxToRem(36)}`,
      minWidth: `${pxToRem(36)}`,
      outline: `0.125rem solid ${defaultTheme.colors.transparent}`,
      padding: '0px !important',
      width: `${pxToRem(36)}`,
      background: { _: `${defaultTheme.colors.white} !important`, sm: 'transparent !important' },
      _focusVisible: {
        background: { _: `${defaultTheme.colors.white} !important`, sm: 'transparent !important' }
      },
      _hover: {
        background: { _: `${defaultTheme.colors.white} !important`, sm: 'transparent !important' }
      },
      _active: {
        background: { _: `${defaultTheme.colors.white} !important`, sm: 'transparent !important' }
      }
    }
  },
  Icon: {
    props: {
      color: defaultTheme.colors.black
    }
  }
};
