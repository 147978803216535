import { ButtonV2, styled, StyleSystemProps } from '@withjoy/joykit';

export const WelcomeVideoIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;

export const WelcomeVideoThumbnail = styled.img`
  aspect-ratio: 9 / 5;
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: 16px 0;
`;

export const ThumbnailPlayButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s opacity;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

export const StyledCloseButton = styled(ButtonV2)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const thumbnailContainerProps: StyleSystemProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const videoPlayerPortalBodyProps: StyleSystemProps = {
  maxWidth: 900,
  width: '100%',
  marginX: 4,
  paddingTop: '80px'
};
