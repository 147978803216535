import React from 'react';
import { usePriceSummaryController, PriceUpTo, CardPromotion } from './PriceSummary.controller';
import { BoxProps, Flex, TextV2 } from '@withjoy/joykit';
import { PRICE_CONFIG } from '@apps/card/config';
import { ThemeJson, CardEnvelopeRecipientAddressLayout } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { useFeatureValue } from '@shared/core/featureFlags';

export interface PriceSummaryProps extends Omit<BoxProps, 'as' | '__css'> {
  templateCategory: StationeryTemplateCategoryEnum;
  cardConfig: ThemeJson;
  title?: string;
  priceUpTo: PriceUpTo;
  salesTax?: number | null | undefined;
  /**
   * Promotion can come from the draft or the print order (depends on whether the user has
   * checked out or not)
   */
  promotion?: Maybe<CardPromotion>;
  children: (summary: ReturnType<typeof usePriceSummaryController>) => React.ReactElement;
}

export const deriveFullCustomizationsFromConfig = (config: ThemeJson, isRecipientsStepEnabled: boolean) => {
  return {
    quantity: config.customizations.quantity,
    size: config.customizations.size,
    paperType: config.customizations.paperType,
    shape: config.customizations.shape,
    backLayout: config.card.back.layout,
    returnAddress: config.card.envelope.layout,
    recipientAddress: isRecipientsStepEnabled ? config.card.envelope.recipientAddressLayout : undefined,
    delivery: config.customizations.delivery
  };
};

export const usePriceSummary = (args: Pick<PriceSummaryProps, 'templateCategory' | 'priceUpTo' | 'cardConfig' | 'promotion' | 'salesTax'>) => {
  const { cardConfig, templateCategory, priceUpTo, promotion, salesTax } = args;
  const { value } = useFeatureValue('printEnableRecipientsStep');
  const isRecipientsStepEnabled = value === 'on' || cardConfig.card.envelope.recipientAddressLayout === CardEnvelopeRecipientAddressLayout.front;
  const summary = usePriceSummaryController(
    templateCategory,
    priceUpTo,
    PRICE_CONFIG,
    deriveFullCustomizationsFromConfig(cardConfig, isRecipientsStepEnabled),
    promotion,
    salesTax
  );
  return summary;
};

const PriceSummary: React.FC<PriceSummaryProps> = ({ templateCategory, cardConfig, title, children, priceUpTo, promotion, salesTax, ...restProps }) => {
  const { value } = useFeatureValue('printEnableRecipientsStep');
  const isRecipientsStepEnabled = value === 'on' || cardConfig.card.envelope.recipientAddressLayout === CardEnvelopeRecipientAddressLayout.front;
  const summary = usePriceSummaryController(
    templateCategory,
    priceUpTo,
    PRICE_CONFIG,
    deriveFullCustomizationsFromConfig(cardConfig, isRecipientsStepEnabled),
    promotion,
    salesTax
  );

  const { t } = useTranslation('stationery');
  if (!summary) {
    return null;
  }

  const trans = t('cardCustomizer', 'priceSummary');
  const discountedTotal = summary.discountedTotal;
  const isDiscounted = !!discountedTotal;

  return (
    <Flex flexDirection="column" rowGap={6} {...restProps}>
      {title && <TextV2 typographyVariant="hed3">{title}</TextV2>}
      {children(summary)}
      <Flex flexDirection="column" paddingTop={3} borderTop="1px solid #EBEBEB">
        {promotion && (
          <Flex justifyContent="space-between" marginBottom={3}>
            <TextV2 typographyVariant="label2" color="positive7">
              {trans.discountSection.label({ code: promotion.code })}
            </TextV2>
            {summary.discountPercentage && (
              <TextV2 typographyVariant="label2" color="positive7">
                {trans.discountSection.value({ discount: summary.discountPercentage * 100 })}
              </TextV2>
            )}
          </Flex>
        )}

        <Flex justifyContent="space-between" alignItems="center">
          <TextV2 typographyVariant="hed3" fontSize="15px">
            {trans.totalLabel()}
          </TextV2>
          {isDiscounted ? (
            <Flex columnGap={4}>
              <TextV2 typographyVariant="hed3" textDecoration="line-through" fontWeight={400}>
                {summary.total}
              </TextV2>
              <TextV2 typographyVariant="hed3">{discountedTotal}</TextV2>
            </Flex>
          ) : (
            <TextV2 typographyVariant="hed3">{summary.total}</TextV2>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PriceSummary;
