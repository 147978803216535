import { animated } from 'react-spring';
import { Box, ButtonV2, css, Flex, styled } from '@withjoy/joykit';
import { buttonSharedStates } from '@shared/utils/style/buttons';

export const StyledActionButton = styled(ButtonV2)`
  ${buttonSharedStates};
`;

export const FocalPoint = styled(Flex)``;
export const Cell = styled(Flex)<{ isActive?: boolean }>`
  ${({ isActive }) =>
    isActive &&
    css`
      ${FocalPoint} {
        &::after {
          content: '';
          display: block;
          position: absolute;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 2px solid white;
          box-shadow: 0px 0px 4px 0px rgba(44, 41, 37, 0.12);
        }
      }
    `}
`;

export const HighlightMask = styled(animated(Box))`
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.24));
  mix-blend-mode: overlay;
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 0px 80px 160px #ffffff;
  backdrop-filter: unset;
  -webkit-backdrop-filter: blur(0); /* fix for white blur issue in safari */
`;
