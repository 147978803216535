/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Unlocked Thin',
  definitions: {
    '48': {
      viewBox: '0 0 43 39',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.625 9.18182C24.625 5.06302 28.3172 1.625 33 1.625C37.6828 1.625 41.375 5.06302 41.375 9.18182V16H42.625V9.18182C42.625 4.26322 38.2584 0.375 33 0.375C27.7416 0.375 23.375 4.26322 23.375 9.18182V15H3C1.34315 15 0 16.3431 0 18V36C0 37.6569 1.34315 39 3 39H27C28.6569 39 30 37.6569 30 36V18C30 16.3431 28.6569 15 27 15H24.625V9.18182ZM3 16.25H27C27.9665 16.25 28.75 17.0335 28.75 18V36C28.75 36.9665 27.9665 37.75 27 37.75H3C2.0335 37.75 1.25 36.9665 1.25 36V18C1.25 17.0335 2.0335 16.25 3 16.25Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
