import React from 'react';
import { GuestSiteEventDesign } from '../../../layout.types';
import { brannanConfig, useBrannanDesign } from '../../LayoutBrannan.theme';
import { FrameInner, FrameOuter } from '../Frames';
import { Page } from '../Page';
import chroma from 'chroma-js';

const { layerBack, layerMiddle } = brannanConfig.applicatorHtmlProps;

export interface ShellProps {
  eventDesign?: GuestSiteEventDesign;
  noTranslucency?: boolean;
  isAlohaGuestPage?: boolean;
  isCustomPage?: boolean;
}

export const Shell: React.FC<ShellProps> = ({ eventDesign, noTranslucency, isAlohaGuestPage, isCustomPage, children }) => {
  const { applicatorConfig, loading, CustomStyle } = useBrannanDesign(eventDesign, isAlohaGuestPage);

  // For some themes (e.g. Rustic Chic) custom background color is not being applied even if enabled
  // This workaround allows to override the default theme color
  const customBackgroundColorConfig = applicatorConfig?.layerBack.customBackgroundColor;
  const customBackgroundColor =
    customBackgroundColorConfig?.isEnabled && customBackgroundColorConfig?.backgroundColor
      ? chroma(customBackgroundColorConfig?.backgroundColor as Array<number>).hex()
      : undefined;

  return (
    <FrameOuter loading={loading} applicatorProp={layerBack.prop} customBackgroundColor={customBackgroundColor} isCustomPage={isCustomPage}>
      {CustomStyle && <CustomStyle />}
      <FrameInner>
        <Page applicatorProp={layerMiddle.prop} showBorder={applicatorConfig?.layerMiddle.border?.isEnabled} noTranslucency={noTranslucency}>
          {children}
        </Page>
      </FrameInner>
    </FrameOuter>
  );
};
