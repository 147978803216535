import React from 'react';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';
import { useSpring } from 'react-spring';
import { StyledTransitionWrapper } from '@apps/guest/packages/layout-engine/components/ActionBar/ActionBar.styles';

export interface PreviewFadeTransition {
  show: boolean;
}

export const ActionBarTransition: React.FC<PreviewFadeTransition> = ({ children, show }) => {
  const springProps = useSpring({
    opacity: show ? 1 : 0,
    offset: show ? 0 : -40,
    config: {
      duration: 500,
      easing: cubicBezierEasingFn
    }
  });

  return (
    <StyledTransitionWrapper style={{ opacity: springProps.opacity, transform: springProps.offset.interpolate(x => `translate3d(0, ${x}px, 0)`) }}>
      {children}
    </StyledTransitionWrapper>
  );
};
