import { MutableRefObject, useEffect } from 'react';

import { useComponentHealthProvider } from '../ComponentHealthProvider';
import { ACTION_COMPONENT_LOAD_START } from '../constants';
import { ComponentHealthTelemetryData, ComponentInfo } from '../types';

export function useLoadStartEffect(componentInfoRef: MutableRefObject<ComponentInfo>): void {
  const { onAction } = useComponentHealthProvider();

  useEffect(() => {
    const { componentName, loadId } = componentInfoRef.current;
    const telemetryContext: ComponentHealthTelemetryData = {
      componentName,
      loadId
    };

    onAction(ACTION_COMPONENT_LOAD_START, telemetryContext);

    // Only run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
