import React from 'react';
import { ButtonV2, FormControl, InputV2, TextV2 } from '@withjoy/joykit';
import { StyledContent, StyledFormStack, StyledHeaderStack, StyledInputsStack, StyledTitleStack, styles } from '../UnlockForm/UnlockForm.styles';
import { useAskHostFormController } from './AskHostForm.controller';

export interface AskHostFormProps {
  eventId: string;
  onClose: () => void;
}

export const AskHostForm: React.FC<AskHostFormProps> = ({ eventId, onClose }) => {
  const {
    formik,
    loading,
    title,
    subtitle,
    fullNameLabel,
    emailLabel,
    submitButtonText,
    nameInputError,
    emailInputError,
    namePlaceholder,
    emailPlaceholder
  } = useAskHostFormController({
    eventId,
    onClose
  });

  return (
    <StyledContent __css={styles.content}>
      <StyledHeaderStack>
        <StyledTitleStack>
          <TextV2 typographyVariant="display4">{title}</TextV2>
          <TextV2 typographyVariant="body4">{subtitle}</TextV2>
        </StyledTitleStack>
      </StyledHeaderStack>
      <StyledFormStack spacing={6} __css={styles.formStack}>
        <form onSubmit={formik.handleSubmit}>
          <StyledInputsStack>
            <FormControl label={fullNameLabel}>
              <InputV2
                placeholder={namePlaceholder}
                isInvalid={(nameInputError && formik.touched.name) || undefined}
                enableFocusOnMount
                isDisabled={loading}
                {...formik.getFieldProps('name')}
              />
            </FormControl>
            <FormControl label={emailLabel}>
              <InputV2 placeholder={emailPlaceholder} isInvalid={(emailInputError && formik.touched.email) || undefined} isDisabled={loading} {...formik.getFieldProps('email')} />
            </FormControl>
          </StyledInputsStack>
          <ButtonV2 width={'100%'} intent="primary" type="submit" loading={loading}>
            {submitButtonText}
          </ButtonV2>
        </form>
      </StyledFormStack>
    </StyledContent>
  );
};
