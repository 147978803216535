import { ParseOptions } from 'query-string';
import curations from '@assets/icons/catalog-curations.svg';

export const CATALOG_PATH = '/shop';
export const BABY_SHOP_PATH = '/shop/baby';
export const CURATIONS_PATH = '/curations';
export const SHIPPING_POLICY_PATH = '/shipping-policy';
export const RETURN_POLICY_PATH = '/return-policy';

export const CURATION_DEPARTMENT_ICON = curations;

export const SKELETON_ITEMS_COUNT = 5;
export const CURATION_TILE_IMAGE_COUNT = 3;

export const PRODUCT_TILE_MAX_LENGTH = 56;

export const CATALOG_PRODUCT_LIST_PAGE_SIZE = 48;

export const RECOMMENDED_PRODUCTS_PAGE_SIZE = 12;

export const DEFAULT_CUSTOM_ITEM_IMAGE = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';

export const CATALOG_SEARCH_ICON_BUTTON = 'catalogSearchIcon';

export const PRODUCT_FILTER_QS_PARSE_OPTIONS: ParseOptions = { arrayFormat: 'separator', arrayFormatSeparator: '|' };

export const REFINEMENT_PANEL_STICKY_THRESHOLD_CSS_VAR = '--rp-stickythreshold';
export const REFINEMENT_PANEL_TOGGLE_TOP_CSS_VAR = '--rpt-top';

export const PRODUCT_GRID_BREAKPOINTS = ['mobile', 'sm2', 'md3', 'lg', 'lg1'] as const;
export const CAROUSEL_BREAKPOINTS = PRODUCT_GRID_BREAKPOINTS;
