/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Discount Code',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.2 12c0-5.404 4.396-9.8 9.8-9.8 5.404 0 9.8 4.396 9.8 9.8 0 5.404-4.396 9.8-9.8 9.8-5.404 0-9.8-4.396-9.8-9.8zM12 1C5.933 1 1 5.933 1 12s4.933 11 11 11 11-4.933 11-11S18.067 1 12 1zm.648 16.72v-.717a3.099 3.099 0 0 0 2.432-3.129 2.926 2.926 0 0 0-1.181-2.2c-.634-.474-1.268-.618-1.772-.732l-.035-.008c-.533-.116-.81-.186-1.065-.464-.255-.277-.417-.579-.394-.856 0-.278.162-.487.324-.649.533-.44 1.436-.44 1.968.024.158.159.389.277.61.242l.707-.11c.297-.046.489-.345.346-.61a2.721 2.721 0 0 0-.597-.75c-.393-.324-.834-.579-1.343-.695v-.764a.787.787 0 0 0-1.575 0v.788C9.99 7.45 9.053 8.304 9.01 9.522c-.023.718.278 1.458.857 2.061.602.602 1.274.764 1.852.903.44.092.811.185 1.204.463.325.255.51.602.533.996a1.44 1.44 0 0 1-.463 1.134c-.533.487-1.459.487-2.038.024a1.212 1.212 0 0 1-.194-.193c-.179-.224-.437-.444-.72-.404l-.605.086c-.274.04-.468.296-.38.557.157.46.438.878.834 1.205a3.17 3.17 0 0 0 1.182.602v.764a.787.787 0 1 0 1.575 0z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
