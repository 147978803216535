/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Arrow Up',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.3889 7.38886C14.1741 7.60365 13.8258 7.60365 13.611 7.38886L8.54995 2.32777V15C8.54995 15.3037 8.30371 15.55 7.99995 15.55C7.69619 15.55 7.44995 15.3037 7.44995 15V2.32777L2.38886 7.38886C2.17407 7.60365 1.82583 7.60365 1.61104 7.38886C1.39625 7.17407 1.39625 6.82583 1.61104 6.61104L7.61104 0.611042C7.82583 0.396254 8.17407 0.396254 8.38886 0.611042L14.3889 6.61104C14.6036 6.82583 14.6036 7.17407 14.3889 7.38886Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7947 3.06903L20.2582 10.5357C20.6118 10.8861 21.1799 10.8861 21.5336 10.5357C21.8832 10.1828 21.8832 9.61396 21.5336 9.26031C21.5336 9.26031 14.6822 2.41294 12.5355 0.262237C12.1818 -0.0874124 11.613 -0.0874124 11.2601 0.262237C9.21022 2.31613 2.58048 8.94187 2.26524 9.26031C1.91159 9.61396 1.91159 10.1828 2.26524 10.5357C2.61489 10.8861 3.18377 10.8861 3.53742 10.5357L10.9945 3.07544V23.0999C10.9945 23.3375 11.0881 23.5655 11.2569 23.7376C11.4257 23.9064 11.657 24 11.8946 24C12.1322 24 12.3635 23.9064 12.5323 23.7376C12.7011 23.5655 12.7947 23.3375 12.7947 23.0999V3.06903Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
