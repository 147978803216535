import React from 'react';
import { Link } from '@react-router';
import { TextV2, ButtonV2, Box } from '@withjoy/joykit';
import { useGetStationeryPrintOrderByOrderNumberQuery } from '@graphql/generated';
import { TempSpinner } from '@shared/components/TempSpinner';
import { useRouterHelper } from '@shared/core';
import { routePaths } from '@apps/serviceCenter/route.paths';
import { getConciergeCategoryFromDesignSetName, getProductOfferingFromDesignSetName } from '@apps/card/routes/Concierge/Concierge.utils';
import { ArrowLeft } from '@withjoy/joykit/icons';

interface PrintOrderSearchByOrderNumberProps {
  printOrderNumber: string;
}

export const PrintOrderSearchByOrderNumber = (props: PrintOrderSearchByOrderNumberProps) => {
  const { printOrderNumber } = props;
  const { buildPath } = useRouterHelper();
  const { data, loading } = useGetStationeryPrintOrderByOrderNumberQuery({
    variables: {
      orderNumber: printOrderNumber
    },
    batchMode: 'fast',
    skip: !printOrderNumber
  });

  const queryData = data?.stationeryPrintOrderByOrderNumber;

  if (loading) return <TempSpinner />;

  if (!queryData) return null;

  const conciergeSuiteName = queryData.designSetName ? getProductOfferingFromDesignSetName(queryData.designSetName)?.longName : null;
  const category = conciergeSuiteName ? getConciergeCategoryFromDesignSetName(queryData.designSetName!) : queryData.cardDraft?.stationeryTemplate.category;

  return (
    <Box as="main" paddingX={'2.5rem'}>
      <Box display="grid" rowGap={'1.5rem'} maxWidth={'1440px'} marginX={'auto'} marginTop={10}>
        <ButtonV2 as={Link} to={buildPath(routePaths.print.path)} variant="link" fullWidth={false} justifySelf={'self-start'}>
          <ArrowLeft size="md" marginRight={'0.5rem'} /> Back to Print
        </ButtonV2>
        <Box display="grid" gridTemplateColumns={'max-content 1fr'} rowGap="10px" columnGap={'1.5rem'} paddingTop={6}>
          <TextV2 typographyVariant={'hed2'}>Order Number:</TextV2>
          <TextV2>{printOrderNumber}</TextV2>
          <TextV2 typographyVariant={'hed2'}>ID:</TextV2>
          <TextV2>{queryData.id}</TextV2>
          <TextV2 typographyVariant={'hed2'}>Price:</TextV2>
          <TextV2>{queryData.priceInMinorUnits / 100}</TextV2>
          <TextV2 typographyVariant={'hed2'}>Quantity:</TextV2>
          <TextV2>{queryData.quantity}</TextV2>
          {queryData.eventId && (
            <>
              <TextV2 typographyVariant={'hed2'}>Event ID:</TextV2>
              <TextV2> {queryData.eventId}</TextV2>
            </>
          )}
          <TextV2 typographyVariant={'hed2'}>Owner ID:</TextV2>
          <TextV2>{queryData.ownerId}</TextV2>
          {queryData.cardDraft?.stationeryTemplate.id && (
            <>
              <TextV2 typographyVariant={'hed2'}>Template ID:</TextV2>
              <TextV2>{queryData.cardDraft.stationeryTemplate.id}</TextV2>
            </>
          )}
          {queryData.cardDraft?.stationeryTemplate.themeId && (
            <>
              <TextV2 typographyVariant={'hed2'}>Template Theme ID:</TextV2>
              <TextV2>{queryData.cardDraft.stationeryTemplate.themeId}</TextV2>
            </>
          )}
          {category && (
            <>
              <TextV2 typographyVariant={'hed2'}>Template Category:</TextV2>
              <TextV2>{category}</TextV2>
            </>
          )}
          {conciergeSuiteName && (
            <>
              <TextV2 typographyVariant={'hed2'}>Concierge Suite Name:</TextV2>
              <TextV2>{conciergeSuiteName}</TextV2>
            </>
          )}
          <TextV2 typographyVariant={'hed2'}>Delivery Method:</TextV2>
          <TextV2>{queryData.deliveryMethod}</TextV2>
          <TextV2 typographyVariant={'hed2'}>Sales Order ID:</TextV2>
          <TextV2> {queryData.printPayment?.salesOrderId}</TextV2>
          <TextV2 typographyVariant={'hed2'}>Status:</TextV2>
          <TextV2> {queryData.currentStatus}</TextV2>
          <TextV2 typographyVariant={'hed2'}>Created At:</TextV2>
          <TextV2>{queryData.createdAt}</TextV2>
        </Box>
        {queryData.eventId && (
          <ButtonV2 as={Link} to={`${buildPath(routePaths.printOrder.goToPath(printOrderNumber))}?eventId=${queryData.eventId}`} justifySelf={'self-start'} target="_blank">
            Go to Order Detail
          </ButtonV2>
        )}
      </Box>
    </Box>
  );
};
