import React, { useContext } from 'react';

import { addAction, addError } from './defaultHealthLogging';

export interface ComponentHealthContext {
  readonly onError: (error: unknown, context?: object) => void;
  readonly onAction: (actionName: string, context?: object) => void;
}

const Context = React.createContext<Partial<ComponentHealthContext> | undefined>(undefined);

/**
 * Optional provider to override ComponentHealth functionality. Use this provider to override or mock (during testing) functions used in
 * ComponentHealth, like error and action logging.
 *
 * If no provider is provided, default values will be used.
 */
export const ComponentHealthProvider: React.FC<Partial<ComponentHealthContext>> = ({ children, ...props }) => <Context.Provider value={{ ...props }}>{children}</Context.Provider>;

export function useComponentHealthProvider(): ComponentHealthContext {
  const context = useContext(Context);

  return {
    onError: context?.onError ?? addError,
    onAction: context?.onAction ?? addAction
  };
}
