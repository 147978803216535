import { useDisclosure } from '@withjoy/joykit';
import { useUnlockDialogContext } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialogProvider';
import { useAuth } from '@shared/components/AuthProvider';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { useUserProfile } from '@shared/utils/useUserProfile';
import { DesignLayoutType, useGetEventCTAButtonsDataQuery, useLogoutEventSessionMutation } from '@graphql/generated';
import { performFullPageRedirect } from '@shared/utils/navigation';
import { useHistory } from '@react-router';
import { useTranslation } from '@shared/core';
import { useCallback, useMemo } from 'react';
import { NavbarEventPropsInterface } from '../../../layout.types';
import { notNullOrUndefined } from '@shared/utils/notNullOrUndefined';
import { useCtaButtons } from '@apps/guest/packages/layout-engine/common/utils/useCtaButtons';
import { CtaButtonsSessionData } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons.types';

interface EventMenuControllerProps {
  eventHandle: string;
  handleMenuClicked?: (title: string) => void;
  handleMenuOpenedChange?: (opened: boolean) => void;
  eventProps: NavbarEventPropsInterface;
}

export const useEventMenuController = ({ eventHandle, handleMenuClicked, handleMenuOpenedChange, eventProps }: EventMenuControllerProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { handleUnlockDialogOpen } = useUnlockDialogContext();
  const { isAdmin } = useEventUserRole();
  const { getButtonsVisibility, getUnlockButtonText, getRSVPButtonText } = useCtaButtons();
  const history = useHistory();
  const { t2 } = useTranslation('layoutEngine');
  const { buttonNotYouText } = t2('components', 'eventCta');

  let profileFirstName, profileLastName, profileEmail;

  const {
    currentUser: { profile },
    loginManager
  } = useAuth();

  if (profile) {
    ({ firstName: profileFirstName, lastName: profileLastName, email: profileEmail } = profile);
  }

  const profileFullName = [profileFirstName, profileLastName].filter(name => !!name).join(' ');
  const profileInitials = useUserProfile().initials;

  const [logoutEvent] = useLogoutEventSessionMutation({ variables: { eventId: eventProps?.eventId } });
  // Marking this query as `ssr: false` because any RSVP data query is proxied through app-server-api -- which takes long to resolve.
  const { data: sessionData } = useGetEventCTAButtonsDataQuery({
    variables: { name: eventHandle || '' },
    batchMode: 'fast',
    ssr: false
  });

  const { guestFullName, guestInitials, guestEmail, layout, rsvpIsEnabled, hasUnlocked, pages, scheduleHasInviteOnlyItems } = useMemo(() => {
    const guestFullName = sessionData?.eventByName?.activeSession.person?.fullName || '';
    const guestInitials = sessionData?.eventByName?.activeSession.person?.avatarName || '';
    const guestEmail = sessionData?.eventByName?.activeSession.person?.email || '';
    const hasUnlocked = sessionData?.eventByName?.activeSession.hasUnlocked || false;
    const layout = eventProps?.layoutType || DesignLayoutType.aloha;
    const rsvpIsEnabled = sessionData?.eventByName?.activeSession.eventCTA?.rsvpIsEnabled || false;
    const isRsvpRequiresPassword = sessionData?.eventByName?.activeSession.eventCTA?.requiresPassword || false;
    const scheduleHasInviteOnlyItems = sessionData?.eventByName?.activeSession.eventCTA.scheduleHasInviteOnlyItems ?? false;
    const origPages = eventProps?.pages ?? [];
    const pages = origPages
      .map(page => {
        if (page.type === 'tidbits' && layout === DesignLayoutType.brannan) {
          return null;
        }
        return page;
      })
      .filter(notNullOrUndefined);

    return {
      guestFullName,
      guestInitials,
      guestEmail,
      layout,
      rsvpIsEnabled,
      isRsvpRequiresPassword,
      hasUnlocked,
      pages,
      scheduleHasInviteOnlyItems
    };
  }, [eventProps, sessionData]);

  const handleClickItemMenu = useCallback(
    (title: string) => {
      handleMenuClicked && handleMenuClicked(title);
      onClose();
    },
    [handleMenuClicked, onClose]
  );

  const unlockDialogHandle = useCallback(() => {
    handleMenuClicked && handleMenuClicked('unlockDialog');
    onClose();
    handleUnlockDialogOpen();
  }, [handleUnlockDialogOpen, onClose, handleMenuClicked]);

  const rsvpHandle = useCallback(() => {
    handleMenuClicked && handleMenuClicked('rsvp');
    performFullPageRedirect(`/${eventHandle}/rsvp`);
  }, [handleMenuClicked, eventHandle]);

  const handleOnOpen = useCallback(() => {
    onOpen();
    handleMenuOpenedChange && handleMenuOpenedChange(true);
  }, [onOpen, handleMenuOpenedChange]);

  const handleOnClose = useCallback(() => {
    onClose();
    handleMenuOpenedChange && handleMenuOpenedChange(false);
  }, [onClose, handleMenuOpenedChange]);

  const onProfileHandle = useCallback(() => {
    handleMenuClicked && handleMenuClicked('Account');
    history.push('/account/events');
  }, [handleMenuClicked, history]);

  const logoutHandle = async () => {
    onClose();
    if (!profile) {
      await logoutEvent({ refetchQueries: () => ['GetEventSession', 'GetGuestSiteSchedule', 'GetEventCTAButtonsData'] });
    } else {
      //This is a temporary solution until the useAuth().provideLogout is fixed.
      // Problem ticket https://withjoy.atlassian.net/browse/WEB-1674
      await loginManager.authService.clearToken();
      await logoutEvent();
      window.location.reload();
    }
  };

  const event: CtaButtonsSessionData = sessionData?.eventByName ?? {};
  const { activeSession, welcomePage, storyPage, tidbitsPage, schedulePage, travelPage, faqPage, weddingPage, registryPage, momentsPage, rsvpPage } = event;
  const initialPages = [welcomePage, storyPage, tidbitsPage, schedulePage, travelPage, faqPage, weddingPage, registryPage, momentsPage, rsvpPage];
  const { label: rsvpLabel, rsvpAttendanceStatus } = activeSession?.eventCTA || {};
  const rsvpIsPasswordProtected = rsvpPage?.private;
  const { shouldDisplayUnlockButton, shouldDisplayRSVPButton } = getButtonsVisibility(
    initialPages,
    welcomePage,
    schedulePage,
    rsvpIsEnabled,
    rsvpIsPasswordProtected,
    scheduleHasInviteOnlyItems,
    hasUnlocked
  );
  const unlockButtonText = getUnlockButtonText(hasUnlocked);
  const rsvpButtonText = getRSVPButtonText(rsvpAttendanceStatus, rsvpLabel);

  return {
    onOpen: handleOnOpen,
    isOpen,
    onClose: handleOnClose,
    name: !!profile ? profileFullName : guestFullName,
    layout,
    email: !!profile ? profileEmail : guestEmail,
    initials: !!profile ? profileInitials : guestInitials,
    isAdmin,
    unlockDialogHandle,
    rsvpHandle,
    shouldDisplayRSVPButton,
    shouldDisplayUnlockButton,
    pages,
    isProfile: !!profile,
    onProfileHandle,
    logoutHandle,
    buttonNotYouText,
    handleClickItemMenu,
    unlockButtonText,
    rsvpButtonText
  };
};
