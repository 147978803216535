/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Plane',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M20.948 3.052c-.592-.592-1.549-.604-2.156-.027l-4.484 4.265a.6.6 0 0 1-.594.137L4.581 4.547c-.172-.054-.36-.008-.487.119l-.811.811 7.317 4.634a.6.6 0 0 1 .14.891l-3.543 4.251a.6.6 0 0 1-.579.204l-2.887-.578-.77.77 3.215 1.979a.6.6 0 0 1 .196.196l1.979 3.215.77-.769-.578-2.887a.6.6 0 0 1 .204-.579l4.251-3.543a.6.6 0 0 1 .891.14l4.634 7.317.811-.811c.127-.128.174-.315.119-.487l-2.881-9.133a.6.6 0 0 1 .137-.594l4.265-4.484c.577-.607.565-1.563-.027-2.156zm-2.983-.896c1.079-1.026 2.779-1.005 3.831.048s1.074 2.753.048 3.831l-4.021 4.228 2.774 8.795c.189.599.029 1.253-.415 1.697l-1.341 1.341a.6.6 0 0 1-.931-.103l-4.669-7.373-3.451 2.877.571 2.852a.6.6 0 0 1-.164.542l-1.534 1.534a.6.6 0 0 1-.935-.11l-2.302-3.74-3.74-2.302a.6.6 0 0 1-.11-.935l1.534-1.534a.6.6 0 0 1 .542-.164l2.852.571L9.38 10.76 2.007 6.09a.6.6 0 0 1-.103-.931l1.341-1.341c.444-.444 1.098-.604 1.697-.415l8.796 2.774 4.228-4.021z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M27.525 4.475c-.818-.818-2.14-.835-2.979-.037l-5.718 5.438a.6.6 0 0 1-.594.138L6.59 6.34a.78.78 0 0 0-.788.193l-1.18 1.18 9.504 6.019a.6.6 0 0 1 .14.891l-4.517 5.42a.6.6 0 0 1-.579.204l-3.768-.755-1.191 1.191 4.276 2.633a.6.6 0 0 1 .196.196l2.633 4.276 1.191-1.191-.755-3.768a.6.6 0 0 1 .204-.579l5.42-4.517a.6.6 0 0 1 .891.14l6.019 9.504 1.18-1.18a.78.78 0 0 0 .193-.788l-3.673-11.645a.6.6 0 0 1 .137-.594l5.439-5.718c.798-.839.781-2.16-.037-2.979zm-3.806-.907c1.31-1.246 3.376-1.221 4.654.058s1.305 3.344.058 4.654l-5.194 5.461 3.567 11.307a1.98 1.98 0 0 1-.489 1.997l-1.71 1.71a.6.6 0 0 1-.931-.103l-6.054-9.559L13 22.944l.748 3.733a.6.6 0 0 1-.164.542l-1.956 1.955a.6.6 0 0 1-.935-.11l-2.956-4.802-4.802-2.956a.6.6 0 0 1-.11-.935l1.956-1.956a.6.6 0 0 1 .542-.164L9.056 19l3.851-4.62-9.559-6.054a.6.6 0 0 1-.103-.931l1.71-1.71a1.98 1.98 0 0 1 1.997-.489l11.307 3.567 5.461-5.194z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M41.365 6.635a3.45 3.45 0 0 0-4.815-.06l-8.52 8.104c-.165.157-.402.212-.619.143L10.058 9.348c-.51-.161-1.067-.025-1.445.353l-1.996 1.996 14.447 9.15c.152.096.255.253.283.431s-.022.359-.137.497l-6.732 8.077c-.147.176-.378.258-.603.213l-5.756-1.153-2.118 2.118 6.663 4.102c.083.051.153.121.205.205l4.102 6.663 2.118-2.118-1.153-5.756c-.045-.225.037-.456.213-.603l8.077-6.732c.138-.115.319-.165.497-.137s.335.131.431.283l9.149 14.446 1.996-1.996c.378-.378.514-.935.353-1.445l-5.474-17.353c-.068-.217-.014-.454.143-.619l8.104-8.52a3.45 3.45 0 0 0-.06-4.815zm-5.676-.966a4.7 4.7 0 0 1 6.642 6.642l-7.85 8.253 5.363 17.001c.301.955.046 1.997-.662 2.705l-2.548 2.548c-.134.134-.322.2-.511.179s-.358-.127-.459-.287l-9.186-14.504-7.244 6.037 1.146 5.719c.041.205-.023.417-.171.565l-2.914 2.914c-.135.135-.325.201-.515.179s-.359-.13-.459-.293l-4.439-7.21-7.21-4.439c-.163-.1-.271-.269-.293-.459s.044-.38.179-.515l2.914-2.914c.148-.148.36-.212.565-.171l5.719 1.146 6.038-7.244-14.505-9.186c-.16-.102-.266-.27-.287-.459s.045-.377.179-.511l2.548-2.548c.708-.708 1.75-.963 2.705-.662l17.002 5.363 8.253-7.85z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M55.177 8.823a4.63 4.63 0 0 0-6.466-.081l-11.36 10.806a.8.8 0 0 1-.792.183l-23.138-7.298c-.692-.218-1.447-.033-1.96.479l-2.69 2.69 19.297 12.222a.8.8 0 0 1 .186 1.188l-8.976 10.769a.8.8 0 0 1-.772.272l-7.692-1.541-2.867 2.867 8.92 5.491a.8.8 0 0 1 .262.262l5.491 8.92 2.867-2.867-1.541-7.692a.8.8 0 0 1 .272-.772l10.769-8.975a.8.8 0 0 1 1.188.186l12.221 19.297 2.69-2.69c.513-.513.698-1.268.48-1.96l-7.298-23.137a.8.8 0 0 1 .183-.792l10.806-11.36a4.63 4.63 0 0 0-.081-6.466zm-7.569-1.24a6.23 6.23 0 0 1 8.809 8.809L45.937 27.41l7.156 22.687c.398 1.261.061 2.638-.874 3.573l-3.397 3.397a.8.8 0 0 1-1.242-.138L35.312 37.558l-9.703 8.087 1.532 7.645a.8.8 0 0 1-.219.723l-3.886 3.886a.8.8 0 0 1-1.247-.146l-5.922-9.62-9.62-5.922a.8.8 0 0 1-.146-1.247l3.886-3.886a.8.8 0 0 1 .723-.219l7.645 1.532 8.087-9.703L7.07 16.419a.8.8 0 0 1-.138-1.242l3.397-3.397c.935-.935 2.312-1.272 3.573-.874l22.688 7.156 11.018-10.48z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
