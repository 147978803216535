import { useEffect, useRef } from 'react';

import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

export type OnComponentHealthHookRegistered = () => void;

/**
 * Report loadComplete if no `useComponentHealth` hook is registered on first render.
 */
export function useAutoLoadComplete(onAutoLoadComplete: () => void): OnComponentHealthHookRegistered {
  const hasAnyHookRegistered = useRef(false);

  useEffect(() => {
    if (!hasAnyHookRegistered.current) {
      onAutoLoadComplete();
    }

    // Only run after first render cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onComponentHealthHookRegistered = useEventCallback(() => {
    hasAnyHookRegistered.current = true;
  });

  return onComponentHealthHookRegistered;
}
