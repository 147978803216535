import { addDays, subDays, isBefore, isValid, isSameMonth, differenceInCalendarDays } from 'date-fns';

const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;

export const useDateFromDatestring = (dateString?: string) => {
  if (!dateString) {
    return null;
  }
  return new Date(createDateFromUnformattedString(dateString).toLocaleDateString('en-US'));
};

export const useCalculatedCheckIn = (dateString?: string) => {
  if (!dateString || isBefore(new Date(dateString), new Date())) {
    return null;
  }
  return subDays(new Date(dateString), 1);
};

export const useCalculatedCheckOut = (dateString?: string) => {
  if (!dateString || isBefore(new Date(dateString), new Date())) {
    return null;
  }
  return addDays(new Date(dateString), 1);
};

export const validateCheckInCheckOut = (dateCheckInCheckOut: Date) => {
  const today = new Date();
  if (isBefore(dateCheckInCheckOut, today)) {
    return null;
  }
  return dateCheckInCheckOut;
};

export const createDateFromUnformattedString = (dateString: string): Date => {
  if (dateOnlyRegex.test(dateString)) {
    return new Date(`${dateString}T00:00:00`);
  }

  if (isValid(new Date(dateString))) {
    return new Date(dateString);
  }

  return new Date();
};

export const isSameMonthCheckInCheckOut = (inDate: string, outDate: string) => {
  if (isSameMonth(new Date(inDate), new Date(outDate))) {
    return true;
  }
  return false;
};

export const isCutOffDateNinteyOrFewerDaysAway = (dateStringCutOffDate: string) => {
  const today = new Date();
  if (differenceInCalendarDays(new Date(dateStringCutOffDate), today) <= 90) {
    return true;
  }
  return false;
};
