import { EventPageFragment } from '@graphql/generated';
import { RefObject, useContext } from 'react';

import { AuxFrameContext } from '../components/AuxFrame';
import { useScrollToRef } from './useScrollToRef';

export const useAlohaScrollWatch = (pageRef: RefObject<HTMLElement>, page?: Maybe<EventPageFragment>) => {
  // change the aux panel panel image
  const { page: currentPage } = useContext(AuxFrameContext);
  useScrollToRef(pageRef, page?.id);

  // detect when page comes into view from the bottom
  // and update location for appropriate page slug
  // eslint-disable-next-line react-hooks/exhaustive-deps

  return { currentPage };
};
