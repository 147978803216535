import React, { useCallback, useRef } from 'react';
import { useHistory } from '@react-router';
import { ScrollToContext } from './ScrollTo.context';
import { ScrollToRegisterFunction, ScrollToFunction } from './ScrollTo.types';

export const ScrollToProvider: React.FC<{}> = ({ children }) => {
  const observerRef = useRef<Record<string, (casually?: boolean) => void>>({});
  const locationTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const history = useHistory();

  const scrollTo = ((id: string, casually?: boolean) => {
    const observeFunc = observerRef.current[id];
    if (observeFunc) {
      observeFunc(casually);
    }
  }) as ScrollToFunction;

  const register = ((id: string, callback: () => void) => {
    observerRef.current[id] = callback;
    return () => {
      delete observerRef.current[id];
    };
  }) as ScrollToRegisterFunction;

  const hackySolution = useCallback(
    (pathname: string) => {
      if (locationTimeout.current) {
        clearTimeout(locationTimeout.current);
        locationTimeout.current = undefined;
      }
      locationTimeout.current = setTimeout(() => {
        history.replace({ ...history.location, pathname: pathname });
      }, 500);
    },
    [history, locationTimeout]
  );

  return (
    <ScrollToContext.Provider
      value={{
        register,
        scrollTo,
        hackySolution
      }}
    >
      {children}
    </ScrollToContext.Provider>
  );
};

export const withScrollToProvider = (component: React.ReactNode) => {
  return <ScrollToProvider>{component}</ScrollToProvider>;
};
