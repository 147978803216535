export const Position = {
  BOTTOM: 'bottom' as 'bottom',
  BOTTOM_LEFT: 'bottom-left' as 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right' as 'bottom-right',
  LEFT: 'left' as 'left',
  LEFT_BOTTOM: 'left-bottom' as 'left-bottom',
  LEFT_TOP: 'left-top' as 'left-top',
  RIGHT: 'right' as 'right',
  RIGHT_BOTTOM: 'right-bottom' as 'right-bottom',
  RIGHT_TOP: 'right-top' as 'right-top',
  TOP: 'top' as 'top',
  TOP_LEFT: 'top-left' as 'top-left',
  TOP_RIGHT: 'top-right' as 'top-right'
};

export type Position = typeof Position[keyof typeof Position];

export type PositionWithoutDirection = typeof Position.TOP | typeof Position.RIGHT | typeof Position.BOTTOM | typeof Position.LEFT;

export const isVerticalPosition = (position: Position) => {
  switch (position) {
    case Position.BOTTOM:
    case Position.BOTTOM_LEFT:
    case Position.BOTTOM_RIGHT:
    case Position.TOP:
    case Position.TOP_LEFT:
    case Position.TOP_RIGHT:
      return true;
    default:
      return false;
  }
};
export const isHorizontalPosition = (position: Position) => {
  switch (position) {
    case Position.LEFT:
    case Position.LEFT_TOP:
    case Position.LEFT_BOTTOM:
    case Position.RIGHT:
    case Position.RIGHT_TOP:
    case Position.RIGHT_BOTTOM:
      return true;
    default:
      return false;
  }
};

export const getPositionWithoutAlignment = (position: Position) => {
  return position.split('-')[0];
};
