import { Flex, styled, StyleSystemProps } from '@withjoy/joykit';

export const TableActionsContainer = styled(Flex)``;

const containerStyles: StyleSystemProps = {
  justifyContent: 'flex-end'
};

export const styles = {
  container: containerStyles
};
