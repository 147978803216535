/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Brand Outlook',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.78 2.78a2 2 0 0 0-2 2v14.44a2 2 0 0 0 2 2h14.44a2 2 0 0 0 2-2V4.78a2 2 0 0 0-2-2H4.78zm2.291 6.411c-.342.776-.513 1.735-.513 2.878 0 1.786.493 3.189 1.477 4.21.985 1.015 2.312 1.523 3.98 1.523 1.648 0 2.965-.51 3.95-1.531.984-1.026 1.477-2.444 1.477-4.256 0-1.826-.498-3.253-1.493-4.278-.99-1.026-2.311-1.538-3.964-1.538-.914 0-1.72.147-2.419.443-.525.22-1.01.557-1.454 1.01a5.318 5.318 0 0 0-1.04 1.54zm2.702 5.694c-.587-.658-.88-1.622-.88-2.893 0-1.29.285-2.255.857-2.893.571-.643 1.324-.964 2.258-.964.933 0 1.68.316 2.242.949.567.633.85 1.592.85 2.878 0 1.3-.291 2.278-.873 2.931-.576.648-1.316.972-2.22.972-.902 0-1.647-.326-2.234-.98z"
            fill="#333"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
