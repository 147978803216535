import { MutableRefObject, useEffect, useRef } from 'react';

import { useComponentHealthProvider } from '../ComponentHealthProvider';
import { ACTION_COMPONENT_LOAD_END } from '../constants';
import { ComponentHealthState, ComponentHealthTelemetryData, ComponentInfo, StateType } from '../types';

interface LoadEndTelemetryData extends ComponentHealthTelemetryData {
  type: StateType;
  reason?: unknown;
  context?: object;
}

export function useLoadEndEffect(componentInfoRef: MutableRefObject<ComponentInfo>, componentHealthState: ComponentHealthState): void {
  const { onAction, onError } = useComponentHealthProvider();
  const hasLoggedEndStateRef = useRef(false);

  useEffect(() => {
    if (componentHealthState.type === StateType.Failure) {
      // Always log an error event if a failure is reported
      onError(componentHealthState.reason, componentHealthState.context);
    }

    if (!hasLoggedEndStateRef.current && componentHealthState.type !== StateType.Loading) {
      const { componentName, loadId, loadStartTime } = componentInfoRef.current;
      const telemetryData: LoadEndTelemetryData = {
        componentName,
        loadId,
        duration: Date.now() - loadStartTime,
        ...componentHealthState
      };

      onAction(ACTION_COMPONENT_LOAD_END, telemetryData);
      hasLoggedEndStateRef.current = true;
    }

    // Effect should only rerun when componentHealthState changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentHealthState]);
}
