/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Clock',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8 12c0 5.412-4.388 9.8-9.8 9.8-5.412 0-9.8-4.388-9.8-9.8 0-5.412 4.388-9.8 9.8-9.8 5.412 0 9.8 4.388 9.8 9.8zM12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm.6-17.222a.6.6 0 1 0-1.2 0v6.865l.176.176 3.308 3.309a.6.6 0 1 0 .849-.849L12.6 12.146V5.778z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M16.6002 7.70406C16.6002 7.37269 16.3315 7.10406 16.0002 7.10406C15.6688 7.10406 15.4002 7.37269 15.4002 7.70406V16.7751L19.9871 21.362C20.2215 21.5964 20.6014 21.5964 20.8357 21.362C21.07 21.1277 21.07 20.7478 20.8357 20.5135L16.6002 16.278V7.70406Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0002 30.6667C24.1003 30.6667 30.6668 24.1002 30.6668 16C30.6668 7.89987 24.1003 1.33337 16.0002 1.33337C7.89999 1.33337 1.3335 7.89987 1.3335 16C1.3335 24.1002 7.89999 30.6667 16.0002 30.6667ZM29.4668 16C29.4668 23.4375 23.4376 29.4667 16.0002 29.4667C8.56273 29.4667 2.5335 23.4375 2.5335 16C2.5335 8.56261 8.56273 2.53337 16.0002 2.53337C23.4376 2.53337 29.4668 8.56261 29.4668 16Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
