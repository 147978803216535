import React from 'react';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const CUSTOM_PAGE_EMPTY_STATE_IMAGE = 'https://withjoy.com/assets/public/custom-page/editor-empty-state.png';

interface CustomComponentEmptyStateProps {
  title: string;
  subTitle: string;
  isBrannanLayout?: boolean;
}

export const CustomComponentEmptyState: React.FC<CustomComponentEmptyStateProps> = ({ title, subTitle, isBrannanLayout }) => {
  return (
    <Flex flexDirection="column" alignItems="center" paddingX={6} paddingTop={pxToRem(44)} paddingBottom={isBrannanLayout ? 0 : pxToRem(44)} marginTop={isBrannanLayout ? 0 : 8}>
      <Flex flexDirection="column" alignItems="center" paddingY={pxToRem(44)} rowGap={pxToRem(44)} maxWidth={pxToRem(482)} marginX="auto">
        <Box width={pxToRem(170)}>
          <img src={CUSTOM_PAGE_EMPTY_STATE_IMAGE} alt={title} width="100%" />
        </Box>
        <Flex flexDirection="column" rowGap={3} width="100%">
          <TextV2 typographyVariant="hed1" fontFamily="Inter UI" fontSize={pxToRem(26)} fontWeight={600} textAlign="center">
            {title}
          </TextV2>
          <TextV2 typographyVariant="body2" fontFamily="Inter UI" maxWidth={pxToRem(314)} lineHeight={pxToRem(24)} textAlign="center">
            {subTitle}
          </TextV2>
        </Flex>
      </Flex>
    </Flex>
  );
};
