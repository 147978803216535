import { EventPageFragment, EventPageType, PhotoXPosition, PhotoYPosition } from '@graphql/generated';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuxFrameContainer, AuxFrameInnerContainer } from './AuxFrame.styles';
import AuxFramePhotoSlider from './components/AuxFramePhotoSlider';
import AuxFrameTitle from './components/AuxFrameTitle';
import { EditPhoto } from './components/EditPhoto';
import { AuxPhoto } from './AuxFrame.types';
import { isInIframe } from '@shared/utils/isInIframe';
import { zeroRightClassName } from 'react-remove-scroll-bar';
import { usePageRefFromUrl } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/usePageRefFromUrl';
import { InlineEditor } from '@shared/components';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { Box } from '@withjoy/joykit';

interface IAuxFrameContext {
  eventId: string;
  page: Maybe<EventPageType>;
  pageId: string;
  pageSlug?: string;
  photo?: AuxPhoto | null;
  setPageInView: (pageId: string) => void;
  previewFocusPoint: (alignX: PhotoXPosition, alignY: PhotoYPosition) => void;
  reflectPhotoChange: (imageInfo?: AuxPhoto | null) => void;
  pages: EventPageFragment[];
}
export const AuxFrameContext = React.createContext<IAuxFrameContext>({
  eventId: '',
  page: EventPageType.welcome,
  pageId: '',
  setPageInView: () => {
    throw Error(`No AuxFrameContext`);
  },
  previewFocusPoint: (alignX, alignY) => {
    throw Error(`No AuxFrameContext`);
  },
  reflectPhotoChange: () => {
    throw Error(`No AuxFrameContext`);
  },
  pages: []
});

export const AuxFrameProvider: React.FC<{ isSmallScreen: boolean; eventId: string; primaryPhoto?: AuxPhoto | null; pages: Array<EventPageFragment>; isPreviewing: boolean }> = ({
  isSmallScreen,
  eventId,
  primaryPhoto,
  pages,
  children,
  isPreviewing
}) => {
  const [currentPhoto, setCurrentPhoto] = useState<AuxPhoto | undefined | null>(primaryPhoto);
  const [currentPage, setCurrentPage] = useState<EventPageFragment | null | undefined>();
  const [pageInView, setPageInView] = useState<string>();
  const getPageRefFromUrl = usePageRefFromUrl();

  const previewFocusPoint = (alignX: PhotoXPosition, alignY: PhotoYPosition) => {
    if (currentPhoto) {
      setCurrentPhoto({ ...currentPhoto, layout: { alignX, alignY } });
    }
  };

  useEffect(() => {
    const page = pages.find(page => page.id === pageInView);
    if (currentPage?.id !== page?.id) {
      setCurrentPage(page);
    }
  }, [currentPage?.id, pages, pageInView]);

  useEffect(() => {
    if (pages.length) {
      const pageRef = getPageRefFromUrl(pages.map(page => ({ page }))) ?? { page: pages?.[0] };

      if (pageRef) {
        setPageInView(pageRef.page.id);
      }
    }
  }, [pages, getPageRefFromUrl]);

  useEffect(() => {
    if (isSmallScreen) return;

    // Only change photo for desktop when currentPage changes
    setCurrentPhoto(getPhotoToApply());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryPhoto, pages, currentPage, isSmallScreen]);

  useEffect(() => {
    // Only change photo when a page is changed if (isPreviewing) is active.
    // One of the reasons the page can be changed is if we edit the photo.

    if (!isPreviewing) return;
    setCurrentPhoto(getPhotoToApply());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, isPreviewing]);

  const getPhotoToApply = () => {
    // photo will exist coming from the preview listener (angular app publishes data changes)
    // but the url will be null when there isn't a photo for that page
    // the fallback is the event photo, here that is primaryPhoto
    const pageForCurrentPage = (currentPage?.id && pages.find(p => p.id === currentPage.id)) || null;
    const photoToApply = pageForCurrentPage?.photo?.url ? pageForCurrentPage?.photo : primaryPhoto;
    return photoToApply;
  };

  const reflectPhotoChange = (imageInfo?: AuxPhoto | null) => {
    imageInfo && setCurrentPhoto(imageInfo);
  };

  const welcomePageData = useMemo(() => pages.find(page => page.type === EventPageType.welcome), [pages]);

  return (
    <AuxFrameContext.Provider
      value={{
        eventId,
        page: currentPage?.type || EventPageType.welcome,
        pageSlug: currentPage?.pageSlug,
        pageId: currentPage?.id || welcomePageData?.id || '',
        photo: currentPhoto,
        setPageInView,
        previewFocusPoint,
        reflectPhotoChange,
        pages
      }}
    >
      {children}
    </AuxFrameContext.Provider>
  );
};

export const AuxFrame: React.FC<{
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  eventHandle: string;
  telemetryPhotoEditor: {
    onEditPhotoOpenedChange: (opened: boolean) => void;
  };
}> = ({ title, subtitle, eventHandle, telemetryPhotoEditor, children }) => {
  const { photo, pageId, page, pages, pageSlug } = useContext(AuxFrameContext);
  const isPreviewing = isInIframe();
  const { onEditPhotoOpenedChange } = telemetryPhotoEditor;

  const handleEditPhotoClick = () => {
    // send the message to the parent window to open the photo editor
    sendMessageToParentWindow({ action: 'inlineEditingInteraction', source: 'joyPreview', value: { action: 'editPhoto', inlineEditData: { photo, pageId, page, pageSlug } } });
  };

  const welcomePageId = useMemo(() => pages.find(page => page.type === EventPageType.welcome)?.id || '', [pages]);

  return (
    <AuxFrameContainer className={zeroRightClassName}>
      <AuxFrameInnerContainer>
        <InlineEditor
          elementLabel="Photo"
          wrapperType="actionInside"
          actionData={{
            editPhoto: handleEditPhotoClick
          }}
          minHeight="100vh"
          componentName="pagePhoto"
          pageName={page}
          pageSlug={pageSlug || ''}
        >
          {photo?.url ? <AuxFramePhotoSlider photo={photo} /> : <Box width="100%" height="100vh" />}
        </InlineEditor>
        <AuxFrameTitle x={'left'} y={'bottom'} textLight={true} title={title} subtitle={subtitle} welcomePageId={welcomePageId} page={page} pageSlug={pageSlug || ''} />
        {children}
        {!isPreviewing && <EditPhoto onEditPhotoOpenedChange={onEditPhotoOpenedChange} />}
      </AuxFrameInnerContainer>
    </AuxFrameContainer>
  );
};
