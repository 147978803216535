import { styled, css, pseudoSelectors } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { animationTransition } from '@shared/utils/animationTransition';
import ReactDatePicker from 'react-datepicker';
import { Box } from '../Box';
import { ReactComponent as ArrowLeft } from './arrowLeft.svg';

export const StyledNavArrow = styled(ArrowLeft)<{ $type: 'prev' | 'next' }>`
  position: absolute;
  top: 0;
  ${({ $type }) => {
    const placement = $type === 'prev' ? 'left' : 'right';
    return {
      [placement]: 0,
      transform: placement === 'right' ? 'rotate(180deg)' : undefined
    };
  }}
`;

export const StyledDayContent = styled.div`
  /* margin: 0.166rem; */
`;

const containerResetStyles = css`
  .react-datepicker {
    border: none;
    border-radius: inherit;
    color: #333;
    display: inline-flex;
  }
`;

const navigationResetStyles = css`
  .react-datepicker__navigation {
    border: none;

    /* Shared arrow styles */
    &--previous,
    &--next {
      width: 19px;
      height: 11px;
    }

    &--previous {
      left: 10px;
      &:hover {
        /* border-right-color: darken($datepicker__muted-color, 10%); */
      }

      &--disabled,
      &--disabled:hover {
        /* border-right-color: $datepicker__navigation-disabled-color; */
        cursor: default;
      }
    }

    &--next {
      right: 10px;

      &--with-time:not(&--with-today-button) {
        right: 95px;
      }
    }
  }
`;

const headerResetStyles = css`
  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }
`;

const calendarResetStyles = css`
  .react-datepicker__month {
    margin-top: 0;
  }

  .react-datepicker__day-names {
    margin-top: ${props => props.theme.space[2]};
  }
  .react-datepicker__day-name {
    color: ${props => props.theme.colors.mono14};
    font-size: ${pxToRem(11)};
    padding: 0 1rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    ${({ theme: { colors, space } }) => {
      return {
        // color: colors.mono1,
        display: 'inline-flex',
        justifyContent: 'center',
        margin: 0
      };
    }}
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    cursor: pointer;
    outline: none;

    /* Set transitions */
    transition: ${animationTransition('background-color', 'color', 'box-shadow')};

    /* Reset border radius */
    &:hover,
    &--highlighted,
    &--selected,
    &--in-selecting-range,
    &--in-range,
    &--keyboard-selected {
      border-radius: 0;
    }
  }

  .react-datepicker__day {
    position: relative;
    padding: 0.166rem 1rem;

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      height: calc(100% - 1px);
      width: calc(100% - 1px);
      box-shadow: 0px 0px 0 1px #e4e7e7;
      user-select: none;
      transition: ${animationTransition('box-shadow')};
    }
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
    &::after {
      box-shadow: none;
    }
  }
`;

////////////////////////////////////////////////////////

const getBoxShadowValue = (color: string) => {
  return `0px 0px 0 1px ${color}`;
};

const highlightedDayStyles = css`
  ${({ theme: { colors } }) => {
    return {
      backgroundColor: colors.datepickerDayHighlighted,
      [pseudoSelectors._after]: {
        zIndex: 1,
        boxShadow: getBoxShadowValue(colors.datepickerDayHighlighted)
      }
    };
  }}
`;

const selectedDayStyles = css`
  color: #fff;
  ${({ theme: { colors } }) => {
    return {
      backgroundColor: colors.datepickerDaySelected,
      [pseudoSelectors._after]: {
        zIndex: 1,
        boxShadow: getBoxShadowValue(colors.datepickerDaySelected)
      },
      [pseudoSelectors._disabled]: {
        color: colors.buttonDisabledText
      }
    };
  }}
`;

const sharedStateStyles = css`
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    &--highlighted {
      ${highlightedDayStyles}
      color: #fff;
    }

    &--keyboard-selected {
      /* ${selectedDayStyles} */
      background-color: white;
      color: #333;
    }

    &--disabled {
      cursor: default;
      /* color: $datepicker__muted-color; */

      &:hover {
        background-color: transparent;
      }
    }
  }
`;

const dateRangePickerStyles = css`
  .react-datepicker > div.react-datepicker__month-container ~ div.react-datepicker__month-container {
    margin-left: ${props => props.theme.space[5]};
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    &--in-selecting-range,
    &--in-range {
      color: #fff;
      ${highlightedDayStyles}

      ${({ theme: { colors } }) => {
        return {
          [pseudoSelectors._after]: {
            boxShadow: getBoxShadowValue(colors.datepickerDaySelected)
          },
          [pseudoSelectors._hover]: {
            backgroundColor: colors.datepickerDayHighlightedHover
          }
        };
      }}
    }

    &--selected,
    &--range-end,
    &--range-start {
      ${selectedDayStyles}
    }

    &--in-selecting-range:not(&--in-range) {
      ${highlightedDayStyles}
    }

    &--in-range:not(&--in-selecting-range) {
      .react-datepicker__month--selecting-range & {
        ${highlightedDayStyles}
      }
    }
  }

  .react-datepicker {
    ${({ theme: { mediaQueries } }) => {
      return {
        [mediaQueries.between(0, { breakpointAlias: 'sm' })]: {
          flexDirection: 'column',
          ['& > .react-datepicker__month-container:last-of-type']: {
            marginLeft: 0
          }
        }
      };
    }}
  }
`;

const singleDatePickerStyles = css`
  .react-datepicker__day {
    &--selected,
    &:hover {
      ${selectedDayStyles}
    }
  }
`;

export const StyledDatePickerRoot = styled(Box)<{ pickerType: 'dayPicker' | 'dateRange' }>`
  border-radius: 8px;
  box-shadow: 0px 10px 27px -5px rgba(68, 50, 93, 0.25), 0px 7px 16px -8px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: inline-block;

  & > div {
    border-radius: inherit;
    display: inline-block;
  }

  ${containerResetStyles}
  ${navigationResetStyles}
  ${headerResetStyles}
  ${calendarResetStyles}
  ${sharedStateStyles}
  ${props => (props.pickerType === 'dayPicker' ? singleDatePickerStyles : dateRangePickerStyles)}
`;

export const StyledReactDatePicker = styled(ReactDatePicker)`
  .react-datepicker__day {
    color: red;
    &--selected {
      background-color: palevioletred;
    }
  }
`;
