import React from 'react';
import { ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { StationeryPrintOrderDetailsFragment } from '@graphql/generated';
import ReceiptBlock from '@apps/card/components/Blocks/ReceiptBlock';
import { templateToTitle } from '@apps/card/Card.utils';
import { useTranslation } from '@shared/core';
import { minorToMajorUnits } from '@apps/card/hooks/useCalculatePrintOrderPrice';
import { capitalize } from 'lodash-es';
import { ThemeJson } from '@apps/card/routes/CardCustomizer';

export const DigitalPaymentSummary = ({ order }: { order: StationeryPrintOrderDetailsFragment }) => {
  const { t } = useTranslation('stationery');
  const categoryName = t('stationeryTemplateCategoryToTile', order.cardDraft!.stationeryTemplate.category)?.();

  const cardJSON = order.cardDraft!.cardJSON as ThemeJson;
  const shippingAddress = order.shippingAddress;
  const silhouette = cardJSON.customizations.shape;
  const totalPrice = minorToMajorUnits(order.priceInMinorUnits);

  return (
    <Flex width={'100%'} maxWidth="100%" alignSelf="center" paddingX={8} flexDirection={{ _: 'column', md: 'row' }} rowGap={10} columnGap={140}>
      <Flex flexDirection="column" gap={8} flex={1} maxWidth={{ md: '440px' }} width="100%">
        <Flex flexDirection="column" gap={6}>
          <TextV2 typographyVariant="hed1">Price Details</TextV2>
          <Flex flexDirection="column" flex={1} gap={6}>
            <ReceiptBlock
              title={`Digital ${categoryName}`}
              subTitle={templateToTitle(order.cardDraft!.stationeryTemplate)}
              lines={[
                ['Digital Card', `$${totalPrice}`],
                [`Silhouette: ${capitalize(silhouette)}`, 'Free']
              ]}
            />
            <Flex flexDirection="column" paddingTop={3} borderTop="1px solid #EBEBEB">
              <Flex justifyContent="space-between" alignItems="center">
                <TextV2 typographyVariant="hed3" fontSize="15px">
                  {t('cardCustomizer', 'priceSummary').totalLabel()}
                </TextV2>
                <TextV2 typographyVariant="hed3">${totalPrice}</TextV2>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap={6}>
          <TextV2 typographyVariant="hed1">Payment Method</TextV2>
          <Flex flexDirection="column" gap={5}>
            <TextV2 typographyVariant="hed1">Card</TextV2>
            <TextV2 typographyVariant="label2" color="mono12">
              {capitalize(order.printPayment?.paymentInfo?.creditCardBrand)} ending {order.printPayment?.paymentInfo?.creditCardLast4}
            </TextV2>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap={5}>
          <TextV2 typographyVariant="hed1">Billing Address</TextV2>
          <Flex flexDirection="column" color="mono12">
            <TextV2 fontSize="15px" lineHeight="22px">
              {shippingAddress.name}
            </TextV2>
            <TextV2 fontSize="15px" lineHeight="22px">
              {shippingAddress.address1}
            </TextV2>
            {shippingAddress.address2 && (
              <TextV2 fontSize="15px" lineHeight="22px">
                {shippingAddress.address2}
              </TextV2>
            )}
            <TextV2 fontSize="15px" lineHeight="22px">
              {shippingAddress.city}, {shippingAddress.region} {shippingAddress.postalCode} {shippingAddress.countryCode}
            </TextV2>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap={8} flex={1} maxWidth={{ md: '440px' }} width="100%">
        <Flex flexDirection="column" gap={6}>
          <TextV2 typographyVariant="hed3">Payment Method</TextV2>
          <Flex flexDirection="column" gap={5}>
            <TextV2 typographyVariant="hed1">Credit or Debit Card</TextV2>
            <TextV2 typographyVariant="label2" color="mono12">
              {capitalize(order.printPayment?.paymentInfo?.creditCardBrand)} ending {order.printPayment?.paymentInfo?.creditCardLast4}
            </TextV2>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap={5}>
          <TextV2 typographyVariant="label2">Need help with your order?</TextV2>
          <ButtonV2 width={{ md: 305 }} as="a" href="mailto:printsupport@withjoy.com" flex="none" intent="neutral" shape="rounded" variant="outline">
            Contact Support
          </ButtonV2>
        </Flex>
      </Flex>
    </Flex>
  );
};
