import React, { useContext } from 'react';
import LinkifyReact from 'linkify-react';
import 'linkifyjs';
import { Opts as LinkifyReactOptions } from 'linkifyjs';
import { PrettyUrlOptions, prettyUrl } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';
import { SafeLink } from '@shared/components/SafeLink';
import { AnalyticsContext } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

function getLinkifyOptions(prettyUrlFormat = true, prettyUrlOptions?: PrettyUrlOptions, source?: string, onClickEmail?: (email?: string) => void): LinkifyReactOptions {
  const linkifyOptions: LinkifyReactOptions = {
    nl2br: true,
    ignoreTags: ['a', 'button'],
    attributes: (href: string, type: string, token: { t: string; v: string }) => {
      if (type === 'email') {
        return {
          onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onClickEmail?.(token.v)
        };
      }
      return {};
    },
    render: {
      url: ({ attributes, content }) => {
        return (
          <SafeLink href={attributes.href} isExternal={true} source={source}>
            {prettyUrlFormat ? prettyUrl(content, prettyUrlOptions)?.toString() : content}
          </SafeLink>
        );
      }
    }
  };

  return linkifyOptions;
}

type LinkifyUrlsAndEmailProps = { prettyUrlFormat?: boolean; prettyUrlOptions?: PrettyUrlOptions; source?: string };

export const LinkifyUrlsAndEmails: React.FC<LinkifyUrlsAndEmailProps> = ({ children, prettyUrlFormat, prettyUrlOptions, source }) => {
  const analytics = useContext(AnalyticsContext);
  const onClickEmail = useEventCallback(email => {
    analytics.track({
      action: 'LinkInteracted',
      category: 'linkify',
      extraInfo: { actionType: 'click', url: email, source, type: 'email' }
    });
  });
  return <LinkifyReact options={getLinkifyOptions(prettyUrlFormat, prettyUrlOptions, source, onClickEmail)}>{children}</LinkifyReact>;
};
