import { styled, DialogV2 } from '@withjoy/joykit';

export const DialogContainer = styled(DialogV2)`
  ${props => props.theme.mediaQueries.between({ breakpointAlias: 'md' }, { breakpointAlias: 'md3' })} {
    height: 100%;
  }
`;
export const DialogBody = styled(DialogV2.Body)`
  ${props => props.theme.mediaQueries.between({ breakpointAlias: 'md' }, { breakpointAlias: 'md3' })} {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
