/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Heart Stroke',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00004 4.66962L7.22834 3.90992C6.13139 2.83003 4.34584 2.83003 3.24889 3.90992C2.16153 4.98037 2.16153 6.70867 3.24889 7.77912L2.49186 8.54811L3.24889 7.77912L8.00004 12.4564L12.7512 7.77912L13.5229 8.563L8.00004 14L2.47719 8.563C0.952101 7.06163 0.952101 4.62741 2.47719 3.12603C4.00228 1.62466 6.47495 1.62466 8.00004 3.12603C9.52513 1.62466 11.9978 1.62466 13.5229 3.12603C15.048 4.62741 15.048 7.06163 13.5229 8.563L12.7512 7.77912C13.8385 6.70867 13.8385 4.98037 12.7512 3.90992C11.6542 2.83003 9.86869 2.83003 8.77174 3.90992L8.00004 4.66962Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 6.37296L11.1581 5.5442C9.33757 3.75193 6.37816 3.75193 4.55758 5.5442C2.74747 7.32616 2.74747 10.2074 4.55758 11.9894L3.71573 12.8445C1.42809 10.5924 1.42809 6.94112 3.71573 4.68905C6.00337 2.43698 9.71236 2.43698 12 4.68905C14.2876 2.43698 17.9966 2.43698 20.2843 4.68905C22.5719 6.94112 22.5719 10.5924 20.2843 12.8445L12 21L3.71573 12.8445L4.55758 11.9894L12 19.3161L19.4424 11.9894C21.2525 10.2074 21.2525 7.32616 19.4424 5.5442C17.6218 3.75193 14.6624 3.75193 12.8418 5.5442L12 6.37296Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 7.93598L15.1581 7.10722C12.575 4.56426 8.37924 4.56426 5.79612 7.10722C3.22346 9.63987 3.22346 13.7382 5.79612 16.2709L4.96818 17.1119L5.79612 16.2709L16 26.3161L26.2038 16.2709C28.7765 13.7382 28.7765 9.63987 26.2038 7.10722C23.6207 4.56426 19.4249 4.56426 16.8418 7.10722L16 7.93598ZM16 28L4.95426 17.126C1.90408 14.1233 1.90408 9.25482 4.95426 6.25207C8.00445 3.24931 12.9498 3.24931 16 6.25207C19.0501 3.24931 23.9955 3.24931 27.0457 6.25207C30.0958 9.25482 30.0958 14.1233 27.0457 17.126L16 28Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
