import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';
import { animationTransition } from '@shared/utils/animationTransition';
import { usePreviousValue } from '@shared/utils/hooks/usePreviousValue';

import React from 'react';
import { useTransition, animated } from 'react-spring';
import { AuxPhoto } from '../../AuxFrame.types';
import { addRendition } from '@shared/utils/photoRendition';

export const AuxFramePhotoSlider: React.FC<{ photo: AuxPhoto }> = ({ photo }) => {
  const previousPhoto = usePreviousValue(photo);

  const transition = useTransition(photo, item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 220, friction: 120 }
  });

  const animation = previousPhoto?.id === photo.id ? `${animationTransition('background-position')}` : `${animationTransition('background-image')}`;

  return (
    <>
      {transition.map(({ item, props, key }) => (
        <animated.div
          key={key}
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: '100vh',
            backgroundImage: backgroundImageForUrl(addRendition({ url: item.url, renditionSize: 'xlarge' })),
            backgroundSize: 'cover',
            backgroundPosition: `${photo.layout.alignX} ${photo.layout.alignY}`,
            backgroundRepeat: 'no-repeat',
            transition: animation,
            ...props
          }}
        />
      ))}
    </>
  );
};
