export const isValidUrl = (url: Maybe<string>): boolean => {
  if (typeof url !== 'string') {
    return false;
  }
  const res = url.match(/(http(s)?:\/\/.)/g);
  return res !== null;
};

export const getDomainNameFromProductExternalUrl = (productExternalUrl: Maybe<string>) => {
  if (!productExternalUrl || !isValidUrl(productExternalUrl)) {
    return undefined;
  }
  const domain = new URL(productExternalUrl || '');
  const siteName = domain.hostname.replace('www.', '');
  return siteName.charAt(0).toUpperCase() + siteName.slice(1);
};

interface KeyValue {
  [key: string]: string | undefined;
}

// When IE 11 support ends URLSearchParams can be used
export const getURLParams = <ParamsKeyValues extends KeyValue>(search: string): ParamsKeyValues | KeyValue => {
  const paramValue = search
    .substring(1)
    .split('&')
    .reduce((params: KeyValue, newString) => {
      const kv = newString.split('=');
      if (kv[0] && kv[1]) {
        params[kv[0].replace(/\s+/g, '')] = kv[1].trim();
      }
      return params;
    }, {});
  return paramValue;
};

/*
  A function that extract URL from given string. If there is no matching URL, then it will return the given string itself.
  @param {string} text - The text from which the URL to be extracted
  @returns {string} - The extracted URL or the given text itself if no valid URL found
*/
export const extractURLFromText = (text: string) => {
  const urlRegex = /(?:https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?/g;
  const matches = text.match(urlRegex);
  return matches ? matches[0] : text;
};
