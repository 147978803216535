import { createTelemetry, createTelemetryObject } from '@shared/core';

const action = 'PagePhotoEditorButtonInteracted';

const telemObject = createTelemetryObject({
  actions: {
    editPagePhotoClicked: (category: string, pageType: string) => ({
      action,
      category,
      extraInfo: {
        name: 'editPagePhotoClicked',
        pageType
      }
    }),

    photoEditorOpened: (category: string, label: 'opened' | 'closed') => ({
      action: 'PagePhotoEditorStatus',
      category,
      label
    }),

    photoEditorChangePhotoClicked: (category: string) => ({
      action: 'PagePhotoEditorChangePhoto',
      actionType: 'click',
      category
    }),

    pagePhotoUpdated: (category: string, action: 'FocusChanged' | 'Update' | 'Delete', status: 'succeeded' | 'failed', extraInfo: Object = {}) => ({
      action: 'PagePhotoUpdated',
      category,
      extraInfo: {
        name: 'pagePhotoUpdated',
        label: action,
        status,
        ...extraInfo
      }
    })
  }
});

const { TelemetryProvider: FocusPointEditorTelemetryProvider, useTelemetry: useFocusPointEditorTelemetry } = createTelemetry(telemObject);

export { FocusPointEditorTelemetryProvider, useFocusPointEditorTelemetry };
