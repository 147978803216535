import { useTranslation } from '@shared/core/i18n';
import { useMemo } from 'react';

export type ScheduleListTranslations = Readonly<{
  addToCalendar: string;
  addToCal: string;
  dressCode: string;
  getDirections: string;
  moreDetails: string;
  timeframe: (start?: string, end?: string) => string | undefined;
  timeframeTz: (start?: string, end?: string, timezone?: string) => string | undefined;
  timeTz: (time?: string, timezone?: string) => string | undefined;
  toBeAnnounced: string;
  toBeAnnouncedDescription: string;
}>;

export const useTranslations = (): ScheduleListTranslations => {
  const { t } = useTranslation('guestKit');

  const translations = t('common');

  const value = useMemo<ScheduleListTranslations>(() => {
    return {
      addToCalendar: translations.addToCalendar(),
      addToCal: translations.addToCal(),
      dressCode: translations.dressCode(),
      getDirections: translations.getDirections(),
      moreDetails: translations.moreDetails(),
      timeframe: (start, end) => {
        return start && end ? translations.timeframe({ start, end }) : start || end;
      },
      timeframeTz: (start, end, timezone) => {
        return start && end ? translations.timeframeTz({ start, end, timezone }) : translations.timeTz({ time: start, timezone }) || translations.timeTz({ time: end, timezone });
      },
      timeTz: (time, timezone) => translations.timeTz({ time, timezone }),
      toBeAnnounced: translations.toBeAnnouncedHeader(),
      toBeAnnouncedDescription: translations.toBeAnnouncedDescription()
    };
  }, [translations]);

  return value;
};
