import React from 'react';
import { StyledLinkifyWrapper } from './Linkify.styles';
export interface SystemProps
  extends Readonly<{
    height?: string;
    width?: string;
  }> {}

interface InternalLinkifyProps
  extends Readonly<{
    // place to display on map
    place?: string;
    // latitude of the location to display
    lat?: number;
    // longitude of the location to diplay
    lng?: number;
    // address of the place
    address?: string;
    // name of the place
    name?: string;
    // Custom title to enables callers to provide a better description
    a11yTitle: string;
  }> {}

export const defaultProps = {
  height: '' as string,
  width: '' as string,
  place: '' as string,
  lat: 0 as number,
  lng: 0 as number,
  address: '' as string,
  name: '' as string,
  a11yTitle: '' as string
} as const;

// Public API key for google map location
export const GMAP_API_KEY = 'AIzaSyB9ePoqHL2N2xqOhy2H_7vjDokVDIq8-fE';

export interface LinkifyProps extends Readonly<InternalLinkifyProps>, SystemProps {}

/**
 * make url escaped string for q
 */
function _formatPlaceQuery(place?: string, name?: string, address?: string) {
  const value = [name, address].filter(item => item).join(',');
  return place ? { key: 'q', value: `place_id:${place}` } : value ? { key: 'q', value } : null;
}

/**
 * format center location lat and lng
 */
function _formatCenterLocaton(lat?: number, lng?: number) {
  return lat && lng ? { key: 'center', value: `${lat},${lng}` } : null;
}

const GOOGLE_EMBED_URL = 'https://www.google.com/maps/embed/v1/place';

const Linkify: React.FC<LinkifyProps> = ({ lat, lng, a11yTitle, width, height, place, name, address, ...restProps }) => {
  const url = new URL(GOOGLE_EMBED_URL);
  url.searchParams.append('key', GMAP_API_KEY);
  url.searchParams.append('zoom', '10');

  [_formatPlaceQuery(place, name, address), _formatCenterLocaton(lat, lng)].forEach(entry => {
    if (entry) {
      url.searchParams.append(entry.key, entry.value);
    }
  });

  return url.searchParams.has('q') || url.searchParams.has('center') ? (
    <StyledLinkifyWrapper {...restProps} title={a11yTitle} width={width} height={height} src={url.toString()} />
  ) : null;
};

Linkify.defaultProps = defaultProps;

Linkify.displayName = 'Linkify';

export { Linkify };
