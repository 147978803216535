import { TextV2, TextV2Props } from '@withjoy/joykit';
import { useIsMounted } from '@withjoy/joykit/hooks';
import { pxToRem } from '@withjoy/joykit/theme';
import React, { useEffect, useRef } from 'react';

interface ResponsiveText extends Omit<TextV2Props, 'fontSize'> {
  text: Maybe<string>;
  skipResize?: boolean;
  defaultFontSize?: number;
}

export const ResponsiveText: React.FC<ResponsiveText> = ({ text, defaultFontSize = 48, skipResize, ...textV1Props }) => {
  const isMounted = useIsMounted();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateFontSize = () => {
      if (ref.current && isMounted && !skipResize) {
        let newFontSize = 0;
        while (newFontSize < defaultFontSize) {
          ref.current.style.fontSize = `${pxToRem(newFontSize)}`;
          const textWidth = ref.current.scrollWidth;
          if (textWidth > ref.current.clientWidth) {
            break;
          }
          newFontSize++;
        }
        ref.current.style.fontSize = `${pxToRem(newFontSize - 1)}`;
      }
    };

    const timeout = setTimeout(() => updateFontSize(), 200);

    window.addEventListener('resize', updateFontSize);
    return () => {
      window.removeEventListener('resize', updateFontSize);
      clearTimeout(timeout);
    };
  }, [text, isMounted, defaultFontSize, skipResize]);

  return (
    <TextV2 ref={ref} {...textV1Props} {...(!skipResize && { whiteSpace: 'nowrap' })}>
      {text}
    </TextV2>
  );
};
