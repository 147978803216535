import { PointsOnMapFragment, PointOnMapType, ReservedRoomBlockStatus } from '@graphql/generated';
import { AccommodationCombined, AccommodationCombinedType } from './components/Accommodations/Accommodation.types';

export const DEFAULT_DESTINATION: PointsOnMapFragment = {
  id: 'baa68678-2a93-4e22-8c86-6fdcbc578d5a',
  name: 'Austin, TX, USA',
  latitude: 30.267153,
  longitude: -97.7430608,
  postalCode: null,
  address: '',
  type: PointOnMapType.venue,
  __typename: 'PointsOnMap'
};

export const DEFAULT_RESERVED_ROOM_BLOCKS: AccommodationCombined[] = [
  {
    id: 'ad5f8393-bf88-446f-8c10-dcf85a666a1b',
    type: AccommodationCombinedType.Roomblock,
    displayName: 'Austin Marriott Downtown',
    latitude: 30.2628425,
    longitude: -97.7415826,
    isFavorite: false,
    starRating: 4.6,
    pricePerNight: 350,
    bookingUrl: 'https://reservemygroup.com/event/demo-wedding-event-please-do-not-send-offers-with-joy',
    cutoffDate: '2025-07-08',
    description:
      'This is a great choice for guests who want to explore Austin and be in the downtown (just a 10 min walk from lively Rainey Street!) Both the ceremony and reception will be held here.',
    checkOutDate: '2025-10-23',
    checkInDate: '2025-10-20',
    address: '304 E Cesar Chavez St, Austin, TX 78701, USA',
    strikeoutPricePerNight: 400,
    numberOfRoomsBooked: 20,
    photo: {
      id: '81d7eb0f-c200-57fd-adf7-e3469fc11708',
      url: 'https://withjoy.com/assets/public/hotelroomblock/default-reserved-room-block-2-v2.png'
    },
    status: ReservedRoomBlockStatus.active
  },
  {
    id: '8b8021d6-5fbb-4ec9-889e-d6781b6f1e1f',
    type: AccommodationCombinedType.Roomblock,
    displayName: 'Hyatt Regency Austin',
    latitude: 30.2607308,
    longitude: -97.74716319999999,
    isFavorite: true,
    starRating: 4.5,
    pricePerNight: 250,
    bookingUrl: 'https://reservemygroup.com/event/demo-wedding-event-please-do-not-send-offers-with-joy',
    cutoffDate: '2025-07-25',
    description:
      'We love this hotel. We stayed here on our first trip to Austin and loved the sunsets and the river view. A 15 min walk to the venue, it’s close to the fun South Congress area and right on Lady Bird Lake!',
    checkOutDate: '2025-10-23',
    checkInDate: '2025-10-20',
    address: '208 Barton Springs Rd, Austin, TX 78704, USA',
    strikeoutPricePerNight: 300,
    numberOfRoomsBooked: null,
    photo: {
      id: '2ef754fe-2a4a-5021-9b5a-aa6f54a9456e',
      url: 'https://withjoy.com/assets/public/hotelroomblock/default-reserved-room-block-1.png'
    },
    status: ReservedRoomBlockStatus.active
  }
];
