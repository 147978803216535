import { allowedDomains } from './safeLinkAllowedDomains';

export const ensureHttpsPrefix = (url: string) => {
  if (!['http://', 'https://'].some(prefix => url.startsWith(prefix))) {
    return `https://${url}`;
  }
  return url;
};

export const getIsAllowListedUrl = (href: string) => {
  let isAllowListed = false;

  if (href) {
    let url: URL | undefined;
    try {
      url = new URL(ensureHttpsPrefix(href.trim()));
    } catch (_e) {
      // Invalid href, assume URL is not allowlisted
    }

    if (url) {
      const domainParts = url.hostname.toLowerCase().split('.');
      const maxDomainPartsToCheck = Math.min(domainParts.length, 4);
      for (let i = 1; i <= maxDomainPartsToCheck; i++) {
        const domainToCheck = domainParts.slice(-i).join('.');
        if (allowedDomains.has(domainToCheck)) {
          isAllowListed = true;
          break;
        }
      }
    }
  }
  return isAllowListed;
};
