/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Mail Personalized',
  definitions: {
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00004 6.5C9.99986 6.5 11.5 5.3 11.5 0.5C11.5 5.3 13 6.5 16 6.5C14.6976 6.5 13.6778 6.7262 12.9408 7.375H23.8613C24.1205 5.93054 24.25 4.1596 24.25 2C24.25 4.1596 24.3796 5.93054 24.6387 7.375H31C32.4498 7.375 33.625 8.55025 33.625 10L33.625 15.1875L37.3637 17.9915C37.5271 18.1083 37.6279 18.3113 37.625 18.5085V38C37.625 39.4498 36.4498 40.625 35 40.625L3.00004 40.625C1.55029 40.625 0.375037 39.4498 0.375037 38V18.5073C0.372784 18.2999 0.473849 18.1108 0.630826 17.9957L4.37504 15.1875L4.37504 10C4.37504 8.55025 5.55029 7.375 7.00004 7.375H10.0593C9.32226 6.7262 8.30252 6.5 7.00004 6.5ZM10.9259 8.625H7.00004C6.24065 8.625 5.62504 9.24061 5.62504 10L5.62503 20.8829L15.1598 27.3529L17.3314 25.5645C18.3007 24.7663 19.6996 24.7663 20.6689 25.5645L22.8406 27.353L32.375 20.8831L32.375 10C32.375 9.24061 31.7594 8.625 31 8.625H24.9167C25.8865 12.192 27.9143 13.25 31 13.25C26.5 13.25 24.25 15.5 24.25 24.5C24.25 15.5 22 13.25 17.5 13.25C20.5856 13.25 22.6135 12.192 23.5833 8.625H12.0742C11.6914 9.53028 11.5 10.7855 11.5 12.5C11.5 10.7855 11.3087 9.53028 10.9259 8.625ZM11.5 20.5C13.4999 20.5 14.5 19.6 14.5 16C14.5 19.6 15.5 20.5 17.5 20.5C15.5 20.5 14.5 21.4 14.5 25C14.5 21.4 13.5 20.5 11.5 20.5ZM36.3032 38.4398L23.8463 28.1812L36.375 19.6794V38C36.375 38.1538 36.3498 38.3017 36.3032 38.4398ZM19.8743 26.5294L35.4006 39.3157C35.2739 39.3543 35.1394 39.375 35 39.375L3.00004 39.375C2.86083 39.375 2.72646 39.3543 2.59982 39.3159L18.1261 26.5294C18.6338 26.1113 19.3665 26.1113 19.8743 26.5294ZM14.1542 28.1811L1.69696 38.4401C1.65032 38.3019 1.62504 38.1539 1.62504 38V19.6793L14.1542 28.1811ZM4.37504 16.75L2.0757 18.4745L4.37503 20.0347L4.37504 16.75ZM35.9245 18.4746L33.625 16.75V20.0349L35.9245 18.4746Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
