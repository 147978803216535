/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Discount Tag',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.79065 3.77559C6.37643 4.36138 6.37643 5.31113 5.79065 5.89691C5.20486 6.4827 4.25511 6.4827 3.66933 5.89691C3.08354 5.31113 3.08354 4.36138 3.66933 3.77559C4.25511 3.18981 5.20486 3.18981 5.79065 3.77559ZM5.01283 4.55341C5.16904 4.70962 5.16904 4.96289 5.01283 5.1191C4.85662 5.27531 4.60335 5.27531 4.44714 5.1191C4.29093 4.96289 4.29093 4.70962 4.44714 4.55341C4.60335 4.3972 4.85662 4.3972 5.01283 4.55341Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.77915 8.8154C1.37402 8.41027 1.16209 7.85084 1.1971 7.27896L1.43985 3.31413C1.50158 2.3058 2.30556 1.50182 3.31389 1.44009L7.27872 1.19735C7.85059 1.16233 8.41002 1.37426 8.81515 1.77939L14.2203 7.18457C15.0014 7.96561 15.0014 9.23194 14.2203 10.013L10.0127 14.2206C9.2317 15.0016 7.96537 15.0016 7.18432 14.2206L1.77915 8.8154ZM8.03734 2.55721L13.4425 7.96238C13.794 8.31386 13.794 8.8837 13.4425 9.23518L9.23493 13.4428C8.88346 13.7942 8.31361 13.7942 7.96214 13.4428L2.55697 8.03758C2.37466 7.85527 2.27929 7.60353 2.29505 7.34619L2.53779 3.38135C2.56557 2.92761 2.92736 2.56582 3.38111 2.53803L7.34594 2.29529C7.60328 2.27953 7.85503 2.3749 8.03734 2.55721Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.81915 5.9167C9.66271 6.76027 9.66271 8.12795 8.81915 8.97152C7.97558 9.81508 6.6079 9.81508 5.76434 8.97152C4.92077 8.12795 4.92077 6.76027 5.76434 5.9167C6.6079 5.07314 7.97558 5.07314 8.81915 5.9167ZM7.97062 6.76523C8.34555 7.14017 8.34555 7.74805 7.97062 8.12299C7.59569 8.49792 6.9878 8.49792 6.61286 8.12299C6.23793 7.74805 6.23793 7.14017 6.61286 6.76523C6.9878 6.3903 7.59569 6.3903 7.97062 6.76523Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.04181 13.1741C2.4584 12.5907 2.15321 11.7851 2.20363 10.9616L2.5532 5.25203C2.6421 3.79999 3.79987 2.64222 5.25191 2.55332L10.9615 2.20375C11.785 2.15333 12.5906 2.45852 13.174 3.04193L20.9577 10.8257C22.0825 11.9504 22.0825 13.774 20.9577 14.8987L14.8986 20.9579C13.7739 22.0826 11.9503 22.0826 10.8255 20.9579L3.04181 13.1741ZM12.3255 3.89046L20.1092 11.6742C20.7653 12.3303 20.7653 13.3941 20.1092 14.0502L14.0501 20.1093C13.394 20.7655 12.3302 20.7655 11.6741 20.1093L3.89034 12.3256C3.55001 11.9853 3.37198 11.5153 3.40139 11.0349L3.75095 5.32537C3.80281 4.47832 4.4782 3.80294 5.32525 3.75108L11.0348 3.40151C11.5152 3.3721 11.9852 3.55013 12.3255 3.89046Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.89975 6.4025C10.8696 7.37235 10.8696 8.94479 9.89975 9.91464C8.9299 10.8845 7.35746 10.8845 6.38761 9.91464C5.41775 8.94479 5.41775 7.37235 6.38761 6.4025C7.35746 5.43265 8.9299 5.43265 9.89975 6.4025ZM9.05122 7.25103C9.55245 7.75225 9.55245 8.56489 9.05122 9.06612C8.55 9.56734 7.73736 9.56734 7.23613 9.06612C6.73491 8.56489 6.73491 7.75225 7.23613 7.25103C7.73736 6.7498 8.55 6.7498 9.05122 7.25103Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.59362 17.6263C2.78561 16.8183 2.36293 15.7025 2.43276 14.562L2.9169 6.65432C3.04002 4.64327 4.64352 3.03978 6.65457 2.91665L14.5622 2.43251C15.7028 2.36268 16.8185 2.78536 17.6265 3.59338L28.4068 14.3737C29.9646 15.9314 29.9646 18.4571 28.4068 20.0148L20.0151 28.4066C18.4573 29.9643 15.9317 29.9643 14.3739 28.4066L3.59362 17.6263ZM16.778 4.4419L27.5583 15.2222C28.6474 16.3113 28.6474 18.0772 27.5583 19.1663L19.1665 27.5581C18.0774 28.6472 16.3116 28.6472 15.2225 27.5581L4.44215 16.7778C3.87721 16.2128 3.58169 15.4327 3.63051 14.6353L4.11465 6.72766C4.20074 5.3216 5.32184 4.2005 6.7279 4.11441L14.6355 3.63027C15.433 3.58145 16.2131 3.87697 16.778 4.4419Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.2207 22.5407C22.8552 24.1751 23.0024 26.6779 21.5495 28.1308C20.0967 29.5836 17.5939 29.4364 15.9595 27.8019C14.325 26.1675 14.1778 23.6647 15.6306 22.2119C17.0835 20.759 19.5862 20.9062 21.2207 22.5407ZM20.3368 23.4246C21.5839 24.6716 21.5354 26.3772 20.6657 27.2469C19.7959 28.1166 18.0904 28.1651 16.8433 26.9181C15.5963 25.671 15.6448 23.9654 16.5145 23.0957C17.3842 22.226 19.0898 22.1775 20.3368 23.4246Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.7028 28.1308C36.1557 26.6779 36.0084 24.1751 34.374 22.5407C32.7395 20.9062 30.2368 20.759 28.7839 22.2119C27.331 23.6647 27.4783 26.1675 29.1127 27.8019C30.7472 29.4364 33.25 29.5836 34.7028 28.1308ZM33.8189 27.2469C34.6886 26.3772 34.7372 24.6716 33.4901 23.4246C32.243 22.1775 30.5375 22.226 29.6678 23.0957C28.7981 23.9654 28.7496 25.671 29.9966 26.9181C31.2437 28.1651 32.9492 28.1166 33.8189 27.2469Z"
            fill="currentColor"
          />
          <path
            d="M26.7738 35.6707C26.8455 36.0084 27.1773 36.224 27.5149 36.1524C27.8526 36.0808 28.0683 35.749 27.9966 35.4113L23.6524 14.9314C23.5808 14.5938 23.249 14.3781 22.9113 14.4497C22.5737 14.5213 22.358 14.8531 22.4296 15.1908L26.7738 35.6707Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.83874 25.4593C5.03072 24.6513 4.60804 23.5356 4.67787 22.395L5.48438 9.22205C5.60751 7.211 7.211 5.6075 9.22205 5.48438L22.395 4.67787C23.5356 4.60804 24.6513 5.03072 25.4593 5.83874L42.1612 22.5407C43.719 24.0984 43.719 26.624 42.1612 28.1818L28.1818 42.1612C26.624 43.719 24.0984 43.719 22.5407 42.1612L5.83874 25.4593ZM24.5754 6.72262L41.2774 23.4245C42.347 24.4941 42.347 26.2283 41.2774 27.2979L27.2979 41.2774C26.2283 42.347 24.4941 42.347 23.4245 41.2774L6.72262 24.5754C6.16782 24.0206 5.87759 23.2545 5.92554 22.4714L6.73204 9.29844C6.81659 7.91759 7.91759 6.81658 9.29844 6.73204L22.4714 5.92554C23.2545 5.87759 24.0206 6.16781 24.5754 6.72262Z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.2274 11.3092L52.6909 31.7727C53.6281 32.71 53.6281 34.2295 52.6909 35.1668L35.1669 52.6908C34.2297 53.628 32.7101 53.628 31.7728 52.6908L11.3093 32.2273C10.8231 31.7411 10.5688 31.0698 10.6108 30.3835L11.6218 13.8706C11.6959 12.6606 12.6607 11.6958 13.8707 11.6217L30.3837 10.6107C31.0699 10.5687 31.7412 10.823 32.2274 11.3092ZM10.1779 33.3586C9.36766 32.5484 8.94381 31.4295 9.01383 30.2858L10.0248 13.7728C10.1483 11.7561 11.7563 10.1482 13.7729 10.0247L30.2859 9.01371C31.4296 8.94369 32.5485 9.36754 33.3587 10.1778L53.8223 30.6413C55.3844 32.2034 55.3844 34.7361 53.8223 36.2982L36.2983 53.8221C34.7362 55.3842 32.2035 55.3842 30.6414 53.8221L10.1779 33.3586ZM26.7535 30.0161C28.0936 31.3562 28.0084 33.1471 27.1229 34.0327C26.2373 34.9182 24.4464 35.0034 23.1063 33.6633C21.7663 32.3232 21.8514 30.5323 22.737 29.6468C23.6225 28.7612 25.4134 28.6761 26.7535 30.0161ZM27.8849 28.8848C29.7208 30.7207 29.8862 33.5321 28.2542 35.164C26.6223 36.796 23.8109 36.6306 21.975 34.7947C20.139 32.9587 19.9736 30.1474 21.6056 28.5154C23.2376 26.8834 26.0489 27.0488 27.8849 28.8848ZM41.8978 34.0327C42.7833 33.1471 42.8685 31.3562 41.5284 30.0161C40.1883 28.6761 38.3974 28.7612 37.5119 29.6468C36.6263 30.5323 36.5412 32.3232 37.8812 33.6633C39.2213 35.0034 41.0122 34.9182 41.8978 34.0327ZM43.0291 35.164C44.6611 33.5321 44.4957 30.7207 42.6598 28.8848C40.8238 27.0488 38.0125 26.8834 36.3805 28.5154C34.7485 30.1474 34.9139 32.9587 36.7499 34.7947C38.5858 36.6306 41.3972 36.796 43.0291 35.164ZM33.9107 43.5783C34.0024 44.0105 34.4271 44.2866 34.8593 44.1949C35.2915 44.1032 35.5675 43.6785 35.4759 43.2463L30.6367 20.4332C30.545 20.0009 30.1203 19.7249 29.6881 19.8166C29.2559 19.9083 28.9799 20.3329 29.0715 20.7652L33.9107 43.5783Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
