import React from 'react';
import { isNodeEnv } from '../utils';

/**
 * -----------------------------------
 * DEPRECATED -- Only used by legacy class components.
 * ----------------------------------
 *
 * @deprecated
 */
export abstract class AbstractPureComponent<P, S = {}> extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.tryToValidateProps();
  }

  componentDidUpdate(prevProps: P, prevState: S) {
    this.tryToValidateProps();
  }

  public abstract render(): React.ReactNode;

  /**
   * Used to ensure that a component's props are valid.
   * To be **optionally** implemented by a derived component.
   *
   * Note: this runtime check is only performed in development mode.
   *
   * @param props
   */
  protected validateProps(props: P): void {
    //
  }

  private tryToValidateProps = () => {
    if (!isNodeEnv('production')) {
      this.validateProps(this.props);
    }
  };
}
