import React from 'react';

import { PopperArrowProps } from 'react-popper';
import PopperJS from 'popper.js';
import { Arrow, ArrowBorderPath } from './styles';
import { Props } from '../../../../common/props';

export interface PopoverArrowProps extends Props {
  arrowProps: PopperArrowProps;
  placement: PopperJS.Placement;
}

const PopoverArrow = ({ arrowProps, placement, className }: PopoverArrowProps) => {
  return (
    <Arrow ref={arrowProps.ref} style={arrowProps.style} className={className} data-placement={placement}>
      {/* <svg width="20" height="13" viewBox="0 0 20 13" fill="initial" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.23658 0.902122C9.63603 0.430045 10.3639 0.430045 10.7634 0.902122L19.6072 11.354C20.1572 12.0039 19.6952 12.9999 18.8439 12.9999H1.15608C0.304717 12.9999 -0.157239 12.0039 0.392691 11.354L9.23658 0.902122Z" />
      </svg> */}

      <svg viewBox="0 19 30 10">
        <ArrowBorderPath d="M23.7,27.1L17,19.9C16.5,19.3,15.8,19,15,19s-1.6,0.3-2.1,0.9l-6.6,7.2C5.3,28.1,3.4,29,2,29h26 C26.7,29,24.6,28.1,23.7,27.1z" />
        <path d="M23,27.8c1.1,1.2,3.4,2.2,5,2.2h2H0h2c1.7,0,3.9-1,5-2.2l6.6-7.2c0.7-0.8,2-0.8,2.7,0L23,27.8L23,27.8z" />
      </svg>
    </Arrow>
  );
};

export default PopoverArrow;
