export type RenditionSize = 'avatar' | 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'full';

export const addRendition = <T>({ url, renditionSize, isProduct }: { url: T; renditionSize?: RenditionSize; isProduct?: boolean }): T | string => {
  if (typeof url === 'string' && url.length) {
    try {
      const photoRequestUrl = new URL(url);
      if (renditionSize) {
        photoRequestUrl.searchParams.append('rendition', renditionSize);
      }
      if (isProduct) {
        photoRequestUrl.searchParams.append('product', 'true');
      }
      return photoRequestUrl.toString();
    } catch (err) {
      console.error(err);
    }
  }
  return url;
};
