import React from 'react';
import { IconSource } from '..';
import { IconV1 } from '../IconV1';
import { StyledInput } from './StyledInput';
import { safeInvoke, generateComponentDisplayName, removeNonHTMLProps, extractProps } from '../../utils';
import { HTMLInputProps, BaseControlProps, BaseComponentProps } from '../../common/props';
import { StyledInputContainer } from './StyledInputContainer';
import { StyledInputIcon } from './StyledInputIcon';
import { BASE_COMPONENT_PROPS } from '../../common/styledSystem/types';
import { PaletteColorTokens } from '../../common';
/**
 * -----------------------------------
 * DEPRECATED -- Use `<InputV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { InputV2, InputV2Props } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/InputV2/Input.stories.tsx`.
 */
export interface InputV1Props extends BaseControlProps<HTMLInputElement>, HTMLInputProps, BaseComponentProps {
  /** Automatically focus the input on mount. */
  autoFocus?: boolean;
  children?: never;

  focusedBorderColor?: keyof PaletteColorTokens;

  /**
   * Whether the input is invalid.
   */
  error?: boolean | string;

  /**
   * Whether or not the input's background is transparent.
   *
   * Commonly used against a colored background;
   */
  ghost?: boolean;

  /** ID for the input */
  id?: string;

  iconRight?: IconSource;
  iconLeft?: IconSource;

  inputRef?: (ref: HTMLInputElement | null) => any;

  /**
   * Whether or not the input has a border.
   *
   * Commonly used when the input is part of a larger component with more context.
   */
  minimal?: boolean;
  /** Name of the input */
  name?: string;
}

// /**
//  * A set of dynamic style props that can be used to ensure
//  * consistent Input styling.
//  */
// interface ComposedInputProps extends BorderProps, BorderColorProps {}

interface InternalInputProps extends InputV1Props {}

type State = Readonly<{
  iconRightWidth: number;
  iconLeftWidth: number;
  focused: boolean;
}>;
type CommonInputProps = {
  'aria-invalid': boolean;
  onChange: InputV1Props['onChange'] | undefined;
  onFocus: InputV1Props['onFocus'] | undefined;
  onBlur: InputV1Props['onBlur'] | undefined;
};
/**
 * -----------------------------------
 * DEPRECATED -- Use `<InputV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { InputV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/InputV2/Input.stories.tsx`.
 */
export class InputV1 extends React.PureComponent<InternalInputProps, State> {
  static displayName = generateComponentDisplayName('Input');

  inputRef: HTMLInputElement | null = null;
  iconRef = React.createRef<HTMLSpanElement>();
  iconLeftRef = React.createRef<HTMLSpanElement>();

  static getDerivedStateFromProps(props: InputV1Props, state: State) {
    if (props.disabled && state.focused) {
      return { focused: false };
    }
    return null;
  }

  state = {
    iconLeftWidth: 16,
    iconRightWidth: 16,
    focused: false
  };

  // ==============================================================
  // Lifecycle
  // ==============================================================

  componentDidMount() {
    if (this.props.autoFocus && this.inputRef) {
      this.inputRef.focus();
    }

    this.updateInputWidth();
  }

  componentDidUpdate(prevProps: Readonly<InternalInputProps>): void {
    if (this.props.iconRight !== prevProps.iconRight || this.props.iconLeft !== prevProps.iconLeft) {
      this.updateInputWidth();
    }
  }

  // ==============================================================
  // Callbacks
  // ==============================================================

  handleRef = (node: HTMLInputElement | null) => {
    safeInvoke(this.props.inputRef, node);

    this.inputRef = node;
  };

  handleOnFocus: React.FocusEventHandler<HTMLInputElement> = e => {
    this.setState(state => (!state.focused ? { focused: true } : null));

    safeInvoke(this.props.onFocus, e);
  };

  handleOnBlur: React.FocusEventHandler<HTMLInputElement> = e => {
    this.setState(state => (state.focused ? { focused: false } : null));

    safeInvoke(this.props.onBlur, e);
  };

  // ==============================================================
  // Helpers
  // ==============================================================

  getCommonInputProps = (): CommonInputProps => {
    const { disabled, error } = this.props;

    return {
      'aria-invalid': Boolean(error) || !!this.props['aria-invalid'],
      onChange: disabled ? undefined : this.props.onChange,
      onFocus: disabled ? undefined : this.handleOnFocus,
      onBlur: disabled ? undefined : this.handleOnBlur
    };
  };

  // ==============================================================
  // Render
  // ==============================================================

  render(): React.ReactNode {
    const { focusedBorderColor, iconLeft, iconRight, disabled, onChange, onBlur, onFocus, ...restProps } = this.props;
    const commonInputProps = this.getCommonInputProps();
    return (
      <StyledInputContainer
        minimal={restProps.minimal}
        focused={this.state.focused}
        focusedBorderColor={focusedBorderColor}
        disabled={disabled}
        invalid={commonInputProps['aria-invalid']}
        {...extractProps(restProps, BASE_COMPONENT_PROPS)}
      >
        {iconLeft && this.renderIcon('left', iconLeft, this.iconLeftRef)}

        <StyledInput
          style={{ paddingRight: this.state.iconRightWidth, paddingLeft: this.state.iconLeftWidth }}
          {...removeNonHTMLProps(restProps, BASE_COMPONENT_PROPS)}
          disabled={disabled}
          focusedBorderColor={focusedBorderColor}
          error={commonInputProps['aria-invalid']}
          {...commonInputProps}
          ref={this.handleRef}
        />
        {iconRight && this.renderIcon('right', iconRight, this.iconRef)}
      </StyledInputContainer>
    );
  }

  private renderIcon = (direction: 'left' | 'right', source: IconSource, iconRef: React.RefObject<HTMLSpanElement>) => {
    return (
      <StyledInputIcon direction={direction} ref={iconRef}>
        <IconV1 margin={12} source={source} color={this.props.disabled ? 'currentColor' : undefined} />
      </StyledInputIcon>
    );
  };

  private updateInputWidth = () => {
    const iconContainer = this.iconRef.current;
    const iconLeftContainer = this.iconLeftRef.current;
    let iconLeftWidth = 16;
    let iconRightWidth = 16;
    if (iconContainer) {
      iconRightWidth = iconContainer.clientWidth;
    }
    if (iconLeftContainer) {
      iconLeftWidth = iconLeftContainer.clientWidth;
    }
    this.setState(prevState => ({ ...prevState, iconLeftWidth, iconRightWidth }));
  };
}
