import React, { useEffect, useMemo, useState } from 'react';

import { GridOverlayWrapper } from './GridOverlay.styles';
import { isNodeEnv } from '@shared/utils/isNodeEnv';
import { ENV_VARS } from '@shared/utils/getEnvVar';
import globalWindow from '@shared/core/globals';
import { ColumnsV2, ColumnV2 } from '../ColumnsV2';
import { Box } from '../Box';
import queryString from '@shared/core/queryString';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';

export interface GridOverlayProps {
  active?: boolean;
  children?: React.ReactElement<Element>;
  disableKeyboardTriggers?: boolean;
  onKeyToggle?: () => void;
}

const columns = Array.from(Array(12)).map((_, idx) => (
  <ColumnV2 span={1} key={idx}>
    <Box height={'100vh'} style={{ backgroundColor: 'rgba(255, 0, 0, 0.1)' }} />
  </ColumnV2>
));

const document = globalWindow.document;

const isNotProduction = !isNodeEnv('production');
const isStorybookApp = ENV_VARS.app === 'storybook';

/**
 * A tool that will render grid column markers to assist in content alignment.
 *
 * This component wraps all storybook stories by default, to trigger it:
 *  1. Click the `Grid` button in the storybook toolbar
 *  2. Press ctrl + g on your keyboard.
 * 
 * To use this outside of storybook, this component must used as the parent.

 * @example
 *  <GridOverlay active={true}>
 *    <MyComponent>Some cool content</MyComponent>
 *  </GridOverlay>
 */
const GridOverlay: React.FC<GridOverlayProps> = ({ children, active, disableKeyboardTriggers = false, onKeyToggle }) => {
  const [isActive, setIsActive] = useState<boolean>(() => active || false);
  const mounted = useIsMounted();

  const useOverLay = useMemo(() => {
    if (isNotProduction || isStorybookApp) {
      return true;
    }

    if (mounted) {
      const gridOverlayFeature = queryString.getValue('feature.gridoverlay');
      return typeof gridOverlayFeature === 'string' && gridOverlayFeature.toLowerCase() === 'true';
    }
    return false;
  }, [mounted]);

  useEffect(() => {
    if (!disableKeyboardTriggers && mounted && document && useOverLay) {
      const shortcutListener = (evt: KeyboardEvent) => {
        if (evt.ctrlKey && evt.key === 'g') {
          onKeyToggle?.();
          setIsActive(state => !state);
        }
      };
      document.addEventListener('keydown', shortcutListener);
      return () => {
        document.removeEventListener('keydown', shortcutListener);
      };
    }
    return;
  }, [disableKeyboardTriggers, mounted, onKeyToggle, useOverLay]);

  const test = <ColumnsV2 fluid={'md'}>{columns}</ColumnsV2>;

  const contentMarkup = (
    <>
      <GridOverlayWrapper active={active !== undefined ? active : isActive} data-testid="grid-overlay-wrapper">
        {test}
      </GridOverlayWrapper>
      {children}
    </>
  );
  return useOverLay || isStorybookApp ? contentMarkup : children || null;
};

GridOverlay.displayName = 'GridOverlay';

export { GridOverlay };
