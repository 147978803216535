import React from 'react';
import { PrintOrderManagementState } from './state';
import { FilterBar } from './components/FilterBar';
import { ResultsTable } from './components/ResultsTable';
import { Link, useRouterHelper } from '@shared/core';
import { Box, ButtonV2 } from '@withjoy/joykit';
import { ArrowLeft } from '@withjoy/joykit/icons';
import { routePaths } from '@apps/serviceCenter/route.paths';

export const PrintOrderManagement: React.FC = () => {
  const routeHelpers = useRouterHelper();

  return (
    <PrintOrderManagementState>
      <Box as="main" paddingX={'2.5rem'}>
        <Box display="grid" rowGap={'1.5rem'} maxWidth={'1440px'} marginX={'auto'} marginTop={10}>
          <ButtonV2 as={Link} to={routeHelpers.buildPath(routePaths.servicecenter.path)} variant="link" fullWidth={false} justifySelf={'self-start'}>
            <ArrowLeft size="md" marginRight={'0.5rem'} /> Back to Service Center
          </ButtonV2>
          <FilterBar />
        </Box>
      </Box>
      <ResultsTable />
    </PrintOrderManagementState>
  );
};
