import React from 'react';
import { TextV2, SpacingStack, ButtonV2 } from '@withjoy/joykit';
import { DrawerV2 } from '@withjoy/joykit/components/DrawerV2';
import { LockHeavy } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core/i18n';
import {
  styles,
  OutlineButton,
  StyledIconButton,
  StyledLinksContainer,
  StyledMenuLink,
  StyledMenuTitle,
  drawerOverrides,
  BottomStackStyled,
  StyledMenuLinkBox,
  StyledProfileButton,
  StyledLogoutButton
} from './EventMenu.styles';
import BatchCard from './components/BatchCard/BatchCard';
import MenuButton from './components/MenuButton/MenuButton';
import { useEventMenuController } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/components/EventMenu/EventMenu.controller';
import { Link } from '@react-router';
import { customScrollbarCss } from '@shared/utils/style/scrollbar';
import { useTheme } from '@withjoy/joykit';
import chroma from 'chroma-js';
import { NavbarEventPropsInterface } from '../../../layout.types';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventPageRoute } from '@apps/guest/routes/GuestSite/GuestSite.routes';

interface EventMenuProps
  extends Readonly<{
    title?: Maybe<string>;
    eventHandle: string;
    handleMenuOpenedChange?: (opened: boolean) => void;
    handleMenuClicked?: (pageTitle: string) => void;
    eventProps: NavbarEventPropsInterface;
    isCustomPage?: boolean;
    isBrannanLayout?: boolean;
    hasBanner?: boolean;
  }> {}

const EventMenu: React.FC<EventMenuProps> = ({ title, eventHandle, handleMenuOpenedChange, handleMenuClicked, eventProps, isCustomPage, isBrannanLayout, hasBanner }) => {
  const {
    onOpen,
    isAdmin,
    isOpen,
    onClose,
    name,
    email,
    initials,
    unlockDialogHandle,
    rsvpHandle,
    pages,
    shouldDisplayRSVPButton,
    shouldDisplayUnlockButton,
    onProfileHandle,
    buttonNotYouText,
    logoutHandle,
    isProfile,
    handleClickItemMenu,
    unlockButtonText,
    rsvpButtonText
  } = useEventMenuController({
    eventHandle,
    handleMenuClicked,
    handleMenuOpenedChange,
    eventProps
  });
  const { t2 } = useTranslation('eventMenu');
  const editEventText = t2('editEvent');

  const eventPageRoute = useEventPageRoute(eventHandle);
  const isPreviewing = isInIframe();

  const theme = useTheme();
  const linkTextColor = theme.colors.linkText;
  const colorLinkHoverBackground = chroma(linkTextColor).alpha(0.1).hex();
  const colorLinkActiveBackground = chroma(linkTextColor).alpha(0.2).hex();

  return (
    <>
      <MenuButton onClick={onOpen} isCustomPage={isCustomPage} isBrannanLayout={isBrannanLayout} hasBanner={hasBanner} />
      <DrawerV2 anchor={'left'} disableAutoFocus overrides={drawerOverrides} isOpen={isOpen} onClose={onClose} useBackdrop={false}>
        <DrawerV2.CloseButton />
        <DrawerV2.Body __css={styles.drawerBody}>
          <SpacingStack css={customScrollbarCss} __css={styles.bodyStack}>
            <StyledLinksContainer __css={styles.linksContainer}>
              <StyledMenuTitle {...styles.menuTitle} typographyVariant="display3">
                {title}
              </StyledMenuTitle>
              {pages
                ?.filter(page => page.pageSlug !== 'rsvp' && page.pageSlug !== 'guests')
                .map(page => (
                  <Link onClick={() => handleClickItemMenu(page.pageSlug)} style={styles.linkItem} key={page.pageSlug} to={eventPageRoute.goToPath(page.type, page.pageSlug)}>
                    <StyledMenuLinkBox hoverBackground={colorLinkHoverBackground} activeBackground={colorLinkActiveBackground}>
                      <StyledMenuLink {...styles.links}>{page.pageTitle}</StyledMenuLink>
                    </StyledMenuLinkBox>
                  </Link>
                ))}
            </StyledLinksContainer>
            <BottomStackStyled isPreviewing={isPreviewing}>
              {shouldDisplayUnlockButton && (
                <StyledIconButton onClick={unlockDialogHandle} intent="primary" variant="outline" startIcon={<LockHeavy />}>
                  <TextV2>{unlockButtonText}</TextV2>
                </StyledIconButton>
              )}

              {shouldDisplayRSVPButton && (
                <OutlineButton onClick={rsvpHandle} variant="outline" intent="primary">
                  {rsvpButtonText}
                </OutlineButton>
              )}

              {isAdmin && (
                <ButtonV2
                  onClick={() => handleMenuClicked && handleMenuClicked('EditEvent')}
                  intent="primary"
                  size="sm"
                  typographyVariant="button1"
                  variant="outline"
                  as={Link}
                  to={`/${eventHandle}/edit/dashboard`}
                >
                  {editEventText}
                </ButtonV2>
              )}

              {initials && (
                <div>
                  <StyledProfileButton as={!isProfile ? 'div' : undefined} variant={'link'} onClick={isProfile ? onProfileHandle : undefined}>
                    <BatchCard initials={`${initials}`} name={`${name}`} email={`${email}`} />
                  </StyledProfileButton>
                  {!isAdmin && (
                    <StyledLogoutButton variant={'link'} typographyVariant={'label2'} onClick={logoutHandle}>
                      {buttonNotYouText}
                    </StyledLogoutButton>
                  )}
                </div>
              )}
            </BottomStackStyled>
          </SpacingStack>
        </DrawerV2.Body>
      </DrawerV2>
    </>
  );
};

export default EventMenu;
