import { styled, ThemeStyledProps, Box, BoxProps } from '@withjoy/joykit';
import { system, ConfigStyle } from 'styled-system';
import { ColumnInset, ContentColumnProps } from './ContentColumn';

const _insetToValue: Readonly<Record<ColumnInset, number>> = {
  none: 0,
  md: 64
};

const _styleFn = system({
  leftInset: {
    property: 'marginLeft',
    transform: (value: Maybe<ColumnInset>, scale: unknown, props: ThemeStyledProps<ContentColumnProps>) => {
      return value ? props.theme.typography.pxToRem(_insetToValue[value]) : undefined;
    }
  } as Readonly<ConfigStyle>
});

export const StyledRoot = styled(Box)<BoxProps & { leftInset?: ContentColumnProps['leftInset'] }>`
  ${_styleFn}
`;
