import React from 'react';
import { StyledButton, StyledContainer, StyledUnlockButton } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons.styles';
import { useCtaButtonsController } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons.controller';
import { Flex, TextV2 } from '@withjoy/joykit';
import { GraphQLError } from 'graphql';

export interface CtaButtonsProps {
  hideRsvp?: boolean;
  hideUnlock?: boolean;
  hideGuestName?: boolean;
  onRSVPButtonClicked?: () => void;
  onNotYouButtonClicked?: () => void;
  onNotYouErrorRequest?: (err: GraphQLError) => void;
}

const CtaButtons: React.FC<CtaButtonsProps> = ({
  hideRsvp = false,
  hideUnlock = false,
  hideGuestName = false,
  onRSVPButtonClicked,
  onNotYouButtonClicked,
  onNotYouErrorRequest
}) => {
  const {
    isAdmin,
    guestName,
    personalizedText,
    buttonNotYouText,
    rsvpButtonText,
    shouldDisplayRSVPButton,
    rsvpHandle,
    logoutHandle,
    shouldDisplayUnlockButton,
    unlockButtonText
  } = useCtaButtonsController({
    onRSVPButtonClicked,
    onNotYouButtonClicked,
    onNotYouErrorRequest
  });

  return (
    <StyledContainer>
      {!hideGuestName && !isAdmin && guestName && (
        <Flex columnGap={2}>
          <TextV2 whiteSpace="nowrap" data-testid="identity">
            {personalizedText}
          </TextV2>
          <StyledButton textDecoration="underline" variant="link" onClick={logoutHandle}>
            {buttonNotYouText}
          </StyledButton>
        </Flex>
      )}
      {!hideUnlock && (
        <StyledUnlockButton
          unlockButtonText={unlockButtonText}
          shouldDisplayUnlockButton={shouldDisplayUnlockButton}
          position="relative"
          variant="outline"
          intent="primary"
          data-testid="unlock-btn"
        />
      )}
      {!hideRsvp && shouldDisplayRSVPButton && (
        <StyledButton variant="outline" onClick={rsvpHandle} data-testid="rsvp-btn">
          {rsvpButtonText}
        </StyledButton>
      )}
    </StyledContainer>
  );
};

CtaButtons.displayName = 'CtaButtons';

export { CtaButtons };
