import { styled } from '@withjoy/joykit/theme';
import { Box, ButtonV2, TextV2, StyleSystemProps, SpacingStack } from '@withjoy/joykit';
import { DrawerV2Props } from '@withjoy/joykit/components/DrawerV2';

export const StyledLinksContainer = styled(Box)``;
export const StyledMenuLink = styled(TextV2)``;
export const StyledMenuTitle = styled(TextV2)``;
export const OutlineButton = styled(ButtonV2)``;
export const StyledIconButton = styled(ButtonV2)``;
export const CapitalizedSpan = styled.span`
  text-transform: capitalize;
`;
export const BottomStackStyled = styled(SpacingStack)<{ isPreviewing: boolean }>`
  gap: 0.625rem;
  pointer-events: ${props => (props.isPreviewing ? 'none' : 'auto')};
`;

export const StyledProfileButton = styled(ButtonV2)`
  width: 100%;

  :hover {
    text-decoration: none;
  }
`;

export const StyledLogoutButton = styled(ButtonV2)`
  position: relative;
  left: 4.25rem;
  bottom: 0.5rem;
`;
const logoutButton: StyleSystemProps = {
  position: 'relative',
  left: '4.25rem',
  bottom: '0.5rem'
};

export const StyledMenuLinkBox = styled(Box)<{ hoverBackground: string; activeBackground: string }>`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  margin-left: -12px;

  &:hover {
    background-color: ${props => props.hoverBackground};
  }

  &:active {
    background-color: ${props => props.activeBackground};
  }
`;

const menuTitle: StyleSystemProps = {
  color: 'linkText'
};

const linksContainer: StyleSystemProps = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
};

const links: StyleSystemProps = {
  color: 'linkText',
  _visited: {
    color: 'linkVisited'
  },
  _active: {
    color: 'linkActive'
  },
  _hover: {
    color: 'linkHover'
  }
};

const drawerCloseButton: StyleSystemProps = {
  zIndex: 'overlay',
  position: 'absolute',
  top: 0,
  right: 0,
  color: 'linkText',
  _active: {
    color: 'linkActive'
  },
  _hover: {
    color: 'linkHover'
  }
};

const drawerBody: StyleSystemProps = {
  height: '100%'
};

const bodyStack: StyleSystemProps = {
  height: '100%',
  justifyContent: 'space-between',
  overflowY: 'auto',
  rowGap: 7,
  padding: 7
};

export const drawerOverrides: NonNullable<DrawerV2Props['overrides']> = {
  Content: {
    props: {
      backdropFilter: 'saturate(180%) blur(20px)',
      background: 'rgba(255,255,255,0.97)',
      '-moz-background': 'rgba(255,255,255,0.97)',
      width: '312px'
    }
  },
  CloseButton: {
    props: {
      zIndex: 'overlay',
      position: 'absolute',

      //Restart default values
      padding: 0,
      margin: 0,
      minWidth: 0,
      minHeight: 0,

      top: '4px',
      right: '4px',
      height: '44px',
      width: '44px',
      color: 'linkText',
      borderRadius: 100,
      borderColor: 'none',

      _active: {
        backgroundColor: 'buttonOutlinePrimaryFillPress'
      },
      _hover: {
        backgroundColor: 'buttonOutlinePrimaryFillHover'
      }
    }
  }
};

const linkItem = { width: '100%', marginRight: '16px' };

export const styles = {
  menuTitle,
  links,
  drawerCloseButton,
  drawerBody,
  linksContainer,
  bodyStack,
  linkItem,
  logoutButton
};
