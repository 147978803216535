import { QueryResult } from '@apollo/client';
import { useIsMounted } from '@withjoy/joykit/hooks';
import { useEffect, useState } from 'react';

interface Props extends Partial<QueryResult> {
  onComplete: () => void;
  onError: (error: Error) => void;
}

/**
 * A custom React hook that checks if a component has mounted
 * and if a GraphQL query has emitted its value for the first time.
 *
 * @param {boolean} params.loading - Indicates whether the GraphQL query is still loading.
 * @param {Error | undefined} params.error - Any error that occurred while executing the GraphQL query.
 * @param {Function} params.onComplete - Callback function to be executed when the GraphQL query completes successfully.
 * @param {Function} params.onError - Callback function to be executed when an error occurs during the GraphQL query.
 *
 * @returns {null} Always returns `null`.
 *
 * @example
 * // In your React component
 * import { useMyQuery } from '@apollo/client';
 * import useComponentMountAndGraphqlQueryStatus from './useComponentMountAndGraphqlQueryStatus';
 *
 * function MyComponent() {
 *   const { loading, error, data } = useMyQuery();
 *
 *   useComponentMountAndGraphqlQueryStatus({
 *     loading,
 *     error,
 *     onComplete: () => {
 *       // Handle successful query completion
 *     },
 *     onError: (err) => {
 *       // Handle query error
 *     }
 *   });
 *
 *   // Rest of your component logic
 * }
 */
export function useComponentMountAndGraphqlQueryStatus({ loading, error, onComplete, onError }: Props) {
  const [valueEmited, setValueEmited] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted && !loading && !valueEmited) {
      if (error) {
        onError && onError(error);
      } else {
        onComplete && onComplete();
      }
      setValueEmited(true);
    }
  }, [loading, isMounted, error, onComplete, onError, valueEmited]);

  return null;
}
