/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Moon',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00004 15.3341C12.051 15.3341 15.335 12.0501 15.335 7.99906C15.335 3.94805 12.051 0.664062 8.00004 0.664062C3.94903 0.664062 0.665039 3.94805 0.665039 7.99906C0.665039 12.0501 3.94903 15.3341 8.00004 15.3341ZM11.6198 10.0317C11.9774 9.91291 12.3647 10.2471 12.1667 10.5677C11.3045 11.9632 9.76083 12.893 8.00001 12.893C5.29696 12.893 3.10571 10.7018 3.10571 7.99873C3.10571 6.00085 4.3028 4.28256 6.01883 3.52201C6.36304 3.36945 6.64135 3.79783 6.47503 4.1356C6.19717 4.6999 6.04109 5.33495 6.04109 6.00646C6.04109 8.34852 7.93971 10.2471 10.2818 10.2471C10.7493 10.2471 11.1992 10.1715 11.6198 10.0317Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
