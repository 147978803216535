import { Box, StyleSystemProps } from '../Box';
import { Theme, styled, useTheme } from '@withjoy/joykit/theme';
import { SharedInputProps, Size } from './Input.types';
import { animationTransition } from '@shared/utils/animationTransition';

// eslint-disable-next-line no-restricted-imports
import { useFocusBoxStyles } from './useFocusBoxStyles';

const inputSizes: ReadonlyRecord<Size, Required<Pick<StyleSystemProps, 'height' | 'paddingY' | 'paddingX'>>> = {
  md: {
    height: 9,
    paddingY: 4,
    paddingX: 5
  }
};

const baseRootStyles: StyleSystemProps = {
  display: 'inline-flex',
  backgroundColor: 'inputFill',
  color: 'inputText',
  transition: animationTransition('border-color', 'color', 'background-color', 'box-shadow'),
  _placeholder: {
    color: 'inputPlaceholder'
  }
};

const rootDisabledStyles: StyleSystemProps = {
  color: 'inputTextDisabled',
  backgroundColor: 'inputFillDisabled',
  cursor: 'not-allowed',
  _placeholder: {
    color: 'inputPlaceholderDisabled'
  }
};

export const useRootStyles = (theme: Theme, props: SharedInputProps): StyleSystemProps => {
  const focusBoxStyles = useFocusBoxStyles(props);
  return {
    width: props.$isFullWidth ? '100%' : undefined,
    ...baseRootStyles,
    ...focusBoxStyles,
    ...(props.$isDisabled ? rootDisabledStyles : {})
  };
};

export const getInputStyles = ({ fontSizes, radii }: Theme, { $size, $isFullWidth }: SharedInputProps): StyleSystemProps => {
  return {
    background: 'none',
    border: 'none',
    color: 'currentColor',
    cursor: 'inherit',
    display: 'block',
    fontSize: fontSizes[500],
    ...inputSizes[$size],
    width: $isFullWidth ? '100%' : undefined,
    _focus: {
      outline: 0
    }
  };
};

////////////////////////
// Enhancer

const enhancerPaddingBySize: ReadonlyRecord<Size, Required<Pick<StyleSystemProps, 'paddingLeft' | 'paddingRight'>>> = {
  md: {
    paddingLeft: 5,
    paddingRight: 5
  }
};

const getEnhancerStyles = (theme: Theme, props: SharedInputProps): StyleSystemProps => {
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    transition: animationTransition('color', 'background-color', 'fill'),
    ...enhancerPaddingBySize[props.$size]
  };
};

const getEnhancerElementStyles = (theme: Theme, props: SharedInputProps): StyleSystemProps => {
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    transition: animationTransition('color', 'background-color', 'fill'),
    paddingX: 4,
    position: 'absolute',
    pointerEvents: 'none'
  };
};

////////////////////////
// All

export const useInputStyles = (
  props: SharedInputProps
): {
  Root: StyleSystemProps;
  Input: StyleSystemProps;
  Enhancer: StyleSystemProps;
  EnhancerElement: StyleSystemProps;
} => {
  const theme = useTheme();
  return {
    Root: useRootStyles(theme, props),
    Input: getInputStyles(theme, props),
    Enhancer: getEnhancerStyles(theme, props),
    EnhancerElement: getEnhancerElementStyles(theme, props)
  };
};

export const StyledInput = styled(Box)``;
export const StyledInputRoot = styled(Box)``;
export const StyledEnhancer = styled(Box)``;
export const StyledEnhancerElement = styled(Box)<{ $placement: 'left' | 'right' }>`
  ${props => {
    return {
      [props.$placement]: 0
    };
  }}
`;
