// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isHTMLElement = (el: any): el is HTMLElement => {
  return el && typeof el === 'object' && 'nodeType' in el && el.nodeType === Node.ELEMENT_NODE;
};

export const dataAttr = (condition: boolean | undefined) => {
  return (condition ? '' : undefined) as Booleanish;
};

export function stopEvent(event: React.SyntheticEvent) {
  event.preventDefault();
  event.stopPropagation();
}

export const contains = (parent: HTMLElement | null, child: HTMLElement | null) => {
  return parent ? parent === child || parent.contains(child) : false;
};
