import { useMemo } from 'react';
import { DEFAULT_DESTINATION, DEFAULT_RESERVED_ROOM_BLOCKS } from '../TravelMap.constants';
import { GuestTravelMapComponentFragment, PointOnMapType, PointsOnMapFragment, ReservedRoomBlockStatus, useGuestEventHotelRoomBlocksQuery } from '@graphql/generated';
import {
  AccommodationPlacesV2ToAccommodationCombined,
  AccommodationPlaceToAccommodationCombined,
  RoomBlockToAccommodationCombined
} from '../components/Accommodations/Accommodation.utils';
import { sortByFavorite, sortByOrderList } from '@shared/utils/array';
import { useFeatureValue } from '@shared/core/featureFlags';

export const useTravelMapData = (eventId: string, travelMapComponent: GuestTravelMapComponentFragment) => {
  const accommodationsPlacesV2Enabled = useFeatureValue('accommodationsPlacesV2Enabled').value === 'on';
  const { data: accommodationPlaceData, loading } = useGuestEventHotelRoomBlocksQuery({
    variables: {
      eventId
    },
    fetchPolicy: 'cache-and-network',
    batchMode: 'fast'
  });
  const reservedRoomBlocks = accommodationPlaceData?.event?.hotelRoomBlocks?.reservedRoomBlocks
    ?.filter(roomBlock => roomBlock.status === ReservedRoomBlockStatus.active)
    .map(r => RoomBlockToAccommodationCombined(r));

  const eventPhoto = accommodationPlaceData?.event?.photo?.url ?? '';

  const accommodationsPlace = accommodationsPlacesV2Enabled
    ? accommodationPlaceData?.event?.accommodationPlacesV2?.map(accommodation => AccommodationPlacesV2ToAccommodationCombined(accommodation))
    : accommodationPlaceData?.event?.accommodationPlaces?.map(accommodation => AccommodationPlaceToAccommodationCombined(accommodation));

  const accommodationCombined = useMemo(() => [...(reservedRoomBlocks ?? []), ...(accommodationsPlace ?? [])], [accommodationsPlace, reservedRoomBlocks]);

  const blockOrderingInitial = useMemo(() => (travelMapComponent && travelMapComponent.__typename === 'TravelMapComponent' ? travelMapComponent?.blockOrdering : []), [
    travelMapComponent
  ]);

  const sortedHotelList = useMemo(() => sortByFavorite(sortByOrderList(accommodationCombined, blockOrderingInitial ?? [])), [accommodationCombined, blockOrderingInitial]);

  const blockOrdering = blockOrderingInitial && blockOrderingInitial?.length === sortedHotelList?.length ? blockOrderingInitial : sortedHotelList?.map(roomBlock => roomBlock.id);

  const showDefaultData = !loading && (!sortedHotelList || sortedHotelList.length === 0);
  const pointsOnMap = useMemo(
    () =>
      travelMapComponent && travelMapComponent.__typename === 'TravelMapComponent'
        ? travelMapComponent?.pointsOnMap?.filter((pointOnMap): pointOnMap is Exclude<PointsOnMapFragment, null> => pointOnMap !== null) ?? []
        : [],
    [travelMapComponent]
  );
  const destination = useMemo(() => pointsOnMap?.find(pointOnMap => pointOnMap?.type === PointOnMapType.venue), [pointsOnMap]);

  return {
    loading,
    showDefaultData,
    venues: pointsOnMap,
    eventTimezone: accommodationPlaceData?.event?.info.timezone,
    sortedHotelList: sortedHotelList,
    destination: showDefaultData && !destination ? DEFAULT_DESTINATION : destination,
    eventPhoto,
    reservedRoomBlocks: showDefaultData ? DEFAULT_RESERVED_ROOM_BLOCKS : sortedHotelList,
    blockOrdering,
    pointsOnMap: showDefaultData && pointsOnMap?.length === 0 ? [DEFAULT_DESTINATION] : pointsOnMap
  };
};
