/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Paint Bucket',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M3.856 5.513c-.342-1.109-.529-2.151-.534-2.984-.002-.437.045-.847.167-1.189s.346-.68.737-.837c.451-.181.907-.034 1.263.179.365.219.728.564 1.073.979.169.204.339.431.507.678a1.55 1.55 0 0 1 1.962.19l4.864 4.861c1.081 1.081 1.569 2.246 1.569 3.204a1.96 1.96 0 0 1-3.816.618l-3.914 3.914a1.55 1.55 0 0 1-2.192 0L.99 10.571a1.55 1.55 0 0 1 0-2.192l2.867-2.867zm.889-.889c-.214-.812-.32-1.535-.323-2.1-.002-.367.04-.637.103-.812.029-.082.059-.206.166-.185.049.009.127.036.234.1.217.13.488.372.791.738a8.22 8.22 0 0 1 .545.745L4.746 4.623zm7.99 3.945l-5.779 5.779a.45.45 0 0 1-.636 0L1.767 9.794a.45.45 0 0 1 0-.636l5.068-5.068a15.42 15.42 0 0 1 .666 1.444l.283.759c-.18.204-.29.473-.29.767a1.16 1.16 0 0 0 1.159 1.159 1.16 1.16 0 0 0 1.159-1.159 1.16 1.16 0 0 0-.996-1.147l-.294-.788a16.21 16.21 0 0 0-.874-1.844.45.45 0 0 1 .607.027l4.553 4.553c.21.21.053.488-.072.708zm-.087 2.025a.86.86 0 0 0 .858.858.86.86 0 0 0 .858-.858c0-.415-.153-.977-.56-1.594-.315.503-1.155.976-1.155 1.594z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M5.884 8.451c-.501-1.595-.771-3.086-.778-4.265-.003-.605.062-1.163.226-1.619s.452-.882.94-1.078c.563-.226 1.146-.048 1.628.241.492.295.99.766 1.47 1.344.268.323.537.687.803 1.088l.142-.142a1.6 1.6 0 0 1 2.263 0l8.259 8.272c1.502 1.502 2.164 3.105 2.164 4.401a2.59 2.59 0 0 1-2.588 2.588 2.59 2.59 0 0 1-2.588-2.588c0-.82.647-1.443 1.13-2.034.405-.496.738-.987.832-1.594-.056.082-.12.16-.193.233l-8.848 8.848a1.6 1.6 0 0 1-2.263 0L1.468 15.13a1.6 1.6 0 0 1 0-2.263l4.417-4.417zm.965-.965c-.358-1.278-.538-2.421-.543-3.307-.003-.53.057-.933.155-1.208.161-.451.429-.448.822-.213.33.198.728.557 1.163 1.081.283.341.572.74.859 1.189L6.849 7.486zm3.086-1.389c.373.686.732 1.448 1.061 2.267l.374.999c-.336.3-.548.736-.548 1.222a1.64 1.64 0 0 0 1.637 1.637 1.64 1.64 0 0 0 1.637-1.637 1.64 1.64 0 0 0-1.6-1.637l-.387-1.032c-.393-.978-.832-1.89-1.292-2.703l.346-.346a.4.4 0 0 1 .566 0l7.015 7.015a.4.4 0 0 1 0 .566l-8.848 8.848a.4.4 0 0 1-.566 0l-7.015-7.015a.4.4 0 0 1 0-.566l7.619-7.619zm9.948 9.321c.306-.374.647-.83.874-1.393.757 1.017 1.044 1.967 1.044 2.668a1.39 1.39 0 0 1-1.388 1.388 1.39 1.39 0 0 1-1.388-1.388c0-.458.597-.954.859-1.275z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M8.627 11.381C7.96 9.283 7.602 7.327 7.593 5.795c-.004-.775.08-1.475.282-2.04s.551-1.066 1.118-1.294c.656-.263 1.35-.062 1.954.3.614.368 1.246.963 1.863 1.706.384.462.77.992 1.151 1.58l.55-.55a1.6 1.6 0 0 1 2.263 0l9.527 9.527a1.6 1.6 0 0 1 0 2.263L14.396 29.191a1.6 1.6 0 0 1-2.263 0l-9.527-9.527a1.6 1.6 0 0 1 0-2.263l6.02-6.02zm.961-.961c-.523-1.786-.788-3.395-.795-4.632-.004-.699.075-1.245.212-1.629s.306-.533.436-.585c.15-.06.428-.061.891.216.453.271.984.754 1.556 1.442.397.479.803 1.044 1.206 1.682L9.588 10.42zm4.136-2.439c.529.951 1.039 2.019 1.501 3.171l.616 1.679a1.69 1.69 0 0 0-.575 1.271 1.692 1.692 0 1 0 3.383 0 1.69 1.69 0 0 0-1.684-1.692l-.627-1.705c-.526-1.311-1.117-2.528-1.734-3.605l.756-.756a.4.4 0 0 1 .566 0l9.527 9.527a.4.4 0 0 1 0 .566L13.548 28.342a.4.4 0 0 1-.566 0l-9.527-9.527a.4.4 0 0 1 0-.566L13.724 7.981zm13.557 9.071a3.73 3.73 0 0 1-.292 1.449c-.311.75-.822 1.358-1.282 1.906-.538.641-1.007 1.2-1.007 1.807a2.581 2.581 0 1 0 5.161 0c0-1.115-.451-2.516-1.53-3.956-.3-.401-.649-.805-1.051-1.206zm1.381 5.161a1.381 1.381 0 1 1-2.761.001c0-.003.008-.1.19-.362.138-.199.295-.386.496-.625l.334-.402c.281-.344.596-.753.87-1.236.636 1.028.872 1.949.872 2.625z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M15.19 16.721c-.773-2.557-1.167-4.871-1.178-6.643-.005-.96.102-1.726.299-2.275s.453-.807.7-.906c.284-.114.727-.083 1.378.306.64.383 1.376 1.056 2.159 1.998.571.689 1.155 1.506 1.731 2.431l-5.088 5.088zm-.994.994c-.924-2.876-1.417-5.551-1.428-7.63-.006-1.039.108-1.964.372-2.702s.708-1.361 1.408-1.642c.809-.325 1.684-.084 2.481.393.808.483 1.648 1.272 2.477 2.271.558.673 1.121 1.453 1.676 2.323l1.166-1.166c.634-.634 1.661-.634 2.295 0l13.424 13.424c.634.634.634 1.661 0 2.294l-16.65 16.65c-.634.634-1.661.634-2.295 0L5.697 28.508c-.634-.634-.634-1.661 0-2.294l8.499-8.499zm6.738-4.977c.765 1.353 1.505 2.885 2.17 4.543l1.082 3.042c-.253.269-.409.631-.409 1.029a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-1.433-1.499l-1.085-3.037c-.731-1.822-1.554-3.51-2.413-4.992l1.381-1.381c.147-.147.386-.147.534 0l13.424 13.424c.148.147.148.386 0 .534l-16.65 16.65c-.147.147-.386.147-.534 0L6.578 27.628c-.147-.147-.147-.387 0-.534l14.356-14.356zm20.594 19.615a2.25 2.25 0 1 1-4.5 0c0-.798.983-1.528 1.468-2.122s1.028-1.31 1.431-2.185c1.163 1.658 1.601 3.184 1.601 4.306zm-4.384-2.451c.704-.839 1.496-1.781 1.887-2.988a5.02 5.02 0 0 0 .247-1.561c.416.416.79.834 1.126 1.25 1.679 2.086 2.374 4.138 2.374 5.75a3.5 3.5 0 1 1-7 0c0-.824.636-1.582 1.366-2.451z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
