import { useEffect } from 'react';
import { withWindow } from '@shared/utils/withWindow';

export const useKeycodeListener = (keys: number[], callback: () => void, options?: Pick<KeyboardEvent, 'altKey'>, deps: unknown[] = []) => {
  const match = (event: KeyboardEvent) => {
    if (options && options.altKey !== event.altKey) {
      return false;
    }
    return keys.includes(event.keyCode);
  };

  const onDown = (event: KeyboardEvent) => {
    if (match(event)) {
      callback();
    }
  };

  useEffect(() => {
    withWindow(window => {
      window.addEventListener('keydown', onDown);
    });
    return () => {
      withWindow(window => {
        window.removeEventListener('keydown', onDown);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
