import { useResponsive } from '../hooks/useResponsive';
import { MediaScreen, useDefaultMediaMatchContext } from './DefaultMediaMatchProvider';
import globalWindow from '@shared/core/globals';

const mediaScreenPrecedence: { [K in MediaScreen]: number } = {
  mobile: 0,
  tablet: 1,
  desktop: 2
};

export interface UseMediaScreenParams {
  minScreenSize?: MediaScreen;
  maxScreenSize?: MediaScreen;
}

export const useMediaScreen = ({ minScreenSize = 'mobile', maxScreenSize = 'desktop' }: UseMediaScreenParams): boolean => {
  const { defaultScreen } = useDefaultMediaMatchContext();
  const [mediaScreen] = useResponsive<MediaScreen>({ values: { mobile: 'mobile', tablet: 'tablet', desktop: 'desktop' } }, defaultScreen);
  const currentMediaScreenPrecedence = mediaScreenPrecedence[mediaScreen];

  return mediaScreenPrecedence[minScreenSize] <= currentMediaScreenPrecedence && currentMediaScreenPrecedence <= mediaScreenPrecedence[maxScreenSize];
};

export const useIsMobileScreen = () => {
  return useMediaScreen({
    minScreenSize: 'mobile',
    maxScreenSize: 'mobile'
  });
};

export const useIsMobileOrTablet = () => {
  return useMediaScreen({
    minScreenSize: 'mobile',
    maxScreenSize: 'tablet'
  });
};

export const useIsIOS = (): boolean => (globalWindow.navigator ? Boolean(navigator?.userAgent.match(/ipad|iphone/i)) : false);
