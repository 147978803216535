import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { Tags } from '@withjoy/joykit/icons';
import { createDateFromUnformattedString, isSameMonthCheckInCheckOut } from '@shared/utils/useDateUtils';
import { AccommodationCombinedType } from '../../Accommodation.types';

export const AccommodationTileDiscount: React.FC<{
  strikeoutPricePerNight?: number | null;
  checkInDate?: string | null;
  checkOutDate?: string | null;
  pricePerNight?: number | null;
  type: AccommodationCombinedType;
}> = ({ strikeoutPricePerNight, checkInDate, checkOutDate, pricePerNight, type }) => {
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  if (type !== AccommodationCombinedType.Roomblock) return null;
  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Flex>
        <Tags size={'xl'} />
      </Flex>
      <Flex flexDirection={'column'} justifyContent={'center'}>
        <Flex flexDirection={'row'} columnGap={1} paddingBottom={1}>
          {strikeoutPricePerNight ? (
            <>
              <TextV2
                backgroundSize={'100% 1.5px'}
                width={'auto'}
                backgroundImage={'linear-gradient(currentColor, currentColor)'}
                backgroundPosition={'0% 50%'}
                backgroundRepeat={'no-repeat'}
                fontFamily={'Inter UI'}
                fontSize={15}
                fontWeight={700}
                color={'mono10'}
              >
                ${strikeoutPricePerNight.toString()}
              </TextV2>
              <TextV2 color="mono14" fontFamily={'Inter UI'} fontSize={15} fontWeight={700}>
                ${pricePerNight}
              </TextV2>
            </>
          ) : (
            <>
              <TextV2 color="mono14" fontFamily={'Inter UI'} fontSize={15} fontWeight={700}>
                ${pricePerNight}{' '}
              </TextV2>
            </>
          )}
          <TextV2 fontFamily={'Inter UI'}>{accTrans.night()}</TextV2>
        </Flex>
        <TextV2 typographyVariant={'label2'} fontFamily="Inter UI">
          {createDateFromUnformattedString(checkInDate || '').toLocaleDateString('en-US', { month: 'short', day: '2-digit' })} -{' '}
          {isSameMonthCheckInCheckOut(checkInDate || '', checkOutDate || '')
            ? createDateFromUnformattedString(checkOutDate || '').toLocaleDateString('en-US', { day: '2-digit' })
            : createDateFromUnformattedString(checkOutDate || '').toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}
          {', '}
          {createDateFromUnformattedString(checkInDate || '').toLocaleDateString('en-US', { year: 'numeric' })}
        </TextV2>
      </Flex>
    </Flex>
  );
};
