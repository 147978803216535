import React from 'react';
import { styled, css } from '../../common';
import { IconV1 } from '../IconV1';
import { animationTransition } from '../../utils';
import { PopoverContentWrapper } from '../PopoverV1/styles';
import { TextV1 } from '../TextV1';

export const Container = styled.div<{ fluid?: boolean; noBackground?: boolean }>`
  background-color: ${props => (props.noBackground ? 'transparent' : '#fff')};
  min-width: 100px;

  ${PopoverContentWrapper} {
    width: ${props => (props.fluid ? '100%' : undefined)};
  }
`;

export const ValueContainer = styled.div`
  position: relative;
  /* display: flex;
  align-items: center;
  flex-wrap: wrap; */
`;

export const Placeholder = styled(props => {
  return <div className={props.className}>{props.children}</div>;
})<{ isPlaceholder?: boolean; hasLeftIcon: boolean }>`
  height: 100%;
  position: absolute;
  left: ${props => (props.hasLeftIcon ? '48px' : '16px')};
  top: 0;
  display: flex;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 21px;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  -webkit-appearance: textfield;
  -webkit-rtl-ordering: logical;
  user-select: none;
  pointer-events: none;
  color: ${props => (props.isPlaceholder ? '#a9a9a9' : '#333')};
`;

export const ControllerButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  height: 100%;
  justify-content: center;
  align-items: center;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const MenuContainer = styled.div`
  border-radius: ${props => props.theme.radii[2]};
  position: relative;
`;

export const Menu = styled.ul<{ maxHeight: string; divideAt?: number }>`
  min-width: 176px;
  max-height: ${props => props.maxHeight};
  overflow: auto;
  ${props =>
    props.divideAt
      ? `> li:nth-child(${props.divideAt}) {
            border-bottom: 1px solid #EBEBEB;
          }`
      : undefined};
`;

export const Toggle = styled(IconV1)<{ $rotate?: boolean }>`
  outline: none;
  & > svg {
    transition: ${animationTransition('transform')};
    transform: rotate(${props => (props.$rotate ? '-180deg' : 0)});
  }
`;

export const Item = styled.li<{ isHighlighted: boolean; isSelected: boolean; isDisabled: boolean }>`
  align-items: center;
  background-color: ${props => (props.isSelected ? '#f9f9f9' : props.isHighlighted ? 'rgba(0, 0, 0, .06);' : null)};
  cursor: default;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  list-style: none;
  min-height: 48px;
  padding: 12px 16px;
  :first-of-type {
    border-top-left-radius: ${props => props.theme.radii[2]};
    border-top-right-radius: ${props => props.theme.radii[2]};
  }
  :last-of-type {
    border-bottom-left-radius: ${props => props.theme.radii[2]};
    border-bottom-right-radius: ${props => props.theme.radii[2]};
  }

  transition: ${animationTransition('background-color')};

  & > *:not(:last-child) {
    margin-right: ${props => props.theme.space[3]};
  }

  flex-grow: 1;

  ${props => {
    if (props.isDisabled) {
      return css`
        color: ${props => props.theme.colors.typeDisabledDark};
      `;
    }
    return null;
  }}
`;

export const ItemLabel = styled(TextV1)`
  flex-grow: 1;
`;

export const NoOptionMessage = styled.li`
  list-style: none;
  min-height: 48px;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
`;
