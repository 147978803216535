import { useGuestSiteTelemetry } from '@apps/guest/GuestSite.telemetry';
import { EventPageFragment } from '@graphql/generated';
import { useCallback } from 'react';
import { useGuestSitePageChanged } from '../../common/utils/useGuestSitePageChanged';

export const useLayoutBrannanController = (pages: Array<EventPageFragment>) => {
  const telemetry = useGuestSiteTelemetry();

  useGuestSitePageChanged(pages);

  // MENU
  const handleMenuClicked = useCallback(
    (pageClicked: string) => {
      telemetry.navMenuItemClicked(pageClicked);
    },
    [telemetry]
  );

  const handleMenuOpenedChange = useCallback(
    (opened: boolean) => {
      telemetry.navMenuOpenedChange(opened ? 'opened' : 'closed');
    },
    [telemetry]
  );

  // WELCOME SECTION
  const handleWelcomeRSVPButtonClicked = useCallback(() => {
    telemetry.welcomeRSVPButtonClicked();
  }, [telemetry]);

  //Footer Section
  const handleFooterButtonClicked = useCallback(
    (item: string) => {
      telemetry.footerItemClicked(item);
    },
    [telemetry]
  );

  //Edit Event
  const handleEditEventClicked = useCallback(() => {
    telemetry.editEventClicked();
  }, [telemetry]);

  return {
    handleMenuClicked,
    handleWelcomeRSVPButtonClicked,
    handleFooterButtonClicked,
    handleMenuOpenedChange,
    handleEditEventClicked
  };
};
