import { createContext } from '@shared/utils/createContext';

export interface PrintJobDetailsTelemetryProxyContext {
  onContactSupportClick: () => void;
  onTrackOrderClick: () => void;
  onVisitCourierPageToResolveIssueClick: () => void;
  onGoToOrderDetailsClick: () => void;
  onReorderClick: () => void;
  onEditOrderClick: () => void;
}

const [Provider, useContext] = createContext<PrintJobDetailsTelemetryProxyContext>({ name: 'PrintJobDetailsTelemetryProxy', strict: false });

export { Provider };

export const usePrintJobDetailsTelemetryProxyContext = (): PrintJobDetailsTelemetryProxyContext | undefined => {
  return useContext();
};
