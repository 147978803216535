import { useGuestHotelRoomBlocksLinkQuery } from '@graphql/generated';
import { useHistory } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { Flex } from '@withjoy/joykit';
import React, { useEffect, useState } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { getValidBookingLink } from '../../HotelBooking.utils';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

export const HotelBooking: React.FC<{ eventHandle: string }> = ({ eventHandle }) => {
  const { data, loading, error } = useGuestHotelRoomBlocksLinkQuery({ variables: { eventHandle: eventHandle }, batchMode: 'fast' });
  const history = useHistory();

  const [showNotFound, setShowNotFound] = useState<boolean>(false);

  const telemetry = useHotelBookingGuestSiteTelemetry();

  useMount(() => {
    telemetry.hotelbooking.enter();
  });

  useEffect(() => {
    if (loading) return;

    if (error) {
      telemetry.guestHotelBookingQueryError();
      setShowNotFound(true);
      return;
    }

    if (data) {
      withWindow(() => {
        const bookingLink = data?.eventByName?.hotelRoomBlocks?.bookingLink || '';
        const validBookingLink = getValidBookingLink(bookingLink);
        if (validBookingLink) {
          telemetry.guestHotelBookingRedirect(bookingLink, validBookingLink.hostname, true);
          window.location.replace(bookingLink);
        } else {
          telemetry.guestHotelBookingRedirect(bookingLink, bookingLink, false);
          setShowNotFound(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data, history]);

  if (showNotFound) {
    return <PageNotFound />;
  }

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh" width="100vw">
      <JoyLogoLoader loaderKey="guest-hotel-booking" />
    </Flex>
  );
};
