/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Shuttle',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M3.45 9.5a1.55 1.55 0 1 1 3.1 0 1.55 1.55 0 1 1-3.1 0zM5 9.05a.45.45 0 0 0 0 .9.45.45 0 0 0 0-.9zm6-1.1a1.55 1.55 0 1 0 0 3.1 1.55 1.55 0 1 0 0-3.1zm-.45 1.55a.45.45 0 0 1 .9 0 .45.45 0 1 1-.9 0zM4 .95A2.55 2.55 0 0 0 1.45 3.5v9.55h1.004v.463c-.026.353.079.702.292.984l.023.03.027.027a1.69 1.69 0 0 0 .553.368c.207.085.428.128.652.128s.445-.043.652-.128a1.69 1.69 0 0 0 .553-.368l.027-.027.023-.03a1.46 1.46 0 0 0 .292-.984v-.463h4.908v.463c-.026.353.079.702.292.984l.023.03.027.027a1.69 1.69 0 0 0 .553.368c.207.085.428.128.652.128s.445-.043.652-.128a1.69 1.69 0 0 0 .553-.368l.027-.027.023-.03a1.46 1.46 0 0 0 .292-.984v-.463h1.004V3.5A2.55 2.55 0 0 0 12 .95H4zm7.554 12.1v.511l-.002.025c-.007.075.011.151.052.218a.59.59 0 0 0 .163.1.62.62 0 0 0 .468 0 .59.59 0 0 0 .163-.1.36.36 0 0 0 .052-.218l-.002-.025v-.511h-.892zm1.896-6H2.55v4.9h10.9v-4.9zm-10.9-1.1h10.9v-1.9H2.55v1.9zm.108-3h10.684A1.45 1.45 0 0 0 12 2.05H4a1.45 1.45 0 0 0-1.342.9zm1.788 10.1h-.892v.511l-.002.025c-.007.075.01.151.052.218a.59.59 0 0 0 .163.1.62.62 0 0 0 .468 0 .59.59 0 0 0 .163-.1c.041-.067.059-.143.052-.218l-.002-.025v-.511z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M9.961 3.065c-3.294 0-5.894 3.077-5.894 6.267v16 .6h.6 1.406v1.086c-.044.481.138.936.465 1.282l.029.03.033.026a2.58 2.58 0 0 0 .809.432c.296.097.61.146.925.146s.63-.049.925-.146a2.58 2.58 0 0 0 .809-.432l.033-.026.029-.03c.327-.346.509-.801.465-1.282v-1.086h10.813v1.086c-.044.481.138.936.465 1.282l.029.03.033.026a2.58 2.58 0 0 0 .809.432c.296.097.61.146.925.146s.63-.049.926-.146a2.58 2.58 0 0 0 .809-.432l.033-.026.029-.03c.327-.346.509-.801.465-1.282v-1.086h1.406.6v-.6-16c0-3.19-2.6-6.267-5.894-6.267H9.961zm12.646 22.867h2.12v1.117.035l.004.034a.43.43 0 0 1-.115.329 1.41 1.41 0 0 1-.398.202c-.171.056-.359.086-.551.086s-.38-.03-.551-.086c-.154-.051-.289-.121-.398-.202a.43.43 0 0 1-.115-.329l.004-.034v-.035-1.117zm3.32-1.2h.806v-8.583H5.267v8.583h.806 0 .6 3.32.6 0 10.813 0 .6 3.32.6 0zm-20.66-9.783h21.467V9.482H5.267v5.467zm.114-6.667H26.62c-.477-2.196-2.392-4.017-4.58-4.017H9.961c-2.188 0-4.103 1.821-4.58 4.017zm1.893 17.65h2.12v1.117.035l.004.034a.43.43 0 0 1-.115.329 1.41 1.41 0 0 1-.398.202c-.171.056-.359.086-.551.086s-.38-.03-.551-.086a1.41 1.41 0 0 1-.398-.202.43.43 0 0 1-.115-.329l.004-.034v-.035-1.117zm.661-5.6c0-.957.776-1.733 1.733-1.733s1.733.776 1.733 1.733-.776 1.733-1.733 1.733-1.733-.776-1.733-1.733zm1.733-2.933c-1.62 0-2.933 1.313-2.933 2.933s1.313 2.933 2.933 2.933 2.933-1.313 2.933-2.933-1.313-2.933-2.933-2.933zm12.667 1.2c-.957 0-1.733.776-1.733 1.733s.776 1.733 1.733 1.733 1.733-.776 1.733-1.733-.776-1.733-1.733-1.733zM19.4 20.332c0-1.62 1.313-2.933 2.933-2.933s2.933 1.313 2.933 2.933-1.313 2.933-2.933 2.933-2.933-1.313-2.933-2.933z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M14.941 4.873c-4.766 0-8.566 4.468-8.566 9.125v24 .625H7h2.385v1.917c-.062.639.176 1.25.622 1.722l.03.032.034.027c.328.262.712.465 1.127.601s.858.206 1.302.206.887-.069 1.302-.206.799-.339 1.127-.601l.034-.027.03-.032c.446-.472.684-1.083.622-1.722v-1.917h16.769v1.917c-.062.639.176 1.25.622 1.722l.03.032.034.027c.328.262.712.465 1.127.601s.858.206 1.302.206.887-.069 1.302-.206.799-.339 1.127-.601l.034-.027.03-.032c.445-.472.684-1.083.622-1.722v-1.917H41h.625v-.625-24c0-4.657-3.8-9.125-8.566-9.125H14.941zm22.424 33.75h-3.731v1.95.036l-.004.036c-.027.234.047.491.257.728a2.37 2.37 0 0 0 .701.362c.285.094.596.143.912.143s.627-.049.912-.143a2.37 2.37 0 0 0 .701-.362c.21-.237.284-.494.257-.728l-.004-.036v-.036-1.95zm1.25-1.25h1.76V23.948H7.625v13.425h1.76.625 4.981.625 16.769.625 4.981.625zM7.625 22.698h32.75v-8.7-.05H7.625v.05 8.7zm.112-10h32.525c-.614-3.55-3.661-6.575-7.204-6.575H14.941c-3.543 0-6.59 3.025-7.204 6.575zm6.628 25.925h-3.731v1.95.036l-.004.036c-.027.234.047.491.257.728a2.37 2.37 0 0 0 .7.362c.285.094.596.143.912.143s.627-.049.912-.143a2.37 2.37 0 0 0 .7-.362c.21-.237.284-.494.257-.728l-.004-.036v-.036-1.95zm-2.74-8.125c0-1.588 1.287-2.875 2.875-2.875s2.875 1.287 2.875 2.875-1.287 2.875-2.875 2.875-2.875-1.287-2.875-2.875zm2.875-4.125c-2.278 0-4.125 1.847-4.125 4.125s1.847 4.125 4.125 4.125 4.125-1.847 4.125-4.125-1.847-4.125-4.125-4.125zm19 1.25c-1.588 0-2.875 1.287-2.875 2.875s1.287 2.875 2.875 2.875 2.875-1.287 2.875-2.875-1.287-2.875-2.875-2.875zm-4.125 2.875c0-2.278 1.847-4.125 4.125-4.125s4.125 1.847 4.125 4.125-1.847 4.125-4.125 4.125-4.125-1.847-4.125-4.125z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M19.921 6.53c-6.333 0-11.388 5.939-11.388 12.133v32 .8h.8 3.213v2.591c-.082.842.232 1.648.82 2.272l.038.041.043.035c.434.347.942.615 1.492.796a5.55 5.55 0 0 0 1.726.272 5.55 5.55 0 0 0 1.726-.272c.55-.181 1.059-.449 1.492-.796l.043-.035.038-.041c.588-.624.902-1.43.82-2.272v-2.591h22.426v2.591c-.082.842.232 1.648.82 2.272l.038.041.044.035c.434.347.942.615 1.492.796a5.55 5.55 0 0 0 1.726.272 5.55 5.55 0 0 0 1.726-.272c.55-.181 1.059-.449 1.492-.796l.044-.035.038-.041c.588-.624.903-1.43.82-2.272v-2.591h3.213.8v-.8-32c0-6.194-5.055-12.133-11.388-12.133H19.921zm24.891 44.933h5.041v2.633.046l.005.046c.038.323-.065.676-.352.999-.263.202-.583.37-.946.489a3.95 3.95 0 0 1-1.227.192 3.95 3.95 0 0 1-1.227-.192c-.363-.119-.683-.288-.946-.489-.287-.323-.39-.676-.352-.999l.005-.046v-.046-2.633zm6.641-1.6h2.413V31.898H10.133v17.966h2.413 0 .8 6.641.8 0 22.426 0 .8 6.641.8 0zm-41.32-19.566h43.733V18.664l-.001-.099H10.134l-.001.099v11.634zm.144-13.333h43.446c-.805-4.764-4.889-8.834-9.644-8.834H19.921c-4.756 0-8.839 4.07-9.644 8.834zm3.869 34.5h5.041v2.633.046l.005.046c.038.323-.065.676-.352.999-.263.202-.583.37-.946.489a3.95 3.95 0 0 1-1.227.192 3.95 3.95 0 0 1-1.227-.192c-.363-.119-.683-.288-.946-.489-.287-.323-.39-.676-.352-.999l.005-.046v-.046-2.633zm1.32-10.8a3.87 3.87 0 0 1 3.867-3.867 3.87 3.87 0 0 1 3.867 3.867 3.87 3.87 0 0 1-3.867 3.867 3.87 3.87 0 0 1-3.867-3.867zm3.867-5.467a5.47 5.47 0 0 0-5.467 5.467 5.47 5.47 0 0 0 5.467 5.467 5.47 5.47 0 0 0 5.467-5.467 5.47 5.47 0 0 0-5.467-5.467zm25.334 1.6a3.87 3.87 0 0 0-3.867 3.867 3.87 3.87 0 0 0 3.867 3.867 3.87 3.87 0 0 0 3.867-3.867 3.87 3.87 0 0 0-3.867-3.867zM39.2 40.664a5.47 5.47 0 0 1 5.467-5.467 5.47 5.47 0 0 1 5.467 5.467 5.47 5.47 0 0 1-5.467 5.467 5.47 5.47 0 0 1-5.467-5.467z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
