import React, { useMemo } from 'react';
import { forwardRef } from '@shared/utils/forwardRef';
import { Box } from '../Box';
import { useFormControlStyles } from './FormControl.styles';
import { useId } from '@reach/auto-id';
import { FormControlProps, FormControlOverrides, SharedFormControlProps } from '.';
import { FormControlContext } from '.';
import { useOverrides } from '@shared/utils/overrides';

const defaultProps = {
  overrides: {} as FormControlOverrides
} as const;

export const defaultComponents = {
  Root: Box,
  Label: Box,
  ContentContainer: Box,
  Hint: Box
} as const;

const FormControl = forwardRef<'div', FormControlProps>((props, ref) => {
  const { caption, children, error, htmlFor, isDisabled, isInvalid, isRequired, label, overrides, ...restProps } = props as FormControlProps & typeof defaultProps;
  const {
    Root: [Root, rootOverrides],
    Label: [Label, labelOverrides],
    ContentContainer: [ContentContainer, contentOverrides],
    Hint: [Hint, hintOverrides]
  } = useOverrides(defaultComponents, { Root: overrides.Root, Label: overrides.Label, ContentContainer: overrides.ContentContainer, Hint: overrides.Hint });

  const context = useMemo(() => {
    return {
      isDisabled,
      isInvalid,
      isRequired
    };
  }, [isDisabled, isInvalid, isRequired]);

  const onlyChildProps = React.Children.only(children).props;
  const id = useId(htmlFor || onlyChildProps.id);
  const captionId = `field-${id}-helptext`;
  const sharedProps: SharedFormControlProps = {
    $isInvalid: !!context.isInvalid,
    $isDisabled: !!context.isDisabled
  };

  const styles = useFormControlStyles(sharedProps);

  // Render

  const renderFormElementContent = (content: Maybe<FormControlProps['error']>) => {
    if (!content) {
      return null;
    }

    return typeof content === 'function' ? content(sharedProps) : content;
  };

  const renderChildren = () => {
    return React.Children.map(children, (child, index) => {
      if (!child) {
        return;
      }
      const key = child.key || `${index}`;
      return React.cloneElement(child, {
        key,
        'aria-errormessage': error ? captionId : null,
        'aria-describedby': caption ? captionId : null
      });
    });
  };

  const renderHint = () => {
    const hint = error || caption;
    return renderFormElementContent(hint);
  };

  return (
    <FormControlContext.Provider value={context}>
      <Root role="group" ref={ref} {...rootOverrides} {...restProps}>
        {label && (
          <Label as="label" htmlFor={id} {...styles.Label} {...labelOverrides}>
            {renderFormElementContent(label)}
          </Label>
        )}
        <ContentContainer {...contentOverrides}>{renderChildren()}</ContentContainer>
        {(caption || error) && (
          <Hint id={captionId} {...styles.Hint} {...hintOverrides}>
            {renderHint()}
          </Hint>
        )}
      </Root>
    </FormControlContext.Provider>
  );
});

FormControl.displayName = 'FormControl';
FormControl.defaultProps = defaultProps;

export { FormControl };
