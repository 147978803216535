import React, { createContext, useContext, useState } from 'react';
import { UnlockDialog } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialog';
import { useDisclosure } from '@withjoy/joykit';

export type UnlockDialogContextValue = {
  isOpen: boolean;
  handleUnlockDialogOpen: (shouldDisplayFindInvite?: boolean) => void;
  onClose: () => void;
  shouldDisplayFindInvite: boolean;
};

export const UnlockDialogContext = createContext<UnlockDialogContextValue | undefined>(undefined);

export const UnlockDialogProvider: React.FC = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shouldDisplayFindInvite, setShouldDisplayFindInvite] = useState<boolean>(false);

  const handleUnlockDialogOpen = (displayFindInvite?: boolean) => {
    setShouldDisplayFindInvite(displayFindInvite ?? false);
    onOpen();
  };

  return (
    <UnlockDialogContext.Provider value={{ isOpen, handleUnlockDialogOpen, onClose, shouldDisplayFindInvite }}>
      {children}
      <UnlockDialog isOpen={isOpen} handleUnlockDialogOpen={handleUnlockDialogOpen} onClose={onClose} shouldDisplayFindInvite={shouldDisplayFindInvite} />
    </UnlockDialogContext.Provider>
  );
};

export const useUnlockDialogContext = () => {
  const context = useContext(UnlockDialogContext);
  if (context === undefined) {
    throw new Error('useUnlockDialogContext must be used within a UnlockDialogProvider');
  }
  return context;
};
