import React from 'react';
import { useAuth } from '@shared/components/AuthProvider';
import { Redirect, Route, RouteProps } from '@react-router';

export const SuperAdminRoute = (routeProps: RouteProps) => {
  const { hasInitializedOnce, currentUser } = useAuth();

  if (!__DEV__) {
    if (!hasInitializedOnce) {
      return null;
    }

    if (!currentUser.profile?.superAdmin) {
      return <Redirect to={{ pathname: '/account?mode=signin&provider=google' }} />;
    }
  }

  return <Route {...routeProps} />;
};
