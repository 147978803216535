import { animationTransition } from '@shared/utils/animationTransition';
import { Box, Flex, keyframes, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const mapUIstyles = [
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#878787'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f9f5ed'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#c9c9c9'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#aee0f4'
      }
    ]
  }
];

const dotAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
`;

export const MapLoading = styled(Flex)``;
const mapLoadingStyles = (loading: boolean): StyleSystemProps => ({
  position: 'absolute',
  width: pxToRem(148),
  height: pxToRem(40),
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: 3,
  backgroundColor: 'white',
  borderRadius: pxToRem(20),
  boxShadow: '0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)',
  left: '50%',
  transform: 'translateX(-50%)',
  top: {
    _: 5,
    sm2: 6
  },
  pointerEvents: 'none',
  opacity: loading ? 1 : 0,
  transition: animationTransition('opacity')
});

export const MapLoadingDot = styled(Box)`
  animation: ${dotAnimation} 1s infinite;
  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;
const mapLoadingDotStyles: StyleSystemProps = {
  width: 4,
  height: 4,
  backgroundColor: 'black',
  borderRadius: '50%'
};

export const styles = {
  mapLoading: mapLoadingStyles,
  mapLoadingDot: mapLoadingDotStyles
};
