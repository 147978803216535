import { useTranslation } from '@shared/core/i18n';

export const useLayoutEngineTranslations = () => {
  const { t } = useTranslation('layoutEngine');

  const footerTranslations = t('components').footer;

  return {
    help: footerTranslations.help(),
    about: footerTranslations.about()
  };
};
