type DynamicMetricObject = {
  fontSizeUnit: 'rem' | 'px';
  fontSize: number;
  letterSpacing: string;
  lineHeight: string;
};

/**
 * Supported Inter UI Web font sizes
 */
export type InterUIFontSize = 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 20 | 26;

/**
 * For a given font size, there is a matching set of: font size, line height,
 * and letter spacing. Inter UI Dynamic Metric provides guidelines for how to
 * best use Inter UI. https://rsms.me/inter/dynmetrics/
 * @param fontSize number
 */
export const getInterUIDynamicMetricObject = (fontSize: InterUIFontSize): DynamicMetricObject => {
  switch (fontSize) {
    case 10:
      return { fontSizeUnit: 'px', fontSize: 10, letterSpacing: '0.018em', lineHeight: '14px' };
    case 11:
      return { fontSizeUnit: 'px', fontSize: 11, letterSpacing: '0.005em', lineHeight: '15px' };
    case 12:
      return { fontSizeUnit: 'px', fontSize: 12, letterSpacing: '0px', lineHeight: '17px' };
    case 13:
      return { fontSizeUnit: 'px', fontSize: 13, letterSpacing: '0px', lineHeight: '20px' };
    case 14:
      return { fontSizeUnit: 'px', fontSize: 14, letterSpacing: '0px', lineHeight: '20px' };
    case 15:
      return { fontSizeUnit: 'px', fontSize: 15, letterSpacing: '0px', lineHeight: '22px' };
    case 16:
      return { fontSizeUnit: 'px', fontSize: 16, letterSpacing: '-0.005em', lineHeight: '22px' };
    case 17:
      return { fontSizeUnit: 'px', fontSize: 17, letterSpacing: '0px', lineHeight: '24px' };
    case 20:
      return { fontSizeUnit: 'px', fontSize: 20, letterSpacing: '-0.01em', lineHeight: '28px' };
    case 26:
      return { fontSizeUnit: 'px', fontSize: 26, letterSpacing: '-0.4px', lineHeight: '40px' };
    default:
      return { fontSizeUnit: 'px', fontSize: 16, letterSpacing: '-0.005em', lineHeight: '22px' };
  }
};

export type InterUIMetrics = Omit<DynamicMetricObject, 'fontSizeUnit'>;

/**
 * Inter UI Dynamic Metric provides guidelines for how to best use their font
 *
 * We will leverage their system by using their defined "optimal metrics".
 *
 * @param opticalFontSize Target font size metrics
 * @param as Target output unit for font size
 *
 * @returns a InterUIMetrics object to be used in a CSS-in-JS rule
 */
export const getInterUIDynamicMetricCssObject = (opticalFontSize: InterUIFontSize, as: 'rem' | 'px' = 'px'): InterUIMetrics => {
  const { fontSize, letterSpacing, lineHeight } = getInterUIDynamicMetricObject(opticalFontSize);

  return {
    fontSize,
    letterSpacing,
    lineHeight
  };
};
