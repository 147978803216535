import React from 'react';
import { ColumnsV2, ColumnsV2Props } from '@withjoy/joykit';
import { ContentColumn } from '../ContentColumn';

export interface ContentColumnsProps extends Readonly<{}>, ColumnsV2Props {}

/**
 * A simple wrapper for Joykit's `<ColumnsV2 />` component.
 *
 * This component's props extends ColumnsV2Props.
 *
 * The `ContentColumn` sub-component can be referenced via `ContentColumns.Column`.
 */
const ContentColumns: React.FC<ContentColumnsProps> & { Column: typeof ContentColumn } = ({ children, ...restProps }) => {
  return <ColumnsV2 {...restProps}>{children}</ColumnsV2>;
};

ContentColumns.Column = ContentColumn;
ContentColumns.displayName = 'ContentColumns';

export { ContentColumns };
