/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Warning',
  definitions: {
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M41.55 24c0 9.693-7.857 17.55-17.55 17.55S6.45 33.693 6.45 24 14.307 6.45 24 6.45 41.55 14.307 41.55 24zm1.2 0c0 10.355-8.395 18.75-18.75 18.75S5.25 34.355 5.25 24 13.645 5.25 24 5.25 42.75 13.645 42.75 24zm-19.5-8.812a.94.94 0 0 1 .938-.937.94.94 0 0 1 .938.938v13.125a.94.94 0 0 1-.937.938.94.94 0 0 1-.937-.937V15.188zm1.875 18a.94.94 0 0 1-.937.938.94.94 0 0 1-.937-.937.94.94 0 0 1 .938-.937.94.94 0 0 1 .938.938z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
