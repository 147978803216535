import { styled, StyleSystemProps } from '@withjoy/joykit';
import { Box, BoxProps } from '../Box';
import type { DrawerSize, SharedDrawerProps, DrawerPropsWithDefaults, DrawerTransitionStates } from './Drawer.types';
import { ANCHOR_AXIS, ANCHOR_ORIGIN, SIZE_DIMENSION, DRAWER_TRANSFORMS } from './Drawer.constants';
import { MultiPartStyleConfig, PartsStyleFunction, StyleSystemFn, anatomy } from '@withjoy/joykit/utils';
import { pxToRem } from '@shared/joykit/packages/core/common/themes/shared/typography';
const INNER_PADDING_PROPS: Readonly<Pick<BoxProps, 'paddingX' | 'paddingY'>> = {
  paddingX: [6, 7],
  paddingY: ['20px', 6]
};

export const StyledHeader = styled(Box)``;
export const StyledBody = styled(Box)``;
const baseHeaderStyle: StyleSystemFn<DrawerPropsWithDefaults> = props => ({
  backgroundColor: 'white',
  ...(props.stickyBehavior === 'header' || props.stickyBehavior === 'header-footer'
    ? {
        position: 'sticky',
        top: 0,
        zIndex: 'sticky',
        backgroundColor: 'white'
      }
    : undefined),
  _first: {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px'
  },
  ...INNER_PADDING_PROPS
});

const baseStyleCloseButton: StyleSystemProps = {
  position: 'absolute',
  top: 2,
  right: 2
};

const baseStyleBody: StyleSystemFn<DrawerPropsWithDefaults> = props => ({
  flex: '1 1 0%',
  ...INNER_PADDING_PROPS
});

const baseStyleFooter: StyleSystemFn<DrawerPropsWithDefaults> = props => {
  const { stickyBehavior } = props;
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    ...(stickyBehavior === 'footer' || stickyBehavior === 'header-footer'
      ? {
          position: 'sticky',
          bottom: 0,
          zIndex: 'sticky',
          backgroundColor: 'white',
          borderBottomRightRadius: [0, 12],
          borderBottomLeftRadius: [0, 12]
        }
      : undefined),
    ...INNER_PADDING_PROPS
  };
};

export const StyledFooter = styled(Box)``;

const baseStyleDialogRoot: StyleSystemProps = {
  overflow: 'auto',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 'modal',
  backfaceVisibility: 'hidden'
};

export const getTransitionStates = (shared: SharedDrawerProps): DrawerTransitionStates => {
  const transforms = DRAWER_TRANSFORMS[shared.$anchor];
  return {
    from: {
      opacity: 0,
      transform: transforms.out
    },
    to: shared.$isOpen
      ? {
          opacity: 1,
          transform: transforms.in
        }
      : {
          opacity: 0,
          transform: transforms.out
        }
  };
};

export const getAnchorOriginStyles = ({ $anchor }: SharedDrawerProps) => {
  const config = ANCHOR_ORIGIN[$anchor];
  return {
    [config.x]: 0,
    [config.y]: 0
  };
};

const sizeToValue = (size: DrawerSize): string => {
  if (size === 'auto') {
    return 'auto';
  }
  if (typeof size === 'string') {
    const value = SIZE_DIMENSION[size];
    return typeof value === 'number' ? pxToRem(value) : value;
  }

  return size <= 1 ? `${size * 100}%` : pxToRem(size);
};

export const getSizeStyles = (props: SharedDrawerProps) => {
  const upperBoundSize = sizeToValue(props.$size);
  const { crossAxis, mainAxis } = ANCHOR_AXIS[props.$anchor];
  return {
    [crossAxis]: SIZE_DIMENSION.full,
    [mainAxis]: upperBoundSize
  };
};

const baseDialogContentStyle: StyleSystemFn<DrawerPropsWithDefaults> = props => {
  return {
    position: 'fixed',
    backgroundColor: 'white',
    boxShadow: '0px 50px 100px rgba(44, 41, 37, 0.25), 0px 30px 60px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    backfaceVisibility: 'hidden',
    pointerEvents: 'auto',
    overflow: 'auto',
    height: 'unset',
    maxHeight: '100%',
    ...(props.enableDividers
      ? {
          [`${StyledHeader} ~ ${StyledBody}`]: {
            borderTop: `1px solid ${props.theme.colors.mono3}`,
            _notLastChild: {
              borderBottom: `1px solid ${props.theme.colors.mono3}`
            }
          }
        }
      : {
          [`${StyledHeader} ~ ${StyledBody}`]: {
            paddingTop: 0
          }
        })
  };
};

export const StyledDialogContent = styled(Box)``;

export const drawerAnatomy = anatomy('Drawer').parts('dialogRoot', 'contentContainer', 'content').extend('closeButton', 'body', 'header', 'footer');

const drawerBaseStyles: PartsStyleFunction<DrawerPropsWithDefaults, typeof drawerAnatomy> = props => {
  return {
    dialogRoot: baseStyleDialogRoot,
    contentContainer: {},
    content: baseDialogContentStyle(props),
    closeButton: baseStyleCloseButton,
    body: baseStyleBody(props),
    header: baseHeaderStyle(props),
    footer: baseStyleFooter(props)
  };
};

const drawerStyles: MultiPartStyleConfig<DrawerPropsWithDefaults, typeof drawerAnatomy> = {
  parts: drawerAnatomy.keys,
  baseStyle: drawerBaseStyles,
  defaultProps: {
    size: 'sm'
  }
};

export { drawerStyles };
