/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Zoom Contract',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M10.1333 6.56129C9.64548 6.56129 9.25 6.16581 9.25 5.67796V3.34462C9.25 3.04087 9.49624 2.79462 9.8 2.79462C10.1038 2.79462 10.35 3.04087 10.35 3.34462V4.86949L13.7068 1.51265C13.9216 1.29787 14.2699 1.29787 14.4847 1.51265C14.6994 1.72744 14.6994 2.07568 14.4847 2.29047L11.3138 5.46129H12.4667C12.7704 5.46129 13.0167 5.70753 13.0167 6.01129C13.0167 6.31505 12.7704 6.56129 12.4667 6.56129H10.1333Z"
            fill="currentColor"
          />
          <path
            d="M6.75 5.67797C6.75 6.16582 6.35452 6.5613 5.86666 6.5613H3.53333C3.22957 6.5613 2.98333 6.31506 2.98333 6.0113C2.98333 5.70754 3.22957 5.4613 3.53333 5.4613H4.68616L1.51534 2.29048C1.30055 2.07569 1.30055 1.72745 1.51534 1.51266C1.73013 1.29787 2.07837 1.29787 2.29316 1.51266L5.65 4.8695V3.34463C5.65 3.04088 5.89624 2.79463 6.2 2.79463C6.50375 2.79463 6.75 3.04088 6.75 3.34463V5.67797Z"
            fill="currentColor"
          />
          <path
            d="M6.75 10.3246C6.75 9.83678 6.35452 9.44129 5.86667 9.44129H3.53333C3.22958 9.44129 2.98333 9.68754 2.98333 9.99129C2.98333 10.295 3.22958 10.5413 3.53333 10.5413H4.68616L1.51534 13.7121C1.30055 13.9269 1.30055 14.2751 1.51534 14.4899C1.73013 14.7047 2.07837 14.7047 2.29316 14.4899L5.65 11.1331V12.658C5.65 12.9617 5.89624 13.208 6.2 13.208C6.50376 13.208 6.75 12.9617 6.75 12.658V10.3246Z"
            fill="currentColor"
          />
          <path
            d="M9.25 10.3246C9.25 9.83677 9.64548 9.44129 10.1333 9.44129H12.4667C12.7704 9.44129 13.0167 9.68753 13.0167 9.99129C13.0167 10.295 12.7704 10.5413 12.4667 10.5413H11.3138L14.4847 13.7121C14.6994 13.9269 14.6994 14.2751 14.4847 14.4899C14.2699 14.7047 13.9216 14.7047 13.7068 14.4899L10.35 11.1331V12.658C10.35 12.9617 10.1038 13.208 9.8 13.208C9.49624 13.208 9.25 12.9617 9.25 12.658V10.3246Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M15.2 9.61638C14.5925 9.61638 14.1 9.1239 14.1 8.51638V5.01638C14.1 4.68501 14.3686 4.41638 14.7 4.41638C15.0313 4.41638 15.3 4.68501 15.3 5.01638V7.52869L20.7193 2.10933C20.9536 1.87502 21.3335 1.87502 21.5679 2.10933C21.8022 2.34364 21.8022 2.72354 21.5679 2.95786L16.1093 8.41638H18.7C19.0313 8.41638 19.3 8.68501 19.3 9.01638C19.3 9.34775 19.0313 9.61638 18.7 9.61638H15.2Z"
            fill="currentColor"
          />
          <path
            d="M9.89997 8.5164C9.89997 9.12391 9.40748 9.6164 8.79997 9.6164H5.29997C4.9686 9.6164 4.69997 9.34777 4.69997 9.0164C4.69997 8.68502 4.9686 8.4164 5.29997 8.4164H7.89061L2.43208 2.95787C2.19777 2.72356 2.19777 2.34366 2.43208 2.10934C2.6664 1.87503 3.0463 1.87503 3.28061 2.10934L8.69997 7.5287V5.0164C8.69997 4.68502 8.9686 4.4164 9.29997 4.4164C9.63134 4.4164 9.89997 4.68502 9.89997 5.0164V8.5164Z"
            fill="currentColor"
          />
          <path
            d="M9.89998 15.4864C9.89998 14.8789 9.40749 14.3864 8.79998 14.3864H5.29998C4.9686 14.3864 4.69998 14.655 4.69998 14.9864C4.69998 15.3178 4.9686 15.5864 5.29998 15.5864H7.89061L2.43209 21.0449C2.19777 21.2792 2.19777 21.6591 2.43209 21.8934C2.6664 22.1278 3.0463 22.1278 3.28062 21.8934L8.69998 16.4741V18.9864C8.69998 19.3178 8.96861 19.5864 9.29998 19.5864C9.63135 19.5864 9.89998 19.3178 9.89998 18.9864V15.4864Z"
            fill="currentColor"
          />
          <path
            d="M14.1 15.4864C14.1 14.8789 14.5925 14.3864 15.2 14.3864H18.7C19.0313 14.3864 19.3 14.655 19.3 14.9864C19.3 15.3178 19.0313 15.5864 18.7 15.5864H16.1093L21.5679 21.0449C21.8022 21.2792 21.8022 21.6591 21.5679 21.8934C21.3335 22.1278 20.9536 22.1278 20.7193 21.8934L15.3 16.4741V18.9864C15.3 19.3178 15.0313 19.5864 14.7 19.5864C14.3686 19.5864 14.1 19.3178 14.1 18.9864V15.4864Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
