export const generateGoogleUrl = ({ placeId, lat, lng, name, address }: { placeId?: string; lat?: string; lng?: string; name?: string; address?: string }) => {
  const BASE_URL = `https://www.google.com/maps/search/?api=1`;
  if (placeId) {
    return `${BASE_URL}&query=Google&query_place_id=${placeId}`;
  } else if (name && address) {
    return `${BASE_URL}&query=${encodeURIComponent(`${name} ${address}`)}`;
  } else if (lat && lng) {
    return `${BASE_URL}&query=${lat},${lng}`;
  }
  return ``;
};
