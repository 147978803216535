import React, { useContext, useMemo } from 'react';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { AnalyticsContext } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

const ignoreTags = new Set(['a', 'button']);

const PhoneNumberLink: React.FC<{ href: string; children: string; source?: string }> = ({ href, children, source }) => {
  const analytics = useContext(AnalyticsContext);
  const onClickPhoneNumber = useEventCallback(() => {
    analytics.track({
      action: 'LinkInteracted',
      category: 'linkifyPhoneNumbers',
      extraInfo: { actionType: 'click', url: children, source, type: 'phone' }
    });
  });
  return (
    <a href={href} rel="nofollow" onClick={onClickPhoneNumber}>
      {children}
    </a>
  );
};

function stringToElements(str: string, source?: string): React.ReactNode[] {
  const phoneNumbers = findPhoneNumbersInText(str, { defaultCountry: 'US' });
  const nodes: React.ReactNode[] = [];

  if (phoneNumbers.length === 0) {
    // no phone numbers found in the text, just return the string
    return [str];
  }

  let strIndex = 0;
  phoneNumbers.forEach(({ startsAt, endsAt, number }, index) => {
    if (strIndex < startsAt) {
      nodes.push(str.slice(strIndex, startsAt));
    }

    nodes.push(
      <PhoneNumberLink key={`clickablephone-${index}`} href={number.getURI()} source={source}>
        {str.slice(startsAt, endsAt)}
      </PhoneNumberLink>
    );
    strIndex = endsAt;
  });

  // push final portion of string if it wasn't a phone number
  if (strIndex < str.length) {
    nodes.push(str.slice(strIndex));
  }

  return nodes;
}

function convertPhoneNumbersToLinks(element: React.ReactElement, source?: string): React.ReactNode {
  if (React.Children.count(element.props.children) === 0) {
    return element;
  }

  const newChildren: React.ReactNode[] = [];

  React.Children.forEach(element.props.children, (child: React.ReactNode) => {
    if (typeof child === 'string') {
      newChildren.push(...stringToElements(child, source));
    } else if (React.isValidElement(child) && !ignoreTags.has(child.type.toString().toLowerCase())) {
      newChildren.push(convertPhoneNumbersToLinks(child));
    } else {
      // Unknown element type, just push
      newChildren.push(child);
    }
  });

  return React.cloneElement(element, { ...element.props }, ...newChildren);
}

export const LinkifyPhoneNumbers: React.FC<{ source?: string }> = ({ source, children }) => {
  const newChildren = useMemo(() => {
    return <>{convertPhoneNumbersToLinks(<>{children}</>, source)}</>;
  }, [children, source]);

  return newChildren;
};
