import { TextV2, ButtonV2, styled, css } from '@withjoy/joykit';

export const StyledExternalLinkLabel = styled(TextV2)`
  font-weight: bold;
  line-height: 1.5rem;
  leter-spacing: -0.01rem;
  margin-top: 1rem;
`;

export const baseSafeLinkDialogButton = css`
  font-weight: 600;
  font-size: 1rem;
`;
export const StyledSafeLinkCancelButton = styled(ButtonV2)`
  ${baseSafeLinkDialogButton}
  color: ${props => props.theme.colors.mono14};
  margin-right: auto;
  width: 7rem;
`;

export const StyledSafeLinkProceedButton = styled(ButtonV2)`
  ${baseSafeLinkDialogButton}
  background-color: ${props => props.theme.colors.mono14};
  width: 7rem;
`;

export const StyledSafeLinkCloseButton = styled(ButtonV2)`
  ${baseSafeLinkDialogButton}
  background-color: ${props => props.theme.colors.mono14};
  width: 7.75rem;
`;
