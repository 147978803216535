import { useEffect } from 'react';

import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { withWindow } from '@shared/utils/withWindow';

import { COMPONENT_LOADING_TIMEOUT_MS } from '../constants';
import { ComponentHealthState, StateType } from '../types';

export function useLoadTimeoutEffect(componentHealthState: ComponentHealthState, onTimeout: () => void) {
  // Stable callback function ref to prevent the below useEffect from rerunning
  // whenever `onTimeout` param changes.
  const stableOnTimeout = useEventCallback(onTimeout);

  useEffect(() => {
    let timeoutHandle: number;

    if (componentHealthState.type === StateType.Loading) {
      withWindow(window => {
        timeoutHandle = window.setTimeout(() => {
          stableOnTimeout();
        }, COMPONENT_LOADING_TIMEOUT_MS);
      });
    }

    return () => {
      withWindow(window => {
        window.clearTimeout(timeoutHandle);
      });
    };
  }, [componentHealthState.type, stableOnTimeout]);
}
