import { getKeys } from '@shared/utils/object';
import { createContext, useContext } from 'react';
import { FormControlContextType, FormControlBaseProps } from '.';

export const FormControlContext = createContext<FormControlContextType | undefined>(undefined);

export const useFormControlContext = () => {
  return useContext(FormControlContext);
};

export const useFormControl = <P extends FormControlBaseProps>(props: P): FormControlBaseProps => {
  const context = useFormControlContext();
  if (!context) {
    return props;
  }

  return getKeys(context).reduce((acc, prop) => {
    // Give precedence to children component props
    acc[prop] = typeof props[prop] !== 'undefined' ? props[prop] : context[prop];
    return acc;
  }, {} as UnsealedReadonly<FormControlBaseProps>);
};
