import { useEffect, useRef } from 'react';

/**
 * React effect hook that only invokes after mount -- think `componentDidUpdate` after `componentDidMount`
 */
export const useUpdateEffect: typeof useEffect = (cb, deps) => {
  const mounted = useRef(false);
  useEffect(() => {
    // Only invoke cb after mount
    if (mounted.current) {
      return cb();
    }
    mounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
