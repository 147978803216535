import React, { useEffect, useMemo, useState } from 'react';
import type { BoxProps } from '@withjoy/joykit';
import type { SkeletonProps, SkeletonGroupProps, SkeletonTextProps, SkeletonThumbnailProps } from './Skeleton.types';
import { StyledSkeletonContentRoot, StyledSkeletonPlaceholderRoot, StyledSkeletonThumbnailRoot } from './Skeleton.styles';
import { getOverrides, mergeOverrides } from '@shared/utils/overrides';
import { range } from 'lodash-es';
import { usePreviousValue } from '@shared/utils/hooks/usePreviousValue';

const DEFAULT_TEXT_ROW_WIDTHS = [97, 100, 94, 90, 98, 95, 98, 40] as const;
export const renderTimes = (times: number, render: (idx: number) => React.ReactNode) => Array.from(Array(times)).map((x, idx) => render(idx));

/**
 * @TODO
 * - Add support for color transitions
 */
export const Skeleton = (props: SkeletonProps) => {
  const { children, disableShimmerAnimation = false, isReady: isReadyProp = false, overrides = {}, placeholder, backgroundColor, ...restProps } = props;
  const [isReady, setIsReady] = useState(isReadyProp);
  const prevIsReady = usePreviousValue(isReady, isReadyProp);

  const [Root, rootProps] = getOverrides(overrides.Root, StyledSkeletonContentRoot);
  const [PlaceholderRoot, placeholderProps] = getOverrides(overrides.PlaceholderRoot, StyledSkeletonPlaceholderRoot);
  useEffect(() => {
    if (isReady && !isReadyProp) {
      setIsReady(false);
    } else if (isReadyProp) {
      if (!isReady) {
        setIsReady(true);
      }
    }
  }, [isReadyProp, isReady]);

  if (isReady) {
    return (
      <Root $shouldAnimateIn={isReady !== prevIsReady} {...rootProps} {...restProps}>
        {children}
      </Root>
    );
  }

  return (
    <PlaceholderRoot $disableShimmerAnimation={disableShimmerAnimation} borderRadius={1} backgroundColor={Skeleton.defaultBackgroundColor} {...placeholderProps} {...restProps}>
      {placeholder}
    </PlaceholderRoot>
  );
};

Skeleton.defaultBackgroundColor = '#EEEEEE' as const;

export const SkeletonGroup = (props: SkeletonGroupProps) => {
  const { overrides = {}, ...restProps } = props;
  return (
    <Skeleton
      disableShimmerAnimation={true}
      overrides={mergeOverrides(
        {
          PlaceholderRoot: { props: { backgroundColor: 'transparent' } }
        },
        overrides
      )}
      {...restProps}
    />
  );
};

/**
 * @TODO
 *   - Add support for responsive `rows` prop.
 */
export const SkeletonText = (props: SkeletonTextProps) => {
  const {
    children,
    className,
    enableVaryingRowWidth = false,
    isReady,
    overrides = {},
    rows: rowsProp = 3,
    rowWidths = DEFAULT_TEXT_ROW_WIDTHS,
    skeletonHeight = '0.5rem',
    spacing = '0.75rem',
    ...restProps
  } = props;

  const rowsArray = useMemo(() => range(0, rowsProp), [rowsProp]);
  const getRowWidth = (row: number) => {
    if (rowsProp > 1) {
      if (enableVaryingRowWidth) {
        return `${rowWidths[(row + rowWidths.length) % rowWidths.length]}%`;
      }
      return row === rowsProp - 1 ? '80%' : '100%';
    }
    return '100%';
  };
  return (
    <SkeletonGroup
      className={className}
      isReady={isReady}
      {...restProps}
      overrides={overrides}
      placeholder={
        <>
          {rowsArray.map(row => {
            const styleProps: BoxProps = {
              marginTop: row === 0 ? '0px' : spacing,
              width: restProps.width || getRowWidth(row),
              height: skeletonHeight
            };

            return (
              <Skeleton
                key={rowsArray.length.toString() + row}
                overrides={{
                  PlaceholderRoot: {
                    props: styleProps
                  }
                }}
              />
            );
          })}
        </>
      }
    >
      {children}
    </SkeletonGroup>
  );
};

export const SkeletonThumbnail = (props: SkeletonThumbnailProps) => {
  const { skeletonSize = '3.75rem', as, className, variant = 'square', overrides = {}, ...restProps } = props;
  return (
    <Skeleton
      overrides={mergeOverrides({ PlaceholderRoot: { props: { size: skeletonSize, $variant: variant }, component: StyledSkeletonThumbnailRoot } }, overrides)}
      {...restProps}
    />
  );
};

if (__DEV__) {
  Skeleton.displayName = 'Skeleton';
  SkeletonGroup.displayName = 'SkeletonGroup';
  SkeletonText.displayName = 'SkeletonText';
  SkeletonThumbnail.displayName = 'SkeletonThumbnail';
}
