/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Brand Apple',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.997 15.89c-.492 1.09-.73 1.576-1.361 2.54-.883 1.344-2.127 3.017-3.672 3.032-1.371.013-1.723-.892-3.583-.883-1.862.01-2.25.9-3.62.886-1.542-.015-2.724-1.527-3.607-2.87-2.47-3.76-2.728-8.172-1.205-10.518 1.082-1.666 2.79-2.64 4.396-2.64 1.635 0 2.665.896 4.016.896 1.312 0 2.11-.898 4-.898 1.43 0 2.944.778 4.023 2.122-3.534 1.936-2.96 6.983.613 8.333zm-6.07-11.957c.689-.883 1.21-2.125 1.02-3.399-1.122.078-2.435.791-3.201 1.721-.695.846-1.27 2.098-1.045 3.315 1.225.036 2.49-.695 3.227-1.637z"
            fill="#333"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
