import { useState, useEffect } from 'react';

export * from './useValidateProps';
export * from './useId';
export * from './useDisclosure';
export * from './useShouldKeepOverlayOpen';
export * from './useForceUpdate/useForceUpdate';

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};
