import { useCallback, useMemo, useState } from 'react';
import { iframeUrlFromVideoUrl } from './utils';
import { VideoMimeType } from './video.types';

interface Args
  extends Readonly<{
    url: string;
    mimeType: string;
  }> {}

export const useWelcomeVideoController = (args: Args) => {
  const { url, mimeType } = args;
  const [open, setOpen] = useState<boolean>(false);
  const viewerUrl = useMemo(() => iframeUrlFromVideoUrl(url, mimeType as VideoMimeType), [url, mimeType]);

  const handleOpenClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCloseClick = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return {
    open,
    handleOpenClick,
    handleCloseClick,
    viewerUrl
  };
};
