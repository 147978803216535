import { Box } from '@withjoy/joykit';
import React from 'react';
import { SkeletonText, SkeletonThumbnail } from '@shared/components/Skeleton';
import { pxToRem } from '@withjoy/joykit/theme';

export const AccommodationsTileSkeleton: React.FC = () => {
  return (
    <>
      <SkeletonThumbnail
        height={250}
        overrides={{
          PlaceholderRoot: {
            props: {
              width: '100%',
              paddingTop: '75%',
              backgroundColor: 'white',
              borderRadius: '12px !important'
            }
          }
        }}
      />
      <Box width="100%" height={pxToRem(221)} paddingTop={4}>
        <SkeletonText rows={1} width={pxToRem(136)} skeletonHeight={pxToRem(26)} paddingBottom={3} />
        <SkeletonText rows={1} width={pxToRem(250)} skeletonHeight={pxToRem(26)} paddingBottom={3} />
        <SkeletonText rows={1} width={pxToRem(206)} skeletonHeight={pxToRem(42)} paddingBottom={6} />
        <SkeletonText width={'100%'} rows={1} skeletonHeight={pxToRem(40)} />
      </Box>
    </>
  );
};
