import { ReactText } from 'react';
import { createShouldForwardProp as createShouldForwardPropStyledSystem, props as styledSystemProps } from '@styled-system/should-forward-prop';

/**
 * When we apply non-standard attributes to HTML DOM elements, a warning message is displayed.
 *
 * This factory method takes in a list of blocked prop names and returns a utility function
 * that will accept a props map and return a subset that omits the blocked keys.
 *
 * **Note**: This method will handle removing `styled-system` props. So you won't have to pass in `margin`, `padding`, etc.
 *
 * @param blocklist List of props to be removed
 *
 * @example
 *
 * type MyComponentProps = { non: any; standard: any; dom: any; attributes: any; };
 *
 * const shouldForwardProp = createShouldForwardProp<MyComponentProps>(['non', 'standard', 'dom', 'attributes']);
 *
 * const MyComponent = styled.div.withConfig<MyComponentProps>({ shouldForwardProp })` ... `;
 */
export function createShouldForwardProp<P extends {}>(blocklist: Array<keyof P>) {
  const shouldForwardProp = createShouldForwardPropStyledSystem([...(blocklist as string[]), ...styledSystemProps]);
  return shouldForwardProp as (prop: ReactText | symbol) => boolean;
}
