import { createContext, useContext } from 'react';
import type { ScrollToContextValue } from './ScrollTo.types';

export const ScrollToContext = createContext<ScrollToContextValue | undefined>(undefined);

export const useScrollTo = () => {
  const context = useContext(ScrollToContext);
  if (!context) {
    throw new Error('useScrollToContext should be nested under a <ScrollToContext />');
  }
  return context;
};
