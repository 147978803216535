import React from 'react';
import { Helmet } from 'react-helmet-async';
import { URLS } from '@shared/utils/urls';
import { getEventTypeConfig } from '@config';
import { EventPageFragment, EventType } from '@graphql/generated';
import { generateDescriptionKeywords } from '@shared/utils/seo';
import { Seo } from '@shared/components/Seo';

/**
 * Adds a query param to the image url to optimize the image from Media Service and add a source query param
 */
const addOptimizationQueryParam = (url: Maybe<string>): string | undefined => {
  if (!url) return undefined;
  const hasQueryParam = url.includes('?');
  const separator = hasQueryParam ? '&' : '?';
  return `${url}${separator}rendition=small&source=websitepreview`;
};

interface Props
  extends Readonly<{
    handle: Maybe<string>;
    eventDisplayName: Maybe<string>;
    imageUrl: Maybe<string>;
    hideFromSearchEngines: boolean;
    ownerFirstName: Maybe<string>;
    ownerLastName: Maybe<string>;
    fianceeFirstName: Maybe<string>;
    pageData: EventPageFragment[];
    eventType: EventType;
  }> {}

/**
 * This supplies \<Helmet /> from react-helmet-async
 * Use this component to include necessary font imports
 * as well as update the title and meta information
 */
export const GuestSiteHead: React.FC<Props> = props => {
  const { handle, eventDisplayName, imageUrl, hideFromSearchEngines, ownerFirstName, ownerLastName, fianceeFirstName, pageData, eventType } = props;

  const keywords = generateDescriptionKeywords(pageData);
  const userInfo = { partnerOneFirstName: ownerFirstName || '', partnerOneLastName: ownerLastName || '', partnerTwoFirstName: fianceeFirstName || '' };
  const pageTitle = getEventTypeConfig(eventType).guestSitePageTitle(eventDisplayName || '', userInfo) || '';
  const pageDescription = getEventTypeConfig(eventType).guestSitePageDescription(eventDisplayName || '', userInfo, keywords);
  const pageUrl = `https://withjoy.com/${handle}/`;

  return (
    <>
      <Seo
        title={pageTitle}
        description={pageDescription}
        canonicalURL={pageUrl}
        name="Joy"
        url={pageUrl}
        ogImage={addOptimizationQueryParam(imageUrl)}
        author="@JoytheApp"
        twitterSite="@JoytheApp"
      />
      <Helmet>
        {/* style applicator is being requested already by Html.tsx */}
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&display=swap" rel="stylesheet" />
        <script src={URLS.styleApplicatorLib} />
        {hideFromSearchEngines && <meta name="robots" content="noindex" />}
      </Helmet>
    </>
  );
};
