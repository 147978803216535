type GetInitialsOptions = {
  /** @default none */
  transform?: 'uppercase' | 'lowercase' | 'none';
};

export function getInitialsFromName(name: string, options: GetInitialsOptions = {}): string {
  const { transform = 'none' } = options;
  const initialsFromName = (name || '')
    .split(' ')
    .map(x => x[0])
    .join('');
  return transform === 'uppercase' ? initialsFromName.toLocaleUpperCase() : transform === 'lowercase' ? initialsFromName.toLocaleLowerCase() : initialsFromName;
}
