import React, { FC } from 'react';
import { GuestSiteEvent } from '@apps/guest/packages/layout-engine/layouts/layout.types';
import { useGuestSiteDebugger } from '@apps/guest/components/GuestSiteDebugger/GuestSiteDebugger.controller';

export type Props = {
  event: GuestSiteEvent;
};

export const GuestSiteDebugger: FC<Props> = ({ event }) => {
  useGuestSiteDebugger({ isCharm: true, eventId: event.id, firebaseId: event.firebaseId });

  return <></>;
};
