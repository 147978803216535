import { useEffect, useState } from 'react';

export const useIsStickyElement = (elemetId: string, skip: boolean) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const child = document.getElementById(elemetId);
    // observer to observe whether the elemet is intersecting with the top of the viewport, if yes then need to make it sticky there
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          // If the child intersects with the top of the viewport
          if (entry.intersectionRatio < 1 && entry.boundingClientRect.top <= 0) {
            setIsSticky(true);
          } else {
            setIsSticky(false);
          }
        });
      },
      { root: null, threshold: 1 }
    );

    if (child && !skip) {
      observer.observe(child);
    }

    return () => {
      if (child) {
        observer.unobserve(child);
      }
    };
  }, [elemetId, skip]);

  return { isSticky };
};
