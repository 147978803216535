import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EventPageFragment, EventPageType } from '@graphql/generated';
import { BoxProps } from '@withjoy/joykit';
import { LayoutAlohaProps } from '../../LayoutAloha';
import { pageWidgets, PreparedPages } from './Body.constants';
import Page from '../Page';
import { InlineEditor } from '@shared/components';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { withWindow } from '@shared/utils/withWindow';
import { routePaths, RoutesNames } from '@apps/guest/utils/RouteHelper.utils';
import { isInIframe } from '@shared/utils/isInIframe';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { InlineEditExperimentProps, useInlineEditExperimentListeners } from '@shared/hooks/useInlineEditExperimentListeners';

export interface BodyProps extends Readonly<{ page: Maybe<EventPageFragment>; applicatorProps?: Object } & LayoutAlohaProps & BoxProps> {}

export const Body: React.FC<BodyProps> = ({ event, eventHandle, page, applicatorProps = {}, ...restProps }) => {
  const [inlinePageEditEnabled, setInlinePageEditEnabled] = useState(false);

  const pageRef = useRef<HTMLDivElement>(null);

  const isPreviewing = isInIframe();

  useEffect(() => {
    sendMessageToParentWindow({
      action: 'experimentRequestFromPreview',
      value: { id: page?.type || '', key: 'admindashboardInlinePageEditExperiment', skip: !isPreviewing || page?.type === EventPageType.app },
      source: 'joyPreview'
    });
  }, [isPreviewing, page?.type]);

  const experimentData: InlineEditExperimentProps = useMemo(
    () => [
      {
        experimentKey: 'admindashboardInlinePageEditExperiment',
        setFn: data => {
          if (data.id === page?.type || '') {
            setInlinePageEditEnabled(data.isEnabled);
          }
        }
      }
    ],
    [page?.type]
  );

  useInlineEditExperimentListeners(experimentData);

  const onPageEditClick = useEventCallback(() => {
    const path = page?.type === EventPageType.custom && page.pageSlug === 'accommodations' ? 'accommodations' : page?.type;

    withWindow(window => {
      if (path && routePaths.hasOwnProperty(path)) {
        window.open(`/${eventHandle}/edit/${routePaths[path as RoutesNames].goToPath(page?.pageSlug || '')}`, '_blank');
      }
    });
  });

  const onVisibilityClick = useEventCallback(() => {
    if (page) {
      // send the message to the parent window to open the text editor
      sendMessageToParentWindow({
        action: 'inlineEditingInteraction',
        source: 'joyPreview',
        value: {
          action: 'editPageSettings',
          inlineEditData: { pageId: page.id, pageType: page.type, pageTitle: page.pageTitle, pageVisibility: page.visibility, isPageContainer: page.isPageContainer }
        }
      });
    }
  });

  const { isTidbitsPage, isStoryPage, marginTop, isAccommodations } = useMemo(() => {
    return {
      isTidbitsPage: page?.type === 'tidbits',
      isStoryPage: page?.type === 'story',
      marginTop: '4rem',
      isRegistryPage: page?.type === 'registry',
      isAccommodations: page?.pageSlug === 'accommodations'
    };
  }, [page?.pageSlug, page?.type]);

  if (!page) {
    return null;
  }

  const { type } = page;
  const PageWidget = pageWidgets[type as PreparedPages];

  const pageProps = (!isTidbitsPage && {
    title: page.pageTitle,
    subtitle: !isStoryPage ? page.subTitle : undefined,
    graphicAccent: undefined,
    isAccommodations: isAccommodations,
    marginTop
  }) || {
    title: page.pageTitle,
    marginTop
  };
  return PageWidget ? (
    inlinePageEditEnabled ? (
      <InlineEditor
        actionData={{
          edit: onPageEditClick,
          visibility: {
            function: onVisibilityClick,
            visibility: page.visibility
          }
        }}
        inset={page.pageSlug === 'accommodations' ? { x: -12, y: -16 } : { x: 4, y: -16 }}
        wrapperType="actionOutside"
        componentName="page"
        elementLabel={page.pageTitle}
        pageName={page.type}
        pageSlug={page.pageSlug}
        stickyOnScroll={true}
      >
        <Page {...pageProps} pageSlug={page.pageSlug} ref={pageRef}>
          <PageWidget
            isAccommodations={isAccommodations}
            event={event}
            eventHandle={eventHandle}
            {...applicatorProps}
            pageId={page.id}
            pageSlug={page.pageSlug}
            pageTitle={pageProps.title}
          />
        </Page>
      </InlineEditor>
    ) : (
      <Page {...pageProps} pageSlug={page.pageSlug} ref={pageRef}>
        <PageWidget
          isAccommodations={isAccommodations}
          event={event}
          eventHandle={eventHandle}
          {...applicatorProps}
          pageId={page.id}
          pageSlug={page.pageSlug}
          pageTitle={pageProps.title}
        />
      </Page>
    )
  ) : null;
};
