import { useEffect } from 'react';
import { useDebounceScroll } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/useDebounceScroll';

export const usePageScrollAtTheBottom = (container: HTMLDivElement | null, setIsPageScrollAtTheBottom?: (value: boolean) => void) => {
  const onUserScroll = useDebounceScroll();

  useEffect(() => {
    const unsubscribe = onUserScroll(container, ({ scrollTop }) => {
      if (!container) return;

      const isPageScrollAtTheBottom = () => {
        const scrollBottomThreshold = 300;
        const containerScroll = scrollTop + container.offsetHeight;
        const isScrollAtTheEnd = container.scrollHeight - containerScroll < scrollBottomThreshold;

        setIsPageScrollAtTheBottom?.(isScrollAtTheEnd);
      };

      isPageScrollAtTheBottom();
    });

    return () => unsubscribe?.();
  }, [container, setIsPageScrollAtTheBottom, onUserScroll]);
};
