import { useTranslation } from '@shared/core';
import { EventPageType, EventPageVisibility, EventWebsiteGuestQuery, GetRegistryDataQuery } from '@graphql/generated';

export const useAppPage = () => {
  const { t: appsTranslation } = useTranslation('eventSiteApps');

  const addAppPage = (eventHandle: string | undefined, event: EventWebsiteGuestQuery['eventByName'] | GetRegistryDataQuery['eventByName']) => {
    const newPages = event?.pages ? [...event.pages] : [];

    if (!event?.settings?.hideDownloadAppPage) {
      newPages.push({
        id: 'app',
        disabled: false,
        mobilePhoto: null,
        pageSlug: 'app',
        pageTitle: appsTranslation('title')(),
        photo: null,
        subTitle: appsTranslation('message')({ website: eventHandle }),
        type: EventPageType.app,
        isPageContainer: false,
        visibility: EventPageVisibility.public
      });
    }

    return newPages;
  };

  return addAppPage;
};
