import { TextV2 } from '@withjoy/joykit';
import React from 'react';
import { styles, StyledMenuButton, StyledMenuButtonContent, StyledHamburger } from './MenuButton.styles';
import { useTranslation } from '@shared/core/i18n';
import { isInIframe } from '@shared/utils/isInIframe';

const isPreviewing = isInIframe();

interface Props
  extends Readonly<{
    onClick: () => void;
    isCustomPage?: boolean;
    isBrannanLayout?: boolean;
    hasBanner?: boolean;
  }> {}

export const MenuButton: React.FC<Props> = ({ onClick, isCustomPage, isBrannanLayout, hasBanner }) => {
  const { t2 } = useTranslation('eventMenu');
  const menu = t2('menu');
  return (
    <StyledMenuButton {...styles.button(isCustomPage, hasBanner, isBrannanLayout)} onClick={onClick} typographyVariant="button3" isPreviewing={isPreviewing}>
      <StyledMenuButtonContent __css={styles.content}>
        <StyledHamburger />
        <TextV2 typographyVariant="label4">{menu}</TextV2>
      </StyledMenuButtonContent>
    </StyledMenuButton>
  );
};

export default MenuButton;
