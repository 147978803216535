// TODO: Maybe test this down the line
/* istanbul ignore file */

import { styled } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const GridOverlayWrapper = styled.div<{ active: boolean }>`
  opacity: ${props => (props.active ? 1 : 0)};
  display: flex;
  position: fixed;
  pointer-events: none;
  user-select: none;

  height: 100vh;
  width: 100%;
  transition: ${animationTransition('opacity')};
  z-index: 3000;
`;

export const GridColumn = styled.div`
  /*
    All columns are hidden by defaut, incremently reveal columns by device size
  */
  display: none;
  /* 4 Column grid by default */
  width: ${100 / 4}%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.1);
  z-index: 100;

  /* Default gutter is 16px */
  margin: 0 8px;

  :first-of-type {
    margin-left: 32px;
  }
  ${props => props.theme.mediaQueries.between(0, 480)} {
    :first-of-type {
      margin-right: 32px;
      display: block;
      width: 100%;
    }
  }

  /** Small device and downwards, use 4 column grid */
  @media screen and (min-width: 480px) {
    :nth-child(-n + 4) {
      display: block;
    }
  }

  @media screen and (min-width: 480px) and (max-width: 839px) {
    :nth-child(4) {
      margin-right: 32px;
    }
  }

  @media screen and (min-width: 840px) {
    width: ${100 / 8}%;
    margin: 0 12px;

    /* Reveal all columns */
    :nth-child(-n + 8) {
      display: block;
    }
    :first-of-type {
      margin-left: 24px;
    }
  }

  @media screen and (min-width: 840px) and (max-width: 1279px) {
    :nth-child(8) {
      margin-right: 24px;
    }
  }

  /* Desktop +up has larger column gutter  */
  @media screen and (min-width: 1280px) {
    /* Inner gutters */

    width: ${100 / 12}%;

    /* Reveal all columns */
    display: block;

    /* Outer gutters */
    :first-of-type {
      margin-left: 24px;
    }

    :last-of-type {
      margin-right: 24px;
    }
  }
`;
