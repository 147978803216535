import React from 'react';
import { generateComponentDisplayName } from '../../utils';
import { ResponsiveValue } from '@withjoy/joykit/styled-system';
import { BaseComponentProps } from '../../common/props';

import { FontWeight } from '../../common/textStyles';
import { StyledText } from './styles';
import { TEXT_COMPONENT_VARIANT_MAPPING } from './constants';
import { BoxProps } from '../Box';
import { TypographyVariant } from '@withjoy/joykit/theme';

type TextComponent = 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'p';

/**
 * -----------------------------------
 * DEPRECATED -- Use `<TextV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { TextV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/TextV2/TextV2.stories.tsx`.
 */
export interface TextV1Props extends BaseComponentProps, Omit<BoxProps, 'fontWeight'> {
  /**
   * The rendered HTML element.
   *
   * The font size, letter-spacing, and line height are primarily driven by
   * the chosen HTML tag.
   */
  component?: TextComponent;

  /**
   * Responsive font weight value that will override the current font weight.
   */
  fontWeight?: ResponsiveValue<FontWeight | number>;

  /**
   * Forces the text to display on one line with elllipsis at given max-width
   *
   * Per CSS specification, truncate will only apply to block container elements.
   * In particular, this will not work with inline elements such as `<span />`.
   *
   * @default
   * false
   */
  truncate?: boolean | number | string;

  /**
   * Apply predefined styles.
   *
   * While the component tag is set for semantic correctness and accessibility,
   * we can alter the appearance of text apart from the semantic meaning.
   *
   * @example
   *
   * <Text component="h1" variant="body1"/>
   * // This will render a `<h1 />` component but with the styles of a "body" variant.
   */
  variant?: TypographyVariant;
}

/**
 * -----------------------------------
 * DEPRECATED -- Use `<TextV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { TextV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/TextV2/TextV2.stories.tsx`.
 */
export const TextV1: React.FC<TextV1Props> = React.memo(({ children, color, as, component = 'span' as 'span', variant, ...rest }) => {
  return (
    <StyledText color={color || 'black'} as={as || component} typeVariant={variant || TEXT_COMPONENT_VARIANT_MAPPING[component]} {...rest}>
      {children}
    </StyledText>
  );
});

TextV1.displayName = generateComponentDisplayName('Text');
