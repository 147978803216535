import { createGlobalStyle } from '@withjoy/joykit';

const RichTextGlobalStyle = createGlobalStyle`
  .jodit-editor h1:first-child, 
  .jodit-editor h2:first-child, 
  .jodit-editor h3:first-child, 
  .jodit-editor h4:first-child, 
  .jodit-editor h5:first-child, 
  .jodit-editor h6:first-child,
  .jodit-editor p:first-child {
    margin-top: 0;
  }
`;

export { RichTextGlobalStyle };
