import { useCallback } from 'react';
import { EventPageType, EventRSVPAttendanceStatus, GetEventCTAPageInfoFragment } from '@graphql/generated';
import { useTranslation } from '@shared/core';

type FilteredPages = {
  pagesHidden: GetEventCTAPageInfoFragment[];
  pagesPasswordProtected: GetEventCTAPageInfoFragment[];
};

type ButtonsVisibility = {
  shouldDisplayUnlockButton: Maybe<boolean>;
  shouldDisplayRSVPButton: boolean;
};

const filterPages = (pages?: Maybe<GetEventCTAPageInfoFragment>[]): FilteredPages => {
  const pagesPasswordProtected: GetEventCTAPageInfoFragment[] = [];
  const pagesHidden: GetEventCTAPageInfoFragment[] = [];

  pages?.forEach(page => {
    if (page?.disabled) {
      pagesHidden.push(page);
    } else if (page?.private && page.type !== EventPageType.schedule && page.type !== EventPageType.rsvp) {
      pagesPasswordProtected.push(page);
    }
  });

  return { pagesHidden, pagesPasswordProtected };
};

export const useCtaButtons = () => {
  const { t } = useTranslation('layoutEngine');

  const getButtonsVisibility = useCallback(
    (
      pages: Maybe<GetEventCTAPageInfoFragment>[],
      welcomePage: Maybe<GetEventCTAPageInfoFragment>,
      schedulePage: Maybe<GetEventCTAPageInfoFragment>,
      rsvpIsEnabled: Maybe<boolean>,
      rsvpIsPasswordProtected: Maybe<boolean>,
      scheduleHasInviteOnlyItems: Maybe<boolean>,
      hasUnlocked: Maybe<boolean>
    ): ButtonsVisibility => {
      const filteredPages = filterPages(pages);
      const isUnlocked = hasUnlocked ?? false;
      const isAnyPagePasswordProtected = filteredPages.pagesPasswordProtected.length > 0;
      const hasBlockedPages = isAnyPagePasswordProtected && !isUnlocked;

      // Everything is hidden
      if (filteredPages.pagesHidden.length === pages.length) {
        return { shouldDisplayUnlockButton: false, shouldDisplayRSVPButton: false };
      }

      // Welcome page is hidden but RSVP varies
      if (welcomePage?.disabled) {
        return {
          shouldDisplayUnlockButton: rsvpIsPasswordProtected ? !isUnlocked : hasBlockedPages,
          shouldDisplayRSVPButton: false
        };
      }

      // Welcome page is public and Schedule page is password protected
      if (!schedulePage?.disabled && schedulePage?.private) {
        if (rsvpIsEnabled) {
          return {
            shouldDisplayUnlockButton: !isUnlocked,
            shouldDisplayRSVPButton: rsvpIsPasswordProtected ? isUnlocked : true
          };
        }

        return {
          shouldDisplayUnlockButton: !isUnlocked,
          shouldDisplayRSVPButton: false
        };
      }

      // Welcome page is public and Schedule page is hidden or public
      if (rsvpIsEnabled) {
        return {
          shouldDisplayUnlockButton: rsvpIsPasswordProtected ? !isUnlocked : hasBlockedPages,
          shouldDisplayRSVPButton: rsvpIsPasswordProtected ? isUnlocked : true
        };
      }

      return { shouldDisplayUnlockButton: hasBlockedPages, shouldDisplayRSVPButton: false };
    },
    []
  );

  const getUnlockButtonText = useCallback(
    (hasUnlocked: Maybe<boolean>) => {
      const { findInviteButtonText, unlockButtonText } = t('components', 'eventCta');

      return hasUnlocked ? findInviteButtonText() : unlockButtonText();
    },
    [t]
  );

  const getRSVPButtonText = useCallback(
    (rsvpAttendanceStatus: EventRSVPAttendanceStatus | undefined = EventRSVPAttendanceStatus.notAttending, rsvpLabel: string | undefined = '') => {
      const { rsvpButtonText } = t('components', 'eventCta');

      return rsvpAttendanceStatus === EventRSVPAttendanceStatus.noAttendanceQuestion && rsvpLabel === 'RSVP Here' ? rsvpButtonText() : rsvpLabel;
    },
    [t]
  );

  return { getButtonsVisibility, getUnlockButtonText, getRSVPButtonText };
};
