import { ApolloError } from '@apollo/client';
import { GetFeatureSetQuery, useGetFeatureSetQuery } from '@graphql/generated';
import { useEventNameMatch } from '@shared/components/AuthProvider/AuthProvider.utils';
import { useCallback, useEffect, useState } from 'react';
import { FeatureSet, FeatureSetType } from './featureSet.types';

/**
 * useFeatureSet is a hook that is responsible for retrieving the values of featureSet (currently retrieving visible pages).
 * It exports 3 properties:
 * isInitialized: This property indicates whether the featureSet was loaded correctly.
 * features: Object of type FeatureSet with preloaded featureSets.
 * hasFeatureSetEnable: Method that allows obtaining information on whether a featureSet is enabled or not for that event.
 * hasError: possible ApolloError
 */

export const useFeatureSet = () => {
  const eventNameMatch = useEventNameMatch();
  const [featureSetOpts, setFeatureSetOpts] = useState<FeatureSet>();
  const [hasErrors, setHasErrors] = useState<Maybe<ApolloError>>(null);

  const onCompleted = useCallback(
    (data: GetFeatureSetQuery | undefined) => {
      if (!data) {
        return;
      }

      const eventByName = data?.eventByName;
      const welcomePageDisabled = eventByName?.welcomePage?.disabled;
      const registryPageDisabled = eventByName?.registryPage?.disabled;
      const rsvpPageDisabled = eventByName?.rsvpPage?.disabled;

      const initialValueFeatureset: FeatureSet = {
        [FeatureSetType.Website]: !welcomePageDisabled,
        [FeatureSetType.Registry]: !registryPageDisabled,
        [FeatureSetType.Rsvp]: !rsvpPageDisabled
      };

      setFeatureSetOpts(initialValueFeatureset);
    },
    [setFeatureSetOpts]
  );

  const { loading, refetch, data } = useGetFeatureSetQuery({
    batchMode: 'off',
    ssr: true,
    variables: {
      eventName: eventNameMatch.eventName || ''
    },
    onError(err) {
      setHasErrors(err);
    }
  });

  useEffect(() => {
    onCompleted(data);
  }, [onCompleted, data]);

  const hasFeatureSetEnable = useCallback(
    (feature: FeatureSetType) => {
      return featureSetOpts ? !!featureSetOpts[feature] : false;
    },
    [featureSetOpts]
  );

  return {
    isInitialized: !loading,
    features: featureSetOpts,
    hasFeatureSetEnable,
    hasErrors,
    refetchFeatureSet: refetch
  };
};
