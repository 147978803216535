import { useEffect, useState } from 'react';
import { EventPageFragment } from '@graphql/generated';
import { useScrollToPageWhenUrlChanges } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/useScrollToPageWhenUrlChanges';
import { useKeepPageScrollPosition } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/useKeepPageScrollPosition';
import { PagesRefs } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/LayoutAloha.types';
import { usePageInScreenWhenScrolling } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/usePageInScreenWhenScrolling';
import { usePageScrollAtTheBottom } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/usePageScrollAtTheBottom';

/**
 * UseAlohaScroll is the pages' scroll controller. It's composed of 4 features:
 * 1. Scroll to the right event page when the page loads or when the user navigates to the event page through the menu or URL.
 *    This behavior will be addressed by the useScrollToPageWhenUrlChanges hook
 *
 * 2. Event pages load lazily and asynchronously, Then if the user is watching an event page, an above event page can load content,
 *    and it will move down the scroll (Event page jumps). The current fix is listening for the DOM changes on Event Pages
 *    then moving the scroll the difference between the old height and the new height. It will move the scroll to keep the Event Page in the position
 *    that the user is watching avoiding the jump for the user.
 *    This behavior is handled by the useKeepPageScrollPosition hook
 *
 * 3. When the user starts scrolling, we update the URL to reflect the page on the visible screen. We will update the URL once
 *    the page is 80% visible.
 *    This behavior is managed by the usePageInScreenWhenScrolling hook
 *
 * 4. When the scroll thumb reached the scroll bar bottom, it will update the setIsLastPageVisible state.
 *    Some components like Action Bar need to know the scroll thumb is at the end to show up and trigger another action.
 *    This behavior is covered by usePageScrollAtTheBottom hook
 */
export const useAlohaScroll = (
  pages: EventPageFragment[],
  pageRefList: Record<string, PagesRefs>,
  container: HTMLDivElement | null,
  setIsPageScrollAtTheBottom?: (value: boolean) => void
) => {
  const [pagesRef, setPagesRef] = useState<PagesRefs[]>([]);
  const { currentPage } = useScrollToPageWhenUrlChanges({ pagesRef, container });

  useEffect(() => {
    const mapPages = () => {
      if (pageRefList) {
        const currentPagesArray = Object.keys(pageRefList).map(page => pageRefList[page]);
        setPagesRef(currentPagesArray);
      }
    };

    mapPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  useKeepPageScrollPosition({ pagesRef, container, targetPage: currentPage?.ref });
  usePageInScreenWhenScrolling(pagesRef, container, currentPage?.page);
  usePageScrollAtTheBottom(container, setIsPageScrollAtTheBottom);
};
