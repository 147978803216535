import { config } from '@static/js/env.config';

export const getIsolatedMessageId = (messageIdString: string | undefined) => {
  if (!messageIdString) return undefined;
  const isolatedMessageIdRegex = new RegExp(`(?:\<)(.*?)(?=\>)`);
  const match = isolatedMessageIdRegex.exec(messageIdString);
  if (!match) {
    return undefined;
  } else {
    return match[1];
  }
};

export const getViewEmailLink = (messageIdString: string | undefined) => {
  const messageId = getIsolatedMessageId(messageIdString);
  if (!messageId) return undefined;
  return `${config.serverUri}/email/lookup/messageid/${messageId}`;
};
