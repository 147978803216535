import React, { useMemo, useEffect } from 'react';
import { CellProps, Column, Row, useTable, useFilters } from 'react-table';
import { StyledTableWrapper, styles } from '@apps/serviceCenter/routes/Messages/components/ResultsTable/ResultsTable.styles';
import type { EmailDeliverySearchResultEntry } from '@apps/serviceCenter/ServiceCenter.types';
import { useServiceCenterState } from '@apps/serviceCenter/ServiceCenterState';
import { getViewEmailLink } from '@apps/serviceCenter/routes/Messages/components/ResultsTable/ResultsTable.utils';
import { LinkV2, TextV2, Flex } from '@withjoy/joykit';
import { TempSpinner } from '@shared/components/TempSpinner';
import { capitalizeWord } from '@shared/utils/strings';
import { SelectColumnFilter } from './components/ColumnFilters';
import { statusColors } from '@apps/serviceCenter/constants';

type CellRenderer<Value extends unknown = string> = CellProps<EmailDeliverySearchResultEntry, Maybe<string>> & { row: Row<EmailDeliverySearchResultEntry>; value: Value };

export const ResultsTable = () => {
  const { searchResults, searchResultsLoading, searchResultsError, searchResultsCalled, setFilteredSearchResults } = useServiceCenterState();

  const ViewEmailCell = (props: CellRenderer<EmailDeliverySearchResultEntry['messageId']>) => {
    const { value } = props;
    const viewEmailUrl = !!value ? getViewEmailLink(value) : undefined;
    if (!viewEmailUrl) {
      return null;
    } else {
      return (
        <LinkV2 href={viewEmailUrl} target="_blank" fontSize="14px">
          View Email
        </LinkV2>
      );
    }
  };

  const StatusCell = (props: CellRenderer<EmailDeliverySearchResultEntry['statusHistory'][number]>) => {
    const { value: { status, message, description } = {} } = props;
    if (status) {
      const color = statusColors[status as keyof typeof statusColors];
      const messageOrDescription = message || description;
      return (
        <Flex flexDirection="column">
          <Flex alignItems="center">
            <TextV2 as="span" __css={styles.statusDot} backgroundColor={color} />
            <TextV2 fontSize="14px" color={color}>
              {capitalizeWord(status)}
            </TextV2>
          </Flex>
          {status === 'failed' && messageOrDescription && (
            <TextV2 fontSize="12px" color={color}>
              Reason: {messageOrDescription}
            </TextV2>
          )}
        </Flex>
      );
    } else {
      return null;
    }
  };

  const columns = useMemo<Column<EmailDeliverySearchResultEntry>[]>(
    () => [
      {
        Header: 'Date',
        accessor: 'createdDate',
        minWidth: 200
      },
      {
        Header: 'To Email',
        accessor: 'toEmail'
      },
      {
        Header: 'replyTo Email',
        accessor: 'replyToEmail'
      },
      {
        Header: 'From Email',
        accessor: 'fromEmail'
      },
      {
        Header: 'Subject',
        accessor: 'subject'
      },
      {
        Header: 'Last Status',
        accessor: row =>
          row?.statusHistory.find(({ status }) => status === row?.status) || {
            status: row?.status,
            message: null,
            description: null
          },
        Cell: (args: CellRenderer<EmailDeliverySearchResultEntry['statusHistory'][number]>) => <StatusCell {...args} />,
        Filter: SelectColumnFilter,
        filter: (rows, columnIds, filterValue) => rows.filter(({ values }) => values[columnIds[0]]?.status === filterValue)
      },
      {
        Header: 'Email Type',
        accessor: 'emailType',
        Filter: SelectColumnFilter,
        filter: 'equals'
      },
      {
        Header: 'Event Id',
        accessor: 'eventId'
      },
      {
        Header: 'View Email',
        accessor: 'messageId',
        Cell: args => <ViewEmailCell {...args} />
      }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable<EmailDeliverySearchResultEntry>(
    {
      columns,
      data: searchResults,
      defaultColumn: { Filter: '' }
    },
    useFilters
  );

  useEffect(() => {
    setFilteredSearchResults(rows.map(row => row.original));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  if (searchResultsLoading) {
    return <TempSpinner />;
  }
  if (searchResultsError) {
    return (
      <div>
        <TextV2 typographyVariant="body3" color="red">
          Error
        </TextV2>
        <TextV2>{JSON.stringify(searchResultsError)}</TextV2>
      </div>
    );
  }
  if (searchResults.length === 0) {
    if (searchResultsCalled) {
      return (
        <Flex __css={styles.noResultsWrapper}>
          <TextV2 typographyVariant="body3">No results found</TextV2>
        </Flex>
      );
    }
    return null;
  } else {
    return (
      <StyledTableWrapper>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </StyledTableWrapper>
    );
  }
};
