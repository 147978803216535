import React from 'react';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { useServiceCenterController } from '@apps/serviceCenter/ServiceCenter.controller';
import { Box, Flex, TextV2, IconV2 } from '@withjoy/joykit';
import { useAccountRouteHelper } from '@apps/account/useAccountHelper';
import { useRouterHelper } from '@shared/core';
import { routePaths } from '@apps/serviceCenter/route.paths';
import { Link as RouterLink } from '@react-router';
import { ReactComponent as ChatIcon } from '@assets/icons/iconChat.svg';
import { styles } from '@apps/serviceCenter/ServiceCenter.styles';
import { OrderReceipt, WarningTriangle } from '@withjoy/joykit/icons';
import { useFeatureValue } from '@shared/core/featureFlags';
import { RestoreEvent } from './forms/RestoreEvent';
import { SearchForEventWithResult } from './forms/SearchForEvent';

export const ServiceCenter = () => {
  const { loading: isEnableNewPrintExperienceLoading, value: enableNewPrintExperienceValue } = useFeatureValue('enableNewPrintExperience');
  const { isLoading, isSuperAdmin, currentUserName } = useServiceCenterController();
  const accountHelper = useAccountRouteHelper();
  const { buildPath } = useRouterHelper();

  const isMorning = new Date().getHours() < 12;

  if (isLoading || isEnableNewPrintExperienceLoading) {
    return <JoyLogoLoader loaderKey="service-center" />;
  }

  if (isSuperAdmin) {
    return (
      <Box paddingX={'2.5rem'}>
        <Box maxWidth={'1440px'} marginX={'auto'} marginTop={10}>
          <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'}>
            <TextV2 typographyVariant="hed5" gridColumn={'1 / span 3'}>
              {isMorning ? 'Good morning' : 'Good afternoon'}, {currentUserName}
            </TextV2>
            <Flex alignItems="center" marginTop={6} columnGap={8} rowGap={8} flexDirection={['column', null, 'row']}>
              <RouterLink to={buildPath(routePaths.messages.path)}>
                <Box __css={styles.actionContainer}>
                  <Flex flexDirection="column" alignItems="center">
                    <ChatIcon size={'lg'} />
                    <TextV2 typographyVariant="hed2">Messages</TextV2>
                  </Flex>
                </Box>
              </RouterLink>
              <RouterLink to={buildPath(routePaths.eventSafetyRating.path)}>
                <Box __css={styles.actionContainer}>
                  <Flex flexDirection="column" alignItems="center">
                    <WarningTriangle size={'lg'} />
                    <TextV2 textAlign="center" typographyVariant="hed2">
                      Event Safety Rating
                    </TextV2>
                  </Flex>
                </Box>
              </RouterLink>
              {!!enableNewPrintExperienceValue && (
                <RouterLink to={buildPath(routePaths.print.path)}>
                  <Box __css={styles.actionContainer}>
                    <Flex flexDirection="column" alignItems="center">
                      <IconV2 size="lg">
                        <OrderReceipt />
                      </IconV2>
                      <TextV2 textAlign="center" typographyVariant="hed2">
                        Print Order Management
                      </TextV2>
                    </Flex>
                  </Box>
                </RouterLink>
              )}
            </Flex>
          </Box>
          <Box marginTop={10}>
            <TextV2 typographyVariant="hed5" marginBottom={'2rem'}>
              Search for an event
            </TextV2>
            <SearchForEventWithResult />
          </Box>
          <Box marginTop={10} maxWidth={'37.5rem'}>
            <TextV2 typographyVariant="hed5" marginBottom={'2rem'}>
              Restore a deleted event
            </TextV2>
            <RestoreEvent />
          </Box>
        </Box>
      </Box>
    );
  } else {
    accountHelper.signInAndReturnToCurrentPath(['google']);
    return null;
  }
};
