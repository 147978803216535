import { Box, styled, StyleSystemProps } from '@withjoy/joykit';
import { getBrannanMobileMediaQuery } from '../../LayoutBrannan.theme';

export const StyledRoot = styled(Box)`
  width: 100%;
  ${({ theme }) => {
    return {
      [getBrannanMobileMediaQuery(theme)]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    };
  }}
`;

export const StyledTitle = styled(Box)``;

const titleStyles: StyleSystemProps = {
  maxWidth: ['90%', null, null, 720],
  marginX: 'auto',
  marginY: [70, 70, 'unset'],
  textAlign: 'center',
  position: 'relative'
};

export const styles = {
  title: titleStyles
};
