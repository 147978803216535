type PositionProps = { y: 'top' | 'middle' | 'bottom'; x: 'left' | 'middle' | 'right' };

/**
 * Only works with a complete short padding 'top right bottom left'
 *
 * If x == 'middle', we use the same value in right and left padding (We use the Right Value);
 * If y === 'middle', we use the same value in top and bottom (We use the Top Value);
 *
 * If you need to use in another file, please move this funcion to /shared/util/style
 *
 * @param padding 'top right bottom left' format
 * @param initialPosition  => { x, y }
 * @param props => { x ,y }
 * @returns
 */

export const reversePadding: (padding: string, initialPosition: PositionProps, props: PositionProps) => string = (padding, initialPosition, props) => {
  enum PaddingPosition {
    TOP,
    RIGHT,
    BOTTOM,
    LEFT
  }

  const paddingToArr = padding.split(' ');
  if (paddingToArr.length !== 4) return padding;

  if (props.x === 'middle') {
    paddingToArr[PaddingPosition.LEFT] = paddingToArr[PaddingPosition.RIGHT];
  } else if (props.x !== initialPosition.x) {
    const auxP = paddingToArr[PaddingPosition.LEFT];
    paddingToArr[PaddingPosition.LEFT] = paddingToArr[PaddingPosition.RIGHT];
    paddingToArr[PaddingPosition.RIGHT] = auxP;
  }

  if (props.y === 'middle') {
    paddingToArr[PaddingPosition.BOTTOM] = paddingToArr[PaddingPosition.TOP];
  } else if (props.y !== initialPosition.y) {
    const auxP = paddingToArr[PaddingPosition.BOTTOM];
    paddingToArr[PaddingPosition.BOTTOM] = paddingToArr[PaddingPosition.TOP];
    paddingToArr[PaddingPosition.TOP] = auxP;
  }

  return paddingToArr.join(' ');
};
