import { useHistory } from '@shared/core';
import { useEffect, useState } from 'react';
import { PagesRefs } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/LayoutAloha.types';
import { usePageRefFromUrl } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/usePageRefFromUrl';
import { usePageInHistory } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/usePageInHistory';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';

type ScrollToPageWhenUrlChangesProps = {
  pagesRef: PagesRefs[];
  container: HTMLDivElement | null;
};

export const useScrollToPageWhenUrlChanges = ({ pagesRef, container }: ScrollToPageWhenUrlChangesProps) => {
  const history = useHistory();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasNormalizedUrl, setHasNormalizedUrl] = useState(false);
  const [currentPage, setCurrentPage] = useState<PagesRefs | null>(null);
  const getPageRefFromUrl = usePageRefFromUrl();
  const replacePageInBrowserHistory = usePageInHistory();
  const { hasBookingLink } = useGuestSiteState();

  useEffect(() => {
    if (!hasNormalizedUrl && pagesRef?.length) {
      const pageRef = getPageRefFromUrl(pagesRef);

      // if the current slug is not valid, let's replace the url by the firsts available event page url
      if (!pageRef) {
        if (history.location.pathname.includes('accommodations') && hasBookingLink) {
          // intercept in the case of aloha to allow guest site to render bookinglink redirect component
          return;
        }
        replacePageInBrowserHistory(pagesRef[0].page);
      }

      setHasNormalizedUrl(true);
    }
  }, [pagesRef, hasNormalizedUrl, getPageRefFromUrl, replacePageInBrowserHistory, history.location.pathname, hasBookingLink]);

  useEffect(() => {
    if (pagesRef?.length && container) {
      const scrollToPage = (pageRef: PagesRefs, isSmooth: boolean) => {
        const scrollTop = container.scrollTop;
        const isFirstPage = pageRef.page.id === pagesRef[0].page.id;
        const pageRelativePositionToScreen = pageRef?.ref?.getBoundingClientRect?.()?.top || 0;
        const pagePosition = pageRelativePositionToScreen + scrollTop;
        const scrollPositionY = isFirstPage ? 0 : pagePosition;

        container.scrollTo({ left: 0, top: scrollPositionY, behavior: isSmooth ? 'smooth' : 'auto' });
      };

      const goToPage = (pageRef: PagesRefs, hasScrolled: boolean) => {
        if (pageRef?.ref) {
          scrollToPage(pageRef, hasScrolled);
          setCurrentPage(pageRef);
          setHasScrolled(true);
        }
      };

      // Go to the Page when Page loads the first time or when url change by pushing
      if (!hasScrolled || history.action === 'PUSH') {
        const pageRef = getPageRefFromUrl(pagesRef);

        if (pageRef) {
          goToPage(pageRef, hasScrolled);
        }
      }
    }
  }, [container, pagesRef, history.action, getPageRefFromUrl, hasScrolled]);

  return { hasScrolled, currentPage };
};
