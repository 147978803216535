export const KEY_ENTER = 13;
export const KEY_SPACE = 32;
export const KEY_ESCAPE = 27;

/**
 * Determines if a key code is `enter` or `space`.
 *
 * @param keyCode number
 */
export const isKeyboardClick = (...keys: number[]) => (keyCode: number): boolean => {
  return keys.some(targetKeyCode => targetKeyCode === keyCode);
};

export const isKeyboardConfirmTrigger = isKeyboardClick(KEY_ENTER, KEY_SPACE);
export const isKeyboardEscapeClick = isKeyboardClick(KEY_ESCAPE);
