import { css } from '@withjoy/joykit';

export const buttonSharedStates = css`
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.125rem ${({ theme }) => theme.colors.primary7};
  }
`;

export const linkSharedStates = css`
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.primary7};
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary6};
  }

  &:active {
    color: ${({ theme }) => theme.colors.primary8};
  }

  &:focus-visible {
    color: ${({ theme }) => theme.colors.primary7};
    outline: none;
    box-shadow: 0 0 0 0.125rem ${({ theme }) => theme.colors.primary7};
  }
`;
