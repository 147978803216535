import { debounce } from 'lodash-es';
import { useCallback } from 'react';

type ScrollCallBack = {
  scrollTop: number;
};

export const useDebounceScroll = () => {
  const onDebounceScroll = useCallback((container: HTMLDivElement | null, callback: ({ scrollTop }: ScrollCallBack) => void): (() => void) | undefined => {
    if (!container) return;

    const debounceWaitTime = 250;
    const handleScroll = debounce(() => callback({ scrollTop: container.scrollTop }), debounceWaitTime, { maxWait: 700 });
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return onDebounceScroll;
};
