import { useCurrencyContext } from '@shared/utils/currency';
import { useCallback } from 'react';

export const useMinorUnitsToPriceString = () => {
  const currency = useCurrencyContext();
  return useCallback(
    (price: number, { canShowFreeLabel, freeLabel = 'FREE' }: { canShowFreeLabel: boolean; freeLabel?: string }) => {
      const priceInMajorUnits = price / 100;

      if (canShowFreeLabel && priceInMajorUnits === 0) {
        return freeLabel;
      }

      return currency.formatCurrency(priceInMajorUnits, {
        form: 'auto'
      });
    },
    [currency]
  );
};
