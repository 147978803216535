import { ApolloError } from '@apollo/client';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const ScheduleListTelemetry = createTelemetryObject({
  actions: {
    trackError: (mutation: string, error: ApolloError | Error | unknown, extra = {}) => ({
      category: 'wedding',
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),

    scheduleAddressClicked: (scheduleTitle: string) => ({
      action: 'ScheduleAddressClicked',
      actionType: 'click',
      category: 'wedding',
      label: scheduleTitle
    }),

    scheduleAddToCalendarClicked: (scheduleTitle: Maybe<string>, provider: Maybe<string>) => ({
      action: 'ScheduleAddToCalendarClicked',
      actionType: 'click',
      category: 'wedding',
      label: scheduleTitle || '',
      extraInfo: {
        provider: provider || ''
      }
    }),
    scheduleVirtualEventClicked: (scheduleTitle: Maybe<string>) => ({
      action: 'ScheduleVirtualEventClicked',
      actionType: 'click',
      category: 'wedding',
      label: scheduleTitle || ''
    })
  }
});

const { TelemetryProvider, useTelemetry: useScheduleListTelemetry } = createTelemetry(ScheduleListTelemetry);
export { TelemetryProvider, useScheduleListTelemetry };
