/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Hotel Bed',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M15.258 6.158a.51.51 0 0 0-.508-.508h-2.173a.51.51 0 0 0-.508.508v1.398h-.086A2 2 0 0 0 9.985 5.65H7.098a1.99 1.99 0 0 0-1.081.317 1.73 1.73 0 0 0-2.081-.661V4.072a.51.51 0 0 0-.508-.508H1.25a.51.51 0 0 0-.508.508v7.856a.51.51 0 0 0 .508.508h2.178a.51.51 0 0 0 .508-.508v-.649h8.132v.649a.51.51 0 0 0 .508.508h2.173a.51.51 0 0 0 .508-.508V6.158zM2.836 8.656V4.664h-.994v6.672h.994v-1.157h10.332v1.157h.989V6.75h-.989v1.906H2.836zM7.098 6.75h2.887a.9.9 0 0 1 .895.806H6.203c.046-.443.452-.806.895-.806zm-2.529.802c-.349 0-.633-.283-.633-.633s.283-.633.633-.633.633.283.633.633c0 .344-.282.633-.633.633z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M22.321 9.658a1 1 0 0 0-1-1h-2.148a1 1 0 0 0-1 1v.722a2.81 2.81 0 0 0-2.091-.93h-5.78c-.636 0-1.222.21-1.693.566-.365-.642-1.056-1.075-1.847-1.075-.333 0-.648.077-.928.213V6.692a1 1 0 0 0-1-1H2.679a1 1 0 0 0-1 1v10.616a1 1 0 0 0 1 1h2.156a1 1 0 0 0 1-1v-.645h12.339v.645a1 1 0 0 0 1 1h2.148a1 1 0 0 0 1-1v-7.65zM4.634 13.2V6.892H2.879v10.216h1.756v-1.645h14.739v1.645h1.748v-7.25h-1.748V13.2H4.634zm5.669-2.55h5.78c.802 0 1.467.584 1.595 1.35H8.708c.126-.757.791-1.35 1.595-1.35zm-3.54 1.337c-.51 0-.923-.413-.923-.923s.413-.924.923-.924.923.414.923.924a.95.95 0 0 1-.923.923z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M24.87 22.198v1.226a.96.96 0 0 0 .961.96h2.959a.96.96 0 0 0 .96-.96V12.519a.96.96 0 0 0-.96-.96H25.83a.96.96 0 0 0-.961.96v1.837c-.87-.993-2.148-1.619-3.571-1.619h-6.822a4.73 4.73 0 0 0-3.115 1.164 2.79 2.79 0 0 0-2.637-1.884c-.593 0-1.143.185-1.595.502V8.576a.96.96 0 0 0-.96-.96H3.211a.96.96 0 0 0-.96.96v14.848a.96.96 0 0 0 .96.96h2.957a.96.96 0 0 0 .96-.96v-1.226H24.87zM5.929 17.604V8.815H3.45v14.369h2.478v-2.186H26.07v2.186h2.48V12.758h-2.48v4.846H5.929zm5.167-1.2h13.583a3.55 3.55 0 0 0-3.38-2.467h-6.822a3.55 3.55 0 0 0-3.38 2.467zm-2.372-.014c.876 0 1.587-.71 1.587-1.587s-.71-1.587-1.587-1.587-1.587.71-1.587 1.587.71 1.587 1.587 1.587z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M37.663 21.916v-3.21a1.46 1.46 0 0 1 1.461-1.46h4.278a1.46 1.46 0 0 1 1.46 1.46v16.583a1.46 1.46 0 0 1-1.46 1.46h-4.278a1.46 1.46 0 0 1-1.461-1.46v-1.577H10.338v1.577a1.46 1.46 0 0 1-1.46 1.461H4.599a1.46 1.46 0 0 1-1.461-1.461V12.711a1.46 1.46 0 0 1 1.461-1.46h4.279a1.46 1.46 0 0 1 1.46 1.46v6.319c.691-.636 1.613-1.025 2.627-1.025a3.88 3.88 0 0 1 3.832 3.283c.982-.88 2.279-1.415 3.701-1.415h12.864c1.735 0 3.284.797 4.301 2.044zM9.088 25.762V12.711a.21.21 0 0 0-.211-.21H4.599a.21.21 0 0 0-.211.21V35.29a.21.21 0 0 0 .211.211h4.279a.21.21 0 0 0 .211-.211v-2.827h29.825v2.827a.21.21 0 0 0 .211.21h4.278a.21.21 0 0 0 .21-.21V18.707a.21.21 0 0 0-.21-.21h-4.278a.21.21 0 0 0-.211.21v7.056H9.088zm7.208-1.25h21.266a4.3 4.3 0 0 0-4.201-3.39H20.498a4.3 4.3 0 0 0-4.201 3.39zm-3.332-.004a2.63 2.63 0 0 1-2.626-2.58c0-1.448 1.157-2.674 2.627-2.674a2.63 2.63 0 0 1 2.627 2.627 2.63 2.63 0 0 1-2.627 2.627z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M14.427 17.061v8.376a5.09 5.09 0 0 1 3.497-1.386c2.533 0 4.636 1.845 5.037 4.265 1.233-1.015 2.812-1.625 4.533-1.625h16.547a7.12 7.12 0 0 1 5.533 2.63v-4.547a1.46 1.46 0 0 1 1.461-1.46h6.338a1.46 1.46 0 0 1 1.46 1.46v22.166a1.46 1.46 0 0 1-1.46 1.461h-6.338a1.46 1.46 0 0 1-1.461-1.461v-2.447H14.427v2.447a1.46 1.46 0 0 1-1.46 1.46h-6.34a1.46 1.46 0 0 1-1.461-1.46V17.061a1.46 1.46 0 0 1 1.46-1.46h6.34a1.46 1.46 0 0 1 1.46 1.46zm-1.6 17.198V17.2H6.766v29.6h6.061v-3.907h38.347V46.8h6.059V24.913h-6.059v9.346H12.827zm36.625-1.6c-.535-2.497-2.755-4.369-5.411-4.369H27.495c-2.657 0-4.876 1.872-5.411 4.369h27.369zm-31.528-7.009a3.53 3.53 0 0 0-3.497 3.505c0 1.857 1.629 3.434 3.497 3.503 1.954 0 3.505-1.487 3.505-3.503 0-1.936-1.569-3.505-3.505-3.505z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
