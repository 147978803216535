import { Listbox } from '@headlessui/react';
import { animationTransition } from '@shared/utils/animationTransition';

/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

export const SelectButton = styled(Listbox.Button)`
  width: 100%;
  height: 48px;
  padding: 0 6px;
  border: 1px solid ${({ theme }) => theme.colors.mono5};
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonText = styled.p`
  font-size: ${({ theme }) => theme.typography.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.variants.body2.fontWeight};
  line-height: ${({ theme }) => theme.typography.variants.body2.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.variants.body2.letterSpacing};
  margin-left: 12px;
  transition: ${animationTransition('color')};
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ButtonRightSlot = styled.div`
  margin-left: auto;
  margin-right: 10px;
  display: flex;

  & > * {
    margin-left: 12px;
  }
`;

export const ButtonRightIcon = styled.div<{ rotate?: number }>`
  transition: ${animationTransition('transform')};
  transform: rotate(${({ rotate }) => rotate || 0}deg);
`;

export const SelectContainer = styled.div`
  width: 220px;
  position: relative;

  .selector-listbox-options {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 60px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07);
    padding: 10px;
  }

  .transition {
    transition: ${animationTransition('opacity')};
  }

  .opacity-100 {
    opacity: 1;
  }

  .opacity-0 {
    opacity: 0;
  }

  .selector-listbox-option {
    width: 100%;
    height: 48px;
    padding: 0 6px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: ${animationTransition('background-color')};

    &.upload-option {
      justify-content: center;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.mono1};

      ${ButtonText} {
        color: ${({ theme }) => theme.colors.purple6};
      }
    }

    &:active,
    &.active-option {
      background-color: ${({ theme }) => theme.colors.mono2};

      ${ButtonText} {
        color: ${({ theme }) => theme.colors.purple8};
      }
    }
  }
`;
