import React, { HTMLAttributes } from 'react';
import { styled } from '@withjoy/joykit';

interface Props extends Readonly<{ aspectRatio: number; minHeight?: string; style?: HTMLAttributes<HTMLElement>['style'] }> {}
const AspectOuter = styled.div.withConfig({
  componentId: 'AspectRatioContainer/AspectOuter'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any)<Props>`
  position: relative;
  min-height: ${({ minHeight }) => minHeight};
  &:before {
    content: '';
    display: block;
    padding-bottom: ${({ aspectRatio }) => `${(1 / aspectRatio) * 100}%`};
  }
`;

const AspectInner = styled.div.withConfig({
  componentId: 'AspectRatioContainer/AspectInner'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any)`
  max-height: 600px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const AspectRatioContainer: React.FC<Props> = ({ aspectRatio, children, ...restProps }) => (
  <AspectOuter aspectRatio={aspectRatio} {...restProps}>
    <AspectInner>{children}</AspectInner>
  </AspectOuter>
);
