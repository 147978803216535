import React, { useMemo, useState } from 'react';
import { formatISO } from 'date-fns';
import { ApolloError } from '@apollo/client';
import { createContext } from '@shared/utils/createContext';
import type { PrintOrderSearchResults, Pagination } from '../PrintOrderManagement.types';
import {
  useGetStationeryPrintOrdersLazyQuery,
  StationeryPrintOrdersFiltersInput,
  StationeryPrintOrdersOrderByDirection,
  StationeryPrintOrdersOrderByField
} from '@graphql/generated';

type PrintOrderManagementStateContext = {
  results: PrintOrderSearchResults;
  setQueryArgsAndGetResults: (pagination: Pagination, filters: Maybe<StationeryPrintOrdersFiltersInput> | undefined) => void;
  queryLoading: boolean;
  queryError: ApolloError | undefined;
  queryCalled: boolean;
  totalPageCount: number;
  totalCount: number;
};

const [Provider, usePrintOrderManagementState] = createContext<PrintOrderManagementStateContext>({ name: 'PrintOrderManagementState' });

const PrintOrderManagementState: React.FC = ({ children }) => {
  const [findPrintOrderData, { loading: queryLoading, data: queryData, error: queryError, called: queryCalled }] = useGetStationeryPrintOrdersLazyQuery({
    batchMode: 'fast'
  });

  const totalCount = queryData?.stationeryPrintOrders?.count ?? 0;

  const [pageSize, setPageSize] = useState<number>(20);

  const totalPageCount = useMemo<number>(() => {
    return Math.ceil(totalCount / pageSize);
  }, [pageSize, totalCount]);

  const results = useMemo<PrintOrderSearchResults>(() => {
    return queryData?.stationeryPrintOrders?.orders || [];
  }, [queryData?.stationeryPrintOrders?.orders]);

  const setQueryArgsAndGetResults = (pagination: Pagination, filters: Maybe<StationeryPrintOrdersFiltersInput> | undefined) => {
    const pageSize = pagination.pageSize ?? 20;
    const pageNumber = pagination.pageNumber ?? 1;

    setPageSize(pageSize);

    const paginationArgs = { skip: pageSize * (pageNumber - 1), take: pageSize };
    const filterArgs = {
      orderBy: {
        field: StationeryPrintOrdersOrderByField.createdAt,
        direction: StationeryPrintOrdersOrderByDirection.desc
      },
      ...(!filters?.eventId ? {} : { eventId: filters.eventId }),
      ...(!filters?.eventName ? {} : { eventName: filters.eventName }),
      ...(!filters?.ownerId ? {} : { ownerId: filters.ownerId }),
      ...(!filters?.userEmail ? {} : { userEmail: filters.userEmail }),
      ...(!filters?.statusList ? {} : { statusList: filters.statusList }),
      ...(!filters?.startDate ? {} : { startDate: formatISO(new Date(filters.startDate)) }),
      ...(!filters?.endDate ? {} : { endDate: formatISO(new Date(filters.endDate)) })
    };
    findPrintOrderData({ variables: { pagination: paginationArgs, filters: filterArgs } });
  };

  return (
    <Provider
      value={{
        results,
        setQueryArgsAndGetResults,
        queryLoading,
        queryError,
        queryCalled,
        totalPageCount,
        totalCount
      }}
    >
      {children}
    </Provider>
  );
};

export { usePrintOrderManagementState, PrintOrderManagementState };
