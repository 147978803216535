import { useCallback, useEffect, useRef } from 'react';
import { useImmer } from 'use-immer';
import globalWindow from '@shared/core/globals';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { DEFAULT_OFFSET } from './Popover.constants';
import type { PopoverInternalState, PopoverV2Props, OnPopperUpdateHandler, PopoverTimers } from './Popover.types';
import { callAllHandlers } from '@shared/utils/functions';
import { getValues } from '@shared/utils/object';
import { isTriggerHover } from './Popover.utils';
import { BoxProps } from '@withjoy/joykit';
import { useIds, useDisclosure, useShouldKeepOverlayOpen } from '@withjoy/joykit/hooks';
import { mergeRefs } from '@shared/utils/hooks/setRef';
import { HTMLProps, PropGetter, isEscapeKeyClick } from '@withjoy/joykit/utils';
import { contains } from '@shared/utils/dom';
import { useOnShowFocus, useOnHideFocus } from './useFocus';
import { usePopper } from './usePopper';

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/FocusEvent/relatedTarget
 *
 * - `blur` event -> the `EventTarget` receiving focus (if any)
 * - `focus` event -> the `EventTarget` losing focus (if any)
 */
const getRelatedTarget = (e: Pick<FocusEvent, 'relatedTarget' | 'target' | 'currentTarget'>) => {
  const target = (e.target ?? e.currentTarget) as HTMLElement;

  return (e.relatedTarget ?? target?.ownerDocument?.activeElement) as HTMLElement;
};

export type UsePopoverArgs = Omit<PopoverV2Props, 'children'> & Required<Pick<PopoverV2Props, 'placement' | 'triggerType'>>;

export const usePopover = (props: UsePopoverArgs) => {
  const {
    disableCloseOnBlur,
    disableCloseOnEscapeKeyClick,
    isOpen: isOpenProp,
    onClose: onCloseProp,
    onMouseEnterDelay,
    onMouseLeaveDelay = 300,
    onOpen: onOpenProp,
    placement: placementProp,
    popperOptions,
    triggerType
  } = props;

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({ isOpen: isOpenProp, onOpen: onOpenProp, onClose: onCloseProp });
  // Autogenerate IDs to facilitate WAI-ARIA and server rendering.
  const [popoverId] = useIds(props.id, 'joykit-popover');
  const popperRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLElement>(null);
  const arrowRef = useRef<HTMLElement>(null);
  const tetherReferenceRef = useRef<HTMLElement | null>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const timersRef = useRef<PopoverTimers>({});
  const isHoveringRef = useRef<boolean>(false);

  const [state, setState] = useImmer<PopoverInternalState>({
    arrowOffset: DEFAULT_OFFSET,
    placement: placementProp,
    transformOrigin: undefined
  });
  const { arrowOffset, placement: placementState, transformOrigin } = state;

  useOnHideFocus(popperRef, { isVisible: isOpen, shouldFocus: triggerType === 'click', focusRef: triggerRef });
  useOnShowFocus(popperRef, { isVisible: isOpen, shouldFocus: triggerType === 'click' });

  const handleOnPopperUpdate = useEventCallback<OnPopperUpdateHandler>((placement, offsets, transformOrigin) => {
    timersRef.current.onPopperUpdate = setTimeout(() => {
      setState(draft => {
        draft.placement = placement;
        draft.transformOrigin = transformOrigin || undefined;
      });
    });
  });

  usePopper({
    anchorRef: tetherReferenceRef.current,
    popperRef: popperRef.current,
    arrowRef: arrowRef.current,
    placement: placementProp,
    onPopperUpdate: handleOnPopperUpdate,
    options: popperOptions
  });

  const { shouldKeepOverlayOpen, setVisibilityForKey } = useShouldKeepOverlayOpen({ refValues: { content: false } });

  ////////////////////////
  // Cleanup

  useEffect(() => {
    return () => {
      // Clear any existing timers to avoid memory-leaks + actions occuring when component is unmounted
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const { ...timers } = timersRef.current;
      getValues(timers).forEach(timerId => {
        globalWindow.clearTimeout(timerId);
      });
    };
  }, []);

  const prepareToOpen = useEventCallback<React.MouseEventHandler>(e => {
    isHoveringRef.current = true;
    const timers = timersRef.current;
    // Clear the `onMouseLeave` timer so that a user can move from anchor -> popover
    if (timers.onMouseLeave) {
      globalWindow.clearTimeout(timers.onMouseLeave);
    }

    timers.onMouseEnter = globalWindow.setTimeout(onOpen, onMouseEnterDelay);
  });
  const prepareToClose = useEventCallback<React.MouseEventHandler>(e => {
    isHoveringRef.current = false;
    const timers = timersRef.current;
    // Clear the `onMouseEnter` timer to prevent a stuck open state
    if (timers.onMouseEnter) {
      globalWindow.clearTimeout(timers.onMouseEnter);
    }

    timers.onMouseLeave = globalWindow.setTimeout(() => {
      if (!isHoveringRef.current) {
        onClose();
      }
    }, onMouseLeaveDelay);
  });

  ////////////////////////
  // Prop Getters

  const getAnchorProps: PropGetter = useCallback((props = {}, ref) => {
    return {
      ...props,
      ref: mergeRefs(ref, anchorRef, tetherReferenceRef)
    };
  }, []);

  const getTriggerProps: PropGetter = useCallback(
    (props = {}, ref) => {
      const triggerProps: HTMLProps = {
        ...props,
        'aria-haspopup': 'true',
        'aria-expanded': isOpen ? 'true' : 'false',
        'aria-controls': isOpen ? popoverId : undefined,
        ref: mergeRefs(ref, triggerRef, (node: HTMLElement) => {
          if (!anchorRef.current) {
            tetherReferenceRef.current = node;
          }
        })
      };

      if (isTriggerHover(triggerType)) {
        triggerProps.onFocus = callAllHandlers(props.onFocus, onOpen);
        triggerProps.onBlur = callAllHandlers(props.onBlur, e => {
          const maybeRelatedTarget = getRelatedTarget(e);

          if (!disableCloseOnBlur && !contains(popperRef.current, maybeRelatedTarget)) {
            onClose();
          }
        });
        triggerProps.onMouseEnter = callAllHandlers(props.onMouseEnter, prepareToOpen);
        triggerProps.onMouseLeave = callAllHandlers(props.onMouseLeave, prepareToClose);
      } else {
        triggerProps.onClick = callAllHandlers(props.onClick, () => {
          isOpen || shouldKeepOverlayOpen ? onClose() : onOpen();
        });
      }

      return triggerProps;
    },
    [shouldKeepOverlayOpen, disableCloseOnBlur, isOpen, onOpen, onClose, popoverId, prepareToOpen, prepareToClose, onToggle, triggerType]
  );

  const getArrowProps: PropGetter = useCallback((props, ref) => {
    return {
      ...props,
      ref: mergeRefs(ref, arrowRef)
    };
  }, []);

  const getPopoverProps: PropGetter<HTMLDivElement, BoxProps> = useCallback(
    (props = {}, ref) => {
      const popoverProps: HTMLProps = {
        ...props,
        ref: mergeRefs(ref, popperRef),
        id: popoverId,
        role: 'dialog',
        tabIndex: -1,
        children: isOpen || shouldKeepOverlayOpen ? props.children : null,
        onKeyDown: callAllHandlers(props.onKeyDown, e => {
          if (!disableCloseOnEscapeKeyClick && isEscapeKeyClick(e.key)) {
            onClose();
          }
        }),
        onBlur: callAllHandlers(props.onBlur, e => {
          const maybeRelatedTarget = getRelatedTarget(e);
          const isContainedToContent = contains(popperRef.current, maybeRelatedTarget);
          const isContainedToTrigger = contains(triggerRef.current, maybeRelatedTarget);

          const isOutsideBlur = !isContainedToContent && !isContainedToTrigger;
          if (!disableCloseOnBlur && isOutsideBlur) {
            onClose();
          }
        })
      };

      if (isTriggerHover(triggerType)) {
        popoverProps.role = 'tooltip';
        // The `hover` triggerType should consider both anchor + body
        popoverProps.onMouseEnter = callAllHandlers(props.onMouseEnter, () => {
          isHoveringRef.current = true;
        });
        popoverProps.onMouseLeave = callAllHandlers(props.onMouseLeave, e => {
          isHoveringRef.current = false;
          prepareToClose(e);
        });
      }

      return popoverProps;
    },
    [disableCloseOnBlur, disableCloseOnEscapeKeyClick, isOpen, onClose, popoverId, prepareToClose, shouldKeepOverlayOpen, triggerType]
  );

  return {
    arrowOffset,
    isOpen,
    onClose,
    placement: placementState,
    shouldKeepOverlayOpen,
    transformOrigin,

    getPopoverProps,
    getAnchorProps,
    getArrowProps,
    getTriggerProps,
    setVisibilityForKey
  };
};
