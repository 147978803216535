import { Manager, Reference, Popper, PopperChildrenProps, ReferenceChildrenProps } from 'react-popper';
import React from 'react';
import PopperJS, { ModifierFn } from 'popper.js';

import { Overlay, OverlayProps } from '../Overlay/Overlay';
import { positionToPlacement, getTransformOrigin, getOppositePosition, getPosition, getPopoverOffset, popoverAnimations, getPopoverArrowOffsetModifier } from './popoverUtils';
import { Transition } from '../Overlay/Transition';
import { AnimatedValue, animated } from 'react-spring';
import { safeInvoke } from '../../utils';
import { isElementOrContains } from '../../utils/isElementOrContains';
import memoize from 'memoize-one';
import { PopoverWrapper, PopoverContent, PopoverTarget, PopoverContentWrapper } from './styles';
import { AbstractPureComponent, Position } from '../../common';
import { POPOVER_REQUIRES_TARGET } from '../../common/errors';
import { PopoverArrow } from './components';
import { ResizeSensor } from './ResizeSensor';
import { PopoverInteractionVariant, PopoverOffset, PopoverProps, PopoverState, PopoverTransition } from './types';
import { windowExists } from '@shared/utils/windowExists';
import { Box } from '../Box';

const popoverDefaultProps = {
  boundary: 'scrollParent' as PopperJS.Boundary,
  position: 'auto',
  hoverCloseDelay: 150,
  hoverOpenDelay: 150,
  interactionVariant: PopoverInteractionVariant.CLICK as PopoverInteractionVariant,
  minimal: false,
  offset: PopoverOffset.MEDIUM,
  pinned: false,
  targetTagName: 'span',
  defaultIsOpen: false,
  showArrow: true,
  transitionName: PopoverTransition.FADE_SCALE_IN_OUT as PopoverTransition,
  useBackdrop: false,
  usePortal: true,
  wrapperTagName: 'span',
  modifiers: {}
};

type RefHandler = (el: any) => void;

export class Popover extends AbstractPureComponent<PopoverProps, PopoverState> {
  public static defaultProps = popoverDefaultProps;
  constructor(props: PopoverProps) {
    super(props);
    this.state = {
      isOpen: this.getIsOpenFromProps(),
      transformOrigin: ''
    };
  }

  private popperScheduleUpdate: () => void;

  public reposition = () => safeInvoke(this.popperScheduleUpdate);

  private popoverElement: HTMLElement | null = null;
  private targetElement: HTMLElement | null = null;
  private openTimeout?: number;
  private closeTimeout?: number;
  private isMouseInTargetOrContent = false;

  private refHandlers: {
    popover: RefHandler;
    target: RefHandler;
  } = {
    popover: ref => {
      this.popoverElement = ref;
    },
    target: ref => (this.targetElement = ref)
  };

  componentDidUpdate(prevProps: PopoverProps, prevState: PopoverState) {
    const nextIsOpen = this.getIsOpenFromProps();
    const isInControlledMode = this.isInControlledMode();
    if (isInControlledMode && nextIsOpen !== this.state.isOpen) {
      // Update Open state from prop
      this.setState({ isOpen: nextIsOpen });
    } else if (!isInControlledMode && this.props.disabled) {
      this.setState({ isOpen: false });
    }
  }

  componentWillUnmount() {
    [this.openTimeout, this.closeTimeout].forEach(window.clearTimeout);
  }

  /**
   * TODO: Add prop validation
   */
  protected validateProps(props: PopoverProps) {
    const childrenCount = React.Children.count(props.children);
    if (childrenCount === 0) {
      throw new Error(POPOVER_REQUIRES_TARGET);
    }
  }

  // ==============================================================
  // Callback Handlers
  // ==============================================================

  private handleOnOverlayClose: OverlayProps['onClose'] = e => {
    if (!isElementOrContains(this.targetElement, e.target) || e instanceof KeyboardEvent) {
      if (this.isInControlledMode()) {
        safeInvoke(this.props.onInteraction, false, e);
      } else {
        this.setState({ isOpen: false });
      }

      // safeInvoke(this.props.onClose, e);
    }
  };

  private handleTargetOnClick: React.MouseEventHandler<HTMLElement> = e => {
    if (!this.props.disabled) {
      if (this.isInControlledMode()) {
        safeInvoke(this.props.onInteraction, !this.props.isOpen, e);
      } else {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
      }
    }
  };

  private handleTargetOnFocus: React.FocusEventHandler = e => {
    // TODO
  };
  private handleTargetOnBlur: React.FocusEventHandler = e => {
    // TODO
  };

  private handleOnMouseEnter: React.MouseEventHandler = e => {
    if (!this.props.disabled) {
      this.isMouseInTargetOrContent = true;
      this.setIsOpen(true, e, this.props.hoverOpenDelay);
    }
  };
  private handleOnMouseLeave: React.MouseEventHandler = e => {
    this.isMouseInTargetOrContent = false;

    this.closeTimeout = windowExists()
      ? window.setTimeout(() => {
          if (this.isMouseInTargetOrContent) {
            return;
          }
          this.setIsOpen(false, e, this.props.hoverCloseDelay);
        }, 0)
      : undefined;
  };

  private updatePopoverState: ModifierFn = data => {
    const transformOrigin = getTransformOrigin(data);
    if (transformOrigin !== this.state.transformOrigin) {
      this.setState({ transformOrigin });
    }
    return data;
  };

  // ==============================================================
  // Getters
  // ==============================================================

  private getProps = () => {
    return this.props as PopoverProps & Required<typeof Popover.defaultProps>;
  };

  private getChildren = (targetProps?: PopoverProps & { children?: React.ReactNode }): { target: React.ReactNode; content: React.ReactNode } => {
    const { children, content } = targetProps || this.props;
    const [targetChild, contentChild] = React.Children.toArray(children) as React.ReactChild[];

    return {
      target: targetChild,
      content: content || contentChild
    };
  };

  private getIsOpenFromProps = (): boolean => {
    const { disabled, isOpen, defaultIsOpen } = this.getProps();
    if (disabled) {
      return false;
    } else if (isOpen !== undefined) {
      return isOpen;
    }

    return defaultIsOpen;
  };

  // Memoized aggregate of default + prop modifiers
  private getPopperModifiers = memoize(
    (boundary: PopperJS.Boundary, offset: PopoverOffset, pinned: boolean, showArrow: boolean, modifiers: PopoverProps['modifiers'] = {}): PopperJS.Modifiers => {
      const { preventOverflow = {}, offset: ps = {} } = modifiers;
      return {
        offset: {
          enabled: offset !== PopoverOffset.NONE,
          fn: (data, options) => {
            const offsetDelta = getPopoverOffset(offset);
            const currentPosition = getPosition(data.placement);
            if (currentPosition === Position.LEFT || currentPosition === Position.TOP) {
              data.offsets.popper[currentPosition] -= offsetDelta;
            } else {
              data.offsets.popper[getOppositePosition(currentPosition) as keyof PopperJS.Offset] += offsetDelta;
            }
            safeInvoke(ps.fn, data, options);
            return data;
          }
        },
        // keepTogether: {
        //   enabled: true
        // },
        preventOverflow: {
          ...preventOverflow,
          boundariesElement: preventOverflow.boundariesElement || boundary,
          enabled: preventOverflow.enabled !== undefined ? preventOverflow.enabled : true
        },
        flip: {
          boundariesElement: boundary,
          enabled: !pinned
          // padding: getPopoverOffset(offset) + (showArrow ? 26 : 0)
        },
        arrowOffset: {
          enabled: showArrow,
          order: 510,
          fn: (data, options) => {
            return getPopoverArrowOffsetModifier(data, offset);
          }
        },
        updatePopoverState: {
          enabled: true,
          order: 900,
          fn: this.updatePopoverState
        }
      };
    }
  );

  private getTransitionStyles = (transitionProps: AnimatedValue<React.CSSProperties>) => {
    const { transitionName } = this.props;
    switch (transitionName) {
      case PopoverTransition.FADE_SCALE_IN_OUT: {
        return {
          opacity: transitionProps.opacity,
          transform: this.props.minimal ? undefined : transitionProps.scale!.interpolate(x => `scale(${x}) translateZ(0.01px)`),
          transformOrigin: this.state.transformOrigin
        };
      }
      case PopoverTransition.FADE_SLIDE_IN_OUT:
      default:
        return undefined;
    }
  };

  // ==============================================================
  // Render
  // ==============================================================

  private renderTarget = (referenceProps: ReferenceChildrenProps) => {
    const { targetTagName = 'span', targetProps, interactionVariant, openOnTargetFocus } = this.getProps();
    const target = this.getChildren().target;

    const interactionHandlers: React.HTMLAttributes<HTMLElement> =
      interactionVariant === PopoverInteractionVariant.HOVER || interactionVariant === PopoverInteractionVariant.HOVER_TARGET
        ? {
            onMouseEnter: this.handleOnMouseEnter,
            onMouseLeave: this.handleOnMouseLeave
          }
        : {
            onClick: this.handleTargetOnClick
          };

    const focusHandlers: React.HTMLAttributes<HTMLElement> = openOnTargetFocus ? { tabIndex: 0, onFocus: this.handleTargetOnFocus, onBlur: this.handleTargetOnBlur } : {};

    return (
      <PopoverTarget isInlineElement={targetTagName === 'span'} {...targetProps} {...interactionHandlers} {...focusHandlers} ref={referenceProps.ref} as={targetTagName}>
        {target}
      </PopoverTarget>
    );
  };

  private renderContent = (popperProps: PopperChildrenProps, transitionProps: AnimatedValue<React.CSSProperties>) => {
    const { interactionVariant, usePortal, showArrow, popoverArrowClassName, contentProps, contentWrapperProps, transitionElProps } = this.getProps();
    const content = this.getChildren().content;

    this.popperScheduleUpdate = popperProps.scheduleUpdate;

    const interactionHandlers: React.HTMLAttributes<HTMLElement> = {};

    if (interactionVariant === PopoverInteractionVariant.HOVER || (!usePortal && interactionVariant === PopoverInteractionVariant.HOVER_TARGET)) {
      interactionHandlers.onMouseEnter = this.handleOnMouseEnter;
      interactionHandlers.onMouseLeave = this.handleOnMouseLeave;
    }

    return (
      <PopoverContentWrapper ref={popperProps.ref} style={popperProps.style} zIndex={1} {...contentWrapperProps} {...interactionHandlers}>
        <ResizeSensor onResize={this.reposition}>
          <Box as={animated.div} style={this.getTransitionStyles(transitionProps)} {...transitionElProps}>
            {showArrow && <PopoverArrow className={popoverArrowClassName} placement={popperProps.placement} arrowProps={popperProps.arrowProps} />}
            <PopoverContent boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px" backgroundColor="white" borderRadius="4px" {...contentProps}>
              {content}
            </PopoverContent>
          </Box>
        </ResizeSensor>
      </PopoverContentWrapper>
      // </div>
    );
  };

  render() {
    const { boundary, offset, pinned, minimal, position, interactionVariant } = this.getProps();
    const {
      className,
      canCloseOnEscapeKey,
      useBackdrop,
      usePortal,
      onEntering,
      onEntered,
      onExiting,
      onExited,
      portalContainer,
      showArrow,
      wrapperTagName,
      transitionName,
      modifiers
    } = this.getProps();
    const { isOpen } = this.state;
    const placement = positionToPlacement(position);
    const popperModifiers = this.getPopperModifiers(boundary, offset, pinned, showArrow, modifiers);

    return (
      <Manager>
        <PopoverWrapper as={wrapperTagName} className={className}>
          <Reference innerRef={this.refHandlers.target}>{this.renderTarget}</Reference>
          <Overlay
            overlayKey={''}
            portalContainer={portalContainer}
            containerElement={this.popoverElement}
            isOpen={isOpen}
            onClose={this.handleOnOverlayClose}
            canCloseOnEscapeKey={canCloseOnEscapeKey}
            canCloseOnOutsideClick={interactionVariant === PopoverInteractionVariant.CLICK}
            usePortal={usePortal}
            useBackdrop={useBackdrop}
          >
            <Transition
              toggle={this.state.isOpen}
              {...(minimal ? popoverAnimations.minimal : popoverAnimations[transitionName])}
              config={{ duration: 100 }}
              onEntering={onEntering}
              onEntered={onEntered}
              onExiting={onExiting}
              onExited={onExited}
            >
              {transitionProps => (
                <Popper innerRef={this.refHandlers.popover} placement={placement} modifiers={popperModifiers}>
                  {popperProps => {
                    return this.renderContent(popperProps, transitionProps);
                  }}
                </Popper>
              )}
            </Transition>
          </Overlay>
        </PopoverWrapper>
      </Manager>
    );
  }

  // ==============================================================
  // Util
  // ==============================================================

  private isInControlledMode = () => this.props.isOpen !== undefined;

  private setIsOpen = (isOpen: boolean, e?: React.SyntheticEvent, timeout: number = 0) => {
    window.clearTimeout(this.openTimeout);
    if (timeout > 0) {
      this.openTimeout = windowExists()
        ? window.setTimeout(() => {
            this.setIsOpen(isOpen, e);
          }, timeout)
        : undefined;
    } else {
      if (this.isInControlledMode()) {
        safeInvoke(this.props.onInteraction, isOpen, e);
      } else {
        this.setState({ isOpen });
      }
    }
  };
}
