export const STANDARD_TIMING_FUNCTION = 'cubic-bezier(0.23, 1, 0.32, 1)';
export const STANDARD_DURATION = '500ms';
const STANDARD_DELAY = '0ms';
const STANDARD_TRANSITION = `${STANDARD_DURATION} ${STANDARD_TIMING_FUNCTION}`;
export const animationTransition = (...properties: Array<string>) => {
  if (!properties) {
    throw new Error('Must pass one property to transition');
  }
  return properties.map(property => `${property} ${STANDARD_TRANSITION} 0s`).join(', ');
};

export interface TransitionProps
  extends Readonly<{
    property: string;
    delay?: string;
    duration?: string;
    timingFunction?: string;
  }> {}

export const animationTransitionExt = (...transitions: Array<TransitionProps>) => {
  if (!transitions) {
    throw new Error('Must pass one property to transition');
  }
  return transitions
    .map(({ property, duration, delay, timingFunction }) => {
      duration = duration || STANDARD_DURATION;
      delay = delay || STANDARD_DELAY;
      return `${property} ${duration} ${timingFunction || STANDARD_TIMING_FUNCTION} ${delay}`;
    })
    .join(', ');
};
