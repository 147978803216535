import { animationTransition } from '@shared/utils/animationTransition';
import { styled } from '@withjoy/joykit';
import { Box, StyleSystemProps } from '../Box';
import { SharedMenuItemProps } from './Menu.types';

const maybeUseStyles = <T extends {}>(condition: Maybe<boolean>, styles: T): T | {} => {
  return condition ? styles : {};
};

////////////////////////
// Menu

export const StyledList = styled(Box)`
  list-style: none;
`;

export const getListStyles = (): StyleSystemProps => {
  return {
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: '0px 4px 12px -2px rgba(44, 41, 37, 0.25), 0px 3px 7px -3px rgba(0, 0, 0, 0.3)',
    color: 'mono14',
    margin: 0,
    maxWidth: '300px',
    overflow: 'auto',
    paddingY: 3,
    textAlign: 'left'
  };
};

////////////////////////
// Menu Item

const baseListItemStyles: StyleSystemProps = {
  alignItems: 'center',
  backgroundColor: 'transparent',
  color: 'currentColor',
  cursor: 'pointer',
  display: 'flex',
  minHeight: '48px',
  paddingX: 5,
  paddingY: 4,
  transition: animationTransition('background-color', 'opacity', 'color'),
  // userSelect: 'none',
  width: '100%',
  _focus: {
    outline: 0
  }
};

const highlightedListItemStyles: StyleSystemProps = {
  backgroundColor: 'menuItemFillHighlighted',
  color: 'menuItemTextHighlighted'
};

const disabledListItemStyles: StyleSystemProps = {
  backgroundColor: 'menuItemFillDisabled',
  color: 'menuItemTextDisabled',
  cursor: 'not-allowed',
  opacity: 0.4
};

const selectedListItemStyles: StyleSystemProps = {
  backgroundColor: 'menuItemFillActive',
  color: 'menuItemTextActive',
  cursor: 'default'
};

export const getListItemStyles = ({ $isActive, $isDisabled, $isHighlighted }: SharedMenuItemProps): StyleSystemProps => {
  return {
    ...baseListItemStyles,
    ...maybeUseStyles(!$isDisabled && $isActive, selectedListItemStyles),
    ...maybeUseStyles(!$isDisabled && $isHighlighted, highlightedListItemStyles),
    ...maybeUseStyles($isDisabled, disabledListItemStyles)
  };
};
