import React, { CSSProperties, useCallback, useRef, useEffect, useState } from 'react';
import { styled } from '@withjoy/joykit';
import { useOnIntersectingChange } from '@shared/utils/hooks/useOnIntersectingChange';
import { PhotoFragment } from '@graphql/generated';
import { addRendition, RenditionSize } from '@shared/utils/photoRendition';
import { decode } from 'blurhash';
import { withWindow } from '@shared/utils/withWindow';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';
import { windowExists } from '@shared/utils/windowExists';
import { mergeRefs } from './setRef';

interface GenericArgs {
  url?: string;
}

export const useLazyLoadGenericImage = (args: GenericArgs) => {
  const { url } = args;
  const imageRef = useRef<HTMLImageElement>(null);
  const isIntersecting = useCallback((isIntersecting: boolean) => {
    if (isIntersecting && imageRef.current) {
      imageRef.current.src = imageRef.current.dataset.src ?? '';
    }
  }, []);
  useOnIntersectingChange(imageRef, isIntersecting);
  return { ref: imageRef, 'data-src': url };
};

interface BackgroundArgs {
  url?: string;
}
export const useLazyLoadBackgroundImage = (args: BackgroundArgs) => {
  const { url } = args;
  const backgroundImageRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useCallback((isIntersecting: boolean) => {
    if (isIntersecting && backgroundImageRef.current) {
      backgroundImageRef.current.style.backgroundImage = backgroundImageForUrl(backgroundImageRef.current.dataset.src) ?? '';
    }
  }, []);
  useOnIntersectingChange(backgroundImageRef, isIntersecting);
  return { ref: backgroundImageRef, 'data-src': url };
};

const lazyLoadingObserver = (() => {
  type Observer = (element: Element, callback: () => void) => () => void;
  const thresholdObserverMap = new Map<number, Observer>();
  const getObserver = (threshold: number) => {
    let entry = thresholdObserverMap.get(threshold);
    if (!entry) {
      const callbackMap = new Map<Element, () => void>();
      const cleanupIfEmpty = () => {
        if (callbackMap.size === 0) {
          observer.disconnect();
          thresholdObserverMap.delete(threshold);
        }
      };
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(i => {
            const target = i.target;
            const callback = callbackMap.get(target);
            if (i.isIntersecting) {
              callback?.();
              callbackMap.delete(target);
              observer.unobserve(target);
            }
          });
          cleanupIfEmpty();
        },
        {
          rootMargin: `0px 0px ${threshold}px 0px`
        }
      );
      entry = (element, callback) => {
        observer.observe(element);
        callbackMap.set(element, callback);
        return () => {
          observer.unobserve(element);
          callbackMap.delete(element);
        };
      };
      thresholdObserverMap.set(threshold, entry);
    }
    return entry;
  };

  return () => {
    // If window does not exist, we are in SSR case, don't trigger any callbacks
    if (!windowExists()) {
      return { updateObserver: () => {}, updateCallback: () => {} };
    }

    // If there is a window, but no intersection observer disable lazy loading and just call the callback immediately
    if ((windowExists() && !window.IntersectionObserver) || process.env.NODE_ENV === 'test') {
      return {
        updateObserver: () => {},
        updateCallback: (callback: () => void) => {
          callback();
        }
      };
    }

    let unobserve = () => {};
    let currentCallback = () => {};
    return {
      updateObserver: (element: Element | null, threshold: number) => {
        unobserve();
        unobserve = element
          ? getObserver(threshold)(element, () => {
              currentCallback();
            })
          : () => {};
      },
      updateCallback: (newCallback: () => void) => {
        currentCallback = newCallback;
      }
    };
  };
})();

export const useOnScreen = <T extends HTMLElement>(threshold = 500): { isOnScreen: boolean; ref: React.Ref<T> } => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observer = useRef(lazyLoadingObserver());
  useEffect(() => {
    observer.current.updateCallback(() => setIsOnScreen(true));
  }, []);
  const ref = useCallback(
    (newElement: T | null) => {
      if (!isOnScreen) {
        // Add a timeout to make sure the callback is called after it is registered in the observer (needed for Safari)
        setTimeout(() => {
          observer.current.updateObserver(newElement, threshold);
        }, 50);
      }
    },
    [threshold, isOnScreen]
  );
  return { ref, isOnScreen };
};

/**
 * Utility for loading a prop when it is on (or near the screen), mostly useful for images in a grid
 * Binds minimal set of IntersectionObservers, and handles the cleanup.
 * It is agnostic to what type of component, so can be used with img source tags, custom image components etc.
 * Note: It is a one-way toggle, once something is loaded there is no "unload" when it goes off-screen again
 * @param Component The component to wrap
 * @param propName The property name to lazily set
 * @param threshold The off screen threshold before triggering the load in pixels (defaults to 500)
 * @returns A prop-identical component to the one passed in, but with the propName swapped to a bag of offscreen-onscreen
 */
export const withLazyPropOnIntersection = <P, T extends keyof P>(Component: React.FC<P>, propName: T, threshold = 500) => {
  return React.forwardRef<HTMLElement, Omit<P, T> & { [K in T]: { offscreen?: P[K]; onscreen?: P[K] } }>((props, forwardedRef) => {
    const { isOnScreen, ref } = useOnScreen(threshold);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mergedRef = useCallback(mergeRefs(ref, forwardedRef), []);
    const newProps = ({ ...props, ref: mergedRef, [propName]: isOnScreen ? props[propName].onscreen : props[propName].offscreen } as unknown) as P;
    return <Component {...newProps}>{props.children}</Component>;
  });
};

interface Args {
  photo: Pick<PhotoFragment, 'id' | 'width' | 'height' | 'url' | 'blurHash'>;
  rendition: RenditionSize;
  maxHeight?: number;
  maxWidth?: number;
}

const calculatePlaceholder = (photoWidth: number, photoHeight: number, refWidth?: number, refHeight?: number) => {
  if (!refWidth && !refHeight) {
    return { width: 0, height: 0, padding: 0 };
  } else if (refWidth && refHeight) {
    return { width: refWidth, height: refHeight, padding: 0 };
  } else if (!refWidth && refHeight) {
    const height = Math.ceil(refHeight);
    const width = Math.ceil((refHeight / photoHeight) * photoWidth);
    const padding = (width / height) * refHeight;
    return { width, height, padding };
  } else if (!refHeight && refWidth) {
    const height = Math.ceil((refWidth / photoWidth) * photoHeight);
    const width = Math.ceil(refWidth);
    const padding = (height / width) * refWidth;
    return { width, height, padding };
  }
  return { width: 0, height: 0, padding: 0 };
};

const LazyImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  :not([src]) {
    visibility: hidden;
  }
`;

export const useLazyLoadImage = (args: Args) => {
  const { photo, rendition, maxHeight, maxWidth } = args;
  const parentRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(withWindow(window => window.document.createElement('canvas'), null));
  const { width, height, padding } = calculatePlaceholder(photo.width, photo.height, maxWidth, maxHeight);
  useEffect(() => {
    const draw = () => {
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext('2d');
        if (ctx && photo.blurHash) {
          try {
            const pixels = decode(photo.blurHash, Math.ceil(32), Math.ceil(32), 1);
            const imageData = ctx.createImageData(Math.ceil(32), Math.ceil(32));
            imageData.data.set(pixels);
            ctx.putImageData(imageData, 0, 0);
          } catch (err) {
            console.error(err);
          }
        }
      }
    };
    // eslint-disable-next-line compat/compat
    window.requestIdleCallback(() => {
      draw();
    });
  }, [photo.blurHash, height, width]);
  const isIntersectingImage = useCallback((isIntersecting: boolean) => {
    if (isIntersecting && imageRef.current) {
      imageRef.current.src = imageRef.current.dataset.src ?? '';
    }
  }, []);
  useOnIntersectingChange(parentRef, isIntersectingImage);
  const parentStyles: CSSProperties = { position: 'relative', height: '100%', width: '100%' };
  const canvasStyles: CSSProperties = { top: 0, left: 0, bottom: 0, right: 0, height: height ? height + 'px' : '100%', width: '100%', position: 'absolute' };
  const paddingStyles: CSSProperties = { paddingRight: `${padding}px`, display: 'block' };
  return {
    LazyImage,
    parentProps: { ref: parentRef, style: parentStyles },
    imageProps: { 'data-src': photo.url ? addRendition({ url: photo.url, renditionSize: rendition }) : undefined, ref: imageRef },
    canvasProps: { ref: canvasRef, width: 32, height: 32, style: canvasStyles },
    paddingDivProps: { style: paddingStyles }
  };
};
