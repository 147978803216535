import React from 'react';
import { StyledLabel, StyledLabelText } from './choice.styles';
import { ChoiceProps } from '.';

interface ChoiceInternalProps extends ChoiceProps {
  indicator?: React.ReactElement<any>;
}

export const choiceDefaultProps = {
  alignIndicator: 'right'
};

/**
 * -----------------------------------
 * DEPRECATED -- Use `<CheckboxV2 />`
 * ----------------------------------
 *
 * @deprecated
 */
const Choice: React.SFC<ChoiceInternalProps> = React.memo(props => {
  const { alignIndicator, label, children, indicator, ...restProps } = props;
  const isAlignedLeft = alignIndicator === 'left';

  const labelMarkup = (
    <StyledLabelText variant="body1" key={'label'} color={'currentColor'}>
      {label}
    </StyledLabelText>
  );

  return (
    <StyledLabel {...restProps} isAlignedLeft={isAlignedLeft}>
      {children}
      {isAlignedLeft ? (
        <>
          {indicator}
          {labelMarkup}
        </>
      ) : (
        <>
          {labelMarkup}
          {indicator}
        </>
      )}
    </StyledLabel>
  );
});

export default Choice;
