import React from 'react';

import { addRendition } from '@shared/utils/photoRendition';
import { EventPageFragment } from '@graphql/generated';

export interface PreloadImagesProps {
  pages: EventPageFragment[];
}

const PreloadImages: React.FC<PreloadImagesProps> = ({ pages }) => {
  const images = pages.map(page => page.photo?.url);

  return (
    <>
      {images.map((url, index) => (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img key={`${index}${url}`} src={addRendition({ url, renditionSize: 'xlarge' })} aria-hidden="true" style={{ display: 'none' }} />
      ))}
    </>
  );
};

export { PreloadImages };
