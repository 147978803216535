import { Variant } from './ScheduleList';
import { ContentColumnProps } from '../../primitives/ContentColumn';
import { BoxProps, ButtonV2Props, SpacingStackProps, TextV2Props } from '@withjoy/joykit';
import deepmerge from 'deepmerge';

const mergeVariantStyles = <T>(x: T, y: T) => {
  return deepmerge<T>(x, y, {
    clone: true,
    arrayMerge: (target, source) => source
  });
};

export type VariantStyles = {
  scheduleDayItemContainer: BoxProps;
  container: ContentColumnProps;
  header: BoxProps;
  headerText: TextV2Props;
  primaryBlockColumns: ContentColumnProps;
  primaryBlock: {
    container: BoxProps;
  };
  events: BoxProps;
  eventsStack: SpacingStackProps;

  secondaryBlockColumns: ContentColumnProps;
  secondaryBlock: {
    container: SpacingStackProps;
    durationBlockContainer: BoxProps;
    durationBlock: BoxProps;
    calendarBlock: ButtonV2Props;
    directionBlock: ButtonV2Props;
  };
};

const defaultStyles: VariantStyles = {
  scheduleDayItemContainer: {
    _notFirst: {
      marginTop: [10]
    }
  },
  header: {
    borderBottom: '1px solid',
    borderBottomColor: 'mono3'
  },
  headerText: {
    textAlign: 'center'
  },
  events: {},
  eventsStack: {
    width: '100%'
  },
  container: {},
  primaryBlockColumns: {
    width: '100%',
    marginTop: [0, null, 0]
  },
  primaryBlock: {
    container: {}
  },
  secondaryBlockColumns: {
    marginTop: [10]
  },
  secondaryBlock: {
    container: {
      spacing: 6
    },
    durationBlockContainer: {},
    durationBlock: {
      textAlign: 'center'
    },
    calendarBlock: {
      width: ['auto', 'auto', '260px'],
      paddingX: ['0.75rem', '1rem', '1.5rem']
    },
    directionBlock: {
      width: 'auto',
      paddingX: ['0.75rem', '1rem']
    }
  }
};

export const variantStyles: Readonly<Record<Variant, VariantStyles>> = {
  stack: mergeVariantStyles(defaultStyles, {
    scheduleDayItemContainer: {
      _notFirst: {
        marginTop: [0]
      }
    },
    container: {},
    events: {
      paddingTop: '2.5rem',
      paddingX: { _: '2rem', md: '4rem' },
      maxWidth: '100%'
    },
    eventsStack: {
      spacing: '1.5rem'
    },
    header: {
      paddingBottom: '0.5rem',
      marginBottom: '2.5rem'
    },
    headerText: {
      typographyVariant: 'hed3'
    },
    primaryBlockColumns: {
      span: 12
    },
    primaryBlock: {
      container: {
        marginTop: [0],
        marginBottom: ['2.5rem']
      }
    },
    secondaryBlockColumns: {
      marginTop: [0],
      span: 12
    },
    secondaryBlock: {
      container: {
        alignItems: 'center',
        marginBottom: ['2.5rem']
      },
      durationBlockContainer: {
        textAlign: 'center'
      },
      durationBlock: {
        color: 'mono10'
      },
      calendarBlock: {},
      directionBlock: {}
    }
  }),
  inline: mergeVariantStyles(defaultStyles, {
    scheduleDayItemContainer: {
      marginTop: [10]
    },
    container: {}, //{ span: [12, 10], offset: [null] },
    events: {},
    eventsStack: {
      spacing: 10
    },
    header: {
      paddingBottom: 5
    },
    headerText: {
      typographyVariant: 'hed4',
      whiteSpace: 'pre-wrap'
    },
    primaryBlockColumns: {
      span: [12, 'five-eigths']
    },
    primaryBlock: {
      container: {
        marginTop: [8, null, 0]
      }
    },
    secondaryBlockColumns: {
      span: [12, 'three-eigths'],
      marginTop: 0
    },
    secondaryBlock: {
      container: {
        alignItems: ['center', null, 'flex-end'],
        marginBottom: ['2.5rem'],
        marginTop: ['0.125rem']
      },
      durationBlockContainer: {
        textAlign: ['center', 'inherit']
      },
      durationBlock: {
        marginTop: [5, 0],
        color: 'mono10'
      },
      calendarBlock: {},
      directionBlock: {}
    }
  })
};
