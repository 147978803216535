import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from '@shared/core';
import { EventWebsiteGuestDocument, GetEventCTAButtonsDataDocument, GetGuestSiteScheduleDocument, useJoinEventAsPersonWithPasswordMutation } from '@graphql/generated';
import { performFullPageRedirect } from '@shared/utils/navigation';
import { useEffect } from 'react';
import { useToast } from '@withjoy/joykit';

interface UnlockFields
  extends Readonly<{
    password: string;
  }> {}

interface UseUnlockControllerProps {
  eventId: string;
  onUnlockSuccess: () => void;
}

export function useUnlockController({ eventId, onUnlockSuccess }: UseUnlockControllerProps) {
  const { toast } = useToast();
  const [joinEvent] = useJoinEventAsPersonWithPasswordMutation();
  const { t2 } = useTranslation('guestUnlock');
  const { title, subtitle, passwordLabel, passwordPlaceholder, submitButtonText, signInButtonText, contactButtonText, incorrectPassText, required } = t2('unlockForm');

  const formik = useFormik<UnlockFields>({
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object<UnlockFields>({
      password: Yup.string().required(required)
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async values => {
      try {
        const responseJoinEvent = await joinEvent({
          variables: {
            eventId,
            payload: { eventPassword: values.password }
          },
          refetchQueries: () => [GetEventCTAButtonsDataDocument, EventWebsiteGuestDocument, GetGuestSiteScheduleDocument],
          awaitRefetchQueries: true
        });
        if (responseJoinEvent?.data?.joinEventAsPersonWithPassword?.hasUnlocked) {
          onUnlockSuccess();
        }
      } catch (e) {
        formik.setFieldError('password', 'incorrect');
      }
    }
  });

  const onSignInHandler = () => {
    performFullPageRedirect('/login');
  };

  const inputError = formik.errors.password;

  useEffect(() => {
    if (inputError === 'incorrect') {
      toast(incorrectPassText);
    }

    if (inputError && inputError !== 'incorrect') {
      toast(inputError);
    }
  }, [inputError, toast, incorrectPassText]);

  return {
    formik,
    loading: formik.isSubmitting,
    title,
    subtitle,
    passwordLabel,
    passwordPlaceholder,
    submitButtonText,
    contactButtonText,
    signInButtonText,
    incorrectPassText,
    inputError: formik.errors.password,
    onSignInHandler
  };
}
