import { Flex, styled, StyleSystemProps, InputV2, FormControl, Box } from '@withjoy/joykit';

export const SearchBarRow = styled(Flex)``;
export const SearchBarInput = styled(InputV2)``;
export const SearchBarFormControl = styled(FormControl)``;
export const SearchButtonWrapper = styled(Box)``;

const formControlStyles: StyleSystemProps = {
  padding: 2
};

const searchButtonWrapperStyles: StyleSystemProps = {
  padding: 2,
  paddingTop: 7
};

const SearchBarRowStyles: StyleSystemProps = {
  flexWrap: 'wrap',
  marginBottom: 6
};

export const styles = {
  formControl: formControlStyles,
  searchButtonWrapper: searchButtonWrapperStyles,
  SearchBarRow: SearchBarRowStyles
};
