import { isHTMLElement } from '@shared/utils/dom';
export const hasDisplayNone = (element: HTMLElement) => window.getComputedStyle(element).display === 'none';

export const hasTabIndex = (element: HTMLElement) => element.hasAttribute('tabindex');

export const hasNegativeTabIndex = (element: HTMLElement) => hasTabIndex(element) && element.tabIndex === -1;

export function isDisabled(element: HTMLElement) {
  return Boolean(element.getAttribute('disabled')) === true || Boolean(element.getAttribute('aria-disabled')) === true;
}

export function isHidden(element: HTMLElement) {
  if (element.parentElement && isHidden(element.parentElement)) return true;
  return element.hidden;
}

export function isContentEditable(element: HTMLElement) {
  const value = element.getAttribute('contenteditable');
  return value !== 'false' && value != null;
}

const focusableTags = ['input', 'select', 'textarea', 'button'];
export function isFocusable(element: HTMLElement) {
  if (!isHTMLElement(element) || isHidden(element) || isDisabled(element)) {
    return false;
  }

  const { localName } = element;
  if (focusableTags.indexOf(localName) >= 0) return true;

  if (isContentEditable(element)) return true;

  return hasTabIndex(element);
}

export function isTabbable(element?: HTMLElement | null) {
  if (!element) return false;
  return isHTMLElement(element) && isFocusable(element) && !hasNegativeTabIndex(element);
}
