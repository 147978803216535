/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Brand Google',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.143 6.954l3.24-2.652A9.958 9.958 0 0 0 12 2a9.998 9.998 0 0 0-8.882 5.401A9.95 9.95 0 0 0 2 12a9.999 9.999 0 0 0 10 10c2.37 0 4.547-.824 6.26-2.202a9.998 9.998 0 0 0 3.565-9.666H12.22v3.956h5.347a5.96 5.96 0 0 1-2.564 3.043 5.946 5.946 0 0 1-8.57-3.045A5.92 5.92 0 0 1 6.054 12c0-.676.114-1.326.321-1.932a5.948 5.948 0 0 1 8.767-3.114z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
