import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApolloClient } from '@apollo/client';
import { EventWebsiteGuestDocument, FindInviteDocument, FindInviteQuery, useJoinEventAsPersonWithPasswordMutation } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { VerifyEmailFormProps } from './VerifyEmailForm';
import { useEventNameMatch } from '@shared/components/AuthProvider/AuthProvider.utils';
import { useEffect, useMemo } from 'react';
import { useToast } from '@withjoy/joykit';

interface VerifyEmailFields
  extends Readonly<{
    email: string;
    notFoundError: string;
    manyResultsError: string;
  }> {}

export const VerifyEmailFormController = ({ eventId, setGuest, guest }: VerifyEmailFormProps) => {
  const { toast } = useToast();
  const client = useApolloClient();
  const { t, t2 } = useTranslation('guestUnlock');
  const { title, emailLabel, submitButtonText, required, inputPlaceholder } = t2('verifyEmailForm');
  const { firstName, lastName } = guest;
  const subtitle = t(
    'verifyEmailForm',
    'subtitle'
  )({
    firstName,
    lastName
  });
  const { eventName } = useEventNameMatch();
  const [joinEvent, { loading }] = useJoinEventAsPersonWithPasswordMutation();

  const formik = useFormik<VerifyEmailFields>({
    initialValues: {
      email: '',
      notFoundError: '',
      manyResultsError: ''
    },
    validationSchema: Yup.object<VerifyEmailFields>({
      email: Yup.string().required(required).email(t2('askHostForm').validEmailWarning),
      notFoundError: Yup.string(),
      manyResultsError: Yup.string()
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async ({ email }) => {
      try {
        const { data } = await client.query<FindInviteQuery>({
          query: FindInviteDocument,
          variables: {
            eventId,
            email,
            firstName,
            lastName
          },
          fetchPolicy: 'no-cache'
        });

        const guestsFound = data?.event?.findPeople.results.filter(({ person }) => person.email === email && person.firstName === firstName && person.lastName === lastName);

        if (guestsFound?.length) {
          const foundPerson = guestsFound[0].person;

          await joinEvent({
            variables: {
              eventId,
              payload: { personIdTag: foundPerson.personIdTag }
            },
            fetchPolicy: 'no-cache',
            refetchQueries: () => [EventWebsiteGuestDocument, { variables: { eventHandle: eventName }, batchMode: 'fast' }],
            awaitRefetchQueries: true
          });

          setGuest({
            firstName: foundPerson.firstName,
            lastName: foundPerson.lastName,
            personIdTag: foundPerson.personIdTag
          });
        } else {
          formik.setFieldError('notFoundError', t('verifyEmailForm', 'notFoundErrorText')({ firstName, lastName }));
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  const inputError = useMemo(() => formik.errors.email, [formik.errors.email]);
  const notFoundError = useMemo(() => formik.errors.notFoundError, [formik.errors.notFoundError]);

  useEffect(() => {
    if (inputError) {
      toast(inputError);
    }

    if (notFoundError) {
      toast(notFoundError);
    }
  }, [inputError, notFoundError, toast]);

  return {
    formik,
    loading: formik.isSubmitting || loading,
    inputError: formik.errors.email,
    notFoundError: formik.errors.notFoundError,
    manyResultsError: formik.errors.manyResultsError,
    title,
    subtitle,
    emailLabel,
    submitButtonText,
    inputPlaceholder
  };
};
