import React, { useMemo, useCallback } from 'react';
import { SelectV1 } from '@withjoy/joykit';
import type { EmailDeliverySearchResultEntry } from '@apps/serviceCenter/ServiceCenter.types';
import { FilterProps } from 'react-table';

export const SelectColumnFilter = ({ column: { setFilter, preFilteredRows, id } }: FilterProps<EmailDeliverySearchResultEntry>) => {
  const allOptionsOption = useMemo(() => ({ value: '', label: 'Show All' }), []);
  const uniqueOptions = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      const value = row.values[id];
      const option = value && typeof value === 'object' ? value.status : value;
      if (option) {
        options.add(option);
      }
    });
    return Array.from(options.values())
      .map(option => ({ value: `${option}`, label: `${option}` }))
      .concat(allOptionsOption);
  }, [id, preFilteredRows, allOptionsOption]);

  const onChange = useCallback(
    e => {
      const filter = !!e?.value ? e?.value : undefined;
      setFilter(filter);
    },
    [setFilter]
  );

  return <SelectV1 searchable={false} onChange={onChange} options={uniqueOptions} defaultValue={allOptionsOption} />;
};
