/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Zoom Expand',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M1.45044 2.33255C1.45044 1.8447 1.84592 1.44922 2.33377 1.44922H4.66711C4.97086 1.44922 5.21711 1.69546 5.21711 1.99922C5.21711 2.30298 4.97086 2.54922 4.66711 2.54922H3.51428L6.6851 5.72004C6.89988 5.93483 6.89988 6.28307 6.6851 6.49786C6.47031 6.71264 6.12207 6.71264 5.90728 6.49786L2.55044 3.14102V4.66589C2.55044 4.96964 2.3042 5.21589 2.00044 5.21589C1.69668 5.21589 1.45044 4.96964 1.45044 4.66589V2.33255Z"
            fill="currentColor"
          />
          <path
            d="M1.45044 13.6664C1.45044 14.1543 1.84592 14.5497 2.33377 14.5497H4.66711C4.97086 14.5497 5.21711 14.3035 5.21711 13.9997C5.21711 13.696 4.97086 13.4497 4.66711 13.4497H3.51428L6.6851 10.2789C6.89988 10.0641 6.89988 9.71589 6.6851 9.5011C6.47031 9.28632 6.12207 9.28632 5.90728 9.5011L2.55044 12.8579V11.3331C2.55044 11.0293 2.3042 10.7831 2.00044 10.7831C1.69668 10.7831 1.45044 11.0293 1.45044 11.3331V13.6664Z"
            fill="currentColor"
          />
          <path
            d="M13.6663 1.44922C14.1542 1.44922 14.5497 1.8447 14.5497 2.33255V4.66589C14.5497 4.96964 14.3034 5.21589 13.9997 5.21589C13.6959 5.21589 13.4497 4.96964 13.4497 4.66589V3.14102L10.0928 6.49786C9.87803 6.71264 9.52979 6.71264 9.315 6.49786C9.10021 6.28307 9.10021 5.93483 9.315 5.72004L12.4858 2.54922H11.333C11.0292 2.54922 10.783 2.30298 10.783 1.99922C10.783 1.69546 11.0292 1.44922 11.333 1.44922H13.6663Z"
            fill="currentColor"
          />
          <path
            d="M14.5497 13.6664C14.5497 14.1543 14.1542 14.5497 13.6663 14.5497H11.333C11.0292 14.5497 10.783 14.3035 10.783 13.9997C10.783 13.696 11.0292 13.4497 11.333 13.4497H12.4858L9.315 10.2789C9.10021 10.0641 9.10021 9.71589 9.315 9.5011C9.52979 9.28632 9.87803 9.28632 10.0928 9.5011L13.4497 12.8579V11.3331C13.4497 11.0293 13.6959 10.7831 13.9997 10.7831C14.3034 10.7831 14.5497 11.0293 14.5497 11.3331V13.6664Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.40002 3.50039C2.40002 2.89288 2.89251 2.40039 3.50002 2.40039H7.00002C7.3314 2.40039 7.60002 2.66902 7.60002 3.00039C7.60002 3.33176 7.3314 3.60039 7.00002 3.60039H4.40886L9.8674 9.05893C10.1017 9.29324 10.1017 9.67314 9.8674 9.90745C9.63308 10.1418 9.25318 10.1418 9.01887 9.90745L3.60002 4.48861V7.00039C3.60002 7.33176 3.3314 7.60039 3.00002 7.60039C2.66865 7.60039 2.40002 7.33176 2.40002 7.00039V3.50039ZM2.40002 20.5003C2.40002 21.1079 2.89251 21.6003 3.50002 21.6003H7.00002C7.3314 21.6003 7.60002 21.3317 7.60002 21.0003C7.60002 20.669 7.3314 20.4003 7.00002 20.4003H4.40886L9.8674 14.9418C10.1017 14.7075 10.1017 14.3276 9.8674 14.0933C9.63308 13.859 9.25318 13.859 9.01887 14.0933L3.60002 19.5121V17.0003C3.60002 16.669 3.3314 16.4003 3.00002 16.4003C2.66865 16.4003 2.40002 16.669 2.40002 17.0003V20.5003ZM20.5 2.40039C21.1075 2.40039 21.6 2.89288 21.6 3.50039V7.00039C21.6 7.33176 21.3314 7.60039 21 7.60039C20.6687 7.60039 20.4 7.33176 20.4 7.00039V4.48861L14.9812 9.90745C14.7469 10.1418 14.367 10.1418 14.1327 9.90745C13.8983 9.67314 13.8983 9.29324 14.1327 9.05893L19.5912 3.60039H17C16.6687 3.60039 16.4 3.33176 16.4 3.00039C16.4 2.66902 16.6687 2.40039 17 2.40039H20.5ZM21.6 20.5003C21.6 21.1079 21.1075 21.6003 20.5 21.6003H17C16.6687 21.6003 16.4 21.3317 16.4 21.0003C16.4 20.669 16.6687 20.4003 17 20.4003H19.5912L14.1327 14.9418C13.8983 14.7075 13.8983 14.3276 14.1327 14.0933C14.367 13.859 14.7469 13.859 14.9812 14.0933L20.4 19.5121V17.0003C20.4 16.669 20.6687 16.4003 21 16.4003C21.3314 16.4003 21.6 16.669 21.6 17.0003V20.5003Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
