import { Box } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import React from 'react';

const PADDING_VERTICAL = `${96 / 16}rem`;

interface PageProps
  extends Readonly<{
      applicatorProp: object;
      showBorder?: boolean;
      noTranslucency?: boolean;
    }>,
    HtmlProps<HTMLElement> {}

const Page: React.FC<PageProps> = ({ applicatorProp, showBorder = true, noTranslucency, children, ...restProps }) => {
  return (
    <Box
      paddingBottom={PADDING_VERTICAL}
      paddingTop={{ xxs: 0, sm4: PADDING_VERTICAL }}
      minHeight={'100vh'}
      backgroundColor={noTranslucency ? 'white !important' : 'white'}
      position="relative"
      boxShadow={showBorder ? [null, null, '0px 30px 60px -12px rgba(68, 50, 93, 0.25), 0px 18px 36px -18px rgba(0, 0, 0, 0.3)'] : undefined}
      transition={animationTransition('background-color')}
      {...applicatorProp}
      {...restProps}
    >
      {children}
    </Box>
  );
};

Page.displayName = 'Page';

export { Page };
