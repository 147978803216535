import { useEffect } from 'react';
import { RemoteFeatureFlagKeys } from '@shared/core/featureFlags';
import globalWindow from '@shared/core/globals';
import { isInIframe } from '@shared/utils/isInIframe';
import { createPreviewMessageEventHandler, PreviewIframeMessage } from '@shared/utils/previewMessageBus';

export type InlineEditExperimentProps = {
  experimentKey: RemoteFeatureFlagKeys;
  setFn: ({ isEnabled, id }: { isEnabled: boolean; id: string }) => void;
}[];

export const useInlineEditExperimentListeners = (experimentData: InlineEditExperimentProps) => {
  const isPreviewing = isInIframe();

  useEffect(() => {
    if (!isPreviewing) {
      return;
    }

    const processMessage = (message: PreviewIframeMessage) => {
      switch (message.action) {
        case 'fromList': {
          message.value.forEach(msg => {
            if (msg.key === 'experiment') {
              experimentData.forEach(({ experimentKey, setFn }) => {
                if (msg.value[experimentKey] !== undefined) {
                  setFn(msg.value[experimentKey] as { isEnabled: boolean; id: string });
                }
              });
            }
          });
          break;
        }
      }
    };

    const receiveMessage = createPreviewMessageEventHandler(processMessage, 'joy');
    globalWindow.addEventListener?.('message', receiveMessage, false);

    return () => {
      globalWindow.removeEventListener?.('message', receiveMessage, false);
    };
  }, [experimentData, isPreviewing]);
};
