import React, { useEffect, useRef } from 'react';
import { isEscapeKeyClick } from '@withjoy/joykit/utils';
import { useOverrides } from '@shared/utils/overrides';
import { useForkRef } from '@shared/utils/hooks/useCombinedRefs';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import { useImmer } from 'use-immer';
import { useFormControl } from '../FormControl/FormControl.provider';
import { StyledEnhancerElement, StyledInput, StyledInputRoot, useInputStyles } from './Input.styles';
import { InputEnhancer, InputOverrides, InputV2Props, InputState, SharedInputProps } from './Input.types';
import { trySetNativeValueAndDispatch } from './Input.utils';
import { forwardRef } from '@shared/utils/forwardRef';

const inputDefaultProps = {
  enableClearOnEscape: false as boolean,
  enableFocusOnMount: false as boolean,
  isFullWidth: true as boolean,
  overrides: {} as InputOverrides,
  type: 'text' as string
} as const;

export const defaultComponents = {
  Root: StyledInputRoot,
  Input: StyledInput,
  StartElement: StyledEnhancerElement,
  EndElement: StyledEnhancerElement
} as const;

const ENHANCER_GROWTH_THRESHOLD = 2;

const InputV2 = forwardRef<'input', InputV2Props>((baseProps, ref) => {
  const props = baseProps as InputV2Props & typeof inputDefaultProps;
  const {
    'aria-autocomplete': ariaAutoComplete,
    'aria-describedby': ariaDescribedBy,
    'aria-errormessage': ariaErrorMessage,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    enableClearOnEscape,
    enableFocusOnMount,
    endElement,
    id,
    isFullWidth,
    isDisabled,
    isInvalid,
    isReadOnly,
    isRequired,
    onBlur,
    onChange,
    onClear,
    onFocus,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    overrides,
    size,
    startElement,
    ...restProps
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const leftEnhancerRef = useRef<HTMLDivElement>(null);
  const rightEnhancerRef = useRef<HTMLDivElement>(null);
  const forkedRefHandler = useForkRef(ref, inputRef);
  const [state, setState] = useImmer<InputState>({ isFocused: false, leftEnhancerWidth: undefined, rightEnhancerWidth: undefined });

  const formControlProps = useFormControl(props);
  const sharedProps: SharedInputProps = {
    $isDisabled: !!formControlProps.isDisabled,
    $isInvalid: !!formControlProps.isInvalid,
    $isFocused: state.isFocused,
    $isFullWidth: props.isFullWidth,
    $isRequired: false,
    $size: 'md'
  };

  const styles = useInputStyles(sharedProps);

  const {
    Root: [Root, rootProps],
    Input: [InputEl, inputProps],
    StartElement: [StartElement, startElementProps],
    EndElement: [EndElement, endElementProps]
  } = useOverrides(defaultComponents, overrides);

  ////////////////////////
  // Utils

  const setFocus = useEventCallback((isFocused: boolean) => {
    setState(draft => {
      draft.isFocused = isFocused;
    });
  });

  ////////////////////////
  // Enhancers

  const calculateEnhancerWidth = useEventCallback((element: Maybe<HTMLDivElement>, currentWidth: number | undefined): number | undefined => {
    if (element) {
      const clientWidth = element.clientWidth;

      // Reduce layout thrashing by enforcing min threshold differences
      if (currentWidth === undefined || Math.abs(clientWidth - currentWidth) > ENHANCER_GROWTH_THRESHOLD) {
        return clientWidth;
      }
      return currentWidth;
    }
    return undefined;
  });

  useIsomorphicLayoutEffect(() => {
    setState(draft => {
      draft.leftEnhancerWidth = calculateEnhancerWidth(leftEnhancerRef.current, draft.leftEnhancerWidth);
      draft.rightEnhancerWidth = calculateEnhancerWidth(rightEnhancerRef.current, draft.rightEnhancerWidth);
    });
  }, [startElement, calculateEnhancerWidth, endElement, setState]);

  ////////////////////////
  // Event handlers

  const handleOnBlur = useEventCallback<React.FocusEventHandler<HTMLInputElement>>(e => {
    setFocus(false);
    onBlur?.(e);
  });
  const handleOnFocus = useEventCallback<React.FocusEventHandler<HTMLInputElement>>(e => {
    setFocus(true);
    onFocus?.(e);
  });
  const handleOnKeyDown = useEventCallback<React.KeyboardEventHandler<HTMLInputElement>>(e => {
    if (enableClearOnEscape && isEscapeKeyClick(e.key)) {
      e.stopPropagation();
      if (trySetNativeValueAndDispatch(inputRef.current)) {
        onClear?.(e);
      }
    }
    onKeyDown?.(e);
  });
  const handleOnKeyUp = useEventCallback<React.KeyboardEventHandler<HTMLInputElement>>(e => {
    onKeyUp?.(e);
  });
  const handleOnKeyPress = useEventCallback<React.KeyboardEventHandler<HTMLInputElement>>(e => {
    onKeyPress?.(e);
  });
  const handleOnChange = useEventCallback<React.ChangeEventHandler<HTMLInputElement>>(e => {
    onChange?.(e);
  });

  ////////////////////////
  // Effects

  // Maybe focus input on first mount
  useEffect(() => {
    if (enableFocusOnMount) {
      setFocus(true);
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ////////////////////////
  // Render

  const renderEnhancer = (enhancer: InputEnhancer) => {
    return typeof enhancer === 'function' ? enhancer(sharedProps) : enhancer;
  };

  const { leftEnhancerWidth, rightEnhancerWidth } = state;

  return (
    <Root {...styles.Root} {...rootProps}>
      {startElement && (
        <StartElement $placement="left" ref={leftEnhancerRef} {...styles.EnhancerElement} {...startElementProps}>
          {renderEnhancer(startElement)}
        </StartElement>
      )}
      <InputEl
        as="input"
        aria-autocomplete={ariaAutoComplete}
        aria-describedby={ariaDescribedBy}
        aria-disabled={formControlProps.isDisabled}
        aria-errormessage={ariaErrorMessage}
        aria-invalid={formControlProps.isInvalid}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-readonly={isReadOnly}
        aria-required={formControlProps.isRequired}
        disabled={formControlProps.isDisabled}
        id={id}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onKeyDown={handleOnKeyDown}
        onKeyPress={handleOnKeyPress}
        onKeyUp={handleOnKeyUp}
        ref={forkedRefHandler}
        readOnly={isReadOnly}
        required={formControlProps.isRequired}
        style={{
          paddingLeft: startElement ? leftEnhancerWidth || 40 : undefined,
          paddingRight: endElement ? rightEnhancerWidth || 48 : undefined
        }}
        __css={styles.Input}
        {...restProps}
        {...sharedProps}
        {...inputProps}
      />
      {endElement && (
        <EndElement $placement="right" ref={rightEnhancerRef} {...styles.EnhancerElement} {...endElementProps}>
          {renderEnhancer(endElement)}
        </EndElement>
      )}
    </Root>
  );
});

InputV2.displayName = 'InputV2';
InputV2.defaultProps = inputDefaultProps;

export { InputV2 };
