import { config } from '@static/js/env.config';
import queryString from 'query-string';

export interface QueryRedirectURL {
  url: string;
  provider?: string;
  guests?: string;
  patform?: string;
  eventId?: string;
  userId?: string;
}

/**
 * Format a URL to be passed through the backend redirect
 * @param query
 * @returns
 */
export const formatRedirectURL = (query: Partial<QueryRedirectURL>) => {
  return queryString.stringifyUrl({
    url: `${config.redirectServiceUri}/redirect`,
    query: query
  });
};
