import { RoutesPaths } from '@shared/core';

export type RoutesNames = 'rsvp' | 'story' | 'tidbits' | 'schedule' | 'travel' | 'accommodations' | 'vip' | 'faq' | 'custom' | 'registry';

export const routePaths: RoutesPaths<RoutesNames> = {
  rsvp: {
    path: 'rsvp',
    goToPath: () => 'rsvp'
  },
  registry: {
    path: 'registry',
    goToPath: () => 'registrylinks'
  },
  story: {
    path: 'story',
    goToPath: () => 'story'
  },
  tidbits: {
    path: 'story',
    goToPath: () => 'story'
  },
  schedule: {
    path: 'schedule',
    goToPath: () => 'schedule'
  },
  travel: {
    path: 'travel',
    goToPath: () => 'travel'
  },
  accommodations: {
    path: 'accommodations',
    goToPath: () => 'accommodations'
  },
  custom: {
    path: 'website/page/:pageSlug',
    goToPath: (pageSlug: string) => `website/page/${pageSlug}`
  },
  vip: {
    path: 'vip',
    goToPath: () => 'vip'
  },
  faq: {
    path: 'faq',
    goToPath: () => 'faq'
  }
};
