import { GuestSiteRegistryFragment, GuestSiteRegistryItemFragment } from '@graphql/generated';
import { extractHostname } from '@shared/utils/extractHostname';

export type RegistryItemType = {
  photo: string;
  url: string;
  isRegistry: boolean;
  title: string;
};

export const getRegistryItems = ({ list, eventHandle }: { list: ReadonlyArray<GuestSiteRegistryFragment>; eventHandle: string }) => {
  const registryItems = list
    .reduce((items, registry) => {
      if (registry?.items && registry?.hasItems && registry.wantSync !== false) {
        return items.concat(registry?.items.filter(item => !!item?.productData?.photos[0]?.url));
      } else {
        return items;
      }
    }, [] as Array<GuestSiteRegistryItemFragment>)
    .sort((item, item2) => item.fpIndex - item2.fpIndex)
    .slice(0, 8)
    .map(item => {
      return {
        photo: item?.productData?.photos[0]?.url || '',
        url: `/${eventHandle}/registry`,
        isRegistry: false,
        title: item?.productData?.title || ''
      };
    });

  const externalRegistries = list
    .filter(registry => registry.url)
    .slice(0, 8 - registryItems.length)
    .map(item => {
      const title = item?.name ? item?.name : extractHostname(item?.url || '');
      return {
        photo: item.imageUrl || '',
        url: item.url || '',
        isRegistry: true,
        title
      };
    });
  return [...registryItems, ...externalRegistries];
};
