import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AskHostFormProps } from './AskHostForm';
import { useTranslation } from '@shared/core';
import { useDeliverRequestForPasswordMutation } from '@graphql/generated';
import { useEffect, useMemo } from 'react';
import { useToast } from '@withjoy/joykit';

interface AskHostFormFields
  extends Readonly<{
    name: string;
    email: string;
  }> {}

export function useAskHostFormController({ eventId, onClose }: AskHostFormProps) {
  const { toast } = useToast();
  const { t2 } = useTranslation('guestUnlock');
  const { title, subtitle, fullNameLabel, emailLabel, submitButtonText, required, validEmailWarning, namePlaceholder, emailPlaceholder } = t2('askHostForm');
  const [sendRequest, { loading }] = useDeliverRequestForPasswordMutation({
    onCompleted: () => {
      onClose();
    }
  });

  const formik = useFormik<AskHostFormFields>({
    initialValues: {
      name: '',
      email: ''
    },
    validationSchema: Yup.object<AskHostFormFields>({
      name: Yup.string().required(required),
      email: Yup.string().required(required).email(validEmailWarning)
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async ({ name, email }) => {
      try {
        await sendRequest({
          variables: {
            payload: { eventId, guest: { firstName: name, email } }
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  });

  const inputError = useMemo(() => formik.errors.email || formik.errors.name || '', [formik.errors.email, formik.errors.name]);

  useEffect(() => {
    if (inputError) {
      toast(inputError);
    }
  }, [inputError, toast]);

  return {
    formik,
    loading,
    title,
    subtitle,
    fullNameLabel,
    emailLabel,
    namePlaceholder,
    emailPlaceholder,
    submitButtonText,
    nameInputError: formik.errors.name,
    emailInputError: formik.errors.email
  };
}
