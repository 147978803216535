import React from 'react';
import { StyledColumnWrapper } from './';
import { useColumnsContext } from '../../Columns.context';
import { ResponsiveColumnValue } from '../../Columns.types';
import { createDisplayName } from '@withjoy/joykit/utils';
import { BoxProps } from '../../../Box';

export interface ColumnV2Props
  extends Readonly<{
      /**
       * @default 'div'
       */
      tagName?: keyof JSX.IntrinsicElements;
      // children?: React.ReactNode;

      /**
       * Specify the number of columns to span.
       *
       * A column's span is relative to the column count of the current breakpoint.
       *
       * Column count varies by viewport size:
       *  - [0, 480px), 1 column.
       *  - [480px and 840px), 4 columns.
       *  - [840px, 1280px), 8 columns.
       *  - [1280px, ∞), 12 columns.
       *
       * `span` is a responsive prop. It accepts both a single `ColumnWidth` value or an array of `ColumnWidth` values.
       *
       * - A singular `ColumnWidth` will specify a relative width per breakpoint.
       *   For example, column with `span={1}` will have a width of:
       *    - 25% of a 4 column grid.
       *    - 12.5% of a 8 column grid.
       *    - 8.333333333333334% of a 12 column grid
       *
       * - With an array of `ColumnWidth`, each value will specify the width for any viewport larger than or equal to
       *   the `sm` breakpoint. For example, a column with `span={[1, 2]}` will have widths of:
       *    - 25% of a 4 column grid.
       *    - 25% of a 8 column grid.
       *
       * Because of our `min-width` based media queries, we can omit larget breakpoint values if it remains unchanged.
       * For example, `span={[1,2]}` is equivalent to `span={[1,2,2]}`.
       *
       * If you specify a `span` value larger than the column count of the current breakpoint, the column will
       * span the max. For example, a column with a span of 12 will span 8 on medium and 4 on small.
       *
       * This shorthand syntax is a convenient way for adding responsive styles with a mobile first approach.
       *
       * **Note**:
       *  - There is only one column on viewports smaller than `480px`. A `<Column />` will take up the full width.
       */
      span?: ResponsiveColumnValue;
      offset?: ResponsiveColumnValue;
    }>,
    Pick<BoxProps, 'marginTop' | 'marginBottom' | 'marginY'>,
    HtmlProps<HTMLElement> {}

export const ColumnV2: React.FC<ColumnV2Props> = ({ children, tagName = 'div', ...restProps }) => {
  const context = useColumnsContext();
  const boxProps: BoxProps = { paddingX: [3, null, 4] }; // !context.containedUpstream && context.containedCurrent ? [3, null, 4] : [0, null, 5] };
  return (
    <StyledColumnWrapper as={tagName} {...restProps} {...context} {...boxProps}>
      {children}
    </StyledColumnWrapper>
  );
};

ColumnV2.displayName = createDisplayName('Column');
