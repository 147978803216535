/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { usePreventBodyScroll } from '@shared/core/scrolling';
import { ReactComponent as CloseSVG } from '@assets/icons/close.svg';
import { useKeycodeListener } from '@shared/utils/hooks/useKeycodeListener';
import { ButtonV2, styled } from '@withjoy/joykit';
import { withWindow } from '@shared/utils/withWindow';

const PortalContent = styled.div<{ backgroundColor?: string }>`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme, backgroundColor }) => (backgroundColor ? backgroundColor : theme.colors.white)};
`;

const ChildrenWrapper = styled.div`
  /* overflow should be delegated to children, allows for scrollRefs */
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// https://github.com/microsoft/TypeScript/issues/37597
// joykit's button comes from styled-components v4
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Close = styled(ButtonV2 as any)<{ onClick: React.MouseEventHandler }>`
  position: fixed;
  top: 40px;
  right: 44px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.white};
  & svg {
    margin-right: 8px;
    & path {
      fill: ${props => props.theme.colors.mono13};
    }
  }
  & > span > span {
    padding: 12px 0;
    display: flex;
    align-items: center;
  }
`;

export interface Props
  extends Readonly<{
    onCloseClick?: () => void;
    backgroundColor?: string;
    onBackdropClick?: () => void;
    onEscapeKeyDown?: () => void;
  }> {}

export const SimplePortal: React.FC<Props> = props => {
  const parentRef = useRef<null | HTMLDivElement>(null);
  const { children, onCloseClick, backgroundColor, onBackdropClick, onEscapeKeyDown } = props;

  return withWindow<React.ReactPortal | null>(() => {
    const portal = document.createElement('div');

    useKeycodeListener([27], () => {
      if (onEscapeKeyDown) {
        onEscapeKeyDown();
      }
    });

    usePreventBodyScroll();

    useEffect(() => {
      window.document.body.appendChild(portal);
      return () => {
        window.document.body.removeChild(portal);
      };
    }, [portal]);

    return createPortal(
      <PortalContent backgroundColor={backgroundColor} onClick={onBackdropClick}>
        <ChildrenWrapper ref={parentRef}>
          {children}
          {onCloseClick ? (
            <Close onClick={onCloseClick} intent="secondary" color="grey">
              <CloseSVG />
              <span>Close</span>
            </Close>
          ) : null}
        </ChildrenWrapper>
      </PortalContent>,
      portal
    );
  }, null);
};
