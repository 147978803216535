import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, ButtonV2, CloseButton, Divider, Flex, SpacingStack, TextV2, useToast } from '@withjoy/joykit';
import { closeButtonOverrides } from '../ResponsiveDialog/ResponsiveDialog.styles';
import { Cell, FocalPoint, HighlightMask, StyledActionButton } from './FocusPointEditor.styles';
import { useTranslation } from '@shared/core';
import { Camera, ChevronLeftSquare, Trash, WarningTriangle } from '@withjoy/joykit/icons';
import { FocusPointEditorProps, PagePhotoSettingsProps, PhotoAlignmentX, PhotoAlignmentY } from './FocusPointEditor.types';
import { pxToRem } from '@withjoy/joykit/theme';
import {
  EventPageType,
  PhotoXPosition,
  PhotoYPosition,
  SetAssetPayload,
  useGetPageIdsQuery,
  useRemoveEventPagePhotoMutation,
  useSetEventPageAssetMutation,
  useSetPhotoAlignmentMutation,
  useUpdatePageContainerMutation,
  useUploadPhotoFromUrlMutation
} from '@graphql/generated';
import { useTransition } from 'react-spring';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useFilestack } from '@shared/utils/filestack';
import { CheckIcon } from '@assets/icons';
import { TempSpinner } from '../TempSpinner';
import globalWindow from '@shared/core/globals';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useFocusPointEditorTelemetry } from './FocusPointEditor.telemetry';

const PHOTO_TYPE_SUPPORT_MAPPER = {
  [EventPageType.tidbits]: EventPageType.story,
  [EventPageType.app]: EventPageType.welcome,
  [EventPageType.custom]: EventPageType.welcome
};

const YPositions = [PhotoYPosition.top, PhotoYPosition.center, PhotoYPosition.bottom];
const XPositions = [PhotoXPosition.left, PhotoXPosition.center, PhotoXPosition.right];

enum Orientation {
  LANDSCAPE,
  PORTRAIT,
  SQUARE
}

const RATIOS_ARRAY = ['1:1', '2:3', '3:2', '3:4', '4:3', '4:5', '5:4', '11:10', '10:11'];

const SIZE_SETTING: Record<Orientation, { container: [number, number]; [key: string]: [number, number] }> = {
  [Orientation.LANDSCAPE]: {
    container: [400, 400],
    '3:2': [400, 266], // [width, height]
    '4:3': [400, 300],
    '5:4': [400, 320]
  },
  [Orientation.PORTRAIT]: {
    container: [400, 400],
    '2:3': [266, 400],
    '3:4': [300, 400],
    '4:5': [320, 400]
  },
  [Orientation.SQUARE]: {
    container: [400, 440],
    '1:1': [400, 400],
    '11:10': [440, 400],
    '10:11': [400, 440]
  }
};

const closest = (ratio: number, ratiosArray: number[]) => {
  let curr = ratiosArray[0];
  let diff = Math.abs(ratio - curr);
  for (let val = 0; val < ratiosArray.length; val++) {
    const newdiff = Math.abs(ratio - ratiosArray[val]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = ratiosArray[val];
    }
  }
  return curr;
};

const ratioFloatsArray = RATIOS_ARRAY.map(item => {
  const temp = item.split(':');
  return parseFloat(temp[0]) / parseFloat(temp[1]);
});

const calculateAspectRatio = (width: number, height: number) => {
  const currentRatioFloat = width / height;
  const matchedRatioFloat = closest(currentRatioFloat, ratioFloatsArray);
  const matchedRatio = RATIOS_ARRAY[ratioFloatsArray.indexOf(matchedRatioFloat)];
  let orientation;
  if (['1:1', '11:10', '10:11'].includes(matchedRatio)) {
    orientation = Orientation.SQUARE;
  } else if (matchedRatioFloat > 1) {
    orientation = Orientation.LANDSCAPE;
  } else {
    orientation = Orientation.PORTRAIT;
  }

  // Get the size settings based on the orientation
  const size = SIZE_SETTING[orientation];
  const imageSize = [...size[matchedRatio]];
  const containerSize = [...size.container];

  const windowWidth = globalWindow.innerWidth;
  const windowHeight = globalWindow.innerHeight;

  const IMG_PADDINNG_X = 40;
  const DESKTOP_THRESHOLD_HEIGHT = 332;

  // Adjust the image size for mobile devices
  if (windowWidth && windowWidth <= 768) {
    if (imageSize[0] + IMG_PADDINNG_X > windowWidth) {
      imageSize[0] = windowWidth - IMG_PADDINNG_X;
      imageSize[1] = imageSize[0] / matchedRatioFloat;
    }
  }
  // Adjust the image size for desktop devices
  else if (windowWidth && windowWidth > 768 && windowHeight && imageSize[1] + DESKTOP_THRESHOLD_HEIGHT > windowHeight) {
    imageSize[1] = windowHeight - DESKTOP_THRESHOLD_HEIGHT;
    imageSize[0] = imageSize[1] * matchedRatioFloat;
    containerSize[0] = imageSize[0];
    containerSize[1] = imageSize[1];
  }

  return {
    orientation,
    aspectRatio: matchedRatio,
    size: {
      container: containerSize,
      image: imageSize
    }
  };
};

const findMatchingPageType = (pageType: EventPageType | undefined) => {
  const pageTypeToUpdate =
    pageType && PHOTO_TYPE_SUPPORT_MAPPER.hasOwnProperty(pageType) ? PHOTO_TYPE_SUPPORT_MAPPER[pageType as keyof typeof PHOTO_TYPE_SUPPORT_MAPPER] : pageType || '';
  return pageTypeToUpdate;
};

const Mask: FC<{ isActive: boolean }> = ({ isActive }) => {
  const transition = useTransition(isActive, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 400 }
  });
  return <>{transition.map(({ item, key, props: style }) => item && <HighlightMask key={key} style={style} />)}</>;
};

const FocusPointEditor: FC<FocusPointEditorProps> = props => {
  const {
    isOpen,
    handleOnClose,
    handleOnCancel,
    imageData,
    pageType,
    pageId,
    openFilePicker,
    eventId,
    isLoadingPhoto,
    handleSaveUploadedPhoto,
    isUpdatingEventPhoto,
    showNavigateBack,
    onFocusUpdateSuccess,
    onPhotoDeleteSuccess,
    uploadedImageData,
    onFocusPointChange,
    telemetryCategory,
    pageIdsData,
    allowPagePhoto
  } = props;

  const alignX = (imageData?.layout.alignX as PhotoAlignmentX) || 'center';
  const alignY = (imageData?.layout.alignY as PhotoAlignmentY) || 'center';

  const [focusPoint, setFocusPoint] = useState<[PhotoXPosition, PhotoYPosition]>([PhotoXPosition[alignX], PhotoYPosition[alignY]]);
  const [aspectRatio, setAspectRatio] = useState({
    orientation: Orientation.SQUARE,
    aspectRatio: '1:1',
    size: {
      container: [400, 400],
      image: [400, 400]
    }
  });

  useEffect(() => {
    const checkModalPosition = () => {
      setTimeout(() => {
        setAspectRatio(calculateAspectRatio(imageData?.width || 1, imageData?.height || 1));
      }, 250);
    };
    window.addEventListener('resize', checkModalPosition);
    checkModalPosition();
    setAspectRatio(calculateAspectRatio(imageData?.width || 1, imageData?.height || 1));
    return () => window.removeEventListener('resize', checkModalPosition);
  }, [isOpen, imageData?.width, imageData?.height]);

  const { toast } = useToast();
  const { t2 } = useTranslation('joykit');
  const dialogTrans = t2('focusPointDialog');

  const telemetry = useFocusPointEditorTelemetry();

  const [uploadPhotoFromUrl, { loading: waitingUploadPhoto }] = useUploadPhotoFromUrlMutation();
  const [updatePageContainer, { loading: waitingForUpdatePageContainer }] = useUpdatePageContainerMutation({});
  const [updatePhotoAlignment, { loading: isUpdatingEventPhotoAlignment }] = useSetPhotoAlignmentMutation({
    onCompleted: () => toast(`${dialogTrans.photoSettingsUpdateSuccess}`, { icon: <CheckIcon /> }),
    onError: () => toast(`${dialogTrans.photoSettingsUpdateFailure}`, { icon: <WarningTriangle /> })
  });

  const isUpdatingPhotoAlignment = isUpdatingEventPhotoAlignment || waitingForUpdatePageContainer || waitingUploadPhoto;

  const [removeEventPagePhoto, { loading: isDeletingPagePhoto }] = useRemoveEventPagePhotoMutation({
    onCompleted: () => toast(`${dialogTrans.photoDeleteSuccess}`, { icon: <CheckIcon /> }),
    onError: () => toast(`${dialogTrans.photoDeleteError}`, { icon: <WarningTriangle /> })
  });

  const handleFocusPointChange = (x: PhotoXPosition, y: PhotoYPosition) => () => {
    setFocusPoint([x, y]);
    onFocusPointChange && onFocusPointChange(x, y);
  };

  const handleChangePhoto = () => {
    telemetry.photoEditorChangePhotoClicked(telemetryCategory);
    openFilePicker();
  };

  const resetFocusPointAndCancel = () => {
    telemetry.photoEditorOpened(telemetryCategory, 'closed');
    setFocusPoint([PhotoXPosition[alignX], PhotoYPosition[alignY]]);
    handleOnCancel();
  };

  const handleSaveFocusPoint = async () => {
    await handleSaveUploadedPhoto();
    // Custom and Normal page photo have different end points, we have to maintain both in global level
    if (props.pageType === EventPageType.custom) {
      let mainPhotoId: string | null | undefined = null;
      if (uploadedImageData && imageData?.url && props.pageId && uploadedImageData.assetId) {
        const { data } = await uploadPhotoFromUrl({
          variables: {
            payload: {
              assetId: uploadedImageData.assetId,
              containerId: props.pageId,
              url: imageData.url
            }
          }
        });
        mainPhotoId = data?.uploadPhotoFromUrl?.id;
        telemetry.pagePhotoUpdated(props.telemetryCategory, 'Update', 'succeeded', { pageType: props.pageSlug, actualPageType: props.pageType });
      }
      await updatePageContainer({
        variables: {
          payload: {
            id: props.pageId ?? '',
            ...(mainPhotoId && { mainPhotoId }),
            ...(focusPoint && { mainPhotoAlignment: { x: focusPoint[0], y: focusPoint[1] } })
          }
        },
        refetchQueries: ['PageContainerBySlug', 'WebsiteInfo'],
        awaitRefetchQueries: true
      }).then(info => {
        if (!info.errors) {
          telemetry.pagePhotoUpdated(telemetryCategory, 'FocusChanged', 'succeeded', {
            previousFocus: [(imageData?.layout.alignX as PhotoXPosition) || PhotoXPosition.center, (imageData?.layout.alignY as PhotoYPosition) || PhotoYPosition.center],
            currentFocus: focusPoint
          });
          handleOnCancel();
          const mainPhoto = info.data?.updatePageContainer.mainPhoto;
          const mainPhotoAlignment = info.data?.updatePageContainer.mainPhotoAlignment;
          if (onFocusUpdateSuccess && mainPhoto && mainPhotoAlignment) {
            const assetId = uploadedImageData?.assetId || imageData?.assetId || mainPhoto.id;
            onFocusUpdateSuccess(assetId, {
              id: mainPhotoId || mainPhoto.id,
              url: mainPhoto.url,
              assetId,
              height: mainPhoto.height,
              width: mainPhoto.width,
              layout: {
                alignX: mainPhotoAlignment?.x,
                alignY: mainPhotoAlignment?.y
              }
            });
          }
        }
      });
    } else {
      telemetry.pagePhotoUpdated(telemetryCategory, 'FocusChanged', 'succeeded', {
        previousFocus: [(imageData?.layout.alignX as PhotoXPosition) || PhotoXPosition.center, (imageData?.layout.alignY as PhotoYPosition) || PhotoYPosition.center],
        currentFocus: focusPoint
      });
      const pageTypeToUse = findMatchingPageType(pageType) || pageType;
      updatePhotoAlignment({
        variables: {
          eventId: eventId || '',
          payload: {
            page: pageTypeToUse,
            isMobile: false,
            alignment: {
              x: focusPoint[0],
              y: focusPoint[1]
            }
          }
        }
      }).then(info => {
        if (!info.errors) {
          handleOnCancel();
          if (onFocusUpdateSuccess && info.data?.setPhotoAlignment) {
            onFocusUpdateSuccess(uploadedImageData?.assetId || info.data.setPhotoAlignment.assetId, info.data.setPhotoAlignment);
          }
        }
      });
    }
  };

  const handleRemovePhoto = () => {
    // Custom and Normal page photo have different end points, we have to maintain both in global level
    if (props.pageType === EventPageType.custom) {
      const pageIdToUse = props.pageId;
      updatePageContainer({
        variables: {
          payload: {
            id: props.pageId ?? '',
            mainPhotoId: null,
            mainPhotoAlignment: null
          }
        },
        refetchQueries: ['PageContainerBySlug', 'WebsiteInfo'],
        awaitRefetchQueries: true
      }).then(info => {
        if (!info.errors) {
          telemetry.pagePhotoUpdated(telemetryCategory, 'Delete', 'succeeded', { pageType: props.pageSlug, actualPageType: pageType || '' });
          handleOnCancel();
          onPhotoDeleteSuccess && onPhotoDeleteSuccess(pageIdToUse || '', EventPageType.custom, pageId || '', pageType);
        }
      });
    } else {
      const pageTypeToUpdate = findMatchingPageType(props.pageType);
      const pageIdToUse = pageIdsData.find(page => page.type === pageTypeToUpdate)?.id || props.pageId;
      removeEventPagePhoto({
        variables: {
          eventPageId: pageIdToUse || ''
        },
        refetchQueries: ['WebsiteInfo'],
        awaitRefetchQueries: true
      }).then(info => {
        if (!info.errors) {
          telemetry.pagePhotoUpdated(telemetryCategory, 'Delete', 'succeeded', { pageType: pageTypeToUpdate, actualPageType: pageType || '' });
          handleOnCancel();
          onPhotoDeleteSuccess && onPhotoDeleteSuccess(pageIdToUse || '', pageTypeToUpdate, pageId || '', pageType);
        }
      });
    }
  };

  const handleCloseClick = () => {
    telemetry.photoEditorOpened(telemetryCategory, 'closed');
    handleOnClose();
  };

  return (
    <>
      <SpacingStack spacing={7}>
        <Flex flexDirection="row" justifyContent="space-between" alignItems="center" gap={6}>
          <Flex
            borderRadius={48}
            border="1px solid"
            borderColor="mono3"
            background="white"
            width={8}
            height={8}
            padding={2}
            justifyContent="center"
            alignItems="center"
            gap={3}
            marginLeft="-16px"
            marginTop="-16px"
            cursor="pointer"
            zIndex={999}
            onClick={resetFocusPointAndCancel}
            _hover={
              allowPagePhoto
                ? {
                    backgroundColor: 'mono3',
                    borderColor: 'mono5'
                  }
                : {}
            }
            _active={
              allowPagePhoto
                ? {
                    backgroundColor: 'mono5',
                    borderColor: 'mono5'
                  }
                : {}
            }
            visibility={showNavigateBack ? 'visible' : 'hidden'}
          >
            <ChevronLeftSquare size={16} />
          </Flex>
          <TextV2 as="h3" typographyVariant={['hed3', 'hed6']} textAlign="center">
            {dialogTrans.title}
          </TextV2>
          <CloseButton
            overrides={{
              Root: { props: { ...closeButtonOverrides.Root?.props, marginRight: '-16px', marginTop: '-16px' } },
              Icon: { props: { ...closeButtonOverrides.Icon?.props } }
            }}
            onClick={handleCloseClick}
            aria-label={'close dialog'}
            disabled={isUpdatingPhotoAlignment || isUpdatingEventPhoto}
          />
        </Flex>

        <Flex flexDirection="column" alignItems="center" gap={6}>
          <Flex
            flexDirection="column"
            position="relative"
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            width="100%"
            aspectRatio={aspectRatio.aspectRatio.replace(':', '/')}
            maxWidth={aspectRatio.size.container[0]}
            maxHeight={aspectRatio.size.container[1]}
          >
            <Box
              background={imageData?.url ? `url("${imageData.url}") lightgray 50%` : 'lightgray 50%'}
              backgroundSize="cover"
              backgroundPosition="center"
              position="relative"
              backgroundRepeat="no-repeat"
              width="100%"
              height="100%"
              borderRadius={3}
              overflow="hidden"
              maxWidth={aspectRatio.size.image[0]}
              maxHeight={aspectRatio.size.image[1]}
              __css={{ ['.loading > div']: { width: pxToRem(40), height: pxToRem(40) } }}
            >
              {isLoadingPhoto && <TempSpinner position={'absolute'} className="loading" />}
              <Box
                display="grid"
                justifyContent="center"
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                gridTemplateColumns="repeat(3, 1fr)"
                background="rgba(0, 0, 0, 0.48)"
                visibility={isLoadingPhoto ? 'hidden' : 'visible'}
              >
                {YPositions.map(y =>
                  XPositions.map(x => (
                    <Cell
                      key={`align-${y}-${x}`}
                      justifyContent="center"
                      alignItems="center"
                      cursor="pointer"
                      isActive={focusPoint[0] === x && focusPoint[1] === y}
                      onClick={handleFocusPointChange(PhotoXPosition[x], PhotoYPosition[y])}
                    >
                      <FocalPoint justifyContent="center" alignItems="center" backgroundColor="white" width={4} height={4} borderRadius="full" border="0 none" />
                      <Mask isActive={focusPoint[0] === x && focusPoint[1] === y} />
                    </Cell>
                  ))
                )}
              </Box>
            </Box>
          </Flex>
          <TextV2 typographyVariant="label2" textAlign="center" color="mono10">
            {dialogTrans.note}
          </TextV2>
          <Divider />
          <Flex flexDirection="row" gap={3} alignItems="flex-start" width="100%">
            <ButtonV2
              variant="ghost"
              size="lg"
              shape="square"
              intent="destructive"
              startIcon={Trash}
              width="100%"
              onClick={handleRemovePhoto}
              loading={isDeletingPagePhoto}
              disabled={isDeletingPagePhoto || isLoadingPhoto}
            >
              {dialogTrans.delete}
            </ButtonV2>
            <ButtonV2 variant="ghost" size="lg" shape="square" intent="neutral" startIcon={Camera} width="100%" onClick={handleChangePhoto} disabled={isLoadingPhoto}>
              {dialogTrans.replacePhoto}
            </ButtonV2>
          </Flex>
        </Flex>
      </SpacingStack>
      <Flex flexDirection="column" width="100%" gap={5} marginTop={pxToRem(56)}>
        <StyledActionButton
          intent="neutral"
          onClick={handleSaveFocusPoint}
          disabled={isUpdatingPhotoAlignment || isUpdatingEventPhoto || isLoadingPhoto}
          loading={isUpdatingPhotoAlignment || isUpdatingEventPhoto}
        >
          {dialogTrans.save}
        </StyledActionButton>
        <StyledActionButton
          color="mono14"
          variant="ghost"
          backgroundColor="transparent"
          onClick={resetFocusPointAndCancel}
          disabled={isUpdatingPhotoAlignment || isUpdatingEventPhoto}
        >
          {dialogTrans.cancel}
        </StyledActionButton>
      </Flex>
    </>
  );
};

export const PagePhotoSettings: FC<PagePhotoSettingsProps> = props => {
  const [isLoadingPhoto, setLoadingPhoto] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.imageData?.url);
  const [uploadedImageData, setUploadedImageData] = useState<SetAssetPayload>();

  const isOpenFileStack = useRef(false);
  const isImageUploaded = useRef(!!props.imageData?.url);

  const { eventInfo, eventHandle } = useEventInfo();
  const telemetry = useFocusPointEditorTelemetry();

  const { data: pageIds } = useGetPageIdsQuery({ variables: { name: eventHandle || '' }, batchMode: 'fast', skip: !eventHandle });

  const [setEventPageAssetMutation, { loading: isUpdatingEventPhoto }] = useSetEventPageAssetMutation();

  const { open: openFilePicker, getPhotoPreviewUrl } = useFilestack({
    containerId: eventInfo?.eventFirebaseId || '',
    accept: 'image/*',
    onUploadDone: async (res): Promise<void> => {
      setLoadingPhoto(true);
      const { filename, mimetype, size, url } = res.filesUploaded[0];
      const previewUrl = await getPhotoPreviewUrl({ url });
      isImageUploaded.current = !!previewUrl;
      const loadImageDimensions = (imageUrl: string): Promise<{ width: number; height: number }> =>
        new Promise(resolve => {
          const image = new Image();

          image.onload = () => {
            const height = image.height;
            const width = image.width;
            resolve({ width, height });
          };

          image.src = imageUrl;
        });

      setImageUrl(previewUrl);
      const dimensions = await loadImageDimensions(previewUrl);
      setUploadedImageData({ assetId: filename, mimeType: mimetype, fileSize: size, width: dimensions.width, height: dimensions.height });
      setLoadingPhoto(false);
    },
    onClose: () => {
      if (!isLoadingPhoto) isOpenFileStack.current = false;
      setTimeout(() => {
        props.onFileStackClose && props.onFileStackClose(!!isImageUploaded.current);
      }, 500);
    },
    maxFiles: 1
  });

  useEffect(() => {
    if (props.isOpen) {
      telemetry.photoEditorOpened(props.telemetryCategory, 'opened');
    }
    if (props.isOpen && !props.imageData?.url && !isOpenFileStack.current) {
      openFilePicker();
      isOpenFileStack.current = true;
    } else if (!props.isOpen) {
      isOpenFileStack.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const handleSaveUploadedPhoto = async () => {
    if (uploadedImageData) {
      // Custom and Normal page photo have different end points, we have to maintain both in global level
      if (props.pageType !== EventPageType.custom) {
        const pageTypeToUpdate = findMatchingPageType(props.pageType);
        const pageIdToUse = pageIds?.eventByName?.pages.find(page => page.type === pageTypeToUpdate)?.id || props.pageId;
        telemetry.pagePhotoUpdated(props.telemetryCategory, 'Update', 'succeeded', { pageType: pageTypeToUpdate, actualPageType: props.pageType });
        await setEventPageAssetMutation({
          variables: {
            id: pageIdToUse || '',
            payload: uploadedImageData
          },
          refetchQueries: ['WebsiteInfo'],
          awaitRefetchQueries: true
        });
      }
    }
  };

  const onCancelPhotoFocus = useEventCallback(() => {
    setImageUrl('');
    setUploadedImageData(undefined);
    props.handleOnCancel();
  });

  return props.imageData?.url || imageUrl ? (
    <FocusPointEditor
      {...props}
      imageData={
        imageUrl && uploadedImageData
          ? {
              id: 'id',
              height: uploadedImageData.height,
              width: uploadedImageData.width,
              url: imageUrl,
              layout: {
                alignX: props.imageData?.layout.alignX || 'center',
                alignY: props.imageData?.layout.alignY || 'center'
              }
            }
          : props.imageData
      }
      handleOnCancel={onCancelPhotoFocus}
      openFilePicker={openFilePicker}
      eventId={eventInfo?.eventId}
      isLoadingPhoto={isLoadingPhoto}
      handleSaveUploadedPhoto={handleSaveUploadedPhoto}
      isUpdatingEventPhoto={isUpdatingEventPhoto}
      uploadedImageData={uploadedImageData}
      pageIdsData={pageIds?.eventByName?.pages || []}
    />
  ) : (
    <Flex height={pxToRem(300)}>
      <TempSpinner />
    </Flex>
  );
};

FocusPointEditor.displayName = 'FocusPointEditor';
