import { useTranslation } from '@shared/core/i18n';
import { VipFragment } from '@graphql/generated';

export type WeddingPartyListTranslations = Readonly<{
  title: string;
  ariaLabel: string;
}>;

export const useWeddingPartyListTranslations = () => {
  // ==================
  // Set up
  // ==================

  const { t } = useTranslation('weddingParty');

  // ==================
  // Translation
  // ==================
  // Define translation strings

  // Some translation files may contain (deeply) nested properties.
  // Our localization packages provides access to strongly typed translation files.
  // Given a namespace, you can see the shape of the translation file by hovering over the `t` function.
  //
  // The `t` function accepts a number of string path arguments. The TSC compiler will use the keys to
  // traverse the translation object, and complain with an invalid key

  const titles = t('titles'); // The `titles` key is an object with nested translations
  // Calling `t('titles')` effectively grabs that branch for ease of use.
  const titleMap = {
    Bride: titles.Bride(),
    Groom: titles.Groom(),
    Bridesmaid: titles.Bridesmaid(),
    Groomsman: titles.Groomsman({ name: 'Interpolated name' }), // The translate function accepts an optional options arg.
    'Mother In Law': titles.motherInLaw()
  };

  // The previous code can alternatively be written as:
  // NOTE: The following `t` function calls are on their own line purely as an example - it can be on one line
  // const bride = t('titles', 'Bride');
  // const groom = t('titles', 'Groom');
  // const bridesmaid = t('titles', 'Bridesmaid');
  // const groomsman = t('titles', 'Groomsman');
  // const motherInLaw = t('titles', 'Mother In Law');

  // Instead of manually invoking the translation function, you can automatically translate an entire branch.
  // `t2` is *currently* intended for branches that do not require options to be passed to the underlying translate function.
  // NOTE: `t2` will invoke each translate function and does NOT accept/pass an options arg.
  //        This capability will be added in at a later.
  // const translatedTitles = t2('titles');
  // translatedTitles.Bride;
  // translatedTitles.Groom;

  const getItemAriaLabel = (title: string, alias: Maybe<string>) => {
    const aliasOrTitle = alias || title;
    // 1. Provide path component (`learnAbout`) to get the translation function
    // 2. Invoke the translation function with any optons
    return t('learnAbout')({ name: aliasOrTitle });
  };

  const getItemTranslations = (vip: VipFragment) => {
    // Return a Joy translated title or default to the user provided title
    const title = titleMap[vip.title as keyof typeof titleMap] || vip.title;
    const ariaLabel = getItemAriaLabel(title, vip.alias);

    return {
      title,
      ariaLabel
    };
  };

  // ==================
  // Return
  // ==================

  return {
    getItemTranslations
  };
};
