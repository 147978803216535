import React, { useEffect, useState } from 'react';
import FindInviteSuccessForm from '@apps/guest/packages/layout-engine/components/UnlockDialog/FindInviteSuccessForm/FindInviteSuccessForm';
import { VerifyEmailForm } from '@apps/guest/packages/layout-engine/components/UnlockDialog/VerifyEmailForm';
import { FindInviteForm } from '@apps/guest/packages/layout-engine/components/UnlockDialog/FindInviteForm';
import UnlockSuccess from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnclockSuccess/UnlockSuccess';
import { AskHostForm } from '@apps/guest/packages/layout-engine/components/UnlockDialog/AskHostForm';
import { AskHostSuccessForm } from '@apps/guest/packages/layout-engine/components/UnlockDialog/AskHostSuccessForm';
import { UnlockForm } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockForm';
import { GuestTypes } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialog';
import { ManageUnlockDialogFormsProps } from '@apps/guest/packages/layout-engine/components/UnlockDialog/ManageUnlockDialogForms/ManageUnlockDialogForms.types';

export const ManageUnlockDialogForms: React.FC<ManageUnlockDialogFormsProps> = ({ unlockData, onDialogClose }) => {
  const { eventId, hasUnlocked, setShowBackButton, onBackButtonClick, shouldDisplayFindInvite } = unlockData ?? {};
  const [showUnlockSuccess, setShowUnlockSuccess] = useState<boolean>(false);
  const [showAskHost, setShowAskHost] = useState<boolean>(false);
  const [showAskHostSuccess, setShowAskHostSuccess] = useState<boolean>(false);
  const [guest, setGuest] = useState<GuestTypes>({ firstName: '', lastName: '', personIdTag: '' });
  const [guestsFound, setGuestsFound] = useState<number>(0);

  // Manage onClick Dialog Back button
  useEffect(() => {
    onBackButtonClick(() => {
      setShowAskHost(false);
      setGuestsFound(0);
    });
  }, [onBackButtonClick]);

  // Manage Dialog Back button conditions
  useEffect(() => {
    setShowBackButton(false);
    if (guestsFound > 1 && !guest.personIdTag) setShowBackButton(true);
    if (showAskHost) setShowBackButton(true);
  }, [guest.personIdTag, guestsFound, setShowBackButton, showAskHost]);

  // CB Only show UnlockSuccess if we don't have to display FindInvite
  const unlockSuccessHandle = () => {
    if (!shouldDisplayFindInvite) setShowUnlockSuccess(true);
  };

  // CB Only works if we don't have to display FindInvite
  const hideUnlockSuccessHandle = () => {
    if (!shouldDisplayFindInvite) {
      onDialogClose();
    }
  };

  // If we have a personIdTag it means that we have already unlocked the invitation
  if (guest.personIdTag) {
    return <FindInviteSuccessForm eventId={eventId} guest={guest} onDialogClose={onDialogClose} />;
  }

  // If we have more thant 1 guest found, it means that we have to verify the email.
  if (guestsFound > 1) {
    return <VerifyEmailForm eventId={eventId} guest={guest} setGuest={setGuest} />;
  }

  // Show the invitation form
  if (shouldDisplayFindInvite && hasUnlocked) {
    return <FindInviteForm eventId={eventId} setGuestsFound={setGuestsFound} setGuest={setGuest} />;
  }

  // If we have the event unlocked show success
  if (hasUnlocked && showUnlockSuccess) {
    return <UnlockSuccess onHide={hideUnlockSuccessHandle} />;
  }

  // showAskHost Section
  if (showAskHost) {
    const onCloseHandler = () => {
      setShowAskHost(false);
      setShowAskHostSuccess(true);
    };
    return <AskHostForm eventId={eventId} onClose={onCloseHandler} />;
  }

  if (showAskHostSuccess) {
    return <AskHostSuccessForm onClose={onDialogClose} />;
  }

  // Show password form to unlock the event
  return <UnlockForm eventId={eventId} onUnlockSuccess={unlockSuccessHandle} onShowAskHost={() => setShowAskHost(true)} />;
};
