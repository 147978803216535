import { animationTransition } from '@shared/utils/animationTransition';
import { StyleSystemProps } from '../Box';

export const VARIANT_STYLES: Record<'solid' | 'outline' | 'ghostShadow', StyleSystemProps> = {
  solid: {
    height: '56px',
    minWidth: '56px',
    backgroundColor: 'mono14',
    color: 'white',
    paddingX: 6,
    _hover: {
      backgroundColor: 'mono13'
    },
    _active: {
      backgroundColor: 'mono14'
    }
  },
  outline: {
    height: '56px',
    minWidth: '56px',
    backgroundColor: 'white',
    color: 'mono14',
    borderWidth: '2px',
    borderColor: 'mono5',
    paddingX: 6,
    filter: 'drop-shadow(0px 7px 27px rgba(44, 41, 37, 0.06)) drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.07))',
    _hover: {
      backgroundColor: 'mono3',
      color: 'mono12',
      borderColor: 'mono6'
    },
    _active: {
      backgroundColor: 'mono5',
      color: 'mono14',
      borderColor: 'mono7'
    }
  },
  ghostShadow: {
    height: '56px',
    minWidth: '56px',
    borderColor: 'none',
    backgroundColor: 'white',
    transition: animationTransition('backgroundColor', 'color', 'box-shadow'),
    boxShadow: 'rgba(44, 41, 37, 0.03) 0px 10px 60px, rgba(0, 0, 0, 0.03) 0px 3px 8px,rgba(0, 0, 0, 0.04) 0px 9px 36px',
    _hover: {
      backgroundColor: 'mono2',
      color: 'linkHover',
      boxShadow: '0px 9px 36px 0px rgba(0, 0, 0, 0.14), 0px 10px 60px 0px rgba(44, 41, 37, 0.12)',
      _disabled: {
        backgroundColor: 'white',
        color: 'mono8',
        boxShadow: 'rgba(44, 41, 37, 0.03) 0px 10px 60px, rgba(0, 0, 0, 0.03) 0px 3px 8px, rgba(0, 0, 0, 0.04) 0px 9px 36px'
      }
    },
    _active: {
      backgroundColor: 'mono3',
      color: 'linkPress',
      boxShadow: '0px 1px 3px -1px rgba(0, 0, 0, 0.3), 0px 1px 5px -1px rgba(44, 41, 37, 0.25)',
      _disabled: {
        backgroundColor: 'white',
        color: 'mono8',
        boxShadow: 'rgba(44, 41, 37, 0.03) 0px 10px 60px, rgba(0, 0, 0, 0.03) 0px 3px 8px, rgba(0, 0, 0, 0.04) 0px 9px 36px'
      }
    },
    _disabled: {
      backgroundColor: 'white',
      color: 'mono8',
      boxShadow: 'rgba(44, 41, 37, 0.03) 0px 10px 60px, rgba(0, 0, 0, 0.03) 0px 3px 8px, rgba(0, 0, 0, 0.04) 0px 9px 36px'
    }
  }
};
