/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Note',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M5.55 4.567a.55.55 0 0 0 0 1.1h4.9a.55.55 0 1 0 0-1.1h-4.9zM5 7.783a.55.55 0 0 1 .55-.55h4.9a.55.55 0 1 1 0 1.1h-4.9a.55.55 0 0 1-.55-.55zM5.55 9.9a.55.55 0 0 0 0 1.1h2.4a.55.55 0 0 0 0-1.1h-2.4z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            d="M3.917 1.45a1.8 1.8 0 0 0-1.8 1.8v9.5a1.8 1.8 0 0 0 1.8 1.8H9.84a.55.55 0 0 0 .446-.228l3.399-3.4a.55.55 0 0 0 .198-.423V3.25a1.8 1.8 0 0 0-1.8-1.8H3.917zm8.336 9.35l-2.122 2.122V11.5a.7.7 0 0 1 .7-.7h1.422zm.531-1.1V3.25a.7.7 0 0 0-.7-.7H3.917a.7.7 0 0 0-.7.7v9.5a.7.7 0 0 0 .7.7H9.03V11.5a1.8 1.8 0 0 1 1.8-1.8h1.953z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M8.1 7.3a.6.6 0 0 0 0 1.2h7.8a.6.6 0 0 0 0-1.2H8.1zm-.6 4.35a.6.6 0 0 1 .6-.6h7.8a.6.6 0 0 1 0 1.2H8.1a.6.6 0 0 1-.6-.6zm.6 3.15a.6.6 0 0 0 0 1.2h4.3a.6.6 0 0 0 0-1.2H8.1z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            d="M5.875 2.4C4.508 2.4 3.4 3.508 3.4 4.875v14.25c0 1.367 1.108 2.475 2.475 2.475H15.5h.019.012c.07-.003.137-.019.199-.045a.6.6 0 0 0 .188-.123l.008-.008 4.498-4.498.008-.008a.6.6 0 0 0 .169-.417V4.875c0-1.367-1.108-2.475-2.475-2.475H5.875zm13.176 14.2L15.6 20.052v-2.177c0-.704.571-1.275 1.275-1.275h2.177zm.348-1.2V4.875c0-.704-.571-1.275-1.275-1.275H5.875c-.704 0-1.275.571-1.275 1.275v14.25c0 .704.571 1.275 1.275 1.275H14.4v-2.525c0-1.367 1.108-2.475 2.475-2.475H19.4z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M10 10.733a.6.6 0 0 1 .6-.6h10.8a.6.6 0 0 1 0 1.2H10.6a.6.6 0 0 1-.6-.6zm0 3.334a.6.6 0 0 1 .6-.6h10.8a.6.6 0 1 1 0 1.2H10.6a.6.6 0 0 1-.6-.6zm.6 2.733a.6.6 0 1 0 0 1.2h10.8a.6.6 0 0 0 0-1.2H10.6zm-.6 3.933a.6.6 0 0 1 .6-.6h6.133a.6.6 0 0 1 0 1.2H10.6a.6.6 0 0 1-.6-.6z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            d="M7.833 3.4a3.1 3.1 0 0 0-3.1 3.1v19a3.1 3.1 0 0 0 3.1 3.1H22a.6.6 0 0 0 .434-.186l4.646-4.646a.6.6 0 0 0 .186-.434V6.5a3.1 3.1 0 0 0-3.1-3.1H7.833zm18.051 19.867l-3.951 3.951v-2.051a1.9 1.9 0 0 1 1.9-1.9h2.051zm.182-1.2V6.5a1.9 1.9 0 0 0-1.9-1.9H7.833a1.9 1.9 0 0 0-1.9 1.9v19a1.9 1.9 0 0 0 1.9 1.9h12.9v-2.233a3.1 3.1 0 0 1 3.1-3.1h2.233z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M7.375 10c0-2.554 2.071-4.625 4.625-4.625h24c2.554 0 4.625 2.071 4.625 4.625v24 .999V35v.006c-.002.158-.062.316-.183.436l-7 7a.62.62 0 0 1-.203.136c-.064.027-.134.043-.207.047l-.032.001-1.625-2.069V38c0-2.554 2.071-4.625 4.625-4.625h3.375V10c0-1.864-1.511-3.375-3.375-3.375H12c-1.864 0-3.375 1.511-3.375 3.375v1.592L7.375 10zm1.25 1.592L7.375 10v28c0 2.554 2.071 4.625 4.625 4.625h20 .999l-1.624-2.068v-.001L24.655 32h.72c.345 0 .625-.28.625-.625s-.28-.625-.625-.625h-1.702L20.728 27h11.648c.345 0 .625-.28.625-.625s-.28-.625-.625-.625H19.746L16.8 22h15.575c.345 0 .625-.28.625-.625s-.28-.625-.625-.625H15.819l-7.193-9.158h0zM23.672 30.75L20.727 27h-5.102c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h4.12L16.8 22h-1.175c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h.193l-7.193-9.158V38c0 1.864 1.511 3.375 3.375 3.375h19.375v-.818L24.654 32h-9.029c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h8.047zm9.069 10.625l6.634-6.634v-.116H36c-1.864 0-3.375 1.511-3.375 3.375v3.375h.116zM15 16.375c0-.345.28-.625.625-.625h16.75c.345 0 .625.28.625.625s-.28.625-.625.625h-16.75c-.345 0-.625-.28-.625-.625z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M9.867 13.333C9.867 9.946 12.613 7.2 16 7.2h32c3.387 0 6.133 2.746 6.133 6.133v32.209 1.109a.8.8 0 0 1-.234.582l-9.333 9.333c-.082.082-.178.143-.28.182A.8.8 0 0 1 44 56.8h-1.117-.008-.008H16c-3.387 0-6.133-2.746-6.133-6.133V13.333zm33.809 41.86l8.852-8.852h-4.318c-2.504 0-4.533 2.03-4.533 4.533v4.318zm8.858-41.86v31.408h-4.325c-3.387 0-6.133 2.746-6.133 6.133V55.2H16c-2.504 0-4.533-2.03-4.533-4.533V13.333c0-2.504 2.03-4.533 4.533-4.533h32c2.504 0 4.533 2.03 4.533 4.533zM20 21.867a.8.8 0 0 1 .8-.8h22.4a.8.8 0 1 1 0 1.6H20.8a.8.8 0 0 1-.8-.8zm.8 5.867a.8.8 0 1 0 0 1.6h22.4a.8.8 0 1 0 0-1.6H20.8zM20 35.2a.8.8 0 0 1 .8-.8h22.4a.8.8 0 1 1 0 1.6H20.8a.8.8 0 0 1-.8-.8zm.8 5.867a.8.8 0 1 0 0 1.6h13.067a.8.8 0 1 0 0-1.6H20.8z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
