/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Close Thick',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M11.22 3.719a.75.75 0 0 1 1.061 1.061L9.061 8l3.22 3.22a.75.75 0 0 1-1.061 1.061L8 9.061l-3.22 3.22a.75.75 0 0 1-1.061-1.061L6.939 8l-3.22-3.22A.75.75 0 0 1 4.78 3.719L8 6.939l3.22-3.22z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M17.214 5.512a.9.9 0 1 1 1.273 1.273L13.273 12l5.215 5.215a.9.9 0 1 1-1.273 1.273L12 13.273l-5.215 5.215a.9.9 0 1 1-1.273-1.273L10.727 12 5.513 6.785a.9.9 0 1 1 1.273-1.273L12 10.727l5.214-5.215z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M24.714 6.013a.9.9 0 1 1 1.273 1.273L17.273 16l8.714 8.714a.9.9 0 1 1-1.273 1.273L16 17.273l-8.714 8.714a.9.9 0 1 1-1.273-1.273L14.727 16 6.013 7.286a.9.9 0 0 1 1.273-1.273L16 14.727l8.714-8.714z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M37.044 9.541a1 1 0 0 1 1.414 1.414L25.414 24l13.045 13.045a1 1 0 0 1-1.414 1.414L24 25.414 10.955 38.459a1 1 0 0 1-1.414-1.414L22.586 24 9.541 10.955a1 1 0 0 1 1.414-1.414L24 22.586 37.044 9.541z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
