import { Box, styled, StyleSystemProps } from '@withjoy/joykit';

export const GraphicAccentContainer = styled(Box)``;

export const GraphicAccentImg = styled.img<{ pixelDensity: number }>`
  transform: scale(${props => 1 / Math.max(props.pixelDensity, 1)});
`;

const container: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '5rem', // based on 16px = 1 rem
  width: 'auto',
  maxWidth: '100%',
  overflow: 'visible' // make no assumption about the size of the <img />
};

export const styles = {
  container
};
