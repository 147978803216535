import { withWindow } from '@shared/utils/withWindow';

/**
 * Formats a Date object into a string in the format 'YYYY-MM-DD'.
 */
export const accommodationPlaceFormatDate = (dateObj: Date | null): string | null =>
  dateObj && !isNaN(dateObj.getTime()) ? `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}` : null;

export function accommodationPlaceReplaceUrl(url: string): void {
  withWindow(() => {
    window.location.replace(url);
  });
}

const ACCOMMODATION_PLACE_PATH = 'accommodation-place';

export const useAccommodationPlaceRoutePaths = () => {
  return {
    accommodationPlace: {
      path: `/:eventHandle/${ACCOMMODATION_PLACE_PATH}/:accommodationPlaceId?`,
      goToPath: (eventHandle: string, accommodationPlaceId: string) => `/${eventHandle}/${ACCOMMODATION_PLACE_PATH}/${accommodationPlaceId}`
    }
  } as const;
};
