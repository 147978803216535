/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Tags',
  definitions: {
    '16': {
      viewBox: '0 0 14 14',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.20908 2.77559C8.62329 3.36138 8.62329 4.31113 9.20908 4.89691C9.79486 5.4827 10.7446 5.4827 11.3304 4.89691C11.9162 4.31113 11.9162 3.36138 11.3304 2.77559C10.7446 2.18981 9.79486 2.18981 9.20908 2.77559ZM9.98689 3.55341C9.83068 3.70962 9.83068 3.96289 9.98689 4.1191C10.1431 4.27531 10.3964 4.27531 10.5526 4.1191C10.7088 3.96289 10.7088 3.70962 10.5526 3.55341C10.3964 3.3972 10.1431 3.3972 9.98689 3.55341Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2206 7.8154C13.6257 7.41027 13.8376 6.85084 13.8026 6.27896L13.5599 2.31413C13.4981 1.3058 12.6942 0.501825 11.6858 0.440091L7.721 0.197346C7.14913 0.162333 6.5897 0.374262 6.18457 0.779395L0.779397 6.18457C-0.00165081 6.96561 -0.00165081 8.23194 0.779397 9.01299L4.98697 13.2206C5.76802 14.0016 7.03435 14.0016 7.8154 13.2206L13.2206 7.8154ZM6.96239 1.55721L1.55721 6.96238C1.20574 7.31386 1.20574 7.8837 1.55721 8.23518L5.76479 12.4428C6.11626 12.7942 6.68611 12.7942 7.03758 12.4428L12.4428 7.03758C12.6251 6.85527 12.7204 6.60353 12.7047 6.34619L12.4619 2.38135C12.4342 1.92761 12.0724 1.56582 11.6186 1.53803L7.65378 1.29529C7.39644 1.27953 7.1447 1.3749 6.96239 1.55721Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 20 20',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3859 17.7565L17.7566 10.3858C18.1236 10.0188 18.3143 9.51122 18.2798 8.99332L17.93 3.7462C17.87 2.84643 17.1535 2.1299 16.2537 2.06992L11.0066 1.72011C10.4887 1.68558 9.98108 1.8763 9.61406 2.24333L2.24342 9.61397C1.54048 10.3169 1.54048 11.4566 2.24342 12.1596L7.84035 17.7565C8.5433 18.4594 9.68299 18.4594 10.3859 17.7565ZM11.0864 0.522766C10.2232 0.465221 9.37724 0.783091 8.76553 1.3948L1.39489 8.76544C0.223318 9.93701 0.22332 11.8365 1.39489 13.0081L6.99183 18.605C8.1634 19.7766 10.0629 19.7766 11.2345 18.605L18.6051 11.2344C19.2168 10.6227 19.5347 9.77667 19.4771 8.9135L19.1273 3.66637C19.0274 2.16676 17.8331 0.972549 16.3335 0.872574L11.0864 0.522766Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8815 5.11844C15.0921 5.32909 15.4336 5.32909 15.6443 5.11844C15.8549 4.90779 15.8549 4.56626 15.6443 4.35561C15.4336 4.14495 15.0921 4.14495 14.8815 4.35561C14.6708 4.56626 14.6708 4.90779 14.8815 5.11844ZM14.0329 5.96697C14.7122 6.64625 15.8135 6.64625 16.4928 5.96697C17.1721 5.28769 17.1721 4.18636 16.4928 3.50708C15.8135 2.8278 14.7122 2.8278 14.0329 3.50708C13.3537 4.18636 13.3537 5.28769 14.0329 5.96697Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.09708 11.7745C3.86277 11.5402 3.86277 11.1603 4.09708 10.926L8.16834 6.85469C8.40265 6.62038 8.78255 6.62038 9.01687 6.85469C9.25118 7.08901 9.25118 7.46891 9.01687 7.70322L4.94561 11.7745C4.71129 12.0088 4.33139 12.0088 4.09708 11.7745Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.92358 15.601C7.68926 15.3667 7.68926 14.9868 7.92358 14.7525L12.9788 9.69724C13.2131 9.46292 13.593 9.46292 13.8273 9.69724C14.0616 9.93155 14.0616 10.3114 13.8273 10.5458L8.77211 15.601C8.53779 15.8353 8.15789 15.8353 7.92358 15.601Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.06499 13.7424C5.83068 13.5081 5.83068 13.1282 6.06499 12.8938L8.66032 10.2985C8.89463 10.0642 9.27453 10.0642 9.50884 10.2985C9.74316 10.5328 9.74316 10.9127 9.50884 11.1471L6.91352 13.7424C6.67921 13.9767 6.29931 13.9767 6.06499 13.7424Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 26 26',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8097 23.2213L23.2212 13.8098C23.7921 13.2388 24.0888 12.4492 24.0351 11.6436L23.5932 5.01449C23.4998 3.61485 22.3852 2.50025 20.9856 2.40694L14.3565 1.965C13.5509 1.91129 12.7613 2.20797 12.1903 2.7789L2.77879 12.1904C1.68532 13.2839 1.68532 15.0568 2.77879 16.1502L9.84986 23.2213C10.9433 24.3148 12.7162 24.3148 13.8097 23.2213ZM14.4363 0.767659C13.2854 0.690933 12.1574 1.11476 11.3418 1.93037L1.93026 11.3419C0.368166 12.904 0.368165 15.4367 1.93026 16.9988L9.00133 24.0698C10.5634 25.6319 13.0961 25.6319 14.6582 24.0698L24.0697 14.6583C24.8853 13.8427 25.3092 12.7147 25.2324 11.5638L24.7905 4.93467C24.6572 2.93518 23.0649 1.3429 21.0654 1.2096L14.4363 0.767659ZM19.0422 6.95785C19.4523 7.3679 20.1171 7.3679 20.5272 6.95785C20.9372 6.5478 20.9372 5.88298 20.5272 5.47293C20.1171 5.06288 19.4523 5.06288 19.0422 5.47293C18.6322 5.88298 18.6322 6.5478 19.0422 6.95785ZM18.1937 7.80638C19.0724 8.68506 20.497 8.68506 21.3757 7.80638C22.2544 6.9277 22.2544 5.50308 21.3757 4.6244C20.497 3.74572 19.0724 3.74572 18.1937 4.6244C17.315 5.50308 17.315 6.9277 18.1937 7.80638ZM5.46579 14.5946C5.23148 14.8289 5.23148 15.2088 5.46579 15.4431C5.7001 15.6774 6.08 15.6774 6.31432 15.4431L11.8298 9.92769C12.0641 9.69337 12.0641 9.31347 11.8298 9.07916C11.5954 8.84484 11.2155 8.84484 10.9812 9.07916L5.46579 14.5946ZM10.4155 20.3929C10.1812 20.1586 10.1812 19.7787 10.4155 19.5443L17.2038 12.7561C17.4381 12.5218 17.818 12.5218 18.0523 12.7561C18.2866 12.9904 18.2866 13.3703 18.0523 13.6046L11.2641 20.3929C11.0298 20.6272 10.6499 20.6272 10.4155 20.3929ZM8.01137 17.1402C7.77706 17.3745 7.77706 17.7544 8.01137 17.9887C8.24569 18.223 8.62559 18.223 8.8599 17.9887L12.4661 14.3825C12.7005 14.1481 12.7005 13.7682 12.4661 13.5339C12.2318 13.2996 11.8519 13.2996 11.6176 13.5339L8.01137 17.1402Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9396 40.6094L40.6809 25.8681C41.6494 24.8995 42.1527 23.56 42.0616 22.1933L41.362 11.6991C41.2037 9.32471 39.3128 7.43388 36.9385 7.27558L26.4442 6.57597C25.0775 6.48485 23.738 6.98815 22.7695 7.95669L8.02821 22.698C6.17322 24.553 6.17322 27.5605 8.02821 29.4155L19.2221 40.6093C21.0771 42.4643 24.0846 42.4643 25.9396 40.6094ZM26.5274 5.32874C24.801 5.21365 23.109 5.84938 21.8856 7.0728L7.14433 21.8141C4.80118 24.1572 4.80119 27.9562 7.14433 30.2994L18.3382 41.4932C20.6813 43.8364 24.4803 43.8364 26.8235 41.4932L41.5648 26.752C42.7882 25.5285 43.4239 23.8365 43.3088 22.1102L42.6092 11.6159C42.4093 8.61673 40.0208 6.2283 37.0216 6.02835L26.5274 5.32874Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.3044 15.3332C34.1748 16.2036 35.586 16.2036 36.4564 15.3332C37.3268 14.4628 37.3268 13.0516 36.4564 12.1812C35.586 11.3108 34.1748 11.3108 33.3044 12.1812C32.434 13.0516 32.434 14.4628 33.3044 15.3332ZM32.4205 16.2171C33.779 17.5757 35.9817 17.5757 37.3403 16.2171C38.6988 14.8586 38.6988 12.6559 37.3403 11.2973C35.9817 9.93878 33.779 9.93878 32.4205 11.2973C31.0619 12.6559 31.0619 14.8586 32.4205 16.2171Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.955 28.2382C12.7109 27.9941 12.7109 27.5983 12.955 27.3543L21.9107 18.3986C22.1547 18.1545 22.5505 18.1545 22.7945 18.3986C23.0386 18.6427 23.0386 19.0384 22.7945 19.2825L13.8389 28.2382C13.5948 28.4822 13.199 28.4822 12.955 28.2382Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.6083 35.8912C20.3642 35.6472 20.3642 35.2514 20.6083 35.0073L31.5319 24.0837C31.776 23.8397 32.1717 23.8397 32.4158 24.0837C32.6599 24.3278 32.6599 24.7235 32.4158 24.9676L21.4922 35.8912C21.2481 36.1353 20.8524 36.1353 20.6083 35.8912Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.891 32.1739C16.6469 31.9299 16.6469 31.5341 16.891 31.2901L22.8948 25.2862C23.1389 25.0422 23.5346 25.0422 23.7787 25.2862C24.0228 25.5303 24.0228 25.926 23.7787 26.1701L17.7749 32.1739C17.5308 32.418 17.1351 32.418 16.891 32.1739Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
