import { useEffect, useState } from 'react';
import { isInIframe } from '@shared/utils/isInIframe';
import { FeatureSet } from '@shared/core/featureSet/featureSet.types';
import { useHistory } from '@react-router';
import { useEventNameMatch } from '@shared/components/AuthProvider/AuthProvider.utils';
import { withWindow } from '@shared/utils/withWindow';

const isIframed = isInIframe();

/**
 * The hook will be responsible for redirecting to the enabled featureSet with the highest priority.
 * You can see the details of the operation in the following document:
 * @link https://withjoy.atlassian.net/wiki/spaces/LNCH/pages/2666561550/Standalone+Registry#CRAWL-(Cost-~1-sprint)
 */
export const useGuestSiteRedirection = (featureSet: Maybe<FeatureSet>) => {
  const eventNameMatch = useEventNameMatch();
  const history = useHistory();

  const [waitingRedirection, setWaitingRedirection] = useState<boolean>(!isIframed);

  useEffect(() => {
    if (featureSet && !isIframed) {
      if (featureSet.website) {
        // If we have the website featureSet enabled, we don't need to do any redirection.
      } else if (featureSet.registry) {
        history.replace(`/${eventNameMatch.eventName}/registry`);
      } else if (featureSet.rsvp) {
        withWindow(global => {
          // Full redirection to RSVP
          global.location.href = `/${eventNameMatch.eventName}/rsvp`;
        });
      } else {
        withWindow(global => {
          // On localhost, this redirection will fail since find-a-couple is being handled by marcom.
          global.location.href = '/find';
        });
      }
      setWaitingRedirection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureSet]);

  return {
    waitingRedirection
  };
};
