import React, { useMemo } from 'react';
import { CellProps, Column, Row, useTable, useSortBy } from 'react-table';
import { StyledTableWrapper, styles } from './ResultsTable.styles';
import { PrintOrderSearchResultEntry } from '../../PrintOrderManagement.types';
import { usePrintOrderManagementState } from '../../state';
import { LinkV2, TextV2, Flex, IconV2, Box } from '@withjoy/joykit';
import { TempSpinner } from '@shared/components/TempSpinner';
import { ArrowUp } from '@withjoy/joykit/icons';
import { getConciergeCategoryFromDesignSetName, getProductOfferingFromDesignSetName } from '@apps/card/routes/Concierge/Concierge.utils';

type CellRenderer<Value extends unknown = string> = CellProps<PrintOrderSearchResultEntry, Maybe<string>> & { row: Row<PrintOrderSearchResultEntry>; value: Value };

export const ResultsTable = () => {
  const { results, queryLoading, queryError, queryCalled } = usePrintOrderManagementState();

  const ViewDetailCell = (props: CellRenderer<PrintOrderSearchResultEntry['orderNumber']>) => {
    const { value, row } = props;
    const viewDetailUrl = !!value ? `${window.location.origin}/servicecenter/print/${value}?eventId=${row?.values?.['Event ID'] ?? ''}` : undefined;
    if (!viewDetailUrl) {
      return null;
    } else {
      return (
        <LinkV2 href={viewDetailUrl} target="_blank" fontSize="14px">
          View Detail
        </LinkV2>
      );
    }
  };

  const columns = useMemo<Column<PrintOrderSearchResultEntry>[]>(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableSortBy: true
      },
      {
        Header: 'Order Number',
        accessor: 'orderNumber',
        disableSortBy: true
      },
      {
        Header: 'Price',
        accessor: row => `$${row.priceInMinorUnits / 100}`
      },
      {
        Header: 'Quantity',
        accessor: 'quantity'
      },
      {
        Header: 'Event ID',
        accessor: row => row.eventId,
        disableSortBy: true
      },
      {
        Header: 'Owner ID',
        accessor: row => row.ownerId,
        disableSortBy: true
      },
      {
        Header: 'Template ID',
        accessor: row => row.cardDraft?.stationeryTemplate.id ?? '',
        disableSortBy: true
      },
      {
        Header: 'Template Theme ID',
        accessor: row => row.cardDraft?.stationeryTemplate.themeId ?? ''
      },
      {
        Header: 'Template Category',
        accessor: row => (row.designSetName ? getConciergeCategoryFromDesignSetName(row.designSetName) : row.cardDraft!.stationeryTemplate.category)
      },
      {
        Header: 'Concierge Suite Name',
        accessor: row => (row.designSetName ? getProductOfferingFromDesignSetName(row.designSetName)?.longName ?? '' : '')
      },
      {
        Header: 'Delivery Method',
        accessor: 'deliveryMethod'
      },
      {
        Header: 'Sales Order ID',
        accessor: row => row.printPayment?.salesOrderId,
        disableSortBy: true
      },
      {
        Header: 'Status',
        accessor: row => row.currentStatus
      },
      {
        Header: 'Created At',
        accessor: row => row.createdAt
      },
      { id: 'orderDetailId', Header: 'Order Detail', accessor: 'orderNumber', disableSortBy: true, Cell: args => <ViewDetailCell {...args} /> }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<PrintOrderSearchResultEntry>(
    {
      columns,
      data: results
    },
    useSortBy
  );

  if (queryLoading) {
    return <TempSpinner />;
  }
  if (queryError) {
    return (
      <div>
        <TextV2 typographyVariant="body3" color="red">
          Error
        </TextV2>
        <TextV2>{JSON.stringify(queryError)}</TextV2>
      </div>
    );
  }
  if (!results.length) {
    if (queryCalled) {
      return (
        <Flex __css={styles.noResultsWrapper}>
          <TextV2 typographyVariant="body3">No results found</TextV2>
        </Flex>
      );
    }
    return null;
  } else {
    return (
      <Box padding={6}>
        <TextV2 marginBottom={4}>
          Also you can sort by Price, Quantity, Template Theme ID, Template Category, Concierge Suite Name, Delivery Method, Status or Created At, by toggling the column header.
        </TextV2>
        <StyledTableWrapper>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                      <Flex alignItems="center" justifyContent="center" columnGap="5px">
                        {column.render('Header')}
                        <>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <IconV2 size="sm" transform="rotate(180deg);">
                                <ArrowUp />
                              </IconV2>
                            ) : (
                              <IconV2 size="sm">
                                <ArrowUp />
                              </IconV2>
                            )
                          ) : null}
                        </>
                      </Flex>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td {...cell.getCellProps()} key={index}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </StyledTableWrapper>
      </Box>
    );
  }
};
