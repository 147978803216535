import React, { useState, useEffect } from 'react';
import { AnimatedValue, SpringConfig, config as SpringPresets, useTransition } from 'react-spring';
import { safeInvoke } from '../../../core/utils';

type TransitionAnimatedStage = 'entering' | 'exiting';
type TransitionRestingStage = 'entered' | 'exited';
type TransitionStage = TransitionAnimatedStage | TransitionRestingStage;

export type TransitionRenderer = (styles: AnimatedValue<React.CSSProperties>) => React.ReactNode;

type TransitionStageCallback = () => void;

export type TransitionProperties = { to: React.CSSProperties; from: React.CSSProperties; leave: React.CSSProperties };

export interface TransitionProps {
  component?: JSX.IntrinsicElements;
  children: (properties: AnimatedValue<React.CSSProperties>) => React.ReactElement | null;
  toggle: boolean;
  lazy?: boolean;
  unmountOnExit?: boolean;

  from: React.CSSProperties;
  to: React.CSSProperties;
  leave?: React.CSSProperties;
  config?: SpringConfig;

  onEnter?: TransitionStageCallback;
  onEntering?: TransitionStageCallback;
  onEntered?: TransitionStageCallback;

  onExit?: TransitionStageCallback;
  onExiting?: TransitionStageCallback;
  onExited?: TransitionStageCallback;
}

interface TransitionState {
  mounted: boolean;
  stage: TransitionStage;
}

export const Transition: React.FC<TransitionProps> = props => {
  const [state, setState] = useState<TransitionState>({
    mounted: false,
    stage: 'exited'
  });

  const { mounted, stage } = state;

  const {
    children,
    toggle,
    from,
    to,
    leave,
    config = SpringPresets.default,
    // lazy = true,
    onEnter,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
    unmountOnExit = true
  } = props;

  /**
   * Invoke state transition callbacks before the animation begins
   */
  const handleToggleUpdate = () => {
    const nextStage = toggle ? 'entering' : 'exiting';

    if (stage.includes('ing') || stage === nextStage || (stage === 'entered' && toggle) || (stage === 'exited' && !toggle)) {
      return;
    }

    /**
     * Depending on current toggle state, invoke the correct stage transition callback
     */
    safeInvoke(toggle ? onEnter : onExit);

    /**
     * Set the in-between transition state: `entering` or `exiting`
     */
    setState({ mounted: true, stage: nextStage });

    let callback: TransitionStageCallback | undefined;
    switch (nextStage) {
      case 'entering':
        callback = onEntering;
        break;
      case 'exiting':
        callback = onExiting;
        break;
      default:
        break;
    }
    safeInvoke(callback);
  };

  const handleRest = (isDestroyed: boolean) => {
    const nextStage = toggle ? 'entered' : 'exited';
    if (stage === nextStage) {
      return;
    }
    const callback = toggle ? onEntered : onExited;
    safeInvoke(callback);

    setState({ mounted: toggle ? true : false, stage: nextStage });
  };

  // Lifecycle

  useEffect(() => {
    handleToggleUpdate();
  }, [toggle]);

  // Render

  const transitions = useTransition(toggle, null, {
    from,
    enter: to,
    leave,
    unique: true,
    reset: true,
    config,
    onDestroyed: handleRest
  });

  if (!mounted && stage === 'exited' && unmountOnExit) {
    return null;
  }

  return transitions.map(({ key, item, props }) => item && <React.Fragment key={key}>{children(props)}</React.Fragment>) as any;
};
