import { useMemo } from 'react';
import { EventPageType, PhotoAlignment, PhotoFragment } from '@graphql/generated';

interface Page {
  id: string;
  type: EventPageType;
  photo?: PhotoFragment | null;
}

type Pages = ReadonlyArray<Page>;

interface PageContainer {
  id: string;
  mainPhotoAlignment?: PhotoAlignment | null;
}

type PageContainers = ReadonlyArray<PageContainer>;

/**
 * Used to map page photo alignment from page container
 * Custom Pages photo focus alignment and photo are stored with different services
 */
export function usePagesWithPhotoAlignment<P extends Pages, C extends PageContainers>(pages?: P | null, pageContainers?: C | null) {
  const photoAlignedPages = useMemo(() => {
    if (!pages || !pageContainers) return pages ?? [];

    return (pages.map(page => {
      const customPage = pageContainers.find(container => container.id === page.id);
      const customPagePhotoAlignment = customPage?.mainPhotoAlignment;

      if (customPagePhotoAlignment && page.photo && page.type === EventPageType.custom) {
        const photo = {
          ...page.photo,
          layout: {
            ...(page.photo.layout || {}),
            alignX: customPagePhotoAlignment.x,
            alignY: customPagePhotoAlignment.y
          }
        };

        return { ...page, photo };
      }

      return page;
    }) as unknown) as P;
  }, [pages, pageContainers]);

  return photoAlignedPages;
}
