import { ModalProps, styled, TextV2 } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const dialogOverridesDesktop: NonNullable<ModalProps['overrides']> = {
  ContentContainer: {
    props: {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  Content: {
    props: {
      borderRadius: '32px',
      maxHeight: 'calc(100vh - 48px)',
      width: '600px'
    }
  },
  Header: {
    props: {
      _first: {
        borderTopLeftRadius: '32px',
        borderTopRightRadius: '32px'
      }
    }
  }
};

export const dialogOverridesMobile: NonNullable<ModalProps['overrides']> = {
  Content: {
    props: {
      minHeight: 'auto',
      maxHeight: '100vh',
      margin: 0,
      flexGrow: 0
    }
  }
};

export const StyledAnnouncementContent = styled(TextV2)`
  a {
    text-decoration: underline;
    transition: ${animationTransition('color')};
    color: ${props => props.theme.colors.linkText};
    :hover {
      color: ${props => props.theme.colors.linkHover};
    }
    :active {
      color: ${props => props.theme.colors.linkActive};
    }
    :visited {
      color: ${props => props.theme.colors.linkVisited};
    }
  }
`;
