import React from 'react';

import { Box, Flex, TextV2 } from '@withjoy/joykit';

export const ErrorComponent: React.FC = () => {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
      <Box maxWidth={500}>
        <TextV2>An error occurred. Please refresh to try again.</TextV2>
      </Box>
    </Flex>
  );
};
