import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { LayoutBaseProps } from '../layout.types';
import { Flex } from '@withjoy/joykit';

import { FrameWrapper } from './components/FrameWrapper';
import { MainFrame } from './components/MainFrame';
import { AuxFrame, AuxFrameProvider } from './components/AuxFrame';
import { Body } from './components/Body';
import { alohaConfig, useAlohaDesign } from './LayoutAloha.theme';
import { WelcomeCard } from './components/WelcomeCard';
import WelcomeVideo from '../../widgets/WelcomeVideo';
import { Footer } from '@apps/guest/packages/layout-engine/components/Footer';
import { alohaFooterContainerStyles } from '@apps/guest/packages/layout-engine/components/Footer/Footer.styles';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { PageContainer, CustomPageContainer, StyledFlexAloha } from './LayoutAloha.styles';
import { isInIframe } from '@shared/utils/isInIframe';
import EventMenu from './components/EventMenu/EventMenu';
import { useLayoutAlohaController } from './LayoutAloha.controller';
import { getEventMenuPropsFromEvent } from '@apps/guest/packages/layout-engine/layouts/layout.utils';
import { useAlohaScroll } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/useAlohaScroll';
import { PagesRefs } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/LayoutAloha.types';
import { PreloadImages } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/components/PreloadImages/PreloadImages';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { InlineEditor } from '@shared/components';
import { EventPageType } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { InlineEditExperimentProps, useInlineEditExperimentListeners } from '@shared/hooks/useInlineEditExperimentListeners';

export interface LayoutAlohaProps extends Readonly<LayoutBaseProps> {
  setLoadingTheme?: Maybe<Dispatch<SetStateAction<boolean>>>;
  setIsLastPageVisible?: (value: boolean) => void;
}

const { simple } = alohaConfig.applicatorHtmlProps;

const LayoutAloha: React.FC<LayoutAlohaProps> = ({ eventHandle, event, setLoadingTheme, setIsLastPageVisible, ...restProps }) => {
  const {
    id,
    pages,
    photo,
    mobilePhoto,
    video,
    info: { eventDisplayName, greeting }
  } = event;

  const { isSmallScreen, loading, CustomStyle } = useAlohaDesign(event.eventDesign);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const useMobilePhoto = isPortrait && isMobile;
  const homeRef = useRef<HTMLDivElement>(null);
  const isPreviewing = isInIframe();
  const containerRef = useRef(null);
  const pagesRef: Record<string, PagesRefs> = {};
  const { enabledAdminGuestSiteBannerDialog } = useGuestSiteState();

  useAlohaScroll(pages, pagesRef, containerRef.current, setIsLastPageVisible);

  const {
    handleMenuClicked,
    handleWelcomeRSVPButtonClicked,
    handleWelcomeViewDetailsButtonClicked,
    handleFooterButtonClicked,
    handleMenuOpenedChange,
    handleWelcomeOnNotYouButtonClicked,
    handleWelcomeOnNotYouErrorRequest,
    telemetryEditPhotoActions
  } = useLayoutAlohaController({ id, eventHandle, pages });

  const { t2 } = useTranslation('joykit');
  const inlineEditorTrans = t2('inlineEditor');

  const [inlineThemeColorEditExperimentEnabled, setInlineThemeColorEditExperimentEnabled] = useState(false);

  useEffect(() => {
    setLoadingTheme && setLoadingTheme(loading);
  }, [loading, setLoadingTheme]);

  useEffect(() => {
    if (isPreviewing) {
      sendMessageToParentWindow({ action: 'requestIframeScaleValue', source: 'joyPreview' });
    }
  }, [isPreviewing, event.eventDesign?.theme.websiteLayoutAlohaSettings?.alohaColorPreference?.supportsCustomization]);

  useEffect(() => {
    sendMessageToParentWindow({
      action: 'experimentRequestFromPreview',
      value: { id: 'alohaLayout', key: 'inlineThemeColorEditExperiment', skip: !isPreviewing },
      source: 'joyPreview'
    });
  }, [isPreviewing]);

  const experimentData: InlineEditExperimentProps = useMemo(
    () => [
      {
        experimentKey: 'inlineThemeColorEditExperiment',
        setFn: ({ isEnabled, id }) => {
          if (id === 'alohaLayout') {
            setInlineThemeColorEditExperimentEnabled(isEnabled);
          }
        }
      }
    ],
    []
  );

  useInlineEditExperimentListeners(experimentData);

  const onEditThemeColorClick = useCallback(() => {
    // send the message to the parent window to open the theme editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: {
        action: 'editThemeColor',
        inlineEditData: {
          imageURL: pages.find(p => p.type === EventPageType.welcome)?.photo?.url || '',
          eventDesignId: event.eventDesign?.id || '',
          paletteId: event.eventDesign?.colorPalette[0].id || ''
        }
      }
    });
  }, [event.eventDesign?.colorPalette, event.eventDesign?.id, pages]);

  return (
    <FrameWrapper>
      {CustomStyle && <CustomStyle />}
      <PreloadImages pages={pages} />
      <AuxFrameProvider isSmallScreen={isSmallScreen} eventId={id} primaryPhoto={useMobilePhoto ? mobilePhoto : photo} pages={pages} isPreviewing={isPreviewing}>
        <StyledFlexAloha ref={containerRef} loading={loading} isPreviewing={isPreviewing} className="aloha-scrollable">
          <div id="photo-welcome" ref={homeRef} />
          {!isMobile && (
            <AuxFrame telemetryPhotoEditor={telemetryEditPhotoActions} title={eventDisplayName} subtitle={greeting} eventHandle={eventHandle}>
              <EventMenu
                eventProps={getEventMenuPropsFromEvent(event)}
                handleMenuOpenedChange={handleMenuOpenedChange}
                handleMenuClicked={handleMenuClicked}
                title={eventDisplayName}
                eventHandle={eventHandle}
                hasBanner={enabledAdminGuestSiteBannerDialog}
              />
            </AuxFrame>
          )}
          <MainFrame>
            {pages.map((page, idx) => {
              if (page.type === 'welcome') {
                const nextPage = idx === pages.length - 1 ? null : pages[idx + 1]; // Get next page if it has
                return (
                  <PageContainer key={page.id} ref={ref => (pagesRef[page.id] = { ref, page })}>
                    <div>
                      {isMobile && (
                        <AuxFrame telemetryPhotoEditor={telemetryEditPhotoActions} title={eventDisplayName} subtitle={greeting} eventHandle={eventHandle}>
                          <EventMenu
                            eventProps={getEventMenuPropsFromEvent(event)}
                            handleMenuOpenedChange={handleMenuOpenedChange}
                            handleMenuClicked={handleMenuClicked}
                            title={eventDisplayName}
                            eventHandle={eventHandle}
                            hasBanner={enabledAdminGuestSiteBannerDialog}
                          />
                        </AuxFrame>
                      )}
                      {inlineThemeColorEditExperimentEnabled ? (
                        <InlineEditor
                          elementLabel={inlineEditorTrans.theme}
                          wrapperType="actionInside"
                          actionData={{
                            editThemeColor: onEditThemeColorClick
                          }}
                          componentName="welcomePageTheme"
                          pageName={page.type}
                          pageSlug={page.pageSlug}
                          wrapperCSS={{ zIndex: 10 }}
                          isEligibleForInlineEditing={event.eventDesign?.theme.websiteLayoutAlohaSettings?.alohaColorPreference?.supportsCustomization}
                        >
                          <WelcomeCard
                            homeRef={homeRef}
                            page={page}
                            nextPageSlug={nextPage?.pageSlug}
                            eventHandle={eventHandle}
                            hideEventCountdown={event.settings.hideEventCountdown}
                            finalizedDate={event.info.finalizedDate}
                            location={event.info.location}
                            onRSVPButtonClicked={handleWelcomeRSVPButtonClicked}
                            onViewDetailsButtonClicked={handleWelcomeViewDetailsButtonClicked}
                            onNotYouButtonClicked={handleWelcomeOnNotYouButtonClicked}
                            onNotYouErrorRequest={handleWelcomeOnNotYouErrorRequest}
                            {...restProps}
                          />
                        </InlineEditor>
                      ) : (
                        <WelcomeCard
                          homeRef={homeRef}
                          page={page}
                          nextPageSlug={nextPage?.pageSlug}
                          eventHandle={eventHandle}
                          hideEventCountdown={event.settings.hideEventCountdown}
                          finalizedDate={event.info.finalizedDate}
                          location={event.info.location}
                          onRSVPButtonClicked={handleWelcomeRSVPButtonClicked}
                          onViewDetailsButtonClicked={handleWelcomeViewDetailsButtonClicked}
                          onNotYouButtonClicked={handleWelcomeOnNotYouButtonClicked}
                          onNotYouErrorRequest={handleWelcomeOnNotYouErrorRequest}
                          {...restProps}
                        />
                      )}
                      {video ? <WelcomeVideo key="welcome-video" video={video} thumbnailUrl={video.thumbnailUrl || page.photo?.url} /> : null}
                    </div>
                  </PageContainer>
                );
              }

              if (page.type === 'custom' && page?.pageSlug === 'accommodations') {
                return (
                  <CustomPageContainer key={page.id} ref={ref => (pagesRef[page.id] = { ref, page })}>
                    <Body
                      key={page.id}
                      data-testid={`layout-body-${page.pageSlug}`}
                      page={page}
                      event={event}
                      eventHandle={eventHandle}
                      applicatorProps={simple.prop || {}}
                      pageTitle={page.pageTitle}
                    />
                  </CustomPageContainer>
                );
              }

              return (
                <PageContainer key={page.id} ref={ref => (pagesRef[page.id] = { ref, page })}>
                  <Body
                    key={page.id}
                    data-testid={`layout-body-${page.pageSlug}`}
                    page={page}
                    event={event}
                    eventHandle={eventHandle}
                    applicatorProps={simple.prop || {}}
                    pageTitle={page.pageTitle}
                  />
                </PageContainer>
              );
            })}
            <Flex __css={alohaFooterContainerStyles}>
              <Footer onButtonClicked={handleFooterButtonClicked} data-testid={'layout-footer'} />
            </Flex>
          </MainFrame>
        </StyledFlexAloha>
      </AuxFrameProvider>
    </FrameWrapper>
  );
};

LayoutAloha.displayName = 'LayoutAloha';

export { LayoutAloha };
