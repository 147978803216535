import { useContext, useMemo } from 'react';

import { AnalyticsContext } from '@shared/core';
import { BooleanStringBasedFeatureKeys, useFeatureFlagsContext } from '@shared/core/featureFlags';

// https://github.com/microsoft/TypeScript/pull/45711
// Leverage tail recursion to generate a literal type for a numbers range
type NumberRange<N extends number, Result extends Array<unknown> = []> = Result['length'] extends N ? Result[number] : NumberRange<N, [...Result, Result['length']]>;
type Percentage = NumberRange<101>;

const loggedABTestAssignments = new Set<string>();

/**
 * Hook to calculate a feature flag value based on a provided string. The string is converted to a number, then this number
 * is used to determine which of the feature flag supported values to be returned.
 *
 * This hook can be used to assign "sticky" feature flag values to different cohorts of users by passing an immutable identifier
 * for that user, like a user id or event id.
 *
 * @param featureFlag Name of the feature flag.
 * @param assignmentString String which will be used to return one of the feature flag values.
 * @param percentage Percentage of users we want in this cohort.
 */
export function useFeatureFlagAssignedFromString<TFeatureFlagKey extends BooleanStringBasedFeatureKeys>(
  featureFlag: TFeatureFlagKey,
  assignmentString: string,
  percentage: Percentage
): boolean {
  const { features } = useFeatureFlagsContext();
  const analytics = useContext(AnalyticsContext);
  const featureFlagValue: boolean = useMemo(() => {
    const feature = features[featureFlag];
    const featureValue = feature.value;

    // In `src/shared/core/featureFlags/featureFlags.constants.ts`, boolean feature flags are initialized with a default value.
    // This following branch serves as an escape hatch to force a feature
    if (typeof featureValue !== 'undefined') {
      return featureValue;
    }

    const weightedValue = convertStringToWeightedValue(featureFlag, assignmentString);
    const isAssignedExperiment = weightedValue <= percentage;

    const experimentKey = `${featureFlag} | ${percentage} | ${assignmentString}`;
    if (assignmentString && !loggedABTestAssignments.has(experimentKey)) {
      loggedABTestAssignments.add(experimentKey);
      analytics.track({
        category: 'ABTestAssigned',
        action: 'ABTestAssigned',
        extraInfo: {
          name: featureFlag,
          value: isAssignedExperiment ? 'Experiment' : 'Control'
        }
      });
    }

    return isAssignedExperiment;
  }, [analytics, assignmentString, featureFlag, features, percentage]);

  return featureFlagValue;
}

const converStringToSummedCharCodes = (str: string) => {
  const chars = str.split('');
  const summedCharCodes = chars.reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
  return summedCharCodes;
};

function convertStringToWeightedValue(featureFlag: string, str: string): number {
  const endChars = str.slice(-4);
  const summedCharCodes = converStringToSummedCharCodes(endChars) + converStringToSummedCharCodes(featureFlag);
  return summedCharCodes % 100;
}
