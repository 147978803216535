/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Home',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.15998 2.58795C8.06516 2.51684 7.93479 2.51684 7.83998 2.58795L2.50664 6.58795C2.43949 6.63831 2.39998 6.71735 2.39998 6.80129V13.3013C2.39998 13.4486 2.51937 13.568 2.66664 13.568H5.66664C5.81392 13.568 5.93331 13.4486 5.93331 13.3013V9.96795C5.93331 8.82656 6.85859 7.90129 7.99998 7.90129C9.14136 7.90129 10.0666 8.82656 10.0666 9.96795V13.3013C10.0666 13.4486 10.186 13.568 10.3333 13.568H13.3333C13.4806 13.568 13.6 13.4486 13.6 13.3013V6.80129C13.6 6.71735 13.5605 6.63831 13.4933 6.58795L8.15998 2.58795ZM7.35998 1.94795C7.73923 1.66351 8.26072 1.66351 8.63998 1.94795L13.9733 5.94795C14.2419 6.1494 14.4 6.46554 14.4 6.80129V13.3013C14.4 13.8904 13.9224 14.368 13.3333 14.368H10.3333C9.7442 14.368 9.26664 13.8904 9.26664 13.3013V9.96795C9.26664 9.26839 8.69954 8.70129 7.99998 8.70129C7.30042 8.70129 6.73331 9.26839 6.73331 9.96795V13.3013C6.73331 13.8904 6.25575 14.368 5.66664 14.368H2.66664C2.07754 14.368 1.59998 13.8904 1.59998 13.3013V6.80129C1.59998 6.46554 1.75805 6.1494 2.02664 5.94795L7.35998 1.94795Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M12.24 3.856a.4.4 0 0 0-.48 0l-8 6a.4.4 0 0 0-.16.32v9.75a.4.4 0 0 0 .4.4h4.5a.4.4 0 0 0 .4-.4v-5a3.1 3.1 0 0 1 6.2 0v5a.4.4 0 0 0 .4.4H20a.4.4 0 0 0 .4-.4v-9.75a.4.4 0 0 0-.16-.32l-8-6zm-1.2-.96a1.6 1.6 0 0 1 1.92 0l8 6a1.6 1.6 0 0 1 .64 1.28v9.75a1.6 1.6 0 0 1-1.6 1.6h-4.5a1.6 1.6 0 0 1-1.6-1.6v-5a1.9 1.9 0 1 0-3.8 0v5a1.6 1.6 0 0 1-1.6 1.6H4a1.6 1.6 0 0 1-1.6-1.6v-9.75a1.6 1.6 0 0 1 .64-1.28l8-6z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
