import React, { useCallback } from 'react';
import { GuestSiteRegistryFragment, useGetGuestsiteRegistryQuery } from '@graphql/generated';
import { Box, BoxProps, ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { StyledColumns, StyledItemAvatar, StyledColumnsV2Column } from './RegistryList.styles';
import { getRegistryItems, RegistryItemType } from './RegistryList.utils';
import { useTranslation } from '@shared/core';
import { addRendition } from '@shared/utils/photoRendition';
import { TelemetryProvider, useRegistryListTelemetry } from './RegistryList.telemetry';
import { useLayout } from '../../layouts/LayoutProvider';
import { SlapAdsRegistry, useSlapAdsRegistryExperiment } from '../../components/SlapAdsRegistry';
import { useEventUserRole } from '@shared/components/AuthProvider';

export interface RegistryListProps
  extends Readonly<{
      eventHandle: string;
      eventId: string;
    }>,
    BoxProps {}

export interface RegistryListContentProps
  extends Readonly<{
      eventHandle: string;
      eventId: string;
      list: ReadonlyArray<GuestSiteRegistryFragment>;
    }>,
    BoxProps {}

const RegistryListContent = React.forwardRef<HTMLDivElement, RegistryListContentProps>(({ children, eventId, eventHandle, list, ...restProps }, ref) => {
  const telemetry = useRegistryListTelemetry();
  const { isAdmin } = useEventUserRole();
  const { layout } = useLayout();
  const slapAdsExperimentEnabled = useSlapAdsRegistryExperiment(eventId);

  const itemButtonAction = useCallback((clickFrom: string) => {
    telemetry.viewGiftsClick(clickFrom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const registryBrowseAction = useCallback(() => {
    telemetry.registryBrowseClicked('ViewStore');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t, t2 } = useTranslation('guestKit');
  const { buttonText, visitUnnamedRegistry } = t2('widgetRegistry');
  const { visitNamedRegistry } = t('widgetRegistry');

  const itemList1: JSX.Element[] = [];
  const itemList2: JSX.Element[] = [];

  const registryItems: RegistryItemType[] = getRegistryItems({ list, eventHandle });
  registryItems.forEach((regItem: RegistryItemType, indx: number) => {
    const rowPos = registryItems.length < 4 || indx < Math.floor(registryItems.length / 2) ? 1 : 2;
    const element = (
      <StyledColumnsV2Column key={indx}>
        <a
          onClick={() => itemButtonAction(regItem.isRegistry ? 'store' : 'gift')}
          key={indx}
          href={regItem.url}
          target="_blank"
          rel="noreferrer"
          data-testid="registrylist-item"
          color={'mono5'}
          aria-label={regItem.isRegistry && regItem.title ? visitNamedRegistry({ name: regItem.title }) : visitUnnamedRegistry}
        >
          <Flex height="100%" maxWidth="150px" borderRadius="8px" alignItems="center" justifyContent="center" backgroundColor="mono2" overflow="hidden" textAlign="center">
            {regItem.photo ? (
              <StyledItemAvatar
                paddingTop="100%"
                data-testid="registryitem-logo"
                role="img"
                backgroundImage={`url("${addRendition({
                  url: regItem.photo,
                  renditionSize: 'small'
                })}")`}
                width={regItem.isRegistry ? '70%' : '100%'}
              />
            ) : (
              <TextV2 data-testid="registryitem-name" typographyVariant={'display3'} fontSize="1.4rem" margin="auto">
                {regItem.title}
              </TextV2>
            )}
          </Flex>
        </a>
      </StyledColumnsV2Column>
    );
    if (rowPos === 1) {
      itemList1.push(element);
    } else {
      itemList2.push(element);
    }
  });
  return (
    <TelemetryProvider context={{ eventId: eventId, layout: layout, eventHandle }}>
      <Box ref={ref} {...restProps} marginX={[null, 3]}>
        <StyledColumns fluid margin="8px 0px">
          {itemList1}
        </StyledColumns>
        <StyledColumns margin="8px 0px">{itemList2}</StyledColumns>
        {registryItems.length > 0 && (
          <Box justifyContent={'center'} display={'flex'} padding={8}>
            <ButtonV2 target="_blank" as="a" href={`/${eventHandle}/registry`} onClick={registryBrowseAction} variant="outline" intent="primary">
              {buttonText}
            </ButtonV2>
          </Box>
        )}
        {isAdmin && slapAdsExperimentEnabled && registryItems.length === 0 && (
          <Box margin={'0 auto'} maxWidth={[null, null, 375]}>
            <SlapAdsRegistry redirectTo={`/${eventHandle}/edit/registry/shop`} />
          </Box>
        )}
      </Box>
    </TelemetryProvider>
  );
});

const RegistryList = React.forwardRef<HTMLDivElement, RegistryListProps>((props, ref) => {
  const { eventHandle, eventId } = props;
  const { layout } = useLayout();

  const { data, loading } = useGetGuestsiteRegistryQuery({
    variables: { eventHandle: eventHandle },
    batchMode: 'fast',
    fetchPolicy: 'cache-and-network',
    ssr: false
  });

  if (loading) return <div style={{ height: '300px' }}></div>; // height set to prevent divs from jumping around while page loads

  const eventRegistry = data?.eventByName?.info.registry || [];

  return (
    <TelemetryProvider context={{ eventId: eventId, layout: layout, eventHandle }}>
      <RegistryListContent ref={ref} {...props} list={eventRegistry} />
    </TelemetryProvider>
  );
});
export { RegistryList };
