import { useTranslation } from '@shared/core/i18n';

export const useEventCountdownTranslations = () => {
  // ==================
  // Set up
  // ==================

  const { t } = useTranslation('eventCountdown');
  // ==================
  // Translation
  // ==================

  const getEventDateWithAgoText = (dateTime: string) => {
    return t('eventDateWithAgoText')({ dateTime });
  };

  // ==================
  // Return
  // ==================

  return {
    getEventDateWithAgoText
  };
};
