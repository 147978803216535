import React, { useContext } from 'react';
import { ButtonV2, useDisclosure } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { Search } from '@withjoy/joykit/icons';
import { BookingAssistant } from '@apps/guest/packages/layout-engine/components/BookingAssistant';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useBookingAssistantInfo } from './hooks/useBookingAssistantInfo';
import { AccommodationCombined } from '../Accommodations/Accommodation.types';
import { PointsOnMapFragment } from '@graphql/generated';
import { AnalyticsContext, useTranslation } from '@shared/core';

interface BookingAssistantCtaProps {
  pageSlug: string;
  eventTimezone: Maybe<string>;
  venue: Maybe<PointsOnMapFragment>;
  accommodations: Maybe<AccommodationCombined[]>;
}

export const BookingAssistantCta: React.FC<BookingAssistantCtaProps> = ({ pageSlug, eventTimezone: timezone, venue, accommodations }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const analytics = useContext(AnalyticsContext);

  const { t2 } = useTranslation('guestSiteCustom');
  const bookingAssistantTrans = t2('bookingAssistant');

  const { ...bookingAssistantInfo } = useBookingAssistantInfo({ venue, accommodations });

  const handleOnClick = useEventCallback(() => {
    analytics.track({
      action: 'BookingAssistantClicked',
      actionType: 'click',
      category: 'wedding',
      pagePrefix: 'guest',
      page: pageSlug
    });
    onOpen();
  });

  const onProviderClicked = useEventCallback((checkIn: Maybe<string>, checkOut: Maybe<string>, guests: Maybe<string>, url: Maybe<string>, provider: Maybe<string>) => {
    analytics.track({
      action: 'BookingAssistantSearchProviderClicked',
      actionType: 'click',
      category: 'wedding',
      pagePrefix: 'guest',
      page: pageSlug,
      label: provider || '',
      extraInfo: {
        url,
        checkIn,
        checkOut,
        guests,
        provider
      }
    });
  });

  return (
    <>
      <ButtonV2
        width={pxToRem(244)}
        startIcon={<Search size="md" />}
        typographyVariant="button2"
        intent="neutral"
        shape="rounded"
        variant="outline"
        fontWeight={600}
        fontSize={pxToRem(16)}
        fontFamily={'Inter UI'}
        onClick={handleOnClick}
      >
        {bookingAssistantTrans.cta}
      </ButtonV2>
      <BookingAssistant {...bookingAssistantInfo} timezone={timezone} onProviderClicked={onProviderClicked} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
