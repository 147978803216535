import { styled } from '../../common';
import { Box } from '../Box';

export const PopoverWrapper = styled.span`
  /* added this recently for select, may need to remove */
  position: relative;
`;

export const PopoverContentWrapper = styled(Box)``;

export const PopoverContent = styled(Box)``;
export const PopoverTarget = styled.span<{
  isInlineElement: boolean;
}>`
  display: ${props => (props.isInlineElement ? 'inline-block' : undefined)};
`;
