import React from 'react';
import { StyledWrapper, StyledContainer } from './Columns.styles';
import { ColumnsContextProvider, useColumnsContext } from './Columns.context';
import { ColumnV2 } from './components/Column';
import type { ColumnsV2Props } from './Columns.types';

/**
 * The `<Columns>` ensures consistent layout across screen sizes.
 *
 * Based on a 12-column grid layout, and with conditional 8-column grid support. If the grid can be divided into **8** columns,
 * use the `ColumnEigths` size values else use the standard `ColumnWidth`.
 *
 *
 * **Limitations**:
 * Since this component employs negative margin to implement spacing, a horizontal scroll will appear a negative margin goes
 * beyond the `<body>`. There are several workarounds:
 * 1. Apply padding to the parent that will offset the negative margin
 * 2. Add `overflow-x: hidden` to the parent
 *
 */
export function ColumnsV2({ children, className, useContainer = true, centered = false, fluid = 'md', ...restProps }: ColumnsV2Props) {
  const parentContext = useColumnsContext('silent');

  const isRootContainer = !parentContext;
  const shouldUseContainer = isRootContainer && useContainer;

  const contentMarkup = (
    <StyledWrapper data-testid="inner" className={className} {...(shouldUseContainer ? {} : restProps)} marginX={[-3, null, -4]} centered={centered}>
      {children}
    </StyledWrapper>
  );

  return (
    <ColumnsContextProvider columnGap={'sm'} isRootContainer={isRootContainer}>
      {shouldUseContainer ? (
        <StyledContainer
          marginX={['auto']}
          // For media query `md` and below, double the outer gutter
          // 7 -> 32, 6 -> 24
          // paddingX={[7, null, 6]}
          data-testid={'outer'}
          fluid={fluid}
          {...restProps}
        >
          {contentMarkup}
        </StyledContainer>
      ) : (
        contentMarkup
      )}
    </ColumnsContextProvider>
  );
}

ColumnsV2.displayName = 'ColumnsV2';
ColumnsV2.Column = ColumnV2;
