import { styled } from '@withjoy/joykit/theme';
import { BoxProps } from '../Box';
import { SharedFormControlProps } from '.';

export const StyledFormControlRoot = styled.div``;

const getLabelStyles = (props: SharedFormControlProps): BoxProps => {
  return {
    color: props.$isDisabled ? 'inputTextDisabled' : props.$isInvalid ? 'red6' : 'mono14',
    display: 'block',
    fontWeight: 'medium',
    padding: 0,
    marginX: 0,
    marginTop: 0,
    marginBottom: 3,
    typographyVariant: 'label3'
  };
};

const getHintStyles = (props: SharedFormControlProps): BoxProps => {
  return {
    marginTop: 3,
    typographyVariant: 'label2',
    color: props.$isInvalid ? 'red6' : 'mono14'
  };
};

export const useFormControlStyles = (props: SharedFormControlProps) => {
  return {
    Label: getLabelStyles(props),
    Hint: getHintStyles(props)
  };
};
