import { parseDomain, fromUrl, ParseResultType } from 'parse-domain';

export const extractHostname = (url: string) => {
  const parseResult = parseDomain(fromUrl(url));
  switch (parseResult.type) {
    case ParseResultType.Listed: {
      const { hostname, topLevelDomains, domain } = parseResult;
      if (!domain) {
        return hostname;
      } else {
        return `${domain}.${topLevelDomains.join('.')}`;
      }
    }
    case ParseResultType.Reserved:
    case ParseResultType.NotListed: {
      const { hostname } = parseResult;
      return hostname;
    }
    default:
      return '';
  }
};
