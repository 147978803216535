import { system, compose, margin, flex, width, get, ConfigStyle } from 'styled-system';
import { Box } from '../Box';
import { createShouldForwardProp } from '@withjoy/joykit/utils';
import { SpacingStackProps } from './SpacingStack';

import { css, styled } from '@withjoy/joykit';
import { ResponsiveSpacingProps } from '@withjoy/joykit/styled-system';

// Styled Functions

// Got this from styled-system
const isNumber = (n: unknown): n is number => typeof n === 'number' && !isNaN(n);

const getSpacing = (n: number, scale: unknown) => {
  if (!isNumber(n)) {
    return get(scale, n, n);
  }
  const isNegative = n < 0;
  const absolute = Math.abs(n);
  const value = get(scale, absolute, absolute);
  if (!isNumber(value)) {
    return isNegative ? '-' + value : value;
  }
  const marginValue = value * (isNegative ? -1 : 1);

  return typeof marginValue === 'string' ? marginValue : `${marginValue}px`;
};

const _createStackSpacingFn = (stack: 'vertical' | 'horizontal') => {
  return system({
    spacing: {
      property: stack === 'horizontal' ? 'marginLeft' : 'marginTop',
      scale: 'space',
      transform: (value: number, scale: unknown, props: { stack: 'vertical' | 'horizontal' }) => {
        return props.stack === stack ? getSpacing(value, scale) : null;
      }
    } as ConfigStyle
  });
};

export const childSpacing = css<{ stack: 'horizontal' | 'vertical' } & ResponsiveSpacingProps>`
  > *:not(:first-child) {
    ${_createStackSpacingFn('horizontal')}
    ${_createStackSpacingFn('vertical')}
  }
`;

const orientationStyleFn = system({
  stack: {
    property: 'flexDirection',
    transform: value => {
      return value === 'vertical' ? 'column' : 'row';
    }
  }
});

const inlineStyleFn = system({
  inline: {
    property: 'display',
    transform: shouldRenderAsInline => {
      return shouldRenderAsInline ? 'inline-flex' : 'flex';
    }
  }
});

const composedResponsiveFunctions = compose(margin, flex, orientationStyleFn, inlineStyleFn, width);

const shouldForwardProp = createShouldForwardProp<SpacingStackProps>(['spacing', 'stack', 'inline']);

export const SpacingStackWrapper = styled(Box).withConfig<SpacingStackProps & Required<Pick<SpacingStackProps, 'spacing' | 'stack'>>>({
  shouldForwardProp
})`
  ${composedResponsiveFunctions}

  ${childSpacing}
`;
