import React from 'react';
import { styled, css, keyframes } from '@withjoy/joykit';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const visibilityKeyframes = keyframes`
  to {
    visibility: visible;
  }
`;

const SpinnerWrapper = styled.div<{ position: 'absolute' | 'fixed'; enableDelayedVisibility: boolean }>`
  position: ${props => props.position};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  /* background-color: #fff; */
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => {
    if (props.enableDelayedVisibility) {
      return css`
        visibility: hidden;
        animation: 0s linear 0.5s forwards ${visibilityKeyframes};
      `;
    }
    return {};
  }}
`;

const spinnerIconAnimation = () => {
  return css`
    ${spinAnimation} 1.5s ease infinite;
  `;
};
export const SpinnerItem = styled.div`
  height: 50px;
  width: 50px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: black;
  border-radius: 50%;
  animation: ${spinnerIconAnimation};
`;
export const InlineSpinner = styled.div<{ size?: number; color?: string }>`
  height: ${({ size = 22 }) => size}px;
  width: ${({ size = 22 }) => size}px;
  border: 2px solid ${({ color = '#808080' }) => color};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${spinnerIconAnimation};
`;

export const TempSpinner: React.FC<{ className?: string; enableDelayedVisibility?: boolean; position?: 'fixed' | 'absolute'; id?: string }> = ({
  className,
  enableDelayedVisibility = false,
  position = 'fixed',
  id
}) => {
  return (
    <SpinnerWrapper id={id} aria-label="Loading" aria-hidden={false} enableDelayedVisibility={enableDelayedVisibility} className={className} position={position}>
      <SpinnerItem />
    </SpinnerWrapper>
  );
};
