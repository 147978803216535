import { useEventNameMatch } from '@shared/components/AuthProvider/AuthProvider.utils';
import { useGetEventCTAButtonsDataQuery } from '@graphql/generated';
import { useState } from 'react';
import { BackButtonCallBackType } from '@apps/guest/packages/layout-engine/components/DialogStyled/DialogStyled';
import { CtaButtonsSessionData } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons.types';

interface UnlockGuestControllerTypes
  extends Readonly<{
    hasUnlocked: boolean;
    shouldDisplayFindInvite: boolean;
    eventId: string;
    showBackButton: boolean;
    setShowBackButton: (p: boolean) => void;
    onBackButtonClick: (cb: BackButtonCallBackType) => void;
  }> {}

function useGuestUnlockInfoController() {
  const { eventName } = useEventNameMatch();
  const [showBackButton, setShowBackButton] = useState<boolean>(false);

  const { data: sessionData } = useGetEventCTAButtonsDataQuery({
    variables: { name: eventName || '' },
    batchMode: 'fast',
    ssr: false
  });

  const event: CtaButtonsSessionData = sessionData?.eventByName ?? {};
  const { activeSession, id: eventId = '' } = event;
  const { hasUnlocked = false } = activeSession || {};

  const onBackButtonClick = (cb: BackButtonCallBackType) => {
    document.getElementById('backButton')?.addEventListener('click', () => {
      cb();
    });
  };

  return {
    hasUnlocked,
    eventId,
    showBackButton,
    setShowBackButton,
    onBackButtonClick
  };
}

export { UnlockGuestControllerTypes, useGuestUnlockInfoController };
