import React from 'react';
import { BackdropWrapper } from './styles';
import { useTransition } from 'react-spring';

export interface BackdropProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'onMouseDown'> {
  isOpen: boolean;
  onClick?: React.MouseEventHandler;
}
export const Backdrop: React.SFC<BackdropProps> = ({ isOpen, onClick, onMouseDown }) => {
  const backdropTransition = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: isOpen ? 0 : 166,
    unique: true,
    reset: true
  });

  return (
    <>
      {backdropTransition.map(({ key, item, props }) => {
        return (
          item && (
            <BackdropWrapper
              key={key}
              isOpen={isOpen}
              style={{
                opacity: props.opacity!.interpolate(x => x)
              }}
              onClick={onClick}
              onMouseDown={onMouseDown}
            />
          )
        );
      })}
    </>
  );
};
