import { TextV2 } from '@withjoy/joykit';
import React from 'react';

export const AccommodationTileTitle: React.FC<{ displayName: Maybe<string> }> = ({ displayName }) => {
  return (
    <TextV2 width={'100%'} fontFamily={'Inter UI'} fontSize={'18px'} fontWeight={600} lineHeight={'23.4px'} letterSpacing={'-0.432px'}>
      {displayName}
    </TextV2>
  );
};
