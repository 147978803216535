import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { PRODUCT_OFFERINGS } from '../Dashboard/components/ConciergeServiceSection/ConciergeServiceSection.config';
import { ConciergeServiceCategory } from './config';

export const getConciergeCategoryFromDesignSetName = (designSetName: string) => {
  return (
    (Object.keys(PRODUCT_OFFERINGS).find(key =>
      PRODUCT_OFFERINGS[key as StationeryTemplateCategoryEnum].some(offering => offering.identifier === designSetName)
    ) as ConciergeServiceCategory) ?? null
  );
};

export const getProductOfferingFromDesignSetName = (designSetName: string) => {
  return Object.values(PRODUCT_OFFERINGS)
    .flat()
    .find(offering => offering.identifier === designSetName);
};
